import { memo } from "react";
import styles from "./horizontalStat.module.css";

export const HorizontalStat = memo(
    ({
        heading,
        children,
        size = "sm",
    }: {
        heading: string;
        children: React.ReactNode;
        size?: "sm" | "lg";
    }) => (
        <div
            className={`${styles.statContainer}`}
            style={{ flexDirection: size === "sm" ? "column" : "column-reverse" }}
        >
            <p
                className={styles.statHeading}
                style={{
                    marginBottom: size === "sm" ? 1.5 : 0,
                    marginTop: size === "sm" ? 0 : 1.5,
                }}
            >
                {heading}
            </p>
            <p className={size === "sm" ? styles.statSm : styles.statLg}>{children}</p>
        </div>
    ),
);
