import React from "react";
import { Table } from "@mantine/core";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import styles from "./CallLogs.module.css";
import { PhoneIcomingIcon } from "../../../../../assets/icons/PhoneIncomingIcon";
import { PhoneMissedIcon } from "../../../../../assets/icons/PhoneMissedIcon";
import { formatDateTime } from "../../../../../utils/formateDateTime";
import { TCallLogs } from "../../../../../types";
import { formatDuration } from "../../../../../utils/formatDuration";

type CallLogProps = {
    callLogs?: TCallLogs[];
    isLoading?: boolean;
};

export const CallLogs: React.FC<CallLogProps> = ({ callLogs, isLoading }) => {
    const navigate = useNavigate();

    return (
        <div className={styles.callLogsWrapper}>
            <Table>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>Customer</Table.Th>
                        <Table.Th>Date</Table.Th>
                        <Table.Th>Duration</Table.Th>
                        <Table.Th style={{ textAlign: "end" }}>Action</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {isLoading ? (
                        <Table.Tr>
                            <Table.Td colSpan={12}>
                                <Skeleton height={39} width={"100%"} style={{ marginBottom: 19 }} />
                            </Table.Td>
                        </Table.Tr>
                    ) : (
                        <>
                            {callLogs && callLogs.length > 0 ? (
                                callLogs.map((data: TCallLogs, index: number) => {
                                    const { formattedDate, formattedTime } = formatDateTime(
                                        data.call_date_created,
                                    );
                                    const formattedDuration = formatDuration(
                                        parseInt(data.call_duration),
                                    );
                                    return (
                                        <Table.Tr key={index}>
                                            <Table.Td>
                                                <div className={styles.name}>
                                                    <div
                                                        className={`${styles.callType} ${data.call_status === "completed" ? styles.incoming : styles.missed}`}
                                                    >
                                                        {data.call_status === "completed" && (
                                                            <PhoneIcomingIcon />
                                                        )}
                                                        {data.call_status === "no-answer" && (
                                                            <PhoneMissedIcon />
                                                        )}
                                                    </div>
                                                    {data.caller_phonenum}
                                                </div>
                                            </Table.Td>
                                            <Table.Td>
                                                <span>{formattedDate}</span>{" "}
                                                <span className={styles.time}>{formattedTime}</span>
                                            </Table.Td>
                                            <Table.Td>{formattedDuration}</Table.Td>
                                            <Table.Td style={{ textAlign: "end" }}>
                                                <button
                                                    onClick={() =>
                                                        navigate(
                                                            `/contractor/ai-assistant/call-log/${data._id}`,
                                                        )
                                                    }
                                                    className={styles.bookingBtn}
                                                    style={{ marginRight: "5px" }}
                                                >
                                                    View Details
                                                </button>
                                                <button className={styles.bookingBtn}>
                                                    View Booking
                                                </button>
                                            </Table.Td>
                                        </Table.Tr>
                                    );
                                })
                            ) : (
                                <Table.Tr>
                                    <Table.Td colSpan={12}>
                                        <div className={styles.nothingToSeePlaceholder}>
                                            No Call Logs Exists!
                                        </div>
                                    </Table.Td>
                                </Table.Tr>
                            )}
                        </>
                    )}
                </Table.Tbody>
            </Table>
        </div>
    );
};
