import React from "react";
import { X } from "lucide-react";
import { cn } from "@/lib/utils";

/**
 * @component Modal
 * @description This component is used to create a modal dialog.
 *
 * @prop {boolean} isOpen - @required Determines if the modal is open.
 * @prop {function} onClose - @required Callback function triggered when the modal is closed.
 * @prop {string} title - @required The title displayed at the top of the modal.
 * @prop {React.ReactNode} children - @required The content rendered inside the modal.
 * @prop {string} [className] - Additional classes for the modal container.
 * @prop {string} [contentClassName] - Additional classes for the modal content area.
 * @prop {string} [titleClassName] - Additional classes for the modal title.
 * @prop {string} [closeButtonClassName] - Additional classes for the close button.
 *
 * @example
 * <Modal
 *   isOpen={isOpen}
 *   onClose={handleClose}
 *   title="Modal Title"
 *   className="custom-modal"
 *   contentClassName="custom-content"
 *   titleClassName="custom-title"
 *   closeButtonClassName="custom-close-button"
 * >
 *   <p>Your modal content goes here.</p>
 * </Modal>
 *
 * @notes
 * - The modal can be fully customized with additional classNames.
 * - Ensure `isOpen` is controlled via state to handle open/close functionality.
 * - pass max-w-[actual_size] on contentClassName prop to set the width of the modal.
 */

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
    className?: string;
    contentClassName?: string;
    titleClassName?: string;
    closeButtonClassName?: string;
    headerContainerClassName?: string;
}

const LBModal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    title,
    children,
    className,
    contentClassName,
    titleClassName,
    closeButtonClassName,
    headerContainerClassName,
}) => {
    if (!isOpen) return null;

    return (
        <div
            onClick={onClose}
            className={cn(
                "fixed inset-0 z-50 bg-black/50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none px-6",
                className,
            )}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className="relative w-max mx-auto max-h-[90vh]"
            >
                <div
                    className={cn(
                        "relative flex flex-col min-w-xl mx-auto z-[100] bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none overflow-hidden",
                        contentClassName,
                    )}
                >
                    <div
                        className={cn(
                            headerContainerClassName,
                            "flex items-center justify-between px-5 rounded",
                        )}
                    >
                        <h3 className={cn("text-lg font-semibold", titleClassName)}>{title}</h3>
                        <button
                            onClick={onClose}
                            className={cn(
                                "p-1 ml-auto bg-transparent border-0 text-black text-3xl leading-none font-semibold outline-none focus:outline-none",
                                closeButtonClassName,
                            )}
                        >
                            <X size={16} />
                        </button>
                    </div>
                    <div className="relative py-4 px-6 flex-auto overflow-y-auto max-h-[calc(90vh-100px)]">
                        {children}
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </div>
    );
};

export default LBModal;
