import styles from "./homeOwnerDashboard.module.css";

export const SpendingToggleButton = ({
    showSpending,
    onClick,
}: {
    showSpending: boolean;
    onClick: () => void;
}) => (
    <button className={styles.spendingHeaderButton} onClick={onClick}>
        {showSpending ? <i className="ri-eye-off-line"></i> : <i className="ri-eye-line"></i>}
        <span>{showSpending ? "Hide spendings" : "Show spendings"}</span>
    </button>
);
