import { configureStore } from "@reduxjs/toolkit";
import languageSlice from "./features/languageSlice";
import uiSlice from "./features/uiSlice";
import { api } from "./rtk-query/api";
import { setupListeners } from "@reduxjs/toolkit/query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import postJobSlice from "./features/postJobSlice";

const isDevelopment = window.location.hostname === "localhost";

export const store = configureStore({
    reducer: {
        language: languageSlice,
        postJob: postJobSlice,
        ui: uiSlice,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
    devTools: isDevelopment,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

setupListeners(store.dispatch);
