import { cn } from "@/lib/utils";
import { capitalizeEachWord } from "@/utils/capitalizeEachWords";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import UserProfile from "@/components/userProfile/UserProfile";

// function getInitials(name: string | undefined): string {
//     if (!name) return "";
//     return name
//         .split(" ")
//         .map((word) => word.charAt(0).toUpperCase())
//         .join("");
// }

type TProps = {
    heading: string | undefined;
    image: string | undefined;
    username: string | undefined;
    className?: string;
    hasSearchBar?: boolean;
};

export const NewHeader = ({ hasSearchBar = false, ...props }: TProps) => {
    return (
        <header
            className={cn(
                "h-[65px] px-4 py-0 flex items-center justify-between w-full bg-white border-b border-b-zinc-200",
                props.className,
            )}
        >
            {props.heading ? (
                <h2 className="font-medium text-lg leading-[26px] text-black">
                    {capitalizeEachWord(props.heading)}
                </h2>
            ) : (
                <div></div>
            )}
            <div className="flex gap-4">
                {hasSearchBar && (
                    <div className="flex w-full max-w-sm items-center relative">
                        <Search className="absolute left-3" />

                        <Input type="text" placeholder="Search..." className="!pl-9" />
                    </div>
                )}
                <UserProfile />
            </div>
        </header>
    );
};
