import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Spinner } from "@/components/ui/spinner";
import useGoogleAutocomplete from "@/hooks/useGoogleAutocomplete";
import { cn } from "@/lib/utils";
import {
    useCreateAddonsMutation,
    useDeleteAddonsMutation,
    useGetAddonsQuery,
} from "@/redux/rtk-query/addonsApis";
import {
    useGetMyDetailsQuery,
    useGetUserbyTypeQuery,
    useInviteUsersMutation,
    useUpdateAddressMutation,
} from "@/redux/rtk-query/authApis";
import {
    useCreateContractMutation,
    useUpdateContractMutation,
} from "@/redux/rtk-query/contractsApis";
import { usePropertiesMutation, useUpdatePropertiesMutation } from "@/redux/rtk-query/properties";
import { TAddons, TInviteOwnerArgs } from "@/types";
import { catchAll } from "@/utils/catch";
import { propertyTypeOptions } from "@/utils/constantData";
import { convertScreamToTitle } from "@/utils/convertScreamToTitle";
import { getAddressFromGoogleObject } from "@/utils/getAddressFromGoogleObject";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import {
    ArrowLeft,
    Calendar as CalendarIcon,
    Check,
    ChevronDown,
    LogOut,
    UserRound,
    X,
} from "lucide-react";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { z } from "zod";
import { TAddPropertyPage } from ".";
import AutoCompleteAddress from "@/components/common/address";
import { getCity } from "@/utils/commonutils";
import COUNTRY_JSON from "../../../../jsonData/country.json";
import { useNavigate } from "react-router-dom";

type UnitDetails = {
    buildingId?: string;
    unitNumber?: string;
    noOfBedrooms?: number;
    address?: string;
    city?: string;
    postCode?: string;
    country?: string;
    unitArea?: string;
    propertyType?: string;
    status?: string;
    companyId?: string;
    amenities?: any;
    propertyOwnerName?: string;
    propertyOwner?: string;
    phoneNumber?: string;
    propertyOwnerEmail?: string;
    rent?: string | number;
    securityDeposit?: string | number;
    leasePeriodStart?: string;
    leasePeriodEnd?: string;
};

type TProps = {
    onChangePage: (page: TAddPropertyPage) => void;
    buildingId: string;
    images: string[];
    addressId: string;
    unitDetails?: UnitDetails;
    id?: string;
    unitDataId?: string;
};

const FormSchema = z.object({
    unitNumber: z.string().min(1, "Unit number is required"),
    propertyType: z.string().min(1, "Property type is required"),
    unitArea: z.string().min(1, "Unit area is required"),
    noOfBedrooms: z.string().min(1, "No. of bedrooms is required"),
    address: z.string().min(1, "Address is required"),
    city: z.string().min(1, "City is required"),
    postCode: z.string().min(1, "pinCode is required"),
    country: z.string().min(1, "Country is required"),
    amenities: z.array(z.string()),
    propertyOwner: z.string(),
    propertyOwnerPhoneNumber: z.string(),
    propertyOwnerName: z.string(),
    propertyOwnerEmail: z.string(),
    rent: z.string(),
    securityDeposit: z.string(),
    leasePeriodStart: z.string(),
    leasePeriodEnd: z.string(),
});

const defaultValues: z.infer<typeof FormSchema> = {
    unitNumber: "",
    propertyType: "",
    unitArea: "",
    noOfBedrooms: "",
    address: "",
    city: "",
    postCode: "",
    country: "",
    amenities: [],
    propertyOwner: "",
    propertyOwnerPhoneNumber: "",
    propertyOwnerName: "",
    propertyOwnerEmail: "",
    rent: "",
    securityDeposit: "",
    leasePeriodStart: "",
    leasePeriodEnd: "",
};

const Countries = Object.entries(COUNTRY_JSON.Results).map((country) => country[1].Name);

export const UnitForm = ({
    onChangePage,
    buildingId,
    images,
    addressId,
    unitDetails,
    id,
    unitDataId,
}: TProps) => {
    const navigate = useNavigate();
    const [unitStatus, setUnitStatus] = useState("vacant");
    const { data: myDetails } = useGetMyDetailsQuery();
    const { data: addonsDataDefault } = useGetAddonsQuery();
    const { data: propertyOwnersData } = useGetUserbyTypeQuery({
        userType: "TENANT",
        status: "",
    });

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: defaultValues,
    });

    const [initialLoad, setInitialLoad] = useState(true);
    const [filterAddons, setFilterAddons] = useState("");

    const filteredAddons = useMemo(() => {
        if (!addonsDataDefault) return [];

        const normalizedFilter = filterAddons.toLowerCase();

        return addonsDataDefault
            .filter((addon) => {
                if (addon?.key) {
                    return filterAddons ? addon.key.toLowerCase().includes(normalizedFilter) : true;
                }
                return false;
            })
            .filter((addon) => !form.watch("amenities").includes(addon._id));
    }, [addonsDataDefault, filterAddons, form]);

    // useEffect(() => {
    //     if (unitDetails) {
    // const amenities =
    //     [
    //         ...(unitDetails?.amenities
    //             ?.filter((data: TAddons) => {
    //                 const matchingAddon = addonsDataDefault?.find(
    //                     (addon) => addon.key === data.key,
    //                 );
    //                 return !!matchingAddon;
    //             })
    //             .map((data: any) => {
    //                 const matchingAddon = addonsDataDefault?.find(
    //                     (addon) => addon.key === data.key,
    //                 );
    //                 return matchingAddon?._id;
    //             }) || []),
    //     ] || [];

    //         form.reset({
    //             unitNumber: unitDetails.unitNumber,
    //             propertyType: unitDetails?.propertyType?.toString() || "",
    //             unitArea: unitDetails?.unitArea?.toString() || "",
    //             noOfBedrooms: unitDetails?.noOfBedrooms?.toString() || "",
    //             address: unitDetails?.address || "",
    //             amenities,
    //             propertyOwner: unitDetails?.propertyOwner || "",
    //             propertyOwnerPhoneNumber: unitDetails?.phoneNumber || "",
    //             propertyOwnerName: unitDetails?.propertyOwnerName || "",
    //             propertyOwnerEmail: unitDetails?.propertyOwnerEmail || "",
    //             rent: unitDetails?.rent?.toString() || "",
    //             city: unitDetails?.city || "",
    //             postCode: unitDetails?.postCode || "",
    //             country: unitDetails?.country || "",
    //             securityDeposit: unitDetails?.securityDeposit?.toString() || "",
    //             leasePeriodStart: unitDetails?.leasePeriodStart || "",
    //             leasePeriodEnd: unitDetails?.leasePeriodEnd || "",
    //         });
    //         setAddressSelected(true);
    //         if (unitDetails?.propertyOwner) {
    //             setUnitStatus("occupied");
    //         }
    //     }
    // }, [addonsDataDefault, form, unitDetails]);

    useEffect(() => {
        if (unitDetails?.unitNumber && initialLoad) {
            const amenities =
                [
                    ...(unitDetails?.amenities
                        ?.filter((data: TAddons) => {
                            const matchingAddon = addonsDataDefault?.find(
                                (addon) => addon.key === data.key,
                            );
                            return !!matchingAddon;
                        })
                        .map((data: any) => {
                            const matchingAddon = addonsDataDefault?.find(
                                (addon) => addon.key === data.key,
                            );
                            return matchingAddon?._id;
                        }) || []),
                ] || [];

            form.reset({
                unitNumber: unitDetails.unitNumber,
                propertyType: unitDetails.propertyType || "",
                unitArea: unitDetails.unitArea?.toString() || "",
                noOfBedrooms: unitDetails.noOfBedrooms?.toString() || "",
                address: unitDetails.address || "",
                city: unitDetails.city || "",
                postCode: unitDetails.postCode || "",
                country: unitDetails.country || "",
                amenities,
                propertyOwner: unitDetails.propertyOwner || "",
                propertyOwnerPhoneNumber: unitDetails.phoneNumber || "",
                propertyOwnerName: unitDetails.propertyOwnerName || "",
                propertyOwnerEmail: unitDetails.propertyOwnerEmail || "",
                rent: unitDetails.rent?.toString() || "",
                securityDeposit: unitDetails.securityDeposit?.toString() || "",
                leasePeriodStart: unitDetails.leasePeriodStart || "",
                leasePeriodEnd: unitDetails.leasePeriodEnd || "",
            });

            setInitialLoad(false);
            setAddressSelected(true);
            if (unitDetails?.propertyOwner) {
                setUnitStatus("occupied");
            }
        }
    }, [unitDetails, form, initialLoad, addonsDataDefault]);

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [addressSelected, setAddressSelected] = useState(false);
    const [createOwner, setCreateOwner] = useState(false);

    const [inviteOwner, { isLoading: inviteOwnerLoading }] = useInviteUsersMutation();
    const [createContract, { isLoading: createContractLoading }] = useCreateContractMutation();
    const [updateContract, { isLoading: updateContractLoading }] = useUpdateContractMutation();
    const [createUnit, { isLoading: createPropertyUnitLoading }] = usePropertiesMutation();
    const [updateUnit, { isLoading: updatePropertyUnitLoading }] = useUpdatePropertiesMutation();
    const [createAddons, { isLoading: createAddonsLoading }] = useCreateAddonsMutation();
    const [deleteAddons, { isLoading: deleteAddonsLoading }] = useDeleteAddonsMutation();
    const [updateAddress, { isLoading: isUpdateAddressLoading }] = useUpdateAddressMutation();

    const { autocompleteInputRef, place } = useGoogleAutocomplete({
        name: "address",
        handleUserDetails: (e) => {
            form.setValue("address", e.target.value);
            setAddressSelected(!!e.target.value);
        },
        showCompleteDetails: true,
    });

    useEffect(() => {
        if (place) {
            const city = getCity(place.address_components ?? []);
            form.setValue("city", city || "");
        }
    }, [form, place]);

    const onClickAddUnit = async (data: z.infer<typeof FormSchema>) => {
        if (!myDetails?.companyId) {
            toast.error("Token expiCanada, Ontariored. Please login again");
            return;
        }

        const noOfBedrooms = Number(data.noOfBedrooms);

        if (!place) {
            form.setError("address", { message: "Invalid address value. Select again" });
            return;
        }

        const address = getAddressFromGoogleObject(place);

        if (Number.isNaN(noOfBedrooms) || noOfBedrooms < 0) {
            form.setError("noOfBedrooms", {
                type: "valueAsNumber",
                message: "Invalid value for bedrooms",
            });
            return;
        }

        const unitArea = Number(data.unitArea);
        if (Number.isNaN(unitArea) || unitArea < 0) {
            form.setError("unitArea", {
                type: "valueAsNumber",
                message: "Invalid value for area",
            });
            return;
        }

        const payload = {
            buildingId,
            images,
            name: data.unitNumber,
            type: data.propertyType,
            area: data.unitArea,
            address: {
                ...address,
                building: form.getValues("address"),
                city: form.getValues("city"),
                country: form.getValues("country"),
                postalCode: form.getValues("postCode"),
            },
            noOfBedrooms,
            status: unitStatus === "vacant" ? "AVAILABLE" : "NOT_AVAILABLE",
            companyId: myDetails.companyId,
        };

        if (unitDetails) {
            const { companyId, address, images, ...restPayload } = payload;
            const updateUnitResponse = await catchAll(
                updateUnit({ ...restPayload, buildingId: buildingId, _id: id }).unwrap(),
            );

            if (updateUnitResponse.error) return;

            const addonIds = form.getValues("amenities");

            if (unitDetails?.amenities.length > 0) {
                for (const data of unitDetails.amenities) {
                    await catchAll(deleteAddons(data._id).unwrap());
                }
            }

            if (addonIds.length > 0 && addonsDataDefault && addonsDataDefault.length > 0) {
                const addonPromises = addonsDataDefault
                    .filter((addon) => addonIds.includes(addon._id))
                    .map((addon) => {
                        return createAddons({
                            default: false,
                            key: addon.key,
                            propertyId: updateUnitResponse?.data?.data?._id,
                            value: true,
                        });
                    });

                await catchAll(Promise.all(addonPromises));
            }

            const { propertyOwner, rent, securityDeposit, leasePeriodStart, leasePeriodEnd } =
                form.getValues();

            const startDate = dayjs(leasePeriodStart);
            const endDate = dayjs(leasePeriodEnd);

            if (startDate.isAfter(endDate)) {
                toast.error("Start date cannot be after end date");
                return;
            }

            const rentAsNumber = Number(rent);

            if (Number.isNaN(rentAsNumber) || rentAsNumber < 0) {
                form.setError("rent", { message: "Invalid value for rent" });
                return;
            }

            const securityDepositAsNumber = Number(securityDeposit);
            if (Number.isNaN(securityDepositAsNumber) || securityDepositAsNumber < 0) {
                form.setError("securityDeposit", {
                    message: "Invalid value for security deposit",
                });
                return;
            }

            if (!propertyOwner) {
                form.setError("propertyOwner", {
                    type: "required",
                    message: "Tenant is required",
                });
                return;
            }
            if (unitDetails && "propertyOwner" in unitDetails && unitStatus === "occupied") {
                await catchAll(
                    updateContract({
                        companyId: myDetails?.companyId,
                        attachmentsUrls: [],
                        propertyId: updateUnitResponse.data._id,
                        userId: propertyOwner,
                        tenure: "long-term",
                        rent: rentAsNumber,
                        securityDeposit: securityDepositAsNumber,
                        otherCharges: 0,
                        _id: unitDataId,
                        paymentMethod: "bank_transfer",
                        bookingDate: new Date(new Date().toISOString()).toISOString(),
                        startDate: new Date(startDate.toISOString()).toISOString(),
                        endDate: new Date(endDate.toISOString()).toISOString(),
                        status: "active",
                    }).unwrap(),
                );
            } else if (unitDetails && "propertyOwner" in unitDetails && unitStatus === "vacant") {
                await catchAll(
                    updateContract({
                        _id: unitDataId,
                        status: "terminated",
                    }).unwrap(),
                );
            } else if (
                unitDetails &&
                !("propertyOwner" in unitDetails) &&
                unitStatus === "occupied"
            ) {
                await catchAll(
                    createContract({
                        companyId: myDetails?.companyId,
                        attachmentsUrls: [],
                        propertyId: updateUnitResponse.data._id,
                        userId: propertyOwner,
                        bookingDate: new Date().toISOString(),
                        startDate: startDate.toISOString(),
                        endDate: endDate.toISOString(),
                        tenure: "long-term",
                        rent: rentAsNumber,
                        securityDeposit: securityDepositAsNumber,
                        otherCharges: 0,
                    }).unwrap(),
                );
            }
            await updateAddress({
                _id: addressId,
                addressType: address?.addressType,
                mainStreet: address.mainStreet,
                building: address?.building,
                country: place?.formatted_address
                    ? place?.formatted_address?.split(",").slice(-1).join("").trim()
                    : "",
                city: address?.city,
                postalCode: address?.postalCode,
                province: address?.province,
                timezone: address?.timezone,
                latitude: place?.geometry?.location?.lat() ?? 0,
                longitude: place?.geometry?.location?.lng() ?? 0,
            });

            navigate(-1);
        } else {
            const createUnitResponse = await catchAll(createUnit(payload).unwrap());

            if (createUnitResponse.error) return;

            const addonIds = form.getValues("amenities");

            if (addonIds.length > 0 && addonsDataDefault && addonsDataDefault.length > 0) {
                const addonPromises = addonsDataDefault
                    .filter((addon) => addonIds.includes(addon._id))
                    .map((addon) => {
                        return createAddons({
                            default: false,
                            key: addon.key,
                            propertyId: createUnitResponse.data._id,
                            value: true,
                        });
                    });

                await catchAll(Promise.all(addonPromises));
            }

            if (unitStatus === "occupied") {
                const { propertyOwner, rent, securityDeposit, leasePeriodStart, leasePeriodEnd } =
                    form.getValues();

                const startDate = dayjs(leasePeriodStart);
                const endDate = dayjs(leasePeriodEnd);

                if (startDate.isAfter(endDate)) {
                    toast.error("Start date cannot be after end date");
                    return;
                }

                const rentAsNumber = Number(rent);

                if (Number.isNaN(rentAsNumber) || rentAsNumber < 0) {
                    form.setError("rent", { message: "Invalid value for rent" });
                    return;
                }

                const securityDepositAsNumber = Number(securityDeposit);
                if (Number.isNaN(securityDepositAsNumber) || securityDepositAsNumber < 0) {
                    form.setError("securityDeposit", {
                        message: "Invalid value for security deposit",
                    });
                    return;
                }

                if (!propertyOwner) {
                    form.setError("propertyOwner", {
                        type: "required",
                        message: "Tenant is required",
                    });
                    return;
                }

                await catchAll(
                    createContract({
                        companyId: myDetails?.companyId,
                        attachmentsUrls: [],
                        propertyId: createUnitResponse.data._id,
                        userId: propertyOwner,
                        bookingDate: new Date().toISOString(),
                        startDate: startDate.toISOString(),
                        endDate: endDate.toISOString(),
                        tenure: "long-term",
                        rent: rentAsNumber,
                        securityDeposit: securityDepositAsNumber,
                        otherCharges: 0,
                    }).unwrap(),
                );
            }

            onChangePage("unit-listing");
            form.reset();
        }
    };

    const onClickBackToProperty = () => {
        onChangePage("property");
    };

    const onClickSelectOwner = useCallback(
        (owner: string) => {
            form.setValue("propertyOwner", owner);
        },
        [form],
    );

    const onChangeUnitStatus = (value: string) => {
        if (value === "vacant") {
            form.setValue("propertyOwner", "");
            form.setValue("propertyOwnerEmail", "");
            form.setValue("propertyOwnerName", "");
            form.setValue("propertyOwnerPhoneNumber", "");
            form.setValue("rent", "");
            form.setValue("securityDeposit", "");
            form.setValue("leasePeriodStart", "");
            form.setValue("leasePeriodEnd", "");

            setCreateOwner(false);
        }

        setUnitStatus(value);
    };

    const onClickSaveOwner = async () => {
        if (!myDetails?.companyId) {
            toast.error("Token expired. Please login again");
            return;
        }
        const { propertyOwnerEmail, propertyOwnerName, propertyOwnerPhoneNumber } =
            form.getValues();

        if (!propertyOwnerName) {
            form.setError("propertyOwnerName", {
                type: "required",
                message: "Property owner is required",
            });
            return;
        }

        if (!propertyOwnerEmail) {
            form.setError("propertyOwnerEmail", {
                type: "required",
                message: "Property owner email is required",
            });
            return;
        }

        if (!propertyOwnerPhoneNumber) {
            form.setError("propertyOwnerPhoneNumber", {
                type: "required",
                message: "Property owner number is required",
            });
            return;
        }

        const firstName = propertyOwnerName.split(" ")[0];
        const lastName = propertyOwnerName.split(" ").slice(1).join(" ");

        const payload: TInviteOwnerArgs = {
            email: propertyOwnerEmail,
            firstName,
            lastName,
            userType: "HOMEOWNER",
            companyId: myDetails?.companyId,
            phoneNumber: propertyOwnerPhoneNumber,
            status: "INVITED",
        };

        const response = await catchAll(inviteOwner(payload).unwrap());

        if (response.error) {
            toast.error("Failed to create owner");
            return;
        }

        setCreateOwner(false);
        form.setValue("propertyOwnerEmail", "");
        form.setValue("propertyOwnerPhoneNumber", "");
        form.setValue("propertyOwnerName", "");
    };

    const PropertyOwnerDropdown = useMemo(() => {
        return (
            <FormItem>
                <FormLabel className="mb-2 text-[#09090B]">Owner</FormLabel>
                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            variant="outline"
                            role="combobox"
                            className="border shadow-none border-zinc-200 font-normal text-gray-500 hover:text-gray-500  p-5 w-[310px] justify-between flex px-3"
                        >
                            {form.watch("propertyOwner")
                                ? form.watch("propertyOwner")
                                : "Select property owner"}
                            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-[310px] p-0">
                        <Command className="relative">
                            <CommandInput placeholder="Search owner..." />
                            <CommandList>
                                <CommandEmpty>No owner found</CommandEmpty>
                                <CommandGroup className="pb-11">
                                    {propertyOwnersData &&
                                        propertyOwnersData?.data.length > 0 &&
                                        propertyOwnersData?.data.map(
                                            (owner: any, index: number) => {
                                                const ownerName =
                                                    `${owner?.firstName ?? ""} ${owner?.lastName ?? ""}`.trim();
                                                return (
                                                    <CommandItem
                                                        key={owner._id + index}
                                                        value={ownerName}
                                                        onSelect={() =>
                                                            onClickSelectOwner(owner._id)
                                                        }
                                                    >
                                                        <Check
                                                            className={cn(
                                                                "mr-2 h-4 w-4",
                                                                form.watch("propertyOwner") ===
                                                                    owner._id
                                                                    ? "opacity-100"
                                                                    : "opacity-0",
                                                            )}
                                                        />
                                                        {ownerName}
                                                    </CommandItem>
                                                );
                                            },
                                        )}
                                </CommandGroup>
                            </CommandList>
                            <div
                                onClick={() => setCreateOwner(true)}
                                className="absolute bottom-0 left-0 right-0 p-3 bg-white flex items-center space-x-2 border-t border-zinc-200 z-50 cursor-pointer"
                            >
                                <LogOut size={16} className={"text-zinc-950"} />
                                <p className="text-sm leading-[17px] text-[#09090B]">
                                    Add New Owner
                                </p>
                            </div>
                        </Command>
                    </PopoverContent>
                </Popover>
                <FormMessage />
            </FormItem>
        );
    }, [form, onClickSelectOwner, propertyOwnersData]);

    function onClickAddon(id: string) {
        form.setValue("amenities", [...form.getValues("amenities"), id]);
    }

    function onClickRemoveAddon(toRemoveId: string) {
        form.setValue(
            "amenities",
            form.watch("amenities").filter((id) => id !== toRemoveId),
        );
    }

    return (
        <section>
            {!unitDetails && (
                <div
                    className="bg-[#F6F8FA]  gap-1 px-2 py-1 rounded-3xl font-medium text-sm leading-[14px] text-[#666D80] flex items-center space-x-2 max-w-max mb-6 cursor-pointer"
                    onClick={onClickBackToProperty}
                >
                    <ArrowLeft size={20} /> Back to Property
                </div>
            )}
            <h2 className="font-medium text-lg leading-[22px] text-[#09090B] mb-1">
                Unit's Details
            </h2>
            <p className="text-sm leading-[17px] text-zinc-500 mb-6">Add unit's basic details</p>

            <Form {...form}>
                <form onSubmit={form.handleSubmit(onClickAddUnit)}>
                    <div className="flex items-center space-x-2 mt-6">
                        <FormField
                            control={form.control}
                            name="unitNumber"
                            render={({ field }) => (
                                <FormItem className="w-[285px]">
                                    <FormLabel className="mb-2 text-[#09090B]">
                                        Unit Number
                                    </FormLabel>
                                    <FormControl>
                                        <Input placeholder="Enter unit number" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="propertyType"
                            render={({ field }) => {
                                const selectedType = propertyTypeOptions.find(
                                    (type) => type.value === field.value,
                                )?.label;
                                return (
                                    <FormItem>
                                        <FormLabel className="mb-2 text-[#09090B]">
                                            Property Type
                                        </FormLabel>
                                        <Select
                                            onValueChange={(value) =>
                                                form.setValue("propertyType", value)
                                            }
                                            value={field.value}
                                        >
                                            <FormControl className="border shadow-none border-zinc-200 font-normal text-gray-500 hover:text-gray-500  p-5 w-[280px] justify-between flex px-3">
                                                <SelectTrigger>
                                                    <SelectValue
                                                        placeholder={
                                                            !selectedType && "Select property type"
                                                        }
                                                    >
                                                        {selectedType &&
                                                            convertScreamToTitle(selectedType)}
                                                    </SelectValue>
                                                </SelectTrigger>
                                            </FormControl>

                                            <SelectContent>
                                                {propertyTypeOptions.map((type) => (
                                                    <SelectItem
                                                        key={type.value + type.label}
                                                        value={type.value}
                                                    >
                                                        {convertScreamToTitle(type.label)}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />
                    </div>

                    <div className="flex items-center space-x-2 mt-6 mb-10">
                        <FormField
                            control={form.control}
                            name="noOfBedrooms"
                            render={({ field }) => (
                                <FormItem className="flex-1">
                                    <FormLabel className="mb-2 text-[#09090B]">
                                        Number of Bedrooms
                                    </FormLabel>
                                    <FormControl>
                                        <Input placeholder="Enter number of bedrooms" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="unitArea"
                            render={({ field }) => (
                                <FormItem className="flex-1">
                                    <FormLabel className="mb-2 text-[#09090B]">
                                        Unit Area (Square feet)
                                    </FormLabel>
                                    <FormControl>
                                        <Input placeholder="Enter unit area" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>

                    <AutoCompleteAddress
                        control={form.control}
                        watch={form.watch}
                        setValue={form.setValue}
                        autocompleteInputRef={autocompleteInputRef}
                        addressSelected={addressSelected}
                        countries={Countries}
                        setPopoverOpen={setPopoverOpen}
                        popoverOpen={popoverOpen}
                    />

                    <h2 className="font-medium text-lg leading-[22px] text-[#09090B] mb-1">
                        Amenities
                    </h2>
                    <p className="text-sm leading-[17px] text-zinc-500 mb-6">
                        Add amenities of your unit
                    </p>

                    <FormField
                        control={form.control}
                        name="amenities"
                        render={() => (
                            <FormItem className="flex-1 mb-2">
                                <FormLabel className="mb-2 text-[#09090B]">Add amenities</FormLabel>
                                <FormControl>
                                    <div
                                        className="border border-zinc-200 rounded-lg border-solid bg-white overflow-hidden"
                                        style={{
                                            boxShadow:
                                                "0px 2px 4px -2px rgba(71, 85, 105, 0.1), 0px 4px 6px -1px rgba(71, 85, 105, 0.1)",
                                        }}
                                    >
                                        <div className="w-full border-b border-zinc-200 flex">
                                            <div className="px-3 py-2 flex flex-wrap gap-2">
                                                {filteredAddons &&
                                                    filteredAddons
                                                        .filter((addon) =>
                                                            form
                                                                .watch("amenities")
                                                                .includes(addon._id),
                                                        )
                                                        .map((addon) => {
                                                            return (
                                                                <div
                                                                    key={addon._id}
                                                                    className="flex flex-row justify-center items-center gap-1 rounded text-xs leading-[15px] text-neutral-50 px-2 py-1 bg-[#18181B] min-w-max cursor-pointer"
                                                                    onClick={() =>
                                                                        onClickRemoveAddon(
                                                                            addon._id,
                                                                        )
                                                                    }
                                                                >
                                                                    <p>{addon.key}</p>
                                                                    <X
                                                                        className="cursor-pointer"
                                                                        size={16}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                <input
                                                    className="w-full px-3 py-0 max-w-max"
                                                    placeholder={
                                                        form.getValues("amenities").length > 0
                                                            ? ""
                                                            : "Type a facility..."
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>,
                                                    ) => setFilterAddons(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        {filteredAddons
                                            .filter(
                                                (addon) =>
                                                    !form.watch("amenities").includes(addon._id),
                                            )
                                            .map((addon) => {
                                                return (
                                                    <div className="px-3 py-2 flex flex-wrap gap-2">
                                                        <div
                                                            key={addon.key}
                                                            className="gap-2.5 border border-zinc-200 rounded px-2 py-1 border-solid bg-white text-xs leading-[15px] text-[#09090B] max-w-max cursor-pointer"
                                                            onClick={() => onClickAddon(addon._id)}
                                                        >
                                                            {addon.key}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <h2 className="font-medium text-lg leading-[22px] text-[#09090B] mb-1 mt-5">
                        Unit Status
                    </h2>
                    <p className="text-sm leading-[17px] text-zinc-500 mb-6">
                        Select the status of the unit
                    </p>
                    <div className="mb-10">
                        <RadioGroup
                            className="mb-4"
                            onValueChange={onChangeUnitStatus}
                            value={unitStatus}
                        >
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="vacant" id="vacant" />
                                <Label className="!p-0 !m-0 !ml-2" htmlFor="vacant">
                                    Vacant
                                </Label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="occupied" id="occupied" />
                                <Label className="!p-0 !m-0 !ml-2" htmlFor="occupied">
                                    Occupied
                                </Label>
                            </div>
                        </RadioGroup>
                        {unitStatus === "occupied" && (
                            <Fragment>
                                <div className="border border-zinc-200 p-6 mb-2 rounded-md">
                                    <h3 className="font-medium text-sm leading-5 text-[#2B2B2B] !m-0 !mb-6 rounded-md ">
                                        Tenant's Detail
                                    </h3>
                                    {!form.watch("propertyOwner") && !createOwner ? (
                                        <FormField
                                            control={form.control}
                                            name="propertyOwner"
                                            render={() => PropertyOwnerDropdown}
                                        />
                                    ) : form.watch("propertyOwner") && !createOwner ? (
                                        <div className="flex items-center space-x-4">
                                            <div className="rounded-full h-16 w-16 flex items-center justify-center bg-[#F6F8FA]">
                                                {(() => {
                                                    const owner = propertyOwnersData?.data?.find(
                                                        (owner: any) =>
                                                            owner._id ===
                                                            form.watch("propertyOwner"),
                                                    );

                                                    const ownerImage =
                                                        owner?.imageUrl ??
                                                        "https://via.placeholder.com/500x500";

                                                    return ownerImage ? (
                                                        <img
                                                            src={ownerImage}
                                                            alt="Use"
                                                            className="rounded-full object-cover h-16 w-16"
                                                        />
                                                    ) : (
                                                        <UserRound className="text-[#818898]" />
                                                    );
                                                })()}
                                            </div>
                                            <p className="font-medium text-lg text-black">
                                                {(() => {
                                                    const owner = propertyOwnersData?.data?.find(
                                                        (owner: any) =>
                                                            owner._id ===
                                                            form.watch("propertyOwner"),
                                                    );

                                                    const ownerName =
                                                        `${owner?.firstName ?? ""} ${owner?.lastName ?? ""}`.trim();

                                                    return ownerName;
                                                })()}
                                            </p>
                                            <div
                                                className="bg-white h-10 w-10 flex items-center justify-center cursor-pointer rounded-md border border-zinc-200"
                                                onClick={() => {
                                                    form.setValue("propertyOwner", "");
                                                    setCreateOwner(false);
                                                }}
                                            >
                                                <X size={16} />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="border border-zinc-200 p-6">
                                            <FormField
                                                control={form.control}
                                                name="propertyOwnerName"
                                                render={({ field }) => (
                                                    <FormItem className="mb-6">
                                                        <FormLabel className="mb-2 text-[#09090B]">
                                                            Owner Name
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                placeholder="Enter owner name"
                                                                {...field}
                                                            />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                            <div className="flex items-center w-full space-x-8 mb-6">
                                                <FormField
                                                    control={form.control}
                                                    name="propertyOwnerPhoneNumber"
                                                    render={() => (
                                                        <FormItem className="flex-1 relative">
                                                            <FormLabel className="mb-2 text-[#09090B]">
                                                                Phone Number
                                                            </FormLabel>
                                                            <FormControl>
                                                                <div>
                                                                    <PhoneInput
                                                                        country={"gb"}
                                                                        countryCodeEditable={false}
                                                                        placeholder="Enter number"
                                                                        onChange={(value) =>
                                                                            form.setValue(
                                                                                "propertyOwnerPhoneNumber",
                                                                                value,
                                                                            )
                                                                        }
                                                                        inputProps={{
                                                                            required: true,
                                                                        }}
                                                                        inputClass="!pl-[70px] !w-full !h-11 !rounded-md !border !border-solid !border-input !bg-background !px-3 !py-3 !text-sm !ring-offset-background !file:border-0 !file:bg-transparent !file:text-sm !file:font-medium !placeholder:text-muted-foreground !focus-visible:outline-none !focus-visible:ring-2 !focus-visible:ring-ring !focus-visible:ring-offset-2 !disabled:cursor-not-allowed !disabled:opacity-50 flex"
                                                                    />
                                                                </div>
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                                <FormField
                                                    control={form.control}
                                                    name="propertyOwnerEmail"
                                                    render={({ field }) => (
                                                        <FormItem className="flex-1">
                                                            <FormLabel className="mb-2 text-[#09090B]">
                                                                Email
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="Enter owner email"
                                                                    {...field}
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                            </div>
                                            <div className="flex space-x-2 items-center">
                                                <Button
                                                    type="button"
                                                    variant={"outline"}
                                                    onClick={onClickSaveOwner}
                                                >
                                                    Save Owner
                                                    {inviteOwnerLoading && (
                                                        <Spinner
                                                            className="ml-2 text-zinc-500"
                                                            size={"small"}
                                                        />
                                                    )}
                                                </Button>
                                                <Button
                                                    type="button"
                                                    variant={"outline"}
                                                    onClick={() => setCreateOwner(false)}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="border border-zinc-200 p-6 rounded-md">
                                    <h3 className="font-medium text-sm leading-5 text-[#2B2B2B] !m-0 !mb-6 rounded-md ">
                                        Rental Detail
                                    </h3>
                                    <FormField
                                        control={form.control}
                                        name="rent"
                                        render={({ field }) => (
                                            <FormItem className="max-w-[320px] mb-6">
                                                <FormLabel className="mb-2 text-[#09090B]">
                                                    Rent Amount
                                                </FormLabel>
                                                <FormControl>
                                                    <div className="relative">
                                                        <Input
                                                            type={"number"}
                                                            min={0}
                                                            placeholder="1200"
                                                            className="pr-[50px]"
                                                            {...field}
                                                        />
                                                        <p className="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm leading-[17px] text-[rgba(0,0,0,0.88)] bg-white pl-1.5">
                                                            /month
                                                        </p>
                                                    </div>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="securityDeposit"
                                        render={({ field }) => (
                                            <FormItem className="max-w-[320px] mb-6">
                                                <FormLabel className="mb-2 text-[#09090B]">
                                                    Security Deposit
                                                </FormLabel>
                                                <FormControl>
                                                    <div className="relative">
                                                        <Input
                                                            type={"number"}
                                                            min={0}
                                                            placeholder="1200"
                                                            className="pr-[50px]"
                                                            {...field}
                                                        />
                                                        <p className="absolute right-3 top-1/2 transform -translate-y-1/2 text-sm leading-[17px] text-[rgba(0,0,0,0.88)] bg-white pl-1.5">
                                                            /month
                                                        </p>
                                                    </div>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <div>
                                        <FormItem className="max-w-[320px]">
                                            <FormLabel className="mb-2 text-[#09090B]">
                                                Lease Period
                                            </FormLabel>
                                            <FormControl>
                                                <div className="flex space-x-2 items-center">
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <div className="border border-zinc-200 text-sm leading-[17px] text-zinc-500 p-3 rounded-md border-solid cursor-pointer flex items-center justify-between flex-1">
                                                                {form.watch("leasePeriodStart")
                                                                    ? dayjs(
                                                                          form.watch(
                                                                              "leasePeriodStart",
                                                                          ),
                                                                      ).format("DD MMM YYYY")
                                                                    : dayjs().format("DD MMM YYYY")}
                                                                <CalendarIcon size={16} />
                                                            </div>
                                                        </PopoverTrigger>
                                                        <PopoverContent
                                                            className="text-sm w-[350px] p-0"
                                                            align="start"
                                                        >
                                                            <Calendar
                                                                showOutsideDays
                                                                mode="single"
                                                                selected={dayjs(
                                                                    form.watch("leasePeriodStart"),
                                                                ).toDate()}
                                                                onSelect={(e) => {
                                                                    if (!e) return;

                                                                    form.setValue(
                                                                        "leasePeriodStart",
                                                                        e?.toString() ?? "",
                                                                    );
                                                                }}
                                                                initialFocus
                                                                className={
                                                                    "[&_td]:h-10 [&_tr]:h-10 [&_tr]:pt-0"
                                                                }
                                                                weekStartsOn={1}
                                                                fromDate={dayjs().toDate()}
                                                            />
                                                        </PopoverContent>
                                                    </Popover>
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <div className="border border-zinc-200 text-sm leading-[17px] text-zinc-500 p-3 rounded-md border-solid cursor-pointer flex items-center justify-between flex-1">
                                                                {form.watch("leasePeriodEnd")
                                                                    ? dayjs(
                                                                          form.watch(
                                                                              "leasePeriodEnd",
                                                                          ),
                                                                      ).format("DD MMM YYYY")
                                                                    : dayjs()
                                                                          .add(1, "month")
                                                                          .format("DD MMM YYYY")}
                                                                <CalendarIcon size={16} />
                                                            </div>
                                                        </PopoverTrigger>
                                                        <PopoverContent
                                                            className="text-sm w-[350px] p-0"
                                                            align="start"
                                                        >
                                                            <Calendar
                                                                mode="single"
                                                                selected={dayjs(
                                                                    form.watch("leasePeriodEnd"),
                                                                ).toDate()}
                                                                onSelect={(e) => {
                                                                    if (!e) return;

                                                                    form.setValue(
                                                                        "leasePeriodEnd",
                                                                        e?.toString() ?? "",
                                                                    );
                                                                }}
                                                                initialFocus
                                                                className={
                                                                    "[&_td]:h-10 [&_tr]:h-10 [&_tr]:pt-0"
                                                                }
                                                                weekStartsOn={1}
                                                                fromDate={
                                                                    form.watch("leasePeriodStart")
                                                                        ? dayjs(
                                                                              form.watch(
                                                                                  "leasePeriodStart",
                                                                              ),
                                                                          )
                                                                              .add(1, "month")
                                                                              .toDate()
                                                                        : dayjs()
                                                                              .add(1, "month")
                                                                              .toDate()
                                                                }
                                                            />
                                                        </PopoverContent>
                                                    </Popover>
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                    <Button
                        className="mb-16"
                        disabled={
                            createPropertyUnitLoading ||
                            createAddonsLoading ||
                            createContractLoading ||
                            deleteAddonsLoading ||
                            updatePropertyUnitLoading ||
                            updateContractLoading ||
                            isUpdateAddressLoading
                        }
                    >
                        <span className="inline-flex items-center">
                            {(createPropertyUnitLoading ||
                                createAddonsLoading ||
                                createContractLoading ||
                                deleteAddonsLoading ||
                                updatePropertyUnitLoading ||
                                updateContractLoading ||
                                isUpdateAddressLoading) && (
                                <Spinner size={"small"} className="mr-2 text-zinc-100" />
                            )}
                        </span>
                        {unitDetails ? "Update" : "Add"} Unit{" "}
                    </Button>
                </form>
            </Form>
        </section>
    );
};
