import { useGetCallLogsQuery } from "@/redux/rtk-query/aiApis";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useMemo } from "react";

interface CallLogs {
    _id: string;
    call_id: string;
    caller_phonenum: string;
    recording_url: string;
    transcript_summary: string;
    caller_name: string;
    call_type: string;
    call_duration: string;
    call_price: number;
    receipient_phonenum: string;
    contractor_id: string;
    call_start_time: string;
    call_end_time: string;
    call_date_created: string;
    call_date_updated: string;
    call_direction: string;
    call_status: string;
    forwarded_sip: string;
    __v: number;
}

const AssistanceCallRow = (page: number, limit: number) => {
    const { data: user, isLoading: isLoadingUser } = useGetMyDetailsQuery();
    const {
        data: CallLogs,
        isLoading: isLoadingCallLogs,
        isFetching,
    } = useGetCallLogsQuery(
        { contractorId: user?._id ?? "", page, limit },
        { skip: !user?._id, refetchOnMountOrArgChange: true },
    );

    console.log(CallLogs, "pppppp");

    dayjs.extend(duration);
    const AssistanceCallRows = useMemo(() => {
        if (!Array.isArray(CallLogs?.data)) return [];
        return CallLogs?.data?.map((item: CallLogs) => {
            return {
                id: item._id,
                customer: item.caller_name || "-",
                phoneNumber: item.caller_phonenum,
                date: new Date(item.call_start_time).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                }),
                category: item.call_type?.split("_")?.join(" ")?.toLowerCase(),
                messageType: item.call_direction,
                summary: `${item.transcript_summary?.substring(0, 15)}...`,
                duration: `${dayjs.duration(parseInt(item.call_duration), "seconds").format("mm:ss")} m`,
                endDate: new Date(item.call_end_time).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                }),
            };
        });
    }, [CallLogs]);

    return {
        CallLogs: CallLogs?.data,
        user,
        AssistanceCallRows,
        isLoading: isLoadingUser || isLoadingCallLogs || isFetching,
        totalpages: Math.ceil(CallLogs?.total / 10),
        callLogsRoot: CallLogs,
    };
};

export default AssistanceCallRow;
