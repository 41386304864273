import { IConversation } from "@/types";
import { useMemo } from "react";
import { getRecipient } from "../getRecipent";

export const useFilteredConversationList = (
    conversationList: IConversation[] | undefined,
    search: string,
    user: any,
) => {
    return useMemo(() => {
        if (!conversationList || !user) return [];

        if (!search) return conversationList;

        const searchLower = search.toLowerCase();

        return conversationList.filter((conversation) => {
            const recipient = getRecipient(conversation.participantDetails, user);

            if (!recipient) return false;

            const { firstName, lastName } = recipient;

            return (
                firstName.toLowerCase().includes(searchLower) ||
                lastName.toLowerCase().includes(searchLower)
            );
        });
    }, [conversationList, search, user]);
};
