import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { useState } from "react";
import { PropertyForm } from "./PropertyForm";
import { Breadcrumbs } from "./Breadcrumbs";
import { PropertyCreationStep } from "./PropertyCreationStep";
import { UnitAddedList } from "./UnitAddedList";
import { Header } from "@/components/common/headers/Header";
import { UnitForm } from "./UnitForm";

export type TAddPropertyPage = "property" | "units" | "unit-listing";

const AddProperty = () => {
    const [page, setPage] = useState<TAddPropertyPage>("property");
    const [buildingId, setBuildingId] = useState("");
    const [addressId, setAddressId] = useState("");
    const [images, setImages] = useState<string[]>([]);

    const onChangePage = (page: TAddPropertyPage) => {
        setPage(page);
    };

    const { data: myDetails } = useGetMyDetailsQuery();

    return (
        <div className="w-full h-full max-h-screen bg-white overflow-y-auto">
            <Header
                image={myDetails?.imageUrl}
                heading={"Create Property"}
                firstName={myDetails?.firstName}
                lastName={myDetails?.lastName}
                hasSearchBar={true}
            />
            {/* <NewHeader
                heading={"Create Property"}
                image={myDetails?.imageUrl ?? ""}
                username={"User"}
            /> */}
            <div className="ml-6 mt-4 ">
                <Breadcrumbs name={"Add Property"} className="mb-10" />
                <div className="grid grid-cols-[230px_628px] gap-[104px]">
                    <PropertyCreationStep page={page} />
                    {(() => {
                        switch (page) {
                            case "property":
                                return (
                                    <PropertyForm
                                        setBuildingId={setBuildingId}
                                        onChangePage={onChangePage}
                                        setAddressId={setAddressId}
                                        setImages={setImages}
                                    />
                                );
                            case "units":
                                return (
                                    <UnitForm
                                        onChangePage={onChangePage}
                                        buildingId={buildingId}
                                        addressId={addressId}
                                        images={images}
                                    />
                                );
                            case "unit-listing":
                                return (
                                    <UnitAddedList
                                        buildingId={buildingId}
                                        onChangePage={onChangePage}
                                    />
                                );
                        }
                    })()}
                </div>
            </div>
        </div>
    );
};

export default AddProperty;
