import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";

import type {
    ChartData,
    CoreChartOptions,
    DatasetChartOptions,
    ElementChartOptions,
    LineControllerChartOptions,
    PluginChartOptions,
    Point,
    ScaleChartOptions,
} from "chart.js";
import { _DeepPartialObject } from "chart.js/dist/types/utils";
import { useEffect, useRef } from "react";
import type { ChartJSOrUndefined } from "react-chartjs-2/dist/types";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend,
);

type lineChartProps = {
    title: string;
    labels: string[];
    displayScales?: boolean;
    dataPoints?: string[];
};

export function LineChart({
    title,
    labels,
    displayScales = true,
    dataPoints = [],
}: lineChartProps) {
    const chartRef = useRef<ChartJSOrUndefined<"line">>(null);
    const increasingValueColors = {
        borderColor: "rgba(71, 120, 245, 1)",
        bgColor: "rgba(71, 120, 245, 0.1)",
    };
    const descreasingValueColors = {
        borderColor: "rgba(230, 72, 61, 1)",
        bgColor: "rgba(230, 72, 61, 0.1)",
    };

    const isIncreasing = dataPoints[dataPoints.length - 1] > dataPoints[0];
    const borderColor = isIncreasing
        ? increasingValueColors.borderColor
        : descreasingValueColors.borderColor;
    const bgColor = isIncreasing ? increasingValueColors.bgColor : descreasingValueColors.bgColor;

    useEffect(() => {
        const chart = chartRef.current;
        if (chart) {
            const ctx = chart.ctx;
            if (ctx) {
                const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
                gradient.addColorStop(0, bgColor);
                gradient.addColorStop(1, "rgba(255, 255, 255, 0)");

                chart.data.datasets[0].backgroundColor = gradient;
                chart.update();
            }
        }
    }, [labels, bgColor]);
    const options:
        | _DeepPartialObject<
              CoreChartOptions<"line"> &
                  ElementChartOptions<"line"> &
                  PluginChartOptions<"line"> &
                  DatasetChartOptions<"line"> &
                  ScaleChartOptions<"line"> &
                  LineControllerChartOptions
          >
        | undefined = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: { display: displayScales, grid: { drawOnChartArea: false } },
            x: { display: displayScales },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: title.toUpperCase(),
                position: "top",
                align: "start",
                padding: { bottom: 29 },
                font: {
                    family: "Inter",
                    size: 12,
                    weight: "normal",
                },
                color: "#333333",
            },
        },
    };
    const data: ChartData<"line", (number | Point | null)[], unknown> = {
        labels: labels,
        datasets: [
            {
                label: "Monthly Income".toUpperCase(),
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                borderColor: borderColor,
                borderWidth: 1,
                cubicInterpolationMode: "monotone",
                pointRadius: 0,
                fill: true,
            },
        ],
    };
    return <Line ref={chartRef} options={options} data={data} />;
}
