import { memo } from "react";
import styles from "./contractorDetails.module.css";

type TProps = {
    bio: string;
};

export const Bio = memo(({ bio }: TProps) => (
    <div>
        <h3 className={styles.profileDescriptionHeading}>bio</h3>
        <p className={styles.profileDescriptionText}>{bio}</p>
    </div>
));
