export async function catchAll<T>(promise: Promise<T>): Promise<TReturnType<T>> {
    return promise
        .then((data: T) => ({ error: null, data }))
        .catch((err: Error) => ({ error: err, data: null }));
}

type TReturnType<T> =
    | {
          error: null;
          data: T;
      }
    | {
          error: Error;
          data: null;
      };
