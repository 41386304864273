import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { TUser } from "@/types";
import { useGetCompaniesQuery, useUpdateCompanyMutation } from "@/redux/rtk-query/companyApis";
import { toast } from "react-toastify";

const formSchema = z.object({
    companyUrl: z.string().url("Invalid URL").min(1, "Company URL is required"),
    companyNumber: z.string().min(1, "Company number is required"),
    email: z.string().email("Invalid email address").min(1, "Email is required"),
});

type ContactInformationFormProps = {
    user: TUser | undefined;
};

const ContactInformationForm = ({ user }: ContactInformationFormProps) => {
    // const [createCompany, { isLoading }] = useCreateCompanyMutation();
    const [updateCompany, { isLoading: isUpdating }] = useUpdateCompanyMutation();
    const { refetch } = useGetCompaniesQuery(undefined, {
        skip: true,
    });
    // const [updateUserDetail] = useUpdateUserDetailsMutation();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            companyUrl: user?.companyInfo?.companyUrl || "",
            companyNumber: user?.companyInfo?.contactNumber || "",
            email: user?.companyInfo?.email || "",
        },
    });

    async function onSubmit(values: z.infer<typeof formSchema>) {
        try {
            const payload = {
                companyUrl: values.companyUrl,
                companyNumber: values.companyNumber,
                email: values.email,
            };
            await updateCompany({
                _id: user?.companyId,
                ...payload,
            }).unwrap();
            refetch();
        } catch (error) {
            toast.error("Failed to update company details");
        }
    }

    return (
        <>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="max-w-2xl space-y-6">
                    <FormField
                        control={form.control}
                        name="companyUrl"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Organisation URL</FormLabel>
                                <FormControl>
                                    <Input
                                        className="focus-visible:ring-1"
                                        placeholder="https://lightwork.com"
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <div className="flex gap-4">
                        <FormField
                            control={form.control}
                            name="companyNumber"
                            render={({ field }) => (
                                <FormItem className="flex-1">
                                    <FormLabel>Organisation Number</FormLabel>
                                    <FormControl>
                                        <Input
                                            className="focus-visible:ring-1"
                                            placeholder="(603) 555-0123"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem className="flex-1">
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input
                                            className="focus-visible:ring-1"
                                            placeholder="debra.holt@example.com"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>

                    <div className="flex gap-3">
                        <Button type="submit" disabled={!form.formState.isDirty || isUpdating}>
                            {isUpdating ? "Saving" : "Save"}
                        </Button>
                        {(form.getValues()?.companyUrl !== (user?.companyInfo?.companyUrl || "") ||
                            form.getValues()?.companyNumber !==
                                (user?.companyInfo?.contactNumber || "") ||
                            form.getValues()?.email !== (user?.companyInfo?.email || "")) && (
                            <Button type="button" variant="outline" onClick={() => form.reset()}>
                                Cancel
                            </Button>
                        )}
                    </div>
                </form>
            </Form>
        </>
    );
};

export default ContactInformationForm;
