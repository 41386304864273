import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { flexRender } from "@tanstack/react-table";
import React from "react";
import { Skeleton } from "../ui/skeleton";
import Paginations from "../common/pagination/Pagination";
import { Trash2Icon } from "lucide-react";
import { cn } from "@/lib/utils";

interface TableProps {
    totalpages?: number;
    currentPage?: number;
    table: any;
    headerLength: number;
    onClickRow?: (row: any) => void;
    isLoading?: boolean | undefined;
    sortColumn?: string;
    sortDirection?: string;
    setEditResponseClicked?: React.Dispatch<React.SetStateAction<boolean>>;
    editResponseHandler?: (scriptId: string) => void;
    onDeleteScript?: (scriptId: string) => void;
    onPageChange?: (page: number) => void;
    tableCellStyle?: string;
    className?: string;
}

export function DataTable({
    totalpages,
    currentPage,
    table,
    headerLength,
    onClickRow,
    className,
    isLoading,
    sortColumn,
    sortDirection,
    editResponseHandler,
    onDeleteScript,
    onPageChange,
    tableCellStyle,
}: TableProps) {
    return (
        <>
            <div className="rounded-2xl">
                {isLoading ? (
                    <Table className="bg-white">
                        <TableHeader className="hover:bg-none">
                            {table.getHeaderGroups().map((headerGroup: any) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header: any) => {
                                        return (
                                            <TableHead key={header.id} className={className}>
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                          header.column.columnDef.header,
                                                          header.getContext(),
                                                      )}
                                            </TableHead>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableHeader>
                        <TableBody>
                            {[...Array(10)].map((_, index) => (
                                <React.Fragment key={index}>
                                    {table.getHeaderGroups().map((headerGroup: any) => (
                                        <TableRow key={headerGroup.id}>
                                            {headerGroup.headers.map((item: any) => (
                                                <TableCell key={item.id}>
                                                    <Skeleton className="h-[20px] bg-gray-200" />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                ) : !isLoading && !table.getRowModel().rows?.length ? (
                    <Table className="bg-white">
                        <TableHeader className="hover:bg-none">
                            {table.getHeaderGroups().map((headerGroup: any) => (
                                <TableRow key={headerGroup.id} className="hover:bg-white">
                                    {headerGroup.headers.map((header: any) => {
                                        return (
                                            <TableHead key={header.id} className={className}>
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                          header.column.columnDef.header,
                                                          header.getContext(),
                                                      )}
                                            </TableHead>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableHeader>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    colSpan={headerLength}
                                    className="text-center font-semibold"
                                >
                                    No data available
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                ) : (
                    <Table className="bg-white rounded-2xl">
                        <TableHeader className="hover:bg-none">
                            {table.getHeaderGroups().map((headerGroup: any) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header: any) => {
                                        return (
                                            <TableHead key={header.id} className={className}>
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                          header.column.columnDef.header,
                                                          header.getContext(),
                                                      )}
                                                {sortColumn === header.column.id &&
                                                    sortDirection === "asc" &&
                                                    header.column.id !== "createdAt" &&
                                                    " ↑"}
                                            </TableHead>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableHeader>
                        <TableBody>
                            {table.getRowModel().rows.map((row: any) => (
                                <TableRow
                                    onClick={() => onClickRow && onClickRow(row)}
                                    key={row.id}
                                    data-state={row.getIsSelected() && "selected"}
                                    className="cursor-pointer"
                                >
                                    {row.getVisibleCells().map((cell: any) => (
                                        <TableCell
                                            width={cell.column.columnDef.size}
                                            key={cell.id}
                                            className={cn("text-xs py-[1.5rem]", tableCellStyle)}
                                            style={{ width: cell.column.columnDef.size }}
                                        >
                                            {cell.column.columnDef.accessorKey === "response" ? (
                                                flexRender(
                                                    <button
                                                        onClick={() =>
                                                            editResponseHandler &&
                                                            editResponseHandler(
                                                                cell?.row?.original?._id,
                                                            )
                                                        }
                                                        className="py-2 bg-transparent font-semibold"
                                                    >
                                                        Edit Response
                                                    </button>,
                                                    cell.getContext(),
                                                )
                                            ) : cell.column.columnDef.accessorKey === "delete" ? (
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onDeleteScript &&
                                                            onDeleteScript(cell.row.original._id);
                                                    }}
                                                    className="text-red-500 hover:text-red-700"
                                                >
                                                    <Trash2Icon size={18} />
                                                </button>
                                            ) : (
                                                flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext(),
                                                )
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </div>
            {!!table.getPageCount() && totalpages !== undefined && totalpages > 1 && (
                <Paginations
                    table={table}
                    totalPagesOfResponse={totalpages}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                />
            )}
        </>
    );
}
