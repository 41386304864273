import React from "react";
import { Skeleton } from "@/components/ui/skeleton";

interface StatItem {
    label: string;
    value: number | string;
}

interface StatsCardProps {
    stats: StatItem[];
    isLoading?: boolean;
}

const StatsCard: React.FC<StatsCardProps> = ({ stats, isLoading }) => {
    return (
        <div className="flex bg-white drop-shadow-md rounded-lg">
            {stats.map((stat, index) => (
                <React.Fragment key={index}>
                    <div className="flex-1 py-6 px-8">
                        <p className="text-sm text-gray-500 font-medium mb-1">{stat.label}</p>
                        {isLoading ? (
                            <Skeleton className="w-20 h-8 mt-1 rounded bg-gray-200" />
                        ) : (
                            <p className="text-2xl font-bold text-gray-900">{stat.value}</p>
                        )}
                    </div>
                    {index < stats.length - 1 && (
                        <div className="flex items-center justify-center">
                            <div className="border-r border-gray-200 h-16"></div>
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default StatsCard;
