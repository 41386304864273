import React, { ReactNode } from "react";

interface BoxProps {
    children: ReactNode;
    ismap?: boolean;
    classes?: string;
}

const Box: React.FC<BoxProps> = ({ children, ismap, classes }) => {
    return <div className={`${classes} ${ismap ? "p-0" : "p-[10px]"}`}>{children}</div>;
};

export default Box;
