import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { useGetTenantsQuery } from "@/redux/rtk-query/tenantsApis";
import { useMemo, useState } from "react";

const useTenantsRow = () => {
    const [queryParams, setQueryParams] = useState({
        limit: 10,
        skip: 0,
    });
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [sortColumn, setSortColumn] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<string>("asc");

    const { data: user, isLoading } = useGetMyDetailsQuery();
    const { data: tenantsResult, isLoading: isTenantLoading } = useGetTenantsQuery(
        {
            companyId: user?.companyId!,
            limit: queryParams.limit,
            skip: queryParams.skip,
            sortBy: sortColumn,
            search: searchTerm,
        },
        {
            skip: !user?.companyId,
        },
    );

    const tableRowItems = useMemo(() => {
        if (!tenantsResult?.tenants) return [];

        return tenantsResult?.tenants.map((tenant: any) => {
            return {
                id: tenant.id,
                TenantName: tenant.name.fullName,
                email: tenant.email,
                phoneNumber: tenant.phone,
            };
        });
    }, [tenantsResult?.tenants]);

    const handlePageChange = (newPage: number) => {
        setQueryParams((prev) => ({
            ...prev,
            skip: (newPage - 1) * prev.limit,
        }));
    };

    const handleSort = (column: string) => {
        const direction = sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
        let newColumnName = "";
        switch (column) {
            case "TenantName": {
                newColumnName = "firstName";
                break;
            }
            case "email": {
                newColumnName = "email";
                break;
            }
            case "phoneNumber": {
                newColumnName = "phone";
                break;
            }
            default: {
                newColumnName = "createdAt";
            }
        }
        setSortColumn(newColumnName);
        setSortDirection(direction);
    };

    // Frontend search
    // const filteredPropertyRow = useMemo(() => {
    //     const lowercasedSearchTerm = searchTerm.toLowerCase();
    //     return tableRowItems?.filter((row: any) =>
    //         Object.values(row).some((value) =>
    //             String(value).toLowerCase().includes(lowercasedSearchTerm),
    //         ),
    //     );
    // }, [searchTerm, tableRowItems]);

    return {
        tableRowItems,
        user,
        isDataLoading: isTenantLoading || isLoading,
        tenantsList: tenantsResult?.tenants,
        pagination: tenantsResult?.pagination || {
            totalDocs: 0,
            limit: queryParams.limit,
            skip: queryParams.skip,
            totalPages: 0,
            currentPage: 1,
        },
        searchTerm,
        setSearchTerm,
        handlePageChange,
        handleSort,
        sortDirection,
        sortColumn,
    };
};

export default useTenantsRow;
