import { Outlet } from "react-router-dom";
import styles from "./layoout.module.css";
import { useState } from "react";
import Navbar from "../dashboard/tenant/navbar";

export const TenantsLayout = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
            <div className={styles.dashboardlayout}>
                <main>
                    <Outlet />
                </main>
            </div>
        </>
    );
};
