import styles from "./Invoice.module.css";
import { BackButton } from "../../../../../components/common/backButton";
// import { Table } from "../../../../../components/common/table/table";
import { Button } from "@/components/ui/button";
import { CardTitle } from "@/components/ui/card";
import { Switch } from "@/components/ui/switch";
import { ArrowUpDown } from "lucide-react";
import { TaskTables } from "@/components/table/TaskTable";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { Header } from "@/components/common/headers/Header";

export function Invoice() {
    const { data } = useGetMyDetailsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    // const headerItems = [
    //     { key: 1, value: "ID" },
    //     { key: 2, value: "Invoice Recipient" },
    //     { key: 3, value: "Date Sent" },
    //     { key: 4, value: "Status" },
    //     { key: 5, value: "Amount" },
    // ];
    const headerItem = [
        {
            accessorKey: "Id",
            header: ({ column }: any) => {
                return (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        ID
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                );
            },
            cell: ({ row }: any) => {
                const sequenceNumber = row.index + 1;
                return <div className="ml-4">{`#${sequenceNumber}`}</div>;
            },
        },
        {
            accessorKey: "invoiceRecipient",
            header: "Invoice Recipient",
            cell: ({ row }: any) => (
                <div className="capitalize">{row.getValue("invoiceRecipient")}</div>
            ),
        },
        {
            accessorKey: "dateSent",
            header: ({ column }: any) => {
                return (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Date Sent <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                );
            },
            cell: ({ row }: any) => (
                <div className="lowercase ml-4">{row.getValue("dateSent")}</div>
            ),
        },
        {
            accessorKey: "status",
            header: () => <div>Status</div>,
            cell: ({ row }: any) => {
                return <div className="font-medium">{row.getValue("status")}</div>;
            },
        },
        {
            accessorKey: "amount",
            header: () => <div className="">Amount</div>,
            cell: ({ row }: any) => {
                return <div className="font-medium">{row.getValue("amount")}</div>;
            },
        },
    ];

    // const items = [
    //     {
    //         ID: "#ID3172",
    //         "Invoice Recipient": "Jeannie Kerluke",
    //         "Date Sent": "Nov 02, 2024, 3:30 PM",
    //         Status: "Settled",
    //         Amount: "£23,495.49",
    //     },
    //     {
    //         ID: "#ID3172",
    //         "Invoice Recipient": "Jeannie Kerluke",
    //         "Date Sent": "Nov 02, 2024, 3:30 PM",
    //         Status: "Cancelled",
    //         Amount: "£23,495.49",
    //     },
    //     {
    //         ID: "#ID3172",
    //         "Invoice Recipient": "Jeannie Kerluke",
    //         "Date Sent": "Nov 02, 2024, 3:30 PM",
    //         Status: "Pending",
    //         Amount: "£23,495.49",
    //     },
    //     {
    //         ID: "#ID3172",
    //         "Invoice Recipient": "Jeannie Kerluke",
    //         "Date Sent": "Nov 02, 2024, 3:30 PM",
    //         Status: "Settled",
    //         Amount: "£23,495.49",
    //     },
    // ];

    const TableData = {
        data: [{ header: headerItem, row: [], heading: "Invoice" }],
        mainHeading: "Invoices",
    };
    // const rowItems =
    //     items.map((item) => [
    //         { key: "ID", value: item.ID },
    //         { key: "Invoice Recipient", value: item["Invoice Recipient"] },
    //         { key: "Date Sent", value: item["Date Sent"] },
    //         { key: "Status", value: item.Status },
    //         { key: "Amount", value: item.Amount },
    //     ]) || [];
    return (
        <div className=" w-full overflow-y-scroll">
            <Header
                image={data?.imageUrl}
                heading={"Invoices"}
                firstName={data?.firstName}
                lastName={data?.lastName}
                hasSearchBar={true}
            />
            <div className="mx-5 pt-2.5">
                <div>
                    <BackButton />
                </div>
                <div className={styles.header}>
                    <CardTitle className="my-0">Invoices</CardTitle>

                    <Button>Create New Invoice</Button>
                </div>
                <div className={styles.infoContainer}>
                    <div>
                        <div className="flex items-center justify-between">
                            <h6>Enable AI assistant invoicing</h6>
                            <Switch />
                        </div>

                        <p>
                            Save time and effort by letting your AI assistant generate professional
                            invoices automatically
                        </p>
                    </div>
                </div>
                {/* <Table headerItems={headerItems} rowItems={[]} /> */}
                <TaskTables data={TableData?.data || []} />
            </div>
        </div>
    );
}
