import { memo, useCallback } from "react";
import { CloseIcon } from "../../../assets/icons/CloseIcon";
import styles from "./dialog.module.css";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";

type TProps = (TWithFooter | TWithoutFooter) & {
    heading: string;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    content: React.ReactNode;
    isLoading?: boolean;
    cleanUp?: () => void;
};

type TWithFooter = {
    footer: React.ReactNode;
    onClickPrimary?: never;
    primaryButtonLabel?: never;
};

type TWithoutFooter = {
    footer?: never;
    onClickPrimary?: () => void;
    primaryButtonLabel?: string;
};

export const Dialog = memo((props: TProps) => {
    const {
        content,
        heading,
        isOpen,
        setIsOpen,
        cleanUp,
        footer,
        isLoading,
        onClickPrimary,
        primaryButtonLabel,
    } = props;

    const closeDialog = useCallback(() => {
        cleanUp && cleanUp();
        setIsOpen(false);
    }, [cleanUp, setIsOpen]);

    return isOpen ? (
        <div className={styles.dialogBackground}>
            <div className={styles.dialogContainer}>
                <header className={styles.dialogHeader}>
                    <h1 className={styles.dialogHeading}>{heading}</h1>
                    <button className={styles.closeButton} onClick={closeDialog}>
                        <CloseIcon />
                    </button>
                </header>
                {content}
                {!footer && (
                    <footer className={styles.dialogFooter}>
                        <Button variant="secondary" onClick={closeDialog}>
                            Cancel
                        </Button>
                        <Button onClick={onClickPrimary} disabled={isLoading} className="bg-[#000]">
                            {isLoading && <Spinner size="small" className="text-white mr-1" />}{" "}
                            {primaryButtonLabel}
                        </Button>
                    </footer>
                )}
                {footer && <footer>{footer}</footer>}
            </div>
        </div>
    ) : null;
});
