import { memo } from "react";
import { SingleRatingStarIcon } from "../../../assets/icons/SingleRatingStarIcon";
import styles from "./smallJobCard.module.css";

type TProps = {
    size?: "sm" | "lg";
};

export const Rating = memo(({ size = "sm" }: TProps) => (
    <p className={styles.suggestionRatingContainer} style={{ fontSize: size === "sm" ? 12 : 24 }}>
        <span>
            <SingleRatingStarIcon size={size === "sm" ? "12" : "18"} />
        </span>
        <span
            className={styles.suggestionCardRating}
            style={{ marginLeft: size === "sm" ? 1.5 : 6 }}
        >
            4.5
        </span>
        <span className={styles.suggestionCardTotalReviews}>(90)</span>
    </p>
));
