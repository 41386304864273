import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { HiOutlineMapPin } from "react-icons/hi2";
import SearchField from "@/components/dashboard/input/searchField";
import { useGetUserbyTypeQuery } from "@/redux/rtk-query/authApis";
import { Spinner } from "@/components/ui/spinner";
import { Skeleton } from "@/components/ui/skeleton";
import { TUser } from "@/types";

// type User = {
//     stripeConnectVerif: boolean;
//     _id: string;
//     username: string;
//     userType: "CONTRACTOR" | "OTHER_USER_TYPES";
//     taxInfoId: string;
//     createdBy: string;
//     addressId: string;
//     firebaseUid: string;
//     profileId: string;
//     email: string;
//     firstName: string;
//     lastName: string;
//     phone: string;
//     imageUrl: string;
//     providerId: string;
//     companyId: string;
//     status: "ACTIVE" | "INACTIVE"; // Adjust as necessary
//     deleted_at: string | null;
//     __v: number;
//     createdAt: string;
//     updatedAt: string;
// };

interface JobAssignListProps {
    title?: string;
    batch?: string;
    address?: string;
    handleAssignJob?: () => void;
    setSelectedUserId: any;
    selectedUserId: string;
    isJobStatusUpdating?: boolean;
    setJobAssignPopUpOpen?: any;
    setOpen?: any;
}

export const JobAssignList = ({
    title,
    batch,
    address,
    handleAssignJob,
    setSelectedUserId,
    selectedUserId,
    isJobStatusUpdating,
    setJobAssignPopUpOpen,
    setOpen,
}: JobAssignListProps) => {
    const [searchQuery, setSearchQuery] = useState("");

    const { data, isLoading: isUserLoading } = useGetUserbyTypeQuery({
        userType: "CONTRACTOR",
        status: "ACTIVE",
    });

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event?.target?.value?.toLowerCase());
    };

    const filteredContractors = data?.data.filter(
        (contractor: TUser) =>
            contractor.firstName.toLowerCase().includes(searchQuery) ||
            contractor.lastName.toLowerCase().includes(searchQuery) ||
            contractor.username.toLowerCase().includes(searchQuery) ||
            contractor.email.toLowerCase().includes(searchQuery),
    );

    const handleUserClick = (id: string) => {
        setSelectedUserId(id);
    };

    return (
        <div className={"py-6"}>
            <div className="flex flex-col gap-1">
                <div className="flex gap-4 items-center">
                    <h1 className="text-2xl font-semibold">{title}</h1>
                    <Badge className="bg-red-200 text-black h-[1.5rem] hover:bg-red-200">
                        {batch}
                    </Badge>
                </div>
                <p className="text-sm text-gray-500 flex items-center gap-1">
                    <HiOutlineMapPin />
                    {address}
                </p>
            </div>

            <div className="relative mt-4">
                <SearchField handleChange={handleSearchChange} placeholder="Search Contractors" />
            </div>

            <ScrollArea className="mt-4 max-h-72">
                <ul className="space-y-3">
                    {(isUserLoading ? Array.from({ length: 10 }) : filteredContractors)?.map(
                        (contractor: any, index: number) => {
                            return (
                                <li
                                    key={contractor?.id || index}
                                    className={`flex items-center gap-3 py-2 cursor-pointer rounded-lg px-3 w-[95%] ${
                                        selectedUserId && contractor._id === selectedUserId
                                            ? "bg-gray-300"
                                            : ""
                                    }`}
                                    onClick={() => handleUserClick(contractor._id)}
                                >
                                    {isUserLoading ? (
                                        <Skeleton className="h-12 w-12 rounded-full bg-gray-300" />
                                    ) : (
                                        <Avatar className="h-12 w-12">
                                            <AvatarImage
                                                src={contractor?.imageUrl}
                                                alt={contractor?.firstName}
                                            />
                                            <AvatarFallback>{`${contractor?.firstName[0]}${contractor?.lastName[0]}`}</AvatarFallback>
                                        </Avatar>
                                    )}
                                    <div className="flex flex-col gap-1">
                                        {isUserLoading ? (
                                            <>
                                                <Skeleton className="h-4 w-24 bg-gray-300" />
                                                <Skeleton className="h-3 w-32 bg-gray-300" />
                                            </>
                                        ) : (
                                            <>
                                                <p className="font-semibold">
                                                    {contractor?.firstName} {contractor?.lastName}
                                                </p>
                                                <p className="text-xs text-gray-500">
                                                    {contractor?.email}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </li>
                            );
                        },
                    )}
                </ul>
            </ScrollArea>
            <div className="flex justify-between mt-2">
                <Button
                    variant="outline"
                    className="mt-4"
                    onClick={() => {
                        setJobAssignPopUpOpen(false);
                        setOpen(true);
                    }}
                >
                    + Add New Contractor
                </Button>
                <div className="flex justify-end mt-4 gap-2">
                    <Button
                        variant="outline"
                        className="rounded-lg"
                        onClick={() => setJobAssignPopUpOpen(false)}
                    >
                        Cancel
                    </Button>
                    {selectedUserId && (
                        <Button
                            className="bg-[#000] text-white rounded-lg"
                            onClick={handleAssignJob}
                            disabled={isJobStatusUpdating}
                        >
                            {isJobStatusUpdating && <Spinner className="text-white size-5 mr-1" />}
                            Assign Job
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
