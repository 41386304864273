import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useGetMyDetailsQuery } from "../../../redux/rtk-query/authApis";
import { useAppSelector } from "../../../redux/store";
import { UserType } from "../../../utils/interface/auth.interface";
import { AIPromptForm } from "../AIPromptForm";
import { HeadingWithBackButton } from "../HeadingWithBackButton";
import styles from "../homeOwnerPostAJob.module.css";
import { PostAJobNavigation } from "../PostAJobNavigation";
import { PostJobHeader } from "../PostJobHeader";
import { UserAIPrompt } from "../UserAIPrompt";
import { capitalize } from "../../../utils/capitalize";

type TUser = UserType.HOMEOWNER | UserType.TENANT;

type TUserBasedLogic = {
    heading: string;
    onClickContinue: () => void;
    onClickCancel: () => void;
    hasPropertyDropdown: boolean;
    hasDueDate: boolean;
    hasBudget: boolean;
};

const DEFAULT_USER_BASED_LOGIC_OBJ: TUserBasedLogic = {
    hasDueDate: false,
    hasPropertyDropdown: false,
    hasBudget: true,
    heading: "",
    onClickCancel: () => {},
    onClickContinue: () => {},
};

const PostAJobEditPage = () => {
    const navigate = useNavigate();
    const userPrompt = useAppSelector((state) => state.postJob.userPrompt);

    const { data: myDetails } = useGetMyDetailsQuery();

    const UserBasedLogic = useMemo(() => {
        const UserBasedLogic: Record<TUser, TUserBasedLogic> = {
            HOMEOWNER: {
                heading: "Post a job",
                onClickContinue: () => navigate("/home-owner/post-job/confirm"),
                onClickCancel: () => navigate("/home-owner/dashboard"),
                hasPropertyDropdown: false,
                hasDueDate: true,
                hasBudget: true,
            },
            TENANT: {
                heading: "Post a maintenance job",
                onClickContinue: () => navigate("/tenant/post-job/confirm"),
                onClickCancel: () => navigate("/tenant/dashboard"),
                hasPropertyDropdown: true,
                hasDueDate: true,
                hasBudget: false,
            },
        };

        if (!myDetails?.userType) {
            return UserBasedLogic["HOMEOWNER"];
        }
        if (myDetails?.userType !== UserType.HOMEOWNER && myDetails?.userType !== UserType.TENANT) {
            return UserBasedLogic["HOMEOWNER"];
        }

        const userType = myDetails?.userType! as TUser;
        return UserBasedLogic[userType] ?? DEFAULT_USER_BASED_LOGIC_OBJ;
    }, [myDetails?.userType, navigate]);

    const firstName = myDetails?.firstName ?? "";
    const lastName = myDetails?.lastName ?? "";
    const fullName = capitalize(firstName) + " " + capitalize(lastName);

    return (
        <div>
            <PostAJobNavigation hasResult={true} onClickPrimary={UserBasedLogic.onClickContinue} />
            <HeadingWithBackButton heading={UserBasedLogic.heading} />
            <div className={styles.main}>
                <PostJobHeader name={fullName} />
                {userPrompt && <UserAIPrompt userPromptValue={userPrompt} />}
                <AIPromptForm
                    onClickContinue={UserBasedLogic.onClickContinue}
                    onClickCancel={UserBasedLogic.onClickCancel}
                    hasPropertyDropdown={UserBasedLogic.hasPropertyDropdown}
                    hasDueDate={UserBasedLogic.hasDueDate}
                    hasBudget={UserBasedLogic.hasBudget}
                />
                {/* <SuggestedContractors /> */}
            </div>
        </div>
    );
};

export default PostAJobEditPage;
