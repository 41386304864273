import { BankIcon } from "../../../assets/icons/BankIcon";
import styles from "../Settings.module.css";

export const AddAccountCardContainerLeft = () => (
    <div className={styles.addAccountCardContainerLeftContainer}>
        <div className={styles.addAccountCardBankIcon}>
            <BankIcon />
        </div>
        <div>
            <p className={styles.addAccountCardHeading}>Add account</p>
            <p className={styles.addAccountCardText}>Setup your payout account</p>
        </div>
    </div>
);
