import { ReactNode, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import {
    Command,
    CommandInput,
    CommandList,
    CommandGroup,
    CommandItem,
    CommandEmpty,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";

interface LBPopoverDropdownProps<T> {
    value: T | null | undefined;
    onChange: (value: T | null) => void;
    items: T[];
    renderTrigger?: ({ open }: { open?: boolean }) => ReactNode;
    renderSelectedItem?: (value: T, onChange: (value: T | null) => void) => ReactNode;
    renderCommandItem: (item: T) => ReactNode;
    triggerButtonClassName?: string;
    popoverContentClassName?: string;
    commandItemClassName?: string;
    commandInputPlaceholder?: string;
    emptyText: string;
    isItemDisabled?: (item: T) => boolean;
    getItemValue: (item: T) => string;
    storeObject?: boolean;
    disabled?: boolean;
    renderTriggerButton?: boolean;
}

function LBPopoverDropdown<T>({
    value,
    onChange,
    items,
    renderTrigger,
    renderSelectedItem,
    renderCommandItem,
    triggerButtonClassName = "",
    popoverContentClassName = "",
    commandItemClassName = "",
    commandInputPlaceholder = "Search...",
    emptyText,
    isItemDisabled,
    getItemValue,
    storeObject = false,
    disabled,
    renderTriggerButton = true,
}: LBPopoverDropdownProps<T>) {
    const [open, setOpen] = useState(false);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                {renderTriggerButton && (
                    <Button
                        disabled={disabled}
                        type="button"
                        variant="outline"
                        className={cn(triggerButtonClassName, "disabled:cursor-not-allowed ")}
                    >
                        {renderTrigger && renderTrigger({ open })}
                    </Button>
                )}
            </PopoverTrigger>
            {value && renderSelectedItem && renderSelectedItem(value, onChange)}
            <PopoverContent className={`p-0 ${popoverContentClassName}`} side="bottom" align="end">
                <Command>
                    <CommandInput placeholder={commandInputPlaceholder} />
                    <CommandList className="max-h-[200px] overflow-y-auto">
                        <CommandEmpty>{emptyText}</CommandEmpty>
                        <CommandGroup>
                            {items.map((item, index) => {
                                const isDisabled = isItemDisabled ? isItemDisabled(item) : false;
                                return (
                                    <CommandItem
                                        key={index}
                                        onSelect={() => {
                                            if (!isDisabled) {
                                                onChange(
                                                    storeObject
                                                        ? item
                                                        : (getItemValue(item) as any),
                                                );
                                                setOpen(false);
                                            }
                                        }}
                                        className={`py-2 hover:bg-gray-100 transition-all duration-200 ${
                                            isDisabled ? "opacity-50 cursor-not-allowed" : ""
                                        } ${commandItemClassName}`}
                                        disabled={isDisabled}
                                    >
                                        {renderCommandItem(item)}
                                    </CommandItem>
                                );
                            })}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
}

export default LBPopoverDropdown;
