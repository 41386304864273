import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@/components/ui/button";
import styles from "./InviteTeam.module.css";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChevronsUpDown } from "lucide-react";
import { useInviteUsersMutation, useGetMyDetailsQuery } from "../../../redux/rtk-query/authApis";
import InputField from "../../../components/common/input/inputField";
import { Spinner } from "@/components/ui/spinner";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { ChevronUp, ChevronDown } from "lucide-react";
import { UserType } from "@/utils/interface/auth.interface";

interface TeamInviteProps {
    invitedUserData: any[];
    invitedUsers: number;
}

interface FormData {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    userType: string;
    status: string;
    companyId: string;
}

const initialFormData: FormData = {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    userType: "",
    status: "INVITED",
    companyId: "",
};

function getInitials(name: string | undefined): string {
    if (!name) return "";
    return name
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join("");
}

function filterOutTenants(users: any) {
    const filteredUsers = users.filter((user: any) => user.userType !== "Tenant");
    return filteredUsers;
}

function TeamInvite({ invitedUserData, invitedUsers }: TeamInviteProps) {
    const [formData, setFormData] = useState<FormData>({ ...initialFormData });
    const [inviteUsers, { isLoading }] = useInviteUsersMutation();
    const { data: user } = useGetMyDetailsQuery();
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false); // Track dropdown state

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // const handlePhoneNumberChange = (phoneNumber: string) => {
    //     setFormData((prev) => ({ ...prev, phoneNumber }));
    // };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await inviteUsers({ ...formData, companyId: user?.companyId }).unwrap();
            setFormData({ ...initialFormData });
            toast.success("Invite sent successfully");
        } catch (error) {
            toast.error("Failed to send invite");
        }
    };
    const [selectedRole, setSelectedRole] = React.useState<string | null>("Property Manager");
    const handleRoleChange = (userType: string) => {
        const formattedUserType = userType.replace(/\s+/g, "_").toUpperCase();
        setSelectedRole(userType);
        setFormData((prev) => ({ ...prev, userType: formattedUserType }));
    };

    return (
        <div className="flex flex-col items-start w-full h-full border border-zinc-200 shadow-[0px_1px_4px_rgba(0,0,0,0.06),0px_1px_3px_rgba(0,0,0,0.1)] p-4 rounded-2xl border-solid">
            <div className="font-medium text-base leading-[26px] text-black mb-2">
                Add New Members
            </div>
            <div>
                <form className={styles.MainForm} onSubmit={handleSubmit}>
                    <div className={styles.InviteModalHeading}>
                        <label className="font-medium text-sm leading-[17px] text-[#09090B]">
                            Email Address
                        </label>
                        <div className={styles.inputFieldDiv}>
                            <InputField
                                type="email"
                                name="email"
                                placeholder="Enter email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className={styles.InlineFields}>
                        <div className={styles.InviteModalHeading}>
                            <label className="font-medium text-sm leading-[17px] text-[#09090B]">
                                First Name
                            </label>
                            <div className={styles.inputFieldDiv}>
                                <InputField
                                    type="text"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className={styles.InviteModalHeading}>
                            <label className="font-medium text-sm leading-[17px] text-[#09090B]">
                                Last Name
                            </label>
                            <div className={styles.inputFieldDiv}>
                                <InputField
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles.ModalButtonsMain}>
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button
                                    variant="outline"
                                    className="font-normal text-sm leading-[17px] text-zinc-500 w-[70%] flex justify-between items-center"
                                >
                                    {selectedRole || "Role"}
                                    <ChevronsUpDown width={16} height={16} />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="w-56">
                                <DropdownMenuLabel>Choose a role</DropdownMenuLabel>
                                <DropdownMenuSeparator />
                                <DropdownMenuCheckboxItem
                                    checked={selectedRole === "Property Manager"}
                                    onCheckedChange={() => handleRoleChange("Property Manager")}
                                >
                                    Property Manager
                                </DropdownMenuCheckboxItem>
                                <DropdownMenuCheckboxItem
                                    checked={selectedRole === "Contractor"}
                                    onCheckedChange={() => handleRoleChange("Contractor")}
                                >
                                    Contractor
                                </DropdownMenuCheckboxItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                        <Button type="submit" disabled={isLoading} className="w-[120px]">
                            {isLoading ? (
                                <Spinner size="small" className="text-white" />
                            ) : (
                                " Invite Member"
                            )}
                        </Button>
                    </div>
                </form>

                {invitedUsers > 0 && (
                    <>
                        <hr className="w-full mt-6" />
                        <div className="mt-6  overflow-scroll">
                            <p className="font-medium text-sm leading-5 text-[#2B2B2B] mb-4">
                                Pending Invitations({invitedUsers})
                            </p>
                            {filterOutTenants(invitedUserData).map((data: any) => (
                                <div className="flex w-[90%] mb-4 justify-between" key={data.email}>
                                    <div className="flex items-center gap-2">
                                        <Avatar>
                                            {data.imageUrl && typeof data.imageUrl === "string" ? (
                                                <AvatarImage src={data.imageUrl} alt="user" />
                                            ) : (
                                                <AvatarFallback>
                                                    {getInitials(data.name) ?? "AB"}
                                                </AvatarFallback>
                                            )}
                                        </Avatar>
                                        <div>
                                            <div className="font-normal text-sm leading-5 text-black">
                                                {data.email}
                                            </div>
                                            <div className="font-normal text-xs leading-4 text-gray-500">
                                                Invitation Sent: {data.createdAt}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <DropdownMenu
                                        onOpenChange={(isOpen) => setDropdownOpen(isOpen)}
                                    >
                                        <DropdownMenuTrigger asChild>
                                            <Button
                                                variant="ghost"
                                                className="w-[20px] !flex !justify-between !items-center !p-0"
                                            >
                                                {dropdownOpen ? (
                                                    <div className="flex gap-1">
                                                        <p>{`{role}`}</p>
                                                        <ChevronUp width={16} height={14} />
                                                    </div>
                                                ) : (
                                                    <div className="flex gap-1">
                                                        <p>{`{role}`}</p>
                                                        <ChevronDown width={16} height={14} />
                                                    </div>
                                                )}
                                            </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent className="w-56">
                                            <DropdownMenuLabel>Choose a role</DropdownMenuLabel>
                                            <DropdownMenuSeparator />
                                            <DropdownMenuCheckboxItem
                                                checked={selectedRole === "Property Manager"}
                                                onCheckedChange={() =>
                                                    handleRoleChange("Property Manager")
                                                }
                                            >
                                                Property Manager
                                            </DropdownMenuCheckboxItem>
                                            <DropdownMenuCheckboxItem
                                                checked={selectedRole === "Tenant"}
                                                onCheckedChange={() => handleRoleChange("Tenant")}
                                            >
                                                Tenant
                                            </DropdownMenuCheckboxItem>
                                        </DropdownMenuContent>
                                    </DropdownMenu> */}
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default TeamInvite;
