import React from "react";

interface ViewDetailsProps {
    children: React.ReactNode;
    handleClick?: () => void;
}

export const ViewDetails: React.FC<ViewDetailsProps> = (props) => (
    <button onClick={props.handleClick}>
        <span>{props.children}</span>
        <i className="ri-arrow-right-line"></i>
    </button>
);
