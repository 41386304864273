import { useCallback, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Heading } from "../../components/common/pageHeading";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { useGetMyDetailsQuery } from "../../redux/rtk-query/authApis";
import { useGetJobsQuery } from "../../redux/rtk-query/jobsApis";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { TableActions } from "./TableActions";

export const PropertyManagementMaintenance = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [sortColumn, setSortColumn] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<string>("asc");
    const [visibleColumns, setVisibleColumns] = useState<string[]>([
        "id",
        "status",
        "due_data",
        "assigned_to",
        "property_id",
        "priority",
    ]);

    const { data: userDetails } = useGetMyDetailsQuery();
    const { data: allJobs, isLoading } = useGetJobsQuery(
        { type: "ALL", myDetails: userDetails },
        { skip: !userDetails },
    );

    const TableHeadItems = useMemo(
        () =>
            [
                {
                    key: "id",
                    value: (
                        <div className="flex items-center">
                            <p>ID</p>
                        </div>
                    ),
                },
                {
                    key: "status",
                    value: "Status",
                },
                {
                    key: "due_data",
                    value: "Due Date",
                },
                {
                    key: "assisgned_to",
                    value: "Assigned To",
                },
                {
                    key: "property_id",
                    value: "Property ID",
                },
                {
                    key: "priority",
                    value: "Priority",
                },
            ].filter((item) => visibleColumns.includes(item.key)),
        [visibleColumns],
    );

    const handleSort = (column: string) => {
        const direction = sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
        setSortColumn(column);
        setSortDirection(direction);
    };

    const handleToggleColumn = (column: string) => {
        setVisibleColumns((prev) =>
            prev.includes(column) ? prev.filter((col) => col !== column) : [...prev, column],
        );
    };

    const maintenanceJobs = useMemo(() => {
        if (!allJobs) return [];
        return allJobs.map((job, index) => [
            { key: job?._id, value: `${index + 1}` },
            {
                key: job?.jobDetail?.createdAt,
                value: dayjs(job?.jobDetail?.createdAt).format("DD/MM/YYYY"),
            },
            {
                key: job.jobDetail?.description,
                value:
                    job.jobDetail?.description.length > 70
                        ? job.jobDetail?.description.slice(0, 70) + "..."
                        : job.jobDetail?.description,
            },
            { key: "" + index, value: "" },
            { key: "low" + index, value: "low" },
        ]);
    }, [allJobs]);

    const sortedMaintenanceJobs = useMemo(() => {
        if (!maintenanceJobs) return [];
        return maintenanceJobs.slice().sort((a: any, b: any) => {
            if (sortColumn) {
                const aValue = a.find((cell: any) => cell.key === sortColumn)?.value || "";
                const bValue = b.find((cell: any) => cell.key === sortColumn)?.value || "";
                if (sortDirection === "asc") {
                    return aValue.localeCompare(bValue);
                } else {
                    return bValue.localeCompare(aValue);
                }
            }
            return 0;
        });
    }, [maintenanceJobs, sortColumn, sortDirection]);

    const filteredMaintenanceJobs = useMemo(() => {
        return sortedMaintenanceJobs.filter((row) =>
            row.some((cell) => cell.value.toLowerCase().includes(searchTerm.toLowerCase())),
        );
    }, [searchTerm, sortedMaintenanceJobs]);

    const noClickMaintenanceRow = useCallback(
        (rowItem: any) => {
            if (typeof rowItem?.[0]?.value === "string") {
                navigate(`/property-management/jobs/${rowItem[0].key}`);
            }
        },
        [navigate],
    );

    return (
        <div className="max-h-screen overflow-y-auto p-5 pl-[22px] pr-[22px] w-full">
            <header className="mb-10 flex items-center gap-4">
                <Heading as="h1">Maintenance</Heading>
                <p className="font-medium text-xl leading-7 text-[#666d80] flex-grow-0">
                    {filteredMaintenanceJobs.length}
                </p>
            </header>
            <TableActions
                onClickFilter={() => {}}
                onClickSort={handleSort}
                onToggleColumn={handleToggleColumn}
                visibleColumns={visibleColumns}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />
            <div className="relative overflow-x-auto">
                <Table className="bg-white">
                    <TableHeader className="sticky top-0 z-10">
                        <TableRow>
                            {TableHeadItems.map((item) => (
                                <TableHead
                                    key={item.key}
                                    onClick={() => handleSort(item.key)}
                                    className="cursor-pointer bg-transparent"
                                >
                                    {item.value}
                                    {sortColumn === item.key &&
                                        (sortDirection === "asc" ? " ↑" : " ↓")}
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {isLoading ? (
                            [...Array(5)].map((_, index) => (
                                <TableRow key={index}>
                                    {TableHeadItems.map((item) => (
                                        <TableCell key={item.key}>
                                            <Skeleton height={20} />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : filteredMaintenanceJobs.length === 0 ? (
                            <TableRow>
                                <TableCell
                                    colSpan={TableHeadItems.length}
                                    className="text-center font-semibold"
                                >
                                    No data available
                                </TableCell>
                            </TableRow>
                        ) : (
                            filteredMaintenanceJobs.map((row) => (
                                <TableRow
                                    key={row[0].key}
                                    onClick={() => noClickMaintenanceRow(row)}
                                    className="cursor-pointer hover:bg-gray-100"
                                >
                                    {row.map((cell) => (
                                        <TableCell key={cell.key}>{cell.value}</TableCell>
                                    ))}
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};
