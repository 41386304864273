import { useMemo } from "react";

type TProps = {
    direction?: "left" | "right" | "down" | "up";
};

export const ChevronLeftIcon = ({ direction = "left" }: TProps) => {
    const rotationDegrees = useMemo(() => {
        switch (direction) {
            case "left":
                return 0;
            case "right":
                return 180;
            case "down":
                return -90;
            case "up":
                return 90;
        }
    }, [direction]);

    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transform: `rotate(${rotationDegrees}deg)` }}
        >
            <path
                d="M11.8 13.6004L8.20001 10.0004L11.8 6.40039"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
