import { TCreateJobBody } from "../types";

export function getAddressFromGoogleObject(addressMapsObject: google.maps.places.PlaceResult) {
    const longitude = addressMapsObject?.geometry?.location?.lng() || 0;
    const latitude = addressMapsObject?.geometry?.location?.lat() || 0;

    let addressToPass: TCreateJobBody["jobDetails"]["address"] = {
        country: "",
        province: "",
        city: "",
        building: "",
        mainStreet: "",
        latitude,
        longitude,
        addressType: "HOME",
        postalCode: "",
        timezone: "",
        tag: "job-address",
    };

    if (addressMapsObject.address_components) {
        for (const component of addressMapsObject.address_components) {
            if (component.types.includes("country")) {
                addressToPass.country = component.long_name;
            } else if (component.types.includes("route")) {
                addressToPass.mainStreet = component.long_name;
            } else if (component.types.includes("administrative_area_level_1")) {
                addressToPass.province = component.long_name;
            } else if (
                component.types.includes("administrative_area_level_3") ||
                component.types.includes("locality")
            ) {
                addressToPass.city = component.long_name;
            } else if (component.types.includes("postal_code")) {
                addressToPass.postalCode = component.long_name;
            } else if (component.types.includes("premise")) {
                addressToPass.building = addressToPass.building
                    ? component.long_name.concat(", ").concat(addressToPass.building)
                    : component.long_name;
            } else if (component.types.includes("subpremise")) {
                addressToPass.building = addressToPass.building
                    ? addressToPass.building.concat(", ").concat(component.long_name)
                    : component.long_name;
            } else if (component.types.includes("neighborhood")) {
                addressToPass.mainStreet = addressToPass.mainStreet
                    ? component.long_name.concat(", ").concat(addressToPass.mainStreet)
                    : component.long_name;
            } else if (component.types.includes("sublocality_level_2")) {
                addressToPass.mainStreet = addressToPass.mainStreet
                    ? addressToPass.mainStreet.concat(", ").concat(component.long_name)
                    : component.long_name;
            } else if (component.types.includes("sublocality_level_1")) {
                addressToPass.mainStreet = addressToPass.mainStreet
                    ? addressToPass.mainStreet.concat(", ").concat(component.long_name)
                    : component.long_name;
            }
        }
    }
    return addressToPass;
}
