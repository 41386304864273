import { memo } from "react";
import { SortIcon } from "../../assets/icons/SortIcon";
import { WindowIcon } from "../../assets/icons/WindowIcon";
import { InputWithSearchIcon } from "../../components/common/input/InputWithSearchIcon";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";

export type TTableActions = {
    icon: JSX.Element;
    label: string;
    onClick: (() => void) | null;
    customComponent?: JSX.Element;
};
interface SortItem {
    value: string;
    key: string;
}

interface ColumnItem {
    value: string;
    key: string;
}

interface TableActionsProps {
    onClickFilter: () => void;
    onClickSort: (column: string) => void;
    onToggleColumn: (column: string, key: string) => void;
    visibleColumns: string[];
    searchTerm?: string;
    setSearchTerm?: (term: string) => void;
    isSearch?: boolean;
    sortItems: SortItem[];
    columnItems: ColumnItem[];
}

export const TableActions = memo((props: TableActionsProps) => {
    const {
        onClickSort,
        onToggleColumn,
        visibleColumns,
        searchTerm,
        setSearchTerm,
        sortItems,
        columnItems,
        isSearch = true,
    } = props;

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value;
        if (setSearchTerm) {
            setSearchTerm(searchValue);
        }
    };

    const handleSortChange = (value: string) => {
        onClickSort(value);
    };

    const handleColumnChange = (value: string) => {
        const column = columnItems.find((item) => item.value === value);
        if (column) {
            onToggleColumn(column.value, column.key);
        }
    };

    return (
        <div className="flex items-center justify-between">
            <div className="flex items-end gap-3 flex-col xl:flex-row">
                {isSearch && setSearchTerm && (
                    <InputWithSearchIcon
                        value={searchTerm}
                        onChange={handleSearch}
                        placeholder="Search by name..."
                        width={250}
                    />
                )}

                <div className="flex gap-2">
                    <Select value="" onValueChange={handleSortChange}>
                        <SelectTrigger className="flex items-center gap-2">
                            <SortIcon />
                            <SelectValue placeholder="Sort" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectLabel>Sort By</SelectLabel>
                                {sortItems.map((item) => (
                                    <SelectItem key={item.value} value={item.value}>
                                        {item.key}
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                    <Select value="" onValueChange={handleColumnChange}>
                        <SelectTrigger className="flex items-center gap-2">
                            <WindowIcon />
                            <SelectValue placeholder="Columns" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectLabel>Columns</SelectLabel>
                                {columnItems.map((item) => (
                                    <SelectItem key={item.value} value={item.value}>
                                        {item.key} {visibleColumns.includes(item.value) ? "✓" : ""}
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            </div>
        </div>
    );
});
