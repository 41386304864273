import { TJobTabFilter } from "../../../types";
import { TabButton } from "./TabButton";
import styles from "./tabButton.module.css";

export type TTab = {
    id: TJobTabFilter;
    label: string;
};

type TProps = {
    onClickTab: (tabId: TJobTabFilter) => void;
    selectedTab: TJobTabFilter;
    tabs: TTab[];
};
export const TabButtons = (props: TProps) => (
    <div className={styles.homeOwnerTabsContainer}>
        {props.tabs.map((t) => (
            <TabButton
                key={t.id}
                label={t.label}
                onClick={() => props.onClickTab(t.id)}
                selected={t.id === props.selectedTab}
            />
        ))}
    </div>
);
