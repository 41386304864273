import React from "react";

export interface ProgressBarSegment {
    color: string;
    width?: string;
}

export interface DetailsSection {
    indicatorColor: string;
    title: string;
    property?: string;
    amount?: string;
    percentage?: string;
}

export interface CardData {
    key: number;
    headerText: string;
    data: string;
    percentageText: string;
    breakdownText: string;
    breakdownPercentage: string;
    progressBarSegments: ProgressBarSegment[];
    detailsSections: DetailsSection[];
}

interface FinancialCardProps {
    cardData?: CardData;
    children: any;
    heading: string;
}

const ChartBox: React.FC<FinancialCardProps> = ({ children, heading }) => {
    return (
        <div className="bg-white rounded-lg shadow-md p-6 w-full">
            <div className="flex justify-between items-center mb-6">
                <h4 className="text-xl font-semibold leading-none tracking-tight">{heading}</h4>
                <div className="flex gap-2 items-center">
                    {/* <button
                        onClick={() => {}}
                        className="h-9 px-4 rounded-full bg-white text-black border border-gray-200 flex items-center gap-2 hover:bg-gray-50 transition-colors"
                    >
                        <SlCalender className="h-4 w-4" />
                        <span>Last 7 days</span>
                    </button>
                    <button
                        onClick={() => {}}
                        className="h-9 w-9 rounded-full bg-[#000] text-white flex items-center justify-center hover:bg-gray-800 transition-colors"
                    >
                        <HiOutlineViewfinderCircle className="h-5 w-5" />
                    </button> */}
                </div>
            </div>

            <div className="w-full h-64">{children}</div>
        </div>
    );
};

export default ChartBox;
