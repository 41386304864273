import { CSSProperties, memo } from "react";
import styles from "./nothingToSeePlaceholder.module.css";
import { cn } from "@/lib/utils";

type TProps = {
    label?: string;
    height?: CSSProperties["height"];
    width?: CSSProperties["width"];
    border?: CSSProperties["border"];
    className?: string;
};

export const NothingToSeePlaceholder = memo((props: TProps) => {
    const { label, height = 313, border = "" } = props;
    return (
        <main
            style={{ height, border }}
            className={cn(styles.nothingToSeeHereContainer, props.className, "border")}
        >
            <div className="text-[#0d0d12] font-medium text-[20px] leading-[24px] capitalize">
                {label}
            </div>
        </main>
    );
});
