import styles from "./contractorDetails.module.css";
import { TextareaWithLabel } from "../../postAJob/TextareaWithLabel";
import { memo } from "react";
import { ProfileWithPrice } from "../../../components/common/smallJobCard/ProfileWithPrice";
import { Divider } from "../../../components/common/divider";

const DialogJobCard = memo(() => (
    <div>
        <h4 className={styles.dialogJobCardTitle}>Household Fix-It Contractor Needed</h4>
        <p className={styles.dialogJobCardCountry}>West London, UK</p>
        <div className={styles.dialogJobCardChipsContainer}>
            <span
                className={styles.skillChip}
                style={{
                    background: "white",
                }}
            >
                Plumbing
            </span>
            <span className={styles.skillChip}>$500</span>
            <span className={styles.skillChip}>Mar 28, 2024 12 pm</span>
        </div>
    </div>
));

export const DialogContent = memo(() => (
    <div>
        <ProfileWithPrice />
        <Divider top={3} />
        <div className={styles.dialogJobCardContainer}>
            <DialogJobCard />
            <img
                className={styles.dialogJobCardImage}
                src={"https://via.placeholder.com/500x500"}
                alt="job image"
                width={173}
                height={125}
            />
        </div>
        <TextareaWithLabel
            containerProps={{
                style: {
                    marginBottom: 64,
                },
            }}
            labelProps={{
                text: "Add Message",
            }}
            textAreaProps={{
                error: {},
                handleChange: () => {},
                name: "message",
                placeholder: "Type your message",
                required: false,
            }}
        />
    </div>
));
