import styles from "./homeOwnerDashboard.module.css";
import { HomeOwnerCardButton } from "./HomeOwnerCardButton";
import { memo } from "react";

export const HomeOwnerCard = memo(
    ({
        buttonLabel,
        heading,
        subtext,
        onClick,
    }: {
        heading: string;
        subtext: string;
        buttonLabel: string;
        onClick: () => void;
    }) => {
        return (
            <div className={styles.homeOwnerCard}>
                <h3 className={styles.homeOwnerCardHeading}>{heading}</h3>
                <p className={styles.homeOwnerCardText}>{subtext}</p>
                <HomeOwnerCardButton onClick={onClick}>
                    {buttonLabel}{" "}
                    <span className={styles.homeOwnerCardButtonArrow}>
                        <i className="ri-arrow-right-line"></i>
                    </span>
                </HomeOwnerCardButton>
            </div>
        );
    },
);
