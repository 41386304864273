import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useGetCallBookingQuery, useGetConversationsQuery } from "@/redux/rtk-query/aiApis";
import CallDetailsInfo from "./CallDetails";
import { PiArrowLeft } from "react-icons/pi";
import CallerDetails from "./CallerDetails";
import { RightArrowIcon } from "@/assets/icons/RightArrowIcon";

export default function CallDetails() {
    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const { data: messages } = useGetConversationsQuery(
        { callId: state?._id },
        { skip: !state?._id, refetchOnMountOrArgChange: true },
    );
    const { data: callBookingDetails } = useGetCallBookingQuery(
        { callId: state?.call_id },
        { skip: !state?.call_id, refetchOnMountOrArgChange: true },
    );

    return (
        <div className="w-full overflow-hidden">
            <div className="px-8 py-5 bg-white border-b flex items-center gap-6 justify-start">
                <div
                    onClick={handleGoBack}
                    className="cursor-pointer bg-gray-200 rounded-full py-2 px-3 flex items-center gap-1"
                >
                    <PiArrowLeft />
                    <span className="text-sm ">Back</span>
                </div>
                <div className="">
                    <Breadcrumb>
                        <BreadcrumbList>
                            <BreadcrumbItem>
                                <NavLink to="/property-management/dashboard">Home</NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator>
                                {/* <Slash /> */}
                                <RightArrowIcon />
                            </BreadcrumbSeparator>
                            <BreadcrumbItem>
                                <NavLink to="/property-management/ai-assistant">
                                    AI Assistant
                                </NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator>
                                {/* <Slash /> */}
                                <RightArrowIcon />
                            </BreadcrumbSeparator>
                            <BreadcrumbItem>
                                <BreadcrumbPage>Call Detail</BreadcrumbPage>
                            </BreadcrumbItem>
                        </BreadcrumbList>
                    </Breadcrumb>
                </div>
            </div>
            <div className="flex w-full gap-2 overflow-hidden h-screen">
                <section className="flex-1 overflow-y-auto h-[90%] bg-white">
                    <CallDetailsInfo
                        messages={messages}
                        summary={state?.transcript_summary}
                        callDuration={state?.call_duration}
                        callDate={state.call_date_created}
                        reason={state.call_type}
                        audio={state.recording_url}
                        callerName={state?.caller_name}
                        forwardedCallAudio={state?.forwarded_call_recording_url}
                    />
                </section>

                <section className="w-[40%] overflow-y-auto max-h-[700px] py-3">
                    <CallerDetails
                        callerName={state?.caller_name}
                        phoneNumber={state?.caller_phonenum}
                        email={state?.caller_email}
                        jobDetails={callBookingDetails}
                        id={state?.RowId}
                    />
                </section>
            </div>
        </div>
    );
}

// {/* <div className="w-full overflow-x-hidden flex flex-col mt-1 border border-gray-200 rounded-2xl p-2">
//     <div className="flex gap-2 mt-2 mb-5">
//         <Avatar className="h-[3rem] w-[3rem]">
//             <AvatarImage src="" alt="@shadcn" />
//             <AvatarFallback>{<IoCall />}</AvatarFallback>
//         </Avatar>
//         <div>
//             <div className="text-lg">{state?.caller_name || "Unknown"}</div>
//             <div className="text-sm text-gray-600">
//                 {state?.caller_phonenum}
//             </div>
//         </div>
//     </div>

//     <div className="p-2 mt-4 flex gap-3 items-center">
//         <span className="text-sm text-gray-700">Category</span>
//         <Chip
//             text={`${state?.call_type.slice(0, 1) + state?.call_type.slice(1).split("_").join(" ").toLowerCase()}`}
//             color="gray"
//         />
//     </div>
//     <div className="bg-gray-100 p-3 rounded-xl">
//         <h5 className="text-[16px] text-gray-900 mb-2 font-bold">Summary</h5>
//         <p className="text-sm">{state?.transcript_summary}</p>
//     </div>

//     <Separator className="my-4" />

//     <AnimatePresence>
//         <div className="px-5 py-2 font-semibold">Call Transcript</div>
//         {messages?.map((message: any, index: number) => (
//             <motion.div
//                 key={index}
//                 layout
//                 initial={{ opacity: 0, scale: 1, y: 50, x: 0 }}
//                 animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
//                 exit={{ opacity: 0, scale: 1, y: 1, x: 0 }}
//                 transition={{
//                     opacity: { duration: 0.1 },
//                     layout: {
//                         type: "spring",
//                         bounce: 0.3,
//                         duration: index * 0.05 + 0.2,
//                     },
//                 }}
//                 style={{
//                     originX: 0.5,
//                     originY: 0.5,
//                 }}
//                 className={cn(
//                     "flex flex-col gap-2 px-4 py-1 whitespace-pre-wrap",
//                     message.role !== "agent" ? "items-end" : "items-start",
//                 )}
//             >
//                 <div className="flex flex-col">
//                     <span
//                         className={`p-3 max-w-xs ${
//                             message.role !== "agent"
//                                 ? " text-[14px] bg-transparent border border-gray-200 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl rounded-br-none"
//                                 : "text-[14px] bg-accent rounded-tl-2xl rounded-tr-2xl rounded-bl-none rounded-br-2xl"
//                         }`}
//                     >
//                         {message.content}
//                     </span>

//                     {message.timestamp && (
//                         <span
//                             className={`text-xs text-gray-500 mt-2 ${message.role !== "agent" ? "text-end" : "text-start"}`}
//                         >
//                             {format(new Date(message.timestamp), "p")}
//                         </span>
//                     )}
//                 </div>
//             </motion.div>
//         ))}
//     </AnimatePresence>
// </div> */}
