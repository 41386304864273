import React, { useMemo, useState } from "react";

import {
    PieChartData,
    TableHeadItems2,
    TableHeadItemsCompliance2,
    chartConfig,
    graphHeading,
} from "./propertyManagementDashboard.constant";

import { BoxContent } from "@/components/common/Box/BoxContent";
import BoxSegment from "@/components/common/Box/BoxSegment";
import DashBoard from "@/components/common/dashboard/DashBoard";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { useGetPropertiesQuery, useGetPropertyStatsQuery } from "@/redux/rtk-query/properties";
import { updateCardPropertiesDetailsSections } from "@/utils/updateCardPropertiesDetailsSection";
import DashBoardMap from "./DashBoardMap";
import ComplianceRow from "./ui/ComplianceRow";
import MainTainanceRow from "./ui/MainTainanceRow";

// Define the ChartConfig type to be used across different components

interface AnalysisData {
    title: string;
    profit: boolean;
    profitNumber: number;
    data: number;
}

interface BoxDataItem {
    children: React.ReactNode;
    ismap: boolean;
}

const getPropertyStates = (properties: any[] | undefined, type: string) =>
    properties?.filter((property) => property.status === type);

const PropertyDashBoard: React.FC = () => {
    const { data: myDetails, isLoading: isprofileloading } = useGetMyDetailsQuery();
    const companyId = myDetails?.companyId;

    const { data: propertyStats, isLoading: isStateLoading } = useGetPropertyStatsQuery(
        companyId ?? "",
        {
            skip: !myDetails?.companyId,
        },
    );

    const { data: propertiesData, isLoading: isPropertyLoading } = useGetPropertiesQuery(
        myDetails?.companyId!,
        {
            refetchOnFocus: true,
            refetchOnMountOrArgChange: true,
            refetchOnReconnect: true,
            skip: !myDetails?.companyId,
        },
    );

    console.log(myDetails?.imageUrl);

    const bookedProperty = getPropertyStates(propertiesData, "BOOKED");
    const availableProperty = getPropertyStates(propertiesData, "AVAILABLE");
    const notAvailableProperty = getPropertyStates(propertiesData, "NOT_AVAILABLE");

    updateCardPropertiesDetailsSections(bookedProperty, availableProperty, notAvailableProperty);

    // const [, setSearchTerm] = useState("");
    // const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setSearchTerm(event.target.value);
    // };
    const [loading, setLoading] = useState(false);

    // Define the analysis data
    const AnalysisData: AnalysisData[] = [
        {
            title: "Compliance Tasks",
            profit: true,
            profitNumber: 19.5,
            // @ts-ignore
            data: propertyStats?.totalMaintenanceRequests || 0,
        },
        {
            title: "Maintenance Tasks",
            profit: false,
            profitNumber: 20.2,
            // @ts-ignore
            data: propertyStats?.totalMaintenanceRequests || 0,
        },
        {
            title: "Total Properties",
            profit: true,
            profitNumber: 45.5,
            data: propertyStats?.totalBuildings || 0,
        },
    ];

    // Prepare data for BoxSegment
    const BoxData: BoxDataItem[] = [
        ...AnalysisData.map((item) => ({
            children: (
                <BoxContent
                    {...item}
                    isLoading={isStateLoading || isPropertyLoading || isprofileloading || loading}
                />
            ),
            ismap: false,
        })),
        {
            ismap: true,
            children: (
                <DashBoardMap
                    isLoading={isStateLoading || isPropertyLoading || isprofileloading || loading}
                    setLoading={setLoading}
                />
            ),
        },
    ];

    const TableData = {
        data: [
            { header: TableHeadItems2, row: MainTainanceRow(), heading: "Maintenance" },
            { header: TableHeadItemsCompliance2, row: ComplianceRow(), heading: "Compliance" },
        ],
        mainHeading: "Outstanding Tasks",
    };

    const BarchartData = [
        { month: "Sunday", compliance: 0, miscellaneous: 0 },
        { month: "Monday", compliance: 0, miscellaneous: 0 },
        { month: "Tuesday", compliance: 0, miscellaneous: 0 },
        { month: "Wednesday", compliance: 0, miscellaneous: 0 },
        { month: "Thursday", compliance: 0, miscellaneous: 0 },
        { month: "Friday", compliance: 0, miscellaneous: 0 },
    ];

    const totalProperties =
        (propertyStats?.totalPropertiesAvailable ?? 0) +
        (propertyStats?.totalPropertiesOccupied ?? 0);

    const pieChartsData = useMemo(() => {
        const indexToStat: Record<number, number> = {
            0: propertyStats?.totalPropertiesAvailable ?? 0,
            1: propertyStats?.totalPropertiesOccupied ?? 0,
        };

        const pieChartsData = PieChartData.map((data, i) => ({
            ...data,
            stat: indexToStat[i],
        }));

        return pieChartsData;
    }, [propertyStats?.totalPropertiesAvailable, propertyStats?.totalPropertiesOccupied]);

    return (
        <DashBoard
            userimage={myDetails?.imageUrl}
            TableData={TableData}
            graphHeading={graphHeading}
            PiechartData={pieChartsData}
            totalNumber={totalProperties}
            BarChartData={{ BarchartData, chartConfig }}
            accountType="property-management"
        >
            <BoxSegment className="flex-1" BoxData={BoxData} />
        </DashBoard>
    );
};

export default PropertyDashBoard;
