import { CallDetailsCardProps } from "./CallDetailsTypes";
import { CiCalendarDate } from "react-icons/ci";
import { CiClock2 } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import { Badge } from "@/components/ui/badge";
import style from "./callDetail.module.css";
import { cn } from "@/lib/utils";

const CallDetailsCard = (props: CallDetailsCardProps) => {
    return (
        <div>
            <div>
                <h1 className="font-semibold">{props.heading}</h1>
            </div>
            <div
                className={cn(
                    style.cardContainer,
                    "mt-5 rounded-lg drop-shadow-md flex justify-between bg-white py-6 px-5",
                )}
            >
                <div>
                    <span className="flex items-center justify-start gap-1">
                        <CiCalendarDate className="-mt-[3px] font-bold" />
                        <p className="text-sm font-semibold">Call Date & Time</p>
                    </span>
                    <p className="mt-2 text-sm">{props.data.callDate}</p>
                </div>
                <div>
                    <span className="flex items-center justify-start gap-1">
                        <CiClock2 className="-mt-[3px] font-bold" />
                        <p className="text-sm font-semibold">Call Duration</p>
                    </span>
                    <p className="mt-2 text-sm">{props.data.callDuration}</p>
                </div>
                <div>
                    <span className="flex items-center justify-start gap-1">
                        <IoCallOutline className="-mt-[3px] font-bold" />
                        <p className="text-sm font-semibold">Call Reason</p>
                    </span>
                    <Badge className="bg-gray-200 hover:bg-gray-200 text-[#000] mt-2">
                        {props.data.callReason}
                    </Badge>
                </div>
            </div>
        </div>
    );
};

export default CallDetailsCard;
