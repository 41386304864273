import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
    useGetAddressQuery,
    useGetMyDetailsQuery,
    useUpdateUserDetailsMutation,
} from "@/redux/rtk-query/authApis";
import { useGetTenantDetailsQuery } from "@/redux/rtk-query/properties";
import { getFullAddressFromAddressObject } from "@/utils/getFullAddressFromAddressObject";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import ContactCard from "./ContractCard";
import Jobs from "./Jobs";
import RentalDetails from "./RentalDetails";
import { useUpdateTenantsMutation } from "@/redux/rtk-query/tenantsApis";
import { RightArrowIcon } from "@/assets/icons/RightArrowIcon";
import { PiArrowLeft } from "react-icons/pi";

export default function TenantDetails() {
    const { tenantId } = useParams();
    const [rentalModalon, setRentalModalOn] = useState(false);
    const navigate = useNavigate();

    const { data: tenantData, isLoading } = useGetTenantDetailsQuery(tenantId, {
        skip: !tenantId,
    });

    const { data: addressData, isLoading: isAddressLoading } = useGetAddressQuery(
        tenantData?.propertyDetails?.addressId ?? "",
        {
            skip: !tenantData?.propertyDetails?.addressId,
        },
    );

    const [address] = useMemo(() => {
        if (!addressData) return ["", true];
        return [getFullAddressFromAddressObject(addressData), false];
    }, [addressData]);

    const [updateTenants, { isLoading: isTenantupdating }] = useUpdateTenantsMutation();
    const [updateUserDetail] = useUpdateUserDetailsMutation();

    const onClickSubmitRentalDetails = async (data: {
        rentAmount: string;
        securityDeposit: string;
        startPeriod: string;
        endPeriod: string;
    }) => {
        const payload = {
            _id: tenantData.tenancyContracts._id,
            rent: Number(data.rentAmount),
            securityDeposit: Number(data.securityDeposit),
            startDate: dayjs(data.startPeriod).format("YYYY-MM-DD"),
            endDate: dayjs(data.endPeriod).format("YYYY-MM-DD"),
        };
        await updateTenants(payload);
        setRentalModalOn(false);
    };
    const onClickSubmitContractDetails = (data: { name: string; email: string }) => {
        const [firstName, ...lastNameParts] = data.name.split(" ");
        const lastName = lastNameParts.join(" ");
        const payload = {
            _id: tenantData._id,
            firstName: firstName || "",
            lastName: lastName || "",
            email: data.email,
            //these fields do not exist in the backend
            // DOB: dayjs(data.DOB).format("YYYY-MM-DD"),

            // phoneNumber: data.phoneNumber,
        };

        updateUserDetail(payload);
    };
    return (
        <ScrollArea className="p-3 h-screen w-full">
            <div className="py-3 flex items-center gap-6">
                <div
                    onClick={() => navigate(-1)}
                    className="cursor-pointer bg-gray-200 rounded-full py-2 px-3 flex items-center gap-1"
                >
                    <PiArrowLeft />
                    <span className="text-sm ">Back</span>
                </div>
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <NavLink to="/property-management/dashboard">Home</NavLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator>
                            {/* <Slash /> */}
                            <RightArrowIcon />
                        </BreadcrumbSeparator>
                        <BreadcrumbItem>
                            <NavLink to="/property-management/tenants">Tenant</NavLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator>
                            {/* <Slash /> */}
                            <RightArrowIcon />
                        </BreadcrumbSeparator>
                        <BreadcrumbItem>
                            <BreadcrumbPage>Tenant Details</BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
            </div>

            <div className="flex flex-col gap-3 pb-10 md:flex-row items-center md:items-start ">
                <div className="space-y-3">
                    {tenantData && (
                        <ContactCard
                            onClickSubmit={onClickSubmitContractDetails}
                            name={`${tenantData?.firstName ?? ""} ${tenantData?.lastName ?? ""}`}
                            location={address ?? ""}
                            email={tenantData?.email ?? ""}
                            isLoading={isAddressLoading || isLoading}
                            createBuildingLoading={isTenantupdating}
                        />
                    )}

                    <RentalDetails
                        setOpen={setRentalModalOn}
                        Open={rentalModalon}
                        onClickSubmit={onClickSubmitRentalDetails}
                        isLoading={isAddressLoading || isLoading || isTenantupdating}
                        tenure={tenantData?.tenancyContracts?.tenure ?? ""}
                        remainingDuration={
                            tenantData?.tenancyContracts?.endDate &&
                            tenantData?.tenancyContracts?.startDate
                                ? (() => {
                                      const start = dayjs(tenantData.tenancyContracts.startDate);
                                      const end = dayjs(tenantData.tenancyContracts.endDate);

                                      if (!start.isValid() || !end.isValid()) {
                                          return "";
                                      }

                                      const years = end.diff(start, "year");
                                      const months = end.diff(start.add(years, "year"), "month");
                                      const days = end.diff(
                                          start.add(years, "year").add(months, "month"),
                                          "day",
                                      );

                                      const parts = [];
                                      if (years > 0) {
                                          parts.push(`${years} year${years > 1 ? "s" : ""}`);
                                      }
                                      if (months > 0) {
                                          parts.push(`${months} month${months > 1 ? "s" : ""}`);
                                      }
                                      if (days > 0) {
                                          parts.push(`${days} day${days > 1 ? "s" : ""}`);
                                      }

                                      return parts.join(", ") || "0 days";
                                  })()
                                : ""
                        }
                        rentAmount={`£${tenantData?.tenancyContracts?.rent ?? 0}`}
                        rentDueDate={
                            tenantData?.tenancyContracts?.startDate
                                ? `${(() => {
                                      const day = dayjs(
                                          tenantData?.tenancyContracts?.startDate,
                                      ).date();
                                      if (day === 1) return `1st of every month`;
                                      if (day === 2) return `2nd of every month`;
                                      if (day === 3) return `3rd of every month`;
                                      return `${day}th of every month`;
                                  })()}`
                                : "1st of every month"
                        }
                        rentStatus={tenantData?.tenancyContracts?.status ?? ""}
                        rentPeriod={
                            tenantData?.tenancyContracts?.startDate &&
                            tenantData?.tenancyContracts?.endDate
                                ? `${dayjs(tenantData.tenancyContracts.startDate).format(
                                      "DD MMM, YYYY",
                                  )} - ${dayjs(tenantData.tenancyContracts.endDate).format(
                                      "DD MMM, YYYY",
                                  )}`
                                : ""
                        }
                        propertyType="House"
                        location={address}
                        addressObject={addressData}
                        postalCode="E1 6AN"
                        chartProps={
                            tenantData?.tenancyContracts?.startDate &&
                            tenantData?.tenancyContracts?.endDate && {
                                remainingMonths: (() => {
                                    const end = dayjs(tenantData.tenancyContracts.endDate);
                                    const start = dayjs();

                                    if (!start.isValid() || !end.isValid()) {
                                        return 0;
                                    }

                                    return end.diff(start, "month");
                                })(),

                                totalMonths: (() => {
                                    const start = dayjs(tenantData.tenancyContracts.startDate);
                                    const end = dayjs(tenantData.tenancyContracts.endDate);

                                    if (!start.isValid() || !end.isValid()) {
                                        return 0;
                                    }

                                    return end.diff(start, "month");
                                })(),
                            }
                        }
                        propertyImages={tenantData?.propertyDetails?.images}
                        tenantData={tenantData?.tenancyContracts}
                    />
                </div>
                <Jobs
                    jobs={tenantData?.propertyDetails?.jobs}
                    isLoading={isLoading || isAddressLoading}
                />
            </div>
        </ScrollArea>
    );
}

// ): <div className="w-full h-[85vh] border border-black rounded-lg flex justify-center items-center">No Data Found</div>}
