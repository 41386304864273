import { Menu } from "@mantine/core";
import { Fragment, memo, useMemo, useRef, useState } from "react";
import { DefaultTarget } from "./DefaultTarget";
import styles from "./Dropdown.module.css";
import { TDropdownProps } from "./Dropdown.types";

export const DropdownZIndex = {
    MENU: 10000,
    MASK: 10001,
    DROPDOWN: 10002,
};

export const Dropdown = memo(({ width = 200, ...props }: TDropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const targetRef = useRef<HTMLDivElement>(null);

    const target = useMemo(() => {
        if (Object.hasOwn(props, "value")) {
            const value = props?.value ? props.value : props.placeholder ? props.placeholder : "";

            return <DefaultTarget value={value} labelProps={props.labelProps} />;
        } else return props.children;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.value, props?.placeholder, props?.children]);

    return (
        <Menu opened={isOpen} width={width} position="bottom-start" zIndex={DropdownZIndex.MENU}>
            <Menu.Target>
                <div ref={targetRef} onClick={() => setIsOpen(true)} style={{ cursor: "pointer" }}>
                    {target}
                </div>
            </Menu.Target>

            {isOpen && props.items && props.items.length > 0 && (
                <div
                    onClick={() => setIsOpen(false)}
                    className={styles.mask}
                    style={{ zIndex: DropdownZIndex.MASK }}
                ></div>
            )}

            <Menu.Dropdown
                classNames={{ dropdown: styles.dropdown }}
                style={{
                    zIndex: DropdownZIndex.DROPDOWN,
                    visibility: props.items && props.items.length > 0 ? "visible" : "hidden",
                }}
            >
                {props.items &&
                    props.items.map(({ key, hasDividerAbove = false, value }) => (
                        <Fragment key={value}>
                            <Menu.Item
                                onClick={() => {
                                    props.onClick && props.onClick({ key, hasDividerAbove, value });
                                    setIsOpen(false);
                                }}
                                classNames={{
                                    item: styles.dropdownItem,
                                }}
                                styles={{
                                    item: {
                                        borderTop: hasDividerAbove ? "1px solid #DCDAE2" : "",
                                    },
                                }}
                            >
                                {key}
                            </Menu.Item>
                        </Fragment>
                    ))}
            </Menu.Dropdown>
        </Menu>
    );
});
