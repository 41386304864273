import { Label, Pie, PieChart } from "recharts";
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart";
import { useMemo } from "react";
import { Skeleton } from "@/components/ui/skeleton";

const chartConfig = {
    months: {
        label: "Remaining",
        color: "blue",
    },
    other: {
        label: "Pasted",
        color: "hsl(var(--chart-5))",
    },
} satisfies ChartConfig;

export type TRentalChartProps = {
    remainingMonths: number;
    totalMonths: number;
    remainingTimeLabel: string;
    isLoading?: boolean;
};

export default function RentalChart({
    remainingMonths = 0,
    totalMonths = 0,
    remainingTimeLabel,
    isLoading = false,
}: TRentalChartProps) {
    const chartData = useMemo(() => {
        const elapsedMonths = totalMonths - remainingMonths;

        return [
            { name: "Total Months", data: elapsedMonths, fill: "gray" },
            {
                name: "Months Left",
                data: remainingMonths,
                fill: "#22BCFF",
            },
        ];
    }, [remainingMonths, totalMonths]);

    return (
        <div className="flex flex-col">
            <div className="flex-1 pb-0">
                {isLoading ? (
                    <Skeleton className="mx-auto aspect-square max-h-[200px] rounded-full my-[1.60rem] border-[20px] border-gray-300 border-opacity-70 bg-transparent" />
                ) : (
                    <ChartContainer
                        config={chartConfig}
                        className="mx-auto aspect-square max-h-[250px]"
                    >
                        <PieChart>
                            <ChartTooltip
                                cursor={false}
                                content={<ChartTooltipContent hideLabel />}
                            />
                            <Pie
                                data={chartData}
                                dataKey="data"
                                nameKey="name"
                                innerRadius={75}
                                strokeWidth={3}
                            >
                                <Label
                                    content={({ viewBox }) => {
                                        if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                                            return (
                                                <text
                                                    x={viewBox.cx}
                                                    y={viewBox.cy}
                                                    textAnchor="middle"
                                                    dominantBaseline="middle"
                                                >
                                                    <tspan
                                                        x={viewBox.cx}
                                                        y={(viewBox.cy || 0) - 24}
                                                        className="fill-muted-foreground"
                                                    >
                                                        Remaining
                                                    </tspan>
                                                    <tspan
                                                        x={viewBox.cx}
                                                        y={viewBox.cy}
                                                        className="fill-foreground  font-bold"
                                                    >
                                                        {remainingTimeLabel}
                                                    </tspan>
                                                </text>
                                            );
                                        }
                                        return null; // Return null if viewBox is not valid
                                    }}
                                />
                            </Pie>
                        </PieChart>
                    </ChartContainer>
                )}
            </div>
        </div>
    );
}
