import { useEffect, useRef, useState } from "react";

interface NavDropdownProps {
    children: React.ReactNode;
    label?: string;
    icon: any;
    button?: boolean;
}

function NavDropdown({ children, label, icon, button }: NavDropdownProps) {
    const [isVisible, setIsVisible] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleMouseEnter = () => {
        if (!button) {
            setIsVisible(true);
        }
    };

    const handleMouseLeave = () => {
        if (!button) {
            setIsVisible(false);
        }
    };

    const toggleDropdown = () => {
        if (button) {
            setIsVisible(!isVisible);
        }
    };
    return (
        <div ref={dropdownRef} onMouseLeave={handleMouseLeave}>
            <div
                onClick={toggleDropdown}
                onMouseEnter={handleMouseEnter}
                className="cursor-pointer"
            >
                <span>{label}</span>
                <span>{icon}</span>
                {isVisible && children}
            </div>
        </div>
    );
}

export default NavDropdown;
