import { IConversation, TUser } from "@/types";
import { getRecipient } from "@/utils/getRecipent";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

type TProps = {
    conversation: IConversation;
    currentUser?: TUser;
    onClickUser: Function;
    isSelected: boolean;
    dashboard?: boolean;
};

export const UserCard = ({
    conversation,
    isSelected = false,
    currentUser,
    onClickUser,
}: TProps) => {
    const recipientUser = getRecipient(conversation.participantDetails, currentUser);

    return (
        <div
            onClick={() => {
                onClickUser(conversation._id);
            }}
            className={`cursor-pointer ${cn(
                buttonVariants({
                    variant: "ghost",
                    size: "xl",
                }),
                "justify-start transition-all duration-200 gap-4 px-3 rounded-2xl bg-gray-50",
                isSelected
                    ? "shadow-md bg-gray-100 border-none dark:bg-gray-800"
                    : "hover:shadow-md transition-all duration-200 dark:hover:bg-gray-700",
                "group",
            )}`}
        >
            <Avatar className="h-[2.7rem] w-[2.7rem]">
                <AvatarImage src="" alt="@shadcn" />
                <AvatarFallback className="text-sm text-white bg-neutral-700">
                    {recipientUser?.firstName.slice(0, 1).toUpperCase()}
                    {recipientUser?.lastName.slice(0, 1).toUpperCase()}
                </AvatarFallback>
            </Avatar>
            <div className="flex flex-col max-w-28">
                <span className="text-[13px] font-normal text-gray-900 dark:text-gray-100 group-hover:text-gray-700 dark:group-hover:text-gray-300">
                    {recipientUser?.firstName.slice(0, 1).toUpperCase()}
                    {recipientUser?.firstName.slice(1)} {""}
                    {recipientUser?.lastName.slice(0, 1).toUpperCase()}
                    {recipientUser?.lastName.slice(1)}
                </span>
                <div className="text-xs w-[150px] truncate font-normal text-gray-500 dark:text-gray-400 group-hover:text-gray-600 dark:group-hover:text-gray-300">
                    {/* Last message content */}
                </div>
            </div>
        </div>
    );
};
