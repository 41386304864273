import styles from "./account.module.css";
import { useCallback, useEffect, useState } from "react";
import { Heading } from "../../../components/common/pageHeading";
import { TUser } from "../../../types";
import { InputWithLabel } from "../../postAJob/InputWithLabel";
import { TextareaWithLabel } from "../../postAJob/TextareaWithLabel";
import {
    useCreateCompanyMutation,
    useUpdateCompanyMutation,
} from "../../../redux/rtk-query/companyApis";
import { UploadImage } from "./UploadImage";
import SelectField from "../../../components/common/input/selectField";
import { IOption } from "../../../utils/constantData";
import Label from "../../../components/common/label";
import {
    useGetAddressQuery,
    useUpdateAddressMutation,
    useUpdateUserDetailsMutation,
} from "../../../redux/rtk-query/authApis";
import useGoogleAutocomplete from "../../../hooks/useGoogleAutocomplete";
import { Dropdown } from "../../../components/common/dropdown";
import { Button } from "@/components/ui/button";

type TProp = {
    user: TUser | undefined;
};

interface CompanyFormData {
    companyName: string;
    companyContact: string;
    companyUrl: string;
    companyBio: string;
    address: string;
    province: string;
    city: string;
    postalCode: string;
    building: string;
    addressType: string;
    companySize: string;
    type: string;
}

const options: IOption[] = [
    { value: "1-10", label: "1-10 employees" },
    { value: "11-50", label: "11-50 employees" },
    { value: "51-200", label: "51-200 employees" },
    { value: "201-500", label: "201-500 employees" },
    { value: "501-1000", label: "501-1000 employees" },
    { value: "1000+", label: "More than 1000 employees" },
];

const TYPE = [
    { key: "Contractor", value: "CONTRACTOR" },
    { key: "Property Management", value: "PROPERTY_MANAGEMENT" },
    { key: "Sole Proprietary", value: "SOLE_PROPRIETORY" },
    { key: "Supplier Company", value: "SUPPLIER_COMPANY" },
];

const ADDRESS_TYPE = [
    { key: "Work", value: "WORK" },
    { key: "Home", value: "HOME" },
    { key: "Job", value: "JOB" },
];

export const CompanySettings = ({ user }: TProp) => {
    const [createCompany, { isLoading }] = useCreateCompanyMutation();
    const [updateCompany, { isLoading: isUpdating }] = useUpdateCompanyMutation();
    const [updateAddress] = useUpdateAddressMutation();
    const [updateUserDetail] = useUpdateUserDetailsMutation();
    const [profileImage, setProfileImage] = useState<string | undefined>(
        user?.companyInfo?.imageUrl,
    );

    const [formData, setFormData] = useState<CompanyFormData>({
        companyName: "",
        companyContact: "",
        companyUrl: "",
        companyBio: "",
        address: "",
        province: "",
        city: "",
        postalCode: "",
        addressType: "",
        building: "",
        companySize: "",
        type: "",
    });

    const { data: addressData } = useGetAddressQuery(user?.companyInfo?.addressId, {
        skip: !user?.companyInfo?.addressId,
    });

    const { autocompleteInputRef, place } = useGoogleAutocomplete({
        name: "mainStreet",
        handleUserDetails: (e) => {
            setFormData((prevState) => ({ ...prevState, address: e.target.value }));
        },
        showCompleteDetails: true,
    });

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
            const { name, value } = e.target;
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        },
        [],
    );

    const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    }, []);

    const handleDropdownClick = useCallback(
        (type: string) => (selectedItem: { value: string }) => {
            setFormData((prevData) => ({ ...prevData, [type]: selectedItem.value }));
        },
        [],
    );

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const payload = {
                name: formData.companyName,
                // contact: formData.companyContact,
                size: formData.companySize,
                // url: `https://${formData.companyUrl}`,
                status: "ACTIVE",
                type: formData.type,
                bio: formData.companyBio,
                imageUrl: profileImage,
                address: {
                    addressType: formData?.addressType,
                    mainStreet: formData?.address,
                    addressData: formData?.address,
                    building: formData?.building,
                    country: place?.formatted_address
                        ? place?.formatted_address?.split(",").slice(-1).join("").trim()
                        : "",
                    city: formData?.city,
                    postalCode: formData?.postalCode,
                    latitude: place?.geometry?.location?.lat()
                        ? place?.geometry?.location?.lat().toString().substring(0, 4)
                        : "",
                    longitude: place?.geometry?.location?.lng()
                        ? place.geometry?.location?.lng().toString().substring(0, 4)
                        : "",
                    province: formData?.province,
                    timezone: addressData?.timezone,
                    tag: addressData?.tag,
                },
            };

            if (user?.companyInfo) {
                await updateAddress({
                    _id: user?.companyInfo?.addressId,
                    addressType: formData?.addressType,
                    mainStreet: formData.address,
                    building: formData?.building,
                    country: place?.formatted_address
                        ? place?.formatted_address?.split(",").slice(-1).join("").trim()
                        : "",
                    city: formData?.city,
                    postalCode: formData?.postalCode,
                    province: formData?.province,
                    timezone: addressData?.timezone,
                    latitude: place?.geometry?.location?.lat() ?? 0,
                    longitude: place?.geometry?.location?.lng() ?? 0,
                });

                const { address, ...payloadWithoutAddress } = payload;

                await updateCompany({
                    ...payloadWithoutAddress,
                    _id: user?.companyId,
                }).unwrap();
            } else {
                const data = await createCompany(payload).unwrap();
                const userPayload = {
                    companyId: data._id,
                };
                await updateUserDetail(userPayload).unwrap();
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (user?.companyInfo && addressData) {
            setFormData({
                companyName: user?.companyInfo?.name || "",
                companyContact: user.lastName,
                companyUrl: "dev.lightwork.blue",
                companyBio: user?.companyInfo?.bio || "",
                companySize: user?.companyInfo?.size || "",
                type: user?.companyInfo?.type || "",
                address: addressData?.mainStreet || "",
                building: addressData?.building || "",
                province: addressData?.province || "",
                city: addressData?.city || "",
                postalCode: addressData?.postalCode || "",
                addressType: addressData?.addressType || "",
            });
            setProfileImage(user.companyInfo?.imageUrl || "https://via.placeholder.com/500x500");
        }
    }, [addressData, user]);

    return (
        <section>
            <Heading bottom={58}>Company Settings</Heading>
            <div className={styles.profileImageContainer}>
                <UploadImage
                    profileImage={profileImage}
                    setProfileImage={setProfileImage}
                    title={"Change Logo"}
                />
            </div>
            <form className={styles.form} onSubmit={handleSubmit}>
                <InputWithLabel
                    labelProps={{ text: "Company Name" }}
                    inputProps={{
                        name: "companyName",
                        placeholder: "Enter company name",
                        value: formData.companyName,
                        onChange: handleChange,
                    }}
                />
                <InputWithLabel
                    labelProps={{ text: "Company Contact" }}
                    inputProps={{
                        name: "companyContact",
                        placeholder: "Enter contact details",
                        value: formData.companyContact,
                        onChange: handleChange,
                    }}
                />
                <div className={styles.companyURLContainer}>
                    <div className={styles.https}>https://</div>
                    <InputWithLabel
                        labelProps={{ text: "Company Url" }}
                        inputProps={{
                            name: "companyUrl",
                            placeholder: "Enter company URL",
                            value: formData.companyUrl,
                            onChange: handleChange,
                            style: { borderRadius: "0px 8px 8xp 0px" },
                        }}
                        inputContainerProps={{
                            style: { marginLeft: 87, width: "calc(100% - 87px)" },
                        }}
                    />
                </div>
                <Dropdown
                    items={TYPE}
                    placeholder="Type"
                    value={TYPE.find((type) => type.value === formData.type)?.key ?? ""}
                    labelProps={{ text: "Type" }}
                    onClick={handleDropdownClick("type")}
                />
                <InputWithLabel
                    labelProps={{ text: "Address" }}
                    inputProps={{
                        name: "address",
                        placeholder: "Enter your Address",
                        type: "text",
                        value: formData.address,
                        onChange: handleChange,
                        onKeyDown: handleKeyDown,
                    }}
                    ref={autocompleteInputRef}
                />
                <InputWithLabel
                    labelProps={{ text: "Building" }}
                    inputProps={{
                        name: "building",
                        placeholder: "Building",
                        handleChange,
                        value: formData.building,
                    }}
                />
                <InputWithLabel
                    labelProps={{ text: "Province" }}
                    inputProps={{
                        required: true,
                        name: "province",
                        placeholder: "Province",
                        handleChange,
                        value: formData.province,
                    }}
                />
                <div className={styles.InlineFields}>
                    <InputWithLabel
                        labelProps={{ text: "City" }}
                        inputProps={{
                            required: true,
                            name: "city",
                            placeholder: "City",
                            handleChange,
                            value: formData.city,
                        }}
                    />
                    <InputWithLabel
                        labelProps={{ text: "Post Code" }}
                        inputProps={{
                            required: true,
                            name: "postalCode",
                            placeholder: "Post Code",
                            handleChange,
                            value: formData.postalCode,
                        }}
                    />
                </div>

                <Dropdown
                    items={ADDRESS_TYPE}
                    placeholder="Address Type"
                    value={
                        ADDRESS_TYPE.find((address) => address.value === formData.addressType)
                            ?.key ?? ""
                    }
                    labelProps={{ text: "Address Type" }}
                    onClick={handleDropdownClick("addressType")}
                />
                <div className={styles.inputSearchDiv}>
                    <Label {...{ text: "Company Size", bottom: 10 }} type="plain-black" />
                    <SelectField
                        name="companySize"
                        placeholder="Company Size"
                        isActive={
                            (formData.companySize && formData.companySize.length > 0) ?? false
                        }
                        options={options}
                        value={formData.companySize}
                        error={{}}
                        handleUserDetails={handleChange}
                    />
                </div>
                <TextareaWithLabel
                    labelProps={{ text: "Bio" }}
                    textAreaProps={{
                        name: "companyBio",
                        placeholder: "Enter company Bio",
                        value: formData.companyBio,
                        handleChange: handleChange,
                    }}
                />
                {/* <Button type="submit" style={{ height: 48, maxHeight: 48, padding: "12px 30px" }}>
                    {isLoading || isUpdating
                        ? `${user?.companyInfo ? "saving..." : "creating..."}`
                        : `${user?.companyInfo ? "Save Settings" : "Create Company"}`}
                </Button> */}
                <Button
                    type="submit"
                    className="h-[48px] max-h-[48px] py-[12px] px-[30px] bg-[#000]"
                >
                    {isLoading || isUpdating
                        ? `${user?.companyInfo ? "saving..." : "creating..."}`
                        : `${user?.companyInfo ? "Save Settings" : "Create Company"}`}
                </Button>
            </form>
        </section>
    );
};
