import Box from "@/components/common/Box/Box";

interface BoxDataItem {
    children: React.ReactNode;
    ismap: boolean;
}

interface BoxSegmentProps {
    BoxData: BoxDataItem[];
    className?: string;
}

const BoxSegment: React.FC<BoxSegmentProps> = ({ BoxData, className }) => {
    return (
        <div
            className={`flex justify-between space-x-4 md:space-x-6 lg:space-x-8 xl:space-x-10 ${className}`}
        >
            {BoxData.map((data, index) => {
                return (
                    <Box
                        key={index}
                        ismap={data?.ismap ?? false}
                        classes="flex flex-col justify-between shadow-md w-[270px] h-[100px] rounded-md bg-white flex-1"
                    >
                        {data.children}
                    </Box>
                );
            })}
        </div>
    );
};

export default BoxSegment;
