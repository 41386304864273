import styles from "../Common.module.css";

type TLabelTypes = "white" | "ash" | "green" | "plain-black";

interface LabelProps {
    text: string;
    type: TLabelTypes;
    bottom?: number;
    top?: number;
}

function Label({ text, type, bottom = 0, top = 0 }: LabelProps) {
    const getTypeClassName = (type: TLabelTypes) => {
        switch (type) {
            case "white":
                return styles.labelWhite;
            case "ash":
                return styles.labelAsh;
            case "green":
                return styles.labelGreen;
            case "plain-black":
                return styles.labelBlack;
            default:
                return "";
        }
    };
    return (
        <div
            className={`${styles.labelFrame} ${getTypeClassName(type)}`}
            style={{ marginTop: top, marginBottom: bottom }}
        >
            <span>{text}</span>
        </div>
    );
}

export default Label;
