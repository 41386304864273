import { memo } from "react";
import { SmallJobCard } from "../../components/common/smallJobCard/SmallJobCard";
import styles from "./findContractors.module.css";

type TProps = { onClickBookButton: () => void; onClickCard: () => void };

export const ContractorsList = memo((props: TProps) => {
    const { onClickBookButton, onClickCard } = props;
    return (
        <div className={styles.contractorsContainer}>
            {Array(9)
                .fill(0)
                .map((key) => (
                    <SmallJobCard
                        key={key}
                        hasMiddleStats
                        onClickPrimaryButton={onClickBookButton}
                        onClickCard={onClickCard}
                    />
                ))}
        </div>
    );
});
