import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../api/config";
import { getTokenFromAccountStorage } from "../../utils/service";

export type QueryReturnType<T> = {
    data?: T;
    error?: string;
};

export const api = createApi({
    reducerPath: "api",
    endpoints: () => ({}),
    tagTypes: [
        "HOME_OWNER_JOBS",
        "USER_DETAILS",
        "USER_PROFILE",
        "HOME_OWNER_JOB",
        "JOB_QUOTES",
        "JOB_CATEGORIES",
        "CONVERSATIONS",
        "JOB_FAVORITES",
        "PROPERTIES",
        "TENANTS",
        "COMPANIES",
        "CONTRACTS",
        "BUILDING",
        "PROPERTIES_STATS",
        "JOB_FILES",
        "USERS",
        "ADDONS",
        "ADDONS",
        "WORK_ORDERS",
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: config.baseUrl,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");

            if (token) {
                const _token = token.replace(/^"(.*)"$/, "$1");
                headers.set("x-api-key", _token);
            } else {
                try {
                    const tokenFromAccount = getTokenFromAccountStorage();
                    if (tokenFromAccount) {
                        const tokenPurified = tokenFromAccount.replace(/^"(.*)"$/, "$1");
                        headers.set("x-api-key", tokenPurified);
                    }
                } catch (error) {
                    console.log(error);
                }
            }

            return headers;
        },
    }),
});
