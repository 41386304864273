import { ChangeEventHandler, useCallback, useMemo } from "react";
import { Divider } from "../../../components/common/divider";
import { TDropdownItem } from "../../../components/common/dropdown/Dropdown.types";
import { useGetMyDetailsQuery } from "../../../redux/rtk-query/authApis";
import { TCreateContractArgs } from "../../../redux/rtk-query/contractsApis";
import { useGetPropertiesQuery } from "../../../redux/rtk-query/properties";
import { useGetTenantsQuery } from "../../../redux/rtk-query/tenantsApis";
import { useUploadFileMutation } from "../../../redux/rtk-query/uploadApis";
import { TContract, TSetState } from "../../../types";
import { catchAll } from "../../../utils/catch";
import { OccupancyInformation } from "./OccupancyInformation";
import styles from "./PropertyManagementCreateContract.module.css";
import { TenantInformation } from "./TenantInformation";

const DEFAULT_TENANT_INFORMATION = {
    name: "",
    email: "",
    image: "https://via.placeholder.com/500x500",
};

type TProps = {
    formValues: TCreateContractArgs;
    setFormValues: TSetState<TCreateContractArgs>;
    contractDetails: TContract[] | undefined;
    onSubmit: any;
    isCreatingContract: boolean;
};

export const Content = ({
    formValues,
    setFormValues,
    contractDetails,
    onSubmit,
    isCreatingContract,
}: TProps) => {
    const { data: userDetails } = useGetMyDetailsQuery();

    const { data: tenantsResult } = useGetTenantsQuery(
        {
            companyId: userDetails?.companyId!,
        },
        {
            skip: !userDetails?.companyId,
        },
    );

    const { data: properties } = useGetPropertiesQuery(userDetails?.companyId!, {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        skip: !userDetails?.companyId,
    });

    const [uploadFiles, { isLoading: isLoadingFileUpload }] = useUploadFileMutation();

    const propertiesDropdown: TDropdownItem[] = useMemo((): TDropdownItem[] => {
        if (!properties) return [];

        return properties.map((property: any) => ({ key: property.name, value: property._id }));
    }, [properties]);

    const tenantsDropdown: TDropdownItem[] = useMemo((): TDropdownItem[] => {
        if (!tenantsResult?.tenants) return [];

        return tenantsResult?.tenants.map((tenant) => ({
            key: tenant.name.fullName,
            value: tenant.id,
        }));
    }, [tenantsResult?.tenants]);

    const tenantInformation = useMemo((): { name: string; image: string; email: string } => {
        if (!tenantsResult?.tenants) {
            return DEFAULT_TENANT_INFORMATION;
        }

        if (!formValues.userId) {
            return DEFAULT_TENANT_INFORMATION;
        }

        const selectedTenant = tenantsResult?.tenants?.find(
            (tenant) => tenant.id === formValues.userId,
        );

        if (!selectedTenant) {
            return DEFAULT_TENANT_INFORMATION;
        }

        const email = selectedTenant.email;
        const image = selectedTenant.image;
        const name = selectedTenant.name.fullName;

        return { email, image, name };
    }, [tenantsResult?.tenants, formValues.userId]);

    const setValuesToFormObject = useCallback(
        (key: keyof typeof formValues, value: string | number): void => {
            setFormValues((prev) => ({ ...prev, [key]: value }));
        },
        [setFormValues],
    );

    const handleOnClickUploadFiles: ChangeEventHandler<HTMLInputElement> = useCallback(
        async function (e) {
            const formData = new FormData();

            if (!e.target.files) return;

            const files = Array.from(e.target.files) as File[];

            files.forEach((file) => {
                formData.append("files", file);
            });

            formData.append("folder", "contracts");

            const response = await catchAll(uploadFiles(formData).unwrap());

            if (response.error) return;

            setFormValues((prev) => ({
                ...prev,
                attachmentsUrls: [...(prev?.attachmentsUrls ?? []), response.data.url],
            }));
        },
        [setFormValues, uploadFiles],
    );

    return (
        <div className={`${styles.contentContainer} bg-white`}>
            {tenantInformation.name && tenantInformation.email && (
                <>
                    <TenantInformation
                        name={tenantInformation.name}
                        email={tenantInformation.email}
                        image={tenantInformation.image}
                    />
                    <Divider top={40} variant="light" />
                </>
            )}
            <OccupancyInformation
                properties={propertiesDropdown}
                tenants={tenantsDropdown}
                formValues={formValues}
                setValuesToFormObject={setValuesToFormObject}
                handleOnClickUploadFiles={handleOnClickUploadFiles}
                isLoadingFileUpload={isLoadingFileUpload}
                contractDetails={contractDetails}
                onSubmit={onSubmit}
                isCreatingContract={isCreatingContract}
            />

            <div>
                {
                    // formValues.attachmentsUrls.map(attachment => <Chip text="" />)
                }
            </div>
        </div>
    );
};
