import { memo } from "react";
import styles from "../Settings.module.css";
import { AddAccountCardContainerLeft } from "./AddAccountCardContainerLeft";
import { Button } from "@/components/ui/button";

type TProps = {
    onClickAddAccount: () => void;
};

export const AddAccountCardContainer = memo(({ onClickAddAccount }: TProps) => (
    <div className={styles.addAccountCardContainer}>
        <AddAccountCardContainerLeft />
        <Button onClick={onClickAddAccount} className="bg-[#000]">
            Add Account
        </Button>
    </div>
));
