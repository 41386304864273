import { DialogModal } from "@/components/common/dialogModal";
import React from "react";
import { JobAssignList } from "./quotes/JobAssignList";

interface ContractorAssignModalProps {
    jobAssignPopUpOpen: boolean;
    setJobAssignPopUpOpen: (open: boolean) => void;
    jobResult: any;
    completeAddress: string;
    handleAssignJob: () => void;
    setSelectedUserId: (userId: string) => void;
    selectedUserId: string;
    isJobStatusUpdating: boolean;
    setOpen: (open: boolean) => void;
}

const ContractorAssignModal: React.FC<ContractorAssignModalProps> = ({
    jobAssignPopUpOpen,
    setJobAssignPopUpOpen,
    jobResult,
    completeAddress,
    handleAssignJob,
    setSelectedUserId,
    selectedUserId,
    isJobStatusUpdating,
    setOpen,
}) => {
    return (
        <DialogModal
            open={jobAssignPopUpOpen}
            dialogTitle="Assign Contractor"
            setOpen={setJobAssignPopUpOpen}
            className="max-h-[38rem]"
        >
            <JobAssignList
                title={jobResult?.jobDetail?.title}
                batch={`${jobResult?.jobPriority?.slice(0, 1)}${jobResult?.jobPriority?.slice(1).toLowerCase()}`}
                address={completeAddress}
                handleAssignJob={handleAssignJob}
                setSelectedUserId={setSelectedUserId}
                selectedUserId={selectedUserId}
                isJobStatusUpdating={isJobStatusUpdating}
                setJobAssignPopUpOpen={setJobAssignPopUpOpen}
                setOpen={setOpen}
            />
        </DialogModal>
    );
};

export default ContractorAssignModal;
