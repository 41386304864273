import MapLoaderGif from "@/assets/icons/MapLoaderGif";
import { Map } from "@/components/common/map";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { useGetPropertiesWithIdQuery } from "@/redux/rtk-query/properties";
import { useEffect, useState } from "react";

interface DashBoardMapProps {
    isLoading?: boolean;
    setLoading?: any;
}

export default function DashBoardMap({ isLoading, setLoading }: DashBoardMapProps) {
    const { data: user } = useGetMyDetailsQuery();

    const { data: propertiesData, isLoading: isPropertyDataLoading } = useGetPropertiesWithIdQuery(
        user?.companyId || "",
        {
            skip: !user?.companyId,
        },
    );

    const [addresses, setAddresses] = useState<string[]>([]);
    useEffect(() => {
        setLoading?.(true);
        if (propertiesData) {
            const addressPromises = propertiesData.map(async (data) => {
                const processAddress = (address: string) => {
                    return new Promise<string>((resolve) => {
                        setTimeout(() => resolve(address), 1000);
                    });
                };

                return processAddress(data?.address?.mainStreet || "");
            });

            Promise.all(addressPromises)
                .then((processedAddresses) => {
                    setAddresses(processedAddresses);
                    setLoading?.(false); // Set loading to false after addresses are set
                })
                .catch((error) => {
                    console.error("Error processing addresses:", error);
                    setLoading?.(false); // Set loading to false in case of an error
                });
        } else {
            setLoading?.(false);
        }
    }, [propertiesData, setLoading]);

    // console.log(isPropertyDataLoading, isLoading, loading, addresses, "papapapapa");

    return (
        <>
            {isPropertyDataLoading || isLoading ? (
                <div className="flex space-y-4 justify-center items-center h-24">
                    <MapLoaderGif />
                </div>
            ) : (
                <Map isOpen={true} type="propertymanager" addresses={addresses} />
            )}
        </>
    );
}
