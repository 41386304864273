import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useGetMyDetailsQuery } from "../redux/rtk-query/authApis";
import {
    useCreateConversationMutation,
    useGetUserConversationsQuery,
    useSendMessageMutation,
} from "../redux/rtk-query/chatApis";
import { catchAll } from "../utils/catch";
import { getUserBasePath } from "../utils/getBaseUserBasedPath";

export type TOnClick = (id: string | undefined, username?: string | undefined) => Promise<void>;

export const useSendMessageOrCreateConversation = () => {
    const navigate = useNavigate();

    const { data: myDetails } = useGetMyDetailsQuery();
    const { data: conversationList } = useGetUserConversationsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const [createConversation] = useCreateConversationMutation();
    const [sendMessage] = useSendMessageMutation();

    const onClick: TOnClick = useCallback(
        async (otherUser, username) => {
            if (!myDetails || !otherUser) return;

            const createConversationObject = {
                conversationType: "DIRECT" as "DIRECT",
                title: username ?? "",
                participants: [{ userId: myDetails._id }, { userId: otherUser }],
            };

            const response = await catchAll(createConversation(createConversationObject).unwrap());

            const basePath = getUserBasePath(myDetails.userType);

            const conversationIds = conversationList?.filter((data) =>
                data.participantDetails.some((datas) => datas._id === otherUser),
            );
            if (response.error) {
                navigate(`${basePath}/chat`, {
                    state: { conversationId: conversationIds?.[0]?._id },
                });
                return;
            }

            const conversationId = response.data.data.connId;

            const payload = {
                senderId: myDetails._id,
                conversationId,
                receipientId: otherUser,
                content: `Hi`,
                status: "SENT",
            };

            await catchAll(sendMessage(payload).unwrap());
            console.log(response, "ppppppp");

            navigate(`${basePath}/chat`, { state: { conversationId: conversationIds?.[0]?._id } });
        },
        [conversationList, createConversation, myDetails, navigate, sendMessage],
    );

    return {
        onClick,
    };
};
