import { MySpendingStat } from "./MySpendingStat";
import styles from "./homeOwnerDashboard.module.css";

export const Stats = [
    {
        heading: "Total Spendings",
        stat: "£0.00",
    },
    {
        heading: "In progress",
        stat: "£0.00",
    },
    {
        heading: "In Review",
        stat: "£0.00",
    },
    {
        heading: "Balance",
        stat: "£0.00",
    },
];

export const SpendingStats = (props: { showSpending: boolean }) => (
    <div className={styles.mySpendingStats}>
        {Stats.map((stat, index) => {
            const isFirstStat = index === 0;
            return (
                <MySpendingStat
                    isFirstStat={isFirstStat}
                    key={stat.heading}
                    heading={stat.heading}
                    stat={stat.stat}
                    showStat={props.showSpending}
                />
            );
        })}
    </div>
);
