import { Checkbox } from "@mantine/core";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
// import { TTableRows, Table } from "../../components/common/table/table";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import Skeleton from "react-loading-skeleton";
import { useGetBuildingsByCompanyQuery } from "../../redux/rtk-query/properties";
import { HeadingWithStats } from "./HeadingWithStats";
import { TableActions } from "./TableActions";

export const PropertyUnits = () => {
    const { id } = useParams<{ id: string }>();

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [sortColumn, setSortColumn] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<string>("asc");

    const { data: propertiesData, isLoading } = useGetBuildingsByCompanyQuery(
        { id: id ?? "", type: "BUILDING" },
        {
            skip: !id,
        },
    );

    const handleSort = (column: string) => {
        const direction = sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
        setSortColumn(column);
        setSortDirection(direction);
    };

    const [visibleColumns, setVisibleColumns] = useState<string[]>([
        "id",
        "name",
        "noOfBedrooms",
        "area",
        "status",
        "type",
        // "category",
    ]);

    const handleToggleColumn = (column: string) => {
        setVisibleColumns((prev) =>
            prev.includes(column) ? prev.filter((col) => col !== column) : [...prev, column],
        );
    };
    const TableHeadItems = useMemo(
        () =>
            [
                { key: "id", value: "Unit ID" },
                { key: "name", value: "Name" },
                { key: "noOfBedrooms", value: "No Of Bedrooms" },
                { key: "area", value: "Area" },
                { key: "status", value: "Status" },
                { key: "type", value: "Type" },
                // { key: "category", value: "Category" },
            ].filter((item) => visibleColumns.includes(item.key)),
        [visibleColumns],
    );

    const sortedProperties = useMemo(() => {
        if (
            !propertiesData?.data ||
            !propertiesData?.data[0] ||
            !propertiesData?.data[0]?.properties
        )
            return [];
        return propertiesData?.data[0]?.properties.slice().sort((a: any, b: any) => {
            if (sortColumn) {
                if (sortDirection === "asc") {
                    return a[sortColumn].localeCompare(b[sortColumn]);
                } else {
                    return b[sortColumn].localeCompare(a[sortColumn]);
                }
            }
            return 0;
        });
    }, [propertiesData, sortColumn, sortDirection]);

    const filteredProperties = useMemo(() => {
        return sortedProperties.filter((property) =>
            property.name.toLowerCase().includes(searchTerm.toLowerCase()),
        );
    }, [searchTerm, sortedProperties]);

    const propertiesLength = propertiesData?.data?.[0]?.properties?.length ?? "0";

    return (
        <div className="max-h-screen overflow-y-auto p-5 w-full">
            {/* <Header /> */}
            {/* <Statistics propertiesData={propertiesLength} /> */}
            <HeadingWithStats heading="Units" stat={propertiesLength} id={id} />
            <TableActions
                onClickFilter={() => {}}
                onClickSort={handleSort}
                onToggleColumn={handleToggleColumn}
                visibleColumns={visibleColumns}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />

            <div className="relative overflow-x-auto">
                <Table className="bg-white">
                    <TableHeader className="sticky top-0 z-10">
                        <TableRow>
                            {TableHeadItems.map((item) => (
                                <TableHead
                                    key={item.key}
                                    onClick={() => handleSort(item.key)}
                                    className="cursor-pointer bg-transparent"
                                >
                                    {item.value}
                                    {sortColumn === item.key &&
                                        (sortDirection === "asc" ? " ↑" : " ↓")}
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {isLoading ? (
                            [...Array(5)].map((_, index) => (
                                <TableRow key={index}>
                                    {TableHeadItems.map((item) => (
                                        <TableCell key={item.key}>
                                            <Skeleton height={20} />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : filteredProperties.length > 0 ? (
                            filteredProperties.map((property, index) => (
                                <TableRow key={property._id}>
                                    {visibleColumns.includes("id") && (
                                        <TableCell>
                                            <div className="flex items-center gap-6">
                                                <Checkbox size="xs" />
                                                <p>{index + 1}</p>
                                            </div>
                                        </TableCell>
                                    )}
                                    {visibleColumns.includes("name") && (
                                        <TableCell>{property.name}</TableCell>
                                    )}
                                    {visibleColumns.includes("noOfBedrooms") && (
                                        <TableCell>{property.noOfBedrooms}</TableCell>
                                    )}
                                    {visibleColumns.includes("area") && (
                                        <TableCell>{property.area}</TableCell>
                                    )}
                                    {visibleColumns.includes("status") && (
                                        <TableCell>
                                            {property.status
                                                .split("_")
                                                .map(
                                                    (word) =>
                                                        word.charAt(0).toUpperCase() +
                                                        word.slice(1).toLowerCase(),
                                                )
                                                .join(" ")}
                                        </TableCell>
                                    )}
                                    {visibleColumns.includes("type") && (
                                        <TableCell>
                                            {property.type
                                                .split("_")
                                                .map(
                                                    (word) =>
                                                        word.charAt(0).toUpperCase() +
                                                        word.slice(1).toLowerCase(),
                                                )
                                                .join(" ")}
                                        </TableCell>
                                    )}
                                    {/* {visibleColumns.includes("category") && (
                                        <TableCell>
                                            <div className="flex justify-between items-center">
                                                <Chip
                                                    color={
                                                        statusColor(property.category) || "white"
                                                    }
                                                    text={property.category
                                                        .split("_")
                                                        .map(
                                                            (word) =>
                                                                word.charAt(0).toUpperCase() +
                                                                word.slice(1).toLowerCase(),
                                                        )
                                                        .join(" ")}
                                                />
                                                <FaPenToSquare
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        navigate(
                                                            `/property-management/properties/add-unit/${id}?id=${property._id}`,
                                                        )
                                                    }
                                                />
                                            </div>
                                        </TableCell>
                                    )} */}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={TableHeadItems.length}>
                                    <div className="text-center font-semibold">No data exists!</div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};
