import React from "react";

export const initializeMap =
    (
        mapRef: React.RefObject<any>,
        placeMarkerAndPanTo: (latLng: google.maps.LatLng, map: google.maps.Map) => void,
        getAddress: (latLng: google.maps.LatLng) => void,
    ) =>
    (position: GeolocationPosition) => {
        if (!mapRef.current) return;

        const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: position.coords.latitude, lng: position.coords.longitude },
            zoom: 8,
        });

        const currentLocation = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude,
        );
        placeMarkerAndPanTo(currentLocation, map);
        getAddress(currentLocation);

        map.addListener("click", (event: google.maps.MapMouseEvent) => {
            if (event.latLng) {
                placeMarkerAndPanTo(event.latLng, map);
                getAddress(event.latLng);
            }
        });
    };

export const handleGeolocationError =
    (
        mapRef: React.RefObject<HTMLDivElement>,
        placeMarkerAndPanTo: (latLng: google.maps.LatLng, map: google.maps.Map) => void,
        getAddress: (latLng: google.maps.LatLng) => void,
    ) =>
    (_: GeolocationPositionError) => {
        if (!mapRef.current) return;

        const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 8,
        });

        map.addListener("click", (event: google.maps.MapMouseEvent) => {
            if (event.latLng) {
                placeMarkerAndPanTo(event.latLng, map);
                getAddress(event.latLng);
            }
        });
    };

export const placeMarkerAndPanTo =
    (markerRef: React.MutableRefObject<google.maps.Marker | null>) =>
    (latLng: google.maps.LatLng, map: google.maps.Map) => {
        if (markerRef.current) {
            markerRef.current.setMap(null);
        }

        const marker = new google.maps.Marker({
            position: latLng,
            map: map,
            icon: {
                path: "M17.0002 0.0707703C12.5901 0.0756221 8.36203 1.82956 5.24346 4.94782C2.12489 8.06608 0.370528 12.294 0.365234 16.7041C0.365234 20.9874 3.6819 27.6908 10.2236 36.6274C11.0023 37.6943 12.022 38.5623 13.1996 39.1606C14.3771 39.759 15.6794 40.0708 17.0002 40.0708C18.3211 40.0708 19.6233 39.759 20.8009 39.1606C21.9785 38.5623 22.9981 37.6943 23.7769 36.6274C30.3186 27.6908 33.6352 20.9874 33.6352 16.7041C33.6299 12.294 31.8756 8.06608 28.757 4.94782C25.6384 1.82956 21.4103 0.0756221 17.0002 0.0707703ZM17.0002 23.3341C15.6817 23.3341 14.3928 22.9431 13.2964 22.2106C12.2001 21.478 11.3456 20.4368 10.841 19.2187C10.3365 18.0005 10.2044 16.66 10.4617 15.3668C10.7189 14.0736 11.3538 12.8857 12.2862 11.9534C13.2185 11.021 14.4064 10.3861 15.6996 10.1289C16.9928 9.87164 18.3333 10.0037 19.5515 10.5082C20.7696 11.0128 21.8108 11.8673 22.5434 12.9636C23.2759 14.06 23.6669 15.3489 23.6669 16.6674C23.6669 18.4355 22.9645 20.1312 21.7143 21.3815C20.464 22.6317 18.7683 23.3341 17.0002 23.3341Z",
                fillColor: "#22BCFF",
                fillOpacity: 1.0,
                strokeColor: "#000000",
                strokeWeight: 0,
            },
        });

        map.panTo(latLng);
        markerRef.current = marker;
    };

export const getAddress =
    (
        setCurrentAddress: React.Dispatch<React.SetStateAction<string>>,
        setPlace: React.Dispatch<React.SetStateAction<google.maps.places.PlaceResult | undefined>>,
    ) =>
    (latLng: google.maps.LatLng) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === "OK") {
                if (results && results[0]) {
                    setCurrentAddress(results[0].formatted_address);
                    setPlace({
                        ...results[0],
                        geometry: { ...results[0]?.geometry, location: latLng },
                    });
                } else {
                    setCurrentAddress("No results found");
                }
            } else {
                setCurrentAddress("Geocoder failed due to: " + status);
            }
        });
    };
