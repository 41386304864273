import React, { ReactNode, useCallback } from "react";
import { BarCharts } from "@/components/chart/BarCharts";
import { PieCharts } from "@/components/chart/PieChart";
import RecentTransactions from "@/components/table/RecentTransaction";
import { MessageCard } from "@/components/table/MessageCard";
import { TaskTables } from "@/components/table/TaskTable";
import {
    CardNetIncome,
    CardProperties,
} from "@/pages/propertyManagementDashboard/propertyManagementDashboard.constant";
import { ColumnDef } from "@tanstack/react-table";
import ChartBox from "../chart/ChartBox";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/redux/store";
import { TDropdownItem } from "../dropdown/Dropdown.types";
import { clearLocalStorage } from "@/utils/service";
import { api } from "@/redux/rtk-query/api";
import { Header } from "../headers/Header";

type tableData = {
    header: ColumnDef<any>[];
    row: any[];
    heading: string;
};

interface TableData {
    data: tableData[];
    mainHeading: string;
}

export interface ChartConfig {
    [key: string]: {
        label: string;
        color: string;
    };
}

type PieChartData = {
    propertyStatLabel: string;
    stat: number;
    fill: string;
};
interface DashBoardProps {
    userimage?: string;
    TableData?: TableData;
    children: ReactNode;
    graphHeading: {
        heading1: string;
        heading2: string;
    };
    PiechartData: PieChartData[];
    BarChartData: any;
    totalNumber: number;
    accountType: string;
}

const DashBoard: React.FC<DashBoardProps> = ({
    userimage,
    TableData,
    children,
    graphHeading,
    PiechartData,
    totalNumber,
    BarChartData,
    accountType,
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // const noClickMaintenanceRow = useCallback(
    //     (rowItem: TTableRows) => {
    //         if (typeof rowItem?.[0]?.value === "string") {
    //             navigate(`/property-management/jobs/${rowItem[0].value}`);
    //         }
    //     },
    //     [navigate],
    // );

    useCallback(
        (clickedItem: TDropdownItem) => {
            console.log(clickedItem.value === "settings");

            if (clickedItem.value === "logout") {
                clearLocalStorage();
                api.util.invalidateTags(["USER_DETAILS"]);
                dispatch(api.util.resetApiState());
                navigate("/login");
            }
            if (clickedItem.value === "settings") {
                navigate(`/${accountType}/settings`);
            }
        },
        [accountType, dispatch, navigate],
    );

    return (
        <div className="max-h-screen overflow-y-auto w-full">
            <Header image={userimage} heading={undefined} username="" hasSearchBar={true} />
            <div className="p-5">
                <header className="flex justify-between items-center mb-6">
                    <h1 className="font-bold text-3xl leading-9 text-[#09090B]">Dashboard</h1>
                </header>
                <ul className="flex items-center justify-end m-0">
                    <li className="flex items-center relative bg-transparent">
                        {/* <Sheet>
                                <SheetTrigger asChild>
                                    <span className="w-10 h-10 rounded-full border border-gray-300 p-2.5 flex justify-center items-center cursor-pointer">
                                        <i className="text-lg ri-notification-2-line"></i>
                                    </span>
                                </SheetTrigger>
                                <SheetContent>
                                    <SheetHeader>
                                        <SheetTitle>Notifications</SheetTitle>
                                    </SheetHeader>
                                    <SheetDescription className="overflow-y-auto h-[90vh] space-y-2 mt-4">
                                        {Array.from({ length: 10 }).map((_, index) => (
                                            <div
                                                key={index}
                                                className="flex items-start p-3 space-x-4 bg-white shadow-md rounded-lg transition-all hover:shadow-lg border border-gray-300"
                                            >
                                                <div className="space-y-1">
                                                    <p className="text-sm font-semibold leading-tight text-gray-800">
                                                        Tenant Notification
                                                    </p>
                                                    <p className="text-sm text-gray-500">
                                                        Email digest, mentions & all activity
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </SheetDescription>
                                </SheetContent>
                            </Sheet> */}
                    </li>
                </ul>
                <div className="min-h-screen w-full bg-[#f6f6f6]">
                    {/* <div className="flex justify-end items-center gap-1.5 mx-[20px]">
                  
                      <Button
                        onClick={() => {}}
                        style={{
                            height: 35,
                            borderRadius: "20px",
                            backgroundColor: "#FFFFFF",
                            color: "black",
                        }}
                    >
                        <div className="flex gap-[7px] items-center">
                            <SlCalender /> Last 7 days
                        </div>
                    </Button>
                    <Button
                        onClick={() => {}}
                        style={{ height: 35, borderRadius: "20px", backgroundColor: "#019CDF" }}
                    >
                        + Create
                    </Button>
                </div> */}

                    {children}
                    <div className="flex gap-3 my-5">
                        <ChartBox cardData={CardNetIncome} heading={graphHeading.heading1}>
                            <BarCharts
                                data={BarChartData.BarchartData}
                                config={BarChartData.chartConfig}
                            />
                        </ChartBox>
                        <ChartBox cardData={CardProperties} heading={graphHeading.heading2}>
                            <PieCharts
                                type="dashboard"
                                chartConfig={{
                                    chrome: {
                                        label: "Chrome",
                                        color: "#FEC84B",
                                    },
                                    edge: {
                                        label: "edge",
                                        color: "#0DADEA",
                                    },
                                    safari: {
                                        label: "Safari",
                                        color: "#FD7F58",
                                    },
                                }}
                                chartData={PiechartData}
                                totalTasks={totalNumber}
                            />
                        </ChartBox>
                    </div>

                    <div className="my-4">
                        <TaskTables
                            data={TableData?.data || []}
                            mainHeading={TableData?.mainHeading || ""}
                            onClickRow={(row: any) => {
                                console.log(row, "----- mnmnmnet---");
                                const rowId = row?.original._id;
                                // const payload = activeJobs.find((log: any) => log._id === rowId);

                                return navigate(
                                    `/property-management/work-order/workOrder-details/${rowId}`,
                                    // {
                                    //     state: payload,
                                    // },
                                );
                            }}
                        />
                    </div>

                    <div className="flex gap-4 my-4">
                        <div className="border rounded-lg w-full bg-white">
                            <RecentTransactions />
                        </div>
                        <div className="border rounded-lg w-full bg-white">
                            <MessageCard dashBoard={true} accountType={accountType} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashBoard;
