import { DialogModal } from "@/components/common/dialogModal";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Spinner } from "@/components/ui/spinner";
import { Textarea } from "@/components/ui/textarea";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";
import { PopOver } from "../PopOver";
import { useGetBuildingsByCompanyQuery } from "@/redux/rtk-query/properties";
import { Building, Trash2 } from "lucide-react";
import UnitPopOver from "../UnitPopOver";
import { useCreateScriptMutation } from "@/redux/rtk-query/knowledgeApi";

interface ResponseModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

interface PropertyData {
    value: string;
    propertyId: string;
}

const formSchema = z.object({
    questions: z.string().min(2, { message: "Question is required!" }),
    answer: z.string().min(2, { message: "Answer is required!" }),
    context: z.string().min(2, { message: "Context is required!" }),
    propertyIds: z.array(z.string()).nonempty("At least one property selection is required!"),
    buildingIds: z.array(z.string().min(1, "Building ID cannot be empty")),
});

const ResponseModal: React.FC<ResponseModalProps> = ({ open, setOpen }) => {
    const { data: user } = useGetMyDetailsQuery();
    const [popOverOpen, setPopOverOpen] = useState(false);
    const [propertyValue, setPropertyValue] = useState<PropertyData[]>([]);
    const [checkBoxPopUpOpen, setCheckBoxPopUpOpen] = useState(false);
    const [selectedPropertyType, setSelectedPropertyType] = useState<"all" | "selected">("all");

    const [createScript, { isLoading: isCreateScriptLoading }] = useCreateScriptMutation();

    const { data: properties } = useGetBuildingsByCompanyQuery(
        { id: user?.companyId || "", type: "COMPANY", limit: 500 },
        {
            skip: !user?.companyId,
        },
    );

    console.log(properties, "lllllllllllllllllll");

    const form = useForm<{
        questions: string;
        answer: string;
        context: string;
        buildingIds: string[];
        propertyIds: string[];
    }>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            questions: "",
            answer: "",
            context: "",
            buildingIds: [],
            propertyIds: [],
        },
    });

    const { handleSubmit, getValues, setValue } = form;

    const onSubmit = async (data: any) => {
        try {
            await createScript({ ...data, companyId: user?.companyId }).unwrap();
            setOpen(false);
            toast.success("response added successfully.");
            form.reset({
                questions: "",
                answer: "",
                context: "",
                buildingIds: [],
                propertyIds: [],
            });
        } catch (error) {
            console.error("Failed to add contractor:", error);
            toast.error("Failed to send invite.");
        }
    };

    const handleCheckboxChange = (type: "all" | "selected") => {
        setSelectedPropertyType(type);

        if (type === "all") {
            setValue("propertyIds", []);
        } else {
            const currentSelection = getValues("propertyIds");

            setCheckBoxPopUpOpen(true);
            if (!currentSelection?.length) {
                setValue("propertyIds", [...getValues("propertyIds"), ...currentSelection]);
            }
        }
    };

    // const handleComboboxSelect = (propertyId: string, value: string) => {
    //     let propertyIds = getValues("propertyIds");
    //     if (propertyIds.includes(propertyId)) {
    //         propertyIds = propertyIds.filter((id) => id !== propertyId);
    //     } else {
    //         propertyIds = [...propertyIds, propertyId];
    //     }
    //     setValue("propertyIds", propertyIds);
    //     setPropertyValue((prev) => {
    //         return propertyIds.includes(propertyId)
    //             ? [...prev, { propertyId, value }]
    //             : prev.filter((p) => p.propertyId !== propertyId);
    //     });
    // };

    // const handleComboboxUnitSelect = (propertyId: string, value: string) => {
    //     let buildingIds = getValues("buildingIds");
    //     if (buildingIds.includes(propertyId)) {
    //         buildingIds = buildingIds.filter((id) => id !== propertyId);
    //     } else {
    //         buildingIds = [...buildingIds, propertyId];
    //     }
    //     setValue("buildingIds", buildingIds);
    // };

    const handleComboboxSelect = (propertyId: string, value: string) => {
        let propertyIds = getValues("buildingIds");
        if (propertyIds.includes(propertyId)) {
            propertyIds = propertyIds.filter((id) => id !== propertyId);
        } else {
            propertyIds = [...propertyIds, propertyId];
        }
        setValue("buildingIds", propertyIds);
        setPropertyValue((prev) => {
            return propertyIds.includes(propertyId)
                ? [...prev, { propertyId, value }]
                : prev.filter((p) => p.propertyId !== propertyId);
        });
    };

    const handleComboboxUnitSelect = (propertyId: string, value: string) => {
        let buildingIds = getValues("propertyIds");
        if (buildingIds.includes(propertyId)) {
            buildingIds = buildingIds.filter((id) => id !== propertyId);
        } else {
            buildingIds = [...buildingIds, propertyId];
        }
        setValue("propertyIds", buildingIds);
    };
    console.log(getValues());

    return (
        <DialogModal
            open={open}
            dialogTitle="Add New Response"
            setOpen={setOpen}
            className="sm:max-w-[900px]"
        >
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <div className="flex py-4 w-full gap-3 justify-between">
                        <div className="w-full">
                            <FormField
                                control={form.control}
                                name="questions"
                                render={({ field }) => (
                                    <FormItem className="my-3">
                                        <FormLabel className="text-xs font-bold text-black">
                                            Question
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                id="questions"
                                                placeholder="Enter question"
                                                className="focus:ring-1 focus-visible:ring-gray-200 border border-gray-300 rounded-md"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage className="text-xs" />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="answer"
                                render={({ field }) => (
                                    <FormItem className="my-3">
                                        <FormLabel className="text-xs font-bold text-black">
                                            Answer
                                        </FormLabel>
                                        <FormControl>
                                            <Textarea
                                                id="answer"
                                                placeholder="Enter Response"
                                                className="focus:ring-1 focus-visible:ring-gray-200 rounded-md border border-gray-300"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage className="text-xs" />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="context"
                                render={({ field }) => (
                                    <FormItem className="my-3">
                                        <FormLabel className="text-xs font-bold text-black">
                                            Context
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                id="context"
                                                placeholder="Enter context"
                                                className="focus:ring-1 focus-visible:ring-gray-200 rounded-md border border-gray-300"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage className="text-xs" />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="p-4 border border-gray-300 rounded-md w-full">
                            <div className="flex items-center space-x-2 my-2">
                                <Checkbox
                                    id="applyAll"
                                    checked={selectedPropertyType === "all"}
                                    onCheckedChange={() => handleCheckboxChange("all")}
                                />
                                <div className="text-sm font-semibold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                    Apply for All Property
                                </div>
                            </div>
                            <div className="flex items-center space-x-2 my-3">
                                <Checkbox
                                    id="applySelected"
                                    checked={selectedPropertyType === "selected"}
                                    onCheckedChange={() => handleCheckboxChange("selected")}
                                />
                                <div className="text-sm font-semibold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                    Apply to selected properties
                                </div>
                            </div>
                            {checkBoxPopUpOpen && (
                                <div className="flex items-center space-x-2 my-3 p-2">
                                    <PopOver
                                        open={popOverOpen}
                                        setOpen={setPopOverOpen}
                                        DropDownArray={
                                            properties?.data?.map((data: any) => {
                                                return { value: data._id, label: data.name };
                                            }) || []
                                        }
                                        onChange={handleComboboxSelect}
                                        placeholder="Select Property..."
                                        message="No Property Found!"
                                        icon={Building}
                                    />
                                </div>
                            )}
                            {checkBoxPopUpOpen && getValues("buildingIds").length > 0 && (
                                <div
                                    className="p-2 rounded-lg my-1 max-h-[200px] overflow-y-auto" // Adjust max-height and enable vertical scrolling
                                >
                                    {propertyValue?.map((data: PropertyData, index: number) => {
                                        return (
                                            <div
                                                className="bg-gray-200 p-2 rounded-lg my-1 gap-2"
                                                key={index}
                                            >
                                                <div className="flex justify-between items-center px-3 mb-2">
                                                    <h4>{data?.value}</h4>
                                                    <Trash2 className="h-5 w-5" />
                                                </div>
                                                <UnitPopOver
                                                    handleComboboxUnitSelect={
                                                        handleComboboxUnitSelect
                                                    }
                                                    propertyId={data?.propertyId}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex justify-end items-end">
                        <Button
                            type="submit"
                            className="mt-4 bg-[#000] w-[150px]"
                            disabled={isCreateScriptLoading}
                        >
                            {isCreateScriptLoading && (
                                <Spinner className="text-white size-5 mr-1" />
                            )}
                            Add Response
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </DialogModal>
    );
};

export default ResponseModal;
