import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { useGetEventsQuery } from "@/redux/rtk-query/calendarApis";
import { addDays, format, startOfWeek } from "date-fns";
import { useMemo, useState } from "react";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { Dialog } from "../dialog";

interface Event {
    _id: string;
    title: string;
    startDate: string;
    endDate: string;
    description: string;
    color?: string;
}

interface Events {
    [date: string]: Event[];
}

const WeeklyCalendar = () => {
    const { data, isLoading, error } = useGetEventsQuery({});
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [currentWeekStart, setCurrentWeekStart] = useState(
        startOfWeek(new Date(), { weekStartsOn: 1 }),
    );
    const [calendarSelected, setCalendarSelected] = useState(new Date());

    const colors = ["#dbeafe", "#fecaca", "#fef08a", "#bbf7d0", "#e9d5ff"];

    const events: Events = useMemo(() => {
        if (!data) return {};

        return data.reduce((acc: Events, event: Event, index: number) => {
            const dateKey = format(new Date(event.startDate), "yyyy-MM-dd");
            if (!acc[dateKey]) {
                acc[dateKey] = [];
            }
            const eventWithColor = { ...event, color: colors[index % colors.length] };
            acc[dateKey].push(eventWithColor);
            return acc;
        }, {});
    }, [colors, data]);

    if (isLoading) {
        return (
            <div className="space-y-4">
                <div className="flex justify-center py-4">
                    <Skeleton width={250} height={40} />
                </div>
                <div className="overflow-x-auto rounded-md">
                    <table className="w-full border-collapse">
                        <tbody>
                            {[...Array(8)].map((_, rowIndex) => (
                                <tr key={rowIndex}>
                                    {[...Array(9)].map((_, colIndex) => (
                                        <td key={colIndex} className="border p-2 w-36 h-20">
                                            <Skeleton height={colIndex === 0 ? 40 : 80} />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
    if (error) return <div className="py-12 text-center">Error loading events</div>;

    const handleEventClick = (event: Event) => {
        setSelectedEvent(event);
        setIsDialogOpen(true);
    };

    const lightenColor = (color: any, percent: number = 20): string => {
        let r: number = parseInt(color.slice(1, 3), 16);
        let g: number = parseInt(color.slice(3, 5), 16);
        let b: number = parseInt(color.slice(5, 7), 16);

        r = Math.min(255, Math.floor(r + ((255 - r) * percent) / 100));
        g = Math.min(255, Math.floor(g + ((255 - g) * percent) / 100));
        b = Math.min(255, Math.floor(b + ((255 - b) * percent) / 100));

        return `rgba(${r},${g},${b}, 0.3)`;
    };

    const renderWeek = () => {
        const rows = [];
        const timeSlots = [];

        // Generate time slots
        for (let i = 0; i < 24; i++) {
            timeSlots.push(`${i.toString().padStart(2, "0")}:00`);
        }

        console.log(timeSlots);

        const header = (
            <tr key="header">
                <th className="border p-2 text-md font-semibold">Time</th>
                <th className="border p-2 h-20 text-center">
                    <Button
                        onClick={() => setCurrentWeekStart(addDays(currentWeekStart, -7))}
                        className="p-1 text-xl text-muted-foreground rounded-full"
                        variant="grey"
                    >
                        <MdArrowBack />
                    </Button>
                </th>
                {[...Array(7)].map((_, index) => {
                    const date = addDays(currentWeekStart, index);
                    return (
                        <th key={index} className="border p-2 h-20">
                            <div className="flex flex-col items-center">
                                <span className="text-xs font-light text-muted-foreground">
                                    {format(date, "EEE")}
                                </span>
                                <span className="text-md font-semibold">{format(date, "d")}</span>
                            </div>
                        </th>
                    );
                })}
                <th className="border p-2 h-20 text-center">
                    <Button
                        onClick={() => setCurrentWeekStart(addDays(currentWeekStart, 7))}
                        className="p-1 text-xl text-muted-foreground rounded-full"
                        variant="grey"
                    >
                        <MdArrowForward />
                    </Button>
                </th>
            </tr>
        );
        rows.push(header);

        timeSlots.forEach((timeSlot) => {
            const row = (
                <tr key={timeSlot}>
                    <td className="border p-2 text-center">{timeSlot}</td>
                    <td className="border p-2 text-center"></td>
                    {[...Array(7)].map((_, index) => {
                        const date = addDays(currentWeekStart, index);
                        const dateString = format(date, "yyyy-MM-dd");
                        const dayEvents = events[dateString] || [];
                        const slotEvents = dayEvents.filter((event) => {
                            const eventStart = new Date(event.startDate);
                            const eventEnd = new Date(event.endDate);
                            const eventStartHour = eventStart.getHours();
                            const eventEndHour = eventEnd.getHours();
                            const slotHour = parseInt(timeSlot.split(":")[0]);

                            // Check if event spans this hour
                            return slotHour >= eventStartHour && slotHour <= eventEndHour;
                        });

                        return slotEvents.length > 0 ? (
                            <td key={index} className="border w-36 h-20">
                                {slotEvents.map((event) => (
                                    <div
                                        key={event._id}
                                        onClick={() => handleEventClick(event)}
                                        className="relative p-1 cursor-pointer text-sm w-full"
                                        style={{
                                            background: `linear-gradient(to bottom, ${event.color} 50%, ${lightenColor(event.color)} 50%)`,
                                            border: `2px solid ${event.color}`,
                                        }}
                                    >
                                        <p className="font-medium truncate">{event.title}</p>
                                        <p>
                                            {format(new Date(event.startDate), "h:mm a")} -
                                            {format(new Date(event.endDate), "h:mm a")}
                                        </p>
                                    </div>
                                ))}
                            </td>
                        ) : (
                            <td key={index} className="border p-2 w-36 h-20"></td>
                        );
                    })}
                    <td className="border p-2 text-center"></td>
                </tr>
            );
            rows.push(row);
        });

        return rows;
    };

    return (
        <div className="">
            <div className="flex justify-center py-4 items-center">
                <Popover>
                    <PopoverTrigger asChild>
                        <span className="mx-4 border rounded-full py-2 px-3 cursor-pointer">
                            {format(currentWeekStart, "MMMM d, yyyy")} -
                            {format(addDays(currentWeekStart, 6), "MMMM d, yyyy")}
                        </span>
                    </PopoverTrigger>
                    <PopoverContent className="text-sm w-[350px] p-0" align="start">
                        <Calendar
                            mode="single"
                            selected={calendarSelected}
                            onSelect={(e) => {
                                if (e) {
                                    const selectedDate = new Date(e?.toDateString());
                                    const weekStart = startOfWeek(selectedDate, {
                                        weekStartsOn: 1,
                                    });
                                    setCurrentWeekStart(weekStart);
                                    setCalendarSelected(selectedDate);
                                }
                            }}
                            disabled={(date) => date > new Date() || date < new Date("1900-01-01")}
                            initialFocus
                            className={"[&_td]:h-10 [&_tr]:h-10 [&_tr]:pt-0"}
                            weekStartsOn={1}
                        />
                    </PopoverContent>
                </Popover>
            </div>
            <div className="overflow-x-auto rounded-md">
                <table className="w-full border-collapse">
                    <tbody>{renderWeek()}</tbody>
                </table>
            </div>
            {selectedEvent && (
                <Dialog
                    heading={"Event Details"}
                    isOpen={isDialogOpen}
                    setIsOpen={setIsDialogOpen}
                    footer={
                        <div className="flex justify-end">
                            <Button variant="secondary" onClick={() => setIsDialogOpen(false)}>
                                Close
                            </Button>
                        </div>
                    }
                    content={
                        <div
                            className="p-3 rounded-lg mb-3"
                            style={{ backgroundColor: selectedEvent.color }}
                        >
                            <p className="mt-2 mb-4 text-lg font-semibold">{selectedEvent.title}</p>
                            <p className="mt-2 mb-4">{selectedEvent.description}</p>
                            <div className="flex items-center gap-2 my-2">
                                <label className="text-md font-semibold m-0 text-black">
                                    Start:{" "}
                                </label>
                                <p className="m-0">
                                    {new Date(selectedEvent.startDate).toLocaleString()}
                                </p>
                            </div>
                            <div className="flex items-center gap-2 my-2">
                                <label className="text-md font-semibold m-0 text-black">
                                    End:{" "}
                                </label>
                                <p className="m-0">
                                    {new Date(selectedEvent.endDate).toLocaleString()}
                                </p>
                            </div>
                        </div>
                    }
                />
            )}
        </div>
    );
};

export default WeeklyCalendar;
