import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { capitalizeEachWord } from "@/utils/capitalizeEachWords";
import { NavLink } from "react-router-dom";

export const Breadcrumbs = (props: { name: string | undefined; className?: string }) => (
    <div className={props.className}>
        <Breadcrumb>
            <BreadcrumbList>
                <BreadcrumbItem>
                    <NavLink to="/property-management/dashboard">Home</NavLink>
                </BreadcrumbItem>
                {props.name && (
                    <>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage>{capitalizeEachWord(props.name)}</BreadcrumbPage>
                        </BreadcrumbItem>
                    </>
                )}
            </BreadcrumbList>
        </Breadcrumb>
    </div>
);
