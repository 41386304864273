import { getUserBasePath } from "@/utils/getBaseUserBasedPath";
import { toast } from "react-toastify";
import {
    TComplianceState,
    TComplianceStateCombined,
    TBuildingResponse,
    TProperty,
    TPropertyBuilding,
    TPropertyState,
    TPropertyStats,
    TPropertyWithId,
    TWithNavigate,
    TBuildingResponseWithPagination,
} from "../../types";
import { catchAll } from "../../utils/catch";
import { api } from "./api";

const propertiesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getProperties: builder.query<any, any>({
            providesTags: ["PROPERTIES"],
            query: (companyId) => ({
                url: `properties?companyId=${companyId}`,
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    toast.error("Something went wrong! Please try again later.");
                }
            },
        }),

        getPropertiesWithId: builder.query<TPropertyWithId[], string>({
            providesTags: ["PROPERTIES"],
            query: (companyId) => ({
                url: `properties/companies/${companyId}`,
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    toast.error("Something went wrong! Please try again later.");
                }
            },
        }),

        getPropertiesState: builder.query<TPropertyState, string>({
            query: (companyId) => ({
                url: `properties/company-stats/${companyId || ""}`,
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    toast.error("Something went wrong! Please try again later.");
                }
            },
        }),

        getPropertyCompliances: builder.query<TPropertyState, void>({
            query: () => ({
                url: "properties/compliances",
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    toast.error("Something went wrong! Please try again later.");
                }
            },
        }),

        getPropertyCompliancesById: builder.query<any, any>({
            query: (companyId) => ({
                url: `properties/compliances?id=${companyId}`,
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    toast.error("Something went wrong! Please try again later.");
                }
            },
        }),

        // getProperty: builder.query<TProperty, string>({
        //     providesTags: (_, __, arg) =>
        //         typeof arg === "string"
        //             ? [{ type: `PROPERTIES`, id: arg }]
        //             : [{ type: "PROPERTIES" }],
        //     query: (id) => ({
        //         url: `properties?id=${id}`,
        //     }),
        //     onQueryStarted: async (_, { queryFulfilled }) => {
        //         const response = await catchAll(queryFulfilled);
        //         if (response.error) {
        //             toast.error("Something went wrong! Please try again later.");
        //         }
        //     },
        // }),

        getProperty: builder.query<TProperty, { id: string; companyId: string }>({
            providesTags: (_, __, { id }) => [{ type: "PROPERTIES", id }],
            query: ({ id, companyId }) => ({
                url: `properties?id=${id}&companyId=${companyId}`,
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    console.log(response.error);
                    toast.error("Something went wrong! Please try again later.");
                }
            },
        }),

        properties: builder.mutation({
            query: (data) => ({
                url: "properties",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["BUILDING", "PROPERTIES"],
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    console.log(response.error);
                    toast.error("Something went wrong! Please try again later.");
                }
            },
        }),

        updateProperties: builder.mutation({
            query: (data) => ({
                url: "properties",
                method: "PUT",
                body: data,
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    console.log(response.error);
                    toast.error("Something went wrong! Please try again later.");
                }
            },
            invalidatesTags: ["BUILDING", "PROPERTIES"],
        }),

        deleteProperty: builder.mutation({
            query: (id) => ({
                url: `properties/${id}`,
                method: "DELETE",
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    toast.error("Failed to delete property. Please try again later.");
                }
            },
            invalidatesTags: ["BUILDING", "PROPERTIES"],
        }),

        // getTenants: builder.query<TGetAllTenantsTransformedResponse[], string>({
        //     providesTags: ["TENANTS"],
        //     query: (companyId: string) => `auth/users/tenants/${companyId}`,
        //     transformResponse: async (response: TGetAllTenants) => {
        //         return response.data.map((d) => ({
        //             id: d._id,
        //             email: d.email,
        //             name: {
        //                 fullName: capitalize(d.firstName) + " " + capitalize(d.lastName),
        //                 firstName: d.firstName,
        //                 lastName: d.lastName,
        //             },
        //             phone: d.phone,
        //             propertyId: d.providerId,
        //             image: d.imageUrl,
        //             status: d.status,
        //         }));
        //     },
        // }),

        getTenantDetails: builder.query<any, any>({
            query: (tenantId) => ({
                url: `properties/tenant/${tenantId}`,
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    toast.error("Something went wrong! Please try again later.");
                }
            },
        }),

        getComplianceStat: builder.query<TComplianceState, string>({
            query: (companyId) => ({
                url: `properties/compliance-stats/${companyId}`,
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    toast.error("Something went wrong! Please try again later.");
                }
            },
        }),

        getComplianceGraphStats: builder.query<TComplianceStateCombined, string>({
            query: (companyId) => ({
                url: `properties/compliance-combined-stats/${companyId}`,
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    toast.error("Something went wrong! Please try again later.");
                }
            },
        }),
    }),
});

const propertyStatsApis = api.injectEndpoints({
    endpoints: (builder) => ({
        getPropertyStats: builder.query<TPropertyStats, string>({
            query: (userId) => `properties/stats/${userId}`,
            // it provides PROPERTIES so that when the properties are updated,
            // the stats are updated as well
            providesTags: ["PROPERTIES"],
        }),
    }),
});

const propertyBuildingApis = api.injectEndpoints({
    endpoints: (builder) => ({
        createBuildingEntry: builder.mutation<TBuildingResponse, TPropertyBuilding>({
            query: (args) => {
                const { ...body } = args;

                return {
                    url: `properties/buildings`,
                    method: "POST",
                    body,
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Failed to create building entry");
                    return;
                }

                toast.success("Property Created successfully");
            },
            invalidatesTags: ["BUILDING"],
        }),
        getBuilding: builder.query<
            TBuildingResponse[],
            { id: string; type: "BUILDING" | "COMPANY" }
        >({
            providesTags: (_, __, arg) => [{ type: "BUILDING", id: arg.id }],
            query: (arg) => `properties/buildings/${arg.id}`,
            transformResponse: (response: TBuildingResponse) => {
                const res = [
                    {
                        ...response,
                        ...(response?.properties
                            ? { properties: response.properties }
                            : { properties: [] }),
                    },
                ];
                return res;
            },
        }),

        getBuildingsByCompany: builder.query<
            TBuildingResponseWithPagination,
            {
                id: string;
                type: "COMPANY" | "BUILDING";
                limit?: number;
                skip?: number;
                sort?: string;
                search?: string;
            }
        >({
            providesTags: (_, __, arg) => [{ type: "BUILDING", id: arg.id }],
            query: (arg) => ({
                url: `properties/companies/${arg.id}/buildings`,
                params: {
                    limit: arg.limit,
                    skip: arg.skip,
                    sort: arg.sort,
                    search: arg.search,
                },
            }),
            transformResponse: (response: TBuildingResponseWithPagination) => response,
        }),

        updateBuildingEntry: builder.mutation<
            TBuildingResponse,
            TWithNavigate<Partial<TPropertyBuilding> & { id: string }>
        >({
            query: (args) => {
                const { userType, id, ...body } = args;

                return {
                    url: `properties/buildings/${id}`,
                    method: "PUT",
                    body,
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Failed to update building entry");
                    return;
                }

                toast.success("Building entry update successfully");
            },
            invalidatesTags: ["BUILDING"],
        }),

        deleteBuildingEntry: builder.mutation<TBuildingResponse, TWithNavigate<{ id: string }>>({
            query: (args) => {
                return {
                    url: `properties/buildings/${args.id}`,
                    method: "DELETE",
                };
            },
            onQueryStarted: async (args, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Failed to delete building entry");
                    return;
                }

                toast.success("Building deleted successfully");

                const path = getUserBasePath(args.userType);

                args.navigate(`${path}/properties`);
            },
            invalidatesTags: ["BUILDING"],
        }),
    }),
});

export const {
    useGetPropertiesQuery,
    useGetPropertiesWithIdQuery,
    useGetPropertiesStateQuery,
    useGetPropertyCompliancesQuery,
    useGetPropertyCompliancesByIdQuery,
    useGetPropertyQuery,
    usePropertiesMutation,
    useUpdatePropertiesMutation,
    useDeletePropertyMutation,
    useGetTenantDetailsQuery,
    useGetComplianceStatQuery,
    useGetComplianceGraphStatsQuery,
} = propertiesApi;
export const { useGetPropertyStatsQuery } = propertyStatsApis;
export const {
    useCreateBuildingEntryMutation,
    useGetBuildingQuery,
    useUpdateBuildingEntryMutation,
    useDeleteBuildingEntryMutation,
    useGetBuildingsByCompanyQuery,
} = propertyBuildingApis;
