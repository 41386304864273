import styles from "./Analytics.module.css";
import { BackButton } from "../../../../../components/common/backButton";
import { Tabs } from "@mantine/core";
import { LineChart } from "../../../../../components/chart/lineChart";
import { Dropdown } from "../../../../../components/common/dropdown";
import { IoIosArrowDown } from "react-icons/io";
import { useGetMyDetailsQuery } from "../../../../../redux/rtk-query/authApis";
import { useGetCallLogsQuery } from "../../../../../redux/rtk-query/aiApis";
import { useGetJobsQuery } from "@/redux/rtk-query/jobsApis";
import { CardTitle } from "@/components/ui/card";
import { Header } from "@/components/common/headers/Header";

export function Analytics() {
    const options = [
        { value: "7_days", key: "Last 7 days" },
        { value: "14_days", key: "Last 14 days" },
        { value: "last_month", key: "Last Month" },
        { value: "last_3_months", key: "Last 3 Months" },
    ];
    const labels = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const { data: myDetails } = useGetMyDetailsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const { data: callLogs } = useGetCallLogsQuery(
        { contractorId: myDetails?._id ?? "", limit: 10, page: 1 },
        { skip: !myDetails?._id, refetchOnMountOrArgChange: true },
    );
    const { data: allJobs } = useGetJobsQuery(
        { type: "ACTIVE", myDetails },
        {
            refetchOnMountOrArgChange: true,
            skip: !myDetails,
        },
    );
    return (
        <div className="w-full overflow-y-scroll">
            <Header
                image={myDetails?.imageUrl}
                heading={"AI Assistant Analytics"}
                firstName={myDetails?.firstName}
                lastName={myDetails?.lastName}
                hasSearchBar={true}
            />
            <div className="mx-5 pt-2.5">
                <div className={styles.TabBackBtn}>
                    <BackButton />
                    <CardTitle className="my-0">AI Assistant Analytics</CardTitle>
                </div>
                <div className={styles.filterContainer}>
                    <span>Overview</span>
                    <Dropdown items={options}>
                        <div className={styles.dropdown}>
                            <span>{options[0].key}</span>
                            <IoIosArrowDown style={{ fontSize: "large" }} />
                        </div>
                    </Dropdown>
                </div>
                <div className={styles.tabs}>
                    <Tabs defaultValue="Total Calls">
                        <Tabs.List>
                            <Tabs.Tab value="Total Calls" className="!bg-white">
                                <h6>Total Calls</h6>
                                <h4>{callLogs ? callLogs.length : 0}</h4>
                                <div className={styles.infoDiv}>
                                    <span className={`${styles.percent} ${styles.up}`}>
                                        {callLogs ? callLogs.length : 0}%
                                    </span>
                                    <span className={styles.day}>vs last week</span>
                                </div>
                            </Tabs.Tab>
                            <Tabs.Tab value="Leads generated" className="!bg-white">
                                <h6>Leads generated</h6>
                                <h4>0</h4>
                                <div className={styles.infoDiv}>
                                    <span className={`${styles.percent} ${styles.up}`}>0%</span>
                                    <span className={styles.day}>vs last week</span>
                                </div>
                            </Tabs.Tab>
                            <Tabs.Tab value="Jobs scheduled" className="!bg-white">
                                <h6>Jobs scheduled</h6>
                                <h4>{allJobs ? allJobs.length : 0}</h4>
                                <div className={styles.infoDiv}>
                                    <span className={`${styles.percent} ${styles.up}`}>
                                        {allJobs ? allJobs.length : 0}%
                                    </span>
                                    <span className={styles.day}>vs last week</span>
                                </div>
                            </Tabs.Tab>
                            <Tabs.Tab value="Total Invoice sent" className="!bg-white">
                                <h6>Total Invoice sent</h6>
                                <h4>0</h4>
                                <div className={styles.infoDiv}>
                                    <span className={`${styles.percent} ${styles.up}`}>0%</span>
                                    <span className={styles.day}>vs last week</span>
                                </div>
                            </Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="Total Calls">
                            <div className={styles.chartContainer}>
                                <LineChart title="Total Calls" labels={labels} />
                            </div>
                        </Tabs.Panel>
                        <Tabs.Panel value="Total Invoice sent">
                            <div className={styles.chartContainer}>
                                <LineChart title="Total Invoice sent" labels={labels} />
                            </div>
                        </Tabs.Panel>
                        <Tabs.Panel value="Leads recieved">
                            <div className={styles.chartContainer}>
                                <LineChart title="Leads recieved" labels={labels} />
                            </div>
                        </Tabs.Panel>
                        <Tabs.Panel value="Jobs scheduled">
                            <div className={styles.chartContainer}>
                                <LineChart title="Jobs scheduled" labels={labels} />
                            </div>
                        </Tabs.Panel>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}
