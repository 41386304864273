import { useState, useEffect } from "react";
import { TUser } from "@/types";
import { UploadImage } from "../UploadImage";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useCreateCompanyMutation, useUpdateCompanyMutation } from "@/redux/rtk-query/companyApis";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { toast } from "react-toastify";

type OrganizationDetailsFormProps = {
    user: TUser | undefined;
    profileImage: string;
    setProfileImage: (image: string) => void;
};

const TYPE = [
    { key: "Contractor", value: "CONTRACTOR" },
    { key: "Property Management", value: "PROPERTY_MANAGEMENT" },
    { key: "Sole Proprietary", value: "SOLE_PROPRIETORY" },
    { key: "Supplier Company", value: "SUPPLIER_COMPANY" },
];

const COMPANY_SIZE = [
    { key: "1-10 employees", value: "1-10" },
    { key: "11-50 employees", value: "11-50" },
    { key: "51-200 employees", value: "51-200" },
    { key: "201-500 employees", value: "201-500" },
    { key: "501-1000 employees", value: "501-1000" },
    { key: "More than 1000 employees", value: "1000+" },
];

const formSchema = z.object({
    companyName: z.string().min(1, "Company name is required"),
    companyBio: z.string(),
    type: z.string().min(1, "Company type is required"),
    companySize: z.string().min(1, "Company size is required"),
});

const OrganizationDetailsForm = ({
    user,
    profileImage,
    setProfileImage,
}: OrganizationDetailsFormProps) => {
    const [isImageChanged, setIsImageChanged] = useState(false);
    const [, { isLoading }] = useCreateCompanyMutation();
    const [updateCompany, { isLoading: isUpdating }] = useUpdateCompanyMutation();
    const { refetch } = useGetMyDetailsQuery();

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            companyName: "",
            companyBio: "",
            type: "",
            companySize: "",
        },
        values: {
            companyName: user?.companyInfo?.name || "",
            companyBio: user?.companyInfo?.bio || "",
            type: user?.companyInfo?.type || "",
            companySize: user?.companyInfo?.size || "",
        },
    });

    useEffect(() => {
        setIsImageChanged(profileImage !== user?.companyInfo?.imageUrl);
    }, [profileImage, user?.companyInfo?.imageUrl]);

    async function onSubmit(values: z.infer<typeof formSchema>) {
        try {
            const payload = {
                name: values.companyName,
                size: values.companySize,
                status: "ACTIVE",
                type: values.type,
                bio: values.companyBio,
                imageUrl: profileImage,
            };

            await updateCompany({
                _id: user?.companyId,
                ...payload,
            }).unwrap();
            setIsImageChanged(false);
            refetch();
        } catch (error) {
            console.log(error);

            toast.error("Failed to update company details");
        }
    }

    return (
        <>
            <div className="">
                <UploadImage
                    profileImage={profileImage}
                    setProfileImage={(image) => {
                        setProfileImage(image);
                        setIsImageChanged(true);
                    }}
                    title="Upload new image"
                />
            </div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="max-w-2xl py-7 space-y-6">
                    <FormField
                        control={form.control}
                        name="companyBio"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Organisational Details</FormLabel>
                                <FormControl>
                                    <Textarea
                                        placeholder="Enter company details"
                                        {...field}
                                        className="min-h-[100px] focus-visible:ring-1"
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                    />

                    <div className="flex gap-4">
                        <FormField
                            control={form.control}
                            name="type"
                            render={({ field }) => (
                                <FormItem className="flex-1">
                                    <FormLabel>Type</FormLabel>
                                    <Select
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                        value={field.value}
                                    >
                                        <FormControl>
                                            <SelectTrigger className="focus-visible:ring-1">
                                                <SelectValue placeholder="Select company type" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {TYPE.map((type) => (
                                                <SelectItem
                                                    defaultChecked={field.value === type.value}
                                                    key={type.value}
                                                    value={type.value}
                                                >
                                                    {type.key}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="companySize"
                            render={({ field }) => (
                                <FormItem className="flex-1">
                                    <FormLabel>Company Size</FormLabel>
                                    <Select
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                        value={field.value}
                                    >
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select company size" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {COMPANY_SIZE.map((size) => (
                                                <SelectItem
                                                    defaultChecked={field.value === size.value}
                                                    key={size.value}
                                                    value={size.value}
                                                >
                                                    {size.key}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </FormItem>
                            )}
                        />
                    </div>

                    <div className="flex gap-3">
                        <Button
                            type="submit"
                            disabled={
                                (!form.formState.isDirty && !isImageChanged) ||
                                isLoading ||
                                isUpdating
                            }
                        >
                            {isLoading || isUpdating ? "Saving" : "Save"}
                        </Button>
                        {(isImageChanged ||
                            form.getValues().companyName !== (user?.companyInfo?.name || "") ||
                            form.getValues().companyBio !== (user?.companyInfo?.bio || "") ||
                            form.getValues().type !== (user?.companyInfo?.type || "") ||
                            form.getValues().companySize !== (user?.companyInfo?.size || "")) && (
                            <Button
                                type="button"
                                variant="outline"
                                onClick={() => {
                                    form.reset();
                                    setProfileImage(user?.companyInfo?.imageUrl || "");
                                    setIsImageChanged(false);
                                }}
                                disabled={isLoading || isUpdating}
                            >
                                Cancel
                            </Button>
                        )}
                    </div>
                </form>
            </Form>
        </>
    );
};

export default OrganizationDetailsForm;
