import { TUserType } from "../types";
import { UserType } from "./interface/auth.interface";

const PATHS: Record<UserType, string> = {
    CONTRACTOR: "/contractor",
    HOMEOWNER: "/home-owner",
    PROPERTY_MANAGER: "/property-management",
    SUPPLIERS: "/supplies",
    TENANT: "/tenant",
};

export const getUserBasePath = (userType: TUserType | undefined) => {
    if (!userType) return "";

    return PATHS[userType];
};
