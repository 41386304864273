import { DropdownMenuDemo } from "@/components/common/dropdown/DropDown";
import { MdAcUnit } from "react-icons/md";
import { useNavigate } from "react-router-dom";

interface ActionsCellProps {
    id: string;
    text: string;
    onClick?: (e: React.MouseEvent) => void;
}

export const ActionsCell: React.FC<ActionsCellProps> = ({ id, text, onClick }) => {
    const navigate = useNavigate();
    const handleUnitsClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (onClick) {
            onClick(e);
        } else {
            navigate(`/property-management/properties/update-unit/${id}`);
        }
    };

    return (
        <DropdownMenuDemo
            field={[
                {
                    text: text,
                    Icon: MdAcUnit,
                    onClick: (e?: React.MouseEvent) => {
                        e && handleUnitsClick(e);
                    },
                },
            ]}
        />
    );
};
