import { Button } from "@/components/ui/button";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import {
    Check,
    Loader2,
    Plus,
    SparklesIcon,
    Trash2Icon,
    UploadIcon,
    X,
    FileIcon,
    XIcon,
    ChevronDown,
    ChevronUp,
} from "lucide-react";
import { WorkOrderFormValues } from "./workOrderSchema";
import { useFormContext } from "react-hook-form";
import { useGetAIJobRecommendationMutation } from "@/redux/rtk-query/aiApis";
import { toast, useGetMyDetailsQuery, useState } from "@/pages/propertyManagementKnowledgeBase";
import { IoLocationOutline } from "react-icons/io5";
import { useUploadToAWSMutation } from "@/redux/rtk-query/uploadApis";
import {
    Command,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { categories } from "../Workorder.constant";
import { useEffect, useRef } from "react";
import { catchAll } from "@/utils/catch";
import { Progress } from "@/components/ui/progress";
import LBModal from "@/components/lBComponents/LBModal";
import { FormStatusProps } from "./AddWorkContainer";
import Skeleton from "react-loading-skeleton";

type AiResponseProp = {
    title: string;
    description: string;
    categories: string[];
};

const MAX_DOCUMENTS = 5;

const AddWorkOrder = ({
    mode,
    currentPreviewOrderInfo,
    currentWorkOrderDocuments,
    currentDocumentsLoading,
    isUploading,
    isUploadingDoc,
    setIsUploading,
    setIsUploadingDoc,
    setMarkedAsDeleted,
}: FormStatusProps & {
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
    setIsUploadingDoc: React.Dispatch<React.SetStateAction<boolean>>;
    setMarkedAsDeleted: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
    const {
        control,
        formState: { errors, isSubmitted },
        getValues,
        trigger,
        clearErrors,
        setValue,
    } = useFormContext<WorkOrderFormValues>();
    const [getTitleFromAi, { isLoading }] = useGetAIJobRecommendationMutation();
    const { data: myDetails } = useGetMyDetailsQuery();
    const [uploadImageToAWS] = useUploadToAWSMutation();
    const [aiResponse, setAiResponse] = useState<AiResponseProp>();
    const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);
    const [localPhotos, setLocalPhotos] = useState<string[]>([]);
    const [uploadProgress, setUploadProgress] = useState<number[]>([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [hasGeneratedOnce, setHasGeneratedOnce] = useState(false);
    const [documents, setDocuments] = useState<{ fileName: string; filePath: string }[]>([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const currentPhotos = getValues("photoUrl") || [];
        const uniquePhotos = Array.from(
            new Set(currentPhotos.filter((url: string) => url && url.trim() !== "")),
        );
        setLocalPhotos(uniquePhotos);
        setValue("photoUrl", uniquePhotos, { shouldValidate: true });
    }, [getValues, setValue]);

    const handleAIGeneration = async () => {
        const title = getValues("title");
        if (!title) {
            await trigger(["title"]);
            return;
        }

        if (hasGeneratedOnce) {
            setShowConfirmModal(true);
        } else {
            await generateWithAI();
        }
    };

    const generateWithAI = async () => {
        const title = getValues("title");
        let res;
        try {
            res = await getTitleFromAi({ prompt: title, myDetails });
        } catch (error) {
            toast.error("Failed to generate the title, please try again later!");
        }
        res && setAiResponse(res?.data?.Answer);

        const titleByAi = res?.data?.Answer?.title || "Please improve your keyword";
        setValue("title", titleByAi);
        setHasGeneratedOnce(true);
    };

    const address =
        mode === "Preview" || mode === "Edit"
            ? currentPreviewOrderInfo?.address
            : getValues("address");
    const propertyAddress = address
        ? [
              getValues("unit")?.name || currentPreviewOrderInfo?.propertyDetails?.name,
              address?.mainStreet,
              address?.city,
              address?.country,
              address?.postalCode,
          ]
              .filter(Boolean)
              .join(", ")
        : "";
    useEffect(() => {
        setValue("description", aiResponse?.description || "");
    }, [aiResponse, setValue]);
    const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
        textarea.style.height = "30vh";
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    useEffect(() => {
        setValue(
            "description",
            aiResponse?.description || currentPreviewOrderInfo?.jobDetail?.description || "",
        );
        if (descriptionRef.current) {
            adjustTextareaHeight(descriptionRef.current);
        }
    }, [aiResponse, setValue, currentPreviewOrderInfo, descriptionRef]);

    useEffect(() => {
        if (descriptionRef.current) {
            adjustTextareaHeight(descriptionRef.current);
        }
    }, []);

    const simulateProgress = (index: number) => {
        let progress = 0;
        const interval = setInterval(() => {
            progress += Math.random() * 20;
            if (progress > 90) {
                clearInterval(interval);
                return;
            }
            setUploadProgress((prev) => {
                const newProgress = [...prev];
                newProgress[index] = Math.min(progress, 90);
                return newProgress;
            });
        }, 200);
        return interval;
    };

    const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;

        const fileArray = Array.from(e.target.files);
        const remainingSlots = 5 - localPhotos.length;

        if (fileArray.length > remainingSlots) {
            toast.error("Max upload limit of 5 photos reached!");
            e.target.value = "";
            return;
        }

        setIsUploading(true);
        setUploadProgress(new Array(fileArray.length).fill(0));

        const uploadedUrls: string[] = [];
        const intervals: NodeJS.Timeout[] = [];

        for (let i = 0; i < fileArray.length; i++) {
            const file = fileArray[i];
            const formData = new FormData();
            formData.append("file", file);
            formData.append("folder", "work_orders");

            intervals.push(simulateProgress(i));

            try {
                const response = await catchAll(uploadImageToAWS(formData).unwrap());

                const url = response?.data?.url;
                if (url) {
                    if (localPhotos.includes(url)) {
                        toast.error(`Image "${file.name}" is already uploaded.`);
                    } else {
                        uploadedUrls.push(url);
                        setUploadProgress((prev) => {
                            const newProgress = [...prev];
                            newProgress[i] = 100;
                            return newProgress;
                        });
                    }
                }
            } catch (error) {
                toast.error(`Failed to upload image: ${file.name}`);
                console.error("Upload error:", error);
                setUploadProgress((prev) => {
                    const newProgress = [...prev];
                    newProgress[i] = 0;
                    return newProgress;
                });
            }
        }

        intervals.forEach(clearInterval);
        const updatedPhotos = Array.from(new Set([...localPhotos, ...uploadedUrls])).filter(
            (url) => url && url.trim() !== "",
        );
        setLocalPhotos(updatedPhotos);
        setValue("photoUrl", updatedPhotos, { shouldValidate: true });
        setIsUploading(false);
        setUploadProgress([]);
        e.target.value = "";
    };

    const handleDeleteImage = (index: number) => {
        const updatedPhotos = localPhotos.filter((_, i) => i !== index);
        setLocalPhotos(updatedPhotos);
        setValue("photoUrl", updatedPhotos, { shouldValidate: true });
    };

    const handleDocumentUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;

        const fileArray = Array.from(e.target.files);
        const currentDocuments = getValues("documents") || [];
        const remainingSlots = MAX_DOCUMENTS - currentDocuments.length;

        if (fileArray.length > remainingSlots) {
            toast.error(`You can only upload up to ${MAX_DOCUMENTS} documents.`);
            e.target.value = "";
            return;
        }

        setIsUploadingDoc(true);

        for (const file of fileArray) {
            if (currentDocuments.length >= MAX_DOCUMENTS) break;

            const formData = new FormData();
            formData.append("file", file);
            formData.append("folder", "work_orders_documents");

            try {
                const response = await uploadImageToAWS(formData);
                const url = response?.data?.url;
                if (url) {
                    const newDocument = { fileName: file.name, filePath: url };
                    setValue("documents", [...currentDocuments, newDocument]);
                    setDocuments((prev) => [...prev, newDocument]);
                }
            } catch (error) {
                toast.error(`Failed to upload document: ${file.name}`);
                console.error("Upload error:", error);
            }
        }

        setIsUploadingDoc(false);
        e.target.value = "";
    };

    const handleDeleteDocument = (index: number) => {
        const currentDocuments = getValues("documents") || [];
        const deletedDocument = currentDocuments[index];
        const updatedDocuments = currentDocuments.filter((_, i) => i !== index);
        setValue("documents", updatedDocuments);
        setDocuments(updatedDocuments);

        // Add the deleted document's filePath to markedAsDeleted
        console.log(deletedDocument);

        mode === "Edit" && setMarkedAsDeleted((p) => [...p, deletedDocument?._id || ""]);
    };

    useEffect(() => {
        if (currentWorkOrderDocuments) {
            console.log(currentWorkOrderDocuments);

            setDocuments(
                currentWorkOrderDocuments.map((doc) => ({
                    fileName: doc.fileName,
                    filePath: doc.filePath,
                })),
            );
        }
    }, [currentWorkOrderDocuments]);

    return (
        <div className="flex-1">
            <div className="">
                <div className="flex items-center gap-1">
                    <IoLocationOutline size={20} />
                    <p className="text-gray-600 text-sm">{propertyAddress}</p>
                </div>
                <FormField
                    control={control}
                    name="title"
                    render={({ field }) => (
                        <FormItem className="mt-2">
                            <FormControl>
                                <Textarea
                                    className={`border-none ring-0 shadow-none px-0 focus:ring-0 focus-visible:ring-0 text-lg focus:outline-none focus-visible:ring-gray-400 ring-offset-0 resize-none overflow-hidden ${
                                        errors.title ? "border-b-2 border-red-500" : ""
                                    }`}
                                    placeholder="Add Title"
                                    {...field}
                                    disabled={mode === "Preview"}
                                    rows={2}
                                    onInput={(e) => {
                                        const target = e.target as HTMLTextAreaElement;
                                        target.style.height = "auto";
                                        target.style.height = `${target.scrollHeight}px`;
                                        if (target.value.trim() !== "") {
                                            clearErrors("title");
                                        }
                                    }}
                                />
                            </FormControl>
                            {errors.title && (
                                <p className="text-red-500 text-sm mt-1">Title is required</p>
                            )}
                        </FormItem>
                    )}
                />
                {mode !== "Preview" && (
                    <Button
                        type="button"
                        variant={"default"}
                        onClick={handleAIGeneration}
                        disabled={isLoading}
                        className="bg-transparent  hover:bg-transparent shadow-none ring-1 ring-pink-300 rounded-md mt-2 group"
                    >
                        {isLoading ? (
                            <>
                                <Loader2 className="mr-2 h-4 w-4 animate-spin text-purple-500" />
                                <span
                                    style={{
                                        background:
                                            "linear-gradient(to right, rgb(128, 0, 128), rgb(255, 105, 180))",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                    }}
                                >
                                    Generating
                                </span>
                            </>
                        ) : (
                            <>
                                <SparklesIcon className="mr-1 " size={16} color="purple" />
                                <span
                                    style={{
                                        background:
                                            "linear-gradient(to right, rgb(128, 0, 128), rgb(255, 105, 180))",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                    }}
                                >
                                    Generate with AI
                                </span>
                            </>
                        )}
                    </Button>
                )}
                <FormField
                    control={control}
                    name="category"
                    render={({ field }) => (
                        <FormItem className="w-full mt-6">
                            {mode !== "Preview" && (
                                <Popover open={open} onOpenChange={setOpen}>
                                    <PopoverTrigger asChild>
                                        <FormControl>
                                            <Button
                                                variant="outline"
                                                role="combobox"
                                                className={cn(
                                                    "max-w-52 text-ellipsis text-gray-500 overflow-hidden w-full justify-between",
                                                    isDropdownDisabled
                                                        ? "opacity-50 cursor-not-allowed"
                                                        : "",
                                                )}
                                                disabled={isDropdownDisabled}
                                                onClick={() => clearErrors("category")}
                                            >
                                                {field.value?.length > 0
                                                    ? `${field.value.length} categories selected`
                                                    : "Select categories"}
                                                {open ? (
                                                    <ChevronUp className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                                ) : (
                                                    <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                                )}
                                            </Button>
                                        </FormControl>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-[300px] p-0">
                                        <Command>
                                            <CommandInput placeholder="Search category..." />
                                            <CommandList>
                                                <CommandGroup>
                                                    {categories?.map((category: string) => (
                                                        <CommandItem
                                                            key={category}
                                                            onSelect={() => {
                                                                const currentValue = Array.isArray(
                                                                    field.value,
                                                                )
                                                                    ? field.value
                                                                    : [];
                                                                const updatedValue =
                                                                    currentValue.includes(category)
                                                                        ? currentValue.filter(
                                                                              (item: string) =>
                                                                                  item !== category,
                                                                          )
                                                                        : [
                                                                              ...currentValue,
                                                                              category,
                                                                          ];
                                                                if (updatedValue.length <= 10) {
                                                                    field.onChange(updatedValue);
                                                                    setIsDropdownDisabled(
                                                                        updatedValue.length === 10,
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <Check
                                                                className={cn(
                                                                    "mr-2 h-4 w-4",
                                                                    field.value?.includes(category)
                                                                        ? "opacity-100"
                                                                        : "opacity-0",
                                                                )}
                                                            />
                                                            {category}
                                                        </CommandItem>
                                                    ))}
                                                </CommandGroup>
                                            </CommandList>
                                        </Command>
                                    </PopoverContent>
                                </Popover>
                            )}
                            {field.value?.length > 0 && (
                                <div className="flex flex-wrap gap-1 mt-2">
                                    {field.value.map((category: string) => (
                                        <div
                                            aria-disabled={mode === "Preview"}
                                            key={category}
                                            className="flex items-center py-1 px-2 text-xs transition-colors duration-150 hover:bg-[#aaddf4] rounded-md bg-[#CCEFFF] gap-2 cursor-pointer aria-disabled:bg-[#CCEFFF]/70"
                                        >
                                            {category}
                                            {mode !== "Preview" && (
                                                <X
                                                    className="h-3 w-3 cursor-pointer"
                                                    onClick={() => {
                                                        const updatedValue = field.value.filter(
                                                            (item: string) => item !== category,
                                                        );
                                                        field.onChange(updatedValue);
                                                        setIsDropdownDisabled(false);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {aiResponse?.categories && (
                                <div className="py-3">
                                    <p className="text-sm mb-2">AI Suggested Categories:</p>
                                    <div className="flex flex-wrap gap-1">
                                        {aiResponse.categories.map((category: string) => (
                                            <div
                                                key={category}
                                                className="flex items-center py-1 px-2 text-xs transition-colors duration-150 hover:bg-[#aaddf4] rounded-md bg-[#CCEFFF] gap-2 cursor-pointer"
                                                onClick={() => {
                                                    if (!field.value?.includes(category)) {
                                                        field.onChange([
                                                            ...(field.value || []),
                                                            category,
                                                        ]);
                                                    }
                                                }}
                                            >
                                                {category}
                                                <Plus className="h-3 w-3" />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <FormMessage className="text-xs" />
                        </FormItem>
                    )}
                />
                <div className="mt-6">
                    <FormLabel className="text-gray-800">Description</FormLabel>
                    <FormField
                        control={control}
                        name="description"
                        render={({ field }) => (
                            <FormItem className="flex-1 relative mt-1">
                                <FormControl>
                                    <Textarea
                                        rows={3}
                                        className={`focus:ring-0 placeholder:text-gray-500 focus:ring-offset-0 focus-visible:ring-1 focus-visible:ring-offset-1 focus:outline-none focus-visible:ring-gray-400 ring-0 ring-offset-0 resize-none`}
                                        placeholder="Add description"
                                        {...field}
                                        disabled={mode === "Preview"}
                                        value={field.value}
                                        ref={descriptionRef}
                                        onInput={(e) => {
                                            field.onChange(e);
                                            clearErrors("description");
                                            adjustTextareaHeight(e.target as HTMLTextAreaElement);
                                        }}
                                    />
                                </FormControl>
                                <FormMessage className="text-xs" />
                            </FormItem>
                        )}
                    />
                </div>

                <div className="mt-6">
                    <FormLabel className="text-gray-800">Photos</FormLabel>
                    <FormField
                        control={control}
                        name="photoUrl"
                        render={({ field }) => (
                            <FormItem>
                                {mode === "Preview" && localPhotos.length === 0 ? (
                                    <p className="text-sm text-gray-500 mt-2">No Photos</p>
                                ) : (
                                    <div className="flex flex-wrap gap-4 mt-2">
                                        {localPhotos.map((url, index) => (
                                            <div key={url} className="relative group">
                                                <img
                                                    src={url}
                                                    alt={`Work order pic ${index + 1}`}
                                                    className="w-24 h-24 object-cover rounded-md"
                                                />
                                                {mode !== "Preview" && (
                                                    <div className="absolute inset-0 rounded-md bg-black bg-opacity-70 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                                                        <Trash2Icon
                                                            className="text-white cursor-pointer text-xs"
                                                            onClick={() => handleDeleteImage(index)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                        {uploadProgress.map((progress, index) => (
                                            <div
                                                key={`upload-${index}`}
                                                className="w-24 h-24 bg-gray-200 rounded-md flex items-center justify-center"
                                            >
                                                <Progress value={progress} className="w-20 h-2" />
                                            </div>
                                        ))}
                                        {localPhotos.length + uploadProgress.length < 5 &&
                                            mode !== "Preview" && (
                                                <label
                                                    className={`w-24 h-24 flex items-center justify-center border-2 border-dashed border-gray-300 rounded-md ${isUploading ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
                                                >
                                                    <input
                                                        type="file"
                                                        multiple
                                                        accept="image/*"
                                                        onChange={(e) => {
                                                            handleImageUpload(e);
                                                            clearErrors("photoUrl");
                                                        }}
                                                        className="hidden"
                                                        disabled={isUploading}
                                                    />
                                                    {isUploading ? (
                                                        <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
                                                    ) : (
                                                        <Plus className="text-gray-400" />
                                                    )}
                                                </label>
                                            )}
                                    </div>
                                )}
                                {isSubmitted && errors.photoUrl && (
                                    <FormMessage className="text-xs mt-1" />
                                )}
                            </FormItem>
                        )}
                    />
                </div>
                <div className="mt-6">
                    <FormLabel className="text-gray-800">Documents</FormLabel>
                    <div className="mt-2">
                        {documents.length < MAX_DOCUMENTS && mode !== "Preview" ? (
                            <label
                                className={`inline-flex text-sm hover:bg-gray-100 font-normal items-center px-4 py-2 border text-black rounded-md ${isUploadingDoc ? "cursor-not-allowed opacity-50" : "cursor-pointer"} transition-colors`}
                            >
                                <input
                                    type="file"
                                    multiple
                                    accept=".pdf,.doc,.docx,.txt"
                                    className="hidden text-sm"
                                    onChange={handleDocumentUpload}
                                    disabled={isUploadingDoc}
                                />
                                {isUploadingDoc ? (
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                ) : (
                                    <UploadIcon size={16} className="mr-2" />
                                )}
                                {isUploadingDoc
                                    ? "Uploading..."
                                    : `Upload Documents (${documents.length}/${MAX_DOCUMENTS})`}
                            </label>
                        ) : (
                            <p className="text-sm text-gray-500">
                                {mode === "Preview"
                                    ? `Documents (${documents.length}/${MAX_DOCUMENTS})`
                                    : `Maximum number of documents reached (${MAX_DOCUMENTS}/${MAX_DOCUMENTS})`}
                            </p>
                        )}
                    </div>
                    {currentDocumentsLoading ? (
                        <div className="mt-4 space-y-3">
                            <Skeleton className="h-6 w-full" />
                            <Skeleton className="h-6 w-full" />
                            <Skeleton className="h-6 w-full" />
                        </div>
                    ) : (
                        documents.length > 0 && (
                            <ul className="mt-4 space-y-2">
                                {documents.map((doc, index) => (
                                    <li
                                        key={index}
                                        className="flex items-center justify-between bg-gray-100 p-2 rounded"
                                    >
                                        <div className="flex items-center">
                                            <FileIcon size={16} className="mr-2" />
                                            <a
                                                href={doc.filePath}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-sm truncate max-w-xs text-blue-600 hover:underline"
                                            >
                                                {doc.fileName}
                                            </a>
                                        </div>
                                        {mode !== "Preview" && (
                                            <button
                                                onClick={() => handleDeleteDocument(index)}
                                                type="button"
                                                className="text-red-500 hover:text-red-700"
                                            >
                                                <XIcon size={16} />
                                            </button>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )
                    )}
                </div>
            </div>

            {/* Confirmation Modal */}
            <LBModal
                isOpen={showConfirmModal}
                onClose={() => setShowConfirmModal(false)}
                title="Are you sure?"
                contentClassName="max-w-md"
            >
                <p className="mb-4">This will override all the existing fields with new data.</p>
                <div className="flex justify-end space-x-2">
                    <Button variant="outline" onClick={() => setShowConfirmModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setShowConfirmModal(false);
                            generateWithAI();
                        }}
                    >
                        Regenerate
                    </Button>
                </div>
            </LBModal>
        </div>
    );
};

export default AddWorkOrder;
