import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import styles from "./addUnit.module.css";
import { useCallback, useEffect, useState } from "react";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import { useUploadToAWSMutation } from "@/redux/rtk-query/uploadApis";
import { toast } from "react-toastify";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import useGoogleAutocomplete from "@/hooks/useGoogleAutocomplete";
import { getAddressFromGoogleObject } from "@/utils/getAddressFromGoogleObject";
import {
    useDeletePropertyMutation,
    useGetBuildingsByCompanyQuery,
    usePropertiesMutation,
    useUpdatePropertiesMutation,
} from "@/redux/rtk-query/properties";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";
import { useGetMyDetailsQuery, useLazyGetAddressQuery } from "@/redux/rtk-query/authApis";
import { catchAll } from "@/utils/catch";
import { getFullAddressFromAddressObject } from "@/utils/getFullAddressFromAddressObject";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";

const FormSchema = z.object({
    name: z.string().min(3, { message: "Building must be at least 3 characters." }),
    images: z
        .array(z.string().url({ message: "Each image must be a valid URL." }))
        // .min(1, { message: "Please upload at least 1 image." })
        .max(10, { message: "You can upload a maximum of 10 images." }),
    noOfBedrooms: z.number().gte(0, "Cannot have negative bed rooms"),
    status: z.string().min(1, "Status is required"),
    // category: z.string().min(1, "Category is required"),
    type: z.string().min(1, "Unit type is required"),
    area: z.string().min(1, "Unit area is required"),
    address: z.string().min(1, "Address is required"),
});

const defaultValues: z.infer<typeof FormSchema> = {
    // category: "",
    images: [],
    noOfBedrooms: 0,
    address: "",
    area: "",
    name: "",
    status: "",
    type: "",
};

export const AddUnit = () => {
    const navigate = useNavigate();

    const [imagePreviews, setImagePreviews] = useState<string[]>([]);
    const [, setAddressObj] = useState();

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: defaultValues,
    });

    const { reset } = form;

    const { id: buildingId } = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const unitId = searchParams.get("id");

    //	=======================================================================
    //								APIS
    //	=======================================================================

    const { data: myDetails } = useGetMyDetailsQuery();

    const { data: buildingData } = useGetBuildingsByCompanyQuery(
        { id: buildingId ?? "", type: "BUILDING" },
        { skip: !unitId || !buildingId },
    );
    const [getAddress] = useLazyGetAddressQuery();

    const [uploadImageToAWS, { isLoading: isUploading }] = useUploadToAWSMutation();
    const [createUnit, { isLoading: createPropertyUnitLoading }] = usePropertiesMutation();
    const [deleteUnit, { isLoading: deleteUnitLoading }] = useDeletePropertyMutation();
    const [updateUnit] = useUpdatePropertiesMutation();

    useEffect(() => {
        (async () => {
            if (
                buildingData &&
                buildingData.data.length === 1 &&
                buildingData.data?.[0] &&
                buildingData.data?.[0]?.properties &&
                buildingData.data?.[0]?.properties.length > 0
            ) {
                const unitDetails = buildingData.data?.[0]?.properties.find(
                    (building) => building._id === unitId,
                );

                if (!unitDetails) return;

                const addressResponse = await catchAll(getAddress(unitDetails?.addressId ?? ""));

                let fullAddress = "";
                if (addressResponse.data) {
                    fullAddress = getFullAddressFromAddressObject(addressResponse.data.data);
                    setAddressObj(addressResponse.data.data);
                }
                setImagePreviews(unitDetails.images);
                form.reset({ ...unitDetails, address: fullAddress });
            }
        })();
    }, [buildingData, form, getAddress, unitId]);

    const { autocompleteInputRef, place } = useGoogleAutocomplete({
        name: "address",
        handleUserDetails: (e) => {
            form.setValue("address", e.target.value);
        },
        showCompleteDetails: true,
    });

    const onSubmit = useCallback(
        async (data: z.infer<typeof FormSchema>) => {
            if (unitId) {
                const { address, ..._data } = data;

                const response = await catchAll(
                    updateUnit({
                        ..._data,
                        _id: unitId,
                    }).unwrap(),
                );
                if (response.data) {
                    toast.success("Unit updated successfully");
                    reset(defaultValues);
                    navigate(`/property-management/properties`);
                }
                return;
            }

            if (!myDetails?.companyId) {
                toast.error("Unauthorized. Please login.");
                return;
            }

            if (!place) {
                toast.error("Failed to get address. Try again please.");
                return;
            }

            if (!buildingId) {
                toast.error("Building id not found");
                return;
            }

            const address = getAddressFromGoogleObject(place);

            const payload = {
                name: data.name,
                address: address,
                area: data.area,
                noOfBedrooms: data.noOfBedrooms,
                status: data.status,
                // category: data.category,
                type: data.type,
                buildingId,
                images: data.images,
                companyId: myDetails.companyId,
            };

            const response = await catchAll(createUnit(payload).unwrap());
            if (response.data) {
                toast.success("Unit added successfully");
                reset(defaultValues);
            }
        },
        [unitId, updateUnit, reset, navigate, myDetails?.companyId, place, buildingId, createUnit],
    );

    const handleFileChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            const { files } = e.target;
            if (files) {
                const fileArray = Array.from(files);
                const remainingSlots = 10 - form.getValues("images").length;

                if (fileArray.length > remainingSlots) {
                    toast.error("Uh oh! Something went wrong.");
                    e.target.value = "";
                    return;
                }

                const uploadedUrls: any = [];
                const filePromises = fileArray.map(async (file) => {
                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("folder", "properties");

                    try {
                        const response = await uploadImageToAWS(formData).unwrap();
                        const imageUrl = response?.url;
                        if (imageUrl) {
                            uploadedUrls.push(imageUrl);
                            return imageUrl;
                        } else {
                            console.error("Image URL not found in response:", response);
                            return null;
                        }
                    } catch (error) {
                        toast.error("Uh oh! Something went wrong.");
                        e.target.value = "";
                        return;
                    }
                });

                await Promise.all(filePromises);

                form.setValue("images", [...form.getValues("images"), ...uploadedUrls]);
                setImagePreviews((prev) => [...prev, ...uploadedUrls]);
                e.target.value = "";
            }
        },
        [form, uploadImageToAWS],
    );

    const onClickDelete = useCallback(async () => {
        if (!unitId) return;

        const response = await catchAll(deleteUnit(unitId));

        !response.error && navigate(`/property-management/properties/units/${unitId}`);
    }, [deleteUnit, navigate, unitId]);

    return (
        <div className={`${styles.MainContent} mt-2.5`}>
            <div className={styles.FormHeader}>
                {/* <BackButton /> */}
                {/* <Heading className="block">
                    Add Unit
                </Heading> */}
            </div>
            <div className={styles.FormContainer}>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6 py-4">
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel className="text-sm text-black">Unit No.</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder="Enter No."
                                            {...field}
                                            className="focus:ring-1 focus-visible:ring-gray-200 h-9 w-full"
                                        />
                                    </FormControl>
                                    <FormMessage className="text-xs" />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="address"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel className="text-sm text-black">Address</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder="Type address"
                                            {...field}
                                            ref={autocompleteInputRef}
                                            className="focus-visible:ring-gray-200 h-9"
                                        />
                                    </FormControl>
                                    <FormMessage className="text-xs" />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="area"
                            render={({ field }) => {
                                return (
                                    <FormItem className="w-full">
                                        <FormLabel className="text-sm text-black">
                                            Unit Area
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder="Unit area"
                                                {...field}
                                                className="focus:ring-1 focus-visible:ring-gray-200 h-9 w-full"
                                            />
                                        </FormControl>
                                        <FormMessage className="text-xs" />
                                    </FormItem>
                                );
                            }}
                        />

                        <FormField
                            control={form.control}
                            name="noOfBedrooms"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel className="text-sm text-black">
                                        Number of bedrooms
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            type="number"
                                            placeholder="Number of bedrooms"
                                            {...field}
                                            onChange={(e) => field.onChange(Number(e.target.value))}
                                            className="focus:ring-1 focus-visible:ring-gray-200 h-9 w-full"
                                        />
                                    </FormControl>
                                    <FormMessage className="text-xs" />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="status"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel className="text-sm text-black">Status</FormLabel>
                                    <FormControl>
                                        <Select
                                            value={field.value}
                                            onValueChange={(value) => field.onChange(value)}
                                        >
                                            <SelectTrigger className="w-full focus:outline-none focus:ring-0 focus-visible:ring-0">
                                                <SelectValue placeholder="Select status" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    <SelectItem value="AVAILABLE">
                                                        Available
                                                    </SelectItem>
                                                    <SelectItem value="BOOKED">Booked</SelectItem>
                                                    <SelectItem value="NOT_AVAILABLE">
                                                        Not Available
                                                    </SelectItem>
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage className="text-xs" />
                                </FormItem>
                            )}
                        />
                        {/* <FormField
                            control={form.control}
                            name="category"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel className="text-sm text-black">Category</FormLabel>
                                    <FormControl>
                                        <Select
                                            value={field.value}
                                            onValueChange={(value) => field.onChange(value)}
                                        >
                                            <SelectTrigger className="w-full focus:outline-none focus:ring-0 focus-visible:ring-0">
                                                <SelectValue placeholder="Select property category" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <div>
                                                    <div className="font-semibold mb-2 px-2">
                                                        Residential
                                                    </div>
                                                    <SelectGroup>
                                                        <SelectItem value="SINGLE_FAMILY_HOME">
                                                            Single Family Home
                                                        </SelectItem>
                                                        <SelectItem value="MULTI_FAMILY_HOME">
                                                            Multi-Family Home
                                                        </SelectItem>
                                                        <SelectItem value="FLAT_APARTMENT">
                                                            Flat/Apartment
                                                        </SelectItem>
                                                        <SelectItem value="TOWNHOUSE">
                                                            Townhouse
                                                        </SelectItem>
                                                        <SelectItem value="OTHER">Other</SelectItem>
                                                    </SelectGroup>
                                                </div>
                                                <div>
                                                    <div className="font-semibold mb-2 px-2">
                                                        Commercial
                                                    </div>
                                                    <SelectGroup>
                                                        <SelectItem value="OFFICE_BUILDING">
                                                            Office Building
                                                        </SelectItem>
                                                        <SelectItem value="RETAIL_PROPERTY">
                                                            Retail Property
                                                        </SelectItem>
                                                        <SelectItem value="INDUSTRIAL_PROPERTY">
                                                            Industrial Property
                                                        </SelectItem>
                                                        <SelectItem value="HOTEL_BAR">
                                                            Hotel/Bar
                                                        </SelectItem>
                                                    </SelectGroup>
                                                </div>
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage className="text-xs" />
                                </FormItem>
                            )}
                        /> */}
                        <FormField
                            control={form.control}
                            name="type"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel className="text-sm text-black">Type</FormLabel>
                                    <FormControl>
                                        <Select
                                            value={field.value}
                                            onValueChange={(value) => field.onChange(value)}
                                        >
                                            <SelectTrigger className="w-full focus:outline-none focus:ring-0 focus-visible:ring-0">
                                                <SelectValue placeholder="Select type" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    <SelectItem value="FOR_RENT">
                                                        For Rent
                                                    </SelectItem>
                                                    <SelectItem value="FOR_LEASE">
                                                        For Lease
                                                    </SelectItem>
                                                    <SelectItem value="FOR_SALE">
                                                        For Sale
                                                    </SelectItem>
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage className="text-xs" />
                                </FormItem>
                            )}
                        />
                        <div className="grid grid-cols-6 gap-5 items-end">
                            <FormField
                                control={form.control}
                                name="images"
                                render={() => (
                                    <FormItem>
                                        <FormLabel className="text-sm text-black">
                                            Upload Images
                                        </FormLabel>
                                        <FormControl>
                                            <label className="flex flex-col items-center justify-center w-[120px] h-[7rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                +
                                                <input
                                                    type="file"
                                                    multiple
                                                    accept="image/*"
                                                    onChange={handleFileChange}
                                                    className="focus-visible:ring-gray-200 hidden"
                                                    disabled={isUploading}
                                                />
                                            </label>
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                            {imagePreviews.length > 0 &&
                                !isUploading &&
                                imagePreviews.map((preview, index) => (
                                    <div key={index} className="relative w-[120px] h-[7rem]">
                                        <img
                                            src={`${preview}`}
                                            alt={`preview-${index}`}
                                            className="w-full h-full object-cover border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setImagePreviews((prev) =>
                                                    prev.filter((_, i) => i !== index),
                                                );
                                                form.setValue(
                                                    "images",
                                                    form
                                                        .getValues("images")
                                                        .filter((_, i) => i !== index),
                                                );
                                            }}
                                            className="absolute top-[-8px] right-[-11px] bg-gray-200 w-[24px] h-[24px] text-[1rem] rounded-full border-none cursor-pointer flex items-center justify-center"
                                        >
                                            ×
                                        </button>
                                    </div>
                                ))}

                            {isUploading &&
                                Array.from({ length: 11 }, (_, index) => (
                                    <Skeleton
                                        key={index}
                                        className="w-[120px] h-[7rem] rounded-xl bg-black"
                                    />
                                ))}
                        </div>
                        <div className="flex gap-3 justify-end items-end ">
                            {unitId && (
                                <Dialog>
                                    <DialogTrigger asChild>
                                        <Button
                                            variant="outline"
                                            className="bg-[#000] text-white hover:bg-[#000] hover:text-white"
                                        >
                                            Delete
                                        </Button>
                                    </DialogTrigger>
                                    <DialogContent className="sm:max-w-[425px]">
                                        <DialogHeader>
                                            <DialogTitle>Are you absolutely sure?</DialogTitle>
                                            <DialogDescription>
                                                This action cannot be undone. This will permanently
                                                delete your property.
                                            </DialogDescription>
                                        </DialogHeader>
                                        <DialogFooter>
                                            <DialogClose className="bg-[#000] text-white hover:bg-[#000] hover:text-white px-3 rounded-sm text-sm">
                                                Cancel
                                            </DialogClose>
                                            <Button className="bg-[#000]" onClick={onClickDelete}>
                                                {deleteUnitLoading && (
                                                    <Spinner className="text-white size-5 mr-1" />
                                                )}
                                                Continue
                                            </Button>
                                        </DialogFooter>
                                    </DialogContent>
                                </Dialog>
                            )}

                            <Button
                                type="submit"
                                className="mt-4 bg-[#000] w-[100px]"
                                disabled={createPropertyUnitLoading || isUploading}
                            >
                                {createPropertyUnitLoading && (
                                    <Spinner className="text-white size-5 mr-1" />
                                )}
                                Continue
                            </Button>
                        </div>
                    </form>
                </Form>
            </div>
        </div>
    );
};
