import { useGetBuildingsByCompanyQuery } from "@/redux/rtk-query/properties";
import React, { useMemo } from "react";

interface useDocumentsRowProps {
    companyId: string | undefined;
}

const useDocumentsRow = ({ companyId }: useDocumentsRowProps) => {
    const { data: propertiesData } = useGetBuildingsByCompanyQuery(
        { id: companyId || "", type: "COMPANY" },
        {
            skip: !companyId,
        },
    );
    // const ContractorRows = useMemo(() => {
    //     if (!Array.isArray(data)) return [];
    //     return data?.map((item) => {
    //         return {
    //             id: item._id,
    //             rent: item.rent,
    //             securityDeposit: item.securityDeposit,
    //             otherCharges: item.otherCharges,
    //             tenure: item.tenure,
    //             bookingDate: new Date(item.bookingDate).toLocaleDateString("en-GB", {
    //                 day: "2-digit",
    //                 month: "short",
    //                 year: "numeric",
    //             }),
    //             startDate: new Date(item.startDate).toLocaleDateString("en-GB", {
    //                 day: "2-digit",
    //                 month: "short",
    //                 year: "numeric",
    //             }),
    //             endDate: new Date(item.endDate).toLocaleDateString("en-GB", {
    //                 day: "2-digit",
    //                 month: "short",
    //                 year: "numeric",
    //             }),
    //         };
    //     });
    // }, [data]);

    return {
        // contractorList: data,
        // ContractorRows,
        // isLoading: isLoading,
        propertiesData,
    };
};

export default useDocumentsRow;
