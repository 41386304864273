import { BackButton } from "../../../../components/common/backButton";
import { Heading } from "../../../../components/common/pageHeading";
import styles from "./quotes.module.css";

export const Header = () => (
    <header className={styles.header}>
        <BackButton />
        <Heading as="h1">Job Quotes</Heading>
    </header>
);
