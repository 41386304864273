import styles from "../Settings.module.css";
import { memo } from "react";

export type TStatCardProps = {
    label: string;
    value: number;
};

export const StatCard = memo((props: TStatCardProps) => {
    const { label, value } = props;
    return (
        <div className={styles.payoutStatCard}>
            <p className={styles.payoutStatHeading}>{label}</p>
            <p className={styles.payoutStatValue}>{value}</p>
        </div>
    );
});
