import styles from "./homeOwnerPostAJob.module.css";
import { AIPromptSample } from "./AIPromptSample";

export const AIPromptSamples = () => (
    <div className={styles.aiPromptSamples}>
        {Array(2)
            .fill(0)
            .map((key) => (
                <AIPromptSample key={key} />
            ))}
    </div>
);
