import { jwtDecode } from "jwt-decode";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { USER_TYPES } from "./interface/auth.interface";

interface IProtectedUserRoute {
    component: React.ComponentType<any>;
}
interface DecodedToken {
    sub: string;
    [key: string]: any;
}

const ProtectedUserRoute: React.FC<IProtectedUserRoute> = ({ component: Component, ...rest }) => {
    const location = useLocation();
    const token = localStorage.getItem("token");
    const isAuthenticated = Boolean(token);
    if (!isAuthenticated || (token && Number(jwtDecode(token).sub) !== USER_TYPES.CONTRACTOR)) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    let userType = null;
    if (token) {
        try {
            const decoded: DecodedToken = JSON.parse(atob(token.split(".")[1]));
            userType = Number(decoded.sub);
        } catch (e) {
            console.error("Invalid token format", e);
        }
    }

    if (!isAuthenticated || userType !== USER_TYPES.CONTRACTOR) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return <Component {...rest} {...location} />;
};

export default ProtectedUserRoute;
