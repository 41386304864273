export const chartConfig = {
    Loss: {
        label: "Loss",
        color: "#019CDF",
    },
    Profit: {
        label: "Profit",
        color: "#BAE9FD",
    },
};
