import styles from "../Common.module.css";

interface ProfileIconProps {
    imageSrc: string;
    icon?: boolean;
}

function ProfileIcon({ imageSrc, icon = false }: ProfileIconProps) {
    let classes = "";
    if (icon) {
        classes += ` ${styles.profileIcon}`;
    } else {
        classes += ` ${styles.pfCursor}`;
    }
    return (
        <div className={classes}>
            <div>
                <img src={imageSrc} alt="Profile" className="object-cover" />
            </div>
            {icon && <i className="ri-arrow-down-s-line"></i>}
        </div>
    );
}

export default ProfileIcon;
