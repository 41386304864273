import { Chip } from "@/components/common/chip";
import { convertScreamToTitle } from "@/utils/convertScreamToTitle";

export const chartData = {
    key: 1,
    headerText: "Properties",
    data: "0",
    percentageText: "0%",
    breakdownText: "Properties breakdown",
    breakdownPercentage: "0%",
    progressBarSegments: [{ color: "green" }, { color: "red" }],
    detailsSections: [
        {
            indicatorColor: "green-indicator",
            title: "Occupied",
            amount: "0",
            percentage: "0%",
        },
        {
            indicatorColor: "blue-indicator",
            title: "Vacant",
            amount: "0",
            percentage: "0%",
        },
        {
            indicatorColor: "orange-indicator",
            title: "Void",
            amount: "0",
            percentage: "0%",
        },
    ],
};

export const chartOptions = {
    series: [
        {
            name: "occupied",
            data: [44, 55, 57, 56, 56],
        },
        {
            name: "vacent",
            data: [76, 85, 101, 98, 34],
        },
        {
            name: "void",
            data: [35, 41, 36, 26, 12],
        },
    ],
    chart: {
        type: "bar" as const,
        height: 350,
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
        },
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
    },
    xaxis: {
        categories: ["Sep 3", "Sep 3", "Sep 3", "Sep 3", "Oct 2"],
    },
    fill: {
        opacity: 1,
    },
    tooltip: {
        y: {
            formatter: function (val: number) {
                return "$ " + val.toFixed(2) + " thousands";
            },
        },
    },
};

export const typeColors = (type: string) => {
    switch (type) {
        case "FOR_RENT":
            return "blue";
        case "FOR_LEASE":
            return "green";
        case "FOR_SALE":
            return "gray";
        case "NOT_AVAILABLE":
            return "red";
        default:
            return "black";
    }
};

export const statusColor = (status: string) => {
    switch (status) {
        case "BOOKED":
            return "yellow";
        case "AVAILABLE":
            return "green";
        case "FOR_SALE":
            return "gray";
        case "NOT_AVAILABLE":
            return "red";
        default:
            return "black";
    }
};

export const TableHeadItems = [
    {
        accessorKey: "id",
        header: "Property ID",
        cell: ({ row }: any) => {
            const sequenceNumber = row.index + 1;
            return <div className="ml-4">{`${sequenceNumber}`}</div>;
        },
    },
    {
        accessorKey: "name",
        header: "Name",
        cell: ({ row }: any) => <div className="capitalize">{row.getValue("name")}</div>,
    },
    {
        accessorKey: "category",
        header: "Category",
        cell: ({ row }: any) => (
            <Chip color={"gray"} text={convertScreamToTitle(row.getValue("category"))} />
        ),
    },
    // <div className="lowercase ml-4">{row.getValue("category")}</div>
    // {
    //     id: "actions",
    //     enableHiding: false,
    //     header: "Actions",
    //     size: 100, // Set a fixed width of 100px for the Actions column
    //     cell: ({ row }: any) => {
    //         return (
    //             <div onClick={(e) => e.stopPropagation()}>
    //                 <ActionsCell id={row.original.id.toString()} />
    //             </div>
    //         );
    //     },
    // },
];

export const sortItems = [
    { value: "id", key: "Property ID" },
    { value: "name", key: "Name" },
    { value: "category", key: "Category" },
];

export const columnItems = [
    { value: "id", key: "Property ID" },
    { value: "name", key: "Name" },
    { value: "category", key: "Category" },
];
