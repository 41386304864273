import { useNavigate, useParams } from "react-router-dom";
import styles from "./dashboard.module.css";
import jobImg from "../../../assets/images/jobImg.png";
import ProfileCard from "../../../components/common/profileIcon/profileCard";
import { useEffect, useState } from "react";
import ApiCall from "../../../api/requestProcessor";
import { IJobDetails, JobDetailsValues } from "../../../utils/interface/dashboard.interface";
import { formatDateToMonthYear } from "../../../utils/service";
import JobDetailsSkeleton from "./loader/jobDetails.skeleton";
import { SendQuoteModal } from "../../../components/dashboard/contractor/modal/sendQuote";
import { getFormattedDate } from "../../../utils/formatDate";
// import { useGetReviewsQuery } from "../../../redux/rtk-query/reviewApis";

function JobDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [jobDetail, setJobDetail] = useState<IJobDetails>(JobDetailsValues);
    const [loading, setLoading] = useState<boolean>(false);
    const [isQuoteModal, setQuoteModal] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchJobDetail = async () => {
            try {
                setLoading(true);
                const res = await ApiCall.sendGet(`jobs?id=${id}`);

                if (res.status === 200) {
                    setLoading(false);
                    const result = res.data.data[0];

                    const data = {
                        id: result._id,
                        date: result.createdAt,
                        title: result.jobDetail.title,
                        description: result.jobDetail.description,
                        photoUrl: result.jobDetail?.photoUrl || jobImg,
                        clock: "",
                        address: result.address?.building || "No address provided",
                        client: {
                            firstName: result.postedBy.firstName,
                            lastName: result.postedBy.lastName,
                            joinedDate: result.postedBy.createdAt,
                        },
                        postedBy: result.postedBy._id,
                        category: result.jobCategory,
                        budget: result.jobDetail.rate,
                        createdAt: result.createdAt,
                    };
                    setJobDetail(data);
                    localStorage.setItem("clientId", result.postedBy._id);
                }
            } catch (error: any) {
                setLoading(false);
                console.error("Failed to fetch job details:", error);
            }
        };
        fetchJobDetail();
    }, [id]);

    // const cId = localStorage.getItem("clientId") as string;

    // const {
    //     data: reviews = [],
    // } = useGetReviewsQuery(cId);

    // const avgReview = () => {
    //     if (reviews.length === 0) return 0;
    //     return reviews.reduce((acc: number, cur: any) => acc + cur.rating, 0) / reviews.length;
    // };

    // const revAvg = avgReview();

    const handleGoBack = () => {
        navigate(-1);
    };

    if (loading) {
        return <JobDetailsSkeleton />;
    }

    const handleDetailPage = () => {
        navigate(`/contractor/user/${jobDetail?.postedBy}`);
    };

    return (
        <>
            {isQuoteModal && (
                <SendQuoteModal
                    jobDetail={jobDetail}
                    isOpen={isQuoteModal}
                    onClose={() => setQuoteModal(false)}
                />
            )}
            <section className={styles.homeFrame}>
                <div className={styles.jobHeader}>
                    <button onClick={handleGoBack}>
                        <i className="ri-arrow-left-line"></i>
                        <span>Back</span>
                    </button>
                </div>
                <div className={`${styles.head} ${styles.mar_y}`}>Job details</div>
                <div className={styles.jobDetailsSect}>
                    <div>
                        <div className={styles.jobImage}>
                            <img src={jobDetail.photoUrl[0] || jobImg} alt="job_image" />
                        </div>
                        <div className={styles.jobDHeader}>{jobDetail.title}</div>
                        <div className={styles.jobDAddress}>{jobDetail.address}</div>
                        <div className={styles.jobDTable}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Budget</td>
                                        <td>£{jobDetail.budget}</td>
                                    </tr>
                                    <tr>
                                        <td>Date</td>
                                        <td>
                                            {getFormattedDate(
                                                new Date(jobDetail.createdAt),
                                            ).toString()}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Category</td>
                                        <td>{jobDetail.category}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={styles.jdDesc}>
                            <p>Job description</p>
                            <span>{jobDetail.description}</span>
                        </div>
                        <div className={styles.aboutTheClient}>
                            <p>About Client</p>
                            <div className={styles.pFrSect}>
                                <ProfileCard
                                    firstName={jobDetail.client.firstName}
                                    lastName={jobDetail.client.lastName}
                                    handleDetailPage={handleDetailPage}
                                />
                                <div className={styles.pCardMem}>
                                    <span>
                                        Member since{" "}
                                        {formatDateToMonthYear(jobDetail.client.joinedDate)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.jDActionBlk}>
                        <div className={styles.cardQuotes}>
                            <button
                                className={`${styles.sendAQuote} ${styles.sty2}`}
                                onClick={() => setQuoteModal(true)}
                            >
                                <span>Send a quote</span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default JobDetails;
