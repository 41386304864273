import { CSSProperties, useState } from "react";
import styles from "../Common.module.css";

interface TextAreaProps {
    name: string;
    error?: Record<string, string>;
    handleChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    required?: boolean;
    placeholder?: string;
    value?: string;
    disabled?: boolean;
    height?: CSSProperties["height"];
}

function TextArea({
    error = {},
    name,
    handleChange,
    required = false,
    placeholder,
    value,
    disabled = false,
    height = 47,
}: TextAreaProps) {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <div className={styles.textAreaFrame}>
            <textarea
                onChange={handleChange}
                name={name}
                required={required}
                placeholder={isFocused ? "" : placeholder}
                value={value}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={disabled}
                style={{ height }}
            ></textarea>
            {error[name] && (
                <div className={styles.inputFieldError}>
                    <i className="ri-error-warning-fill"></i>
                    <span>{error[name]}</span>
                </div>
            )}
        </div>
    );
}

export default TextArea;
