import { Dropdown } from "../../components/common/dropdown";
import { Chip } from "../../components/common/chip";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { ArrowUpDown, MoreHorizontal } from "lucide-react";
import { DropdownMenu, DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";

export const data = [
    {
        id: "#1203",
        date: "Nov 01, 2024, 3:30 PM",
        description: "Outdoor tap broken",
        tenant: "John Doe",
        priority: "Medium",
    },
    {
        id: "#1204",
        date: "Nov 02, 2024, 4:00 PM",
        description: "Heating issue",
        tenant: "Jane Smith",
        priority: "High",
    },
    {
        id: "#1204",
        date: "Nov 02, 2024, 4:00 PM",
        description: "Heating issue",
        tenant: "Jane Smith",
        priority: "Low",
    },
    {
        id: "#1204",
        date: "Nov 02, 2024, 4:00 PM",
        description: "Heating issue",
        tenant: "Jane Smith",
        priority: "Low",
    },
    {
        id: "#1204",
        date: "Nov 02, 2024, 4:00 PM",
        description: "Heating issue",
        tenant: "Jane Smith",
        priority: "Low",
    },
    {
        id: "#1204",
        date: "Nov 02, 2024, 4:00 PM",
        description: "Heating issue",
        tenant: "Jane Smith",
        priority: "Low",
    },
    {
        id: "#1204",
        date: "Nov 02, 2024, 4:00 PM",
        description: "Heating issue",
        tenant: "Jane Smith",
        priority: "Low",
    },
    {
        id: "#1204",
        date: "Nov 02, 2024, 4:00 PM",
        description: "Heating issue",
        tenant: "Jane Smith",
        priority: "Low",
    },
    {
        id: "#1204",
        date: "Nov 02, 2024, 4:00 PM",
        description: "Heating issue",
        tenant: "Jane Smith",
        priority: "Low",
    },
    {
        id: "#1204",
        date: "Nov 02, 2024, 4:00 PM",
        description: "Heating issue",
        tenant: "Jane Smith",
        priority: "Low",
    },
    {
        id: "#1204",
        date: "Nov 02, 2024, 4:00 PM",
        description: "Heating issue",
        tenant: "Jane Smith",
        priority: "Low",
    },
    {
        id: "#1204",
        date: "Nov 02, 2024, 4:00 PM",
        description: "Heating issue",
        tenant: "Jane Smith",
        priority: "Low",
    },
];

export const headerItems = [
    { key: 1, value: "ID" },
    { key: 2, value: "Date" },
    { key: 3, value: "Description" },
    { key: 4, value: "Tenant" },
    { key: 5, value: "Priority" },
];

// export const TableHeadItems = [
//     {
//         key: 0,
//         value: (
//             <div className="flex items-center">
//                 {/* <Checkbox size="xs" /> */}
//                 <p>ID</p>
//             </div>
//         ),
//     },
//     {
//         key: 1,
//         value: "Date",
//     },
//     {
//         key: 2,
//         value: "Description",
//     },
//     {
//         key: 3,
//         value: "Tenant",
//     },
//     {
//         key: 4,
//         value: "Priority",
//     },
// ];

export const TableHeadItems = [
    {
        key: 0,
        value: (
            <div className="flex items-center">
                {/* <Checkbox size="xs" /> */}
                <p>ID</p>
            </div>
        ),
    },
    {
        key: 1,
        value: "Status",
    },
    {
        key: 2,
        value: "Due Date",
    },
    {
        key: 3,
        value: "Assigned To",
    },
    {
        key: 4,
        value: "Property ID",
    },
    {
        key: 5,
        value: "Priority",
    },
    {
        key: 6,
        value: "_id",
    },
];

export type Payment = {
    id: string;
    amount: number;
    status: "pending" | "processing" | "success" | "failed";
    email: string;
};

export const TableHeadItems2 = [
    // {
    //     id: "select",
    //     header: ({ table }:any) => (
    //         <Checkbox
    //             checked={
    //                 table.getIsAllPageRowsSelected() ||
    //                 (table.getIsSomePageRowsSelected() && "indeterminate")
    //             }
    //             onCheckedChange={(value: any) => table.toggleAllPageRowsSelected(!!value)}
    //             aria-label="Select all"
    //         />
    //     ),
    //     cell: ({ row }:any) => (
    //         <Checkbox
    //             checked={row.getIsSelected()}
    //             onCheckedChange={(value: any) => row.toggleSelected(!!value)}
    //             aria-label="Select row"
    //         />
    //     ),
    //     enableSorting: false,
    //     enableHiding: false,
    // },
    {
        accessorKey: "Id",
        header: ({ column }: any) => {
            return (
                <Button
                    variant="ghost"
                    className="hover:bg-transparent"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Id
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }: any) => {
            const sequenceNumber = row.index + 1;
            return <div className="ml-4">{`#${sequenceNumber}`}</div>;
        },
    },
    {
        accessorKey: "Status",
        header: "Status",
        cell: ({ row }: any) => <div className="capitalize">{row.getValue("Status")}</div>,
    },
    {
        accessorKey: "dueDate",
        header: ({ column }: any) => {
            return (
                <Button
                    variant="ghost"
                    className="hover:bg-transparent"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Due Date
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }: any) => <div className="lowercase ml-4">{row.getValue("dueDate")}</div>,
    },
    {
        accessorKey: "assignedTo",
        header: () => <div>Assigned To</div>,
        cell: ({ row }: any) => {
            return <div className="font-medium">{row.getValue("assignedTo")}</div>;
        },
    },
    {
        accessorKey: "propertyId",
        header: () => <div className="">Property</div>,
        cell: ({ row }: any) => {
            return <div className="font-medium">{row.getValue("propertyId")}</div>;
        },
    },
    {
        accessorKey: "priority",
        header: () => <div>Priority</div>,
        cell: ({ row }: any) => {
            return <div className="font-medium">{row.getValue("priority")}</div>;
        },
    },
    //     {
    //         id: "actions",
    //         enableHiding: false,
    //         cell: ({ row }: any) => {
    //             const payment = row.original;

    //             return (
    //                 <DropdownMenu>
    //                     <DropdownMenuTrigger asChild>
    //                         <Button variant="ghost" className="h-8 w-8 p-0">
    //                             <span className="sr-only">Open menu</span>
    //                             <MoreHorizontal className="h-4 w-4" />
    //                         </Button>
    //                     </DropdownMenuTrigger>
    //                     {/* <DropdownMenuContent align="end">
    //                         <DropdownMenuLabel>Actions</DropdownMenuLabel>
    //                         <DropdownMenuItem onClick={() => navigator.clipboard.writeText(payment.id)}>
    //                             Copy payment ID
    //                         </DropdownMenuItem>
    //                         <DropdownMenuSeparator />
    //                         <DropdownMenuItem>View customer</DropdownMenuItem>
    //                         <DropdownMenuItem>View payment details</DropdownMenuItem>
    //                     </DropdownMenuContent> */}
    //                 </DropdownMenu>
    //             );
    //         },
    //     },
];

export const TableHeadItemsCompliance = [
    {
        key: 0,
        value: (
            <div className="flex items-center">
                <p>ID</p>
            </div>
        ),
    },
    {
        key: 1,
        value: "Name",
    },
    {
        key: 2,
        value: "Category",
    },
    {
        key: 3,
        value: "Required Documents",
    },
    {
        key: 4,
        value: "Description",
    },
];

export const TableHeadItemsCompliance2: ColumnDef<Payment>[] = [
    // {
    //     id: "select",
    //     header: ({ table }) => (
    //         <Checkbox
    //             checked={
    //                 table.getIsAllPageRowsSelected() ||
    //                 (table.getIsSomePageRowsSelected() && "indeterminate")
    //             }
    //             onCheckedChange={(value: any) => table.toggleAllPageRowsSelected(!!value)}
    //             aria-label="Select all"
    //         />
    //     ),
    //     cell: ({ row }) => (
    //         <Checkbox
    //             checked={row.getIsSelected()}
    //             onCheckedChange={(value: any) => row.toggleSelected(!!value)}
    //             aria-label="Select row"
    //         />
    //     ),
    //     enableSorting: false,
    //     enableHiding: false,
    // },
    {
        accessorKey: "id",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Id
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }) => {
            const sequenceNumber = row.index + 1;
            return <div className="ml-4">{`#${sequenceNumber}`}</div>;
        },
    },
    {
        accessorKey: "name",
        header: "Name",
        cell: ({ row }) => <div className="capitalize">{row.getValue("name")}</div>,
    },
    {
        accessorKey: "category",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Category
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }) => <div className="lowercase">{row.getValue("category")}</div>,
    },
    {
        accessorKey: "requiredDocuments",
        header: () => <div className="">Required Documents</div>,
        cell: ({ row }) => {
            return <div className="font-medium">{row.getValue("requiredDocuments")}</div>;
        },
    },
    {
        accessorKey: "description",
        header: () => <div className="">Description</div>,
        cell: ({ row }) => {
            return <div className="font-medium">{row.getValue("description")}</div>;
        },
    },
    {
        id: "actions",
        enableHiding: false,
        cell: () => {
            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    {/* <DropdownMenuContent align="end">
                        <DropdownMenuLabel>Actions</DropdownMenuLabel>
                        <DropdownMenuItem onClick={() => navigator.clipboard.writeText(compilance.id)}>
                            Copy payment ID
                        </DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem>View customer</DropdownMenuItem>
                        <DropdownMenuItem>View payment details</DropdownMenuItem>
                    </DropdownMenuContent> */}
                </DropdownMenu>
            );
        },
    },
];

export const DropdownItems = [
    { key: "View Details", value: "View Details" },
    { key: "Request Rent", value: "Request Rent" },
    { key: "Check Compliance", value: "Check Compliance" },
    { key: "Message", value: "Message" },
    { key: "Report", value: "Report" },
];

export const typeColors = (type: string) => {
    switch (type) {
        case "High":
            return "green";
        case "Medium":
            return "yellow";
        case "Low":
            return "red";
        default:
            return "gray"; // Added default case
    }
};

export const CardNetIncome = {
    key: 1,
    headerText: "Net Income",
    data: "£0.00",
    percentageText: "0%",
    breakdownText: "Financial breakdown",
    breakdownPercentage: "0%",
    progressBarSegments: [{ color: "green" }, { color: "red" }],
    detailsSections: [
        {
            indicatorColor: "green-indicator",
            title: "Rent Income",
            amount: "£0.00",
            percentage: "0%",
        },
        {
            indicatorColor: "blue-indicator",
            title: "Expenses",
            amount: "£0.00",
            percentage: "0%",
        },
    ],
};

export const CardProperties = {
    key: 2,
    headerText: "Properties",
    data: "1",
    percentageText: "0%",
    breakdownText: "Properties breakdown",
    breakdownPercentage: "0%",
    progressBarSegments: [
        { color: "green", width: "0" },
        { color: "blue", width: "0" },
        { color: "red", width: "0" },
    ],
    detailsSections: [
        {
            indicatorColor: "green-indicator",
            title: "Occupied",
            property: "0",
            percentage: "0%",
        },
        {
            indicatorColor: "blue-indicator",
            title: "Vacant",
            property: "0",
            percentage: "0%",
        },
        {
            indicatorColor: "orange-indicator",
            title: "Void",
            property: "0",
            percentage: "0%",
        },
    ],
};

export const TableRowItems = data.map((item) => {
    const color = typeColors(item.priority);
    return [
        {
            key: "ID",
            value: (
                <div className="flex items-center">
                    {/* <Checkbox size="xs" /> */}
                    <p>{item.id}</p>
                </div>
            ),
        },
        {
            key: "date",
            value: (
                <div>
                    <p>{item.date}</p>
                </div>
            ),
        },
        {
            key: "description",
            value: (
                <div>
                    <p>{item.description}</p>
                </div>
            ),
        },
        {
            key: "tenant",
            value: (
                <div>
                    <p>{item.tenant}</p>
                </div>
            ),
        },
        {
            key: "priority",
            value: (
                <div className="flex justify-between items-center">
                    <Chip color={color || "white"} text={item.priority} />
                    <Dropdown items={DropdownItems}>
                        <div>...</div>
                    </Dropdown>
                </div>
            ),
        },
    ];
});

export const graphHeading = {
    heading1: "Total Expenses",
    heading2: "Property Units",
};

export const PieChartData = [
    { propertyStatLabel: "Occupied", stat: 200, fill: "var(--color-safari)" },
    { propertyStatLabel: "Available", stat: 173, fill: "var(--color-edge)" },
];

export const chartConfig = {
    compliance: {
        label: "Compliance",
        color: "#019CDF",
    },
    miscellaneous: {
        label: "Miscellaneous",
        color: "#BAE9FD",
    },
};
