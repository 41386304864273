import { memo } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./backButton.module.css";

type TProps = {
    className?: string;
    bottom?: number;
    top?: number;
};

export const BackButton = memo((props: TProps) => {
    const { bottom = 0, className = "", top = 0 } = props;

    const navigation = useNavigate();

    return (
        <button
            onClick={() => navigation(-1)}
            style={{ marginTop: top, marginBottom: bottom }}
            className={`${styles.backButton} ${className}`}
        >
            <span className={styles.backButtonArrow}>
                <i className="ri-arrow-left-line"></i>
            </span>
            Back
        </button>
    );
});
