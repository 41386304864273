import { Button } from "@/components/ui/button";
import { TableHeadItems, columnItems, sortItems } from "./AiAssistant.constants";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { Link, useNavigate } from "react-router-dom";
import { useGetAiAssistanceQuery } from "@/redux/rtk-query/aiAssistance";
import { IoCopyOutline } from "react-icons/io5";
import { TaskTables } from "@/components/table/TaskTable";
import AssistanceCallRow from "./TableRowData/AssistanceCallRow";
import { TableActions } from "@/pages/propertyManagementContracts/TableActions";
import useTableSortAndFilter from "@/components/common/table/useTableSortAndFilter";
import { useEffect, useMemo, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Skeleton } from "@/components/ui/skeleton";
import ExpandableButton from "./ExpandableButton";
import { MdOutlineReportGmailerrorred } from "react-icons/md";
import { FaQuestion } from "react-icons/fa6";
import { BsChatText } from "react-icons/bs";
import Chatbot from "@/components/chatBot";
import style from "./aiAssistant.module.css";
import { cn } from "@/lib/utils";
import StatsCard from "@/pages/myJobs/components/StatsCard";
import { Header } from "@/components/common/headers/Header";

export const PropertyManagementAiAssistant = () => {
    const [isCopied, setIsCopied] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState(1);
    const [chatBotOpen, setChatBotOpen] = useState(false);
    const limit = 10;

    const {
        CallLogs,
        user,
        AssistanceCallRows,
        isLoading: isAssistanceLogLoading,
        totalpages,
        callLogsRoot,
    } = AssistanceCallRow(currentPage, limit);
    const filteredPropertyRow = useMemo(() => {
        const lowercasedSearchTerm = searchTerm.toLowerCase();
        return AssistanceCallRows?.filter((row: any) =>
            Object.values(row).some((value) =>
                String(value).toLowerCase().includes(lowercasedSearchTerm),
            ),
        );
    }, [searchTerm, AssistanceCallRows]);

    const {
        sortedTableRowItems,
        handleToggleColumn,
        handleSort,
        visibleColumns,
        sortDirection,
        sortColumn,
    } = useTableSortAndFilter({ head: TableHeadItems, rows: filteredPropertyRow });

    const navigate = useNavigate();
    const { data: myDetails, isLoading } = useGetMyDetailsQuery();
    const chatAI = localStorage.getItem("chat_ai");
    const parsedIsChatAi = chatAI ? JSON.parse(chatAI) : null;
    // const [createConversation] = useCreateConversationMutation();
    const { data: aiAssistantStats, isLoading: isAiStatLoading } = useGetAiAssistanceQuery();

    // const { data: conversationList } = useGetUserConversationsQuery(undefined, {
    //     refetchOnMountOrArgChange: true,
    // });

    // const chatWithAi = async () => {
    //     try {
    //         const conversationId = conversationList?.find(
    //             (data) => data.conversationType === "AI_ASSISTANT_CHAT",
    //         )?._id;

    //         if (myDetails && !conversationId) {
    //             const payload = {
    //                 conversationType: "AI_ASSISTANT_CHAT" as "AI_ASSISTANT_CHAT",
    //                 title: "AI Chat",
    //                 participants: [
    //                     {
    //                         userId: myDetails._id,
    //                     },
    //                     {
    //                         userId: "667c04fe28e9571ce3baba2f",
    //                     },
    //                 ],
    //             };
    //             const res = await createConversation(payload).unwrap();

    //             setLocalStorage("chat_ai", JSON.stringify(true));
    //             toast.success("Conversation with AI created!");
    //             navigate(`/property-management/chat`, { state: { conversationId: res.data._id } });
    //         } else {
    //             navigate(`/property-management/chat`, {
    //                 state: { conversationId: conversationId },
    //             });
    //         }
    //     } catch (error) {
    //         return error;
    //     }
    // };

    //             setLocalStorage("chat_ai", JSON.stringify(true));
    //             toast.success("Conversation with AI created!");
    //             navigate(`/property-management/chat`, { state: { conversationId: res.data._id } });
    //         } else {
    //             navigate(`/property-management/chat`, {
    //                 state: { conversationId: conversationId },
    //             });
    //         }
    //     } catch (error) {
    //         return error;
    //     }
    // };
    console.log(callLogsRoot, "--- in hello world ----");
    const stats = [
        {
            label: "Weekly Calls",
            value: AssistanceCallRows?.length,
        },
        { label: "⁠Daily Calls", value: callLogsRoot?.daily_calls },
        { label: "Average Call Length", value: callLogsRoot?.average_call_length },
        { label: "Weekly Maintenance Requests", value: aiAssistantStats?.totalJobsScheduled },
    ];

    const TableData = {
        data: [
            {
                header: visibleColumns,
                row: [...sortedTableRowItems],
                heading: "All",
            },
            // { header: visibleColumns, row: sortedTableRowItems, heading: "Calls" },
            // { header: TableHeadItems, row: MainTainanceRow(), heading: "SMS" },
        ],
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(user?.userProfile?.assistantPhoneNo || "");
        setIsCopied(true);
    };

    useEffect(() => {
        if (isCopied) {
            const timer = setTimeout(() => {
                setIsCopied(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
        return undefined; // Add this line to ensure all code paths return a value
    }, [isCopied]);

    const handleButtonClick = (icon: string) => {
        if (icon === "form") {
            window.open("https://customersupport.lightwork.blue/submit", "_blank");
        } else if (icon === "chatBot") {
            setChatBotOpen((prev) => !prev);
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <div className="max-h-screen overflow-y-auto w-full">
            <Header
                image={myDetails?.imageUrl}
                heading={undefined}
                firstName={myDetails?.firstName}
                lastName={myDetails?.lastName}
                hasSearchBar={true}
            />
            <div className="p-5">
                <header className="flex justify-between items-center mb-6">
                    <h1 className="font-bold text-3xl leading-9 text-[#09090B]">Felicity AI</h1>

                    {/* <Link to={'/property-management/ai-assistant/call-details/2'} >Call Details page</Link> */}
                </header>
                <div className="pb-4">
                    <StatsCard
                        stats={stats.map((stat) => ({
                            ...stat,
                            value: stat.value ?? 0,
                        }))}
                        isLoading={isAssistanceLogLoading || isLoading || isAiStatLoading}
                    />
                </div>
                <div
                    className={cn(
                        style.cardContainer,
                        "bg-white border border-gray-200 p-[15px] rounded-lg flex justify-between items-center mt-5",
                    )}
                >
                    <div className="flex gap-3 items-center">
                        <div className="flex flex-col">
                            <span className="font-bold text-lg">LightWork AI</span>
                            <span className="text-gray-400 flex items-center gap-2">
                                {isAssistanceLogLoading || isLoading || isAiStatLoading ? (
                                    <Skeleton className="h-[20px] bg-gray-200 w-[140px]" />
                                ) : (
                                    <span>
                                        {user?.userProfile?.assistantPhoneNo ||
                                            "Number Not Assigned"}
                                    </span>
                                )}
                                {user?.userProfile?.assistantPhoneNo && (
                                    <div className="relative">
                                        {isCopied ? (
                                            <FaCheckCircle className="transform text-black animate-pulse" />
                                        ) : (
                                            <IoCopyOutline
                                                className="text-black cursor-pointer"
                                                onClick={copyToClipboard}
                                            />
                                        )}
                                    </div>
                                )}
                            </span>
                        </div>
                    </div>
                    {(parsedIsChatAi === false || parsedIsChatAi === null) && (
                        <Link to="/property-management/knowledge">
                            <Button className="bg-[#000]">Customise AI</Button>
                        </Link>
                    )}
                    {/* <DropdownMenuDemo field={["Message", "logout"]} bg={true} /> */}
                </div>

                <div
                    // className={cn(
                    //     style.cardContainer,
                    //     "h-fit p-5 mb-3 w-full bg-white mt-6 rounded-lg border border-gray-200",
                    // )}
                    className="my-4"
                >
                    <TaskTables
                        mainHeading="Inbound Messages"
                        data={TableData?.data || []}
                        islengthAllow={false}
                        className="text-xs"
                        isLoading={isAssistanceLogLoading || isLoading || isAiStatLoading}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        totalpages={totalpages}
                        onPageChange={handlePageChange}
                        sortAndFilter={
                            <TableActions
                                onClickFilter={() => {}}
                                onClickSort={handleSort}
                                onToggleColumn={handleToggleColumn}
                                visibleColumns={visibleColumns.map((item) => item?.accessorKey)}
                                isSearch={true}
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                sortItems={sortItems}
                                columnItems={columnItems}
                            />
                        }
                        onClickRow={(row: any) => {
                            const rowId = row?.original?.id;
                            const payload =
                                CallLogs && Array.isArray(CallLogs)
                                    ? CallLogs.find((log: any) => log._id === rowId)
                                    : undefined;

                            return navigate(
                                `/property-management/ai-assistant/call-details/${rowId}`,
                                {
                                    state: { ...(payload || {}), RowId: Number(row.id) + 1 },
                                },
                            );
                        }}
                    />
                    {/* <ExpandableButton
                    mainIcon={<MdOutlineReportGmailerrorred className="text-xl" />}
                    handleClick={handleButtonClick}
                /> */}
                    {chatBotOpen && <Chatbot handleClick={() => setChatBotOpen(false)} />}
                    <ExpandableButton
                        mainIcon={<FaQuestion />}
                        isChatClicked={() => setChatBotOpen(false)}
                        buttons={[
                            {
                                icon: <MdOutlineReportGmailerrorred />,
                                onClick: () => handleButtonClick("form"),
                                colorClass: "bg-[#000]",
                            },
                            {
                                icon: <BsChatText />,
                                onClick: () => handleButtonClick("chatBot"),
                                colorClass: "bg-[#000]",
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};
