interface IClient {
    firstName: string;
    lastName: string;
    joinedDate: string;
}

export interface IJobSuccessResponse {
    page: number;
    pageSize: number;
    skip: number;
    data: [
        {
            id: string;
            date: string;
            title: string;
            description: string;
            photoUrl: string[];
            clock: string;
            address: string;
            client: IClient;
            price?: string;
            qoutesCount?: number;
            budget: string;
        },
    ];
}

export interface IJob {
    id: string;
    date: string;
    title: string;
    description: string;
    photoUrl: string[];
    clock: string;
    address: string;
    postedBy: string;
    client: IClient;
    price?: string;
    qoutesCount?: number;
    budget: string;
}

export interface IJobDetail {
    _id: string;
    jobId: string;
    description: string;
    title: string;
    addressId: string;
    rate: number;
    createdAt: string;
    updatedAt: string;
}

export interface IJobDetails extends IJob {
    category: string;
    budget: string;
    createdAt: string;
}

export const JobDetailsValues: IJobDetails = {
    id: "",
    date: "",
    title: "",
    description: "",
    photoUrl: [],
    clock: "",
    address: "",
    client: {
        firstName: "",
        lastName: "",
        joinedDate: "",
    },
    postedBy: "",
    category: "",
    budget: "",
    qoutesCount: 0,
    createdAt: "",
};
