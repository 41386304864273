import { RightArrowIcon } from "@/assets/icons/RightArrowIcon";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbList,
    BreadcrumbPage,
} from "@/components/ui/breadcrumb";
import { capitalizeEachWord } from "@/utils/capitalizeEachWords";
import { NavLink } from "react-router-dom";
import { Skeleton } from "@/components/ui/skeleton";

export const Breadcrumbs = (props: {
    name: string | undefined;
    className?: string;
    isLoading?: boolean | undefined;
}) => (
    <div className={props.className}>
        <Breadcrumb>
            <BreadcrumbList>
                <BreadcrumbItem>
                    {props.isLoading ? (
                        <Skeleton className="h-4 w-[3.2rem] rounded-full bg-gray-300" />
                    ) : (
                        <NavLink to="/property-management/dashboard">Home</NavLink>
                    )}
                </BreadcrumbItem>
                <RightArrowIcon />
                <BreadcrumbItem>
                    {props.isLoading ? (
                        <Skeleton className="h-4 w-[4rem] rounded-full bg-gray-300" />
                    ) : (
                        <NavLink to="/property-management/properties">Properties</NavLink>
                    )}
                </BreadcrumbItem>
                {props.name && (
                    <>
                        <RightArrowIcon />
                        <BreadcrumbItem>
                            {props.isLoading ? (
                                <Skeleton className="h-4 w-[5rem] rounded-full bg-gray-300" />
                            ) : (
                                <BreadcrumbPage>{capitalizeEachWord(props.name)}</BreadcrumbPage>
                            )}
                        </BreadcrumbItem>
                    </>
                )}
            </BreadcrumbList>
        </Breadcrumb>
    </div>
);
