import React, { useState } from "react";
import ApiCall from "../../../../api/requestProcessor";
import { Modal } from "../../../common/modal";
import styles from "./sendQuote.module.css";
import jobImg from "../../../../assets/images/jobImg.png";
import InputField from "../../../common/input/inputField";
import SelectField from "../../../common/input/selectField";
import TextArea from "../../../common/input/textArea";
import Label from "../../../common/label";
import { IQuote } from "../../../../utils/interface/random.interface";
import { displayAlert } from "../../../../utils/service";
import { IJobDetails } from "../../../../utils/interface/dashboard.interface";
import { getFormattedDate } from "../../../../utils/formatDate";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";

const option = [
    { value: "10", label: "10 days" },
    { value: "20", label: "20 days" },
    { value: "30", label: "30 days" },
];

interface SendQuoteModalProps {
    jobDetail: IJobDetails;
    isOpen: boolean;
    onClose: () => void;
}

export const SendQuoteModal: React.FC<SendQuoteModalProps> = ({ jobDetail, isOpen, onClose }) => {
    const navigate = useNavigate();
    const [quote, setQuote] = useState<IQuote>({
        price: "",
        duration: "",
        message: "",
    });
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    ) => {
        setErrors({});
        const { name, value } = e.target;
        setQuote({ ...quote, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const payload = {
            message: quote.message,
            price: Number(quote.price),
            type: "HOURLY",
            duration: quote.duration,
            status: "PENDING",
            startDate: new Date(),
        };
        try {
            setLoading(true);
            const res = await ApiCall.sendPost(`jobs/bid/${jobDetail.id}`, payload);
            if (res.status === 201) {
                displayAlert("Quote sent Successfully");
                setLoading(false);
                onClose();
                navigate("/contractor/my-jobs");
            }
        } catch (error: any) {
            console.error("error: ", error);
            displayAlert(error.response.data.message, "error");
            setLoading(false);
        }
    };

    return (
        <div className={styles.modalMain}>
            <Modal isOpen={isOpen} onClose={onClose} styleName="modalContent">
                <form className={styles.sendQuoteModal} onSubmit={handleSubmit}>
                    <div className={styles.mHeader}>Send Quote</div>
                    <div className={styles.sqBlock}>
                        <div className={styles.sqImg}>
                            <img src={jobDetail.photoUrl[0] || jobImg} alt="jon_image" />
                        </div>
                        <div>
                            <div className={styles.sqHead}>{jobDetail.title}</div>
                            <div className={styles.sqAddress}>{jobDetail.address}</div>
                            <div className={styles.labelSect}>
                                {jobDetail.category.split(", ").map((cat) => (
                                    <Label key={cat} type="white" text={cat} />
                                ))}

                                <Label
                                    type="ash"
                                    text={getFormattedDate(new Date(jobDetail.createdAt))}
                                />
                                <Label type="green" text={`£${jobDetail.budget}`} />
                                {/* <Label type="ash" text="Less than 5 km away" /> */}
                            </div>
                        </div>
                    </div>
                    <div className={styles.sqMarBot}>
                        <label>Set price (£)</label>
                        <div className={styles.inputFieldDiv}>
                            <InputField
                                type="text"
                                name="price"
                                placeholder=""
                                error={errors}
                                required={true}
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className={styles.sqMarBot}>
                        <label>Duration</label>
                        <div className={styles.inputFieldDiv}>
                            <SelectField
                                name="duration"
                                placeholder="Select option"
                                isActive={false}
                                options={option}
                                error={errors}
                                required={true}
                                handleUserDetails={handleChange}
                            />
                        </div>
                    </div>
                    <div className={styles.sqMarBot}>
                        <label>Message client</label>
                        <div>
                            <TextArea
                                handleChange={handleChange}
                                name="message"
                                error={errors}
                                required={true}
                                placeholder="Type your message"
                            />
                        </div>
                    </div>

                    <div className={styles.jDActionBlk}>
                        <Button
                            className={`${styles.cancel} ${styles.maQ5}`}
                            onClick={() => onClose()}
                            type="button"
                        >
                            Cancel
                        </Button>
                        <button
                            className={`${styles.sendAQuote} ${styles.sty2} ${
                                loading ? styles.btnDisabled : ""
                            }`}
                            type="submit"
                            disabled={loading}
                        >
                            <span>{loading ? "Loading..." : "Send a quote"}</span>
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};
