export function HomeIcon() {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.59998 6.7L7.99998 2.5L13.4 6.7V13.3C13.4 13.6183 13.2735 13.9235 13.0485 14.1485C12.8235 14.3736 12.5182 14.5 12.2 14.5H3.79998C3.48172 14.5 3.17649 14.3736 2.95145 14.1485C2.7264 13.9235 2.59998 13.6183 2.59998 13.3V6.7Z"
                stroke="#666D80"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.19995 14.5V8.5H9.79995V14.5"
                stroke="#666D80"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
