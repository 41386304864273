import { useState, useMemo } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { TableActions } from "./TableActions";
import dayjs from "dayjs";
import { MessageSquareMore, ChevronUp, ChevronDown } from "lucide-react";
import { Button } from "@/components/ui/button";

function getInitials(name: string | undefined): string {
    if (!name) return "";
    return name
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join("");
}

interface MembersDisplayProps {
    totalMembers: number;
    myDetails: any;
    additionalMembers?: any[]; // Optional additional members array
}

function filterOutTenants(users: any) {
    return users.filter((user: any) => user.userType !== "TENANT");
}

function MembersDisplay({ totalMembers, myDetails, additionalMembers = [] }: MembersDisplayProps) {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<string>("asc");
    const [selectedRole, setSelectedRole] = useState<string | null>("Property Manager");
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false); // Track dropdown state

    const handleRoleChange = (userType: string) => {
        setSelectedRole(userType);
    };
    console.log("i am the memeberdisplay:", myDetails);

    const getUserName = (details: any) => {
        return details?.firstName && details?.lastName
            ? `${details.firstName} ${details.lastName}`
            : details?.firstName
              ? details.firstName
              : details?.lastName || "";
    };

    const membersData = useMemo(() => {
        if (!myDetails) {
            return [];
        }

        const myDetailsData = {
            name: getUserName(myDetails),
            img: myDetails?.imageUrl || undefined,
            email: myDetails?.email || "",
            joined: myDetails?.createdAt ? dayjs(myDetails.createdAt).format("DD MMM YYYY") : "",
            role: myDetails?.userType || "", // Adjusted to use userType
        };

        // Combine `myDetails` with any additional members passed in
        return [myDetailsData, ...additionalMembers];
    }, [myDetails, additionalMembers]);

    const handleSort = (direction: string) => {
        setSortDirection(direction);
    };

    const filteredAndSortedMembers = useMemo(() => {
        let filteredMembers = membersData.filter(
            (member) =>
                member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                member.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                member.role.toLowerCase().includes(searchTerm.toLowerCase()),
        );

        filteredMembers = filteredMembers.sort((a, b) => {
            const aValue = dayjs(a.joined);
            const bValue = dayjs(b.joined);

            if (sortDirection === "asc") {
                return aValue.isBefore(bValue) ? -1 : 1;
            } else {
                return aValue.isAfter(bValue) ? -1 : 1;
            }
        });

        return filteredMembers;
    }, [searchTerm, sortDirection, membersData]);

    return (
        <div className="flex flex-col w-full p-4 border rounded-2xl border-solid gap-4">
            <div>
                <TableActions
                    onClickSort={handleSort}
                    totalMembers={totalMembers}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
                <Table>
                    <TableHeader>
                        <TableRow className="hover:bg-white">
                            <TableHead>Properties</TableHead>
                            <TableHead>Action</TableHead>
                            {/* <TableHead>Role</TableHead>  */}
                            <TableHead>
                                <span></span>
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {myDetails.length > 0 ? (
                            filterOutTenants(myDetails)?.map((data: any, index: any) => (
                                <TableRow key={index}>
                                    <TableCell className="font-medium flex gap-2">
                                        <Avatar>
                                            <AvatarImage
                                                src={
                                                    data.img ??
                                                    "https://via.placeholder.com/500x500"
                                                }
                                                alt="user"
                                            />
                                            <AvatarFallback>
                                                {getInitials(data.name) ?? "AB"}
                                            </AvatarFallback>
                                        </Avatar>
                                        <div>
                                            <div className="font-normal text-sm leading-5 text-black">
                                                {data.name}
                                            </div>
                                            <div className="font-normal text-xs leading-4 text-gray-500">
                                                {data.email}
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell className="font-normal text-sm leading-5 text-[#09090B]">
                                        <MessageSquareMore width={24} height={24} />
                                    </TableCell>
                                    {/* <TableCell className="flex items-center">
                                        <div className="font-normal text-sm leading-5 text-[#09090B]">
                                            {data.role
                                                .toLowerCase()
                                                .replace(/_/g, " ")
                                                .replace(/^\w/, (c: string) => c.toUpperCase())}
                                        </div>
                                        <DropdownMenu
                                            onOpenChange={(isOpen) => setDropdownOpen(isOpen)}
                                        >
                                            <DropdownMenuTrigger asChild>
                                                <Button
                                                    variant="ghost"
                                                    className="w-[20px] !flex !justify-between !items-center !p-0"
                                                >
                                                    {dropdownOpen ? (
                                                        <ChevronUp width={16} height={14} />
                                                    ) : (
                                                        <ChevronDown width={16} height={16} />
                                                    )}
                                                </Button>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent className="w-56">
                                                <DropdownMenuLabel>Choose a role</DropdownMenuLabel>
                                                <DropdownMenuSeparator />
                                                <DropdownMenuCheckboxItem
                                                    checked={selectedRole === "Property Manager"}
                                                    onCheckedChange={() =>
                                                        handleRoleChange("Property Manager")
                                                    }
                                                >
                                                    Property Manager
                                                </DropdownMenuCheckboxItem>
                                                <DropdownMenuCheckboxItem
                                                    checked={selectedRole === "Contractor"}
                                                    onCheckedChange={() =>
                                                        handleRoleChange("Contractor")
                                                    }
                                                >
                                                    Contractor
                                                </DropdownMenuCheckboxItem>
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </TableCell> */}
                                    {/* <TableCell className="font-medium flex gap-1">
                                    </TableCell> */}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} className="text-center font-semibold">
                                    No data available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
}

export default MembersDisplay;
