import { TCreateJobBody } from "../types";

export const getFullAddressFromAddressObject = (
    addressObject: TCreateJobBody["jobDetails"]["address"],
) => {
    const orderedAddressItems: string[] = [];
    if (addressObject?.building) {
        orderedAddressItems.push(addressObject?.building);
    }
    if (addressObject?.mainStreet) {
        orderedAddressItems.push(addressObject?.mainStreet);
    }
    if (addressObject?.city) {
        orderedAddressItems.push(addressObject?.city);
    }
    if (addressObject?.province) {
        orderedAddressItems.push(addressObject?.province);
    }
    if (addressObject?.country) {
        orderedAddressItems.push(addressObject?.country);
    }

    return orderedAddressItems.join(", ");
};
