import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Dialog } from "../../../components/common/dialog";
import "../../../cssReset.css";
import { useGetMyDetailsQuery, useLazyGetMyDetailsQuery } from "../../../redux/rtk-query/authApis";
import { useCreateJobMutation } from "../../../redux/rtk-query/jobsApis";
import { useAppSelector } from "../../../redux/store";
import { TCreateJobBody } from "../../../types";
import { catchAll } from "../../../utils/catch";
import { getAddressFromGoogleObject } from "../../../utils/getAddressFromGoogleObject";
import { UserType } from "../../../utils/interface/auth.interface";
import { HeadingWithBackButton } from "../HeadingWithBackButton";
import { PostAJobNavigation } from "../PostAJobNavigation";
import styles from "./confirmJobDetails.module.css";
import { ConfirmJobDetailsForm } from "./ConfirmJobDetailsForm";
import { Footer } from "./Footer";

type TUser = UserType.HOMEOWNER | UserType.TENANT;

type TUserBasedLogic = {
    heading: string;
    hasPropertyDropdown: boolean;
    hasDueDate: boolean;
    jobType: TCreateJobBody["jobType"];
    navigateAfterCreating: string;
    hasBudget: boolean;
};

const ConfirmJobDetails = () => {
    const navigate = useNavigate();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const {
        address,
        budget,
        categories,
        date,
        description,
        images,
        title,
        addressMapsObject,
        ...rest
    } = useAppSelector((state) => state.postJob);

    const [createJob] = useCreateJobMutation();
    const [getUserDetails] = useLazyGetMyDetailsQuery();
    const { data: myDetails } = useGetMyDetailsQuery();

    const UserBasedLogic = useMemo(() => {
        const UserBasedLogic: Record<TUser, TUserBasedLogic> = {
            HOMEOWNER: {
                heading: "Confirm Job Details",
                hasPropertyDropdown: false,
                hasDueDate: true,
                jobType: "JOB",
                navigateAfterCreating: "/home-owner/dashboard",
                hasBudget: true,
            },
            TENANT: {
                heading: "Confirm Maintenance Job Details",
                hasPropertyDropdown: false,
                hasDueDate: true,
                jobType: "MAINTENANCE",
                navigateAfterCreating: "/tenant/dashboard",
                hasBudget: false,
            },
        };
        if (!myDetails?.userType) {
            return UserBasedLogic["HOMEOWNER"];
        }
        if (myDetails?.userType !== UserType.HOMEOWNER && myDetails?.userType !== UserType.TENANT) {
            return UserBasedLogic["HOMEOWNER"];
        }

        const userType = myDetails?.userType! as TUser;
        return UserBasedLogic[userType] ?? UserBasedLogic["HOMEOWNER"];
    }, [myDetails?.userType]);

    const onClickPostJob = async () => {
        if (images.length === 0) {
            toast.error("Upload at least one photo");
            return;
        }

        const rate = Number(budget);

        if ((isNaN(rate) || rate === 0) && myDetails?.userType === UserType.HOMEOWNER) {
            toast.error("Invalid budget value");
            return;
        }

        if (!title) {
            toast.error("Title is required");
            return;
        }

        if (!description) {
            toast.error("Description is required");
            return;
        }

        if (!date) {
            toast.error("Start Date is required");
            return;
        }

        if (!address) {
            toast.error("Address is required");
            return;
        }

        if (UserBasedLogic.hasPropertyDropdown && !rest.property) {
            toast.error("Property is required");
            return;
        }

        if (UserBasedLogic.hasDueDate && !rest.dueDate) {
            toast.error("Due Date is required");
            return;
        }

        let _id = "";
        if (myDetails?._id) {
            _id = myDetails._id;
        } else {
            const response = await catchAll(getUserDetails().unwrap());

            if (response.error) {
                toast.error("Failed to get user details. Try again");
                return;
            }

            _id = response.data._id;
        }
        let companyId = "";
        if (myDetails?.companyId) {
            companyId = myDetails.companyId;
        }
        if (!addressMapsObject) {
            toast.error("Failed to get address. Go back to previous screen and try again.");
            return;
        }
        const _address = getAddressFromGoogleObject(addressMapsObject);

        await createJob({
            address: { ..._address, mainStreet: address },
            categories,
            date,
            description,
            images,
            title,
            navigate,
            _id,
            budget: rate,
            jobType: UserBasedLogic.jobType,
            navigateAfterCreating: UserBasedLogic.navigateAfterCreating,
            ...(rest.dueDate ? { dueDate: rest.dueDate } : {}),
            companyId,
            ...(rest.property ? { property: rest.property } : {}),
        });

        setIsDialogOpen(false);
    };

    return (
        <div>
            <PostAJobNavigation hasResult={false} />
            <HeadingWithBackButton heading={UserBasedLogic.heading} />
            <div className={styles.main}>
                <ConfirmJobDetailsForm
                    hasPropertyDropdown={UserBasedLogic.hasPropertyDropdown}
                    hasDueDate={UserBasedLogic.hasDueDate}
                    hasBudget={UserBasedLogic.hasBudget}
                />
            </div>
            <Footer onClickPrimary={() => setIsDialogOpen(true)} />
            <Dialog
                heading="Confirm Job Post"
                primaryButtonLabel="Yes, Post Job"
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                onClickPrimary={onClickPostJob}
                content={
                    <p className={styles.dialogText}>
                        Are you sure you want to post this job? Once confirmed, your job will be
                        visible to potential candidates
                    </p>
                }
            />
        </div>
    );
};

export default ConfirmJobDetails;
