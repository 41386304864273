import { IOption } from "../../../utils/constantData";
import styles from "../Common.module.css";

interface ISelectField {
    options: IOption[];
    name: string;
    required?: boolean;
    placeholder?: string;
    isActive: boolean | string;
    value?: string | undefined;
    error: Record<string, string>;
    label?: string;
    handleUserDetails?: (event: any) => void;
}

function SelectField({
    options,
    name,
    required = false,
    placeholder,
    isActive,
    value,
    error,
    label,
    handleUserDetails,
}: ISelectField) {
    return (
        <div className={styles.selectFieldFrame}>
            {label && (
                <div className={styles.label}>
                    <label>{label}</label>
                    {required && <i className="ri-asterisk"></i>}
                </div>
            )}
            <select
                className={isActive ? styles.selectActive : ""}
                name={name}
                required={required}
                onChange={handleUserDetails}
                value={value}
            >
                {placeholder && (
                    <option value={""} disabled selected>
                        {placeholder}
                    </option>
                )}
                {options.length > 0 &&
                    options.map((item) => (
                        <option key={item.value} value={item.value}>
                            {item.label}
                        </option>
                    ))}
            </select>
            {error[name] && (
                <div className={styles.inputFieldError}>
                    <i className="ri-error-warning-fill"></i>
                    <span>{error[name]}</span>
                </div>
            )}
        </div>
    );
}

export default SelectField;
