import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useResetPasswordMutation } from "@/redux/rtk-query/settings";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { Eye, EyeOff } from "lucide-react";
import { useGetMyDetailsQuery } from "@/pages/propertyManagementKnowledgeBase";
import { Spinner } from "@/components/ui/spinner";

const passwordSchema = z
    .object({
        currentPassword: z.string().min(6, "Password must be at least 6 characters."),
        newPassword: z.string().min(6, "Password must be at least 6 characters."),
        confirmPassword: z.string().min(6, "Password must be at least 6 characters."),
    })
    .refine((data) => data.newPassword === data.confirmPassword, {
        message: "Passwords don't match",
        path: ["confirmPassword"],
    });

const PasswordForm = () => {
    const [resetPassword, { isLoading: isPassWordupdating }] = useResetPasswordMutation();
    const { refetch } = useGetMyDetailsQuery();

    const [showPasswords, setShowPasswords] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
    });

    const form = useForm({
        resolver: zodResolver(passwordSchema),
        defaultValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
    });

    async function onPasswordSubmit(values: z.infer<typeof passwordSchema>) {
        try {
            await resetPassword({
                old_password: values.currentPassword,
                new_password: values.newPassword,
            }).unwrap();
            refetch();
            toast.success("Password updated successfully");
            form.reset();
        } catch (error) {}
    }

    const togglePasswordVisibility = (field: keyof typeof showPasswords) => {
        setShowPasswords((prev) => ({ ...prev, [field]: !prev[field] }));
    };

    const isFormEmpty = Object.values(form.watch()).every((value) => value === "");

    return (
        <div className="w-full">
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onPasswordSubmit)} className="space-y-4">
                    <FormField
                        control={form.control}
                        name="currentPassword"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Current Password</FormLabel>
                                <FormControl>
                                    <div className="relative">
                                        <Input
                                            className="focus-visible:ring-1"
                                            type={
                                                showPasswords.currentPassword ? "text" : "password"
                                            }
                                            placeholder="Enter current password"
                                            {...field}
                                        />
                                        <button
                                            type="button"
                                            onClick={() =>
                                                togglePasswordVisibility("currentPassword")
                                            }
                                            className="absolute right-2 top-1/2 transform -translate-y-1/2"
                                        >
                                            {showPasswords.currentPassword ? (
                                                <EyeOff size={20} />
                                            ) : (
                                                <Eye size={20} />
                                            )}
                                        </button>
                                    </div>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="newPassword"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>New Password</FormLabel>
                                <FormControl>
                                    <div className="relative">
                                        <Input
                                            className="focus-visible:ring-1"
                                            type={showPasswords.newPassword ? "text" : "password"}
                                            placeholder="Enter new password"
                                            {...field}
                                        />
                                        <button
                                            type="button"
                                            onClick={() => togglePasswordVisibility("newPassword")}
                                            className="absolute right-2 top-1/2 transform -translate-y-1/2"
                                        >
                                            {showPasswords.newPassword ? (
                                                <EyeOff size={20} />
                                            ) : (
                                                <Eye size={20} />
                                            )}
                                        </button>
                                    </div>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="confirmPassword"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Confirm New Password</FormLabel>
                                <FormControl>
                                    <div className="relative">
                                        <Input
                                            className="focus-visible:ring-1"
                                            type={
                                                showPasswords.confirmPassword ? "text" : "password"
                                            }
                                            placeholder="Confirm new password"
                                            {...field}
                                        />
                                        <button
                                            type="button"
                                            onClick={() =>
                                                togglePasswordVisibility("confirmPassword")
                                            }
                                            className="absolute right-2 top-1/2 transform -translate-y-1/2"
                                        >
                                            {showPasswords.confirmPassword ? (
                                                <EyeOff size={20} />
                                            ) : (
                                                <Eye size={20} />
                                            )}
                                        </button>
                                    </div>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <div className="flex justify-end mt-6">
                        <Button type="submit" disabled={isFormEmpty || isPassWordupdating}>
                            {isPassWordupdating && (
                                <Spinner size="small" className="text-white mr-1" />
                            )}
                            Change Password
                        </Button>
                    </div>
                </form>
            </Form>
        </div>
    );
};

export default PasswordForm;
