import React, { useState } from "react";
import "../../cssReset.css";
import { TableHeadItems, columnItems } from "./Workorder.constant";
import { TaskTables } from "@/components/table/TaskTable";
import { Button } from "@/components/ui/button";
import { useWorkOrderRow } from "./ui/useWorkOrderRow";
// import { useGetJobStatsQuery } from "@/redux/rtk-query/jobsApis";
import { Header } from "@/components/common/headers/Header";
import { useGetMyDetailsQuery } from "../propertyManagementKnowledgeBase";
import StatsInfo from "./components/StatsInfo";
import LBModal from "@/components/lBComponents/LBModal";
import AddWorkContainer from "./components/AddWorkContainer";
import { WorkOrderTableAction } from "./table/WorkOrderTableAction";
import WorkOrder from "@/types";
import { useGetJobFileMetadataQuery } from "@/redux/rtk-query/uploadApis";

const MyJobs = () => {
    const { data: user, isLoading: isUserLoading } = useGetMyDetailsQuery();
    const [workOrderDialog, setWorkOrderDialog] = React.useState(false);
    const [currentMode, setCurrentMode] = useState<"Edit" | "Add" | "Preview">("Add");
    const [currentPreviewOrderInfo, setCurrentPreviewOrderInfo] =
        React.useState<WorkOrder | null>();
    const [currentDocuments, setCurrentDocuments] = useState<any[] | undefined>(undefined);
    const { data: files, isFetching: isDocumentsLoading } = useGetJobFileMetadataQuery(
        currentPreviewOrderInfo?._id || "",
        {
            skip: !currentPreviewOrderInfo?._id,
        },
    );

    React.useEffect(() => {
        if (files && currentPreviewOrderInfo) {
            setCurrentDocuments(files);
        }
    }, [files, currentPreviewOrderInfo]);

    const headerStyle = "border-y border-r border-t-0 first:border-l-0 last:border-r-0";
    const cellStyle = "border-y border-r first:border-l-0 last:border-r-0";
    const {
        activeJobs,
        JobDetailsRow,
        isWorkorderLoading,
        isFetching,
        pagination,
        handlePageChange,
        sortColumn,
        sortDirection,
        visibleColumns,
        handleStatusFilter,
        handleSearch,
        handlePriorityFilter,
        handleDateFilter,
        handleResetFilters,
        handleToggleColumn,
        isFiltering,
        activeStatuses,
        activePriorities,
    } = useWorkOrderRow({ head: TableHeadItems });

    const isLoading = isWorkorderLoading || isFetching || isUserLoading;

    const TableData = {
        data: [
            {
                header: visibleColumns,
                row: [...JobDetailsRow],
                heading: "All Requests",
            },
        ],
    };

    return (
        <div className="max-h-screen overflow-y-auto w-full bg-white">
            <Header image={user?.imageUrl} heading={undefined} username="" hasSearchBar={true} />
            <div className="p-5">
                <header className="flex justify-between items-center mb-6">
                    <h1 className="font-bold text-3xl leading-9 text-[#09090B]">Work Order</h1>
                    <Button
                        onClick={() => {
                            setCurrentMode("Add");
                            setWorkOrderDialog(true);
                        }}
                    >
                        Add Work Order
                    </Button>
                </header>
                {workOrderDialog && (
                    <LBModal
                        headerContainerClassName=""
                        closeButtonClassName="text-black"
                        isOpen={workOrderDialog}
                        onClose={() => {
                            setWorkOrderDialog(false);
                            setCurrentPreviewOrderInfo(null);
                            setCurrentMode("Add");
                            setCurrentDocuments(undefined); // Clear documents when closing
                        }}
                        title={
                            currentMode === "Edit"
                                ? "Edit Work Order"
                                : currentMode === "Preview"
                                  ? "Work Order Details"
                                  : "Add Work Order"
                        }
                        titleClassName="text-[16px] font-semibold"
                    >
                        <AddWorkContainer
                            mode={currentMode}
                            currentPreviewOrderInfo={currentPreviewOrderInfo}
                            setCurrentMode={setCurrentMode}
                            currentWorkOrderDocuments={currentDocuments}
                            currentDocumentsLoading={isDocumentsLoading}
                            setWorkOrderDialog={setWorkOrderDialog}
                        />
                    </LBModal>
                )}
                <StatsInfo />

                <TaskTables
                    data={TableData.data}
                    card={false}
                    className={`${headerStyle} text-xs`}
                    tableCellStyle={cellStyle}
                    isLoading={isLoading}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    totalpages={pagination.totalPages}
                    currentPage={pagination.page}
                    onPageChange={(page) => handlePageChange(page - 1)}
                    handleFilter={handleStatusFilter}
                    isLengthAllow={false}
                    sortAndFilter={
                        <WorkOrderTableAction
                            onSearch={handleSearch}
                            onStatusFilter={handleStatusFilter}
                            onPriorityFilter={handlePriorityFilter}
                            onDateFilter={handleDateFilter}
                            onToggleColumn={handleToggleColumn}
                            visibleColumns={visibleColumns.map((item) => item?.accessorKey)}
                            columnItems={columnItems}
                            isLoading={isLoading}
                            onResetFilters={handleResetFilters} // Add this
                            activeStatuses={activeStatuses}
                            activePriorities={activePriorities}
                        />
                    }
                    islengthAllow={false}
                    onClickRow={(row: any) => {
                        const rowId = row?.original?.id;
                        //get data of clicked row
                        const payload = activeJobs.find((log: any) => log._id === rowId);
                        setCurrentPreviewOrderInfo(payload);
                        setCurrentMode("Preview");
                        setWorkOrderDialog(true);
                    }}
                    useTabs={false}
                />
            </div>
        </div>
    );
};

export default MyJobs;
