import { ComponentProps, memo } from "react";
import styles from "./homeOwnerDashboard.module.css";

export const HomeOwnerCardButton = memo(({ children, ...rest }: ComponentProps<"button">) => {
    return (
        <button className={styles.homeOwnerCardButton} {...rest}>
            {children}
        </button>
    );
});
