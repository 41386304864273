import * as z from "zod";
import {
    TAddress,
    TPropertyBuildingFormResponse,
    TUser,
    TProperty,
    TGetAllTenantsTransformedResponse,
} from "@/types/index";

// Create a Zod schema for TPropertyBuilding
const PropertyBuildingSchema = z.custom<TPropertyBuildingFormResponse>(
    (value) => {
        if (typeof value === "object" && value !== null && "_id" in value) {
            return true;
        }
        return false;
    },
    {
        message: "Please select a property",
    },
);

export const workOrderSchema = z.object({
    title: z.string().min(1, { message: "Title is required" }),
    category: z
        .array(z.string())
        .min(1, { message: "Please upload atleast one category" })
        .max(10, { message: "You can select up to 10 categories" }),
    description: z.string().min(1, { message: "Description is required" }),
    requestedBy: z.custom<TGetAllTenantsTransformedResponse>((value) => value && "id" in value, {
        message: "Requested by is required",
    }),
    contractor: z.custom<TUser>((value) => value && "_id" in value, {
        message: "Contractor is required",
    }),
    dueDate: z.date({
        required_error: "Due date is required",
        invalid_type_error: "Due date must be a valid date",
        message: "Due date is required",
    }),
    priority: z.string(),
    status: z.string(),
    notes: z.string(),
    property: PropertyBuildingSchema,
    unit: z.custom<TProperty>().refine((value) => value !== null && "_id" in value, {
        message: "Please select a unit",
    }),
    photoUrl: z.array(z.string()).min(1, { message: "Please upload atleast one image" }),
    documents: z.array(
        z.object({
            _id: z.string().optional(),
            fileName: z.string(),
            filePath: z.string(),
            createdAt: z.string().optional(),
            createdBy: z.string().optional(),
            group: z.string().optional(),
            refId: z.string().optional(),
            status: z.string().optional(),
            tag: z.string().optional(),
            type: z.string().optional(),
            updatedAt: z.string().optional(),
            __v: z.string().optional(),
        }),
    ),
    address: z.custom<TAddress>(),
});

export const defaultWorkOrderValues: Partial<WorkOrderFormValues> = {
    title: "",
    category: [] as string[],
    description: "",
    requestedBy: {} as TGetAllTenantsTransformedResponse,
    contractor: undefined,
    dueDate: new Date(),
    priority: "Standard",
    status: "Not started",
    notes: "",
    property: {} as TPropertyBuildingFormResponse,
    unit: {} as TProperty,
    photoUrl: [] as string[],
    documents: [] as { fileName: ""; filePath: "" }[],
    address: {} as TAddress,
};

export type WorkOrderFormValues = z.infer<typeof workOrderSchema>;
