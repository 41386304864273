import { TDropdownItem } from "../components/common/dropdown/Dropdown.types";
import { TJobTabFilter, TUserType } from "../types";

export const skillsetArr: string[] = [
    "HVAC",
    "Heating",
    "Pipes",
    "Feature Setup",
    "Gas Lines",
    "Electrician",
    "Plumbling",
    "Carpentry",
    "Landscaping",
    "Insulation",
    "Tiling",
    "Plastering",
    "Locksmithing",
];

export interface IOption {
    value: string;
    label: string;
}

export const skillLabel: IOption[] = [
    { value: "Electrical Work", label: "Electrical Work" },
    { value: "Masonry", label: "Masonry" },
    { value: "Painting", label: "Painting" },
    { value: "Decoration", label: "Decoration" },
    { value: "Roofing", label: "Roofing" },
    { value: "Flooring", label: "Flooring" },
    { value: "Drywall Installation", label: "Drywall Installation" },
    { value: "Glazing", label: "Glazing" },
    { value: "Welding", label: "Welding" },
    { value: "Cabinetmaking", label: "Cabinetmaking" },
    { value: "Restoration", label: "Restoration" },
    { value: "Demolition", label: "Demolition" },
    {
        value: "Smart Home Technology Installation",
        label: "Smart Home Technology Installation",
    },
    { value: "Solar Panel Installation", label: "Solar Panel Installation" },
    { value: "Asbestos Removal", label: "Asbestos Removal" },
    { value: "Pest Control", label: "Pest Control" },
    { value: "Scaffolding", label: "Scaffolding" },
    { value: "Joinery", label: "Joinery" },
    { value: "Soundproofing", label: "Soundproofing" },
    { value: "Waterproofing", label: "Waterproofing" },
    { value: "Fencing", label: "Fencing" },
    { value: "Fireproofing", label: "Fireproofing" },
    {
        value: "Elevator Installation and Maintenance",
        label: "Elevator Installation and Maintenance",
    },
    {
        value: "Window Installation and Repair",
        label: "Window Installation and Repair",
    },
    { value: "Home Theater Design", label: "Home Theater Design" },
    { value: "Ductwork and Ventilation", label: "Ductwork and Ventilation" },
];

export const HomeOwnerTabs: {
    id: TJobTabFilter;
    label: string;
    userType: TUserType[];
}[] = [
    {
        id: "ALL",
        label: "All",
        userType: ["HOMEOWNER", "PROPERTY_MANAGER"],
    },
    {
        id: "ACTIVE",
        label: "Active",
        userType: ["CONTRACTOR", "HOMEOWNER", "PROPERTY_MANAGER"],
    },
    {
        id: "QUOTES",
        label: "Quoted",
        userType: ["CONTRACTOR"],
    },
    {
        id: "OFFERS",
        label: "Offers",
        userType: ["CONTRACTOR", "HOMEOWNER", "PROPERTY_MANAGER"],
    },
    {
        id: "NOT_STARTED",
        label: "Open",
        userType: ["HOMEOWNER", "PROPERTY_MANAGER"],
    },
    {
        id: "COMPLETED",
        label: "Completed",
        userType: ["CONTRACTOR", "HOMEOWNER", "PROPERTY_MANAGER"],
    },
];

export const SettingTabs: {
    id: any;
    label: string;
    userType: TUserType[];
}[] = [
    {
        id: "Account",
        label: "Account",
        userType: ["CONTRACTOR", "HOMEOWNER"],
    },
    {
        id: "Billing",
        label: "Billing",
        userType: ["CONTRACTOR", "HOMEOWNER"],
    },
];

export const JobCardDropdownOptions = [
    {
        key: "Send a message",
        value: "send_a_message",
    },
    {
        key: "Propose new quote",
        value: "propose_new_quote",
    },
    {
        key: "Dispute",
        value: "dispute",
    },
    {
        key: "Report User",
        value: "report_user",
    },
];

export const UserProfileDropdowns: TDropdownItem[] = [
    {
        key: "Settings",
        value: "settings",
    },
    // {
    //     key: "Help",
    //     value: "help",
    // },
    {
        key: "Logout",
        value: "logout",
        hasDividerAbove: true,
    },
];

export const ManageAiAssistantDropdown: TDropdownItem[] = [
    {
        key: "Set Pricing",
        value: "SET_PRICING",
    },
    {
        key: "Set AI voice assistant",
        value: "SET_AI_VOICE_ASSISTANT",
    },
    {
        key: "Manage profile",
        value: "MANAGE_PROFILE",
    },
];
export const propertyTypeOptions: IOption[] = [
    { value: "FOR_RENT", label: "FOR_RENT" },
    { value: "FOR_LEASE", label: "FOR_LEASE" },
    { value: "FOR_SALE", label: "FOR_SALE" },
];

export const addressTypeOptions: IOption[] = [
    { value: "WORK", label: "WORK" },
    { value: "HOME", label: "HOME" },
    { value: "JOB", label: "JOB" },
];

export const statusOptions: IOption[] = [
    { value: "BOOKED", label: "BOOKED" },
    { value: "AVAILABLE", label: "AVAILABLE" },
    { value: "NOT_AVAILABLE", label: "NOT_AVAILABLE" },
];

export const categoryOptions: IOption[] = [
    { value: "PUBLIC", label: "PUBLIC" },
    { value: "COMMERCIAL", label: "COMMERCIAL" },
    { value: "FLATS/APARTMENTS", label: "FLATS/APARTMENTS" },
    { value: "LAND", label: "LAND" },
];

export const PROPERTY_TYPES = [
    {
        label: "Office Building",
        value: "OFFICE_BUILDING",
        category: "commercial",
    },
    {
        label: "Retail Property",
        value: "RETAIL_PROPERTY",
        category: "commercial",
    },
    {
        label: "Industrial Property",
        value: "INDUSTRIAL_PROPERTY",
        category: "commercial",
    },
    {
        label: "Hotel/Bar",
        value: "HOTEL_BAR",
        category: "commercial",
    },

    {
        label: " Single Family Home",
        value: "SINGLE_FAMILY_HOME",
        category: "residential",
    },
    {
        label: "Multi-Family Home",
        value: "MULTI_FAMILY_HOME",
        category: "residential",
    },
    {
        label: "Flat/Apartment",
        value: "FLAT_APARTMENT",
        category: "residential",
    },
    {
        label: "Townhouse",
        value: "TOWNHOUSE",
        category: "residential",
    },
    {
        label: "Other",
        value: "OTHER",
        category: "residential",
    },
];
