import { memo } from "react";
import { SortIcon } from "../../assets/icons/SortIcon";
import { WindowIcon } from "../../assets/icons/WindowIcon";
import { InputWithSearchIcon } from "../../components/common/input/InputWithSearchIcon";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";

export type TTableActions = {
    icon: JSX.Element;
    label: string;
    onClick: (() => void) | null;
    customComponent?: JSX.Element;
};

type TableActionsProps = {
    onClickFilter: () => void;
    onClickSort: (column: string) => void;
    onToggleColumn: (column: string) => void;
    visibleColumns: string[];
    searchTerm: string;
    setSearchTerm: (term: string) => void;
};

export const TableActions = memo((props: TableActionsProps) => {
    const { onClickSort, onToggleColumn, visibleColumns, searchTerm, setSearchTerm } = props;

    const sortItems = [
        { key: "Name", value: "name" },
        { key: "Type", value: "type" },
        { key: "Status", value: "status" },
        { key: "City", value: "city" },
    ];

    const columnItems = [
        { key: "ID", value: "id" },
        { key: "Name", value: "name" },
        { key: "City", value: "city" },
        { key: "Images", value: "images" },
        { key: "Type", value: "type" },
        { key: "Status", value: "status" },
    ];

    const handleSortChange = (value: string) => {
        onClickSort(value);
    };

    const handleColumnChange = (value: string) => {
        onToggleColumn(value);
    };

    return (
        <div className="flex items-center justify-between mb-4">
            <InputWithSearchIcon
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="flex gap-2">
                <Select onValueChange={handleSortChange}>
                    <SelectTrigger className="flex items-center gap-2">
                        <SortIcon />
                        <SelectValue placeholder="Sort" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectLabel>Sort By</SelectLabel>
                            {sortItems.map((item) => (
                                <SelectItem key={item.value} value={item.value}>
                                    {item.key}
                                </SelectItem>
                            ))}
                        </SelectGroup>
                    </SelectContent>
                </Select>
                <Select onValueChange={handleColumnChange}>
                    <SelectTrigger className="flex items-center gap-2">
                        <WindowIcon />
                        <SelectValue placeholder="Columns" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectLabel>Columns</SelectLabel>
                            {columnItems.map((item) => (
                                <SelectItem key={item.value} value={item.value}>
                                    {item.key} {visibleColumns.includes(item.value) ? "✓" : ""}
                                </SelectItem>
                            ))}
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
        </div>
    );
});
