import { memo } from "react";
import { TSideBarItemValue } from ".";
import { TKeyValue } from "../../../types";
import styles from "./account.module.css";

type TProps = {
    sidebarItems: ReadonlyArray<TKeyValue<string, TSideBarItemValue>>;
    onClickSidebarItem: (selectedItem: TSideBarItemValue) => void;
    selectedSidebarItem: TSideBarItemValue;
};

export const AccountSidebar = memo((props: TProps) => {
    const { sidebarItems, onClickSidebarItem, selectedSidebarItem } = props;
    return (
        <ul className={styles.accountSidebar}>
            {sidebarItems.map(({ key, value }) => {
                return (
                    <li key={key}>
                        <button
                            className={`${styles.accountSidebarItem} ${
                                selectedSidebarItem === value
                                    ? styles.accountSidebarItemSelected
                                    : ""
                            }`}
                            onClick={() => onClickSidebarItem(value)}
                        >
                            {key}
                        </button>
                    </li>
                );
            })}
        </ul>
    );
});
