import { ChangeEvent, ComponentProps, memo } from "react";
import { SearchIcon } from "../../../assets/icons";
import styles from "./Input.module.css";

type TProps = {
    isAllow?: boolean;
    inputProps?: Omit<ComponentProps<"input">, "value" | "onChange">;
    containerProps?: ComponentProps<"div">;
    width?: React.CSSProperties["width"];
    value?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    placeholder?: string;
};

export const InputWithSearchIcon = memo((props: TProps) => {
    const {
        width,
        inputProps,
        value,
        onChange,
        isAllow = true,
        className = "",
        placeholder = "Search",
    } = props;

    return (
        <div
            className={`${styles.inputWithSearchContainer} ${props.containerProps?.className} ${className}`}
            style={{ width, ...props.containerProps?.style }}
            {...props.containerProps}
        >
            {isAllow && (
                <span className={className}>
                    <SearchIcon />
                </span>
            )}
            <input
                placeholder={placeholder}
                {...inputProps}
                style={{ ...inputProps?.style }}
                value={value}
                onChange={onChange}
            />
        </div>
    );
});
