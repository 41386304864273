import { Price } from "./Price";
import { ProfileImage } from "./ProfileImage";
import { Rating } from "./Rating";
import styles from "./smallJobCard.module.css";
import { memo } from "react";

type TProps = {
    onClick?: () => void;
};

export const ProfileWithPrice = memo((props: TProps) => {
    const { onClick } = props;
    return (
        <div onClick={onClick} className={styles.suggestionCardInfoContainer}>
            <ProfileImage />
            <div className={styles.suggestionCardCenter}>
                <p className={styles.suggestionCardName}>John Doe</p>
                <p className={styles.suggestionCardCountry}>United Kingdom</p>
                <Rating />
            </div>
            <Price />
        </div>
    );
});
