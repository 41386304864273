import { ChangeEventHandler, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../../cssReset.css";
import { setPostJobUserPromptToAI } from "../../redux/features/postJobSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { AIPromptContainer } from "./AIPromptContainer";
import { HeadingWithBackButton } from "./HeadingWithBackButton";
import styles from "./homeOwnerPostAJob.module.css";
import { PostAJobNavigation } from "./PostAJobNavigation";
import { useGetAIJobRecommendationMutation } from "../../redux/rtk-query/aiApis";
import { useGetMyDetailsQuery } from "../../redux/rtk-query/authApis";
import { capitalize } from "../../utils/capitalize";

const PostAJob = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userPrompt = useAppSelector((state) => state.postJob.userPrompt);

    const [getAIResponseFromPrompt, { isLoading }] = useGetAIJobRecommendationMutation();
    const { data: myDetails } = useGetMyDetailsQuery();

    const onChangeInputValue: ChangeEventHandler<HTMLInputElement> = useCallback(
        (e) => {
            dispatch(setPostJobUserPromptToAI(e.target.value));
        },
        [dispatch],
    );

    const onClickArrowButton = useCallback(async () => {
        if (!userPrompt) return;

        getAIResponseFromPrompt({ prompt: userPrompt, navigate, myDetails });
    }, [getAIResponseFromPrompt, myDetails, navigate, userPrompt]);

    const firstName = myDetails?.firstName ?? "";
    const lastName = myDetails?.lastName ?? "";
    const fullName = capitalize(firstName) + " " + capitalize(lastName);

    return (
        <div>
            <PostAJobNavigation hasResult={false} />
            <HeadingWithBackButton heading="Post a job" />
            <div className={styles.main}>
                <AIPromptContainer
                    name={fullName}
                    value={userPrompt}
                    onChangeValue={onChangeInputValue}
                    onClickArrowButton={onClickArrowButton}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
};

export default PostAJob;
