import React, { useState, useRef, useEffect, useCallback } from "react";
import { TUserType } from "@/types";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
    DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { ChevronDown, Slash } from "lucide-react";
import { z } from "zod";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { cn } from "@/lib/utils";
import { useGetMyDetailsQuery, useInviteUsersMutation } from "../../../redux/rtk-query/authApis";
import styles from "./PropertyManagementInviteTenants.module.css";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";
import PhoneInput from "react-phone-input-2";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { CalendarIcon } from "lucide-react";
import { Upload } from "lucide-react";
import { FileText } from "lucide-react";
import { Calendar } from "@/components/ui/calendar";
import { format } from "date-fns";
import {
    useCreateJobFileMetadataMutation,
    useUploadToAWSMutation,
} from "@/redux/rtk-query/uploadApis";
import { X } from "lucide-react";
import { useGetBuildingQuery, useGetBuildingsByCompanyQuery } from "@/redux/rtk-query/properties";
import { useCreateContractMutation } from "@/redux/rtk-query/contractsApis";
import { Input } from "@/components/ui/input";
import { catchAll } from "@/utils/catch";
import { Header } from "@/components/common/headers/Header";

const formSchema = z.object({
    name: z.string().min(2, { message: "First name needs 2+ chars." }),
    DOB: z.string().min(3, { message: "Please choose the correct format of date" }),
    email: z.string().email({ message: "Please enter a valid email address." }),
    phoneNumber: z.string().min(10, { message: "Phone number must be at least 10 digits." }),
    emergencyEmail: z.string().email({ message: "Please enter a valid email address." }),
    emergencyPhoneNumber: z
        .string()
        .min(10, { message: "Phone number must be at least 10 digits." }),
    rentAmount: z.string().min(1, { message: "Rent is required." }),
    securityDeposit: z.string().min(1, { message: "Security deposit is required" }),
    startPeriod: z.string().min(1, { message: "Start period is required" }),
    endPeriod: z.string().min(1, { message: "End period is required" }),
    files: z.array(z.string()).max(10, { message: "You can upload a maximum of 10 files." }),
    property: z.object({
        _id: z.string(),
        name: z.string(),
    }),
    unit: z.object({
        _id: z.string(),
        name: z.string(),
    }),
});

type TInviteOwnerArgs = {
    id?: string;
    firstName: string;
    companyId?: string;
    lastName: string;
    email: string;
    status: string;
    deleted_at?: Date;
    phoneNumber?: string;
    userType: TUserType;
};

interface SelectedPropertyType {
    name: string;
    _id: string;
}

const defaultValues = {
    name: "",
    DOB: "",
    email: "",
    phoneNumber: "",
    emergencyEmail: "",
    emergencyPhoneNumber: "",
    rentAmount: "",
    securityDeposit: "",
    startPeriod: "",
    endPeriod: "",
    property: { _id: "", name: "" } as SelectedPropertyType,
    unit: { _id: "", name: "" } as SelectedPropertyType,
    files: [],
};

export const PropertyManagementInviteTenants = () => {
    const phoneInputContainerRef = useRef<HTMLDivElement | null>(null);
    const [selectedProperty, setSelectedProperty] = useState<SelectedPropertyType | null>(null);
    const [selectedUnit, setSelectedUnit] = useState<SelectedPropertyType>({ _id: "", name: "" });

    const [uploadFileToAWS, { isLoading: isFileUploading }] = useUploadToAWSMutation();
    const [fileNames, setFileNames] = useState<string[]>([]);
    const [createFileMetadata, { isLoading: createFileMetadataLoading }] =
        useCreateJobFileMetadataMutation();
    const [inviteOwner] = useInviteUsersMutation();
    const [createContract, { isLoading: createContractLoading }] = useCreateContractMutation();
    const { data: user } = useGetMyDetailsQuery();

    const { data: propertiesData } = useGetBuildingsByCompanyQuery(
        { id: user?.companyId || "", type: "COMPANY" },
        {
            skip: !user?.companyId,
        },
    );

    const { data: unitData, refetch: refetchUnits } = useGetBuildingQuery(
        { id: selectedProperty?._id ?? "", type: "BUILDING" },
        { skip: !selectedProperty?._id }, // Only fetch when a property is selected
    );

    useEffect(() => {
        if (selectedProperty?._id) {
            refetchUnits();
        }
    }, [selectedProperty, refetchUnits]);

    const handlePropertyChange = (property: SelectedPropertyType) => {
        setSelectedProperty(property);
        setSelectedUnit({ _id: "", name: "" }); // Reset the selected unit when a new property is selected
        form.setValue("property", property);
    };

    const handleUnitChange = (unit: SelectedPropertyType) => {
        console.log(unit, "------ in units ------");
        setSelectedUnit(unit);
        form.setValue("unit", unit);
    };

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues,
    });

    const { getValues } = form;

    const onSubmit = async () => {
        try {
            const userId = await onClickSaveOwner();
            if (!userId) {
                toast.error("Failed to invite user");
                return;
            }
            await onClickSaveContract(userId);
            toast.success("Invite Sent Successfully.");
            form.reset({
                ...defaultValues,
                emergencyPhoneNumber: "+44",
                phoneNumber: "+44",
            });
        } catch (error) {
            console.error("Failed to add Tenant:", error);
            toast.error("Failed to add Tenant. Please try again.");
        }
    };

    const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();

            const values = getValues();
            const emailValue = values.email.trim();
            const phoneNumberValue = values.phoneNumber.trim();
            await form.trigger();

            if (emailValue === "" || phoneNumberValue === "") {
                if (phoneNumberValue === "" && emailValue !== "") {
                    if (phoneInputContainerRef.current) {
                        const input = phoneInputContainerRef.current.querySelector(
                            'input[type="tel"]',
                        ) as HTMLInputElement;
                        if (input) {
                            setTimeout(() => {
                                input.focus();
                            }, 0);
                        }
                    }
                }
                return;
            }
        }
    };

    const onClickSaveOwner = async () => {
        if (!user?.companyId) {
            toast.error("Token expired. Please login again");
            return;
        }
        const { email, name, phoneNumber } = form.getValues();

        if (!name) {
            form.setError("name", {
                type: "required",
                message: "Property owner is required",
            });
            return;
        }

        if (!email) {
            form.setError("email", {
                type: "required",
                message: "Property owner email is required",
            });
            return;
        }

        if (!phoneNumber) {
            form.setError("phoneNumber", {
                type: "required",
                message: "Property owner number is required",
            });
            return;
        }

        if (!selectedUnit?._id) {
            toast.error("Please select a unit.");
            return;
        }

        const firstName = name.split(" ")[0];
        const lastName = name.split(" ").slice(1).join(" ");

        const payload: TInviteOwnerArgs = {
            email,
            firstName,
            lastName,
            userType: "TENANT",
            companyId: user?.companyId,
            phoneNumber,
            status: "INVITED",
        };

        const response = await catchAll(inviteOwner(payload).unwrap());

        if (response.error) {
            toast.error("Failed to create Tenant");
        }

        return response.data.newUser._id;
    };

    const onClickSaveContract = async (userId: string) => {
        if (!user?.companyId) {
            toast.error("Token expired. Please login again");
            return;
        }
        const { startPeriod, endPeriod, rentAmount, files, property, securityDeposit } =
            form.getValues();

        if (!startPeriod) {
            form.setError("startPeriod", {
                type: "required",
                message: "Lease start period is required",
            });
            return;
        }

        if (!endPeriod) {
            form.setError("endPeriod", {
                type: "required",
                message: "Lease end period is required",
            });
            return;
        }

        if (!rentAmount) {
            form.setError("rentAmount", {
                type: "required",
                message: "Rent amount is required",
            });
            return;
        }
        if (!property) {
            form.setError("property", {
                type: "required",
                message: "Property is required",
            });
            return;
        }
        if (!securityDeposit) {
            form.setError("securityDeposit", {
                type: "required",
                message: "Security amount is required",
            });
            return;
        }

        const dateObjectStart = new Date(startPeriod);
        const isoDateStart = dateObjectStart.toISOString();
        const dateObjectEnd = new Date(endPeriod);
        const isoDateEnd = dateObjectEnd.toISOString();
        const now = new Date();
        const isoNow = now.toISOString();
        const payload = {
            propertyId: selectedUnit._id,
            userId,
            companyId: user?.companyId,
            bookingDate: isoNow,
            startDate: isoDateStart,
            endDate: isoDateEnd,
            tenure: "long-term",
            rent: Number(rentAmount),
            securityDeposit: Number(securityDeposit),
            otherCharges: 0,
            attachmentsUrls: files as any,
            status: "active",
            paymentMethod: "bank_transfer",
        };

        const response = await catchAll(createContract(payload).unwrap());
        let refId = "";
        if (response && response.data) {
            refId = response.data._id;
        } else {
            console.error("Response is null or undefined, or data is not present.");
        }

        if (files.length > 0) {
            const promises = files.map((file, index) => {
                return createFileMetadata({
                    createdBy: user?._id,
                    fileName: fileNames[index],
                    filePath: file,
                    group: "properties_files",
                    refId,
                    status: "NEW",
                    tag: "properties_files",
                    type: "PROPERTY",
                }).unwrap();
            });

            await catchAll(Promise.all(promises));
        }
        if (response.error) {
            toast.error("Failed to create contract");
        }
    };

    const handleFileChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!e.target?.files) return;

            const { files } = e.target;

            const fileArray = Array.from(files);
            const formItems: string[] = form.watch("files");
            const remainingSlots = 10 - formItems.length;

            if (fileArray.length > remainingSlots) {
                toast.error("Max upload limit reached!");
                e.target.value = "";
                return;
            }

            const uploadedUrls: string[] = [];
            fileArray.map(async (file) => {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("folder", "properties");
                const response = await catchAll(uploadFileToAWS(formData).unwrap());

                if (response.error) {
                    toast.error("Uh oh! Something went wrong.");
                    e.target.value = "";
                    return;
                }

                const url = response?.data?.url;

                if (url) {
                    uploadedUrls.push(url);
                    return url;
                } else {
                    console.error("file URL not found in response:", response);
                    return null;
                }
            });

            // const response = await catchAll(Promise.all(filePromises));
            const items: string[] = [...formItems, ...uploadedUrls];
            e.target.value = "";

            form.setValue("files", items as any);
            setFileNames((prev) => [...prev, ...fileArray.map((file) => file.name)]);
        },
        [form, uploadFileToAWS],
    );

    return (
        <main className="w-full bg-white h-full overflow-y-auto max-h-screen">
            <Header
                image={user?.imageUrl}
                heading={"Add Tenant"}
                firstName={user?.firstName}
                lastName={user?.lastName}
                hasSearchBar={true}
            />
            {/* <NewHeader
                heading={"Add Tenant"}
                username={username}
                image={user?.imageUrl}
                hasSearchBar={true}
            /> */}
            <div className="p-4 pl-6 w-full">
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <NavLink to="/property-management/dashboard">Home</NavLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator>
                            <Slash />
                        </BreadcrumbSeparator>
                        <BreadcrumbItem>
                            <NavLink to="/property-management/tenants">Tenant</NavLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator>
                            <Slash />
                        </BreadcrumbSeparator>
                        <BreadcrumbItem>
                            <BreadcrumbPage>Add Tenant</BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
                <div className="w-max-[628px] flex-col mt-10">
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                            <div>
                                <div className="flex flex-col gap-1">
                                    <h1 className="font-medium text-lg leading-[22px] text-[#09090B]">
                                        Basic Details
                                    </h1>
                                    <p className="font-normal text-sm leading-[17px] text-zinc-500">
                                        Add tenant's information
                                    </p>
                                </div>
                                <div className="mt-6">
                                    <FormField
                                        control={form.control}
                                        name="name"
                                        render={({ field }) => (
                                            <FormItem className="my-3">
                                                <FormLabel className="text-xs font-bold text-black">
                                                    Tenant's Name
                                                </FormLabel>
                                                <FormControl>
                                                    <Input
                                                        id="name"
                                                        placeholder="Enter tenant's name"
                                                        className="focus:ring-1 focus-visible:ring-gray-200 w-[50%]"
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage className="text-xs" />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="DOB"
                                        render={({ field }) => (
                                            <FormItem className="mt-6">
                                                <FormLabel className="text-xs font-bold text-black">
                                                    Date of Birth
                                                </FormLabel>
                                                <Popover>
                                                    <PopoverTrigger asChild>
                                                        <FormControl>
                                                            <Button
                                                                variant={"outline"}
                                                                className={cn(
                                                                    "w-[320px] pl-3 text-left font-normal mt-0",
                                                                    !field.value &&
                                                                        "text-muted-foreground",
                                                                )}
                                                            >
                                                                {field.value ? (
                                                                    format(field.value, "PPP")
                                                                ) : (
                                                                    <span>Pick a date</span>
                                                                )}
                                                                <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                                            </Button>
                                                        </FormControl>
                                                    </PopoverTrigger>
                                                    <PopoverContent
                                                        className="w-auto p-0"
                                                        align="start"
                                                    >
                                                        <Calendar
                                                            className={
                                                                "[&_td]:h-10 [&_tr]:h-8 [&_tr]:w-full [&_tr]:pt-0"
                                                            }
                                                            mode="single"
                                                            selected={new Date(field.value)}
                                                            onSelect={(date) => {
                                                                if (!date) return;
                                                                form.setValue(
                                                                    "DOB",
                                                                    date?.toString(),
                                                                );
                                                            }}
                                                            disabled={() =>
                                                                new Date(field.value) >
                                                                    new Date() ||
                                                                new Date(field.value) <
                                                                    new Date("1900-01-01")
                                                            }
                                                            initialFocus
                                                        />
                                                    </PopoverContent>
                                                </Popover>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-col gap-1">
                                    <h1 className="font-medium text-lg leading-[22px] text-[#09090B]">
                                        Contact Details
                                    </h1>
                                    <p className="font-normal text-sm leading-[17px] text-zinc-500">
                                        Add tenant's contact details
                                    </p>
                                </div>
                                <div className="mt-6 flex gap-4">
                                    <FormField
                                        control={form.control}
                                        name="phoneNumber"
                                        render={({ field }) => (
                                            <FormItem className="my-3">
                                                <FormLabel className="text-xs text-black font-bold">
                                                    Phone Number
                                                </FormLabel>
                                                <FormControl>
                                                    <div
                                                        className={styles.phoneInput}
                                                        ref={phoneInputContainerRef}
                                                    >
                                                        <PhoneInput
                                                            country={"gb"}
                                                            placeholder="Enter number"
                                                            countryCodeEditable={false}
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            inputClass="!pl-[70px] !w-[306px] !h-[36px] !rounded-md  !border-solid !border-input !bg-background !px-3 !py-3 !text-sm !ring-offset-background !file:border-0 !file:bg-transparent !file:text-sm !file:font-medium !placeholder:text-muted-foreground !focus-visible:outline-none !focus-visible:ring-2 !focus-visible:ring-ring !focus-visible:ring-offset-2 !disabled:cursor-not-allowed !disabled:opacity-50 h-9"
                                                            onKeyDown={handleKeyDown}
                                                        />
                                                    </div>
                                                </FormControl>
                                                <FormMessage className="text-xs" />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="email"
                                        render={({ field }) => (
                                            <FormItem className="my-3">
                                                <FormLabel className="text-xs font-bold text-black">
                                                    Email Address
                                                </FormLabel>
                                                <FormControl>
                                                    <Input
                                                        id="email"
                                                        placeholder="debra.holt@example.com"
                                                        className="focus:ring-1 focus-visible:ring-gray-200 rounded-md w-[263px]"
                                                        {...field}
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                </FormControl>
                                                <FormMessage className="text-xs" />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="mt-6 ">
                                <div className="flex flex-col gap-1">
                                    <h1 className="font-medium text-lg leading-[22px] text-[#09090B]">
                                        Emergency Contact
                                    </h1>
                                    <p className="font-normal text-sm leading-[17px] text-zinc-500">
                                        Add tenant's emergency contact details
                                    </p>
                                </div>
                                <div className="mt-6 flex gap-4">
                                    <FormField
                                        control={form.control}
                                        name="emergencyPhoneNumber"
                                        render={({ field }) => (
                                            <FormItem className="my-3">
                                                <FormLabel className="text-xs text-black font-bold">
                                                    Phone Number
                                                </FormLabel>
                                                <FormControl>
                                                    <div ref={phoneInputContainerRef}>
                                                        <PhoneInput
                                                            country={"gb"}
                                                            placeholder="Enter number"
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            inputClass="!pl-[70px] !w-[306px] !h-5 !rounded-md !border !border-solid !border-input !bg-background !px-3 !py-3 !text-sm !ring-offset-background !file:border-0 !file:bg-transparent !file:text-sm !file:font-medium !placeholder:text-muted-foreground !focus-visible:outline-none !focus-visible:ring-2 !focus-visible:ring-ring !focus-visible:ring-offset-2 !disabled:cursor-not-allowed !disabled:opacity-50"
                                                            onKeyDown={handleKeyDown}
                                                        />
                                                    </div>
                                                </FormControl>
                                                <FormMessage className="text-xs" />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="emergencyEmail"
                                        render={({ field }) => (
                                            <FormItem className="my-3">
                                                <FormLabel className="text-xs font-bold text-black">
                                                    Email Address
                                                </FormLabel>
                                                <FormControl>
                                                    <Input
                                                        id="email"
                                                        placeholder="debra.holt@example.com"
                                                        className="focus:ring-1 focus-visible:ring-gray-200 rounded-md w-[263px]"
                                                        {...field}
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                </FormControl>
                                                <FormMessage className="text-xs" />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-col gap-1">
                                    <h1 className="font-medium text-lg leading-[22px] text-[#09090B]">
                                        Assign to Property
                                    </h1>
                                    <p className="font-normal text-sm leading-[17px] text-zinc-500">
                                        Choose a property and unit of the tenant.
                                    </p>
                                    <p className="font-normal text-sm leading-[17px] text-zinc-500">
                                        If they are already assigned to a unit please select it from
                                        the list below
                                    </p>
                                </div>
                                <div className="mt-6">
                                    <div className="max-w-[588px] border p-4  rounded-xl border-solid border-[#f2f2f2]">
                                        <p className="font-medium text-sm leading-5 text-[#2B2B2B] mb-6">
                                            Property & Unit
                                        </p>
                                        <div className="flex gap-4">
                                            <div className="w-[278px]">
                                                <p className="font-medium text-sm leading-[17px] text-[#09090B] mb-2">
                                                    Property
                                                </p>
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger asChild>
                                                        <Button
                                                            variant="outline"
                                                            className="font-normal text-sm leading-[17px] text-zinc-500 w-full flex justify-between items-center"
                                                        >
                                                            {selectedProperty?.name ||
                                                                "Select Property"}
                                                            <ChevronDown height={16} width={16} />
                                                        </Button>
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent className="w-56">
                                                        <DropdownMenuLabel>
                                                            Choose a Property
                                                        </DropdownMenuLabel>
                                                        <DropdownMenuSeparator />
                                                        {propertiesData &&
                                                            propertiesData.data.map((property) => (
                                                                <DropdownMenuItem
                                                                    key={property._id}
                                                                    onSelect={() =>
                                                                        handlePropertyChange(
                                                                            property,
                                                                        )
                                                                    }
                                                                    className="cursor-pointer"
                                                                >
                                                                    {property.name}
                                                                </DropdownMenuItem>
                                                            ))}
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            </div>

                                            {selectedProperty && (
                                                <div className="w-[278px]">
                                                    <p className="font-medium text-sm leading-[17px] text-[#09090B] mb-2">
                                                        Unit
                                                    </p>
                                                    <DropdownMenu>
                                                        <DropdownMenuTrigger asChild>
                                                            <Button
                                                                variant="outline"
                                                                className="font-normal text-sm leading-[17px] text-zinc-500 w-full flex justify-between items-center"
                                                            >
                                                                {selectedUnit?.name ||
                                                                    "Select Unit"}
                                                                <ChevronDown
                                                                    height={16}
                                                                    width={16}
                                                                />
                                                            </Button>
                                                        </DropdownMenuTrigger>
                                                        <DropdownMenuContent className="w-56">
                                                            <DropdownMenuLabel>
                                                                Choose a Unit
                                                            </DropdownMenuLabel>
                                                            <DropdownMenuSeparator />
                                                            {unitData &&
                                                                unitData &&
                                                                unitData[0].properties &&
                                                                unitData[0].properties.map(
                                                                    (unit) => (
                                                                        <DropdownMenuItem
                                                                            key={unit._id}
                                                                            onSelect={() =>
                                                                                handleUnitChange(
                                                                                    unit,
                                                                                )
                                                                            }
                                                                            className="cursor-pointer"
                                                                        >
                                                                            {unit.name}
                                                                        </DropdownMenuItem>
                                                                    ),
                                                                )}
                                                        </DropdownMenuContent>
                                                    </DropdownMenu>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {selectedProperty && (
                                    <div className="mt-6">
                                        <div className="max-w-[588px] border p-4  rounded-xl border-solid border-[#f2f2f2]">
                                            <p className="font-medium text-sm leading-5 text-[#2B2B2B] mb-6">
                                                Rental Detail
                                            </p>
                                            <FormField
                                                control={form.control}
                                                name="rentAmount"
                                                render={({ field }) => (
                                                    <FormItem className="w-[350px] mt-2">
                                                        <FormLabel className="text-sm text-black">
                                                            Rent Amount
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                placeholder="Enter Rent"
                                                                type="number"
                                                                style={{ appearance: "textfield" }} // For consistency across all browsers
                                                                {...field}
                                                                className="focus:ring-1 focus-visible:ring-gray-200 h-9 w-full"
                                                            />
                                                        </FormControl>
                                                        <FormMessage className="text-xs" />
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="securityDeposit"
                                                render={({ field }) => (
                                                    <FormItem className="w-[350px] mt-6">
                                                        <FormLabel className="text-sm text-black">
                                                            Security Deposit
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                type="number"
                                                                placeholder="Enter Security Deposit"
                                                                {...field}
                                                                className="focus:ring-1 focus-visible:ring-gray-200 h-9 w-full"
                                                            />
                                                        </FormControl>
                                                        <FormMessage className="text-xs" />
                                                    </FormItem>
                                                )}
                                            />
                                            <div className="flex gap-1 ">
                                                <FormField
                                                    control={form.control}
                                                    name="startPeriod"
                                                    render={({ field }) => (
                                                        <FormItem className="mt-6">
                                                            <FormLabel className="text-sm text-black">
                                                                Lease Period
                                                            </FormLabel>
                                                            <Popover>
                                                                <PopoverTrigger asChild>
                                                                    <FormControl>
                                                                        <Button
                                                                            variant={"outline"}
                                                                            className={cn(
                                                                                "w-[175px] pl-3 text-left font-normal",
                                                                                !field.value &&
                                                                                    "text-muted-foreground",
                                                                            )}
                                                                        >
                                                                            {field.value ? (
                                                                                format(
                                                                                    field.value,
                                                                                    "PPP",
                                                                                )
                                                                            ) : (
                                                                                <span>
                                                                                    Pick a date
                                                                                </span>
                                                                            )}
                                                                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                                                        </Button>
                                                                    </FormControl>
                                                                </PopoverTrigger>
                                                                <PopoverContent
                                                                    className="w-auto p-0"
                                                                    align="start"
                                                                >
                                                                    <Calendar
                                                                        mode="single"
                                                                        className={
                                                                            "[&_td]:h-10 [&_tr]:h-8 [&_tr]:w-56 [&_tr]:pt-0"
                                                                        }
                                                                        selected={
                                                                            new Date(field.value)
                                                                        }
                                                                        onSelect={(date) => {
                                                                            if (!date) return;
                                                                            form.setValue(
                                                                                "startPeriod",
                                                                                date?.toString(),
                                                                            );
                                                                        }}
                                                                        disabled={() =>
                                                                            new Date(field.value) >
                                                                                new Date() ||
                                                                            new Date(field.value) <
                                                                                new Date(
                                                                                    "1900-01-01",
                                                                                )
                                                                        }
                                                                        initialFocus
                                                                    />
                                                                </PopoverContent>
                                                            </Popover>
                                                        </FormItem>
                                                    )}
                                                />
                                                <FormField
                                                    control={form.control}
                                                    name="endPeriod"
                                                    render={({ field }) => (
                                                        <FormItem className="mt-6 self-end">
                                                            <Popover>
                                                                <PopoverTrigger asChild>
                                                                    <FormControl>
                                                                        <Button
                                                                            variant={"outline"}
                                                                            className={cn(
                                                                                "w-[175px] pl-3 text-left font-normal",
                                                                                !field.value &&
                                                                                    "text-muted-foreground",
                                                                            )}
                                                                        >
                                                                            {field.value ? (
                                                                                format(
                                                                                    field.value,
                                                                                    "PPP",
                                                                                )
                                                                            ) : (
                                                                                <span>
                                                                                    Pick a date
                                                                                </span>
                                                                            )}
                                                                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                                                        </Button>
                                                                    </FormControl>
                                                                </PopoverTrigger>
                                                                <PopoverContent
                                                                    className="w-auto p-0"
                                                                    align="start"
                                                                >
                                                                    <Calendar
                                                                        mode="single"
                                                                        className={
                                                                            "[&_td]:h-10 [&_tr]:h-8 [&_tr]:w-56 [&_tr]:pt-0"
                                                                        }
                                                                        selected={
                                                                            new Date(field.value)
                                                                        }
                                                                        onSelect={(date) => {
                                                                            if (!date) return;
                                                                            form.setValue(
                                                                                "endPeriod",
                                                                                date?.toString(),
                                                                            );
                                                                        }}
                                                                        disabled={() =>
                                                                            new Date(field.value) >
                                                                                new Date() ||
                                                                            new Date(field.value) <
                                                                                new Date(
                                                                                    "1900-01-01",
                                                                                )
                                                                        }
                                                                        initialFocus
                                                                    />
                                                                </PopoverContent>
                                                            </Popover>
                                                        </FormItem>
                                                    )}
                                                />
                                            </div>
                                            <div className="font-medium text-sm leading-[17px] text-[#09090B] mt-6 mb-[6px]">
                                                Tenancy Document
                                            </div>
                                            <label className="w-[320px] h-[132px] rounded-lg flex flex-col items-center justify-center cursor-pointer mb-2">
                                                <div className="w-[320px] h-full rounded-lg bg-[#F5F5F5] flex flex-col items-center justify-center cursor-pointer ">
                                                    <span className="rounded-full p-[14px] flex items-center justify-center mb-2 bg-[#E3E3E3]">
                                                        <Upload />
                                                    </span>
                                                    <p className="text-xs leading-4 text-[#111111]">
                                                        Upload Files
                                                    </p>
                                                    <input
                                                        type="file"
                                                        multiple
                                                        onChange={(e) => handleFileChange(e)}
                                                        className="focus-visible:ring-gray-200 hidden"
                                                        disabled={isFileUploading}
                                                    />
                                                </div>
                                            </label>

                                            <div className="flex flex-col space-y-2 mb-10 w-[320px]">
                                                {form.watch("files").map((file, index) => {
                                                    return (
                                                        <div
                                                            key={file}
                                                            className="flex items-center justify-between border border-[#00000012] p-4 rounded-md"
                                                        >
                                                            <div className="flex items-center space-x-2">
                                                                <FileText />
                                                                <span className="font-medium text-sm leading-[17px] text-black">
                                                                    {fileNames[index]}
                                                                </span>
                                                            </div>
                                                            <X
                                                                size={24}
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    const files = form
                                                                        .getValues("files")
                                                                        .filter(
                                                                            (_, i) => index !== i,
                                                                        );
                                                                    form.setValue("files", files);

                                                                    setFileNames((prev) =>
                                                                        prev.filter(
                                                                            (_, i) => i !== index,
                                                                        ),
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="flex items-end">
                                <Button
                                    type="submit"
                                    className="mt-4 bg-[#000] w-[130px]"
                                    disabled={createFileMetadataLoading || createContractLoading}
                                >
                                    {(createFileMetadataLoading || createContractLoading) && (
                                        <Spinner className="text-white size-5 mr-1" />
                                    )}
                                    Add Tenant
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </main>
    );
};
