import { memo, useCallback } from "react";
import { Heading } from "../../components/common/pageHeading";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";

type TProps = { heading: string; stat: string | number; id: string | undefined };

export const HeadingWithStats = memo((props: TProps) => {
    const { heading, stat, id } = props;
    const navigate = useNavigate();

    const onClickAddProperty = useCallback(() => {
        id && navigate(`/property-management/properties/add-unit/${id}`);
    }, [id, navigate]);
    return (
        <div className="flex justify-between items-center gap-4 mb-7">
            <div className="flex items-center gap-4">
                <Heading as="h2">{heading}</Heading>
                <p className="font-medium text-xl leading-7 text-[#666d80] flex-grow-0">{stat}</p>
            </div>
            <Button onClick={onClickAddProperty}>+ Add Unit</Button>
        </div>
    );
});
