import { memo, useMemo } from "react";
import styles from "./button.module.css";
import { LeadIcon } from "../../../assets/icons";
import { Button as ShadcnButton } from "@/components/ui/button";

type TVariant =
    | "primary"
    | "secondary"
    | "secondary-with-shadow"
    | "tertiary"
    | "red"
    | "red-text-outline"
    | "primary-with-shadow";
type TSize = "fat" | "thin" | "zero";
type TBorderRadius = "sm" | "md" | "lg";
type TIconPosition = "left" | "right";

type TProps = {
    variant?: TVariant;
    padding?: TSize;
    className?: string;
    type?: string;
    borderRadius?: TBorderRadius;
    icon?: React.ReactNode;
    iconPosition?: TIconPosition;
    children: React.ReactNode;
    full?: boolean;
    isLoading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const getDefaultStyles = (variant: TVariant): string => {
    switch (variant) {
        case "primary":
            return styles.primaryButton;
        case "secondary":
            return styles.secondaryButton;
        case "tertiary":
            return styles.tertiaryButton;
        case "secondary-with-shadow":
            return styles.secondaryWithShadowButton;
        case "red":
            return styles.redButton;
        case "red-text-outline":
            return styles.redTextOutline;
        default:
            return styles.primaryButton;
    }
};

const getSizeStyles = (padding: TSize) => {
    switch (padding) {
        case "fat":
            return styles.fatPadding;
        case "thin":
            return styles.thinPadding;
        case "zero":
            return styles.zeroPadding;
        default:
            return styles.fatPadding;
    }
};

const getBorderRadiusStyles = (borderRadius: TBorderRadius) => {
    switch (borderRadius) {
        case "sm":
            return styles.borderRadiusSm;
        case "md":
            return styles.borderRadiusMd;
        case "lg":
            return styles.borderRadiusLg;
        default:
            return styles.borderRadiusMd;
    }
};

const getDisabledStyles = (variant: TVariant) => {
    switch (variant) {
        case "primary":
            return styles.primaryButtonDisabled;
        case "secondary":
            return styles.primaryButtonDisabled;
        case "secondary-with-shadow":
            return styles.primaryButtonDisabled;
        case "tertiary":
        case "red":
        default:
            return "";
    }
};

export const Button = memo((props: TProps) => {
    const {
        variant = "primary",
        padding = "fat",
        borderRadius = "md",
        children,
        className,
        icon,
        iconPosition = "right",
        type = "button",
        full = false,
        onClick,
        disabled,
        isLoading = false,
        ...rest
    } = props;

    const buttonStyles = useMemo(() => getDefaultStyles(variant), [variant]);
    const buttonSizeStyles = useMemo(() => getSizeStyles(padding), [padding]);
    const buttonBorderRadiusStyles = useMemo(
        () => getBorderRadiusStyles(borderRadius),
        [borderRadius],
    );
    const disabledStyles = useMemo(() => getDisabledStyles(variant), [variant]);

    return (
        <ShadcnButton
            className={`${
                styles.commonStyles
            } ${buttonStyles} ${className} ${buttonSizeStyles} ${buttonBorderRadiusStyles} ${
                disabled && disabledStyles
            }`}
            type={type}
            style={{ width: full ? "100%" : "" }}
            onClick={(e) => onClick && !disabled && onClick(e)}
            disabled={isLoading || disabled}
            {...rest}
        >
            {icon && iconPosition === "left" && <span className={styles.iconLeft}>{icon}</span>}
            {isLoading ? <LeadIcon /> : children}
            {icon && iconPosition === "right" && <span className={styles.iconRight}>{icon}</span>}
        </ShadcnButton>
    );
});
