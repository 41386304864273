import { useCallback, useState } from "react";
import styles from "./InvoiceDetail.module.css";
import { InputWithLabel } from "../../postAJob/InputWithLabel";
import { BackButton } from "../../../components/common/backButton";
import { Heading } from "../../../components/common/pageHeading";
import { Logo } from "../../../assets/icons";
import { TextareaWithLabel } from "../../postAJob/TextareaWithLabel";
import Button from "../../../components/common/button";

export default function InvoiceDetails() {
    const [formData, setFormData] = useState({
        price: "",
        callOutFee: "",
        totalFee: "",
        message: "",
    });
    const handleChange = useCallback(
        (value: any, name: string) => {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        },
        [setFormData],
    );
    return (
        <>
            <div className={styles.navigation}>
                <Logo fill="#22BCFF" />
            </div>
            <div className={styles.page}>
                <div className={styles.header}>
                    <BackButton />
                    <Heading as="h1">Invoice Details</Heading>
                    <span></span>
                </div>
            </div>
            <form className={styles.form}>
                <InputWithLabel
                    labelProps={{
                        text: "Job Price",
                    }}
                    inputProps={{
                        error: {},
                        name: "price",
                        placeholder: "Enter price",
                        required: true,
                        type: "number",
                        value: formData.price,
                        handleChange: (e) => handleChange(e.target.value, "price"),
                    }}
                />
                <InputWithLabel
                    labelProps={{
                        text: "Call out Fee",
                    }}
                    inputProps={{
                        error: {},
                        name: "callOutFee",
                        placeholder: "Enter call out fee",
                        required: true,
                        type: "number",
                        value: formData.callOutFee,
                        handleChange: (e) => handleChange(e.target.value, "callOutFee"),
                    }}
                />
                <InputWithLabel
                    labelProps={{
                        text: "Total fee",
                    }}
                    inputProps={{
                        error: {},
                        name: "totalFee",
                        placeholder: "Enter total fee",
                        required: true,
                        type: "text",
                        value: formData.totalFee,
                        handleChange: (e) => handleChange(e.target.value, "totalFee"),
                    }}
                />
                <TextareaWithLabel
                    labelProps={{
                        text: "Message",
                    }}
                    textAreaProps={{
                        error: {},
                        name: "message",
                        placeholder: "Enter message",
                        required: false,
                        value: formData.message,
                        handleChange: (e) => handleChange(e.target.value, "message"),
                    }}
                />
                <div className={styles.submitBtn}>
                    <Button text="Submit" />
                </div>
            </form>
        </>
    );
}
