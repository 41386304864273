export default function LossChart() {
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.6667 14.1663L12.1095 7.60915C11.7795 7.27914 11.6145 7.11413 11.4242 7.05231C11.2568 6.99792 11.0765 6.99792 10.9092 7.05231C10.7189 7.11413 10.5539 7.27914 10.2239 7.60915L7.94281 9.8902C7.61279 10.2202 7.44779 10.3852 7.25751 10.447C7.09014 10.5014 6.90985 10.5014 6.74248 10.447C6.55221 10.3852 6.3872 10.2202 6.05719 9.8902L2 5.83301M18.6667 14.1663H12.8333M18.6667 14.1663V8.33301"
                stroke="#F04438"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
