import styles from "./myJobs.module.css";
import { StatusCancelledIcon } from "../../assets/icons/StatusCancelledIcon";
import { memo } from "react";
import { StatusProgressIcon } from "../../assets/icons";
import { StatusOpenIcon } from "../../assets/icons/StatusOpenIcon";
import { TJobStatus } from "../../types";

type TProps = {
    iconType: Exclude<TJobStatus, "ALL">;
};

type TIconWithLabel = Record<Exclude<TJobStatus, "ALL">, { icon: JSX.Element; label: string }>;

const IconWithLabel: TIconWithLabel = {
    CANCELLED: {
        icon: <StatusCancelledIcon />,
        label: "Cancelled",
    },
    ONGOING: {
        icon: <StatusProgressIcon />,
        label: "In progress",
    },
    NOT_STARTED: {
        icon: <StatusOpenIcon />,
        label: "Open",
    },
    ACTIVE: {
        icon: <></>,
        label: "",
    },
    PENDING: {
        icon: <></>,
        label: "",
    },
    COMPLETED: {
        icon: <></>,
        label: "",
    },
    OFFERS: {
        icon: <></>,
        label: "",
    },
    QUOTES: {
        icon: <></>,
        label: "",
    },
    IN_REVIEW: {
        icon: <></>,
        label: "",
    },
};

export const JobCardStatus = memo((props: TProps) => {
    const { iconType } = props;
    return (
        <div className={styles.statusIconContainer}>
            {IconWithLabel[iconType]?.icon}
            {IconWithLabel[iconType]?.label}
        </div>
    );
});
