import { toast } from "react-toastify";
import { TUser } from "../../types";
import { catchAll } from "../../utils/catch";
import { api } from "./api";

const resetPassword = api.injectEndpoints({
    endpoints: (builder) => ({
        resetPassword: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `auth/reset-password`,
                    body: data,
                    method: "PUT",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Failed to update password");
                    return;
                }
                toast.success("Password updated successfully");
            },
        }),

        getCompanyDetails: builder.query<TUser[], TUser>({
            query: (myDetails) => `auth/users/companies/${myDetails.companyId}`,
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    toast.error(response?.error?.message);
                }
            },
            transformResponse: (baseResponse: { data: TUser[] }, __, arg) => {
                return baseResponse.data.filter((user) => user._id !== arg._id);
            },
        }),
    }),
});

export const { useResetPasswordMutation, useGetCompanyDetailsQuery } = resetPassword;
