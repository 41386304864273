import { useGetWorkOrderQuery } from "@/redux/rtk-query/jobsApis";
import dayjs from "dayjs";
import { useMemo } from "react";

// type TChipColors = "red" | "yellow" | "green" | "blue" | "white";

// const priorityColorMap: Record<string, TChipColors> = {
//     high: "red",
//     medium: "yellow",
//     low: "green",
//     standard: "blue",
// };
const MainTainanceRow = () => {
    // const { data: user } = useGetMyDetailsQuery();
    const { data: allJobs } = useGetWorkOrderQuery({});
    // const { data: allJobs } = useGetJobsQuery({ type: "ALL", myDetails: user }, { skip: !user });
    // console.log(allJobs, "------ in maintence--------");
    const maintenanceJobs = useMemo(() => {
        if (!allJobs) return [];
        return allJobs.data.map((job: any, index: any) => {
            // const priorityColor = priorityColorMap[job.jobPriority.toLowerCase()] || "white"; // Default color
            return {
                id: index + 1,
                Status: job.status.split("_").join(" "),
                dueDate: dayjs(job?.dueDate).format("DD/MM/YYYY"),
                assignedTo: job.assignedToInfo?.username || "Not Assigned",
                propertyId: job.propertyDetails?.name || "",
                priority: job.jobPriority,
                _id: job._id,
            };
        });
    }, [allJobs]);

    return maintenanceJobs;
};

export default MainTainanceRow;
