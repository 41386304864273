import { ComponentProps, memo } from "react";
import TextArea from "../../components/common/input/textArea";
import Label from "../../components/common/label";
import styles from "./homeOwnerPostAJob.module.css";

export const TextareaWithLabel = memo(
    (props: {
        labelProps: Omit<Parameters<typeof Label>[0], "type">;
        textAreaProps: Parameters<typeof TextArea>[0];
        containerProps?: Omit<ComponentProps<"div">, "className">;
    }) => (
        <div className={styles.aiPromptTextarea} {...props.containerProps}>
            <Label {...props.labelProps} type={"plain-black"} />
            <TextArea {...props.textAreaProps} height={114} />
        </div>
    ),
);
