import { cn } from "@/lib/utils";
import { Pie, PieChart } from "recharts";
import styles from "./propertyManagementProperties.module.css";

import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart";
import { TBuildingCategoryCount } from "@/types";
import { convertScreamToTitle } from "@/utils/convertScreamToTitle";
import { useMemo } from "react";

export const UnitCategoriesCard = (props: { stats: TBuildingCategoryCount[] }) => {
    const [residential, commercial] = useMemo(() => {
        if (!props.stats) {
            return [
                {
                    categories: [],
                    total: 0,
                },
                {
                    categories: [],
                    total: 0,
                },
            ];
        }

        let totalResidential = 0,
            totalCommercial = 0,
            residentialCategories: TBuildingCategoryCount[] = [],
            commercialCategories: TBuildingCategoryCount[] = [];

        props.stats.forEach((stat) => {
            if (RESIDENTIAL_CATEGORIES.includes(stat.category)) {
                totalResidential += stat.count;
                residentialCategories.push(stat);
            } else {
                totalCommercial += stat.count;
                commercialCategories.push(stat);
            }
        });

        return [
            {
                categories: residentialCategories,
                total: totalResidential,
            },
            {
                categories: commercialCategories,
                total: totalCommercial,
            },
        ];
    }, [props.stats]);

    return (
        <div className={cn(styles.cardContainer)}>
            <h3 className={cn(styles.cardHeading, "m-0 p-0 mb-2")}>Unit Categories</h3>
            <div className="flex justify-between xl:flex-col 2xl:flex-row items-center">
                <div className="max-w-[175px] xl:mb-6 2xl:mb-0">
                    <CommercialToResidentialChart
                        commercialTotal={commercial.total}
                        residentialTotal={residential.total}
                    />
                </div>
                <div className="flex-1">
                    <PropertyCategories
                        commercial={commercial.categories}
                        commercialTotal={commercial.total}
                        residential={residential.categories}
                        residentialTotal={residential.total}
                    />
                </div>
            </div>
        </div>
    );
};

const RESIDENTIAL_CATEGORIES = [
    "SINGLE_FAMILY_HOME",
    "MULTI_FAMILY_HOME",
    "FLAT_APARTMENT",
    "TOWNHOUSE",
    "OTHER",
];

// const COMMERCIAL_CATEGORIES = [
//     "OFFICE_BUILDING",
//     "RETAIL_PROPERTY",
//     "INDUSTRIAL_PROPERTY",
//     "HOTEL_BAR",
// ];

const PropertyCategories = (props: {
    commercial: TBuildingCategoryCount[];
    residential: TBuildingCategoryCount[];
    commercialTotal: number;
    residentialTotal: number;
}) => {
    return (
        <div className="flex justify-center">
            <div className="pr-[52px] border-r border-zinc-200">
                <div className="flex items-center mb-2">
                    <div className="h-2.5 w-2.5 bg-[#E5E7EB] rounded-full mr-2"></div>
                    <h2 className={cn(styles.cardHeading, "!m-0 !p-0")}>Commercial</h2>
                </div>
                <p className={cn(styles.cardStat, "mb-4")}>{props.commercialTotal}</p>
                <ul>
                    {props.commercial.map(({ category, count }) => (
                        <li key={category} className="flex justify-between space-x-8 mb-2">
                            <span>{convertScreamToTitle(category)}</span>
                            <span>{count}</span>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="pl-[52px]">
                <div className="flex items-center mb-2">
                    <div className="h-2.5 w-2.5 bg-[#18181B] rounded-full mr-2"></div>
                    <h2 className={cn(styles.cardHeading, "!m-0 !p-0")}>Residential</h2>
                </div>
                <p className={cn(styles.cardStat, "mb-4")}>{props.residentialTotal}</p>
                <ul>
                    {props.residential.map(({ category, count }) => (
                        <li key={category} className="flex justify-between space-x-8 mb-2">
                            <span>{convertScreamToTitle(category)}</span>
                            <span>{count}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

const chartData = [
    { type: "Commercial", stats: 420, fill: "#18181B" },
    { type: "Residential", stats: 20, fill: "#E5E7EB" },
];

const chartConfig = {
    visitors: {
        label: "Visitors",
    },
    chrome: {
        label: "Chrome",
        color: "hsl(var(--chart-1))",
    },
    safari: {
        label: "Safari",
        color: "hsl(var(--chart-2))",
    },
    firefox: {
        label: "Firefox",
        color: "hsl(var(--chart-3))",
    },
    edge: {
        label: "Edge",
        color: "hsl(var(--chart-4))",
    },
    other: {
        label: "Other",
        color: "hsl(var(--chart-5))",
    },
} satisfies ChartConfig;

export function CommercialToResidentialChart(props: {
    commercialTotal: number;
    residentialTotal: number;
}) {
    chartData[0].stats = props.commercialTotal;
    chartData[1].stats = props.residentialTotal;
    return (
        <div>
            <ChartContainer config={chartConfig} className="aspect-square max-h-[170px]">
                <PieChart>
                    <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                    <Pie
                        data={chartData}
                        dataKey="stats"
                        nameKey="type"
                        innerRadius={35}
                        strokeWidth={5}
                    ></Pie>
                </PieChart>
            </ChartContainer>
            <p className="font-medium text-sm leading-[17px] text-center text-[#09090B]">
                Commercial to Residential
            </p>
        </div>
    );
}
