import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { cn } from "@/lib/utils";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { useGetPropertyStatsQuery } from "../../redux/rtk-query/properties";
import styles from "./propertyManagementProperties.module.css";
import { UnitCategoriesCard } from "./UnitCategoriesCard";
import { TaskTables } from "@/components/table/TaskTable";
import { Header } from "@/components/common/headers/Header";
import useTableSortAndFilter from "@/components/common/table/useTableSortAndFilter";
import usePropertiesRow from "./tableRow/usePropertiesRow";
import { columnItems, sortItems, TableHeadItems } from "./propertyManagement.constant";
import { TableActions } from "../propertyManagementContracts/TableActions";

// interface PropertiesRequests {
//     addressId: string;
//     addressType: string;
//     area: string;
//     building: string;
//     category: string;
//     city: string;
//     companyId: string;
//     country: string;
//     createdAt: string;
//     images: string[];
//     latitude: number;
//     longitude: number;
//     mainStreet: string;
//     name: string;
//     noOfBedrooms: number;
//     postalCode: string;
//     province: string;
//     refId: string;
//     status: string;
//     type: string;
//     updatedAt: string;
//     _id: string;
// }

export const Properties = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const { data: user } = useGetMyDetailsQuery();

    const { data: propertyStats } = useGetPropertyStatsQuery(user?.companyId ?? "", {
        skip: !user?.companyId,
    });

    const { propertiesData, propertyRow, isLoading, pagination, handlePageChange } =
        usePropertiesRow({
            search: searchTerm,
        });

    const filteredPropertyRow = useMemo(() => {
        const lowercasedSearchTerm = searchTerm.toLowerCase();
        return propertyRow?.filter((row: any) =>
            Object.values(row).some((value) =>
                String(value).toLowerCase().includes(lowercasedSearchTerm),
            ),
        );
    }, [searchTerm, propertyRow]);

    const {
        sortedTableRowItems,
        handleToggleColumn,
        handleSort,
        visibleColumns,
        sortDirection,
        sortColumn,
    } = useTableSortAndFilter({ head: TableHeadItems, rows: filteredPropertyRow });

    const TableData = {
        data: [{ header: visibleColumns, row: sortedTableRowItems, heading: "Properties" }],
        mainHeading: "Properties",
    };

    const onClickAddProperty = useCallback(() => {
        navigate("add-property");
    }, [navigate]);

    return (
        <div className="max-h-screen overflow-y-auto w-full">
            {/* Heading with action */}
            <Header
                image={user?.imageUrl}
                heading={undefined}
                firstName={user?.firstName}
                lastName={user?.lastName}
                hasSearchBar={true}
            />
            <div className="p-5">
                <header className="flex justify-between items-center mb-6">
                    <h1 className="font-bold text-3xl leading-9 text-[#09090B]">Properties</h1>
                    <Button onClick={onClickAddProperty}>Add Property</Button>
                </header>

                {/* StatsContainer */}
                <section className="flex flex-col xl:flex-row gap-4 mb-8">
                    <div className="xl:max-w-[26.5%] flex flex-col space-y-4 w-full flex-1 justify-between ">
                        {/* TotalPropertiesCard */}
                        <div className={cn(styles.cardContainer, "flex-1")}>
                            <h3 className={cn(styles.cardHeading, "m-0 p-0")}>Total Properties</h3>
                            <p className={cn(styles.cardStat, "mb-2")}>
                                {propertyStats?.totalBuildings ?? 0}
                            </p>
                        </div>

                        {/* TotalUnitsCard */}
                        <div className={cn(styles.cardContainer, "flex-1")}>
                            <h3 className={cn(styles.cardHeading, "m-0 p-0")}>Total Units</h3>
                            <p className={cn(styles.cardStat, "mb-4")}>
                                {propertyStats?.totalProperties ?? 0}
                            </p>
                            <Progress
                                className="mb-2"
                                value={(() => {
                                    const occupied = propertyStats?.totalPropertiesOccupied ?? 0;
                                    const vacant = propertyStats?.totalPropertiesAvailable ?? 0;
                                    const total = occupied + vacant;
                                    const percentageOccupied = (occupied / total) * 100;

                                    return percentageOccupied;
                                })()}
                            />
                            <div className="flex items-center gap-8">
                                <span className="font-normal text-xs text-zinc-500">
                                    Occupied{" "}
                                    <span className="text-sm leading-5 text-[#101010] ml-2">
                                        {propertyStats?.totalPropertiesOccupied ?? 0}
                                    </span>
                                </span>
                                <span className="font-normal text-xs text-zinc-500">
                                    Vacant{" "}
                                    <span className="text-sm leading-5 text-[#101010] ml-2">
                                        {propertyStats?.totalPropertiesAvailable ?? 0}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 min-h-max">
                        <UnitCategoriesCard stats={propertyStats?.buildingCategoryCount ?? []} />
                    </div>
                </section>

                {/* <TaskTables
                mainHeading={TableData?.mainHeading}
                data={TableData?.data || []}
                className="text-xs"
                isLoading={isLoading}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                totalpages={totalpages}
                onPageChange={handlePageChange}
                sortAndFilter={
                    <div className="flex items-end gap-3 flex-col xl:flex-row">
                        <TableActions
                            onClickFilter={() => {}}
                            onClickSort={handleSort}
                            onToggleColumn={handleToggleColumn}
                            visibleColumns={visibleColumns.map((item) => item?.accessorKey)}
                            isSearch={true}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            sortItems={sortItems}
                            columnItems={columnItems}
                        />
                    </div>
                </section> */}

                <TaskTables
                    mainHeading={TableData?.mainHeading}
                    data={TableData?.data || []}
                    className="text-xs"
                    isLoading={isLoading}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    totalpages={pagination.totalPages}
                    currentPage={pagination.currentPage}
                    onPageChange={handlePageChange}
                    sortAndFilter={
                        <div className="flex items-end gap-3 flex-col xl:flex-row">
                            <TableActions
                                onClickFilter={() => {}}
                                onClickSort={handleSort}
                                onToggleColumn={handleToggleColumn}
                                visibleColumns={visibleColumns.map((item) => item?.accessorKey)}
                                isSearch={true}
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                sortItems={sortItems}
                                columnItems={columnItems}
                            />
                        </div>
                    }
                    islengthAllow={false}
                    onClickRow={(row: any) => {
                        const propertyId = row?.original?.id;
                        const payload = propertiesData?.find((log: any) => log._id === propertyId);

                        return navigate(`/property-management/properties/${propertyId}`, {
                            state: payload,
                        });
                    }}
                />
            </div>
        </div>
    );
};
