import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { Header } from "@/components/common/headers/Header";
import { Breadcrumbs } from "../addProperty/Breadcrumbs";
import { useParams } from "react-router-dom";
import { useGetPropertyQuery } from "@/redux/rtk-query/properties";
import { UnitForm } from "../addProperty/UnitForm";
import { useGetTenantDetailQuery } from "@/redux/rtk-query/contractsApis";
import { useGetAddonsQuery } from "@/redux/rtk-query/addonsApis";

export type TAddPropertyPage = "property" | "units" | "unit-listing";

const UpdateUnit = () => {
    const { unitId } = useParams();
    const { data: myDetails, isLoading } = useGetMyDetailsQuery();
    const { data: addons } = useGetAddonsQuery(unitId, {
        skip: !unitId,
    });

    const { data: tenantsDetails } = useGetTenantDetailQuery(unitId || "", { skip: !unitId });

    const { data: unitData, isLoading: isUnitLoading } = useGetPropertyQuery(
        { id: unitId || "", companyId: myDetails?.companyId || "" },
        {
            skip: !unitId || !myDetails?.companyId,
        },
    );

    const unitDetails = {
        buildingId: unitData?.buildingId,
        unitNumber: unitData?.name,
        noOfBedrooms: unitData?.noOfBedrooms,
        address: unitData?.address?.building,
        city: unitData?.address?.city,
        country: unitData?.address?.country,
        postCode: unitData?.address?.postalCode,
        unitArea: unitData?.area || "",
        propertyType: unitData?.type,
        status: unitData?.status,
        companyId: unitData?.companyId,
        amenities: addons,
        // amenities: addons?.map(data => data._id),
        propertyOwnerName: tenantsDetails?.[0]?.userDetails
            ? `${tenantsDetails?.[0]?.userDetails?.firstName} ${tenantsDetails[0]?.userDetails?.lastName}`
            : "",
        ...(tenantsDetails?.[0]?.userId && { propertyOwner: tenantsDetails[0].userId }),
        propertyOwnerEmail: tenantsDetails?.[0]?.userDetails
            ? tenantsDetails?.[0]?.userDetails?.email
            : "",
        rent: tenantsDetails?.[0]?.rent ? tenantsDetails?.[0]?.rent : "",
        securityDeposit: tenantsDetails?.[0]?.securityDeposit
            ? tenantsDetails?.[0]?.securityDeposit
            : "",
        leasePeriodStart: tenantsDetails?.[0]?.startDate ? tenantsDetails?.[0]?.startDate : "",
        leasePeriodEnd: tenantsDetails?.[0]?.endDate ? tenantsDetails?.[0]?.endDate : "",
    };

    console.log(unitData, "unitIdunitIdunitIdunitId");

    return (
        <div className="w-full h-full zmax-h-screen bg-white overflow-y-auto">
            <Header
                image={myDetails?.imageUrl}
                heading={"Edit Unit"}
                firstName={myDetails?.firstName}
                lastName={myDetails?.lastName}
                hasSearchBar={true}
                isloading={isLoading || isUnitLoading}
            />
            <div className="ml-6 mt-4 ">
                <Breadcrumbs
                    name={"Edit Unit"}
                    className="mb-10"
                    isLoading={isLoading || isUnitLoading}
                />
                <div className="gap-[104px] w-1/2">
                    <UnitForm
                        onChangePage={() => {}}
                        buildingId={unitData?.buildingId || ""}
                        addressId={unitData?.addressId || ""}
                        images={[]}
                        unitDetails={unitDetails}
                        id={unitData?._id}
                        unitDataId={tenantsDetails?.[0]?._id}
                    />
                </div>
            </div>
        </div>
    );
};

export default UpdateUnit;
