import { useState } from "react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { useNavigate } from "react-router-dom";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "../ui/alert-dialog";
import { clearLocalStorage } from "../../utils/service";
import { useDispatch } from "react-redux";
import { api } from "@/redux/rtk-query/api";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Skeleton } from "../ui/skeleton";

interface MenuItem {
    id: string;
    item: string;
    redirectLink: string;
}

interface UserProfileProps {
    menuItems?: MenuItem[];
}

const UserProfile: React.FC<UserProfileProps> = ({ menuItems = [] }) => {
    const { data: myDetails, isLoading: isProfileLoading } = useGetMyDetailsQuery();
    const { imageUrl, firstName } = myDetails || {};
    const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        clearLocalStorage();
        dispatch(api.util.resetApiState());
        navigate("/login");
    };

    const allMenuItems = [
        ...menuItems,
        { id: "settings", item: "Settings", redirectLink: "/property-management/settings/account" },
        { id: "logout", item: "Logout", redirectLink: "" },
    ];

    if (isProfileLoading) {
        return (
            <div className="flex items-center bg-gray-200 rounded-full p-1 pr-3">
                <Skeleton className="h-8 w-8 rounded-full" />
                <Skeleton className="h-4 w-4 ml-2" />
            </div>
        );
    }

    return (
        <>
            <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
                <DropdownMenuTrigger asChild>
                    <div className="flex items-center cursor-pointer bg-gray-100 drop-shadow rounded-full py-1 px-3 transition-colors">
                        <Avatar className="h-8 w-8">
                            <AvatarImage src={imageUrl} alt={firstName} />
                            <AvatarFallback className="text-sm">
                                {firstName ? firstName[0].toUpperCase() : "?"}
                            </AvatarFallback>
                        </Avatar>
                        {isDropdownOpen ? (
                            <ChevronUp className="ml-2 h-4 w-4" />
                        ) : (
                            <ChevronDown className="ml-2 h-4 w-4" />
                        )}
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="p-2">
                    {allMenuItems.map((item) => (
                        <DropdownMenuItem
                            key={item.id}
                            onClick={() => {
                                if (item.id === "logout") {
                                    setIsLogoutDialogOpen(true);
                                } else {
                                    navigate(item.redirectLink);
                                }
                                setIsDropdownOpen(false);
                            }}
                        >
                            {item.item}
                        </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>

            <AlertDialog open={isLogoutDialogOpen} onOpenChange={setIsLogoutDialogOpen}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Are you sure you want to logout?</AlertDialogTitle>
                        <AlertDialogDescription>
                            This action will log you out of your account.
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={handleLogout}>Logout</AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};

export default UserProfile;
