// // // import React, { useEffect, useRef, useState } from "react";
// // // import { FaCirclePause, FaCirclePlay } from "react-icons/fa6";
// // // import WaveSurfer, { WaveSurferOptions } from "wavesurfer.js";

// // // type Props = {
// // //     audioFileUrl: string;
// // //     callDuration: string;
// // // };

// // // const formWaveSurferOptions = (ref: any) => ({
// // //     container: ref,
// // //     waveColor: "#ffffff",
// // //     progressColor: "#22BCFF",
// // //     cursorColor: "transparent",
// // //     responsive: true,
// // //     height: 40,
// // //     normalize: true,
// // //     backend: "WebAudio" as const,
// // //     barWidth: 2,
// // //     barGap: 3,
// // // });

// // // export default function AudioPlayer(props: Props) {
// // //     const waveformRef = useRef<HTMLDivElement | null>(null);
// // //     const wavesurfer = useRef<WaveSurfer | null>(null);
// // //     const [playing, setPlaying] = useState(false);

// // //     const handlePlayPause = () => {
// // //         setPlaying((prev) => !prev);
// // //         if (wavesurfer.current) {
// // //             wavesurfer.current.playPause();
// // //         }
// // //     };

// // //     useEffect(() => {
// // //         if (wavesurfer.current) {
// // //             wavesurfer.current.destroy();
// // //             wavesurfer.current = null;
// // //         }

// // //         const options: WaveSurferOptions = formWaveSurferOptions(waveformRef.current);
// // //         wavesurfer.current = WaveSurfer.create(options);

// // //         // Load the audio file from URL
// // //         console.log(props.audioFileUrl);

// // //         wavesurfer.current.load(props.audioFileUrl);

// // //         wavesurfer.current.on("finish", () => {
// // //             setPlaying(false);
// // //         });

// // //         wavesurfer.current.on("error", (error) => {
// // //             console.error("WaveSurfer error:", error);
// // //         });

// // //         return () => {
// // //             if (wavesurfer.current) {
// // //                 wavesurfer.current.destroy();
// // //             }
// // //         };
// // //     }, [props.audioFileUrl]);

// // //     return (
// // //         <div className="flex w-full bg-[#000] p-2 rounded-full hover:bg-[#000] gap-3 items-center">
// // //             {playing ? (
// // //                 <FaCirclePause
// // //                     className="text-[2.5rem] text-[#ffffff] cursor-pointer hover:text-[#ffffff]"
// // //                     onClick={handlePlayPause}
// // //                 />
// // //             ) : (
// // //                 <FaCirclePlay
// // //                     className="text-[2.5rem] text-[#ffffff] cursor-pointer hover:text-[#ffffff]"
// // //                     onClick={handlePlayPause}
// // //                 />
// // //             )}
// // //             <div id="waveform" ref={waveformRef} className="w-full"></div>
// // //             <p className="text-xs text-white">{new Date(parseInt(props.callDuration) * 1000).toISOString().substr(14, 5)}</p>

// // //         </div>
// // //     );
// // // }

// // import React, { useEffect, useRef, useState } from "react";
// // import { FaCirclePause, FaCirclePlay } from "react-icons/fa6";
// // import WaveSurfer, { WaveSurferOptions } from "wavesurfer.js";

// // type Props = {
// //     audioFileUrl: string;
// //     callDuration: string;
// // };

// // const formWaveSurferOptions = (ref: any) => ({
// //     container: ref,
// //     waveColor: "#ffffff",
// //     progressColor: "#22BCFF",
// //     cursorColor: "transparent",
// //     responsive: true,
// //     height: 40,
// //     normalize: true,
// //     backend: "WebAudio" as const,
// //     barWidth: 2,
// //     barGap: 3,
// // });

// // export default function AudioPlayer(props: Props) {
// //     const waveformRef = useRef<HTMLDivElement | null>(null);
// //     const wavesurfer = useRef<WaveSurfer | null>(null);
// //     const [playing, setPlaying] = useState(false);
// //     const [remainingTime, setRemainingTime] = useState(parseInt(props.callDuration));

// //     const handlePlayPause = () => {
// //         setPlaying((prev) => !prev);
// //         if (wavesurfer.current) {
// //             wavesurfer.current.playPause();
// //         }
// //     };

// //     useEffect(() => {
// //         if (wavesurfer.current) {
// //             wavesurfer.current.destroy();
// //             wavesurfer.current = null;
// //         }

// //         const options: WaveSurferOptions = formWaveSurferOptions(waveformRef.current);
// //         wavesurfer.current = WaveSurfer.create(options);

// //         // Load the audio file from URL
// //         wavesurfer.current.load(props.audioFileUrl);

// //         wavesurfer.current.on("audioprocess", () => {
// //             if (wavesurfer.current && wavesurfer.current.isPlaying()) {
// //                 const currentTime = wavesurfer.current.getCurrentTime();
// //                 const duration = wavesurfer.current.getDuration();
// //                 const timeRemaining = Math.max(0, duration - currentTime);
// //                 setRemainingTime(timeRemaining);
// //             }
// //         });

// //         wavesurfer.current.on("finish", () => {
// //             setPlaying(false);
// //             setRemainingTime(parseInt(props.callDuration)); // Reset the remaining time when audio finishes
// //         });

// //         wavesurfer.current.on("error", (error) => {
// //             console.error("WaveSurfer error:", error);
// //         });

// //         return () => {
// //             if (wavesurfer.current) {
// //                 wavesurfer.current.destroy();
// //             }
// //         };
// //     }, [props.audioFileUrl, props.callDuration]);

// //     return (
// //         <div className="flex w-full bg-[#000] p-2 rounded-full hover:bg-[#000] gap-3 items-center">
// //             {playing ? (
// //                 <FaCirclePause
// //                     className="text-[2.5rem] text-[#ffffff] cursor-pointer hover:text-[#ffffff]"
// //                     onClick={handlePlayPause}
// //                 />
// //             ) : (
// //                 <FaCirclePlay
// //                     className="text-[2.5rem] text-[#ffffff] cursor-pointer hover:text-[#ffffff]"
// //                     onClick={handlePlayPause}
// //                 />
// //             )}
// //             <div id="waveform" ref={waveformRef} className="w-full"></div>
// //             <p className="text-xs text-white">
// //                 {new Date(remainingTime * 1000).toISOString().substr(14, 5)}
// //             </p>
// //         </div>
// //     );
// // }

// import React, { useEffect, useRef, useState } from "react";
// import { FaCirclePause, FaCirclePlay } from "react-icons/fa6";
// import WaveSurfer, { WaveSurferOptions } from "wavesurfer.js";

// type Props = {
//     audioFileUrl: string;
//     callDuration: string;
// };

// const formWaveSurferOptions = (ref: any) => ({
//     container: ref,
//     waveColor: "#ffffff",
//     progressColor: "#22BCFF",
//     cursorColor: "transparent",
//     responsive: true,
//     height: 40,
//     normalize: true,
//     backend: "WebAudio" as const,
//     barWidth: 2,
//     barGap: 3,
// });

// export default function AudioPlayer(props: Props) {
//     const waveformRef = useRef<HTMLDivElement | null>(null);
//     const wavesurfer = useRef<WaveSurfer | null>(null);
//     const [playing, setPlaying] = useState(false);
//     const [remainingTime, setRemainingTime] = useState(parseInt(props.callDuration));

//     console.log(props.callDuration);

//     const handlePlayPause = () => {
//         setPlaying((prev) => !prev);
//         if (wavesurfer.current) {
//             wavesurfer.current.playPause();
//         }
//     };

//     useEffect(() => {
//         if (wavesurfer.current) {
//             wavesurfer.current.destroy();
//             wavesurfer.current = null;
//         }

//         const options: WaveSurferOptions = formWaveSurferOptions(waveformRef.current);
//         wavesurfer.current = WaveSurfer.create(options);

//         wavesurfer.current.load(props.audioFileUrl);

//         wavesurfer.current.on("ready", () => {
//             setRemainingTime(wavesurfer.current?.getDuration() || parseInt(props.callDuration));
//         });

//         wavesurfer.current.on("audioprocess", () => {
//             if (wavesurfer.current && wavesurfer.current.isPlaying()) {
//                 const currentTime = wavesurfer.current.getCurrentTime();
//                 const duration = wavesurfer.current.getDuration();
//                 const timeRemaining = Math.max(0, duration - currentTime);
//                 setRemainingTime(timeRemaining);
//             }
//         });

//         wavesurfer.current.on("finish", () => {
//             setPlaying(false);
//             setRemainingTime(parseInt(props.callDuration));
//         });

//         wavesurfer.current.on("error", (error) => {
//             console.error("WaveSurfer error:", error);
//         });

//         return () => {
//             if (wavesurfer.current) {
//                 wavesurfer.current.destroy();
//             }
//         };
//     }, [props.audioFileUrl, props.callDuration]);

//     const formatTime = (seconds: number) => {
//         const minutes = Math.floor(seconds / 60);
//         const secs = Math.floor(seconds % 60);
//         return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
//     };

//     return (
//         <div className="flex w-full bg-[#000] p-2 rounded-full hover:bg-[#000] gap-3 items-center">
//             {playing ? (
//                 <FaCirclePause
//                     className="text-[2.5rem] text-[#ffffff] cursor-pointer hover:text-[#ffffff]"
//                     onClick={handlePlayPause}
//                 />
//             ) : (
//                 <FaCirclePlay
//                     className="text-[2.5rem] text-[#ffffff] cursor-pointer hover:text-[#ffffff]"
//                     onClick={handlePlayPause}
//                 />
//             )}
//             <div id="waveform" ref={waveformRef} className="w-full"></div>
//             <p className="text-xs text-white w-4">
//                 {formatTime(remainingTime)}
//             </p>
//         </div>
//     );
// }
import { useEffect, useRef, useState } from "react";
import { FaCirclePause, FaCirclePlay } from "react-icons/fa6";
import WaveSurfer, { WaveSurferOptions } from "wavesurfer.js";

type Props = {
    audioFileUrl: string;
    callDuration: string;
    title?: string;
};

const formWaveSurferOptions = (ref: any) => ({
    container: ref,
    waveColor: "#000",
    progressColor: "#22BCFF",
    cursorColor: "transparent",
    responsive: true,
    height: 40,
    normalize: true,
    backend: "WebAudio" as const,
    barWidth: 2,
    barGap: 3,
});

export default function AudioPlayer({ audioFileUrl, callDuration, title }: Props) {
    const waveformRef = useRef<HTMLDivElement | null>(null);
    const wavesurfer = useRef<WaveSurfer | null>(null);
    const [playing, setPlaying] = useState(false);
    const [remainingTime, setRemainingTime] = useState<number | null>(null);

    const handlePlayPause = () => {
        setPlaying((prev) => !prev);
        if (wavesurfer.current) {
            wavesurfer.current.playPause();
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        if (wavesurfer.current) {
            wavesurfer.current.destroy();
            wavesurfer.current = null;
        }

        const options: WaveSurferOptions = formWaveSurferOptions(waveformRef.current);
        wavesurfer.current = WaveSurfer.create(options);

        wavesurfer.current.load(audioFileUrl);

        wavesurfer.current.on("ready", () => {
            const duration = wavesurfer.current?.getDuration() || parseInt(callDuration);
            setRemainingTime(duration); // Set the actual duration once audio is ready
        });

        wavesurfer.current.on("audioprocess", () => {
            if (wavesurfer.current && wavesurfer.current.isPlaying()) {
                const currentTime = wavesurfer.current.getCurrentTime();
                const duration = wavesurfer.current.getDuration();
                const timeRemaining = Math.max(0, duration - currentTime);
                setRemainingTime(timeRemaining);
            }
        });

        wavesurfer.current.on("finish", () => {
            setPlaying(false);
            setRemainingTime(wavesurfer.current?.getDuration() || parseInt(callDuration)); // Reset the remaining time
        });

        wavesurfer.current.on("error", (error) => {
            console.error("WaveSurfer error:", error);
        });

        return () => {
            controller.abort("Aborted");
            if (wavesurfer.current) {
                wavesurfer.current.destroy();
            }
        };
    }, [audioFileUrl, callDuration]);

    const formatTime = (seconds: number | null) => {
        if (seconds === null) return "00:00";
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    };

    return (
        <div className="rounded-lg bg-white py-4 px-4 mt-4 shadow-sm">
            {/* Title (Optional) */}
            {title && <h1 className="text-gray-800 text-md font-medium mb-4">{title}</h1>}
            <div className="flex items-center gap-4">
                {/* Play/Pause Button */}
                {playing ? (
                    <FaCirclePause
                        className="text-[2.5rem] text-black cursor-pointer hover:text-gray-700"
                        onClick={handlePlayPause}
                    />
                ) : (
                    <FaCirclePlay
                        className="text-[2.5rem] text-black cursor-pointer hover:text-gray-700"
                        onClick={handlePlayPause}
                    />
                )}
                {/* Waveform */}
                <div id="waveform" ref={waveformRef} className="w-full"></div>
                {/* Remaining Time */}
                <p className="text-gray-500 text-sm w-12 text-right">
                    {formatTime(remainingTime)}
                </p>{" "}
                {/* Fixed width and alignment */}
            </div>
        </div>
    );
}
