import { useGetPropertyCompliancesByIdQuery } from "@/redux/rtk-query/properties";
import { useMemo } from "react";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";

// Define a TypeScript interface for compliance data
interface Compliance {
    _id: string;
    name: string;
    category: string;
    required_documents: string;
    description: string;
}

const ComplianceRow = () => {
    const { data: user } = useGetMyDetailsQuery();

    const { data: complianceData } = useGetPropertyCompliancesByIdQuery(user?.companyId, {
        skip: !user?.companyId,
    });

    console.log(complianceData);

    const complianceRow = useMemo(() => {
        if (!Array.isArray(complianceData)) return [];
        return complianceData.map((compliance: Compliance, index) => {
            return {
                id: index + 1,
                name: compliance.name,
                category: compliance.category,
                requiredDocuments: compliance.required_documents,
                description: compliance.description,
            };
        });
    }, [complianceData]);
    return complianceRow;
};

export default ComplianceRow;
