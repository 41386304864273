import { Chip } from "@/components/common/chip";
import { Button } from "@/components/ui/button";
import { capitalizeEachWord } from "@/utils/capitalizeEachWords";
import { convertScreamToTitle } from "@/utils/convertScreamToTitle";
import { FileText, Pencil } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

type TProps = {
    images: string[] | undefined;
    propertyName: string | undefined;
    province: string | undefined;
    country: string | undefined;
    category: string | undefined;
    ownerName: string | undefined;
    files: string[] | undefined;
};

export const PropertyCard = ({
    images = [],
    propertyName = "",
    province = "",
    country = "",
    category = "",
    ownerName = "",
    files = [],
}: TProps) => {
    const navigate = useNavigate();
    const { propertyId } = useParams();

    const onSelectValue = () => {
        navigate(`/property-management/properties/update-property/${propertyId}`);
    };

    return (
        <div
            className="border border-zinc-200 p-4 rounded-lg border-solid bg-white w-full"
            style={{
                filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05))",
            }}
        >
            {images && images.length > 0 && (
                <div className="grid grid-cols-2 gap-1 mb-4">
                    {images
                        .slice(0, 4)
                        .filter((image) => image)
                        .map((image, index) => (
                            <img
                                key={image}
                                src={image}
                                alt={`Property ${index + 1}`}
                                className="flex-[1_1_50%] h-[122px] w-full rounded-md"
                            />
                        ))}
                </div>
            )}
            <div className="flex justify-between mb-4">
                <div>
                    {propertyName && (
                        <h3 className="m-0 p-0 font-medium text-xl leading-[26px] text-black mb-2 line-clamp-1">
                            {capitalizeEachWord(propertyName)}
                        </h3>
                    )}
                    <p className="line-clamp-2">{`${province ? `${province}, ` : ""} ${country ? country : ""}`}</p>
                </div>
                <Button
                    variant={"outline"}
                    className=" w-10 min-w-[40px] max-w-[40px] h-10 min-h-[40px] max-h-10 !p-0"
                    onClick={onSelectValue}
                >
                    <Pencil size={16} />
                </Button>
            </div>
            <div className="grid grid-cols-[auto_1fr] gap-x-5 gap-y-4 mb-4">
                <p className="text-xs leading-4 text-gray-500">Property Type</p>
                {category ? <Chip color="gray" text={convertScreamToTitle(category)} /> : <p></p>}
                <p className="text-xs leading-4 text-gray-500">Property Owner</p>
                <p className="text-xs leading-4 text-gray-800 pl-2 line-clamp-1">
                    {capitalizeEachWord(ownerName)}
                </p>
            </div>
            {files.length > 0 && (
                <Button variant={"outline"}>
                    {files.map((file, index) => (
                        <a href={file} download key={index} className="flex items-center">
                            <FileText size={16} className="mr-2" />
                            <div>{file.split("/").pop()}</div>
                        </a>
                    ))}
                </Button>
            )}
        </div>
    );
};
