import { toast } from "react-toastify";
import { TQuote, TUser } from "../../types";
import { catchAll } from "../../utils/catch";
import { api } from "./api";

type TGetQuoteByIdArgs = {
    jobId: string | undefined;
    quoteId: string | undefined;
};

type TGetQuoteByIdSuccess = Omit<TQuote, "postedBy"> & { postedBy: TUser };

const quoteApis = api.injectEndpoints({
    endpoints: (builder) => ({
        getQuoteById: builder.query<TGetQuoteByIdSuccess, TGetQuoteByIdArgs>({
            query: ({ jobId, quoteId }) => `jobs/bid/${jobId}/${quoteId}`,
            providesTags: (_, __, args) => {
                return [{ type: "JOB_QUOTES", id: `${args.jobId}/${args.quoteId}` }];
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Failed to get quote details");
                }
            },
            transformResponse: (response: TQuote[]) => {
                return {
                    ...response[0],
                    postedBy: response[0].postedBy[0],
                };
            },
        }),
        createJobQuote: builder.mutation<any, any>({
            query: ({ _id, data }) => {
                return {
                    url: `jobs/bid/${_id}`,
                    method: "POST",
                    body: data,
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Something went wrong!");
                    return;
                }
            },
        }),
    }),
});

export const { useGetQuoteByIdQuery, useLazyGetQuoteByIdQuery, useCreateJobQuoteMutation } =
    quoteApis;
