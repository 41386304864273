import { TableActions } from "@/components/common/tableActions";
import { TaskTables } from "@/components/table/TaskTable";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { TabsContent } from "@/components/ui/tabs";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableHeadItems } from "../PropertyManagementKnowledgeBased.constants";
import useTableSortAndFilter from "@/components/common/table/useTableSortAndFilter";
import useDocumentsRow from "../tableRow/useDocumentsRow";

interface DocumentsTabProps {
    companyId: string | undefined;
}

export const DocumentsTab: React.FC<DocumentsTabProps> = ({ companyId }) => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState<string>("");

    const { propertiesData } = useDocumentsRow({ companyId });

    // const filteredPropertyRow = useMemo(() => {
    //     const lowercasedSearchTerm = searchTerm.toLowerCase();
    //     return ContractorRows?.filter((row: any) =>
    //         Object.values(row).some((value) =>
    //             String(value).toLowerCase().includes(lowercasedSearchTerm),
    //         ),
    //     );
    // }, [searchTerm, ContractorRows]);
    console.log(propertiesData);

    // const {
    //     sortedTableRowItems,
    //     handleToggleColumn,
    //     handleSort,
    //     visibleColumns,
    //     sortDirection,
    //     sortColumn,
    // } = useTableSortAndFilter({ head: TableHeadItems, rows: filteredPropertyRow });

    // const TableData = {
    //     data: [{ header: visibleColumns, row: sortedTableRowItems, heading: "Contractor" }],
    // };

    const sortItems = [
        // { value: "userId", key: "Tenant ID" },
        { value: "rent", key: "Rent" },
        { value: "securityDeposit", key: "Security Deposit" },
        { value: "otherCharges", key: "Other Charges" },
        { value: "tenure", key: "Tenure" },
        { value: "bookingDate", key: "Booking Date" },
        { value: "startDate", key: "Start Date" },
        { value: "endDate", key: "End Date" },
    ];

    const columnItems = [
        { value: "ID", key: "id" },
        // { value: "userId", key: "Tenant ID" },
        { value: "rent", key: "Rent" },
        { value: "securityDeposit", key: "Security Deposit" },
        { value: "otherCharges", key: "Other Charges" },
        { value: "tenure", key: "Tenure" },
        { value: "bookingDate", key: "Booking Date" },
        { value: "startDate", key: "Start Date" },
        { value: "endDate", key: "End Date" },
    ];

    return (
        <TabsContent value="documents" className="p-4">
            <div className="flex items-center justify-between">
                <div className="mr-[100px]">
                    <h4 className="font-semibold text-gray-900 text-lg">Documents</h4>
                    <div className="text-sm text-gray-500 w-[600px]">
                        Upload documents, and the AI will analyze them to provide accurate answers
                        for tenant inquiries, ensuring even complex questions are handled with ease.
                    </div>
                </div>
                <Button className="bg-black text-white px-4 py-2 rounded-md">Add Document</Button>
            </div>
            {/* <TaskTables
                mainHeading="Tenants"
                data={TableData?.data || []}
                className="text-xs"
                isLoading={isDataLoading}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                totalpages={pagination.totalPages}
                currentPage={pagination.currentPage}
                onPageChange={handlePageChange}
                sortAndFilter={
                    <TableActions
                        onClickFilter={() => {}}
                        onClickSort={handleSort}
                        onToggleColumn={handleToggleColumn}
                        visibleColumns={visibleColumns.map((item) => item?.accessorKey)}
                        isSearch={true}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        sortItems={sortItems}
                        columnItems={columnItems}
                    />
                }
                onClickRow={(row: any) => {
                    const rowId = row?.original?.id;
                    const payload =
                        tenantsList && Array.isArray(tenantsList)
                            ? tenantsList.find((log: any) => log._id === rowId)
                            : undefined;
                    const path = getUserBasePath(user?.userType);

                    return navigate(`${path}/tenants/${rowId}`, {
                        state: { ...(payload || {}), RowId: Number(row.id) + 1 },
                    });
                }}
            /> */}
        </TabsContent>
    );
};
