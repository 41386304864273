import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useGetMyDetailsQuery, useUpdateUserDetailsMutation } from "@/redux/rtk-query/authApis";
import { toast } from "react-toastify";
import { UploadImage } from "../UploadImage";
import PhoneInput from "react-phone-input-2";
import { TUser } from "@/types";
import { useEffect, useState } from "react";
import { Spinner } from "@/components/ui/spinner";
import { Label } from "@/components/ui/label";
import { Skeleton } from "@/components/ui/skeleton";
const profileSchema = z.object({
    firstName: z.string().min(2, "First name must be at least 2 characters."),
    lastName: z.string().min(2, "Last name must be at least 2 characters."),
    email: z.string().email("Invalid email address."),
    phone: z.string().optional(),
});

type ProfileFormProps = {
    user?: TUser | undefined;
    profileImage: string;
    setProfileImage: (image: string) => void;
};

export function ProfileForm({ profileImage, setProfileImage }: ProfileFormProps) {
    const [updateUserDetails, { isLoading: isUpdating }] = useUpdateUserDetailsMutation();
    const [isImageChanged, setIsImageChanged] = useState(false);
    const [defaultCountry, setDefaultCountry] = useState("gb");
    const { data: user, refetch, isLoading: userLoading } = useGetMyDetailsQuery();

    const form = useForm({
        resolver: zodResolver(profileSchema),
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
        },
    });

    // Update form values when user data is available
    useEffect(() => {
        if (user) {
            form.reset({
                firstName: user.firstName || "",
                lastName: user.lastName || "",
                email: user.email || "",
                phone: user.phone || "",
            });
        }
    }, [user, form]);

    useEffect(() => {
        setIsImageChanged(profileImage !== user?.imageUrl);
    }, [profileImage, user?.imageUrl]);

    useEffect(() => {
        if (user?.phone) {
            const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
            try {
                const phoneNumber = phoneUtil.parse(user.phone);
                const countryCode = phoneUtil.getRegionCodeForNumber(phoneNumber);
                setDefaultCountry(countryCode.toLowerCase());
            } catch (error) {
                console.error("Error parsing phone number:", error);
            }
        }
    }, [user?.phone]);

    useEffect(() => {
        if (user && user.imageUrl) {
            setProfileImage(user.imageUrl);
        }
    }, [user, setProfileImage]);

    async function onSubmit(values: z.infer<typeof profileSchema>) {
        try {
            await updateUserDetails({
                ...values,
                imageUrl: profileImage,
            }).unwrap();
            refetch();
            setIsImageChanged(false);
            toast.success("Profile updated successfully");
        } catch (error) {
            toast.error("Failed to update profile");
        }
    }

    return (
        <>
            <div className="mb-6">
                {userLoading ? (
                    <Skeleton className="w-32 h-32 rounded-full" />
                ) : (
                    <UploadImage
                        profileImage={profileImage || user?.imageUrl || ""}
                        setProfileImage={setProfileImage}
                        title="Upload new image"
                    />
                )}
            </div>

            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="max-w-2xl py-7">
                    <div className="grid grid-cols-2 gap-6">
                        <div>
                            <FormField
                                control={form.control}
                                name="firstName"
                                render={({ field }) => (
                                    <FormItem>
                                        <Label>First Name</Label>
                                        <FormControl>
                                            {userLoading ? (
                                                <Skeleton className="h-10 w-full" />
                                            ) : (
                                                <Input placeholder="Enter first name" {...field} />
                                            )}
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="phone"
                                render={({ field }) => (
                                    <FormItem className="mt-4">
                                        <Label>Phone Number</Label>
                                        <FormControl>
                                            {userLoading ? (
                                                <Skeleton className="h-10 w-full" />
                                            ) : (
                                                <PhoneInput
                                                    country={defaultCountry}
                                                    value={field.value}
                                                    onChange={(phone) => field.onChange(phone)}
                                                    inputProps={{
                                                        required: true,
                                                        style: {
                                                            marginLeft: "26px",
                                                            height: "40px",
                                                            borderRadius: "6px",
                                                            border: "1px solid var(--input-border-color)",
                                                        },
                                                    }}
                                                    disableDropdown={false}
                                                    // enableSearch={true}
                                                    dropdownStyle={{
                                                        marginTop: "45px",
                                                        width: "320px",
                                                    }}
                                                    enableAreaCodes={true}
                                                    enableTerritories={true}
                                                    searchPlaceholder="Search countries"
                                                    countryCodeEditable={false}
                                                />
                                            )}
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div>
                            <FormField
                                control={form.control}
                                name="lastName"
                                render={({ field }) => (
                                    <FormItem>
                                        <Label>Last Name</Label>
                                        <FormControl>
                                            {userLoading ? (
                                                <Skeleton className="h-10 w-full" />
                                            ) : (
                                                <Input placeholder="Enter last name" {...field} />
                                            )}
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            {/* <FormField
                                control={form.control}
                                name="email"
                                render={({ field }) => (
                                    <FormItem className="mt-4">
                                        <FormLabel>Email</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Enter email" {...field} disabled />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /> */}
                        </div>
                    </div>
                    <div className="flex justify-start space-x-2 mt-6">
                        <Button
                            type="submit"
                            disabled={
                                (!form.formState.isDirty && !isImageChanged) ||
                                isUpdating ||
                                userLoading
                            }
                        >
                            {isUpdating && <Spinner size="small" className="text-white mr-1" />}
                            Save
                        </Button>
                        {(isImageChanged ||
                            form.getValues("firstName") !== user?.firstName ||
                            form.getValues("lastName") !== user?.lastName ||
                            form.getValues("email") !== user?.email ||
                            form.getValues("phone") !== user?.phone) && (
                            <Button
                                type="button"
                                variant="outline"
                                onClick={() => {
                                    form.reset();
                                    setProfileImage(user?.imageUrl || "");
                                    setIsImageChanged(false);
                                }}
                                disabled={isUpdating || userLoading}
                            >
                                Cancel
                            </Button>
                        )}
                    </div>
                </form>
            </Form>
        </>
    );
}
