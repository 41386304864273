import { memo } from "react";
import styles from "./homeOwnerPostAJob.module.css";

type TProps = {
    name: string;
};

export const PostJobHeader = memo((props: TProps) => {
    const { name } = props;
    return (
        <header>
            <h2 className={styles.aiPromptUserName}>Hello {name},</h2>
            <p className={styles.aiPromptDescription}>
                Type your job description and connect with the best contractors
            </p>
        </header>
    );
});
