import { Link, useNavigate } from "react-router-dom";
import { Logo } from "../../../../assets/icons";
import styles from "./style.module.css";
import ProfileIcon from "../../../common/profileIcon";
import imageAvatar from "../../../../assets/images/imageAvatar.png";
import NavDropdown from "../../../common/navDropdown";
import NavList from "../../../common/navList";
import { INavList } from "../../../../utils/interface/random.interface";
import { Dropdown } from "../../../common/dropdown";
import { TDropdownItem } from "../../../common/dropdown/Dropdown.types";
import { useCallback } from "react";
import { clearLocalStorage } from "../../../../utils/service";
import { UserProfileDropdowns } from "../../../../utils/constantData";
import { api } from "../../../../redux/rtk-query/api";
import { useAppDispatch } from "@/redux/store";

const findJobSubList: INavList[] = [
    { label: "Find Jobs", link: "/contractor/find-jobs" },
    { label: "Saved Jobs", link: "/contractor/saved-jobs" },
];

interface NavbarProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function Navbar({ isOpen, setIsOpen }: NavbarProps) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onClickDropdown = useCallback(
        (clickedItem: TDropdownItem) => {
            if (clickedItem.value === "logout") {
                clearLocalStorage();
                api.util.invalidateTags(["USER_DETAILS"]);
                dispatch(api.util.resetApiState());
                navigate("/login");
            }
            if (clickedItem.value === "settings") {
                navigate("/contractor/settings");
            }
        },
        [dispatch, navigate],
    );

    return (
        <main className={styles.navFrame}>
            {/* Desktop Navigation */}
            <div className={styles.desktopNav}>
                <div
                    onClick={() => {
                        navigate("/contractor/dashboard/");
                    }}
                    style={{ cursor: "pointer" }}
                    className={styles.logoContainer}
                >
                    <Logo fill="#22BCFF" />
                </div>
                <ul className={styles.navMainList}>
                    <li className={styles.li}>
                        <NavDropdown
                            label={"Find Jobs"}
                            icon={<i className="ri-arrow-down-s-line"></i>}
                        >
                            <div className={styles.list}>
                                <ul className={`${styles.subnavList} ${styles.dropLeft}`}>
                                    <NavList list={findJobSubList} />
                                </ul>
                            </div>
                        </NavDropdown>
                    </li>
                    {/* <li className={styles.li}>
                        <NavDropdown
                            label={"My Jobs"}
                            icon={<i className="ri-arrow-down-s-line"></i>}
                        >
                            <div className={styles.list}>
                                <ul className={`${styles.subnavList} ${styles.dropLeft}`}>
                                    <NavList list={myJobSubList} />
                                </ul>
                            </div>
                        </NavDropdown>
                    </li> */}
                    <li className={styles.li}>
                        <Link to={"/contractor/my-jobs"}>
                            <span>My Jobs</span>
                        </Link>
                    </li>
                    <li className={styles.li}>
                        <Link to={"/contractor/chat"}>
                            <span>Messages</span>
                        </Link>
                    </li>
                    <li className={styles.li}>
                        <Link to={"/contractor/ai-assistant"}>
                            <span>AI Assistant</span>
                        </Link>
                    </li>
                    <li className={styles.li}>
                        <Link to={"/contractor/calendar"}>
                            <span>Calendar</span>
                        </Link>
                    </li>
                </ul>

                <ul className={styles.dropSection}>
                    {/* <li className={styles.li}>
                        <NavDropdown
                            icon={
                                <span className={styles.navNotification}>
                                    <i className={`${styles.iconLe} ri-notification-2-line`}></i>
                                </span>
                            }
                            button={true}
                        >
                            <div className={`${styles.list} ${styles.listRight}`}>
                                <ul className={`${styles.subnavList} ${styles.dropRight}`}>
                                    <li>Notification</li>
                                </ul>
                            </div>
                        </NavDropdown>
                    </li> */}
                    <li className={styles.li}>
                        <Dropdown onClick={onClickDropdown} items={UserProfileDropdowns}>
                            <span>
                                <ProfileIcon imageSrc={imageAvatar} icon={true} />
                            </span>
                        </Dropdown>
                    </li>
                </ul>
            </div>

            <div className={styles.mobileNav}>
                <button className={styles.mobileLeft} onClick={() => setIsOpen(!isOpen)}>
                    {isOpen ? <i className="ri-close-fill"></i> : <i className="ri-menu-line"></i>}
                </button>
                <div>
                    <Logo fill="#22BCFF" />
                </div>
                <div className={styles.rightSide}>
                    <i className={`${styles.iconLe} ri-notification-2-line`}></i>
                    <Dropdown onClick={onClickDropdown} items={UserProfileDropdowns}>
                        <span>
                            <ProfileIcon imageSrc={imageAvatar} />
                        </span>
                    </Dropdown>
                </div>
            </div>
        </main>
    );
}

export default Navbar;
