import styles from "./homeOwnerPostAJob.module.css";

export const AIPromptSample = () => (
    <div className={styles.aiPromptSample}>
        <h3 className={styles.aiPromptSampleHeading}>Sample prompts</h3>
        <p className={styles.aiPromptSampleDescription}>
            I'm looking for a reliable and experienced contractor to clean my house in East london
            this Saturday, April 13th, at 3 PM
        </p>
    </div>
);
