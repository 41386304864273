import TextArea from "@/components/common/input/textArea";

type TProps = {
    input: string;
    setInput: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export const OptionsForm: React.FC<TProps> = ({ input, setInput }) => {
    return (
        <div className="mb-5">
            <p className="text-base leading-6 text-gray-800 mb-3">
                If you have any queries about the pricing, scheduled time of the job or any other
                questions, enter them below.
            </p>
            <TextArea
                name="message"
                placeholder="Enter message"
                required={true}
                value={input}
                height={130}
                handleChange={setInput}
            />
        </div>
    );
};
