import { Button } from "../button";
import styles from "./confirmJobDetails.module.css";

export const Footer = (props: { onClickPrimary: () => void }) => (
    <footer className={styles.footer}>
        <div className={styles.footerActionContainer}>
            <Button variant="secondary">Preview</Button>
            <Button onClick={props.onClickPrimary}>Post a job</Button>
        </div>
    </footer>
);
