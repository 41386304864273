import * as Avatar from "@radix-ui/react-avatar";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Pencil } from "lucide-react";
import { Spinner } from "@/components/ui/spinner";
import PhoneInput from "react-phone-input-2";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "@/components/ui/calendar";
import { format } from "date-fns";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Skeleton } from "@/components/ui/skeleton";

interface ContactCardProps {
    name: string;
    location: string;
    phoneNumber?: string;
    email: string;
    onClickSubmit: (data: any) => void;
    isLoading: boolean;
    createBuildingLoading?: boolean;
}
const FormSchema = z.object({
    name: z.string().min(3, { message: "Name must be at least 3 characters." }),
    DOB: z.string().min(3, { message: "Please choose the correct format of date" }),
    email: z.string().email("Invalid email format"),
    phoneNumber: z.string().min(10, { message: "Invalid number" }),
    emergencyName: z.string().min(3, { message: "Name must be at least 3 characters." }),
    emergencyNumber: z.string().min(10, { message: "Invalid number" }),
});

const defaultValues: z.infer<typeof FormSchema> = {
    name: "",
    DOB: "",
    email: "",
    phoneNumber: "",
    emergencyName: "",
    emergencyNumber: "",
};
const ContactCard: React.FC<ContactCardProps> = ({
    name,
    location,
    phoneNumber,
    email,
    onClickSubmit,
    isLoading,
    createBuildingLoading,
}) => {
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            ...defaultValues,
            name,
            email,
            phoneNumber,
        },
    });
    // const [createBuilding, { isLoading: createBuildingLoading }] = useCreateBuildingEntryMutation();
    // const [updateBuilding, { isLoading: updateLoading }] = useUpdateBuildingEntryMutation();

    async function onSubmit(data: z.infer<typeof FormSchema>) {
        console.log(data);
        onClickSubmit(data);
    }

    return (
        <div className="bg-white drop-shadow-md rounded-[12px] p-4 lg:w-80 border ">
            <div className="flex items-center space-x-4">
                {isLoading ? (
                    <Skeleton className="w-12 h-12 min-w-12 min-h-12 rounded-full overflow-hidden bg-gray-300" />
                ) : (
                    <Avatar.Root className="w-12 h-12 min-w-12 min-h-12 rounded-full overflow-hidden">
                        <Avatar.Image
                            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                            alt={name}
                            className="w-12 h-12 object-cover"
                        />
                        <Avatar.Fallback className="bg-gray-200 text-gray-500 font-medium w-12 h-12 flex items-center justify-center">
                            {name
                                .split(" ")
                                .map((part) => part.charAt(0))
                                .join("")}
                        </Avatar.Fallback>
                    </Avatar.Root>
                )}
                <div>
                    {isLoading ? (
                        <Skeleton className="w-24 h-5 bg-gray-300" />
                    ) : (
                        <p className="text-lg font-medium">{name}</p>
                    )}
                    {isLoading ? (
                        <Skeleton className="w-32 h-4 mt-2 bg-gray-300" />
                    ) : (
                        location && (
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger
                                        className={cn(
                                            name.length > 60 ? "" : "cursor-default",
                                            "bg-transparent",
                                        )}
                                    >
                                        <p className="text-gray-500 text-xs text-left">
                                            {location.length > 60
                                                ? `${location.slice(0, 33)}...`
                                                : location}
                                        </p>
                                    </TooltipTrigger>
                                    {location.length > 60 && (
                                        <TooltipContent className="w-44 h-64 overflow-y-auto">
                                            {location}
                                        </TooltipContent>
                                    )}
                                </Tooltip>
                            </TooltipProvider>
                        )
                    )}
                </div>

                <Dialog>
                    <DialogTrigger asChild>
                        <Button
                            variant={"outline"}
                            className=" w-10 min-w-[40px] max-w-[40px] h-10 min-h-[40px] max-h-10 !p-0 flex-col-reverse self-start"
                        >
                            <Pencil size={16} />
                        </Button>
                    </DialogTrigger>
                    <DialogContent className="max-h-[calc(100vh-80px)] overflow-y-auto">
                        <DialogHeader>
                            <DialogTitle>Edit Tenant</DialogTitle>
                        </DialogHeader>
                        <div>
                            <Form {...form}>
                                <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
                                    <div className="max-w-[850px] border mt-4 p-4 mb-6 rounded-xl border-solid border-[#f2f2f2] ">
                                        <h1 className="font-medium text-sm leading-[14px] text-black">
                                            Basic Details
                                        </h1>
                                        <FormField
                                            control={form.control}
                                            name="name"
                                            render={({ field }) => (
                                                <FormItem className="w-[320px] mt-6">
                                                    <FormLabel className="text-sm text-black">
                                                        Tenant's Name
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            placeholder="Tenant's Name"
                                                            {...field}
                                                            className="focus:ring-1 focus-visible:ring-gray-200 h-9 w-full"
                                                        />
                                                    </FormControl>
                                                    <FormMessage className="text-xs" />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="DOB"
                                            render={({ field }) => (
                                                <FormItem className="mt-6">
                                                    <FormLabel className="text-sm text-black">
                                                        Date of Birth
                                                    </FormLabel>
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <FormControl>
                                                                <Button
                                                                    variant={"outline"}
                                                                    className={cn(
                                                                        "w-[320px] pl-3 text-left font-normal",
                                                                        !field.value &&
                                                                            "text-muted-foreground",
                                                                    )}
                                                                >
                                                                    {field.value ? (
                                                                        format(field.value, "PPP")
                                                                    ) : (
                                                                        <span>Pick a date</span>
                                                                    )}
                                                                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                                                </Button>
                                                            </FormControl>
                                                        </PopoverTrigger>
                                                        <PopoverContent
                                                            className="w-auto p-0"
                                                            align="start"
                                                        >
                                                            <Calendar
                                                                className={
                                                                    "[&_td]:h-10 [&_tr]:h-8 [&_tr]:w-56 [&_tr]:pt-0"
                                                                }
                                                                mode="single"
                                                                selected={new Date(field.value)}
                                                                onSelect={(date) => {
                                                                    if (!date) return;
                                                                    form.setValue(
                                                                        "DOB",
                                                                        date?.toString(),
                                                                    );
                                                                }}
                                                                disabled={() =>
                                                                    new Date(field.value) >
                                                                        new Date() ||
                                                                    new Date(field.value) <
                                                                        new Date("1900-01-01")
                                                                }
                                                                initialFocus
                                                            />
                                                        </PopoverContent>
                                                    </Popover>
                                                </FormItem>
                                            )}
                                        />
                                    </div>

                                    <div className="max-w-[850px] border mb-6 p-4 rounded-xl border-solid border-[#f2f2f2]">
                                        <h1 className="font-medium text-sm leading-[14px] text-black">
                                            Contact Details
                                        </h1>

                                        <FormField
                                            control={form.control}
                                            name="email"
                                            render={({ field }) => (
                                                <div className="flex gap-[6px]">
                                                    <FormItem className="w-[320px] mt-6">
                                                        <FormLabel className="text-sm text-black">
                                                            Email Address
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                placeholder="xyz@lightwork.blue"
                                                                {...field}
                                                                className="focus:ring-1 focus-visible:ring-gray-200 h-9 w-full"
                                                            />
                                                        </FormControl>
                                                        <FormMessage className="text-xs" />
                                                    </FormItem>
                                                    {/* added this according to design but the funtionality doesn't exist */}

                                                    {/* <Button
                                                        variant="outline"
                                                        size="icon"
                                                        className="p-0 m-0 self-end"
                                                    >
                                                        <Plus size={16} color="black" />
                                                    </Button> */}
                                                </div>
                                            )}
                                        />
                                        <div>
                                            <p className="font-medium text-sm leading-[17px] text-[#09090B] mt-6 mb-[6px]">
                                                Phone Number
                                            </p>
                                        </div>
                                        <PhoneInput
                                            country={"gb"}
                                            placeholder="Enter number"
                                            inputClass="!pl-[70px] !w-full !h-11 !rounded-md !border !border-solid !border-input !bg-background !px-3 !py-3 !text-sm !ring-offset-background !file:border-0 !file:bg-transparent !file:text-sm !file:font-medium !placeholder:text-muted-foreground !focus-visible:outline-none !focus-visible:ring-2 !focus-visible:ring-ring !focus-visible:ring-offset-2 !disabled:cursor-not-allowed !disabled:opacity-50 !w-[320px] !mt-[6px]"
                                            onChange={(value) => {
                                                form.setValue("phoneNumber", value);
                                            }}
                                        />
                                    </div>

                                    <div className="max-w-[850px] border mb-6 p-4 rounded-xl border-solid border-[#f2f2f2]">
                                        <h1 className="font-medium text-sm leading-[14px] text-black">
                                            Emergency Contact
                                        </h1>

                                        <FormField
                                            control={form.control}
                                            name="emergencyName"
                                            render={({ field }) => (
                                                <FormItem className="w-[320px] mt-6">
                                                    <FormLabel className="text-sm text-black">
                                                        Name
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            placeholder="Emergency contact name"
                                                            {...field}
                                                            className="focus:ring-1 focus-visible:ring-gray-200 h-9 w-full"
                                                        />
                                                    </FormControl>
                                                    <FormMessage className="text-xs" />
                                                </FormItem>
                                            )}
                                        />
                                        <div>
                                            <p className="font-medium text-sm leading-[17px] text-[#09090B] mt-6 mb-[6px]">
                                                Phone Number
                                            </p>
                                            <PhoneInput
                                                country={"gb"}
                                                placeholder="Enter number"
                                                onChange={(value) => {
                                                    form.setValue("emergencyNumber", value);
                                                }}
                                                inputClass="!w-[320px] !pl-[70px] !w-full !h-11 !rounded-md !border !border-solid !border-input !bg-background !px-3 !py-3 !text-sm !ring-offset-background !file:border-0 !file:bg-transparent !file:text-sm !file:font-medium !placeholder:text-muted-foreground !focus-visible:outline-none !focus-visible:ring-2 !focus-visible:ring-ring !focus-visible:ring-offset-2 !disabled:cursor-not-allowed !disabled:opacity-50"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-3 justify-end items-end ">
                                        <Button
                                            type="submit"
                                            className="mt-4 bg-[#000] w-[150px]"
                                            disabled={createBuildingLoading}
                                        >
                                            {createBuildingLoading && (
                                                <Spinner className="text-white size-5 mr-1" />
                                            )}
                                            Confirm Changes
                                        </Button>
                                    </div>
                                </form>
                            </Form>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
            <div className="mt-4 space-y-2">
                {phoneNumber ? (
                    isLoading ? (
                        <Skeleton className="w-48 h-4 bg-gray-300" />
                    ) : (
                        <div className="flex items-start gap-[1.25rem] cursor-pointer">
                            <span className="text-gray-500">Phone No.</span>
                            <div className="flex flex-col gap-1">
                                <span className="text-gray-800 text-[13px]">{phoneNumber}</span>
                                <span className="text-gray-800 text-[13px]">{phoneNumber}</span>
                            </div>
                        </div>
                    )
                ) : null}
                <div className="flex items-center gap-[1.57rem]">
                    {isLoading ? (
                        <>
                            <Skeleton className="w-16 h-4 bg-gray-300" />
                            <Skeleton className="w-64 h-4 bg-gray-300" />
                        </>
                    ) : (
                        <>
                            <span className="text-gray-500 text-sm">Email</span>
                            <div>
                                <span className="text-gray-800 text-[13px] font-bold">{email}</span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContactCard;
