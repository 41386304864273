import { useGetWorkOrderQuery } from "@/redux/rtk-query/jobsApis";
import { useMemo, useState, useEffect } from "react";
import { transformPriorityForAPI, transformStatus, transformStatusForAPI } from "../utils/utils";
import { ArrowUp, ArrowDown, ArrowUpDown } from "lucide-react"; // Import ArrowUpDown instead of ArrowUp and ArrowDown

type TableColumn = {
    accessorKey: string;
    header: string;
    cell: ({ row }: any) => JSX.Element;
};

type TableSortAndFilterProps = {
    head?: any;
};

export const formatWorkOrderId = (index: number): string => {
    return `WO-${index.toString().padStart(4, "0")}`;
};

export const useWorkOrderRow = ({ head }: TableSortAndFilterProps) => {
    const [queryParams, setQueryParams] = useState({
        page: "0",
        pageSize: "10",
        status: [] as string[], // Change to array
        search: "",
        propertyId: "",
        contractorId: "",
        startDate: "",
        endDate: "",
        jobPriority: [] as string[], // Change to array
        sortBy: "createdAt", // Set initial sort column to createdAt
        sortOrder: "desc", // Set initial sort order to descending
    });
    const {
        data: activeJobs,
        refetch: refetchTableData,
        isLoading: isWorkorderLoading,
        isFetching,
    } = useGetWorkOrderQuery(queryParams);

    const [visibleColumns, setVisibleColumns] = useState<TableColumn[]>(head);

    const [isFiltering, setIsFiltering] = useState(false);

    const JobDetailsRow = useMemo(() => {
        if (!activeJobs?.data) return [];

        let sortedData = [...activeJobs.data];

        if (queryParams.sortBy === "createdAt") {
            sortedData.sort((a, b) => {
                const dateA = new Date(a.createdAt).getTime();
                const dateB = new Date(b.createdAt).getTime();
                return queryParams.sortOrder === "asc" ? dateA - dateB : dateB - dateA;
            });
        }

        return sortedData
            .filter((item) => {
                if (!queryParams.search) return true;
                return item.jobDetail?.title
                    ?.toLowerCase()
                    .includes(queryParams.search.toLowerCase());
            })
            .map((item, index) => {
                const globalIndex =
                    parseInt(queryParams.page) * parseInt(queryParams.pageSize) + index + 1;
                return {
                    id: item._id,
                    workorderId: formatWorkOrderId(globalIndex), // Use the new formatWorkOrderId function
                    title: item.jobDetail?.title || "No Title",
                    priority:
                        item?.jobPriority?.slice(0, 1).toUpperCase() +
                            item?.jobPriority?.slice(1).toLowerCase() || "No Priority",
                    createdAt: item.createdAt
                        ? new Date(item.createdAt).toLocaleString("en-US", {
                              month: "short",
                              day: "2-digit",
                              year: "numeric",
                          })
                        : "No Date",
                    status: transformStatus(item.status),
                    contractor:
                        (item.assignedToInfo?.firstName || "") +
                        " " +
                        (item.assignedToInfo?.lastName || ""),
                    unit: item?.propertyDetails?.name || "No Unit",
                };
            });
    }, [
        activeJobs?.data,
        queryParams.search,
        queryParams.sortBy,
        queryParams.sortOrder,
        queryParams.page,
        queryParams.pageSize,
    ]);

    const handlePageChange = (newPage: number) => {
        queryParams?.page !== String(newPage) && setIsFiltering(true);
        setQueryParams((prev) => ({ ...prev, page: String(newPage) }));
    };

    const handleSort = (column: string) => {
        setQueryParams((prev) => ({
            ...prev,
            sortBy: column,
            sortOrder: prev.sortBy === column && prev.sortOrder === "asc" ? "desc" : "asc",
            page: "0",
        }));
    };

    const visibleColumnsWithSort = useMemo(() => {
        return visibleColumns.map((column) => {
            if (column.accessorKey === "createdAt") {
                return {
                    ...column,
                    header: () => (
                        <button
                            className="flex items-center"
                            onClick={() => handleSort("createdAt")}
                        >
                            Posted On
                            {queryParams.sortBy === "createdAt" ? (
                                queryParams.sortOrder === "asc" ? (
                                    <ArrowUp className="ml-2 h-4 w-4" />
                                ) : (
                                    <ArrowDown className="ml-2 h-4 w-4" />
                                )
                            ) : (
                                <ArrowUpDown className="ml-2 h-4 w-4" />
                            )}
                        </button>
                    ),
                };
            }
            return column;
        });
    }, [visibleColumns, queryParams.sortBy, queryParams.sortOrder]);

    const handleToggleColumn = (column: string, key: string) => {
        setVisibleColumns((prev: any) => {
            const columnExists = prev.some((col: any) => col.accessorKey === column);
            if (columnExists) {
                return prev.filter((col: any) => col.accessorKey !== column);
            } else {
                const newColumns = [...prev];
                const insertIndex = head.findIndex((data: any) => data.header === key);
                newColumns.splice(
                    insertIndex,
                    0,
                    head.find((__: any, index: any) => index === insertIndex),
                );
                return newColumns;
            }
        });
    };

    const handleStatusFilter = (filter: string) => {
        const transformedFilter = transformStatusForAPI(filter);
        setQueryParams((prev) => ({
            ...prev,
            status: prev.status.includes(transformedFilter)
                ? prev.status.filter((s) => s !== transformedFilter)
                : [...prev.status, transformedFilter],
            page: "0",
        }));
    };

    const handleSearch = (searchTerm: string) => {
        console.log(searchTerm);
        setQueryParams((prev) => ({
            ...prev,
            search: searchTerm,
        }));
    };

    const handlePriorityFilter = (priority: string) => {
        const transformedPriority = transformPriorityForAPI(priority);
        setQueryParams((prev) => ({
            ...prev,
            jobPriority: prev.jobPriority.includes(transformedPriority)
                ? prev.jobPriority.filter((p) => p !== transformedPriority)
                : [...prev.jobPriority, transformedPriority],
            page: "0",
        }));
    };

    const handleDateFilter = (startDate: string, endDate: string) => {
        setIsFiltering(true);
        setQueryParams((prev) => ({
            ...prev,
            startDate,
            endDate,
            page: "0",
        }));
    };

    useEffect(() => {
        if (!isFetching) {
            setIsFiltering(false);
        }
    }, [isFetching]);

    // Add this new function to reset all filters
    const handleResetFilters = () => {
        setQueryParams({
            page: "0",
            pageSize: "10",
            status: [],
            search: "",
            propertyId: "",
            contractorId: "",
            startDate: "",
            endDate: "",
            jobPriority: [],
            sortBy: "createdAt",
            sortOrder: "desc",
        });
        setVisibleColumns(head);
        setIsFiltering(false);
    };

    return {
        activeJobs: activeJobs?.data || [],
        JobDetailsRow: JobDetailsRow || [],
        isWorkorderLoading,
        isFetching,
        handlePageChange,
        pagination: {
            totalDocs: activeJobs?.totalDocuments || 0,
            page: activeJobs?.page || 0,
            pageSize: activeJobs?.pageSize || 10,
            totalPages: activeJobs?.totalPages || 0,
        },
        handleSort,
        sortColumn: queryParams.sortBy,
        sortDirection: queryParams.sortOrder,
        handleToggleColumn,
        visibleColumns: visibleColumnsWithSort,
        handleStatusFilter,
        handleSearch,
        handlePriorityFilter,
        handleDateFilter,
        activeStatuses: queryParams.status,
        activePriorities: queryParams.jobPriority,
        refetchTableData,
        handleResetFilters,
        isFiltering,
    };
};
