import { memo } from "react";
import styles from "./tabButton.module.css";

const TabButtonUnMemo = ({
    onClick,
    count,
    label,
    selected,
}: {
    onClick: () => void;
    count?: number;
    label: string;
    selected: boolean;
}) => (
    <button
        type="button"
        onClick={onClick}
        className={selected ? `${styles.fJTab} ${styles.activeFJTab}` : styles.fJTab}
    >
        {label} {count !== undefined && `(${count})`}
    </button>
);

export const TabButton = memo(TabButtonUnMemo);
