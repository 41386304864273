import { Link } from "react-router-dom";
import styles from "../Landing.module.css";
import Button from "../../common/button";
import { EmailIcon, Hours24, Logo, Marker } from "../../../assets/icons";

function Footer() {
    return (
        <main className={styles.footerMain}>
            <div className={styles.footer}>
                <div>
                    <div>
                        <Link to={"/"}>
                            <Logo fill="#22BCFF" width="174" height="79" />
                        </Link>
                        <div className={styles.logoDesc}>
                            Revolutionising Property Maintenance with Smart Solutions for Property
                            Owners and Contractors.
                        </div>
                    </div>
                </div>
                <ul>
                    <li className={styles.fli}>
                        <Link to={"/"}>
                            <span>Contact details</span>
                        </Link>
                    </li>
                    <li>
                        <span className={styles.footerIcon}>
                            <Hours24 size="16" />
                        </span>
                        <Link to={"/"}>
                            <span> 24/7</span>
                        </Link>
                    </li>
                    <li>
                        <span className={styles.footerIcon}>
                            <Marker size="16" />
                        </span>
                        <Link to={"/"} style={{ textWrap: "nowrap" }}>
                            <span>1-3 Sun Street, London, EC2A 2EP</span>
                        </Link>
                    </li>
                    <li>
                        <span className={styles.footerIcon}>
                            <EmailIcon size="16" />
                        </span>
                        <Link to={"/"}>
                            <span> hello@lightwork.blue</span>
                        </Link>
                    </li>
                    <div className={styles.footerBtn}>
                        <Link to={"/"}>
                            <Button text="Join Waitlist" />
                        </Link>
                    </div>
                </ul>
            </div>
        </main>
    );
}

export default Footer;
