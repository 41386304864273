export function ShareIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.5 17.5C15.7426 17.5 16.75 16.4926 16.75 15.25C16.75 14.0074 15.7426 13 14.5 13C13.2574 13 12.25 14.0074 12.25 15.25C12.25 16.4926 13.2574 17.5 14.5 17.5Z"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5.5 12.25C6.74264 12.25 7.75 11.2426 7.75 10C7.75 8.75736 6.74264 7.75 5.5 7.75C4.25736 7.75 3.25 8.75736 3.25 10C3.25 11.2426 4.25736 12.25 5.5 12.25Z"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.44257 11.1323L12.5651 14.1173"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M14.5 7C15.7426 7 16.75 5.99264 16.75 4.75C16.75 3.50736 15.7426 2.5 14.5 2.5C13.2574 2.5 12.25 3.50736 12.25 4.75C12.25 5.99264 13.2574 7 14.5 7Z"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.5576 5.88232L7.44257 8.86732"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
