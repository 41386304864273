import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { cn } from "@/lib/utils";
import { Chats } from "./chat";
import { Sidebar } from "./sidebar/Sidebar";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { useGetUserConversationsQuery } from "@/redux/rtk-query/chatApis";
import { useLocation } from "react-router-dom";
import { useMobileDetection } from "@/utils/customHooks/useMobileDetection";
import { useConversationSelection } from "@/utils/customHooks/useConversationSelection";
import { useFilteredConversationList } from "@/utils/customHooks/useFilteredConversationList";

interface ChatLayoutProps {
    defaultLayout?: number[];
    defaultCollapsed?: boolean;
    navCollapsedSize: number;
}

export function ChatLayout({
    defaultLayout = [30, 70],
    defaultCollapsed = false,
    navCollapsedSize,
}: ChatLayoutProps) {
    const location = useLocation();
    const isMobile = useMobileDetection();
    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
    const [search, setSearch] = useState("");

    const { data: user } = useGetMyDetailsQuery();
    const { data: conversationList, isLoading: isConversationLoading } =
        useGetUserConversationsQuery();

    const { selectedConversation, conversationObject, isAiChat, handleConversationSelect } =
        useConversationSelection(conversationList, location.state?.conversationId);

    const filteredConversationList = useFilteredConversationList(conversationList, search, user);

    const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }, []);

    useEffect(() => {
        if (location.state?.conversationId) {
            window.history.replaceState({}, document.title);
        }
    }, [location.state?.conversationId]);

    return (
        <div className="z-10 border w-full h-full text-sm lg:flex bg-white">
            <ResizablePanelGroup
                direction="horizontal"
                onLayout={(sizes) => {
                    document.cookie = `react-resizable-panels:layout=${JSON.stringify(sizes)}`;
                }}
                className="h-full items-stretch"
            >
                <ResizablePanel
                    defaultSize={defaultLayout[0]}
                    collapsedSize={navCollapsedSize}
                    collapsible
                    minSize={isMobile ? 0 : 25}
                    maxSize={40}
                    onCollapse={() => {
                        setIsCollapsed(true);
                        document.cookie = `react-resizable-panels:collapsed=true`;
                    }}
                    onExpand={() => {
                        setIsCollapsed(false);
                        document.cookie = `react-resizable-panels:collapsed=false`;
                    }}
                    className={cn(
                        isCollapsed &&
                            "min-w-[50px] md:min-w-[70px] transition-all duration-300 ease-in-out",
                    )}
                >
                    <Sidebar
                        onClickUser={handleConversationSelect}
                        isCollapsed={isCollapsed || isMobile}
                        conversationList={filteredConversationList}
                        isSelected={selectedConversation}
                        currentUser={user}
                        isMobile={isMobile}
                        onChangeSearch={handleSearchChange}
                        search={search}
                        isConversationLoading={isConversationLoading}
                    />
                </ResizablePanel>
                <ResizableHandle withHandle />
                <ResizablePanel defaultSize={defaultLayout[1]} minSize={30}>
                    <Chats
                        isSelected={selectedConversation}
                        conversation={conversationObject}
                        isMobile={isMobile}
                        isAiChat={isAiChat}
                        isConversationLoading={isConversationLoading}
                    />
                </ResizablePanel>
            </ResizablePanelGroup>
        </div>
    );
}
