import { cn } from "@/lib/utils";
import { setPropertyManagerSidebarSettingsOpen } from "@/redux/features/uiSlice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { ChevronDown } from "lucide-react";
import React, { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Sidebar.module.css";
import { TSidebarItem } from "../layouts/PropertyManagementLayouts";

interface LinksProps {
    searchTerm: string;
    children: ReactNode;
    links: TSidebarItem[];
}

const Links: React.FC<LinksProps> = ({ searchTerm, children, links }) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const propertyManagerSidebarSettingsOpen = useAppSelector(
        (state) => state.ui.propertyManagerSidebarSettingsOpen,
    );

    const filteredLinks = links.filter((link) =>
        link.label.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    return (
        <nav className={styles.nav}>
            {filteredLinks.map((link, index) => (
                <React.Fragment key={index}>
                    {link?.subMenu && link.subMenu.length > 0 ? (
                        <button
                            className={cn(
                                styles.navLink,
                                "cursor-pointer w-full transition-all flex justify-between items-center",
                            )}
                            onClick={() =>
                                dispatch(
                                    setPropertyManagerSidebarSettingsOpen(
                                        !propertyManagerSidebarSettingsOpen,
                                    ),
                                )
                            }
                        >
                            <div className={"flex"}>
                                <span className={styles.linkIcon}>{link.icon}</span>
                                <span className={styles.linkLabel}>{link.label}</span>
                            </div>
                            <ChevronDown
                                className={cn(
                                    "h-4 w-4 shrink-0 transition-transform duration-300",
                                    propertyManagerSidebarSettingsOpen ? "rotate-180" : "",
                                )}
                            />
                        </button>
                    ) : (
                        <button
                            className={`${styles.navLink} ${location.pathname.includes(link?.path ?? "") ? styles.activeLink : ""} w-full`}
                            onClick={() => {
                                navigate(link.path ?? "");
                            }}
                        >
                            <div className={"flex"}>
                                <span className={styles.linkIcon}>{link.icon}</span>
                                <span className={styles.linkLabel}>{link.label}</span>
                            </div>
                        </button>
                    )}
                    <div
                        className={cn(
                            "transition-all overflow-hidden duration-300",
                            propertyManagerSidebarSettingsOpen ? "max-h-screen" : "max-h-0",
                        )}
                    >
                        {link.subMenu &&
                            link.subMenu.map((subMenu) => {
                                return (
                                    <div
                                        className={cn(
                                            styles.navLink,
                                            location.pathname.includes(subMenu.path)
                                                ? styles.activeLink
                                                : "",
                                            "ml-6 cursor-pointer transition-all",
                                        )}
                                        key={subMenu.path}
                                        onClick={() => {
                                            navigate(subMenu.path);
                                        }}
                                    >
                                        <div className={"flex"}>
                                            <span className={styles.linkIcon}>{subMenu.icon}</span>
                                            <span className={styles.linkLabel}>
                                                {subMenu.label}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </React.Fragment>
            ))}
            {children}
        </nav>
    );
};

export default Links;
