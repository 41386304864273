import LightWorkIcon from "@/assets/icons/LightWorkIcon";
import { useNavigate } from "react-router-dom";

// type TProps = {
//     companyName?: string;
//     companyLogo?: string;
// };

const Logo = () => {
    const navigate = useNavigate();
    return (
        <div className="flex justify-start ">
            <LightWorkIcon
                className="cursor-pointer"
                handleClick={() => {
                    navigate("/property-management/dashboard");
                }}
            />
        </div>
    );
};

export default Logo;
