// DashboardLayout.tsx
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import styles from "./layoout.module.css";
import Navbar from "../dashboard/homeOwner/navbar";

const HomeOwnersLayout: React.FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    //   const updateJobListing = useCallback(
    //     (data: IJob[]) => {
    //       handleSetState({ jobListings: data });
    //     },
    //     [handleSetState]
    //   );

    //   useEffect(() => {
    //     const fetchData = async () => {
    //       try {
    //         const [userDetailsResponse, jobListingsResponse] = await Promise.all([
    //           ApiCall.sendGet("auth/me"),
    //           ApiCall.sendGet("jobs"),
    //         ]);
    //         if (jobListingsResponse.status === 200) {
    //           const result = jobListingsResponse.data;
    //           const data = result.map((item: any) => ({
    //             id: item._id,
    //             date: item.createdAt,
    //             title: item.jobDetail.title,
    //             description: item.jobDetail.description,
    //             photoUrl: item.jobDetail.photoUrl,
    //             clock: "",
    //             address: item.address.building,
    //           }));
    //           updateJobListing(data);
    //         }

    //         console.log("User Details Response:", userDetailsResponse);
    //         console.log("Job Listings Response:", jobListingsResponse);
    //       } catch (error) {
    //         console.error("An error occurred during fetch:", error);
    //       }
    //     };
    //     fetchData();
    //   }, [updateJobListing]);

    return (
        <>
            {/* <MobileNavigation isOpen={isOpen} setIsOpen={setIsOpen} /> */}
            <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
            <div className={styles.dashboardlayout}>
                <main>
                    <Outlet />
                </main>
            </div>
        </>
    );
};

export default HomeOwnersLayout;
