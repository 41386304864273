import styles from "./Dashboard.module.css";
import { AsterikIcon } from "../../assets/icons";

export function Asterisks() {
    return (
        <div className={styles.asterikFrame}>
            {Array(4)
                .fill(0)
                .map((_, index) => (
                    <div key={index}>
                        <AsterikIcon />
                    </div>
                ))}
        </div>
    );
}
