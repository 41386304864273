import * as React from "react";
import { CalendarIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

interface DatePickerProps {
    selected: Date | undefined;
    onChange: (date: Date | undefined) => void;
    disabled?: boolean;
}

export function DatePicker({ selected, onChange, disabled }: DatePickerProps) {
    const [date, setDate] = React.useState<Date | undefined>(selected);

    React.useEffect(() => {
        setDate(selected);
    }, [selected]);

    const handleDateChange = (date: Date | undefined) => {
        setDate(date);
        onChange(date);
    };

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={"outline"}
                    className={cn(
                        "w-[209px] justify-start text-left font-normal",
                        !date && "text-muted-foreground",
                    )}
                    disabled={disabled}
                >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {date ? format(date, "PPP") : <span>Pick a date</span>}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                    mode="single"
                    selected={date}
                    onSelect={handleDateChange}
                    initialFocus
                    disabled={disabled}
                />
            </PopoverContent>
        </Popover>
    );
}
