import { Button } from "@/components/ui/button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Spinner } from "@/components/ui/spinner";
import useGoogleAutocomplete from "@/hooks/useGoogleAutocomplete";
import { cn } from "@/lib/utils";
import {
    useCreateAddressMutation,
    useGetMyDetailsQuery,
    useGetUserbyTypeQuery,
    useInviteUsersMutation,
} from "@/redux/rtk-query/authApis";
import {
    useCreateBuildingEntryMutation,
    useUpdateBuildingEntryMutation,
} from "@/redux/rtk-query/properties";
import {
    useCreateJobFileMetadataMutation,
    useUploadToAWSMutation,
} from "@/redux/rtk-query/uploadApis";
import { TInviteOwnerArgs, TSetState } from "@/types";
import { catchAll } from "@/utils/catch";
import { PROPERTY_TYPES } from "@/utils/constantData";
import { getAddressFromGoogleObject } from "@/utils/getAddressFromGoogleObject";
import { zodResolver } from "@hookform/resolvers/zod";
import { Check, ChevronDown, FileText, Image, LogOut, Upload, UserRound, X } from "lucide-react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { z } from "zod";
import { TAddPropertyPage } from ".";

import COUNTRY_JSON from "../../../../jsonData/country.json";
import AutoCompleteAddress from "@/components/common/address";
import { getCity } from "@/utils/commonutils";
import { useNavigate } from "react-router-dom";

type TPropertyDetails = {
    propertyName: string;
    postCode: string;
    address: string;
    propertyType: string;
    propertyOwner: string;
    propertyOwnerPhoneNumber: string;
    propertyOwnerName: string;
    propertyOwnerEmail: string;
    images: string[];
    files: string[];
    city: string;
    country: string;
    ownerId: string;
    id: string;
};

type TProps = {
    onChangePage?: (page: TAddPropertyPage) => void;
    setBuildingId?: TSetState<string>;
    setAddressId?: TSetState<string>;
    setImages?: TSetState<string[]>;
    propertyDetails?: TPropertyDetails;
};

const FormSchema = z.object({
    propertyName: z.string().min(1, "Property name is required"),
    postCode: z.string().min(1, "Post code is required"),
    address: z.string().min(3, "Address is required"),
    propertyType: z.string().min(1, "Property type is required"),
    propertyOwner: z.string().min(1, "Property owner is required"),
    country: z.string().min(1, "Country is required"),
    images: z.array(z.string()).min(1, "At least one property image is required"),
    files: z.array(z.string()),
    city: z.string().min(1, "City is required"),

    propertyOwnerPhoneNumber: z.string(),
    propertyOwnerName: z.string(),
    propertyOwnerEmail: z.string(),
});

const Countries = Object.entries(COUNTRY_JSON.Results).map((country) => country[1].Name);

export const PropertyForm = ({
    onChangePage,
    setBuildingId,
    setAddressId,
    setImages,
    propertyDetails,
}: TProps) => {
    const navigate = useNavigate();
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            propertyName: "",
            postCode: "",
            address: "",
            propertyType: "",
            propertyOwner: "",
            propertyOwnerPhoneNumber: "",
            propertyOwnerName: "",
            propertyOwnerEmail: "",
            images: [],
            files: [],
            city: "",
            country: "",
        },
    });

    useEffect(() => {
        if (propertyDetails) {
            form.reset({
                propertyName: propertyDetails?.propertyName || "",
                postCode: propertyDetails?.postCode || "",
                address: propertyDetails?.address || "",
                propertyType: propertyDetails?.propertyType || "",
                propertyOwner: propertyDetails?.propertyOwner || "",
                propertyOwnerPhoneNumber: propertyDetails?.propertyOwnerPhoneNumber || "",
                propertyOwnerName: propertyDetails?.propertyOwnerName || "",
                propertyOwnerEmail: propertyDetails?.propertyOwnerEmail || "",
                images: propertyDetails?.images || [],
                files: propertyDetails?.files || [],
                city: propertyDetails?.city || "",
                country: propertyDetails?.country || "",
            });
            form.setValue("propertyOwner", propertyDetails?.ownerId);
            setAddressSelected(true);
            setFileNames((prev) => [...prev, ...propertyDetails?.files]);
        }
    }, [propertyDetails, form]);

    const [createOwner, setCreateOwner] = useState(false);
    const [addressSelected, setAddressSelected] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const [fileNames, setFileNames] = useState<string[]>([]);

    const [uploadImageToAWS, { isLoading: isImageUploading }] = useUploadToAWSMutation();
    const [createAddress, { isLoading: createAddressLoading }] = useCreateAddressMutation();
    const [createBuilding, { isLoading: createBuildingLoading }] = useCreateBuildingEntryMutation();
    const [updateBuilding, { isLoading: updateLoading }] = useUpdateBuildingEntryMutation();
    const [createFileMetadata, { isLoading: createFileMetadataLoading }] =
        useCreateJobFileMetadataMutation();
    const [inviteOwner, { isLoading: inviteOwnerLoading }] = useInviteUsersMutation();

    const { data: propertyOwnersData } = useGetUserbyTypeQuery({
        userType: "HOMEOWNER",
        status: "",
    });
    const { data: myDetails } = useGetMyDetailsQuery();
    const { autocompleteInputRef, place } = useGoogleAutocomplete({
        name: "address",
        handleUserDetails: (e) => {
            form.setValue("address", e.target.value);
            setAddressSelected(!!e.target.value);
        },
        showCompleteDetails: true,
    });

    useEffect(() => {
        if (place) {
            const city = getCity(place.address_components ?? []);
            form.setValue("city", city || "");
        }
    }, [form, place]);

    const onClickSaveProperty = async (data: z.infer<typeof FormSchema>) => {
        if (!myDetails?.companyId) {
            toast.error("Token expired. Please login again");
            return;
        }

        let addressId = "";

        if (place) {
            const addressObj = getAddressFromGoogleObject(place);

            const addressResponse = await catchAll(createAddress(addressObj).unwrap());

            if (addressResponse.error) {
                toast.error("Failed to create entry for building");
                return;
            }

            addressId = addressResponse.data._id;
        }

        if (propertyDetails) {
            const response = await catchAll(
                updateBuilding({
                    name: data.propertyName,
                    companyId: myDetails?.companyId,
                    ownerId: data.propertyOwner,
                    images: data.images,
                    files: data.files,
                    category: data.propertyType,
                    userType: "PROPERTY_MANAGER",
                    id: propertyDetails.id,
                    navigate: undefined,
                    ...(addressId ? { addressId } : {}),
                }).unwrap(),
            );
            if (response.error) {
                toast.error("Failed to update building");
                return;
            }
            navigate("/property-management/properties");
        } else {
            const response = await catchAll(
                createBuilding({
                    addressId,
                    companyId: myDetails?.companyId,
                    files: data.files,
                    images: data.images,
                    category: data.propertyType,
                    name: data.propertyName,
                    ownerId: data.propertyOwner,
                }).unwrap(),
            );

            if (response.error) {
                toast.error("Failed to create building");
                return;
            }

            const refId = response.data._id;

            if (data.files.length > 0) {
                const promises = data.files.map((file, index) => {
                    return createFileMetadata({
                        createdBy: myDetails?._id,
                        fileName: fileNames[index],
                        filePath: file,
                        group: "properties_files",
                        refId: refId,
                        status: "NEW",
                        tag: "properties_files",
                        type: "PROPERTY",
                    }).unwrap();
                });

                const fileMetadataResult = await catchAll(Promise.all(promises));
                if (fileMetadataResult.error) {
                    toast.error("Failed to create file metadata");
                    return;
                }
            }

            if (onChangePage) {
                onChangePage("units");
            }
            if (setBuildingId) {
                setBuildingId(refId);
            }
            if (setAddressId) {
                setAddressId(addressId);
            }
            if (setImages) {
                setImages(data.images);
            }
        }
    };

    const onClickSelectOwner = (ownerId: string) => {
        const selectedOwner = propertyOwnersData?.data.find((owner: any) => owner._id === ownerId);

        if (selectedOwner) {
            form.setValue("propertyOwner", ownerId);
            form.setValue(
                "propertyOwnerName",
                `${selectedOwner.firstName} ${selectedOwner.lastName}`,
            );
            form.setValue("propertyOwnerPhoneNumber", selectedOwner.phone || "");
            form.setValue("propertyOwnerEmail", selectedOwner.email || "");
        }
    };

    const onClickSaveOwner = async () => {
        if (!myDetails?.companyId) {
            toast.error("Token expired. Please login again");
            return;
        }
        const { propertyOwnerEmail, propertyOwnerName, propertyOwnerPhoneNumber } =
            form.getValues();

        if (!propertyOwnerName) {
            form.setError("propertyOwnerName", {
                type: "required",
                message: "Property owner is required",
            });
            return;
        }

        if (!propertyOwnerEmail) {
            form.setError("propertyOwnerEmail", {
                type: "required",
                message: "Property owner email is required",
            });
            return;
        }

        if (!propertyOwnerPhoneNumber) {
            form.setError("propertyOwnerPhoneNumber", {
                type: "required",
                message: "Property owner number is required",
            });
            return;
        }

        const firstName = propertyOwnerName.split(" ")[0];
        const lastName = propertyOwnerName.split(" ").slice(1).join(" ");

        const payload: TInviteOwnerArgs = {
            email: propertyOwnerEmail,
            firstName,
            lastName,
            userType: "HOMEOWNER",
            companyId: myDetails?.companyId,
            phoneNumber: propertyOwnerPhoneNumber,
            status: "INVITED",
        };

        const response = await catchAll(inviteOwner(payload).unwrap());

        if (response.error) {
            toast.error("Failed to create owner");
            return;
        }

        setCreateOwner(false);
        form.setValue("propertyOwnerEmail", "");
        form.setValue("propertyOwnerPhoneNumber", "");
        form.setValue("propertyOwnerName", "");
    };

    const handleFileChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>, type: "images" | "files") => {
            if (!e.target?.files) return;

            const { files } = e.target;

            const fileArray = Array.from(files);
            const formItems = type === "images" ? form.watch("images") : form.watch("files");
            const remainingSlots = 10 - formItems.length;

            if (fileArray.length > remainingSlots) {
                toast.error("Max upload limit reached!");
                e.target.value = "";
                return;
            }

            const uploadedUrls: any = [];

            const filePromises = fileArray.map(async (file) => {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("folder", "properties");
                const response = await catchAll(uploadImageToAWS(formData).unwrap());

                if (response.error) {
                    toast.error("Something went wrong.");
                    e.target.value = "";
                    return;
                }

                const url = response?.data?.url;

                if (url) {
                    uploadedUrls.push(url);
                    return url;
                } else {
                    console.error("Image URL not found in response:", response);
                    return null;
                }
            });

            await catchAll(Promise.all(filePromises));
            const items = [...formItems, ...uploadedUrls];
            e.target.value = "";

            if (type === "images") {
                form.setValue("images", items);
            } else {
                form.setValue("files", items);
                setFileNames((prev) => [...prev, ...fileArray.map((file) => file.name)]);
            }
        },
        [form, uploadImageToAWS],
    );

    return (
        <section>
            <h2 className="font-medium text-lg leading-[22px] text-[#09090B] mb-1">
                Property Details
            </h2>
            <p className="text-sm leading-[17px] text-zinc-500 mb-6">
                Add images, name and type of your property
            </p>

            <div className="mb-6">
                <Label className="mb-2 text-[#09090B]">Property Image</Label>
                <div className="flex gap-2 flex-wrap">
                    {form
                        .watch("images")
                        .filter((src) => src)
                        .map((src, i) => (
                            <img
                                key={src + i}
                                src={src}
                                alt="property"
                                className="w-[151px] h-[120px] rounded-lg object-cover"
                            />
                        ))}

                    <label className="w-[151px] h-[120px] rounded-lg flex flex-col items-center justify-center cursor-pointer border border-dashed border-[#E4E4E7]">
                        {isImageUploading ? (
                            <Spinner className="text-zinc-400" />
                        ) : (
                            <Fragment>
                                <Image className="mb-2" />
                                <p className="text-xs leading-4 tracking-[0.4px] text-zinc-500">
                                    Upload photo
                                </p>
                                <input
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    onChange={(e) => handleFileChange(e, "images")}
                                    className="focus-visible:ring-gray-200 hidden"
                                    disabled={isImageUploading}
                                />
                            </Fragment>
                        )}
                    </label>
                </div>
            </div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onClickSaveProperty)}>
                    <FormField
                        control={form.control}
                        name="propertyType"
                        render={({ field }) => {
                            const selectedType = PROPERTY_TYPES.find(
                                (type) => type.value === field.value,
                            )?.label;
                            return (
                                <FormItem className="mb-10">
                                    <FormLabel className="mb-2 text-[#09090B]">
                                        Property Type
                                    </FormLabel>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <FormControl className="py-5 text-gray-500">
                                            <SelectTrigger className="w-[310px]">
                                                <SelectValue
                                                    placeholder={
                                                        !selectedType && "Select property type"
                                                    }
                                                >
                                                    {selectedType && selectedType}
                                                </SelectValue>
                                            </SelectTrigger>
                                        </FormControl>

                                        <SelectContent>
                                            <p className="p-2 -ml-1 -mr-1 border-b border-zinc-200 font-semibold text-sm leading-[17px] text-[#09090B]">
                                                Commercial
                                            </p>

                                            {PROPERTY_TYPES.filter(
                                                (type) => type.category === "commercial",
                                            ).map((type) => (
                                                <SelectItem key={type.value} value={type.value}>
                                                    {type.label}
                                                </SelectItem>
                                            ))}

                                            <p className="p-2 -ml-1 -mr-1 border-b border-t border-zinc-200 font-semibold text-sm leading-[17px] text-[#09090B]">
                                                Residential
                                            </p>

                                            {PROPERTY_TYPES.filter(
                                                (type) => type.category === "residential",
                                            ).map((type) => (
                                                <SelectItem key={type.value} value={type.value}>
                                                    {type.label}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />

                    <FormField
                        control={form.control}
                        name="propertyName"
                        render={({ field }) => (
                            <FormItem className="mb-8">
                                <FormLabel className="mb-2 text-[#09090B]">Property Name</FormLabel>
                                <FormControl>
                                    <Input placeholder="Enter property name" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <AutoCompleteAddress
                        control={form.control}
                        watch={form.watch}
                        setValue={form.setValue}
                        autocompleteInputRef={autocompleteInputRef}
                        addressSelected={addressSelected}
                        countries={Countries}
                        setPopoverOpen={setPopoverOpen}
                        popoverOpen={popoverOpen}
                    />

                    <h2 className="font-medium text-lg leading-[22px] text-[#09090B] mb-1">
                        Owner Details
                    </h2>
                    <p className="text-sm leading-[17px] text-zinc-500 mb-6">Add property owner</p>

                    {!form.watch("propertyOwner") && !createOwner ? (
                        <FormField
                            control={form.control}
                            name="propertyOwner"
                            render={({ field }) => (
                                <FormItem className="mb-16">
                                    <FormLabel className="mb-2 text-[#09090B]">Owner</FormLabel>
                                    <Popover>
                                        <PopoverTrigger asChild>
                                            <Button
                                                variant="outline"
                                                role="combobox"
                                                className="border shadow-none border-zinc-200 font-normal text-gray-500 hover:text-gray-500  p-5 w-[310px] justify-between flex px-3"
                                            >
                                                {field.value
                                                    ? form.watch("propertyOwner")
                                                    : "Select property owner"}
                                                <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent className="w-[310px] p-0">
                                            <Command className="relative">
                                                <CommandInput placeholder="Search owner..." />
                                                <CommandList>
                                                    <CommandEmpty>No owner found</CommandEmpty>
                                                    <CommandGroup className="pb-11">
                                                        {propertyOwnersData &&
                                                            propertyOwnersData?.data.length > 0 &&
                                                            propertyOwnersData?.data.map(
                                                                (owner: any) => {
                                                                    const ownerName =
                                                                        `${owner?.firstName ?? ""} ${owner?.lastName ?? ""}`.trim();
                                                                    return (
                                                                        <CommandItem
                                                                            key={owner._id}
                                                                            value={ownerName}
                                                                            onSelect={() =>
                                                                                onClickSelectOwner(
                                                                                    owner._id,
                                                                                )
                                                                            }
                                                                        >
                                                                            <Check
                                                                                className={cn(
                                                                                    "mr-2 h-4 w-4",
                                                                                    form.watch(
                                                                                        "propertyOwner",
                                                                                    ) === owner._id
                                                                                        ? "opacity-100"
                                                                                        : "opacity-0",
                                                                                )}
                                                                            />
                                                                            {ownerName}
                                                                        </CommandItem>
                                                                    );
                                                                },
                                                            )}
                                                    </CommandGroup>
                                                </CommandList>
                                                <div
                                                    onClick={() => setCreateOwner(true)}
                                                    className="absolute bottom-0 left-0 right-0 p-3 bg-white flex items-center space-x-2 border-t border-zinc-200 z-50 cursor-pointer"
                                                >
                                                    <LogOut size={16} className={"text-zinc-950"} />
                                                    <p className="text-sm leading-[17px] text-[#09090B]">
                                                        Add New Owner
                                                    </p>
                                                </div>
                                            </Command>
                                        </PopoverContent>
                                    </Popover>

                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    ) : form.watch("propertyOwner") && !createOwner ? (
                        <div className="flex items-center space-x-4 mb-16">
                            <div className="rounded-full h-16 w-16 flex items-center justify-center bg-[#F6F8FA]">
                                {(() => {
                                    const owner = propertyOwnersData?.data?.find(
                                        (owner: any) => owner._id === form.watch("propertyOwner"),
                                    );

                                    const ownerImage =
                                        owner?.imageUrl ?? "https://via.placeholder.com/500x500";

                                    return ownerImage ? (
                                        <img
                                            src={ownerImage}
                                            alt="Use"
                                            className="rounded-full object-cover h-16 w-16"
                                        />
                                    ) : (
                                        <UserRound className="text-[#818898]" />
                                    );
                                })()}
                            </div>
                            <p className="font-medium text-lg text-black">
                                {(() => {
                                    const owner = propertyOwnersData?.data?.find(
                                        (owner: any) => owner._id === form.watch("propertyOwner"),
                                    );

                                    const ownerName =
                                        `${owner?.firstName ?? ""} ${owner?.lastName ?? ""}`.trim();

                                    return ownerName;
                                })()}
                            </p>
                            <div
                                className="bg-white h-10 w-10 flex items-center justify-center cursor-pointer rounded-md border border-zinc-200"
                                onClick={() => {
                                    form.setValue("propertyOwner", "");
                                    setCreateOwner(false);
                                }}
                            >
                                <X size={16} />
                            </div>
                        </div>
                    ) : (
                        <div className="mb-16 border border-zinc-200 p-6">
                            <FormField
                                control={form.control}
                                name="propertyOwnerName"
                                render={({ field }) => (
                                    <FormItem className="mb-6">
                                        <FormLabel className="mb-2 text-[#09090B]">
                                            Owner Name
                                        </FormLabel>
                                        <FormControl>
                                            <Input placeholder="Enter owner name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <div className="flex items-center w-full space-x-8 mb-6">
                                <FormField
                                    control={form.control}
                                    name="propertyOwnerPhoneNumber"
                                    render={() => (
                                        <FormItem className="flex-1 relative">
                                            <FormLabel className="mb-2 text-[#09090B]">
                                                Phone Number
                                            </FormLabel>
                                            <FormControl>
                                                <div>
                                                    <PhoneInput
                                                        country={"gb"}
                                                        countryCodeEditable={false}
                                                        placeholder="Enter number"
                                                        onChange={(value) =>
                                                            form.setValue(
                                                                "propertyOwnerPhoneNumber",
                                                                value,
                                                            )
                                                        }
                                                        inputProps={{
                                                            required: true,
                                                        }}
                                                        inputClass="!pl-[70px] !w-full !h-11 !rounded-md !border !border-solid !border-input !bg-background !px-3 !py-3 !text-sm !ring-offset-background !file:border-0 !file:bg-transparent !file:text-sm !file:font-medium !placeholder:text-muted-foreground !focus-visible:outline-none !focus-visible:ring-2 !focus-visible:ring-ring !focus-visible:ring-offset-2 !disabled:cursor-not-allowed !disabled:opacity-50 flex"
                                                    />
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="propertyOwnerEmail"
                                    render={({ field }) => (
                                        <FormItem className="flex-1">
                                            <FormLabel className="mb-2 text-[#09090B]">
                                                Email
                                            </FormLabel>
                                            <FormControl>
                                                <Input placeholder="Enter owner email" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="flex space-x-2 items-center">
                                <Button
                                    type="button"
                                    variant={"outline"}
                                    onClick={onClickSaveOwner}
                                >
                                    Save Owner{" "}
                                    {inviteOwnerLoading && (
                                        <Spinner className="ml-2 text-zinc-500" size={"small"} />
                                    )}
                                </Button>
                                <Button
                                    type="button"
                                    variant={"outline"}
                                    onClick={() => setCreateOwner(false)}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    )}

                    <h2 className="font-medium text-lg leading-[22px] text-[#09090B] mb-1">
                        Property Documents
                    </h2>
                    <p className="text-sm leading-[17px] text-zinc-500 mb-6">
                        Add property documents
                    </p>

                    <label className="w-full h-[132px] rounded-lg flex flex-col items-center justify-center cursor-pointer mb-2">
                        <div className="w-full h-full rounded-lg bg-[#F5F5F5] flex flex-col items-center justify-center cursor-pointer ">
                            <span className="rounded-full p-[14px] flex items-center justify-center mb-2 bg-[#E3E3E3]">
                                <Upload />
                            </span>
                            <p className="text-xs leading-4 text-[#111111]">Upload Files</p>
                            <input
                                type="file"
                                multiple
                                onChange={(e) => handleFileChange(e, "files")}
                                className="focus-visible:ring-gray-200 hidden"
                                disabled={isImageUploading}
                            />
                        </div>
                    </label>

                    <div className="flex flex-col space-y-2 mb-10">
                        {form.watch("files")?.map((file, index) => {
                            const getHeading = (str: string) =>
                                str?.split?.(",")?.pop()?.split("/")?.pop();
                            return (
                                <div
                                    key={file}
                                    className="flex items-center justify-between border border-[#00000012] p-4 rounded-md"
                                >
                                    <div className="flex items-center space-x-2">
                                        <FileText />
                                        <span className="font-medium text-sm leading-[17px] text-black">
                                            {getHeading(fileNames[index])}
                                        </span>
                                    </div>
                                    <X
                                        size={24}
                                        className="cursor-pointer"
                                        onClick={() => {
                                            const files = form
                                                .getValues("files")
                                                .filter((_, i) => index !== i);
                                            form.setValue("files", files);

                                            setFileNames((prev) =>
                                                prev.filter((_, i) => i !== index),
                                            );
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <Button
                        className="mb-16 bg-[#000]"
                        disabled={
                            createAddressLoading ||
                            createBuildingLoading ||
                            createFileMetadataLoading ||
                            updateLoading
                        }
                    >
                        {(createAddressLoading ||
                            updateLoading ||
                            createBuildingLoading ||
                            createFileMetadataLoading) && (
                            <Spinner size={"small"} className="mr-2 text-zinc-100" />
                        )}
                        {propertyDetails ? "Update & Save" : "Save & Continue"}
                    </Button>
                </form>
            </Form>
        </section>
    );
};
