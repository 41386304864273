import { useState } from "react";

import { TUser } from "@/types";

import PasswordForm from "./PasswordForm";
import { ProfileForm } from "./ProfileForm";
import LBModal from "@/components/lBComponents/LBModal";

type TProp = {
    user: TUser | undefined;
};

export function ProfileSettings(props: TProp) {
    const [profileImage, setProfileImage] = useState(props.user?.imageUrl || "");
    const [editPasswordClicked, setEditPasswordClicked] = useState(false);

    return (
        <section className="py-8">
            <div>
                <h1 className="text-xl font-semibold">Personal Details</h1>
                <p className="text-sm text-gray-500">Edit your profile picture and name</p>
            </div>

            <div className="mt-6">
                <ProfileForm
                    user={props.user}
                    profileImage={profileImage}
                    setProfileImage={setProfileImage}
                />
            </div>

            <div className="mt-5">
                <h1 className="text-xl font-semibold">Security</h1>
                <p className="text-sm font-semibold text-gray-700 mt-3">Password</p>
                <div className="inline-flex items-center gap-4">
                    <div className="flex items-center gap-1">
                        <p>●</p>
                        <p>●</p>
                        <p>●</p>
                        <p>●</p>
                        <p>●</p>
                        <p>●</p>
                        <p>●</p>
                    </div>
                    <button
                        onClick={() => setEditPasswordClicked(true)}
                        className="text-sm font-medium text-gray-500 underline transition-all duration-200 hover:text-gray-900"
                    >
                        Edit Password
                    </button>
                </div>
            </div>

            <LBModal
                isOpen={editPasswordClicked}
                onClose={() => setEditPasswordClicked(false)}
                title="Change Password"
                titleClassName="text-lg font-semibold text-gray-800"
                closeButtonClassName="text-gray-500 hover:text-gray-700"
                contentClassName="w-[30rem]"
            >
                <div className="p-6">
                    <PasswordForm />
                </div>
            </LBModal>
        </section>
    );
}
