import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Separator } from "@/components/ui/separator";
import dayjs from "dayjs";
import { CiUser } from "react-icons/ci";
import { FaCommentSms } from "react-icons/fa6";
import { MdAlternateEmail } from "react-icons/md";
import style from "./callDetail.module.css";
import { cn } from "@/lib/utils";
import React, { useState } from "react";
import { useGetJobFileMetadataQuery } from "@/redux/rtk-query/uploadApis";
import AddWorkContainer from "@/pages/myJobs/components/AddWorkContainer";
import LBModal from "@/components/lBComponents/LBModal";

const CallerDetails = (props: any) => {
    const [workOrderDialog, setWorkOrderDialog] = React.useState(false);
    const [currentDocuments, setCurrentDocuments] = useState<any[] | undefined>(undefined);
    const { data: files, isFetching: isDocumentsLoading } = useGetJobFileMetadataQuery(
        props.jobDetails?._id || "",
        {
            skip: !props.jobDetails?._id,
        },
    );
    React.useEffect(() => {
        if (files && props.jobDetails) {
            setCurrentDocuments(files);
        }
    }, [files, props.jobDetails]);
    return (
        <div className="px-7">
            {workOrderDialog && (
                <LBModal
                    headerContainerClassName=""
                    closeButtonClassName="text-black"
                    isOpen={workOrderDialog}
                    onClose={() => {
                        setWorkOrderDialog(false);
                        setCurrentDocuments(undefined); // Clear documents when closing
                    }}
                    title={"Work Order Details"}
                    titleClassName="text-[16px] font-semibold"
                >
                    <AddWorkContainer
                        mode={"Preview"}
                        currentPreviewOrderInfo={{
                            ...props.jobDetails,
                            dueDate: props.jobDetails.dueDateTime,
                        }}
                        currentWorkOrderDocuments={currentDocuments}
                        currentDocumentsLoading={isDocumentsLoading}
                        editDetailsButton={false}
                    />
                </LBModal>
            )}
            <div
                className={cn(style.cardContainer, "rounded-lg bg-white drop-shadow-md py-3 px-4")}
            >
                <h1 className="font-medium">Caller Details</h1>
                <section className="mt-6">
                    <div className="flex items-center gap-3">
                        <div className="w-10 h-10 bg-gray-200 flex items-center justify-center rounded-full">
                            <CiUser />
                        </div>
                        <div>
                            <p>{props.callerName}</p>
                            <p className="text-sm text-gray-400">Caller #{props.id}</p>
                        </div>
                    </div>

                    <div className="mt-4 text-sm space-y-1">
                        <div className="flex items-center gap-3">
                            <p className="text-gray-500 max-w-24 w-full">Phone no.</p>
                            <p>{props.phoneNumber}</p>
                        </div>
                    </div>

                    <div className="mt-4 flex items-center gap-2">
                        <Button variant={"outline"} className="border border-gray-500">
                            Visit Profile
                        </Button>

                        {(props.phoneNumber || props.email) && (
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button
                                        variant="outline"
                                        className="border border-gray-500 flex items-center gap-1 cursor-pointer"
                                    >
                                        Send Messages
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-56">
                                    <DropdownMenuLabel>Messages</DropdownMenuLabel>
                                    <DropdownMenuSeparator />
                                    <DropdownMenuGroup>
                                        {props.phoneNumber && (
                                            <DropdownMenuItem
                                                className="flex gap-2 cursor-pointer"
                                                onClick={() => {
                                                    const smsLink = `sms:${encodeURIComponent(props.phoneNumber)}?body=`;
                                                    window.open(smsLink, "_blank");
                                                }}
                                            >
                                                <FaCommentSms />
                                                <span>Send SMS</span>
                                            </DropdownMenuItem>
                                        )}
                                        {props.email && (
                                            <DropdownMenuItem
                                                className="flex gap-2 cursor-pointer"
                                                onClick={() => {
                                                    const mailtoLink = `mailto:${encodeURIComponent("mian@lightwork.blue")}?subject=Subject&body=Your%20message%20here`;
                                                    window.open(mailtoLink, "_blank");
                                                }}
                                            >
                                                <MdAlternateEmail />
                                                <span>Send Email</span>
                                            </DropdownMenuItem>
                                        )}
                                    </DropdownMenuGroup>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        )}
                    </div>

                    {props.jobDetails && (
                        <>
                            <Separator className="my-6" />

                            <div>
                                <h1 className="text-md ">Job Created From Call</h1>
                                <div className="mt-4 rounded-md py-5 px-4 bg-gray-100">
                                    <h1 className="text-md font-semibold">
                                        {props?.jobDetails?.jobDetail?.description}
                                    </h1>
                                    <div className="mt-4 text-sm space-y-1">
                                        <div className="flex items-center gap-3">
                                            <p className="text-gray-500 max-w-24 w-full">Date</p>
                                            <p>
                                                {dayjs(
                                                    props?.jobDetails?.jobDetail?.createdAt,
                                                ).format("D MMM YYYY, hh:mm A")}
                                            </p>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <p className="text-gray-500 max-w-24 w-full">
                                                Contractor
                                            </p>
                                            <p>{`${
                                                props?.jobDetails?.assignedTo
                                                    ? `${props?.jobDetails?.assignedTo?.firstName} ${props?.jobDetails?.assignedTo?.lastName}`
                                                    : "Not Assigned"
                                            }`}</p>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <p className="text-gray-500 max-w-24 w-full">Urgency</p>
                                            <p>
                                                {props?.jobDetails?.jobPriority
                                                    .toLowerCase()
                                                    .replace(/_/g, " ")
                                                    .replace(/\b\w/g, (char: any) =>
                                                        char.toUpperCase(),
                                                    )}
                                            </p>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <p className="text-gray-500 max-w-24 w-full">Status</p>
                                            <p>
                                                {" "}
                                                {props?.jobDetails?.status
                                                    .toLowerCase()
                                                    .replace(/_/g, " ")
                                                    .replace(/\b\w/g, (char: any) =>
                                                        char.toUpperCase(),
                                                    )}
                                            </p>
                                        </div>
                                    </div>
                                    <Button
                                        className="mt-4 border px-4 border-gray-500"
                                        variant="outline"
                                        onClick={() => {
                                            setWorkOrderDialog(true);
                                        }}
                                    >
                                        See Work Order
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </section>
            </div>
        </div>
    );
};

export default CallerDetails;
