import CallDetailsCard from "./CallDetailsCard";
import { Separator } from "@/components/ui/separator";
import dayjs from "dayjs";
import AudioWaveform from "@/components/audioPlayer/AudioPlayer";
import WavAudioPlayer from "@/components/audioPlayer/WavAudioPlayer";
import AudioPlayer from "@/components/audioPlayer/AudioPlayer";

interface Message {
    _id: string;
    role: "user" | "agent" | "forwarded-call" | "Property Manager";
    call_log_id: string;
    content: string;
    timestamp: string;
    __v: number;
}

interface CallDetailsInfoProps {
    messages: Message[];
    summary: string;
    callDuration: string;
    callDate: string;
    reason: any | undefined;
    audio: string;
    callerName: string;
    forwardedCallAudio: string | undefined;
}

const CallDetailsInfo = ({
    messages,
    summary,
    callDuration,
    callDate,
    reason,
    audio,
    callerName,
    forwardedCallAudio,
}: CallDetailsInfoProps) => {
    return (
        <div className="px-8 py-6 bg-[#f6f6f6]">
            <CallDetailsCard
                heading="Call Details"
                data={{
                    callDate: dayjs(callDate).format("DD MMM YYYY, hh:mm A"),
                    callDuration: `${new Date(parseInt(callDuration) * 1000).toISOString().substr(11, 8)}`,
                    callReason: formatCallType(reason),
                }}
            />
            <div className="border mt-6 rounded-xl p-4">
                <h1 className="font-semibold">Call Summary</h1>
                <div className="px-4 py-2 text-sm mt-4 bg-white rounded-lg">
                    <p>{summary}</p>
                </div>
                <div className="mt-4">
                    {/* First Audio Waveform (Conversation with Felicity) */}
                    <AudioPlayer
                        audioFileUrl={audio}
                        callDuration={callDuration}
                        title="Conversation With Felicity AI"
                    />

                    {/* Conditional Rendering for Forwarded Call */}
                    {forwardedCallAudio ? (
                        <div className="mt-4">
                            <WavAudioPlayer
                                audioFileUrl={forwardedCallAudio}
                                callDuration={callDuration}
                                title="Conversation with Property Manager"
                            />
                        </div>
                    ) : null}
                </div>

                <Separator className="my-5" />

                <div className="mt-8">
                    <h1 className="font-semibold">Call Transcript</h1>
                    <section className="mt-2">
                        {messages?.map((message: Message) =>
                            message.role === "forwarded-call" ? (
                                <ForwardedCallMessage key={message._id} />
                            ) : (
                                <MessageCard
                                    key={message._id}
                                    message={message}
                                    callerName={callerName}
                                />
                            ),
                        )}
                    </section>
                </div>
            </div>
        </div>
    );
};

export default CallDetailsInfo;

interface MessageCardProps {
    message: Message;
    callerName: string;
}

const MessageCard = ({ message, callerName }: MessageCardProps) => {
    const isSender = message.role === "agent";
    const propertyManager = message.role === "Property Manager";

    return (
        <div className="mb-2">
            <div
                className={`flex items-center py-2 ${isSender || propertyManager ? "justify-end" : "justify-start"}`}
            >
                <div
                    className={`py-3 px-4 max-w-72 text-sm ${isSender || propertyManager ? "bg-gray-200 rounded-t-3xl rounded-r-3xl" : "border bg-white rounded-t-2xl rounded-l-2xl"}`}
                >
                    {message.content}
                </div>
            </div>
            <div
                className={`flex items-center ${isSender || propertyManager ? "justify-end" : "justify-start"}`}
            >
                <p className="text-xs text-gray-400">
                    {isSender ? "Felicity AI" : propertyManager ? "Property Manager" : callerName} -{" "}
                    {new Date(message.timestamp).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                    })}
                </p>
            </div>
        </div>
    );
};

const ForwardedCallMessage = () => (
    <div className="relative my-6 flex items-center">
        <div className="flex-grow border-t border-dashed border-border"></div>

        <div className="mx-4 bg-muted text-muted-foreground border border-border rounded-[10px] px-4 py-2 shadow-sm">
            <span className="text-sm font-medium tracking-wide">Call Forwarded</span>
        </div>

        <div className="flex-grow border-t border-dashed border-border"></div>
    </div>
);

const formatCallType = (callType: string) => {
    return callType
        .toLowerCase()
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};
