import { toast } from "react-toastify";
import { catchAll } from "../../utils/catch";
import { api } from "./api";
import { addPostJobImages } from "../features/postJobSlice";
import { TFileMetadata, TResponse, TSuccessUploadFile } from "../../types";

const uploadImagesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        uploadToAWS: builder.mutation<TSuccessUploadFile, FormData>({
            query: (body) => {
                return {
                    url: "file-manager/upload",
                    body,
                    method: "POST",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to upload");
                    return;
                }

                api.dispatch(addPostJobImages(response.data.data.url));
            },
        }),
    }),
});

const uploadFilesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        uploadFile: builder.mutation<TSuccessUploadFile, FormData>({
            query: (body) => {
                return {
                    url: "file-upload",
                    body,
                    method: "POST",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to upload file");
                    return;
                }
            },
        }),
    }),
});

const jobFileMetadata = api.injectEndpoints({
    endpoints: (builder) => ({
        createJobFileMetadata: builder.mutation<TResponse<TFileMetadata>, TFileMetadata>({
            invalidatesTags: ["JOB_FILES"],
            query: (body) => {
                return {
                    url: "jobs/files",
                    method: "POST",
                    body,
                };
            },
        }),
        getJobFileMetadata: builder.query<TResponse<TFileMetadata>[], string>({
            providesTags: ["JOB_FILES"],
            query: (refId) => `jobs/files?refId=${refId}`,
        }),
        deleteFile: builder.mutation<any, { id: string }>({
            query: (args) => {
                return {
                    url: `jobs/files/${args.id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ["WORK_ORDERS"],
        }),
    }),
});

export const { useUploadToAWSMutation } = uploadImagesApi;
export const { useUploadFileMutation } = uploadFilesApi;
export const {
    useCreateJobFileMetadataMutation,
    useGetJobFileMetadataQuery,
    useDeleteFileMutation,
} = jobFileMetadata;
