import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { BackButton } from "../../../components/common/backButton";
import { Dialog } from "../../../components/common/dialog";
import { Divider } from "../../../components/common/divider";
import { HorizontalStats } from "../../../components/common/horizontalStats";
import "../../../cssReset.css";
import { useSendMessageOrCreateConversation } from "../../../hooks/useSendMessageOrCreateConversation";
import { useGetMyDetailsQuery, useGetProfileByIdQuery } from "../../../redux/rtk-query/authApis";
import { UserType } from "../../../utils/interface/auth.interface";
import { Bio } from "./Bio";
import styles from "./contractorDetails.module.css";
import { ContractorProfileHeader } from "./ContractorProfileHeader";
import { DialogContent } from "./DialogContent";
import { JobTitleWithPrice } from "./JobTitleWithPrice";
import { SkillsContainer } from "./SkillsContainer.1";

const ContractorDetails = () => {
    const { id } = useParams<string>();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { data: myDetails } = useGetMyDetailsQuery();
    const { data: userProfile } = useGetProfileByIdQuery(id!, { skip: !id });

    const { onClick: onClickMessage } = useSendMessageOrCreateConversation();

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const UserBasedLogic = useMemo(() => {
        const DefaultValue = {
            hasBackButton: false,
            hasPadding: true,
            onClickMessage: () => onClickMessage(id),
            onClickBook: undefined,
            onClickShare: undefined,
            hasTabs: true,
            hasPastJobs: false,
        };

        if (!myDetails?.userType) return DefaultValue;

        switch (myDetails.userType) {
            case UserType.PROPERTY_MANAGER:
                return DefaultValue;
            default:
                return DefaultValue;
        }
    }, [id, myDetails?.userType, onClickMessage]);

    // const onClickBookButton = useCallback(() => {
    //     setIsDialogOpen(true);
    // }, []);

    const onClickDialogBookButton = useCallback(() => {
        setIsDialogOpen(false);
    }, []);

    return (
        <div className={styles.mainPage}>
            <Dialog
                heading={"Book contractor"}
                primaryButtonLabel={"Send Booking"}
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                onClickPrimary={onClickDialogBookButton}
                content={<DialogContent />}
            />

            {UserBasedLogic.hasBackButton && (
                <div className={styles.backButton}>
                    <BackButton />
                </div>
            )}
            {/* <div
                className={styles.backButton}
                onClick={() => {
                    navigate(-1);
                }}
            >
                {"<- Back"}
            </div> */}
            <section className={styles.profileDetailsContainer}>
                <ContractorProfileHeader
                    onClickBook={UserBasedLogic?.onClickBook}
                    onClickMessage={UserBasedLogic?.onClickMessage}
                />

                <div className={styles.contentWrapper}>
                    {userProfile?.qualificationName && (
                        <JobTitleWithPrice jobTitle={userProfile?.qualificationName} />
                    )}

                    <Divider top={40} bottom={40} />
                    <HorizontalStats size="lg" />

                    {userProfile?.bio && (
                        <>
                            <Divider top={40} bottom={40} />
                            <Bio bio={userProfile.bio} />
                        </>
                    )}

                    {userProfile?.skills && (
                        <>
                            <Divider top={40} bottom={40} />
                            <SkillsContainer skills={userProfile.skills} />
                        </>
                    )}
                </div>
            </section>
            {/* <Tabs handleTabClick={handleTabClick} activeTab={activeTab} /> */}
            {/* {activeTab === "Job History" && <PastJobsList />} */}
            {/* {activeTab === "Reviews" && <JobHistories profile={userProfile} />} */}
        </div>
    );
};

export default ContractorDetails;
