export function CopyIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
        >
            <path
                d="M16 8.25H9.25C8.42157 8.25 7.75 8.92157 7.75 9.75V16.5C7.75 17.3284 8.42157 18 9.25 18H16C16.8284 18 17.5 17.3284 17.5 16.5V9.75C17.5 8.92157 16.8284 8.25 16 8.25Z"
                stroke="#666D80"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M4.75 12.75H4C3.60218 12.75 3.22064 12.592 2.93934 12.3107C2.65804 12.0294 2.5 11.6478 2.5 11.25V4.5C2.5 4.10218 2.65804 3.72064 2.93934 3.43934C3.22064 3.15804 3.60218 3 4 3H10.75C11.1478 3 11.5294 3.15804 11.8107 3.43934C12.092 3.72064 12.25 4.10218 12.25 4.5V5.25"
                stroke="#666D80"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
