import { TContract } from "@/types";
import { ChangeEventHandler, memo, useEffect, useMemo } from "react";
import { TDropdownItem } from "../../../components/common/dropdown/Dropdown.types";
import { TCreateContractArgs } from "../../../redux/rtk-query/contractsApis";
import { useForm } from "react-hook-form";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import styles from "./PropertyManagementCreateContract.module.css";
import { DatePicker } from "@/components/calendar/DatePicker";
import { Spinner } from "@/components/ui/spinner";
import { Skeleton } from "@/components/ui/skeleton";

type TProps = {
    properties: TDropdownItem[];
    tenants: TDropdownItem[];
    formValues: TCreateContractArgs;
    setValuesToFormObject: (key: keyof TCreateContractArgs, value: string | number) => void;
    handleOnClickUploadFiles: ChangeEventHandler<HTMLInputElement>;
    isLoadingFileUpload: boolean;
    contractDetails: TContract[] | undefined;
    onSubmit: any;
    isCreatingContract: boolean;
};

const TENURES: TDropdownItem[] = [
    { value: "short-term", key: "Short term" },
    { value: "long-term", key: "Long term" },
    { value: "monthly", key: "Monthly" },
];

const formSchema = z.object({
    tenant: z.string().min(1, "Tenant is required"),
    propertyId: z.string().min(1, "propertyId is required"),
    bookingDate: z.date().refine((date) => date !== null, { message: "Booking date is required" }),
    startDate: z.date().refine((date) => date !== null, { message: "Start date is required" }),
    endDate: z.date().refine((date) => date !== null, { message: "End date is required" }),
    tenure: z.string().min(1, "Tenure is required"),
    rent: z.number().nonnegative("Rent must be a positive number"),
    securityDeposit: z.number().nonnegative("Security deposit must be a positive number"),
    otherCharges: z.number().nonnegative("Other charges must be a positive number"),
});

export const OccupancyInformation = memo((props: TProps) => {
    const { properties, tenants, setValuesToFormObject, contractDetails, onSubmit } = props;

    const disabled = Boolean(contractDetails);

    const formattedContract = useMemo(() => {
        if (!contractDetails || !contractDetails?.[0]) return null;

        const _contractDetails = contractDetails[0];

        const bookingDate = new Date(_contractDetails.bookingDate);
        const startDate = new Date(_contractDetails.startDate);
        const endDate = new Date(_contractDetails.endDate);

        const tenant = tenants.find((tenant) => tenant.value === _contractDetails.userId)?.value;
        const property = properties.find(
            (property) => property.value === _contractDetails.propertyId,
        )?.value;
        const tenure = TENURES.find((tenures) => tenures.value === _contractDetails.tenure)?.value;

        return {
            tenant,
            property,
            bookingDate,
            startDate,
            endDate,
            tenure,
            rent: _contractDetails.rent,
            securityDeposit: _contractDetails.securityDeposit,
            otherCharges: _contractDetails.otherCharges,
        };
    }, [contractDetails, properties, tenants]);
    console.log(formattedContract, disabled, tenants);

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            tenant: "",
            propertyId: "",
            bookingDate: new Date(),
            startDate: new Date(),
            endDate: new Date(),
            tenure: "",
            rent: 0,
            securityDeposit: 0,
            otherCharges: 0,
        },
    });

    useEffect(() => {
        if (formattedContract) {
            form.reset({
                tenant: formattedContract.tenant ?? "",
                propertyId: formattedContract.property ?? "",
                bookingDate: formattedContract.bookingDate ?? new Date(),
                startDate: formattedContract.startDate ?? new Date(),
                endDate: formattedContract.endDate ?? new Date(),
                tenure: formattedContract.tenure ?? "",
                rent: formattedContract.rent ?? 0,
                securityDeposit: formattedContract.securityDeposit ?? 0,
                otherCharges: formattedContract.otherCharges ?? 0,
            });
        }
    }, [formattedContract, form]);

    return (
        <div>
            <h2 className={styles.subHeading}>Occupancy information</h2>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <FormField
                        control={form.control}
                        name="tenant"
                        render={({ field }) => (
                            <FormItem className="mb-6">
                                <FormLabel className="text-[#000]">Tenant</FormLabel>

                                {!tenants.length ? (
                                    <Skeleton className="h-10 w-full bg-gray-300" />
                                ) : (
                                    <Select
                                        onValueChange={(value) => {
                                            field.onChange(value);
                                            setValuesToFormObject("userId", value);
                                        }}
                                        disabled={disabled}
                                        value={field.value}
                                    >
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select tenant" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {tenants.map((tenant) => (
                                                <SelectItem key={tenant.value} value={tenant.value}>
                                                    {tenant.key}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                )}
                                <FormMessage className="text-xs" />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="propertyId"
                        render={({ field }) => (
                            <FormItem className="mb-6">
                                <FormLabel className="text-[#000]">Property</FormLabel>

                                {!tenants.length ? (
                                    <Skeleton className="h-10 w-full bg-gray-300" />
                                ) : (
                                    <Select
                                        onValueChange={field.onChange}
                                        value={field.value}
                                        disabled={disabled}
                                    >
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select property" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {properties.map((property) => (
                                                <SelectItem
                                                    key={property.value}
                                                    value={property.value}
                                                >
                                                    {property.key}
                                                </SelectItem>
                                            ))}
                                            <FormMessage className="text-xs" />
                                        </SelectContent>
                                    </Select>
                                )}
                            </FormItem>
                        )}
                    />

                    <div className="flex gap-3">
                        <FormField
                            control={form.control}
                            name="bookingDate"
                            render={({ field }) => (
                                <FormItem className="mb-6">
                                    <FormLabel className="text-[#000]">Booking date</FormLabel>

                                    {!tenants.length ? (
                                        <Skeleton className="h-10 w-[209px] justify-start text-left font-normal bg-gray-300" />
                                    ) : (
                                        <FormControl>
                                            <DatePicker
                                                selected={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                disabled={disabled}
                                            />
                                        </FormControl>
                                    )}
                                    <FormMessage className="text-xs" />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="startDate"
                            render={({ field }) => (
                                <FormItem className="mb-6">
                                    <FormLabel className="text-[#000]">Start date</FormLabel>

                                    {!tenants.length ? (
                                        <Skeleton className="h-10 w-[209px] justify-start text-left font-normal bg-gray-300" />
                                    ) : (
                                        <FormControl>
                                            <DatePicker
                                                selected={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                disabled={disabled}
                                            />
                                        </FormControl>
                                    )}
                                    <FormMessage className="text-xs" />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="endDate"
                            render={({ field }) => (
                                <FormItem className="mb-6">
                                    <FormLabel className="text-[#000]">End date</FormLabel>

                                    {!tenants.length ? (
                                        <Skeleton className="h-10 w-[209px] justify-start text-left font-normal bg-gray-300" />
                                    ) : (
                                        <FormControl>
                                            <DatePicker
                                                selected={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                disabled={disabled}
                                            />
                                        </FormControl>
                                    )}
                                    <FormMessage className="text-xs" />
                                </FormItem>
                            )}
                        />
                    </div>

                    <FormField
                        control={form.control}
                        name="tenure"
                        render={({ field }) => (
                            <FormItem className="mb-5">
                                <FormLabel className="text-[#000]">Tenure</FormLabel>

                                {!tenants.length ? (
                                    <Skeleton className="h-10 w-full bg-gray-300" />
                                ) : (
                                    <Select
                                        onValueChange={field.onChange}
                                        value={field.value}
                                        disabled={disabled}
                                    >
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select tenure" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {TENURES.map((tenure) => (
                                                <SelectItem key={tenure.value} value={tenure.value}>
                                                    {tenure.key}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                )}
                                <FormMessage className="text-xs" />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="rent"
                        render={({ field }) => (
                            <FormItem className="mb-5">
                                <FormLabel className="text-[#000]">Rent</FormLabel>

                                {!tenants.length ? (
                                    <Skeleton className="h-10 w-full bg-gray-300" />
                                ) : (
                                    <FormControl>
                                        <Input
                                            type="number"
                                            placeholder="Enter rent"
                                            {...field}
                                            onChange={(e) => {
                                                const valueAsNumber = Number(e.target.value);
                                                field.onChange(valueAsNumber);
                                            }}
                                            disabled={disabled}
                                        />
                                    </FormControl>
                                )}
                                <FormMessage className="text-xs" />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="securityDeposit"
                        render={({ field }) => (
                            <FormItem className="mb-5">
                                <FormLabel className="text-[#000]">Security deposit</FormLabel>

                                {!tenants.length ? (
                                    <Skeleton className="h-10 w-full bg-gray-300" />
                                ) : (
                                    <FormControl>
                                        <Input
                                            type="number"
                                            placeholder="Enter security deposit"
                                            {...field}
                                            onChange={(e) => {
                                                const valueAsNumber = Number(e.target.value);
                                                field.onChange(valueAsNumber);
                                            }}
                                            disabled={disabled}
                                        />
                                    </FormControl>
                                )}
                                <FormMessage className="text-xs" />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="otherCharges"
                        render={({ field }) => (
                            <FormItem className="mb-5">
                                <FormLabel className="text-[#000]">Other charges</FormLabel>

                                {!tenants.length ? (
                                    <Skeleton className="h-10 w-full bg-gray-300" />
                                ) : (
                                    <FormControl>
                                        <Input
                                            type="number"
                                            placeholder="Enter other charges"
                                            {...field}
                                            onChange={(e) => {
                                                const valueAsNumber = Number(e.target.value);
                                                field.onChange(valueAsNumber);
                                            }}
                                            disabled={disabled}
                                        />
                                    </FormControl>
                                )}
                                <FormMessage className="text-xs" />
                            </FormItem>
                        )}
                    />

                    {!contractDetails && tenants.length > 0 && (
                        <div className="flex w-full justify-end">
                            <Button type="submit" disabled={props?.isCreatingContract}>
                                {props?.isCreatingContract && (
                                    <Spinner size="small" className="text-white mr-1" />
                                )}{" "}
                                Create Contract
                            </Button>
                        </div>
                    )}
                </form>
            </Form>
        </div>
    );
});
