import { ChangeEventHandler, KeyboardEventHandler } from "react";
import { LeadIcon } from "../../assets/icons";
import { SendArrowIcon } from "../../assets/icons/SendArrowIcon";
import styles from "./homeOwnerPostAJob.module.css";

type TProps = {
    value: string;
    isLoading: boolean;
    onChangeValue: ChangeEventHandler<HTMLInputElement>;
    onClickArrowButton: () => void;
};

export const AIPromptInput = (props: TProps) => {
    const { value, onChangeValue, onClickArrowButton, isLoading = false } = props;

    const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === "Enter") {
            onClickArrowButton();
        }
    };

    return (
        <div className={styles.aiPromptInputContainer}>
            <input
                className={styles.aiPromptInput}
                type="text"
                placeholder="Try “Book me a cleaner for 2 hours tomorrow”"
                value={value}
                onChange={onChangeValue}
                onKeyDown={handleKeyPress}
            />
            <div className={styles.aiPromptInputActionContainer}>
                {value && !isLoading && <SendArrowIcon onClick={onClickArrowButton} />}
                {isLoading && (
                    <span className={styles.sendArrowLoaderIcon}>
                        <LeadIcon />
                    </span>
                )}
            </div>
        </div>
    );
};
