import { MoreHorizontal } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface DataItem {
    text: string;
    Icon: React.ElementType;
    onClick?: (e?: React.MouseEvent) => void;
}
interface DropDownProps {
    field: DataItem[];
    bg?: boolean;
}

export const DropdownMenuDemo: React.FC<DropDownProps> = (props) => {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    className="h-8 w-8 p-0"
                    style={props?.bg ? { border: "1px solid black" } : {}}
                >
                    <span className="sr-only">Open menu</span>
                    <MoreHorizontal className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                {props.field.map((data: DataItem, index: number) => (
                    <DropdownMenuItem
                        key={index}
                        className="cursor-pointer flex items-center"
                        onClick={data.onClick}
                    >
                        <span>
                            <data.Icon className="mr-2 h-4 w-4" />
                        </span>

                        <p>{data.text}</p>
                    </DropdownMenuItem>
                ))}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};
