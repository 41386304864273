import { memo } from "react";
import styles from "./contractorDetails.module.css";

type TProps = {
    skills: string[];
};

export const SkillsContainer = memo(({ skills }: TProps) => (
    <div>
        <h3 className={styles.profileDescriptionHeading}>skills</h3>
        <div className={styles.skills}>
            {skills.map((skill) => (
                <span key={skill} className={styles.skillChip}>
                    {skill}
                </span>
            ))}
        </div>
    </div>
));
