import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog } from "../../components/common/dialog";
import { Heading } from "../../components/common/pageHeading";
import { DialogContent } from "./contractorDetails/DialogContent";
import { ContractorsList } from "./ContractorsList";
import styles from "./findContractors.module.css";
import "../../cssReset.css";

const FindContractors = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const navigate = useNavigate();

    const onClickBookButton = useCallback(() => {
        setIsDialogOpen(true);
    }, []);

    const onClickDialogBookButton = useCallback(() => {
        setIsDialogOpen(false);
    }, []);

    const onClickJobCard = useCallback(() => {
        navigate("/home-owner/contractor-details");
    }, []);

    return (
        <div>
            <Dialog
                heading={"Book contractor"}
                primaryButtonLabel={"Send Booking"}
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                onClickPrimary={onClickDialogBookButton}
                content={<DialogContent />}
            />
            <header className={styles.header}>
                <Heading as="h1" className={styles.pageHeading}>
                    Find Contractors
                </Heading>
                <p className={styles.pageDescription}>Explore the best contractors for your job</p>
            </header>
            {/* TODO: Add search with dropdown and simple dropdown  */}
            <div className={styles.searchContainer}>
                <p>Search with dropdown</p>
                <p>Dropdown</p>
            </div>
            <ContractorsList onClickBookButton={onClickBookButton} onClickCard={onClickJobCard} />
        </div>
    );
};

export default FindContractors;
