// src/features/languageSlice.js
import { createSlice } from "@reduxjs/toolkit";

type TInitialState = {
    propertyManagerSidebarSettingsOpen: boolean;
};

const InitialState: TInitialState = {
    propertyManagerSidebarSettingsOpen: false,
};

export const uiSlice = createSlice({
    name: "ui",
    initialState: InitialState,
    reducers: {
        setPropertyManagerSidebarSettingsOpen: (state, action) => {
            state.propertyManagerSidebarSettingsOpen = action.payload;
        },
    },
});

export const { setPropertyManagerSidebarSettingsOpen } = uiSlice.actions;

export default uiSlice.reducer;
