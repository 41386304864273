import { memo } from "react";
import { Button } from "../../../pages/postAJob/button";
import styles from "./smallJobCard.module.css";

type TProps = { onClickPrimaryButton: () => void; onClickLabel?: () => void };

export const Footer = memo((props: TProps) => {
    const { onClickPrimaryButton, onClickLabel } = props;
    return (
        <div className={styles.suggestionCardFooterContainer}>
            <p onClick={onClickLabel} className={styles.suggestionCardJobTitle}>
                House Cleaner
            </p>
            <Button onClick={onClickPrimaryButton}>Book</Button>
        </div>
    );
});
