import { ReactNode } from "react";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";

interface DialogDemoProps {
    open?: boolean;
    dialogTitle?: string;
    dialogDescription?: string;
    children?: ReactNode;
    onSave?: () => void;
    footerContent?: ReactNode;
    setOpen?: any;
    className?: string;
}

export function DialogModal({
    dialogTitle,
    dialogDescription,
    children,
    open,
    setOpen,
    className = "sm:max-w-[600px]",
}: DialogDemoProps) {
    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className={className}>
                <DialogHeader>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    {dialogDescription && (
                        <DialogDescription>{dialogDescription}</DialogDescription>
                    )}
                </DialogHeader>
                {children}
            </DialogContent>
        </Dialog>
    );
}
