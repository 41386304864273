import { toast } from "react-toastify";
import { TAccountCreationPayload } from "../../types";
import { catchAll } from "../../utils/catch";
import { api } from "./api";

const getAccount = api.injectEndpoints({
    endpoints: (builder) => ({
        getAccount: builder.query<any, string>({
            query: (data) => {
                return {
                    url: `payment/account-detail/${data}`,
                    // url: `payment/account-detail/acct_1PBaImGhpaJw8oCA`,
                    method: "GET",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to get account detail");
                    return;
                }
            },
        }),
    }),
});

const getAccountBalance = api.injectEndpoints({
    endpoints: (builder) => ({
        getAccountBalance: builder.query<any, string>({
            query: (data) => {
                return {
                    url: `payment/account-balance/${data}`,
                    // url: `payment/account-balance/acct_1PBaImGhpaJw8oCA`,
                    method: "GET",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to get account detail");
                    return;
                }
            },
        }),
    }),
});

const getAccountTransactions = api.injectEndpoints({
    endpoints: (builder) => ({
        getAccountTransactions: builder.query<any, string>({
            query: (data) => {
                return {
                    url: `payment/transactions/${data}`,
                    // url: `payment/transactions/acct_1PBaImGhpaJw8oCA`,s
                    method: "GET",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to get account transactions");
                    return;
                }
            },
        }),
    }),
});

const createAccount = api.injectEndpoints({
    endpoints: (builder) => ({
        createAccount: builder.mutation<any, TAccountCreationPayload>({
            query: (data) => {
                return {
                    url: `payment/account`,
                    body: data,
                    method: "POST",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Something Went wrong! lease try again later.");
                    return;
                }
            },
        }),
    }),
});

export const { useCreateAccountMutation } = createAccount;
export const { useLazyGetAccountQuery, useGetAccountQuery } = getAccount;
export const { useLazyGetAccountBalanceQuery, useGetAccountBalanceQuery } = getAccountBalance;
export const { useGetAccountTransactionsQuery } = getAccountTransactions;
