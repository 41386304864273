import { ComponentProps, forwardRef } from "react";
import InputField from "../../components/common/input/inputField";
import Label from "../../components/common/label";
import styles from "./homeOwnerPostAJob.module.css";

type TProps = {
    labelProps?: Omit<Parameters<typeof Label>[0], "type">;
    inputProps: Parameters<typeof InputField>[0];
    icon?: React.ReactNode;
    inputContainerProps?: ComponentProps<"div">;
};

export const InputWithLabel = forwardRef((props: TProps, ref: any) => {
    const { inputContainerProps, ...rest } = props;
    const containerClassName = inputContainerProps?.className ?? "";
    delete inputContainerProps?.className;
    return (
        <div className={styles.aiPromptInputField}>
            {rest.labelProps && (
                <div className={styles.label}>
                    {rest.labelProps && <Label {...rest.labelProps} type="plain-black" />}
                    {props.inputProps.required && <i className="ri-asterisk"></i>}
                </div>
            )}
            <div
                className={`${styles.aiPromptInputFieldContainer} ${containerClassName}`}
                {...inputContainerProps}
            >
                <InputField {...rest.inputProps} ref={ref} />
                {rest.icon && <span className={styles.aiPromptInputFieldIcon}>{rest.icon}</span>}
            </div>
        </div>
    );
});
