import React from "react";
import { useParams } from "react-router-dom";
import styles from "../../AiAssistant.module.css";
import { BackButton } from "../../../../../../components/common/backButton";
import { PhoneIcomingIcon } from "../../../../../../assets/icons/PhoneIncomingIcon";
import {
    useGetCallLogsQuery,
    useGetConversationsQuery,
} from "../../../../../../redux/rtk-query/aiApis";
import Skeleton from "react-loading-skeleton";
import { TCallLogs, TMessages } from "../../../../../../types";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { formatDateTime } from "@/utils/formateDateTime";
import { formatDuration } from "@/utils/formatDuration";
import { PhoneMissedIcon } from "@/assets/icons/PhoneMissedIcon";
import ReactAudioPlayer from "react-audio-player";

export const CallLogDetails: React.FC = () => {
    const { callId } = useParams();
    const { data: myDetails } = useGetMyDetailsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const { data: callLogs, isLoading: isCallLogsLoading } = useGetCallLogsQuery(
        {
            contractorId: myDetails?._id ?? "",
            limit: 10,
            page: 1,
        },
        { skip: !myDetails?._id, refetchOnMountOrArgChange: true },
    );
    const callDetail = callLogs?.filter((call: TCallLogs) => call._id === callId) ?? [];
    const { formattedDate, formattedTime } = formatDateTime(callDetail[0]?.call_date_created);
    const formattedDuration = formatDuration(parseInt(callDetail[0]?.call_duration));
    const { data: messages, isLoading } = useGetConversationsQuery(
        { callId: callId },
        { skip: !callId, refetchOnMountOrArgChange: true },
    );
    return (
        <div className={styles.aiCallLogWrapper}>
            <div className={styles.headerContainer}>
                <BackButton />
                <h6 className="capitalize">{callDetail[0]?.call_status}</h6>
                <span></span>
            </div>
            <div className={styles.detailContainer}>
                {isLoading && isCallLogsLoading ? (
                    <Skeleton height={100} width={"100%"} style={{ marginBottom: 19 }} />
                ) : (
                    <>
                        <div className={styles.userDetails}>
                            <div className={styles.name}>
                                <div
                                    className={`${styles.callType}  ${callDetail[0]?.call_status === "completed" ? styles.incoming : styles.missed} }`}
                                >
                                    {callDetail[0]?.call_status === "completed" && (
                                        <PhoneIcomingIcon />
                                    )}
                                    {callDetail[0]?.call_status === "no-answer" && (
                                        <PhoneMissedIcon />
                                    )}
                                </div>
                                <div className={styles.details}>
                                    <p>Phone Number</p>
                                    <h6>{callDetail[0]?.caller_phonenum}</h6>
                                </div>
                            </div>
                            <div className={styles.details}>
                                <p>Date</p>
                                <div>
                                    <span>{formattedDate}</span>{" "}
                                    <span className={styles.time}>{formattedTime}</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.callDetails}>
                            <div>
                                <p>Direction</p>
                                <h6 className="capitalize">{callDetail[0]?.call_direction}</h6>
                            </div>
                            <div>
                                <p>Call Type</p>
                                <h6 className="capitalize">{callDetail[0]?.call_type}</h6>
                            </div>
                            <div>
                                <p>Call Duration</p>
                                <h6>{formattedDuration}</h6>
                            </div>
                        </div>
                        <hr />
                        {callDetail && (
                            <>
                                <h6 className="mb-3 font-semibold">Call Recording</h6>
                                <div>
                                    <ReactAudioPlayer
                                        className="w-full"
                                        src={callDetail[0]?.recording_url}
                                        autoPlay
                                        controls
                                    />
                                </div>
                            </>
                        )}
                        <hr />
                        <div className={styles.transcriptContainer}>
                            <h6>Call Transcript</h6>
                            <div className={styles.conversationWrapper}>
                                {messages?.map((message: TMessages) => (
                                    <div
                                        key={message._id}
                                        className={
                                            styles[
                                                message.role === "agent" ? "leftCol" : "rightCol"
                                            ]
                                        }
                                    >
                                        <div
                                            className={`${styles.userInfo} ${message.role === "agent" ? styles.aiUser : ""}`}
                                        >
                                            {message.role === "agent" && (
                                                <div className={styles.userImg}>
                                                    <i className="ri-user-3-fill"></i>
                                                </div>
                                            )}
                                            <h6>
                                                {message.role === "agent"
                                                    ? "AI Agent"
                                                    : "Human’s response"}
                                            </h6>
                                        </div>
                                        <div
                                            className={`${styles.message} ${message.role === "user" && styles.response}`}
                                        >
                                            <p>{message.content}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
