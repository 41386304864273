import { ResizablePanel, ResizablePanelGroup } from "../ui/resizable";
import { Chats } from "@/pages/chats/chat";
import { useMobileDetection } from "@/utils/customHooks/useMobileDetection";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { useGetUserConversationsQuery } from "@/redux/rtk-query/chatApis";

interface ChatbotProps {
    handleClick: () => void;
}

const Chatbot: React.FC<ChatbotProps> = ({ handleClick }) => {
    const { data: conversationList } = useGetUserConversationsQuery();

    const isMobile = useMobileDetection();

    const conversationObject = conversationList?.find(
        (data) => data.conversationType === "AI_ASSISTANT_CHAT",
    );

    return (
        <div className="fixed bottom-[3rem] right-[4rem] w-full max-w-sm bg-white shadow-xl rounded-lg overflow-hidden border border-gray-300">
            <div className="w-full bg-black text-white p-4 flex items-start justify-between">
                <div className="flex">
                    <Avatar className="h-[3rem] w-[3rem] mr-3">
                        <AvatarImage src="" alt="@shadcn" />
                        <AvatarFallback className="text-[#000] text-xl">LA</AvatarFallback>
                    </Avatar>
                    <div>
                        <p className="text-lg font-semibold">LightWork AI</p>
                        <p className="text-sm">Online</p>
                    </div>
                </div>
                <div className="cursor-pointer flex text-white text-xl" onClick={handleClick}>
                    ⛌
                </div>
            </div>
            <div className="p-4 h-[35rem] overflow-y-auto">
                <ResizablePanelGroup
                    direction="horizontal"
                    onLayout={(sizes) => {
                        document.cookie = `react-resizable-panels:layout=${JSON.stringify(sizes)}`;
                    }}
                    className="h-full items-stretch"
                >
                    <ResizablePanel defaultSize={[30, 70][1]} minSize={30}>
                        <Chats
                            isSelected={conversationObject?._id}
                            conversation={conversationObject}
                            isMobile={isMobile}
                            isAiChat={true}
                            isTopBar={false}
                        />
                    </ResizablePanel>
                </ResizablePanelGroup>
            </div>
        </div>
    );
};

export default Chatbot;
