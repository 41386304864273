import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import HomeOwnerDashboard from "./pages/dashboard/homeOwner";
import PostAJob from "./pages/postAJob";
import ProtectedContractorRoute from "./utils/protect-contractor-route";
import ProtectedHomeOwnerRoute from "./utils/protect-homeowner-route";
import PropertyManagementRoute from "./utils/protect-propertymanager-route";
import ProtectedTenantsRoute from "./utils/protect-tenants-route";
import { AuthRedirects } from "./utils/service";
// import Loading from "./components/loading/index";
import { jwtDecode } from "jwt-decode";
import FindContractors from "./pages/findContractors";
import ContractorDetails from "./pages/findContractors/contractorDetails";
import HomeOwnerMyJobs from "./pages/myJobs";
import HomeOwnerJobDetails from "./pages/myJobs/jobDetails";
import ConfirmJobDetails from "./pages/postAJob/ConfirmJobDetails";
import PostAJobEditPage from "./pages/postAJob/postAJobForm";
import { TUSER_TYPE } from "./utils/interface/auth.interface";

import { TenantsLayout } from "./components/layouts/TenantsLayout";
import { Toaster } from "./components/ui/toaster";
import { cn } from "./lib/utils";
import { ChatLayout } from "./pages/chats/chat-layout";
import { AiAssistant } from "./pages/dashboard/contractor/aiAssistant";
import { Analytics } from "./pages/dashboard/contractor/aiAssistant/Analytics";
import { CallLogDetails } from "./pages/dashboard/contractor/aiAssistant/CallLogs/CallDetail";
import { Invoice } from "./pages/dashboard/contractor/aiAssistant/Invoice";
import { ManageProfile } from "./pages/dashboard/contractor/aiAssistant/ManageProfile";
import { SetPricing } from "./pages/dashboard/contractor/aiAssistant/SetPricing";
import { SetVoiceAssistant } from "./pages/dashboard/contractor/aiAssistant/SetVoiceAssistant";
import Calender from "@/pages/dashboard/contractor/calendar";
import AddProperty from "./pages/dashboard/propertyManagement/addProperty";
import { PropertyManagementAiAssistant } from "./pages/dashboard/propertyManagement/aiAssistant";
import JobDetailsQuotes from "./pages/myJobs/jobDetails/quotes";
import JobDetailQuoteDetails from "./pages/myJobs/jobDetails/quotes/details";
import Compliance from "./pages/propertyManagementCompliance";
import { PropertyManagementCreateContract } from "./pages/propertyManagementContracts/PropertyManagementCreateContract";
import PropertyDashBoard from "./pages/propertyManagementDashboard";
import { PropertyManagementKnowledgeBase } from "./pages/propertyManagementKnowledgeBase/PropertyManagementKnowledgeBase";
import { PropertyManagementMaintenance } from "./pages/propertyManagementMaintenance";
import { Properties } from "./pages/propertyManagementProperties";
import { PropertyManagementInviteTenants } from "./pages/propertyManagementTenants/propertyManagementInviteTenants";
import TenantDetails from "./pages/propertyManagementTenants/tenentDetails";
import { Settings } from "./pages/settings";
import styles from "./pages/settings/Settings.module.css";
import TeamSettings from "./pages/settings/teams";
import SiteUnderDevelopment from "./pages/siteUnderDevelopment";
import UploadJobImages from "./pages/uploadJobImages";
import InvoiceDetails from "./pages/uploadJobImages/InvoiceDetail";
import { PaymentForm } from "./pages/uploadJobImages/PaymentForm";
import { QuoteDetail } from "./pages/uploadJobImages/QuoteDetail";
import Quotes from "./pages/uploadJobImages/Quotes";
import { handleLoginRoute } from "./utils/handleLoginRoute";
import { Account } from "./pages/settings/account";
import ContractorDashboard from "./pages/dashboard/contractor/dashboard";
import CallDetails from "./pages/dashboard/propertyManagement/callDetails";
import { PropertyUnits } from "./pages/propertyManagementPropertyUnits";
import { AddUnit } from "./pages/dashboard/propertyManagement/addUnit";
import { ViewUnitAndMaintenance } from "./pages/dashboard/propertyManagement/viewUnitAndMaintenance";
import { TenantAndContractor } from "./components/customUi/TenantAndContractor";
import UpdateProperty from "./pages/dashboard/propertyManagement/updateProperty";
import UpdateUnit from "./pages/dashboard/propertyManagement/updateUnit";
import PropertyManagementLayouts from "./components/layouts/PropertyManagementLayouts";
import HomeOwnersLayout from "./components/layouts/HomeOwnersLayout";
import DashBoardslayout from "./components/layouts/DashBoardslayout";
import { ChatHomeOwnerLayout } from "./components/layouts/ChatHomeOwnerLayout";
import LandingLayout from "./components/layouts/LandingLayout";
import JobDetails from "./pages/dashboard/contractor/JobDetails";
import { ChatContractorLayout } from "./components/layouts/ChatContractorLayout";
import StateProvider from "./components/layouts/hooks/Dashboard.hooks";
interface CustomRouteWrapperProps {
    component: React.ComponentType<any>;
}

const Home = React.lazy(() => import("./pages/landing/home"));
const Signup = React.lazy(() => import("./pages/onboarding/signup"));
const Login = React.lazy(() => import("./pages/onboarding/login"));
const SetPassword = React.lazy(() => import("./pages/onboarding/setpassword"));
const MyJobs = React.lazy(() => import("@/pages/dashboard/contractor/Myjob"));
const NotFoundPage = React.lazy(() => import("./pages/notFoundPage"));

const CustomUserRouteWrapper: React.FC<CustomRouteWrapperProps> = ({
    component: Component,
    ...rest
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isAuthenticated = Boolean(localStorage.getItem("token"));
    const token = localStorage.getItem("token");
    let userType: number = 0;
    if (token) {
        userType = Number(jwtDecode(token).sub) || 0;
    }
    const queryParams = new URLSearchParams(location.search);
    const redirectURL = queryParams.get("redirectURL");
    const additionalProps = {
        location,
    };
    React.useEffect(() => {
        AuthRedirects(location, isAuthenticated);
    }, [isAuthenticated, redirectURL, location]);

    useEffect(() => {
        handleLoginRoute(navigate, userType as TUSER_TYPE);
    }, [navigate, userType]);

    return <Component {...rest} {...additionalProps} />;
};
const Router = () => {
    return (
        <StateProvider>
            <React.Suspense>
                <BrowserRouter>
                    {process.env.REACT_APP_IS_DEVELOP === "true" ? (
                        <Routes>
                            <Route path="/" element={<LandingLayout />}>
                                <Route index element={<Home />} />
                            </Route>
                            <Route
                                path="/signup"
                                element={<CustomUserRouteWrapper component={Signup} />}
                            />
                            <Route
                                path="/login"
                                element={<CustomUserRouteWrapper component={Login} />}
                            />
                            <Route
                                path="/set-password"
                                element={<CustomUserRouteWrapper component={SetPassword} />}
                            />
                            {/****************
                             * * * Upload Job Images Route * * *
                             ****************/}
                            <Route path="/ai-assistant/confirm-job" element={<UploadJobImages />} />
                            <Route path="/ai-assistant/payment" element={<PaymentForm />} />
                            <Route path="/ai-assistant/confirm-job/quotes" element={<Quotes />} />
                            <Route
                                path="/ai-assistant/confirm-job/quotes/:quoteId"
                                element={<QuoteDetail />}
                            />
                            <Route
                                path="/ai-assistant/confirm-job/quotes/invoice-detail"
                                element={<InvoiceDetails />}
                            />
                            {/****************
                             * * * Contractor Routes * * *
                             ****************/}
                            <Route
                                path="/contractor"
                                element={<ProtectedContractorRoute component={DashBoardslayout} />}
                            >
                                <Route index element={<Navigate replace to="dashboard" />} />
                                <Route path="dashboard" element={<ContractorDashboard />} />
                                {/* <Route path="find-jobs" element={<FindJobs />} />
                                <Route path="saved-jobs" element={<SavedJobs />} /> */}
                                <Route path="job-detail/:id" element={<JobDetails />} />
                                <Route path="my-jobs" element={<MyJobs />} />
                                <Route path="chat" element={<ChatLayout navCollapsedSize={8} />} />
                                <Route path="ai-assistant" element={<AiAssistant />} />
                                <Route
                                    path="teams"
                                    element={<TeamSettings className="w-[65%]" />}
                                />
                                <Route
                                    path="/contractor/user/:id"
                                    element={<ContractorDetails />}
                                ></Route>
                                <Route
                                    path="/contractor/jobs/:jobId"
                                    element={<HomeOwnerJobDetails />}
                                />
                                <Route path="/contractor/jobs" element={<HomeOwnerMyJobs />} />
                                <Route
                                    path="/contractor/jobs/:jobId"
                                    element={<HomeOwnerJobDetails />}
                                />
                                <Route
                                    path="/contractor/jobs/:jobId/quotes"
                                    element={<JobDetailsQuotes />}
                                />
                                <Route
                                    path="/contractor/jobs/:jobId/quotes/:quoteId"
                                    element={<JobDetailQuoteDetails />}
                                />

                                {/* <Route path="/contractor/dashboard" element={<Dashboard />} />
                                <Route path="/contractor/contracts" element={<Contracts />} />
                                <Route path="/contractor/workdiary" element={<WorkDiary />} />
                                <Route path="/contractor/reports" element={<Reports />} /> */}
                                <Route
                                    path="/contractor/settings"
                                    element={<Settings type="contractor" />}
                                />
                                <Route
                                    path="/contractor/ai-assistant/call-log/:callId"
                                    element={<CallLogDetails />}
                                />
                                <Route
                                    path="/contractor/ai-assistant/set-voice-assistant"
                                    element={<SetVoiceAssistant />}
                                />
                                <Route
                                    path="/contractor/ai-assistant/set-pricing"
                                    element={<SetPricing />}
                                />
                                <Route
                                    path="/contractor/ai-assistant/manage-profile"
                                    element={<ManageProfile />}
                                />
                                <Route
                                    path="/contractor/ai-assistant/invoices"
                                    element={<Invoice />}
                                />
                                <Route
                                    path="/contractor/ai-assistant/analytics"
                                    element={<Analytics />}
                                />
                                <Route path="/contractor/calendar" element={<Calender />} />
                                {/* ... add other protected sub-routes here */}
                                {/* ... add other protected sub-routes here */}
                            </Route>
                            {/****************
                             * * * Contractor Chats * * *
                             ****************/}
                            <Route
                                element={
                                    <ProtectedContractorRoute component={ChatContractorLayout} />
                                }
                            >
                                <Route path="chat" element={<ChatLayout navCollapsedSize={8} />} />
                            </Route>
                            {/****************
                             * * * Home-Owner Chats * * *
                             ****************/}
                            <Route
                                element={
                                    <ProtectedHomeOwnerRoute component={ChatHomeOwnerLayout} />
                                }
                            >
                                <Route
                                    path="/home-owner/chat"
                                    element={<ChatLayout navCollapsedSize={8} />}
                                />
                            </Route>
                            {/****************
                             * * * Home-Owner Routes * * *
                             ****************/}
                            <Route
                                element={<ProtectedHomeOwnerRoute component={HomeOwnersLayout} />}
                            >
                                <Route
                                    path="/home-owner/settings"
                                    element={
                                        <div className={styles.mainContent}>
                                            <Settings type="homeOwner" />
                                        </div>
                                    }
                                />
                                <Route
                                    path="/home-owner/user/:id"
                                    element={<ContractorDetails />}
                                />
                                <Route
                                    path="/home-owner/dashboard"
                                    element={<HomeOwnerDashboard />}
                                />
                                <Route
                                    path="/home-owner/find-contractors"
                                    element={<FindContractors />}
                                ></Route>
                                <Route
                                    path="/home-owner/contractor-details"
                                    element={<ContractorDetails />}
                                ></Route>
                                <Route path="/home-owner/jobs" element={<HomeOwnerMyJobs />} />
                                <Route
                                    path="/home-owner/jobs/:jobId"
                                    element={<HomeOwnerJobDetails />}
                                />
                                <Route
                                    path="/home-owner/jobs/:jobId/quotes"
                                    element={<JobDetailsQuotes />}
                                />
                                <Route
                                    path="/home-owner/jobs/:jobId/quotes/:quoteId"
                                    element={<JobDetailQuoteDetails />}
                                />
                            </Route>
                            {/****************
                             * * * Property Managers Routes * * *
                             ****************/}
                            <Route
                                path="/property-management"
                                element={
                                    <PropertyManagementRoute
                                        component={PropertyManagementLayouts}
                                    />
                                }
                            >
                                <Route
                                    path="/property-management/work-order/workOrder-details/:jobId"
                                    element={<HomeOwnerJobDetails />}
                                />
                                <Route
                                    path="knowledge"
                                    element={<PropertyManagementKnowledgeBase />}
                                />
                                <Route
                                    path="tenants"
                                    element={
                                        <TenantAndContractor label="Tenants" value="Tenants" />
                                    }
                                />
                                <Route
                                    path="/property-management/tenants/:tenantId"
                                    element={<TenantDetails />}
                                />
                                <Route
                                    path="/property-management/tenants/invite-tenant"
                                    element={<PropertyManagementInviteTenants />}
                                />
                                <Route
                                    path="/property-management/ai-assistant"
                                    element={<PropertyManagementAiAssistant />}
                                />
                                <Route
                                    path="/property-management/ai-assistant/call-details/:callId"
                                    element={<CallDetails />}
                                />
                                <Route
                                    path="contracts/:id"
                                    element={<PropertyManagementCreateContract />}
                                />
                                <Route
                                    path="contracts"
                                    element={
                                        <TenantAndContractor label="Contracts" value="Contracts" />
                                    }
                                />
                                <Route
                                    path="contracts/create-contract"
                                    element={<PropertyManagementCreateContract />}
                                />

                                <Route
                                    path="tenants/invite-tenant"
                                    element={<PropertyManagementInviteTenants />}
                                />
                                <Route path="user/:id" element={<ContractorDetails />}></Route>
                                <Route index element={<Navigate replace to="dashboard" />} />
                                <Route path="dashboard" element={<PropertyDashBoard />} />
                                <Route path="properties" element={<Properties />} />
                                <Route path="properties/units/:id" element={<PropertyUnits />} />
                                <Route
                                    path="properties/:propertyId"
                                    element={<ViewUnitAndMaintenance />}
                                />
                                <Route path="properties/add-property" element={<AddProperty />} />
                                <Route
                                    path="properties/update-property/:propertyId"
                                    element={<UpdateProperty />}
                                />
                                <Route
                                    path="properties/update-unit/:unitId"
                                    element={<UpdateUnit />}
                                />

                                <Route path="properties/add-unit/:id" element={<AddUnit />} />
                                {/* <Route path="tenants" element={<Properties />} /> */}
                                <Route path="compliance" element={<Compliance />} />

                                {/* <Route path="financials" element={<Properties />} /> */}
                                {/* <Route path="appliances" element={<Properties />} /> */}
                                {/* <Route path="analytics" element={<Properties />} /> */}
                                {/* <Route path="/property-management/chat" element={<Properties />} /> */}
                                {/* <Route path="notification" element={<Properties />} /> */}
                                <Route path="work-order" element={<HomeOwnerMyJobs />} />
                                <Route path="jobs/:jobId" element={<HomeOwnerJobDetails />} />
                                <Route
                                    path="settings"
                                    element={
                                        <div className={cn(styles.mainContent, "!mt-2.5 !mx-5")}>
                                            <Settings type="propertyManagement" />
                                        </div>
                                    }
                                >
                                    <Route path="account" element={<Account />} />
                                </Route>
                                <Route
                                    path="maintenance"
                                    element={<PropertyManagementMaintenance />}
                                />
                                <Route path="calendar" element={<Calender />} />
                                <Route path="teams" element={<TeamSettings />} />
                            </Route>
                            <Route
                                element={
                                    <PropertyManagementRoute
                                        component={PropertyManagementLayouts}
                                    />
                                }
                            >
                                <Route
                                    path="/property-management/chat"
                                    element={<ChatLayout navCollapsedSize={8} />}
                                />
                            </Route>
                            {/****************
                             * * * Tenants * * *
                             ****************/}
                            <Route element={<ProtectedTenantsRoute component={TenantsLayout} />}>
                                <Route path="/tenant/dashboard" element={<HomeOwnerDashboard />} />
                                <Route path="/tenant/jobs" element={<HomeOwnerMyJobs />} />
                            </Route>
                            <Route
                                path="/tenant/post-job"
                                element={<ProtectedTenantsRoute component={PostAJob} />}
                            />
                            <Route
                                path="/tenant/post-job/edit"
                                element={<ProtectedTenantsRoute component={PostAJobEditPage} />}
                            />
                            <Route
                                path="/tenant/post-job/confirm"
                                element={<ProtectedTenantsRoute component={ConfirmJobDetails} />}
                            />
                            <Route element={<ProtectedTenantsRoute component={TenantsLayout} />}>
                                <Route
                                    path="/tenant/chat"
                                    element={<ChatLayout navCollapsedSize={8} />}
                                />
                            </Route>
                            <Route path="/tenant/jobs/:jobId" element={<HomeOwnerJobDetails />} />
                            {/****************
                             * * * Generic * * *
                             ****************/}
                            <Route path="/home-owner/post-job" element={<PostAJob />} />
                            <Route
                                path="/home-owner/post-job/edit"
                                element={<PostAJobEditPage />}
                            />
                            <Route
                                path="/home-owner/post-job/confirm"
                                element={<ConfirmJobDetails />}
                            />
                            <Route path="*ing" element={<NotFoundPage />} />
                        </Routes>
                    ) : (
                        <SiteUnderDevelopment />
                    )}
                </BrowserRouter>
            </React.Suspense>
            <Toaster />
        </StateProvider>
    );
};

export default Router;
