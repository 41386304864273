import React from "react";
import { formatNumber, formatWithCommas } from "@/utils/formatNumber";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Skeleton } from "@/components/ui/skeleton";

type TProps = {
    title: string;
    profit: boolean;
    profitNumber: number;
    isLoading?: boolean;
    data: any;
};

export const BoxContent: React.FC<TProps> = ({ title, data, isLoading }) => {
    return (
        <div className="flex-1 text-center">
            <p className="text-sm text-[#000] font-bold mt-2">{title}</p>
            {isLoading ? (
                <Skeleton className="w-1/2 h-[1.8rem] mx-auto mt-2 rounded bg-gray-300" />
            ) : (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <p className="text-2xl font-bold text-gray-900 mt-2">
                                {formatNumber(data)}
                            </p>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>{formatWithCommas(data)}</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            )}
        </div>
    );
};
