import { useCallback, useEffect, useState } from "react";
import { Dialog } from "../../../components/common/dialog";
import { Table } from "../../../components/common/table/table";
import { useGetMyDetailsQuery } from "../../../redux/rtk-query/authApis";
import {
    useGetAccountTransactionsQuery,
    useLazyGetAccountBalanceQuery,
} from "../../../redux/rtk-query/paymentAccount";
import styles from "../Settings.module.css";
import { AddAccountCardContainer } from "./AddAccountCardContainer";
import { PayoutHeader } from "./PayoutHeader";
import { StatsCardContainer } from "./StatsCardContainer";

const TableHeadItems = [
    {
        key: 0,
        value: "Date",
    },
    {
        key: 1,
        value: "Account",
    },
    {
        key: 2,
        value: "Status",
    },
    {
        key: 3,
        value: "Amount",
    },
];

type TProp = {
    accountClickHandler: () => void;
    data: any;
    isLoading: boolean;
};

export const Payout = (prop: TProp) => {
    const [showAddAccountDialog, setShowAddAccountDialog] = useState(false);
    const [getAccountBalance, { data, isLoading }] = useLazyGetAccountBalanceQuery();
    const [card, setCard] = useState(false);
    const [availableBalance, setAvailableBalance] = useState<number>(0);
    const [pendingBalance, setPendingBalance] = useState<number>(0);
    const onClickAddAccount = useCallback(() => {
        setShowAddAccountDialog(true);
    }, []);

    const { data: user } = useGetMyDetailsQuery();

    const { data: transactionData, isLoading: transactionsLoading } =
        useGetAccountTransactionsQuery(user?.stripeConnectId || "", {
            skip: !Boolean(user?.stripeConnectId),
        });

    const TableBodyItems =
        !transactionsLoading && transactionData?.data
            ? transactionData.data.map((item: any) => {
                  return [
                      {
                          key: 0,
                          value: new Date(item.date).toDateString(),
                      },
                      {
                          key: 1,
                          value: item.type,
                      },
                      {
                          key: 2,
                          value: item.status,
                      },
                      {
                          key: 3,
                          value: item.amount,
                      },
                  ];
              })
            : [];

    useEffect(() => {
        setCard(true);
    }, [prop.data]);

    useEffect(() => {
        const getFetchBalance = async () => {
            if (user && user.stripeConnectId) {
                await getAccountBalance(user.stripeConnectId);
            }
            if (!isLoading && data && transactionData) {
                setAvailableBalance(data?.available[0]?.amount);
                setPendingBalance(data?.pending[0]?.amount);
            }
        };
        getFetchBalance();
    }, [data, getAccountBalance, isLoading, transactionData, user]);

    return (
        <section className="w-full" style={{ padding: "0px 55px" }}>
            <PayoutHeader />
            <StatsCardContainer
                availableBalance={availableBalance}
                pendingBalance={pendingBalance}
            />
            <h4 className={styles.paymentMethodHeading}>Payment Method</h4>
            {card && prop.data && prop.data?.data?.charges_enabled ? (
                <>
                    <div className={styles.payoutStatCard}>
                        <p>Bank: {prop.data?.data?.external_accounts.data[0].bank_name}</p>
                        <p>
                            Card Number: **** **** ****{" "}
                            {prop.data?.data?.external_accounts.data[0].last4}
                        </p>
                        <p>Country: {prop.data?.data?.external_accounts.data[0].country}</p>
                    </div>
                </>
            ) : (
                <>
                    <p className={styles.paymentMethodText}>
                        Select your preferred method to receive your funds.
                    </p>
                    <AddAccountCardContainer onClickAddAccount={onClickAddAccount} />
                </>
            )}
            <h3 className={styles.recentTransactionsHeading}>Recent Transactions</h3>

            <Table headerItems={TableHeadItems} rowItems={TableBodyItems} />

            <Dialog
                heading={"Add account with Stripe"}
                primaryButtonLabel={"Continue"}
                isOpen={showAddAccountDialog}
                setIsOpen={setShowAddAccountDialog}
                onClickPrimary={() => {
                    prop.accountClickHandler();
                }}
                isLoading={prop.isLoading}
                content={
                    <p className={styles.addAccountDialogContent}>
                        Connect your account with stripe to setup your withdrawal account. Once
                        connected, you can easily withdraw your funds directly to your linked
                        account.
                    </p>
                }
            />
        </section>
    );
};
