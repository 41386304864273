import { ChangeEventHandler, memo } from "react";
import { AIPromptSamplesWithInput } from "./AIPromptSamplesWithInput";
import styles from "./homeOwnerPostAJob.module.css";
import { PostJobHeader } from "./PostJobHeader";

type TProps = {
    value: string;
    onChangeValue: ChangeEventHandler<HTMLInputElement>;
    onClickArrowButton: () => void;
    isLoading: boolean;
    name: string;
};

export const AIPromptContainer = memo((props: TProps) => {
    const { onChangeValue, value, onClickArrowButton, isLoading } = props;

    return (
        <div className={styles.aiPromptContainer}>
            <PostJobHeader name={props.name} />
            <AIPromptSamplesWithInput
                value={value}
                onChangeValue={onChangeValue}
                onClickArrowButton={onClickArrowButton}
                isLoading={isLoading}
            />
        </div>
    );
});
