import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { Check, Plus, RefreshCw, Search, X } from "lucide-react";
import { STATUS, PRIORITY } from "../Workorder.constant";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
    DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { DateRange } from "react-day-picker";
import { format } from "date-fns";
import { transformPriority, transformStatus, transformStatusForAPI } from "../utils/utils";
import { BsArrowLeftRight } from "react-icons/bs";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { CiCalendarDate } from "react-icons/ci";

interface WorkOrderTableActionProps {
    onSearch: (term: string) => void;
    onStatusFilter: (status: string) => void;
    onPriorityFilter: (priority: string) => void;
    onDateFilter: (startDate: string, endDate: string) => void;
    onToggleColumn: (column: string, key: string) => void;
    visibleColumns: string[];
    columnItems: { value: string; key: string }[];
    activeStatuses: string[];
    activePriorities: string[];
    isLoading: boolean;
    onResetFilters: () => void; // Add this new prop
}

const Tag: React.FC<{ label: string; onRemove: () => void }> = ({ label, onRemove }) => (
    <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800 m-1">
        {label}
        <button onClick={onRemove} className="ml-1 bg-blue-100  focus:outline-none">
            <X size={14} />
        </button>
    </span>
);

export const WorkOrderTableAction: React.FC<WorkOrderTableActionProps> = ({
    onSearch,
    onStatusFilter,
    onPriorityFilter,
    onDateFilter,
    onToggleColumn,
    visibleColumns,
    columnItems,
    activeStatuses,
    activePriorities,
    isLoading,
    onResetFilters, // Add this new prop
}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [date, setDate] = useState<DateRange | undefined>();
    const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value;
        setSearchTerm(term);
        onSearch(term);
    };

    const handleDateSelect = (selectedDate: DateRange | undefined) => {
        setDate(selectedDate);
        if (selectedDate?.from && selectedDate?.to) {
            const startDate = format(selectedDate.from, "yyyy-MM-dd");
            const endDate = format(selectedDate.to, "yyyy-MM-dd");
            onDateFilter(startDate, endDate);
        } else {
            onDateFilter("", "");
        }
    };

    const handleStatusSelect = (status: string) => {
        const apiStatus = transformStatusForAPI(status);
        onStatusFilter(apiStatus);
    };

    const handleRemoveStatus = (status: string) => {
        onStatusFilter(transformStatusForAPI(status));
    };

    const handlePrioritySelect = (priority: string) => {
        onPriorityFilter(priority.toUpperCase());
    };

    const handleRemovePriority = (priority: string) => {
        onPriorityFilter(priority);
    };

    const handleResetFilters = () => {
        setSearchTerm("");
        setDate(undefined);
        onSearch("");
        onStatusFilter("");
        onPriorityFilter("");
        onDateFilter("", "");
        onResetFilters();
        setIsResetDialogOpen(false);
    };

    const handleResetClick = () => {
        const equalColumnLength = visibleColumns.length === columnItems.length + 1;
        console.log(equalColumnLength);

        if (
            date ||
            searchTerm.length > 0 ||
            activePriorities.length > 0 ||
            activeStatuses.length > 0 ||
            !equalColumnLength
        )
            setIsResetDialogOpen(true);
        else return;
    };

    return (
        <div className="flex flex-col space-y-4 w-full">
            <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                    <div className="relative w-64">
                        <Search
                            size={20}
                            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400"
                        />
                        <Input
                            placeholder="Search title..."
                            value={searchTerm}
                            onChange={handleSearch}
                            className="pl-10 focus-visible:ring-1 focus-visible:ring-offset-1 focus-visible:ring-gray-400"
                        />
                    </div>
                    <div className="flex flex-col">
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button
                                    variant="outline"
                                    className="flex items-center"
                                    disabled={isLoading}
                                >
                                    <Plus className="mr-2 h-4 w-4" /> Status
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                {STATUS.map((status) => (
                                    <DropdownMenuItem
                                        key={status.value}
                                        onSelect={() => handleStatusSelect(status.label)}
                                        className="flex items-center px-2 py-2 cursor-pointer hover:bg-gray-100"
                                    >
                                        <div className="w-4 h-4 mr-2">
                                            {activeStatuses.includes(status.value) && (
                                                <Check className="h-4 w-4" />
                                            )}
                                        </div>
                                        <div
                                            className={`w-2 h-2 rounded-full mr-2 ${status.textColor}`}
                                        />
                                        <span>{status.label}</span>
                                    </DropdownMenuItem>
                                ))}
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                    <div className="flex flex-col">
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button
                                    variant="outline"
                                    className="flex items-center"
                                    disabled={isLoading}
                                >
                                    <Plus className="mr-2 h-4 w-4" /> Priority
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                {PRIORITY.map((priority) => (
                                    <DropdownMenuCheckboxItem
                                        key={priority.value}
                                        checked={activePriorities.includes(priority.value)}
                                        onSelect={() => handlePrioritySelect(priority.value)}
                                        className="flex items-center px-2 py-2 text-black cursor-pointer hover:bg-gray-100"
                                    >
                                        <div
                                            className={`w-2 h-2 rounded-full mr-2 ml-6 ${priority.textColor}`}
                                        />
                                        <span>{priority.label}</span>
                                    </DropdownMenuCheckboxItem>
                                ))}
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </div>

                <div className="flex items-center space-x-4">
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button variant="outline">
                                {date?.from ? (
                                    date.to ? (
                                        <>
                                            {format(date.from, "LLL dd, y")} -{" "}
                                            {format(date.to, "LLL dd, y")}
                                        </>
                                    ) : (
                                        format(date.from, "LLL dd, y")
                                    )
                                ) : (
                                    <span className="flex items-center gap-2">
                                        <CiCalendarDate size={18} />
                                        Filter by Date
                                    </span>
                                )}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                                initialFocus
                                mode="range"
                                defaultMonth={date?.from}
                                selected={date}
                                onSelect={handleDateSelect}
                                numberOfMonths={2}
                            />
                        </PopoverContent>
                    </Popover>

                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline">
                                <BsArrowLeftRight className="mr-2" />
                                View
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            {columnItems.map((item) => (
                                <DropdownMenuCheckboxItem
                                    key={item.value}
                                    checked={visibleColumns.includes(item.value)}
                                    onCheckedChange={() => onToggleColumn(item.value, item.key)}
                                >
                                    {item.key}
                                </DropdownMenuCheckboxItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>

                    <AlertDialog open={isResetDialogOpen}>
                        <AlertDialogTrigger asChild>
                            <Button
                                onClick={handleResetClick}
                                variant="outline"
                                disabled={isLoading}
                            >
                                <RefreshCw className="mr-2 h-4 w-4" />
                                Reset Filters
                            </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Reset Filters</AlertDialogTitle>
                                <AlertDialogDescription>
                                    Are you sure you want to reset all filters? This will reset the
                                    table to its initial state.
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel onClick={() => setIsResetDialogOpen(false)}>
                                    Cancel
                                </AlertDialogCancel>
                                <AlertDialogAction onClick={handleResetFilters}>
                                    Reset
                                </AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </div>
            </div>
            <div className="flex items-center gap-2">
                {activeStatuses.length > 0 && (
                    <div className="flex flex-wrap items-center">
                        <div className="flex items-center mr-4">
                            <span className="text-sm font-medium mr-2">Status:</span>
                            {activeStatuses.map((status) => (
                                <Tag
                                    key={status}
                                    label={transformStatus(status)}
                                    onRemove={() => handleRemoveStatus(status)}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {activePriorities.length > 0 && (
                    <div className="flex flex-wrap items-center">
                        <div className="flex items-center">
                            <span className="text-sm font-medium mr-2">Priority:</span>
                            {activePriorities.map((priority) => (
                                <Tag
                                    key={priority}
                                    label={transformPriority(priority)}
                                    onRemove={() => handleRemovePriority(priority)}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
