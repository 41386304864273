export const TableHeadItems = [
    {
        accessorKey: "workorderId",
        header: "Serial",
        cell: ({ row }: any) => {
            return <div className="capitalize">{row.getValue("workorderId")}</div>;
        },
    },
    {
        accessorKey: "title",
        header: "Title",
        cell: ({ row }: any) => {
            return <div className="capitalize">{row.getValue("title")}</div>;
        },
    },
    {
        accessorKey: "unit",
        header: "Property Unit",
        cell: ({ row }: any) => {
            return <div className="capitalize">{row.getValue("unit")}</div>;
        },
    },
    {
        accessorKey: "priority",
        header: "Priority",
        cell: ({ row }: any) => {
            const priority = row.getValue("priority");
            const priorityObj = PRIORITY.find((p) => p.label === priority) || PRIORITY[0];
            return (
                <div className={`${priorityObj.color} py-2 px-4 flex items-center rounded-lg`}>
                    <div className={`${priorityObj.textColor} w-2 h-2 rounded-full mr-2`} />
                    <p className="text-sm">{priority}</p>
                </div>
            );
        },
    },
    {
        accessorKey: "createdAt",
        header: "Posted On",
        cell: ({ row }: any) => {
            return <div className="">{row.getValue("createdAt")}</div>;
        },
    },
    {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }: any) => {
            const status = row.getValue("status");
            const statusObj = STATUS.find((s) => s.label === status) || STATUS[0];
            return (
                <div className={`${statusObj.color} py-3 pl-4 flex items-center rounded-lg`}>
                    <div className={`${statusObj.textColor} w-2 h-2 rounded-full mr-2`} />
                    <p className="text-sm">{status}</p>
                </div>
            );
        },
    },
    {
        accessorKey: "contractor",
        header: "Contractor",
        cell: ({ row }: any) => {
            return <div className="capitalize">{row.getValue("contractor")}</div>;
        },
    },
];

export const sortItems = [
    { value: "id", key: "Request ID" },
    { value: "dateReported", key: "Date Reported" },
    { value: "property", key: "Property" },
    { value: "tenant", key: "Tenant" },
    { value: "priority", key: "Priority" },
];

export const columnItems = [
    { value: "title", key: "Title" },
    { value: "priority", key: "Priority" },
    { value: "unit", key: "Property Unit" },
    { value: "createdAt", key: "Posted On" },
    { value: "status", key: "Status" },
    { value: "contractor", key: "Contractor" },
];

export const STATUS = [
    {
        value: "NOT_STARTED",
        label: "Not started",
        color: "bg-gray-100",
        textColor: "bg-gray-500",
    },
    {
        value: "ACTIVE",
        label: "In Progress",
        color: "bg-yellow-100",
        textColor: "bg-yellow-500",
    },
    {
        value: "COMPLETED",
        label: "Completed",
        color: "bg-green-100",
        textColor: "bg-green-500",
    },
];

export const PRIORITY = [
    {
        value: "STANDARD",
        label: "Standard",
        color: "bg-blue-100",
        textColor: "bg-blue-500",
    },
    {
        value: "EMERGENCY",
        label: "Emergency",
        color: "bg-red-100",
        textColor: "bg-red-500",
    },
];

export const categories = [
    "Drain Cleaning",
    "Leaks Repair",
    "Heating Systems",
    "Pipe Installation",
    "Water Heater Repair",
    "Electrical",
    "Plumbing",
    "Painting",
    "Interior Painting",
    "Exterior Painting",
    "Wallpaper Installation",
    "Decorative Painting",
    "Staining & Varnishing",
    "Wiring",
    "Lighting Installation",
    "Electrical Repairs",
    "Circuit Breaker Replacement",
    "Smart Home Installation",
    "Cabinet Making",
    "Wood Framing",
    "Trim Work",
    "Deck Building",
    "Furniture Repair",
    "Carpentry",
    "Cleaning",
    "Residential Cleaning",
    "Commercial Cleaning",
    "Window Washing",
    "Pressure Washing",
    "Pool Cleaning",
    "Kitchen Remodeling",
    "Remodeling & Renovation",
    "Bathroom Renovation",
    "Basement Finishing",
    "Home Additions",
    "Outdoor Living Spaces",
    "Lawn Care",
    "Tree Planting",
    "Garden Design",
    "Hardscaping",
    "Irrigation Systems",
    "Landscaping",
    "Leak Repairs",
    "Shingle Replacement",
    "Roof Installation",
    "Gutter Cleaning",
    "Insulation",
    "Roofing",
    "Tile Installation",
    "Hardwood Flooring",
    "Laminate Flooring",
    "Carpet Installation",
    "Floor Repair",
    "Flooring",
    "Emergency",
    "Popular",
];
