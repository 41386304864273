import styles from "./homeOwnerDashboard.module.css";
import { Heading } from "../../../components/common/pageHeading";
import { SpendingToggleButton } from "./SpendingToggleButton";

export const SpendingHeader = (props: { showSpending: boolean; onClick: () => void }) => (
    <div className={styles.spendingHeader}>
        <Heading>My Spendings</Heading>
        <SpendingToggleButton showSpending={props.showSpending} onClick={props.onClick} />
    </div>
);
