// Zos Schema for forms

import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { z } from "zod";

export const formSchema = z.object({
    title: z.string().min(3, {
        message: "Script title must be at least 3 characters.",
    }),
    question: z.string().min(5, {
        message: "Question must be at least 5 characters",
    }),
    answer: z.string().min(5, {
        message: "Answer must be at least 5 characters",
    }),
    isApiRequired: z.string().nonempty("Please select an option"),
    scripts: z.string(),
    toolName: z.string(),
    authToken: z.string(),
    apiKey: z.string(),
    method: z.string(),
    toolDescription: z.string(),
    parameters: z.array(
        z.object({
            _id: z.optional(z.string()),
            key: z.string(),
            type: z.string(),
            parameterName: z.string(),
            parameterDescription: z.string(),
        }),
    ),
});

export type FormContainerPropType = {
    addScriptClicked: boolean;
    editResponseClicked: boolean;
    setAddScriptClicked: React.Dispatch<React.SetStateAction<boolean>>;
    setEditResponseClicked: React.Dispatch<React.SetStateAction<boolean>>;
    type: "Edit" | "Add";
    editData?: {
        title: string;
        answer: string;
        companyId: string;
        createdAt: string;
        questions: string;
        updatedAt: string;
        _id: string;
        tools?: {
            _id: string;
            name: string;
            api: string;
            method: string;
            authToken: string;
            description: string;
            scriptId: string;
            createdAt: string;
            updatedAt: string;
            tool_parameters: {
                _id: string;
                key: string;
                type: string;
                name: string;
                toolId: string;
                description: string;
                createdAt: string;
                updatedAt: string;
            }[];
        };
    };
    isFetching?: boolean;
};

export type ScriptFormPropType = {
    isApiRequired: string;
    form: UseFormReturn<z.infer<typeof formSchema>>;
    setAddScriptClicked: React.Dispatch<React.SetStateAction<boolean>>;
    setConfigureScriptClicked: React.Dispatch<React.SetStateAction<boolean>>;
    setEditResponseClicked: React.Dispatch<React.SetStateAction<boolean>>;
    type: "Edit" | "Add";
    submitHandler: (values: z.infer<typeof formSchema>) => void;
};

export type ConfigureScriptProp = {
    setConfigureScriptClicked: React.Dispatch<React.SetStateAction<boolean>>;
    setEditResponseClicked: React.Dispatch<React.SetStateAction<boolean>>;
    setAddScriptClicked: React.Dispatch<React.SetStateAction<boolean>>;
    form: UseFormReturn<z.infer<typeof formSchema>>;
    type: "Edit" | "Add";
    submitHandler: (values: z.infer<typeof formSchema>) => void;
    fieldArrayObject: UseFieldArrayReturn<z.infer<typeof formSchema>>;
    allDeletedId: string[];
    setAllDeletedId: React.Dispatch<React.SetStateAction<string[]>>;
};

export type FormSkeletonProps = {
    setEditResponseClicked: React.Dispatch<React.SetStateAction<boolean>>;
    isFetching: boolean;
};

export type ResToolType = {
    name: string;
    api: string;
    method: string;
    authToken: string;
    description: string;
    scriptId: string;
    _id: string;
    createdAt: string;
    updatedAt: string;
};

export type ResScriptType = {
    title: string;
    companyId: string;
    question: string;
    answer: string;
    _id: string;
    createdAt: string;
    updatedAt: string;
};
