import styles from "../Common.module.css";
import { Button as ShadcnButton } from "@/components/ui/button";

interface ButtonProps {
    text: string;
    id?: string;
    disabled?: boolean;
    handleBtnClick?: () => void;
    type?: "button" | "submit" | "reset";
    loading?: boolean;
    style?: React.CSSProperties; // Use React.CSSProperties for the style prop
}

function Button({
    text,
    id,
    disabled = false,
    handleBtnClick,
    type = "button",
    loading = false,
    style = {},
}: ButtonProps) {
    console.log(style);

    return (
        <ShadcnButton
            className={`${styles.btn} ${styles.backgrdBtn}`}
            id={id}
            style={style}
            disabled={loading || disabled}
            onClick={handleBtnClick}
            type={type}
        >
            {loading ? "loading..." : text}
        </ShadcnButton>
    );
}

export default Button;
