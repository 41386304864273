import { TaskTables } from "@/components/table/TaskTable";
import { useNavigate } from "react-router-dom";
import { getUserBasePath } from "../../utils/getBaseUserBasedPath";
import { TableHeadItemsTenants } from "./PropertyManagementTenants.constants";
import useTenantsRow from "./ui/useTenantsRow";
import { TableActions } from "../propertyManagementContracts/TableActions";
import useTableSortAndFilter from "@/components/common/table/useTableSortAndFilter";

export const PropertyManagementTenants = () => {
    const navigate = useNavigate();
    const {
        tableRowItems,
        user,
        isDataLoading,
        tenantsList,
        pagination,
        searchTerm,
        setSearchTerm,
        handlePageChange,
        handleSort,
        sortDirection,
        sortColumn,
    } = useTenantsRow();

    const { handleToggleColumn, visibleColumns } = useTableSortAndFilter({
        head: TableHeadItemsTenants,
        rows: tableRowItems,
    });

    const TableData = {
        data: [{ header: visibleColumns, row: tableRowItems, heading: "Tenants" }],
    };

    const sortItems = [
        { value: "TenantName", key: "Tenant Name" },
        { value: "email", key: "Email" },
        { value: "phoneNumber", key: "Phone Number" },
    ];

    const columnItems = [
        { value: "TenantName", key: "Tenant Name" },
        { value: "email", key: "Email" },
        { value: "phoneNumber", key: "Phone" },
    ];

    return (
        <TaskTables
            // mainHeading="Tenants"
            data={TableData?.data || []}
            className="text-xs"
            isLoading={isDataLoading}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            totalpages={pagination.totalPages}
            currentPage={pagination.currentPage}
            onPageChange={handlePageChange}
            sortAndFilter={
                <TableActions
                    onClickFilter={() => {}}
                    onClickSort={handleSort}
                    onToggleColumn={handleToggleColumn}
                    visibleColumns={visibleColumns.map((item) => item?.accessorKey)}
                    isSearch={true}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    sortItems={sortItems}
                    columnItems={columnItems}
                />
            }
            onClickRow={(row: any) => {
                const rowId = row?.original?.id;
                const payload =
                    tenantsList && Array.isArray(tenantsList)
                        ? tenantsList.find((log: any) => log._id === rowId)
                        : undefined;
                const path = getUserBasePath(user?.userType);

                return navigate(`${path}/tenants/${rowId}`, {
                    state: { ...(payload || {}), RowId: Number(row.id) + 1 },
                });
            }}
        />
    );
};
