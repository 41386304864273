export const transformStatus = (status: string) => {
    switch (status.toLowerCase()) {
        case "not_started":
            return "Not started";
        case "active":
        case "in progress":
            return "In Progress";
        case "completed":
            return "Completed";
        default:
            return (
                status.charAt(0).toUpperCase() + status.slice(1).toLowerCase().replace(/_/g, " ")
            );
    }
};

export const transformStatusForAPI = (status: string) => {
    switch (status.toLowerCase()) {
        case "not started":
            return "NOT_STARTED";
        case "in progress":
            return "ACTIVE";
        case "completed":
            return "COMPLETED";
        default:
            return status.toUpperCase();
    }
};

export const transformPriority = (status: string) => {
    switch (status.toLowerCase()) {
        case "standard":
            return "Standard";
        case "emergency":
            return "Emergency";
        default:
            return (
                status.charAt(0).toUpperCase() + status.slice(1).toLowerCase().replace(/_/g, " ")
            );
    }
};

export const transformPriorityForAPI = (status: string) => {
    switch (status.toLowerCase()) {
        case "standard":
            return "STANDARD";
        case "emergency":
            return "EMERGENCY";
        default:
            return status.toUpperCase();
    }
};

export function convertISOToDate(dateStr: string) {
    const cleanDateStr = dateStr.replace(/(\d+)(st|nd|rd|th)/, "$1");
    return new Date(cleanDateStr); // Example: new Date("29 Sep 2024")
}
