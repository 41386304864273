import React from "react";

import { useGetMyDetailsQuery } from "../../../redux/rtk-query/authApis";
import { Breadcrumbs } from "./Breadcrumbs";

import TeamInvite from "./TeamInvite";
import MembersDisplay from "./MembersDisplay";
import { useGetCompanyDetailsQuery } from "@/redux/rtk-query/settings";
import { useMemo } from "react";
import dayjs from "dayjs";
import { Header } from "@/components/common/headers/Header";

const TeamsRow = () => {
    const { data: myDetails } = useGetMyDetailsQuery();

    const { data: companyDetails } = useGetCompanyDetailsQuery(myDetails!, {
        skip: !myDetails || !myDetails?.companyId,
    });
    console.log("i am comapny details:", companyDetails);

    const complianceRow = useMemo(() => {
        if (!Array.isArray(companyDetails)) return [];
        return companyDetails.map((teams: any, _) => {
            return {
                id: teams._id,
                name: `${teams.firstName} ${teams.lastName}`,
                email: teams.email,
                status: `${teams?.status?.split("_").join(" ").slice(0, 1).toUpperCase() + teams?.status?.split("_").join(" ").toLocaleLowerCase().slice(1)}`,
                createdAt: dayjs(teams.updatedAt).format("MMM DD, YYYY [at] hh:mm A"),
                UserType: `${teams?.userType?.split("_").join(" ").slice(0, 1).toUpperCase() + teams?.userType?.split("_").join(" ").toLocaleLowerCase().slice(1)}`,
            };
        });
    }, [companyDetails]);
    return complianceRow;
};

interface MyComponentProps {
    className?: string;
}

const TeamSettings: React.FC<MyComponentProps> = () => {
    const { data: myDetails } = useGetMyDetailsQuery();

    const rows = TeamsRow();
    console.log({ rows });

    const invitedUsers = rows.filter((user) => user.status === "Invited");
    const teamMembers = rows.filter((user) => user.status === "Active");

    const totalInvitedUsers = invitedUsers.length;
    const teamMembersUsers = teamMembers.length;
    // const username =
    //     myDetails?.firstName && myDetails?.lastName
    //         ? myDetails?.firstName + " " + myDetails?.lastName
    //         : myDetails?.firstName
    //           ? myDetails?.firstName
    //           : myDetails?.lastName;

    return (
        <div className="w-full bg-white overflow-y-scroll">
            <Header
                image={myDetails?.imageUrl}
                heading={"Teams"}
                firstName={myDetails?.firstName}
                lastName={myDetails?.lastName}
                hasSearchBar={true}
            />
            <div className="p-5">
                <div className="h-[92%] w-full pl-10 pr-10">
                    <section className="p-4 pl-0 max-w-[31.16%]">
                        <Breadcrumbs name={"Teams"} className="mb-4" />
                    </section>
                    <div className="flex gap-9 w-full">
                        <div className="flex flex-col gap-2 w-[60%]">
                            {myDetails && (
                                <MembersDisplay
                                    totalMembers={teamMembersUsers}
                                    myDetails={teamMembers}
                                />
                            )}
                        </div>
                        <div className="flex flex-col gap-20 w-[40%]">
                            {myDetails && (
                                <TeamInvite
                                    invitedUserData={invitedUsers}
                                    invitedUsers={totalInvitedUsers}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamSettings;
