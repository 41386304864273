import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { TDropdownItem } from "../../../components/common/dropdown/Dropdown.types";
import "../../../cssReset.css";
import { useGetMyDetailsQuery } from "../../../redux/rtk-query/authApis";
import { useChangeJobStatusMutation, useGetJobQuery } from "../../../redux/rtk-query/jobsApis";
import { capitalize } from "../../../utils/capitalize";
import { TContactorStatus } from "./ContractorCard";
import isEmptyObject from "../../../utils/isEmptyObject";
import { Button } from "@/components/ui/button";
import { HomeIcon } from "@/assets/icons/HomeIcon";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { HiOutlineMapPin } from "react-icons/hi2";
import { cn } from "@/lib/utils";
import style from "./jobDetails.module.css";
import { Separator } from "@/components/ui/separator";
import { Calendar, LogOut, MessageCircle } from "lucide-react";
import { formatUserType } from "@/utils/formatUserType";
import { NothingToSeePlaceholder } from "@/components/common/nothingToSeePlaceholder";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "@/components/ui/spinner";
import { useSendMessageOrCreateConversation } from "@/hooks/useSendMessageOrCreateConversation";
import ContractorAssignModal from "./ContractorAssignModal";
import { InviteContractorForm } from "./InviteContractorForm";
import { Skeleton } from "@/components/ui/skeleton";

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

export const TABS: TDropdownItem[] = [
    {
        key: "Timeline",
        value: "overview",
    },
    {
        key: "Details",
        value: "details",
    },
];

export const DECLINE_REASONS: TDropdownItem[] = [
    {
        key: "reason",
        value: "reason",
    },
];

export type TDialogId =
    | "review-submission"
    | "decline-submission"
    | "accept-offer"
    | "decline-offer";

const CONTRACTOR_ASSIGNED_STATUSES = ["ACTIVE", "COMPLETED", "ONGOING"];

const JobDetails = () => {
    const [open, setOpen] = useState(false);

    const [jobAssignPopUpOpen, setJobAssignPopUpOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState("");
    const { onClick: onClickMessage } = useSendMessageOrCreateConversation();
    // const phoneInputContainerRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();
    const workOrderData = location.state;
    console.log("workOrderData", workOrderData);

    const { jobId } = useParams<{ jobId: string }>();

    const [changeJobStatus, { isLoading: isJobStatusUpdating }] = useChangeJobStatusMutation();

    const { data: myUserDetails } = useGetMyDetailsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const { data: jobResult, isLoading } = useGetJobQuery(
        { jobId, companyId: myUserDetails?.companyId },
        {
            skip: !Boolean(jobId),
        },
    );
    const { completeAddress } = useMemo(() => {
        const title = jobResult?.jobDetail?.title ?? "";
        const description = jobResult?.jobDetail?.description ?? "";
        const city = jobResult?.address?.city ?? "";
        const country = jobResult?.address?.country ?? "";
        const building = jobResult?.address?.building ?? "";
        const mainStreet = jobResult?.address?.mainStreet ?? "";
        const postalCode = jobResult?.address?.postalCode ?? "";
        const createdAt = jobResult?.createdAt ?? "";
        const rate = jobResult?.jobDetail.rate ?? 0;
        const categories = jobResult?.jobCategory ? jobResult?.jobCategory?.split(", ") : [""];
        const id = jobResult?._id;

        const province = jobResult?.address?.province ?? "";
        const completeAddress = `${mainStreet ? `${mainStreet}, ` : ""}${building ? `${building}, ` : ""}${postalCode ? `${postalCode}, ` : ""}
            ${city ? `${city}, ` : ""}${province ? `${province}, ` : ""}
            ${country ? `${country}, ` : ""}`;

        const photos = jobResult?.jobDetail ? jobResult?.jobDetail?.photoUrl : [""];

        const duration =
            jobResult?.jobBid?.find((data) => data?.userInfo?.username === myUserDetails?.username)
                ?.duration || "";

        const status = jobResult?.status ?? "NOT_STARTED";
        const filteredQuotes = jobResult?.jobBid.filter((bid: any) => !isEmptyObject(bid));
        const startDate = filteredQuotes && filteredQuotes[0]?.startDate;
        const totalQuotes = filteredQuotes?.length ?? 0;
        const bookedBy = jobResult?.bookedBy;

        let userStatus: TContactorStatus = "contractor-not-assigned";
        const hasAssignedTo = Boolean(jobResult?.assignedTo);
        if (bookedBy === "AI ASSISTANT") {
            userStatus = "client-info";
        } else if (jobResult?.status && CONTRACTOR_ASSIGNED_STATUSES.includes(jobResult?.status)) {
            userStatus = "contractor-assigned";
        } else {
            if (hasAssignedTo && Boolean(jobResult?.status === "NOT_STARTED")) {
                userStatus = "contractor-assigned-but-not-accepted";
            } else {
                userStatus = "contractor-not-assigned";
            }
        }

        let userName = "";
        let otherUser = "";
        if (myUserDetails?.userType === "CONTRACTOR") {
            const firstName = capitalize(jobResult?.assignedToInfo?.firstName ?? "");
            const lastName = capitalize(jobResult?.assignedToInfo?.lastName ?? "");
            userName = `${firstName} ${lastName}`;

            otherUser = jobResult?.postedBy?._id ?? "";
        } else {
            const firstName = capitalize(jobResult?.postedBy?.firstName ?? "");
            const lastName = capitalize(jobResult?.postedBy?.lastName ?? "");
            userName = `${firstName} ${lastName}`;

            otherUser = jobResult?.assignedTo ?? "";
        }

        return {
            title,
            description,
            createdAt,
            rate,
            categories,
            completeAddress,
            photos,
            city,
            country,
            duration,
            status,
            userStatus,
            userName,
            totalQuotes,
            id,
            otherUser,
            startDate,
        };
    }, [
        jobResult?._id,
        jobResult?.address?.building,
        jobResult?.address?.city,
        jobResult?.address?.country,
        jobResult?.address?.mainStreet,
        jobResult?.address?.postalCode,
        jobResult?.address?.province,
        jobResult?.assignedTo,
        jobResult?.assignedToInfo?.firstName,
        jobResult?.assignedToInfo?.lastName,
        jobResult?.bookedBy,
        jobResult?.createdAt,
        jobResult?.jobBid,
        jobResult?.jobCategory,
        jobResult?.jobDetail,
        jobResult?.postedBy?._id,
        jobResult?.postedBy?.firstName,
        jobResult?.postedBy?.lastName,
        jobResult?.status,
        myUserDetails?.userType,
        myUserDetails?.username,
    ]);

    const handleAssignJob = async () => {
        if (!selectedUserId || !jobId) return;

        try {
            await changeJobStatus({ _id: jobId, assignedTo: selectedUserId }).unwrap();
            toast.success("Job assigned successfully!");
            setJobAssignPopUpOpen(false);
        } catch (error) {
            console.error("Failed to assign job:", error);
            toast.error("Failed to assign job. Please try again.");
        }
    };

    const handleCompleteJob = async () => {
        if (!jobId) return;

        try {
            await changeJobStatus({ _id: jobId, status: "COMPLETED" }).unwrap();

            toast.success("Job Completed successfully!");
            setJobAssignPopUpOpen(false);
        } catch (error) {
            console.error("Failed to assign job:", error);
            toast.error("Failed to assign job. Please try again.");
        }
    };

    return (
        <div className="w-full h-screen overflow-y-auto bg-[#f6f6f6]">
            <div className="bg-white py-6 px-5 border-b flex gap-4 items-center justify-start">
                <div className="p-3 rounded-lg bg-gray-100 border ">
                    <HomeIcon />
                </div>
                <div className="flex flex-col">
                    <h1 className="text-lg font-semibold">Work Order</h1>
                    <Breadcrumb className="w-[17rem]">
                        <BreadcrumbList>
                            <BreadcrumbItem className="text-xs">
                                <NavLink to="/property-management/dashboard">Home</NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator>/</BreadcrumbSeparator>
                            <BreadcrumbItem className="text-xs">
                                <NavLink to="/property-management/work-order">Work Order</NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator>/</BreadcrumbSeparator>
                            <BreadcrumbItem>
                                <BreadcrumbPage className="text-xs">
                                    Work Order Details
                                </BreadcrumbPage>
                            </BreadcrumbItem>
                        </BreadcrumbList>
                    </Breadcrumb>
                </div>
            </div>

            <ContractorAssignModal
                jobAssignPopUpOpen={jobAssignPopUpOpen}
                setJobAssignPopUpOpen={setJobAssignPopUpOpen}
                jobResult={jobResult}
                completeAddress={completeAddress}
                handleAssignJob={handleAssignJob}
                setSelectedUserId={setSelectedUserId}
                selectedUserId={selectedUserId}
                isJobStatusUpdating={isJobStatusUpdating}
                setOpen={setOpen}
            />
            <InviteContractorForm open={open} setOpen={setOpen} />

            <header className="flex justify-between items-center p-4 mb-3">
                <div className="flex flex-col gap-1">
                    <div className="flex gap-4 items-center">
                        {isLoading ? (
                            <Skeleton className="h-8 w-[200px] bg-gray-300 rounded-lg " />
                        ) : (
                            <h1 className="text-2xl font-semibold">
                                {jobResult?.jobDetail?.title}
                            </h1>
                        )}
                        {isLoading ? (
                            <Skeleton className="h-6 w-20 bg-gray-300 rounded-lg" />
                        ) : (
                            <Badge className="bg-red-200 text-black h-[1.5rem]">
                                {jobResult?.jobPriority.slice(0, 1)}
                                {jobResult?.jobPriority?.slice(1).toLowerCase()}
                            </Badge>
                        )}
                        {isLoading ? (
                            <Skeleton className="h-6 w-20 bg-gray-300 rounded-lg" />
                        ) : (
                            <Badge className="bg-blue-200 text-black h-[1.5rem]">
                                {formatUserType(jobResult?.status || "")}
                            </Badge>
                        )}
                    </div>
                    {isLoading ? (
                        <Skeleton className="h-4 w-[300px] bg-gray-300 rounded-lg mt-2" />
                    ) : (
                        <p className="text-sm text-gray-500 flex items-center gap-1">
                            <HiOutlineMapPin />
                            {completeAddress}
                        </p>
                    )}
                </div>
                {isLoading ? (
                    <Skeleton className="h-10 w-[150px] bg-gray-300 rounded-lg" />
                ) : (
                    jobResult?.status !== "COMPLETED" && (
                        <Button
                            className="rounded-lg"
                            onClick={handleCompleteJob}
                            disabled={isJobStatusUpdating}
                        >
                            {isJobStatusUpdating && <Spinner className="text-white size-5 mr-1" />}
                            Mark As Complete
                        </Button>
                    )
                )}
            </header>

            <main className="grid grid-cols-1 md:grid-cols-3 gap-6 p-4">
                <section className="md:col-span-2">
                    <div>
                        <h2 className="font-bold text-lg">Job Description</h2>
                        {isLoading ? (
                            Array(5)
                                .fill(null)
                                .map((_, index) => (
                                    <Skeleton
                                        key={index}
                                        className="ml-4 h-3 w-[600px] bg-gray-300 rounded-lg mt-2"
                                    />
                                ))
                        ) : (
                            <p className="text-sm text-gray-600 ml-4">
                                {jobResult?.jobDetail?.description}
                            </p>
                        )}
                    </div>

                    <div className={cn(style.cardContainer, "mt-6 bg-white p-3")}>
                        <h2 className="font-semibold text-sm">Summary</h2>
                        <div className={"flex items-center mt-4"}>
                            {isLoading ? (
                                <Skeleton className="h-[50px] w-[50px] bg-gray-300 rounded-full" />
                            ) : (
                                <Avatar className="h-[50px] w-[50px]">
                                    <AvatarImage src=""></AvatarImage>
                                    <AvatarFallback>
                                        {jobResult?.postedBy?.firstName.slice(0, 1).toUpperCase()}
                                        {jobResult?.postedBy?.lastName?.slice(0, 1).toUpperCase()}
                                    </AvatarFallback>
                                </Avatar>
                            )}
                            <div className="ml-2">
                                {isLoading ? (
                                    <>
                                        <Skeleton className="h-4 w-[100px] bg-gray-300 rounded-lg mb-1" />
                                        <Skeleton className="h-3 w-[80px] bg-gray-300 rounded-lg" />
                                    </>
                                ) : (
                                    <>
                                        <div className="font-semibold">
                                            {jobResult?.postedBy?.firstName}{" "}
                                            {jobResult?.postedBy?.lastName}
                                        </div>
                                        <div className="text-sm text-gray-600">
                                            {formatUserType(jobResult?.postedBy?.userType || "")}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="my-4 space-y-3">
                            <div className="flex">
                                <span className="w-[24%] text-sm text-gray-500">Category</span>
                                <div className="flex space-x-2 w-3/4">
                                    {isLoading ? (
                                        <Skeleton className="h-6 w-20 bg-gray-300 rounded-lg ml-2" />
                                    ) : (
                                        <Badge className="bg-gray-200 text-black h-[1.5rem]">
                                            {jobResult?.jobCategory || "N/A"}
                                        </Badge>
                                    )}
                                </div>
                            </div>
                            <div className="flex">
                                <span className="w-1/4 text-sm text-gray-500">Date Posted</span>
                                {isLoading ? (
                                    <Skeleton className="h-6 w-[150px] bg-gray-300 rounded-lg" />
                                ) : (
                                    <span className="w-3/4 text-gray-500 text-sm">
                                        {dayjs(jobResult?.jobDetail?.createdAt).format(
                                            "DD MMMM YYYY",
                                        )}
                                    </span>
                                )}
                            </div>
                            <div className="flex">
                                <span className="w-1/4 text-sm text-gray-500">Location</span>
                                {isLoading ? (
                                    <Skeleton className="h-6 w-[300px] bg-gray-300 rounded-lg" />
                                ) : (
                                    <span className="w-3/4 text-sm text-gray-500">
                                        {completeAddress}
                                    </span>
                                )}
                            </div>
                        </div>

                        <Separator />

                        <h2 className="font-semibold text-sm">Files</h2>
                        <NothingToSeePlaceholder label="No File Attached" height={90} />
                    </div>

                    {jobResult?.jobDetail?.photoUrl && (
                        <div className="mt-6">
                            <h2 className="font-semibold text-lg">Photos</h2>
                            {!jobResult?.jobDetail?.photoUrl?.length ? (
                                <NothingToSeePlaceholder
                                    label="No Images Found"
                                    height={90}
                                    width={750}
                                />
                            ) : (
                                jobResult?.jobDetail?.photoUrl.map((photo, index) => (
                                    <div className="relative w-[120px] h-[7rem] flex gap-3">
                                        <img
                                            key={index}
                                            src={photo}
                                            alt={`preview`}
                                            className="w-full h-full object-cover border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                        />
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </section>

                <aside>
                    <div className={cn(style.cardContainer, "p-4")}>
                        <h2 className="font-semibold text-sm">Assign Job To Contractor</h2>
                        <p className="text-sm mt-2 bg-gray-100 p-3 rounded-md flex gap-2 items-center">
                            <Calendar />
                            {`Expected completion in ${Math.max(2, Math.min(5, Math.ceil((new Date(jobResult?.dueDate || "").getTime() - new Date().getTime()) / (24 * 60 * 60 * 1000))))} days`}
                        </p>

                        <div className="mt-6 space-y-4">
                            <h2 className="font-semibold text-sm">
                                {jobResult?.assignedToInfo &&
                                Object.keys(jobResult.assignedToInfo).length > 0
                                    ? "Contractor Assigned"
                                    : "Quick Assign"}
                            </h2>
                        </div>

                        {!(
                            jobResult?.assignedToInfo &&
                            Object.keys(jobResult.assignedToInfo).length > 0
                        ) &&
                            jobResult?.jobBid?.length &&
                            jobResult?.jobBid?.some((item) => Object.keys(item).length > 0) && (
                                <>
                                    {jobResult?.jobBid?.map((data) => {
                                        return (
                                            <div
                                                className="flex items-center justify-between"
                                                key={data?.userInfo?._id}
                                            >
                                                <div className="flex items-center">
                                                    <Avatar className="h-[50px] w-[50px]">
                                                        <AvatarImage
                                                            src={data?.userInfo?.imageUrl}
                                                            alt={`${data?.userInfo?.firstName} ${data?.userInfo?.lastName}`}
                                                        />
                                                        <AvatarFallback>
                                                            {data?.userInfo?.firstName
                                                                ?.slice(0, 1)
                                                                ?.toUpperCase()}
                                                            {data?.userInfo?.lastName
                                                                ?.slice(0, 1)
                                                                ?.toUpperCase()}
                                                        </AvatarFallback>
                                                    </Avatar>
                                                    <div className="ml-4">
                                                        <p className="font-semibold">
                                                            {data?.userInfo?.username}
                                                        </p>
                                                        <p className="text-sm text-gray-600">
                                                            {formatUserType(
                                                                data?.userInfo?.userType,
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                                <Button
                                                    size="sm"
                                                    onClick={() => {
                                                        setSelectedUserId(data?.userInfo?._id);
                                                        handleAssignJob();
                                                    }}
                                                    disabled={isJobStatusUpdating}
                                                >
                                                    {isJobStatusUpdating && (
                                                        <Spinner className="text-white size-5 mr-1" />
                                                    )}
                                                    Assign Job
                                                </Button>
                                            </div>
                                        );
                                    })}
                                </>
                            )}

                        {!(
                            jobResult?.assignedToInfo &&
                            Object.keys(jobResult.assignedToInfo).length > 0
                        ) &&
                            !isLoading && (
                                <div
                                    className="my-5 flex items-center text-gray-500 text-sm cursor-pointer"
                                    onClick={() => setJobAssignPopUpOpen(true)}
                                >
                                    <LogOut className="h-[13px]" /> Assign Other Contractor
                                </div>
                            )}

                        {jobResult?.assignedToInfo && (
                            <div className="mb-4">
                                <div className="mt-6">
                                    <div className="flex items-center gap-3 py-2 cursor-pointer rounded-lg px-3">
                                        <Avatar className="h-14 w-14">
                                            <AvatarImage
                                                src={jobResult?.assignedToInfo?.imageUrl}
                                                alt={jobResult?.assignedToInfo?.firstName}
                                            />
                                            <AvatarFallback>
                                                {jobResult?.assignedToInfo?.firstName
                                                    .slice(0, 1)
                                                    .toUpperCase()}
                                                {jobResult?.assignedToInfo?.lastName
                                                    .slice(0, 1)
                                                    .toUpperCase()}
                                            </AvatarFallback>
                                        </Avatar>
                                        <div className="flex flex-col gap-1">
                                            <p className="font-semibold">
                                                {jobResult?.assignedToInfo?.firstName}{" "}
                                                {jobResult?.assignedToInfo?.lastName}
                                            </p>
                                            <p className="text-xs text-gray-500">
                                                {jobResult?.assignedToInfo?.email}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="mt-4 px-3">
                                        <div className="flex items-center gap-3 my-2">
                                            <span className="w-[24%] text-sm text-gray-500">
                                                Phone No.
                                            </span>
                                            <div className="flex space-x-2 w-3/4 text-xs text-gray-500 ">
                                                {jobResult?.assignedToInfo?.phone}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 flex gap-2">
                                    <Button
                                        className="bg-[#000] border border-3"
                                        onClick={() =>
                                            onClickMessage(jobResult?.assignedToInfo?._id)
                                        }
                                    >
                                        <MessageCircle />
                                    </Button>
                                    <Button className="bg-[#000]">Visit Profile</Button>
                                    <Button
                                        className="bg-[#000]"
                                        onClick={() => setJobAssignPopUpOpen(true)}
                                    >
                                        Reassign Job
                                    </Button>
                                </div>
                            </div>
                        )}

                        <Separator />
                    </div>
                </aside>
            </main>
        </div>
    );
};

export default JobDetails;
