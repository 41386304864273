import { useNavigate } from "react-router-dom";
import styles from "./UploadJobImages.module.css";
import Button from "../../components/common/button";

export default function Success() {
    const navigate = useNavigate();
    return (
        <div className={styles.success}>
            <div>
                <h1>Your Job has been posted successfully!</h1>
                <Button text="Explore Lightwork" handleBtnClick={() => navigate("/")} />
            </div>
        </div>
    );
}
