import React, { useCallback, useRef, ChangeEvent } from "react";
import styles from "./account.module.css";
import { toast } from "react-toastify";
import { useUploadToAWSMutation } from "../../../redux/rtk-query/uploadApis";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";

interface UploadImageProps {
    setProfileImage: (url: string) => void;
    profileImage?: string;
    title: string;
}

const UploadImage: React.FC<UploadImageProps> = (props) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [uploadImageToAWS, { isLoading: isUploading }] = useUploadToAWSMutation();

    const handleImageChange = useCallback(
        async (event: ChangeEvent<HTMLInputElement>) => {
            const formData = new FormData();

            if (event.target.files && event.target.files[0]) {
                formData.append("file", event.target.files[0]);
                formData.append("folder", "properties");

                try {
                    const response = await uploadImageToAWS(formData).unwrap();

                    props.setProfileImage(response?.url);

                    if (!response?.url) {
                        toast.error(`Failed to upload image`);
                        return;
                    }
                } catch (error) {
                    toast.error(`Failed to upload image`);
                    return;
                }
            }
        },
        [uploadImageToAWS, props],
    );

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className="flex items-center gap-4">
            <div>
                <img
                    src={props.profileImage || "https://via.placeholder.com/500x500"}
                    alt="user profile"
                    className={styles.profileImage}
                />
                <input
                    type="file"
                    name="image"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                />
            </div>
            <Button
                disabled={isUploading}
                onClick={handleButtonClick}
                variant={"outline"}
                className="max-h-[48px] py-[12px] px-[16px] bg-[#fff]"
            >
                {isUploading ? (
                    <div className="flex items-center gap-2">
                        <p>Uploading</p>
                        <Spinner size="small" className="text-black" />{" "}
                    </div>
                ) : (
                    props.title
                )}
            </Button>
        </div>
    );
};

export { UploadImage };
