export function SingleRatingStarIcon({
    size = "12",
    onClick,
}: {
    size?: string;
    onClick?: () => void;
}) {
    return (
        <svg
            width={size}
            height={size}
            viewBox={`0 0 12 12`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path
                d="M6 10.0496L8.90991 11.8912C9.44281 12.2287 10.0949 11.7298 9.95468 11.0988L9.18337 7.63582L11.7567 5.3027C12.2265 4.87717 11.9741 4.07011 11.357 4.01875L7.97033 3.71794L6.64509 0.445713C6.40669 -0.148571 5.59331 -0.148571 5.35491 0.445713L4.02967 3.71061L0.642956 4.01142C0.025914 4.06278 -0.226512 4.86983 0.243281 5.29536L2.81663 7.62848L2.04532 11.0915C1.90509 11.7224 2.55719 12.2213 3.09009 11.8838L6 10.0496Z"
                fill="#22BCFF"
            />
        </svg>
    );
}
