import React, { useRef } from "react";
import { buttonVariants } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import { FileImage, Paperclip, SendHorizontal, ThumbsUp } from "lucide-react";
import { Link } from "react-router-dom";
import { EmojiPicker } from "./emoji-picker";
import { Skeleton } from "@/components/ui/skeleton";

interface ChatBottombarProps {
    setInputValue: (text: string) => void;
    inputValue: string;
    sendMessage: Function;
    isMobile: boolean;
    totalMessages?: any;
    isConversationLoading?: boolean;
}

export const BottombarIcons = [{ icon: FileImage }, { icon: Paperclip }];

export default function ChatBottombar({
    sendMessage,
    setInputValue,
    inputValue,
    isConversationLoading,
}: ChatBottombarProps) {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const handleThumbsUp = async (event: any) => {
        event.preventDefault();
        await sendMessage(true);
    };

    const handleSend = async (event: any) => {
        event.preventDefault();
        if (inputValue.trim()) {
            setInputValue("");
            await sendMessage();

            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    };

    const handleKeyPress = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            await handleSend(event);
        }
    };

    return (
        <div className="p-2 flex justify-between flex-1 w-full items-center gap-2">
            {isConversationLoading ? (
                <>
                    <Skeleton className="h-9 w-full rounded-full bg-gray-200" />{" "}
                    <Skeleton className="h-9 w-9 rounded-full bg-gray-200" />
                </>
            ) : (
                <AnimatePresence initial={false}>
                    <motion.div
                        key="input"
                        className="w-full relative"
                        layout
                        initial={{ opacity: 0, scale: 1 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 1 }}
                        transition={{
                            opacity: { duration: 0.05 },
                            layout: {
                                type: "spring",
                                bounce: 0.15,
                            },
                        }}
                    >
                        <Textarea
                            autoComplete="off"
                            value={inputValue}
                            ref={inputRef}
                            onKeyDown={handleKeyPress}
                            onChange={(e) => setInputValue(e.target.value)}
                            name="message"
                            placeholder="Message..."
                            className=" w-full border rounded-full flex items-center h-9 resize-none overflow-hidden bg-background"
                        ></Textarea>
                        <div className="absolute right-2 bottom-0.5">
                            <EmojiPicker
                                onChange={(value) => {
                                    setInputValue(inputValue + value);
                                    if (inputRef.current) {
                                        inputRef.current.focus();
                                    }
                                }}
                            />
                        </div>
                    </motion.div>

                    {inputValue.trim() ? (
                        <Link
                            to="#"
                            className={cn(
                                buttonVariants({ variant: "ghost", size: "icon" }),
                                "h-9 w-9",
                                "dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white shrink-0",
                            )}
                            onClick={handleSend}
                        >
                            <SendHorizontal size={20} className="text-muted-foreground" />
                        </Link>
                    ) : (
                        <Link
                            to="#"
                            className={cn(
                                buttonVariants({ variant: "ghost", size: "icon" }),
                                "h-9 w-9",
                                "dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white shrink-0",
                            )}
                            onClick={handleThumbsUp}
                        >
                            <ThumbsUp size={20} className="text-muted-foreground" />
                        </Link>
                    )}
                </AnimatePresence>
            )}
        </div>
    );
}
