import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { PropertyForm } from "../addProperty/PropertyForm";
import { Breadcrumbs } from "../addProperty/Breadcrumbs";
import { useParams } from "react-router-dom";
import { useGetBuildingQuery } from "@/redux/rtk-query/properties";
import { Header } from "@/components/common/headers/Header";

export type TAddPropertyPage = "property" | "units" | "unit-listing";

const UpdateProperty = () => {
    const { propertyId } = useParams();
    const { data: buildingData, isLoading: isBuildingLoading } = useGetBuildingQuery(
        { id: propertyId as string, type: "BUILDING" },
        {
            skip: !propertyId,
        },
    );

    const { data: myDetails, isLoading: isUserLoading } = useGetMyDetailsQuery();

    const propertyDetails = {
        propertyName: buildingData?.[0]?.name || "",
        postCode: buildingData?.[0]?.address?.[0]?.postalCode || "",
        address: [
            buildingData?.[0]?.address?.[0]?.mainStreet,
            buildingData?.[0]?.address?.[0]?.country,
            buildingData?.[0]?.address?.[0]?.province,
        ]
            .filter(Boolean)
            .join(", "),
        propertyType: buildingData?.[0]?.category || "",
        propertyOwner: "",
        propertyOwnerPhoneNumber: "",
        propertyOwnerName: "",
        propertyOwnerEmail: "",
        images: buildingData?.[0]?.images || [],
        files: buildingData?.[0]?.files || [],
        city: buildingData?.[0]?.address?.[0]?.city || "",
        country: buildingData?.[0]?.address?.[0]?.country || "",
        ownerId: buildingData?.[0]?.ownerId || "",
        id: buildingData?.[0]?._id || "",
    };

    console.log(buildingData, "buildingDatabuildingDatabuildingData");

    return (
        <div className="w-full h-full zmax-h-screen bg-white overflow-y-auto">
            <Header
                image={myDetails?.imageUrl}
                heading={"Edit Property"}
                firstName={myDetails?.firstName}
                lastName={myDetails?.lastName}
                hasSearchBar={true}
                isloading={isBuildingLoading || isUserLoading}
            />
            <div className="ml-6 mt-4 ">
                <Breadcrumbs
                    name={"Edit Property"}
                    className="mb-10"
                    isLoading={isBuildingLoading || isUserLoading}
                />
                <div className="gap-[104px] w-1/2">
                    <PropertyForm propertyDetails={propertyDetails} />
                </div>
            </div>
        </div>
    );
};

export default UpdateProperty;
