import { BackButton } from "@/components/common/backButton";
import { Heading } from "@/components/common/pageHeading";
import { BoxContent } from "./BoxContent";
import { DashBoardChart } from "./DashBoardChart";
import { Button } from "@/components/ui/button";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import {
    useGetComplianceGraphStatsQuery,
    useGetComplianceStatQuery,
} from "@/redux/rtk-query/properties";
import Box from "@/components/common/Box/Box";

const Compliance = () => {
    const { data: user } = useGetMyDetailsQuery();
    const { data: complianceStat, isLoading } = useGetComplianceStatQuery(user?.companyId || "", {
        skip: !user?.companyId,
    });
    const { data: complianceStatCombined } = useGetComplianceGraphStatsQuery(
        user?.companyId || "",
        {
            skip: !user?.companyId,
        },
    );

    console.log(complianceStatCombined);

    const chartArrays = complianceStat?.flatMap((data: any) => {
        return data?.categoryStats;
    }, 0);

    const item = [
        {
            title: "Action Required",
            profit: true,
            profitNumber: 48.5,
            data: { data: complianceStatCombined?.[0]?.actionRequiredCount ?? 0 },
        },
        {
            title: "Upcoming Expiration",
            profit: false,
            profitNumber: 10.2,
            data: { data: complianceStatCombined?.[0]?.upcomingExpiryCount ?? 0 },
        },
        {
            title: "Portfolio Compilance",
            profit: true,
            profitNumber: 54.23,
            data: { data: complianceStatCombined?.[0]?.totalComplianceCount ?? 0 },
        },
    ];

    const chartArray = chartArrays?.map((item) => {
        return {
            title: item.category,
            chartData: [
                { propertyStatLabel: "Valid", stat: item.validPercentage, fill: "#0DADEA" },
                { propertyStatLabel: "Expired", stat: item.expiredPercentage, fill: "#02E09F" },
                { propertyStatLabel: "Unsafe", stat: item.unsafePercentage, fill: "#00F5F5" },
                {
                    propertyStatLabel: "Not Recorded",
                    stat: item.noRecordPercentage,
                    fill: "lavender",
                },
            ],
            totalTasks: item?.totalTasks,
        };
    });
    console.log(chartArray, "chartArraychartArraychartArray");

    return (
        <div className="mx-5 py-2.5 w-full overflow-scroll">
            <BackButton className="cursor-pointer" />
            <div className="w-100 flex justify-between items-end">
                <Heading
                    as="h1"
                    headingprop={{
                        style: { fontWeight: "500", fontSize: "32px" },
                    }}
                    top={20}
                >
                    Compliance
                </Heading>
                <div className="flex gap-3">
                    <Button className="bg-white text-black hover:bg-[#f6f6f6] border border-gray-300">
                        Report
                    </Button>
                    <Button className="bg-white text-black hover:bg-[#f6f6f6] border border-gray-300">
                        Submit Compliance Request
                    </Button>
                </div>
            </div>
            <div className="flex mt-[20px] gap-[50px] justify-center">
                {item.map((data, index) => (
                    <Box
                        key={index + 1}
                        ismap={false}
                        classes={`flex flex-col justify-between shadow-lg w-[350px] h-[150px] rounded-[15px] bg-white border border-gray-300`}
                    >
                        <BoxContent {...data} />
                    </Box>
                ))}
            </div>
            <main className="flex mt-[20px] mb-[20px] justify-center">
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {chartArray &&
                        chartArray.map((data) => (
                            <DashBoardChart
                                key={data.title}
                                title={data.title}
                                chartData={data.chartData}
                                totalTasks={data.totalTasks}
                            />
                        ))}
                </div>
            </main>
            {isLoading && (
                <div className="flex justify-center items-center h-[50vh] w-full">
                    Data Loading ...
                </div>
            )}
        </div>
    );
};

export default Compliance;
