import React, { useEffect, useRef } from "react";
import styles from "./modal.module.css";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    styleName: string;
    datePickerRef?: React.RefObject<HTMLButtonElement>;
}

export const Modal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    children,
    styleName,
    datePickerRef,
}) => {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node) &&
                !datePickerRef?.current
            ) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose, datePickerRef]);

    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <main className={styles[styleName]} ref={modalRef} onClick={(e) => e.stopPropagation()}>
                <button className={styles.modalCloseButton} onClick={onClose}>
                    <i className="ri-close-fill"></i>
                </button>
                {children}
            </main>
        </div>
    );
};

export default Modal;
