import { Outlet } from "react-router-dom";
import Footer from "../landing/footer";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const LandingLayout = () => {
    const comp = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let ctx = gsap.context(() => {
            const t1 = gsap.timeline();
            t1.from("#nav", {
                opacity: 0,
                duration: 1,
            })
                .from("#hero-title", {
                    opacity: 0,
                    duration: 1,
                })
                .fromTo(
                    ["#hero-desc", "#tab"],
                    { y: 10, opacity: 0 },
                    { y: 0, opacity: 1, duration: 1 },
                );
        }, comp);

        const elementIds = [
            "#mainHeader",
            "#mainDesc",
            "#cardA-1",
            "#cardA-2",
            "#getStartedBtn",
            "#mainHeader2",
            "#mainDesc2",
            "#cardB-1",
            "#cardB-2",
            "#cardB-3",
            "#cardB-4",
            "#mainHeader3",
            "#mainDesc3",
            "#cardC-1",
            "#cardC-2",
        ];

        const elementIds2 = [
            "#mainHeader4",
            "#cardD-1",
            "#cardD-2",
            "#cardD-3",
            "#cardD-4",
            "#cardD-5",
            "#cardD-6",
        ];

        const elementIds3 = [
            "#cardE-1",
            "#cardE-2",
            "#cardE-3",
            "#cardE-4",
            "#cardE-5",
            "#cardE-6",
            "#getStartedBtn2",
            "#mainHeader5",
            "#mainDesc5",
            "#learnMore",
            "#smartBg",
        ];

        // scroll animations
        elementIds.forEach((id, index) => {
            gsap.fromTo(
                id,
                {
                    y: 10 * (index + 1),
                    opacity: 0,
                },
                {
                    y: 0,
                    opacity: 1,
                    duration: 1.5,
                    scrollTrigger: {
                        trigger: id,
                        start: "top 75%",
                        end: "top 25%",
                        toggleActions: "play none none none",
                    },
                },
            );
        });
        elementIds2.forEach((id, index) => {
            gsap.fromTo(
                id,
                {
                    y: 20 * (index + 1),
                    opacity: 0,
                },
                {
                    y: 0,
                    opacity: 1,
                    duration: 1.5,
                    scrollTrigger: {
                        trigger: id,
                        start: "top 75%",
                        end: "top 25%",
                        toggleActions: "play none none none",
                        // toggleActions: "restart pause restart pause",
                    },
                },
            );
        });
        elementIds3.forEach((id, index) => {
            gsap.fromTo(
                id,
                {
                    y: 30 * (index + 1),
                    opacity: 0,
                },
                {
                    y: 0,
                    opacity: 1,
                    duration: 1.5,
                    scrollTrigger: {
                        trigger: id,
                        start: "top 75%",
                        end: "top 25%",
                        toggleActions: "play none none none",
                        // toggleActions: "restart pause restart pause",
                    },
                },
            );
        });

        return () => ctx.revert();
    }, []);

    return (
        <div ref={comp}>
            <Outlet />
            <Footer />
        </div>
    );
};

export default LandingLayout;
