// import { useNavigate } from "react-router-dom";
// import { TimeAgo } from "../../components/common/timeCard/timeAgo";
// import { IConversation, TUser } from "../../types";
// import { getRecipient } from "../../utils/getRecipent";
// import styles from "./chat.module.css";
// import { Button } from "@/components/ui/button";
// import { ScrollArea } from "@/components/ui/scroll-area";
// import { SlCalender } from "react-icons/sl";
// type TProps = {
//     isSelected?: boolean;
//     conversation: IConversation;
//     onClickUser: Function;
//     currentUser?: TUser;
// };

// export const MessageCard = ({
//     conversation,
//     isSelected = false,
//     onClickUser,
//     currentUser,
// }: TProps) => {
//     const recipientUser = getRecipient(conversation.participantDetails, currentUser);
//     const navigate = useNavigate();
//     const truncateMessage = (message: string, maxLength: number) => {
//         if (message.length > maxLength) {
//             return message.substring(0, maxLength) + "...";
//         }
//         return message;
//     };
//     console.log(conversation);

//     return (
//         <div
//             className="p-4"
//             onClick={(e) => {
//                 onClickUser(conversation._id);
//             }}
//         >
//             <div className="flex justify-between items-center mb-4">
//                 <h2 className="text-xl font-semibold">Messages</h2>
//                 <Button
//                     variant="outline"
//                     size="sm"
//                     className="rounded-full flex items-center gap-2 shadow-xl bg-[#f6f6f6]"
//                     onClick={() => {
//                         navigate("/property-management/chat");
//                     }}
//                 >
//                     <SlCalender className="h-4 w-4" /> View All
//                 </Button>
//             </div>
//             <ScrollArea className="h-[400px] rounded-md border">
//             </ScrollArea>
//                 <div className="flex items-center p-3 border-b border-gray-200 hover:bg-gray-50 transition-colors">
//                     <img
//                         src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
//                             recipientUser?.username ?? "",
//                         )}&size=${20}&background=random`}
//                         alt="profile"
//                         className="w-10 h-10 rounded-full mr-3 object-cover"
//                     />
//                     <div className="flex-1 min-w-0">
//                         <div className="flex items-center justify-between h-8 mb-1">
//                             <h3 className="text-sm font-semibold truncate">
//                                 {recipientUser?.username}
//                             </h3>
//                             <div className="flex items-center">
//                                 <span className="text-xs text-gray-400">
//                                     {new Date(conversation.createdAt).toLocaleTimeString("en-US", {
//                                         hour: "numeric",
//                                         minute: "numeric",
//                                         hour12: true,
//                                     })}
//                                 </span>
//                                 {/* {msg.status === "new" && (
//                                         <span className="ml-2 w-2 h-2 bg-red-500 rounded-full"></span>
//                                     )} */}
//                             </div>
//                         </div>
//                         <p className="text-sm text-gray-500 truncate">
//                             {truncateMessage(conversation.title ?? "", 30)}
//                         </p>
//                         <p className="text-sm text-gray-500 truncate">
//                             {conversation.messages?.[0]?.content}
//                         </p>
//                     </div>
//                 </div>
//         </div>
//     );
// };

import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { userData } from "@/app/data";
import { useGetUserConversationsQuery } from "@/redux/rtk-query/chatApis";
import React from "react";
import { Sidebar } from "@/pages/chats/sidebar/Sidebar";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";

export const MessageCard = ({ dashBoard = false, accountType }: any) => {
    const navigate = useNavigate();
    const { data: user } = useGetMyDetailsQuery();

    const [selectedUser] = React.useState(userData[0]);

    const { data: conversationList, isLoading: isConversationLoading } =
        useGetUserConversationsQuery(undefined, {
            refetchOnMountOrArgChange: true,
        });

    const onClickUser = (conversationId: string) => {
        navigate(`/${accountType}/chat`, { state: { conversationId } });
    };

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Messages</h2>

                <Button
                    className="bg-white text-black hover:bg-[#f6f6f6]"
                    onClick={() => {
                        navigate(`/${accountType}/chat`);
                    }}
                >
                    View All
                </Button>
            </div>
            <ScrollArea className="h-[400px] rounded-md border">
                <div className="flex items-center">
                    <Sidebar
                        links={userData.map((user) => ({
                            name: user.name,
                            messages: user.messages ?? [],
                            avatar: user.avatar,
                            variant: selectedUser.name === user.name ? "grey" : "ghost",
                        }))}
                        isOnDashBoard={true}
                        conversationList={conversationList || []}
                        onClickUser={onClickUser}
                        isCollapsed={false}
                        currentUser={user}
                        onChangeSearch={() => {}}
                        search={""}
                        isSelected={""}
                        dashboard={dashBoard}
                        isConversationLoading={isConversationLoading}
                    />
                </div>
            </ScrollArea>
        </div>
    );
};
