import { ComponentProps, memo, useMemo } from "react";
import styles from "./pageHeading.module.css";

type TSize = "sm" | "lg";

type TProps = ComponentProps<"h2"> & {
    as?: "h2" | "h1";
    size?: TSize;
    bottom?: number;
    top?: number;
    headingprop?: Omit<ComponentProps<"input">, "value" | "onChange">;
};

const getSizeStyles = (size: TSize) => {
    switch (size) {
        case "sm":
            return styles.headingSm;
        case "lg":
            return styles.headingLg;
    }
};

export const Heading = memo((props: TProps) => {
    const {
        children,
        as: Element = "h2",
        size = "sm",
        className,
        bottom = 0,
        top = 0,
        headingprop,
        ...rest
    } = props;

    const sizeStyles = useMemo(() => getSizeStyles(size), [size]);

    return (
        <Element
            className={`${sizeStyles} ${className}`}
            {...rest}
            style={{ ...headingprop?.style, marginTop: top, marginBottom: bottom }}
        >
            {children}
        </Element>
    );
});
