import SearchResult, { SearchResults } from "@/components/common/headers/SearchResult";
import { searchData } from "@/components/common/headers/SearchResults.constants";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import UserProfile from "@/components/userProfile/UserProfile";
import { cn } from "@/lib/utils";
import { capitalizeEachWord } from "@/utils/capitalizeEachWords";
import { MessageSquareMore, Search } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import Notification from "../notification/notification";

type TProps = {
    heading: string | undefined;
    image: string | undefined;
    username?: string | undefined;
    className?: string;
    hasSearchBar?: boolean;
    firstName?: string | undefined;
    lastName?: string | undefined;
    isloading?: boolean | undefined;
};

export const Header = ({ hasSearchBar = false, ...props }: TProps) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState<SearchResults[]>([]);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const searchContainerRef = useRef<HTMLDivElement>(null);
    const handleSearch = (query: string) => {
        setSearchQuery(query);
        if (query.trim() === "") {
            setSearchResults([]);
            setIsSearchVisible(false);
            return;
        }

        // Perform search on the searchData array
        const results = searchData.filter(
            (item) =>
                item.title.toLowerCase().includes(query.toLowerCase()) ||
                item.description.toLowerCase().includes(query.toLowerCase()),
        );
        setSearchResults(results);
        setIsSearchVisible(true);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                searchContainerRef.current &&
                !searchContainerRef.current.contains(event.target as Node)
            ) {
                setIsSearchVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const notifications = useMemo(() => {
        return Array.from({ length: 10 }).map((_item, index) => {
            return {
                id: index,
                content:
                    "This is a test notification, I am trying to see how it look on the main component",
                timestamp: "2 days ago",
            };
        });
    }, []);
    return (
        <header
            className={cn(
                "h-[65px] px-4 py-[14.5px] flex items-center justify-between w-full",
                "sticky top-0 z-50",
                "bg-white/70 backdrop-blur-sm",
                "border-b border-b-zinc-200",
                "h-[65px] px-4 py-[14.5px] flex items-center justify-between w-full",
                "sticky top-0 z-50",
                "bg-white/70 backdrop-blur-sm",
                "border-b border-b-zinc-200",
                props.className,
            )}
        >
            {props.isloading ? (
                <Skeleton className="h-8 w-[150px] bg-gray-300" />
            ) : props.heading ? (
                <h2 className="font-medium text-lg leading-[26px] text-black">
                    {capitalizeEachWord(props.heading)}
                </h2>
            ) : (
                <div></div>
            )}
            <div className="flex gap-4 items-center">
                {/* {hasSearchBar && (
                    <div
                        className="flex w-[281px] max-w-sm items-center relative"
                        ref={searchContainerRef}
                    >
                        <Search className="absolute left-3 max-h-9" />
                        <Input
                            type="text"
                            placeholder="Search..."
                            className="!pl-12"
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                        <SearchResult
                            results={searchResults}
                            isVisible={isSearchVisible}
                            onClose={() => setIsSearchVisible(false)}
                            onResultClick={() => setIsSearchVisible(false)}
                        />
                    </div>
                )} */}
                {/* {props.isloading ? (
                    <Skeleton className="h-[2.5rem] w-[2.5rem] rounded-full bg-gray-300" />
                ) : (
                    <Notification
                        notifications={notifications}
                        renderNotification={(item: any) => {
                            return (
                                <div className="flex flex-col">
                                    <p className="text-sm leading-5">{item.content}</p>
                                    <p className="text-xs text-gray-500 mt-2">{item.timestamp}</p>
                                </div>
                            );
                        }}
                    />
                )}
                {props.isloading ? (
                    <Skeleton className="h-[2rem] w-[2rem] rounded-md bg-gray-300" />
                ) : (
                    <div className="flex justify-center items-center w-9 h-9 min-h-[36px] max-h-9 rounded-md bg-[rgba(0,0,0,0.04)]">
                        <MessageSquareMore width={24} height={24} />
                    </div>
                )} */}
                {props.isloading ? (
                    <Skeleton className="h-[2.5rem] w-[5rem] rounded-full bg-gray-300" />
                ) : (
                    <UserProfile />
                )}
            </div>
        </header>
    );
};
