import { Link, useLocation, useNavigate } from "react-router-dom";
import { Ellipsis, LogOut } from "lucide-react";
import { cn } from "@/lib/utils";
import { getMenuList } from "./menu-list";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { CollapseMenuButton } from "./collapse-menu-button";
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from "@/components/ui/tooltip";
import { useDispatch } from "react-redux";
import { api } from "@/redux/rtk-query/api";
import { clearLocalStorage } from "@/utils/service";

interface MenuProps {
    isOpen: boolean | undefined;
}

export function Menu({ isOpen }: MenuProps) {
    const location = useLocation();
    const pathname = location.pathname;
    const menuList = getMenuList(pathname);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <ScrollArea className="[&>div>div[style]]:!block">
            <nav className="h-full w-full">
                <ul className="flex flex-col min-h-[calc(100vh-48px-36px-16px-32px)] lg:min-h-[calc(100vh-32px-40px-32px)] items-start space-y-1">
                    {menuList.slice(0, -1).map(({ groupLabel, menus }, index) => (
                        <li className={cn("w-full", groupLabel ? "pt-5" : "")} key={index}>
                            {(isOpen && groupLabel) || isOpen === undefined ? (
                                <p className="text-sm font-medium text-muted-foreground px-4 pb-2 max-w-[248px] truncate">
                                    {groupLabel}
                                </p>
                            ) : !isOpen && isOpen !== undefined && groupLabel ? (
                                <TooltipProvider>
                                    <Tooltip delayDuration={100}>
                                        <TooltipTrigger className="w-full">
                                            <div className="w-full flex justify-center items-center">
                                                <Ellipsis className="h-5 w-5" />
                                            </div>
                                        </TooltipTrigger>
                                        <TooltipContent side="right">
                                            <p>{groupLabel}</p>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            ) : (
                                <></>
                            )}
                            {menus.map(({ href, label, icon: Icon, active, submenus }, index) =>
                                submenus.length === 0 ? (
                                    <div className="w-full" key={index}>
                                        <TooltipProvider disableHoverableContent>
                                            <Tooltip delayDuration={100}>
                                                <TooltipTrigger asChild>
                                                    <Button
                                                        variant={active ? undefined : "ghost"}
                                                        className={`w-full justify-start h-10 mb-1 !px-3 hover:!bg-[#eceff3] font-medium text-sm leading-[150%]  ${active ? "text-white" : "text-[#374151]"}`}
                                                        asChild
                                                    >
                                                        <Link
                                                            to={href}
                                                            className="flex items-center !mb-0"
                                                        >
                                                            <span
                                                                className={cn(
                                                                    isOpen === false ? "" : "mr-3",
                                                                )}
                                                            >
                                                                <Icon size={20} />
                                                            </span>
                                                            <p
                                                                className={cn(
                                                                    "max-w-[200px] truncate",
                                                                    isOpen === false
                                                                        ? "-translate-x-96 opacity-0"
                                                                        : "translate-x-0 opacity-100",
                                                                )}
                                                            >
                                                                {label}
                                                            </p>
                                                        </Link>
                                                    </Button>
                                                </TooltipTrigger>
                                                {isOpen === false && (
                                                    <TooltipContent side="right">
                                                        {label}
                                                    </TooltipContent>
                                                )}
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                ) : (
                                    <div className="w-full" key={index}>
                                        <CollapseMenuButton
                                            icon={Icon}
                                            label={label}
                                            active={active}
                                            submenus={submenus}
                                            isOpen={isOpen}
                                        />
                                    </div>
                                ),
                            )}
                        </li>
                    ))}
                    <div className="w-full grow flex items-end">
                        <div className="w-full grow">
                            {menuList
                                .slice(-1)[0]
                                .menus.map(({ label, icon: Icon, active, submenus }, index) => {
                                    return (
                                        <div className="w-full" key={index}>
                                            <CollapseMenuButton
                                                icon={Icon}
                                                label={label}
                                                active={active}
                                                submenus={submenus}
                                                isOpen={isOpen}
                                            />
                                        </div>
                                    );
                                })}
                            <TooltipProvider disableHoverableContent>
                                <Tooltip delayDuration={100}>
                                    <TooltipTrigger asChild>
                                        <Button
                                            onClick={() => {
                                                clearLocalStorage();
                                                dispatch(api.util.resetApiState());
                                                navigate("/login");
                                            }}
                                            variant="ghost"
                                            className="w-full h-10 justify-start font-medium text-sm leading-[150%] !px-3 hover:!bg-[#eceff3]"
                                        >
                                            <span className={cn(isOpen === false ? "" : "mr-3")}>
                                                <LogOut size={18} />
                                            </span>
                                            <p
                                                className={cn(
                                                    "whitespace-nowrap",
                                                    isOpen === false
                                                        ? "opacity-0 hidden"
                                                        : "opacity-100",
                                                )}
                                            >
                                                Logout
                                            </p>
                                        </Button>
                                    </TooltipTrigger>
                                    {isOpen === false && (
                                        <TooltipContent side="right">Sign out</TooltipContent>
                                    )}
                                </Tooltip>
                            </TooltipProvider>
                        </div>
                    </div>
                </ul>
            </nav>
        </ScrollArea>
    );
}
