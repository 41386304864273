import { toast } from "react-toastify";
import { catchAll } from "../../utils/catch";
import { api } from "./api";
import { TCompany } from "../../types";

const createCompany = api.injectEndpoints({
    endpoints: (builder) => ({
        createCompany: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `auth/companies`,
                    body: data,
                    method: "POST",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Something Went wrong! please try again later.");
                    return;
                } else {
                    toast.success("company data successfully stored.");
                }
            },
        }),
        updateCompany: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `auth/companies`,
                    body: data,
                    method: "PUT",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    console.log(response.error);

                    toast.error("Something Went wrong! please try again later.");
                    return;
                } else {
                    toast.success("Company data successfully updated.");
                }
            },
        }),
        createUserCompany: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `auth/user-companies`,
                    body: data,
                    method: "POST",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Something Went wrong! please try again later.");
                    return;
                } else {
                    toast.success("User-Company data successfully stored.");
                }
            },
        }),

        getCompanies: builder.query<TCompany[], void>({
            providesTags: ["COMPANIES"],
            query: () => {
                return {
                    url: `auth/companies`,
                    method: "GET",
                };
            },
        }),
    }),
});

export const {
    useCreateCompanyMutation,
    useUpdateCompanyMutation,
    useCreateUserCompanyMutation,
    useGetCompaniesQuery,
    useLazyGetCompaniesQuery,
} = createCompany;
