import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis } from "recharts";

import { CardContent } from "@/components/ui/card";
import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart";

// const chartData = [
//     { month: "Sunday", Compliance: 186, Miscellaneous: 80 },
//     { month: "Monday", Compliance: 120, Miscellaneous: 150 },
//     { month: "Tuesday", Compliance: 130, Miscellaneous: 120 },
//     { month: "Wednesday", Compliance: 73, Miscellaneous: 190 },
//     { month: "Thursday", Compliance: 209, Miscellaneous: 130 },
//     { month: "Friday", Compliance: 214, Miscellaneous: 140 },
// ];

// const chartConfig = {
//     compliance: {
//         label: "Compliance",
//         color: "#019CDF",
//     },
//     miscellaneous: {
//         label: "Miscellaneous",
//         color: "#BAE9FD",
//     },
// } satisfies ChartConfig;

interface BarChartData {
    month: string;
    [key: string]: number | string;
}

interface BarChartsProps {
    data: BarChartData[];
    config: ChartConfig;
}

const CustomLegend = ({ payload }: any) => {
    return (
        <ul className={`flex justify-center mt-[10px] gap-2`}>
            {payload.map((entry: any, index: number) => (
                <li key={`item-${index}`} className="flex items-center space-x-2 mt-1">
                    <span
                        className="inline-block w-3 h-3 rounded-full"
                        style={{ backgroundColor: entry.color }}
                    />
                    <span className="text-black flex items-center space-x-1 text-[12px]">
                        <span>{entry.payload.label}</span>
                        <span>{entry.value}</span>
                    </span>
                </li>
            ))}
        </ul>
    );
};

export function BarCharts({ data, config }: BarChartsProps) {
    return (
        <CardContent>
            <ChartContainer config={config} className="mx-auto max-h-[250px]">
                <BarChart data={data}>
                    <CartesianGrid vertical={false} />
                    <XAxis
                        dataKey="month"
                        tickLine={false}
                        tickMargin={10}
                        axisLine={false}
                        tickFormatter={(value) => value.slice(0, 3)}
                    />
                    <YAxis tickLine={false} axisLine={false} />
                    <ChartTooltip
                        cursor={false}
                        content={<ChartTooltipContent indicator="dashed" />}
                    />
                    {Object.keys(config).map((key) => (
                        <Bar key={key} dataKey={key} fill={config[key].color} radius={4} />
                    ))}
                    <Legend content={CustomLegend} verticalAlign="bottom" height={10} />
                </BarChart>
            </ChartContainer>
        </CardContent>
    );
}
