import React from "react";
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import {
    Command,
    CommandInput,
    CommandList,
    CommandEmpty,
    CommandGroup,
    CommandItem,
} from "@/components/ui/command";
import { ChevronDown, Check } from "lucide-react";
import { cn } from "@/lib/utils";
import { Control, UseFormWatch, UseFormSetValue } from "react-hook-form";

type AddressFormProps = {
    control: Control<any>;
    watch: UseFormWatch<any>;
    setValue: UseFormSetValue<any>;
    autocompleteInputRef: React.RefObject<HTMLInputElement>;
    addressSelected: boolean;
    countries: string[];
    setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
    popoverOpen: boolean;
};

const AutoCompleteAddress: React.FC<AddressFormProps> = ({
    control,
    watch,
    setValue,
    autocompleteInputRef,
    addressSelected,
    countries,
    setPopoverOpen,
    popoverOpen,
}) => {
    return (
        <>
            <FormField
                control={control}
                name="address"
                render={({ field }) => (
                    <FormItem className="flex-1">
                        <FormLabel className="mb-2 text-[#09090B]">Address</FormLabel>
                        <FormControl>
                            <Input placeholder="Address 1" {...field} ref={autocompleteInputRef} />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            {/* <FormField
                control={control}
                name="address 2"
                render={() => (
                    <FormItem className="flex-1">
                        <FormControl>
                            <Input id="address2" placeholder="Address 2" />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            /> */}

            <div className="flex gap-2 flex-wrap my-6 w-full">
                <FormField
                    control={control}
                    name="city"
                    render={({ field }) => (
                        <FormItem className="flex-1">
                            <FormLabel className="mb-2 text-[#09090B]">City</FormLabel>
                            <FormControl>
                                <Input
                                    placeholder="Enter City"
                                    {...field}
                                    disabled={!addressSelected}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={control}
                    name="postCode"
                    render={({ field }) => (
                        <FormItem className="flex-1">
                            <FormLabel className="mb-2 text-[#09090B]">Post code</FormLabel>
                            <FormControl>
                                <Input
                                    placeholder="Enter post code"
                                    {...field}
                                    disabled={!addressSelected}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            <FormField
                control={control}
                name="country"
                render={({ field }) => (
                    <FormItem className="mb-16">
                        <FormLabel className="mb-2 text-[#09090B]">Country</FormLabel>
                        <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
                            <PopoverTrigger asChild>
                                <Button
                                    variant="outline"
                                    role="combobox"
                                    className="border shadow-none border-zinc-200 font-normal text-gray-500 hover:text-gray-500 p-5 w-[310px] justify-between flex px-3"
                                    onClick={() => setPopoverOpen(!popoverOpen)}
                                    disabled={!addressSelected}
                                >
                                    {field.value ? watch("country") : "Select country"}
                                    <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-[310px] p-0">
                                <Command className="relative">
                                    <CommandInput placeholder="Search country..." />
                                    <CommandList>
                                        <CommandEmpty>No country found</CommandEmpty>
                                        <CommandGroup className="pb-11">
                                            {countries.map((country: string) => (
                                                <CommandItem
                                                    key={country}
                                                    value={country}
                                                    onSelect={() => {
                                                        setValue("country", country);
                                                        setPopoverOpen(false);
                                                    }}
                                                >
                                                    <Check
                                                        className={cn(
                                                            "mr-2 h-4 w-4",
                                                            watch("country") === country
                                                                ? "opacity-100"
                                                                : "opacity-0",
                                                        )}
                                                    />
                                                    {country}
                                                </CommandItem>
                                            ))}
                                        </CommandGroup>
                                    </CommandList>
                                </Command>
                            </PopoverContent>
                        </Popover>
                        <FormMessage />
                    </FormItem>
                )}
            />
        </>
    );
};

export default AutoCompleteAddress;
