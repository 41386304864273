import { memo } from "react";
import { Logo } from "../../assets/icons";
import { Button } from "./button";
import styles from "./homeOwnerPostAJob.module.css";

type TProps = {
    hasResult: boolean;
    onClickPrimary?: () => void;
};

export const PostAJobNavigation = memo((props: TProps) => {
    return (
        <nav className={styles.navigation}>
            <Logo fill="#22BCFF" />
            {props.hasResult && (
                <div className={styles.buttonContainer}>
                    <Button variant="secondary">Preview</Button>
                    <Button onClick={props.onClickPrimary}>Post a job</Button>
                </div>
            )}
        </nav>
    );
});
