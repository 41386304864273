import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { CloseIcon } from "@/assets/icons/CloseIcon";
import { CiCircleInfo } from "react-icons/ci";
import { Textarea } from "@/components/ui/textarea";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { memo } from "react";
import { ScriptFormPropType } from "./FormTypes";
import { Label } from "@/components/ui/label";

const ScriptForm = memo(
    ({
        setAddScriptClicked,
        type,
        setConfigureScriptClicked,
        setEditResponseClicked,
        form,
        isApiRequired,
        submitHandler,
    }: ScriptFormPropType) => {
        const closeHandler = () => {
            setEditResponseClicked(false);
            setAddScriptClicked(false);
        };

        const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            const fieldsToValidate = ["title", "question", "answer"];
            if (isApiRequired === "yes") {
                fieldsToValidate.push("isApiRequired");
            }
            const isValid = await form.trigger(["title", "question", "answer"]);
            if (isValid) {
                if (isApiRequired === "yes") {
                    setConfigureScriptClicked(true);
                } else {
                    form.handleSubmit(submitHandler)();
                }
            }
        };

        return (
            <div className="rounded-xl bg-white top-50 left-50 -translate-y-[50%] -translate-x-[50%] absolute py-6 px-4 overflow-y-auto opacity-100 max-w-6xl w-[400px] h-[500px]">
                <div className="flex items-center justify-between">
                    <h1 className="font-bold text-lg">
                        {type === "Edit" ? "Edit Script" : "Add Script"}
                    </h1>
                    <div className="cursor-pointer">
                        <CloseIcon onClick={closeHandler} />
                    </div>
                </div>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(() => {})}>
                        <FormField
                            control={form.control}
                            name="title"
                            render={({ field }) => (
                                <FormItem className="mt-4">
                                    <Label>Script Title</Label>
                                    <FormControl>
                                        <Input
                                            className="border h-9 focus-visible:ring-1 focus-visible:ring-offset-0"
                                            placeholder="Enter Title"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage className="text-[10px]" />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="question"
                            render={({ field }) => (
                                <FormItem className="mt-4">
                                    <Label>Question</Label>
                                    <FormControl>
                                        <Input
                                            className="border h-9 focus-visible:ring-1 focus-visible:ring-offset-0"
                                            placeholder="Enter Question"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage className="text-[10px]" />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="answer"
                            render={({ field }) => (
                                <FormItem className="mt-4">
                                    <Label className="flex gap-1 items-center">
                                        Answer
                                        <CiCircleInfo />
                                    </Label>
                                    <FormControl>
                                        <Textarea required {...field} placeholder="Enter answer" />
                                        {/* <Input
                                            className="border h-9 focus-visible:ring-1 focus-visible:ring-offset-0"
                                            placeholder="Enter Question"
                                            {...field}
                                        /> */}
                                    </FormControl>
                                    <FormMessage className="text-[10px]" />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="isApiRequired"
                            render={({ field }) => (
                                <FormItem className="mt-4">
                                    <Label>Is an API required to access the answer ?</Label>
                                    <Select
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                    >
                                        <FormControl>
                                            <SelectTrigger className="border h-9 focus:ring-1 focus:ring-offset-0">
                                                <SelectValue placeholder="Select an option" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectItem className="text-xs" value="yes">
                                                Yes
                                            </SelectItem>
                                            <SelectItem className="text-xs" value="no">
                                                No
                                            </SelectItem>
                                        </SelectContent>
                                    </Select>
                                    <FormMessage className="text-[10px]" />
                                </FormItem>
                            )}
                        />
                        <p className="text-xs mt-2 text-[#727272]">
                            If you are unsure, please contact{" "}
                            <span className="font-bold">team@lightwork.blue</span>
                        </p>
                        <div className="items-center justify-end flex mt-6">
                            <Button className="rounded-xl" type="button" onClick={handleSubmit}>
                                {isApiRequired === "yes"
                                    ? "Configure Script"
                                    : type === "Add"
                                      ? "Add"
                                      : "Edit"}
                            </Button>
                        </div>
                    </form>
                </Form>
            </div>
        );
    },
);

export default ScriptForm;
