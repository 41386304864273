// import { Button } from "@/components/ui/button";
// import { Skeleton } from "@/components/ui/skeleton";
// import { TabsContent } from "@/components/ui/tabs";
// import { useGetScriptsWebQuery } from "@/redux/rtk-query/knowledgeApi";
// import { Key, useState } from "react";
// import ResponseModal from "../modal/ResponseModal";
// import { Building, Layers2 } from "lucide-react";

// interface ResponseTabProps {
//     companyId: string | undefined;
//     isLoadingUser: boolean;
// }

// export const ResponseTab: React.FC<ResponseTabProps> = ({ companyId, isLoadingUser }) => {
//     const {
//         data: scripts,
//         // refetch: refetchScripts,
//         isLoading,
//     } = useGetScriptsWebQuery({ companyId: companyId || "", pageSize: 500 }, { skip: !companyId });
//     const [open, setOpen] = useState(false);

//     console.log(scripts, "opooooo");

//     return (
//         <TabsContent value="responses" className="p-4">
//             <div className="space-y-4">
//                 <div>
//                     <div className="flex items-center justify-between">
//                         <div className="mr-[100px]">
//                             <h4 className="font-semibold text-gray-900 text-lg">
//                                 Custom Responses ({scripts?.data?.length || 0})
//                             </h4>
//                             <div className="text-sm text-gray-500 w-[400px]">
//                                 Create and manage a list of predefined tenant questions and answers
//                                 for quick, AI-driven responses.
//                             </div>
//                         </div>
//                         <Button
//                             className="bg-black text-white px-4 py-2 rounded-md"
//                             onClick={() => setOpen(true)}
//                         >
//                             Add Response
//                         </Button>

//                         <ResponseModal open={open} setOpen={setOpen} />
//                     </div>
//                 </div>
//                 {isLoading || isLoadingUser ? (
//                     <div className="space-y-2">
//                         {Array.from({ length: 10 }).map((_, index) => (
//                             <div
//                                 key={index}
//                                 className="px-4 py-2 border border-gray-200 rounded-md"
//                             >
//                                 <Skeleton className="font-medium my-1 h-5 bg-gray-300 w-[160px]" />
//                                 <Skeleton className="text-[16px] bg-gray-300 my-1 h-4" />
//                                 <Skeleton className="text-[16px] bg-gray-300 my-1 h-4 w-[500px]" />
//                                 <div className="mt-3 flex gap-4">
//                                     <Skeleton className="text-[16px] bg-gray-300 my-1 h-5 w-[120px]" />
//                                     <Skeleton className="text-[16px] bg-gray-300 my-1 h-5 w-[90px]" />
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 ) : (
//                     // scripts?.data?.map((data: any, index: Key | null | undefined) => (
//                     //     <div key={index} className="px-4 py-2 border border-gray-200 rounded-md">
//                     //         <p className="font-medium my-1">{data?.questions}</p>
//                     //         <p className="text-[16px] text-gray-600 my-1">{data?.answer}</p>

//                     //         {/* <div className="flex items-center gap-2">
//                     //             <div className="flex justify-start items-center gap-1 bg-gray-200 p-2 rounded-md min-w-[50px] h-7 text-sm">
//                     //                 <Building className="h-4 w-4" />
//                     //                 propety 1
//                     //                 <div className="flex items-center justify-center">
//                     //                     <div className="border-r border-gray-500 h-4 mr-2"></div>
//                     //                     <div className="flex gap-2 items-center rounded-sm p-1 text-sm">
//                     //                         <Layers2 className="h-4 w-4" />
//                     //                         Hello
//                     //                     </div>
//                     //                     <div className="flex gap-2 items-center rounded-sm p-1 text-sm">
//                     //                         <Layers2 className="h-4 w-4" />
//                     //                         Hello
//                     //                     </div>
//                     //                 </div>
//                     //             </div>
//                     //         </div> */}
//                     //         <div className="flex items-center gap-2">
//                     //             {data?.buildingIds?.map((building: any) =>
//                     //                 data?.propertyIds
//                     //                     ?.filter(
//                     //                         (property: any) =>
//                     //                             property?.companyId === building?.companyId,
//                     //                     )
//                     //                     ?.map((property: any) => (
//                     //                         <div
//                     //                             key={property?._id}
//                     //                             className="flex justify-start items-center gap-1 bg-gray-200 p-2 rounded-md min-w-[50px] h-7 text-sm"
//                     //                         >
//                     //                             <Building className="h-4 w-4" />
//                     //                             {/* Dynamically set the property name */}
//                     //                             {property?.name}
//                     //                             <div className="flex items-center justify-center">
//                     //                                 <div className="border-r border-gray-500 h-4 mr-2"></div>
//                     //                                 {}
//                     //                             </div>
//                     //                         </div>
//                     //                     )),
//                     //             )}
//                     //         </div>
//                     //     </div>
//                     // ))
//                     scripts?.data?.map((data: any, index: Key | null | undefined) => (
//                         <div key={index} className="px-4 py-2 border border-gray-200 rounded-md">
//                             <p className="font-medium my-1">{data?.questions}</p>
//                             <p className="text-[16px] text-gray-600 my-1">{data?.answer}</p>

//                             <div className="flex flex-wrap gap-2">
//                                 {data?.propertyIds?.map((property: any) => {
//                                     const matchingBuildings = data?.buildingIds?.filter(
//                                         (building: any) =>
//                                             building?.companyId === property?.companyId,
//                                     );

//                                     return (
//                                         <div
//                                             key={property?._id}
//                                             className="flex justify-start items-center gap-1 bg-gray-100 p-2 rounded-md min-w-[50px] h-7 text-sm"
//                                         >
//                                             <Building className="h-4 w-4" />

//                                             {property?.name}

//                                             {matchingBuildings?.length > 0 && (
//                                                 <div className="flex items-center justify-center">
//                                                     <div className="border-r border-gray-500 h-4 mr-2"></div>
//                                                     {matchingBuildings?.map((building: any) => (
//                                                         <div
//                                                             key={building?._id}
//                                                             className="flex gap-2 items-center rounded-sm p-1 text-sm"
//                                                         >
//                                                             <Layers2 className="h-4 w-4" />
//                                                             {building?.name}{" "}
//                                                         </div>
//                                                     ))}
//                                                 </div>
//                                             )}
//                                         </div>
//                                     );
//                                 })}
//                             </div>
//                         </div>
//                     ))
//                 )}
//             </div>
//         </TabsContent>
//     );
// };

// // <div className="flex gap-2 items-center rounded-sm p-1 text-sm">
// //     <Layers2 className="h-4 w-4" />
// //     Unit Name 1{" "}
// //     {/* You can replace this with the actual unit name */}
// // </div>
// // <div className="flex gap-2 items-center rounded-sm p-1 text-sm">
// //     <Layers2 className="h-4 w-4" />
// //     Unit Name 2{" "}
// //     {/* Another unit, if applicable */}
// // </div>

import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { TabsContent } from "@/components/ui/tabs";
import { useGetScriptsWebQuery } from "@/redux/rtk-query/knowledgeApi";
import { Key, useState } from "react";
import ResponseModal from "../modal/ResponseModal";
import { Building, Layers2 } from "lucide-react";

interface ResponseTabProps {
    companyId: string | undefined;
    isLoadingUser: boolean;
}

export const ResponseTab: React.FC<ResponseTabProps> = ({ companyId, isLoadingUser }) => {
    const {
        data: scripts,
        // refetch: refetchScripts,
        isLoading,
    } = useGetScriptsWebQuery({ companyId: companyId || "", pageSize: 500 }, { skip: !companyId });
    const [open, setOpen] = useState(false);

    console.log(scripts, "opooooo");

    return (
        <TabsContent value="responses" className="p-4">
            <div className="space-y-4">
                <div>
                    <div className="flex items-center justify-between">
                        <div className="mr-[100px]">
                            <h4 className="font-semibold text-gray-900 text-lg">
                                Custom Responses ({scripts?.data?.length || 0})
                            </h4>
                            <div className="text-sm text-gray-500 w-[400px]">
                                Create and manage a list of predefined tenant questions and answers
                                for quick, AI-driven responses.
                            </div>
                        </div>
                        <Button
                            className="bg-black text-white px-4 py-2 rounded-md"
                            onClick={() => setOpen(true)}
                        >
                            Add Response
                        </Button>

                        <ResponseModal open={open} setOpen={setOpen} />
                    </div>
                </div>
                {isLoading || isLoadingUser ? (
                    <div className="space-y-2">
                        {Array.from({ length: 10 }).map((_, index) => (
                            <div
                                key={index}
                                className="px-4 py-2 border border-gray-200 rounded-md"
                            >
                                <Skeleton className="font-medium my-1 h-5 bg-gray-300 w-[160px]" />
                                <Skeleton className="text-[16px] bg-gray-300 my-1 h-4" />
                                <Skeleton className="text-[16px] bg-gray-300 my-1 h-4 w-[500px]" />
                                <div className="mt-3 flex gap-4">
                                    <Skeleton className="text-[16px] bg-gray-300 my-1 h-5 w-[120px]" />
                                    <Skeleton className="text-[16px] bg-gray-300 my-1 h-5 w-[90px]" />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    // scripts?.data?.map((data: any, index: Key | null | undefined) => (
                    //     <div key={index} className="px-4 py-2 border border-gray-200 rounded-md">
                    //         <p className="font-medium my-1">{data?.questions}</p>
                    //         <p className="text-[16px] text-gray-600 my-1">{data?.answer}</p>

                    //         {/* <div className="flex items-center gap-2">
                    //             <div className="flex justify-start items-center gap-1 bg-gray-200 p-2 rounded-md min-w-[50px] h-7 text-sm">
                    //                 <Building className="h-4 w-4" />
                    //                 propety 1
                    //                 <div className="flex items-center justify-center">
                    //                     <div className="border-r border-gray-500 h-4 mr-2"></div>
                    //                     <div className="flex gap-2 items-center rounded-sm p-1 text-sm">
                    //                         <Layers2 className="h-4 w-4" />
                    //                         Hello
                    //                     </div>
                    //                     <div className="flex gap-2 items-center rounded-sm p-1 text-sm">
                    //                         <Layers2 className="h-4 w-4" />
                    //                         Hello
                    //                     </div>
                    //                 </div>
                    //             </div>
                    //         </div> */}
                    //         <div className="flex items-center gap-2">
                    //             {data?.buildingIds?.map((building: any) =>
                    //                 data?.propertyIds
                    //                     ?.filter(
                    //                         (property: any) =>
                    //                             property?.companyId === building?.companyId,
                    //                     )
                    //                     ?.map((property: any) => (
                    //                         <div
                    //                             key={property?._id}
                    //                             className="flex justify-start items-center gap-1 bg-gray-200 p-2 rounded-md min-w-[50px] h-7 text-sm"
                    //                         >
                    //                             <Building className="h-4 w-4" />
                    //                             {/* Dynamically set the property name */}
                    //                             {property?.name}
                    //                             <div className="flex items-center justify-center">
                    //                                 <div className="border-r border-gray-500 h-4 mr-2"></div>
                    //                                 {}
                    //                             </div>
                    //                         </div>
                    //                     )),
                    //             )}
                    //         </div>
                    //     </div>
                    // ))
                    scripts?.data?.map((data: any, index: Key | null | undefined) => (
                        <div key={index} className="px-4 py-2 border border-gray-200 rounded-md">
                            <p className="font-medium my-1">{data?.questions}</p>
                            <p className="text-[16px] text-gray-600 my-1">{data?.answer}</p>

                            <div className="flex flex-wrap gap-2">
                                {data?.buildingIds?.map((building: any) => {
                                    const matchingBuildings = data?.propertyIds?.filter(
                                        (property: any) => property?.buildingId === building?._id,
                                    );

                                    return (
                                        <div
                                            key={building?._id}
                                            className="flex justify-start items-center gap-1 bg-gray-100 p-2 rounded-md min-w-[50px] h-7 text-sm"
                                        >
                                            <Building className="h-4 w-4" />

                                            {building?.name}

                                            {matchingBuildings?.length > 0 && (
                                                <div className="flex items-center justify-center">
                                                    <div className="border-r border-gray-500 h-4 mr-2"></div>
                                                    {matchingBuildings?.map((property: any) => (
                                                        <div
                                                            key={property?._id}
                                                            className="flex gap-2 items-center rounded-sm p-1 text-sm"
                                                        >
                                                            <Layers2 className="h-4 w-4" />
                                                            {property?.name}{" "}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))
                )}
            </div>
        </TabsContent>
    );
};

// <div className="flex gap-2 items-center rounded-sm p-1 text-sm">
//     <Layers2 className="h-4 w-4" />
//     Unit Name 1{" "}
//     {/* You can replace this with the actual unit name */}
// </div>
// <div className="flex gap-2 items-center rounded-sm p-1 text-sm">
//     <Layers2 className="h-4 w-4" />
//     Unit Name 2{" "}
//     {/* Another unit, if applicable */}
// </div>
