import React, { useState } from "react";

type ButtonProps = {
    icon: React.ReactNode;
    onClick: () => void;
    colorClass?: string;
};

type ExpandableButtonProps = {
    mainIcon: React.ReactNode;
    buttons: ButtonProps[];
    isChatClicked?: () => void;
};

const ExpandableButton: React.FC<ExpandableButtonProps> = ({
    mainIcon,
    buttons,
    isChatClicked,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
        isChatClicked && isChatClicked();
    };

    return (
        <div className="fixed bottom-1 right-1 flex flex-col items-center">
            <div className="relative flex flex-col items-center mt-2 space-y-2">
                {buttons.map((button, index) => (
                    <button
                        key={index}
                        className={`${button.colorClass || "bg-gray-500"}
              text-white p-3 rounded-full shadow-lg hover:bg-opacity-80
              transition-all duration-300 ease-out transform
              ${isExpanded ? `translate-y-${(index + 1) * 60}px opacity-100` : "translate-y-20 hidden"}
              `}
                        onClick={button.onClick}
                        style={{ transitionDelay: `${index * 0.1}s` }}
                    >
                        {button.icon}
                    </button>
                ))}
            </div>
            <button
                className="bg-black text-white p-4 rounded-full shadow-lg hover:bg-black transition-transform duration-300 mt-2"
                onClick={toggleExpand}
            >
                {mainIcon}
            </button>
        </div>
    );
};

export default ExpandableButton;

// import React, { useState } from "react";

// type ButtonProps = {
//     icon: React.ReactNode;
//     onClick: () => void;
//     colorClass?: string;
// };

// type ExpandableButtonProps = {
//     mainIcon: React.ReactNode;
//     handleClick: () => void;
// };

// const ExpandableButton: React.FC<ExpandableButtonProps> = ({ mainIcon, handleClick }) => {
//     return (
//         <div className="fixed bottom-1 right-1 flex flex-col items-center">
//             <div className="relative flex flex-col items-center mt-2 space-y-2"></div>
//             <button
//                 className="bg-black text-white p-4 rounded-full shadow-lg hover:bg-black transition-transform duration-300 mt-2"
//                 onClick={handleClick}
//             >
//                 {mainIcon}
//             </button>
//         </div>
//     );
// };

// export default ExpandableButton;
