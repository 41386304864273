import { toast } from "react-toastify";
import { IConversation, IMessage } from "../../types";
import { catchAll } from "../../utils/catch";
import { api } from "./api";
import dayjs from "dayjs";

type TCreateConversationArgs = {
    conversationType: "DIRECT" | "GROUP" | "CONTRACT" | "AI_ASSISTANT_CHAT";
    title: string;
    participants: { userId: string }[];
    jobId?: string;
};

type TCreateConversationResponse = {
    data: {
        _id: string;
        title: string;
        connId: string;
        createdBy: string;
        conversationType: string;
        participants: Array<{
            userId: string;
            _id: string;
            createdAt: string;
            updatedAt: string;
        }>;
        createdAt: string;
        updatedAt: string;
        __v: number;
        messages: Array<{
            _id: string;
            conversationId: string;
            senderId: string;
            receipientId: string;
            status: string;
            content: string;
            fileUrl?: Array<any>;
            imageUrl?: Array<any>;
            createdAt: string;
            updatedAt: string;
            __v: number;
            senderInfo: {
                _id: string;
                username: string;
                userType: string;
                firebaseUid: string;
                email: string;
                firstName: string;
                lastName: string;
                phone: string;
                providerId: string;
                status: string;
                createdAt: string;
                updatedAt: string;
                __v: number;
                stripeConnectId: string;
            };
            recipientInfo: {
                _id: string;
                username: string;
                userType: string;
                firebaseUid: string;
                email: string;
                firstName: string;
                lastName: string;
                phone: string;
                providerId: string;
                status: string;
                createdAt: string;
                updatedAt: string;
                __v: number;
                stripeConnectId: string;
            };
        }>;
        participantDetails: Array<{
            _id: string;
            username: string;
            userType: string;
            firebaseUid: string;
            email: string;
            firstName: string;
            lastName: string;
            phone: string;
            providerId: string;
            status: string;
            createdAt: string;
            updatedAt: string;
            __v: number;
            stripeConnectId: string;
        }>;
    };
};

type TCreateAIConversationResponse = {
    messsge: string;
    reply: string;
};

// type TCreateAIConversationArgs = {
//     content: string;
//     receipient_id: string;
//     status: string;
//     user_id: string;
//     conversation_id: string;
// };

const getConversations = api.injectEndpoints({
    endpoints: (builder) => ({
        getUserConversations: builder.query<IConversation[], void>({
            providesTags: ["CONVERSATIONS"],
            query: () => {
                return {
                    url: "comm/conversation",
                    method: "GET",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (
                    response.error &&
                    !(response as any).error?.error?.data?.message?.includes(
                        "Failed to get conversation data:",
                    )
                ) {
                    toast.error("Failed to get conversations");
                    return;
                }
                // api.dispatch(addPostJobImages(response.data.data.url));
            },
            transformResponse: (baseQueryReturnValue: IConversation[]) => {
                return baseQueryReturnValue.sort((a, b) => {
                    return dayjs(b.updatedAt).unix() - dayjs(a.updatedAt).unix();
                });
            },
        }),
        createConversation: builder.mutation<TCreateConversationResponse, TCreateConversationArgs>({
            query: (body) => {
                return {
                    url: "comm/conversation",
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["CONVERSATIONS"],
        }),
    }),
});

const getMessages = api.injectEndpoints({
    endpoints: (builder) => ({
        getMessages: builder.query<IMessage[], string | undefined>({
            query: (isSelected) => {
                if (isSelected) {
                    return {
                        url: `comm/message?conversation_id=${isSelected}`,
                        method: "GET",
                    };
                }

                throw new Error("");
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    // toast.error("Failed to get Messages");
                    return;
                }
                // api.dispatch(addPostJobImages(response.data.data.url));
            },
        }),
    }),
});

const sendMessage = api.injectEndpoints({
    endpoints: (builder) => ({
        sendMessage: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `comm/message`,
                    body: data,
                    method: "POST",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    // toast.error("Failed to get Messages");
                    return;
                }
                // api.dispatch(addPostJobImages(response.data.data.url));
            },
        }),
        sendAIMessage: builder.mutation<TCreateAIConversationResponse, IMessage>({
            query: (data) => {
                return {
                    url: `chat/property-manager`,
                    body: data,
                    method: "POST",
                };
            },
        }),
    }),
});

export const { useGetUserConversationsQuery, useCreateConversationMutation } = getConversations;
export const { useGetMessagesQuery, useLazyGetMessagesQuery } = getMessages;
export const { useSendMessageMutation, useSendAIMessageMutation } = sendMessage;
