import React, { useEffect, useRef, useState } from "react";
import styles from "./map.module.css";
import { Button } from "@/components/ui/button";
import {
    getAddress,
    handleGeolocationError,
    initializeMap,
    placeMarkerAndPanTo,
} from "../../../utils/mapUtils";

interface MapProps {
    isOpen: boolean;
    onClose?: () => void;
    onAddressSelect?: (address: string, place?: google.maps.places.PlaceResult | undefined) => void;
    type?: string;
    address?: string;
    addresses?: string[]; // New prop for multiple addresses
}

export const Map: React.FC<MapProps> = ({
    isOpen,
    onClose,
    onAddressSelect,
    type = "common",
    address,
    addresses,
}) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);
    const markerRefs = useRef<google.maps.Marker[]>([]); // Array to hold markers
    const markerRef = useRef<google.maps.Marker | null>(null);
    const [currentAddress, setCurrentAddress] = useState<string>("");
    const [place, setPlace] = useState<google.maps.places.PlaceResult>();

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose?.();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    useEffect(() => {
        if (!mapRef.current || !isOpen) return;

        const initMap = () => {
            if (type === "propertymanager" && addresses && addresses.length > 0) {
                const map = new window.google.maps.Map(mapRef.current!, {
                    zoom: 3,
                    center: { lat: -34.397, lng: 150.644 },
                    disableDefaultUI: true,
                    fullscreenControl: true,
                });

                markerRefs.current.forEach((marker) => marker.setMap(null));
                markerRefs.current = [];

                addresses.forEach((address) => {
                    const geocoder = new window.google.maps.Geocoder();
                    geocoder.geocode({ address }, (results, status) => {
                        if (status === "OK" && results?.[0]) {
                            map.setCenter(results[0].geometry.location);
                            const marker = new google.maps.Marker({
                                map,
                                position: results[0].geometry.location,
                                draggable: false,
                            });
                            markerRefs.current.push(marker);
                        } else {
                            console.error(
                                "Geocode was not successful for the following reason: " + status,
                            );
                        }
                    });
                });
            } else if (type === "homeowner" && address) {
                const map = new window.google.maps.Map(mapRef.current!, {
                    zoom: 15,
                    center: { lat: -34.397, lng: 150.644 },
                    disableDefaultUI: true,
                });

                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({ address }, (results, status) => {
                    if (status === "OK" && results?.[0]) {
                        map.setCenter(results[0].geometry.location);
                        const marker = new google.maps.Marker({
                            map,
                            position: results[0].geometry.location,
                            draggable: false,
                        });
                        markerRef.current = marker;
                    } else {
                        console.error(
                            "Geocode was not successful for the following reason: " + status,
                        );
                    }
                });
            } else {
                const placeMarkerAndPanToWrapper = placeMarkerAndPanTo(markerRef);
                const getAddressWrapper = getAddress(setCurrentAddress, setPlace);

                const initializeMapWrapper = initializeMap(
                    mapRef,

                    placeMarkerAndPanToWrapper,
                    getAddressWrapper,
                );
                const handleGeolocationErrorWrapper = handleGeolocationError(
                    mapRef,
                    placeMarkerAndPanToWrapper,
                    getAddressWrapper,
                );

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        initializeMapWrapper,
                        handleGeolocationErrorWrapper,
                    );
                } else {
                    handleGeolocationErrorWrapper({
                        code: 1,
                        message: "Geolocation is not supported by this browser.",
                        PERMISSION_DENIED: 1,
                        POSITION_UNAVAILABLE: 2,
                        TIMEOUT: 3,
                    });
                }
            }
        };

        if (window.google && window.google.maps) {
            initMap();
        } else {
            const script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_PLACES_AUTO_COMPLETE}&libraries=places`;
            script.async = true;
            script.defer = true;
            script.onload = () => initMap();
            document.body.appendChild(script);
        }

        return () => {
            markerRefs.current.forEach((marker) => marker.setMap(null));
            if (markerRef.current) {
                markerRef.current.setMap(null);
            }
        };
    }, [isOpen, type, address, addresses]);

    const handleClose = () => {
        onAddressSelect?.(currentAddress, place);
        onClose?.();
    };

    if (!isOpen) return null;

    return (
        <div className={styles[type]}>
            <main
                className={styles.modalContent}
                ref={modalRef}
                onClick={(e) => e.stopPropagation()}
                style={type !== "common" ? { width: "100%" } : undefined}
            >
                <div className={styles.mapContainer}>
                    {type === "common" ? (
                        <div className={styles.addressContainer}>
                            <h6>
                                <i className="ri-map-pin-line"></i> Current Location
                            </h6>
                            <p>{currentAddress}</p>

                            <Button onClick={handleClose}>Okay</Button>
                        </div>
                    ) : null}
                    <div
                        ref={mapRef}
                        className={`${type === "propertymanager" ? styles.propertymap : styles.map}`}
                    />
                </div>
            </main>
        </div>
    );
};
