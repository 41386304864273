import { Link } from "react-router-dom";
import styles from "../Common.module.css";
import { INavList } from "../../../utils/interface/random.interface";

interface NavListProps {
    list: INavList[];
}

function NavList({ list }: NavListProps) {
    return (
        <div className={styles.subnavList}>
            {list.map((item, index) => (
                <Link to={item.link}>
                    <li key={index}>
                        <span>{item.label}</span>
                    </li>
                </Link>
            ))}
        </div>
    );
}

export default NavList;
