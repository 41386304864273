import { Logo } from "@/assets/icons";
import { Dialog } from "@/components/common/dialog";
import TextArea from "@/components/common/input/textArea";
import { Heading } from "@/components/common/pageHeading";
import { Button } from "@/components/ui/button";
import {
    useGetNonUserJobQuery,
    useLazyGetPaymentLinkQuery,
    useUpdateJobBidMutation,
} from "@/redux/rtk-query/jobsApis";
import isEmptyObject from "@/utils/isEmptyObject";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import { OptionsForm } from "./OptionsForm";
import { toast } from "react-toastify";

export const PaymentForm = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get("jobId");
    const token = searchParams.get("token");
    const [customerMessage, setCustomerMessage] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [modalType, setModalType] = useState("pay");
    const { data: jobData, isLoading } = useGetNonUserJobQuery(
        { jobId: jobId ?? undefined, token: token ?? undefined },
        {
            skip: !Boolean(jobId),
        },
    );
    const filteredQuotes = jobData?.jobBid?.filter((quote: any) => !isEmptyObject(quote)) ?? [];
    const handleCustomerMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCustomerMessage(event.target.value);
    };
    const [updateJobBid] = useUpdateJobBidMutation();
    const [getPaymentLink] = useLazyGetPaymentLinkQuery();
    const handleSubmit = async () => {
        try {
            if (jobId && token) {
                if (modalType === "options" || customerMessage) {
                    const data = {
                        customerMessage,
                    };
                    await updateJobBid({
                        data,
                        token,
                        jobId,
                        contractorId: jobData?.assignedToInfo?._id,
                    });
                    setCustomerMessage("");
                    toast.success("Your message has been sent successfully to the contractor.");
                    setIsDialogOpen(false);
                    setModalType("pay");
                }
                if (modalType === "pay") {
                    const response = await getPaymentLink({ jobId });
                    if (response.error) {
                        toast.error("Failed to get payment link");
                        return;
                    } else {
                        window.location.href = response.data.paymentLink;
                    }
                    setIsDialogOpen(false);
                }
            }
        } catch (err) {
            toast.error("Something went wrong!");
        }
    };
    return (
        <>
            <div className="absolute w-full h-[72px] top-[1px] bg-white border-b border-[#dfe1e7] p-[20px_24px] flex items-center justify-between">
                <Logo height="100%" fill="#22BCFF" />
            </div>
            <div className="py-3 md:w-1/2 mx-auto mt-[120px]">
                <div className="flex justify-center items-center mb-8">
                    <Heading as="h1">Payments</Heading>
                </div>
                {isLoading ? (
                    <Skeleton width="100%" height="50" />
                ) : (
                    <>
                        <p className="mb-2 text-black font-medium text-lg">Contractor Details</p>
                        <div className="mb-3 mt-2 rounded-md bg-gray-100 p-3">
                            <div className="flex gap-4">
                                <img
                                    className="w-16 h-16 rounded-full"
                                    src={filteredQuotes[0]?.userInfo?.imageUrl}
                                    alt={`${filteredQuotes[0]?.userInfo?.firstName} ${filteredQuotes[0]?.userInfo?.lastName}`}
                                />
                                <div>
                                    <p className="mb-2 text-black font-medium text-lg">
                                        {filteredQuotes[0]?.userInfo?.firstName}{" "}
                                        {filteredQuotes[0]?.userInfo?.lastName}
                                    </p>
                                    <p className="mb-0 text-[#4b5563] text-md">
                                        {filteredQuotes[0]?.userInfo?.email}
                                    </p>
                                    <p className="mb-1 text-[#4b5563] text-md">
                                        {filteredQuotes[0]?.userInfo?.phone}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <h3 className="mb-2 text-black font-medium text-lg">
                            Message from Contractor
                        </h3>
                        <p className="mb-3 text-black text-md">{filteredQuotes[0]?.message}</p>
                        <div className="mb-3">
                            <h3 className="mb-2 text-black font-medium text-lg">Invoice</h3>
                            <div className="container mx-auto py-3 px-0">
                                <div className="overflow-x-auto">
                                    <table className="min-w-full bg-white">
                                        <thead>
                                            <tr>
                                                <th className="px-6 py-3 border-b border-gray-800 text-left text-md leading-4 font-medium text-black uppercase tracking-wider">
                                                    Description
                                                </th>
                                                <th className="px-6 py-3 border-b border-gray-800 text-right text-md leading-4 font-medium text-black uppercase tracking-wider">
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-800 text-sm text-gray-600">
                                                    Service Fee
                                                </td>
                                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-800 text-sm text-gray-600 text-right">
                                                    ${filteredQuotes[0]?.price}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-800 text-sm text-gray-600">
                                                    Callout Fee
                                                </td>
                                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-800 text-sm text-gray-600 text-right">
                                                    $0
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td className="px-6 py-4 whitespace-no-wrap border-t border-gray-800 font-bold">
                                                    Total
                                                </td>
                                                <td className="px-6 py-4 whitespace-no-wrap border-t border-gray-800 font-bold text-right">
                                                    ${filteredQuotes[0]?.price}
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <label className="mb-2 text-black font-medium text-lg">Message</label>
                        <TextArea
                            name="message"
                            placeholder="Enter message"
                            required={true}
                            value={modalType === "pay" ? customerMessage : ""}
                            height={130}
                            handleChange={handleCustomerMessage}
                        />
                        <div className="flex justify-center items-center gap-5 mt-5">
                            <Button
                                onClick={() => {
                                    setIsDialogOpen(true);
                                    setModalType("options");
                                }}
                                variant="grey"
                                className="h-9 rounded-xl"
                            >
                                Talk to Contractor
                            </Button>
                            <Button
                                onClick={() => {
                                    setIsDialogOpen(true);
                                    setModalType("pay");
                                }}
                                variant="default"
                                className="bg-[#22BCFF] h-9 rounded-xl hover:bg-[#22BCFF]"
                            >
                                Confirm & Pay
                            </Button>
                        </div>
                        <Dialog
                            heading={modalType === "pay" ? "Confirmation" : "Talk to Contractor"}
                            primaryButtonLabel={
                                modalType === "pay" ? "Confirm" : "Notify Contractor"
                            }
                            isOpen={isDialogOpen}
                            setIsOpen={setIsDialogOpen}
                            onClickPrimary={() => handleSubmit()}
                            content={
                                modalType === "pay" ? (
                                    <p className="text-base leading-6 text-gray-800 mb-12">
                                        Are you sure you want to proceed with the payment? You will
                                        pay <strong>${filteredQuotes[0]?.price}</strong>.
                                    </p>
                                ) : (
                                    <OptionsForm
                                        input={customerMessage}
                                        setInput={handleCustomerMessage}
                                    />
                                )
                            }
                        />
                    </>
                )}
            </div>
        </>
    );
};
