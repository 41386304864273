import React, { useRef } from "react";
import ChatBottombar from "./chat-bottombar";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "@/lib/utils";
import { format } from "date-fns"; // You can use any date formatting library
import { Skeleton } from "@/components/ui/skeleton";

interface ChatListProps {
    messages?: any;
    selectedUser: any;
    sendMessage: (isEmojiClicked: boolean) => void;
    isMobile: boolean;
    setInputValue: (text: string) => void;
    inputValue: string;
    isConversationLoading?: boolean;
}

export function ChatList({
    messages,
    selectedUser,
    sendMessage,
    isMobile,
    setInputValue,
    inputValue,
    isConversationLoading,
}: ChatListProps) {
    const messagesContainerRef = useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <div className="w-full overflow-y-auto overflow-x-hidden h-full flex flex-col">
            <div
                ref={messagesContainerRef}
                className="w-full overflow-y-auto overflow-x-hidden h-full flex flex-col"
                style={{ maxHeight: "calc(100vh - 150px)" }}
            >
                <AnimatePresence>
                    {isConversationLoading
                        ? [...Array(10)].map((_, index) => (
                              <motion.div
                                  key={index}
                                  layout
                                  initial={{ opacity: 0, scale: 1, y: 90, x: 0 }}
                                  animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
                                  exit={{ opacity: 0, scale: 1, y: 90, x: 0 }}
                                  transition={{
                                      opacity: { duration: 0.1 },
                                  }}
                                  className="flex flex-col gap-2 p-4"
                              >
                                  <Skeleton
                                      className={`h-10 w-[40%] bg-gray-200 ${
                                          index % 2 === 0
                                              ? "ml-auto rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl rounded-br-none"
                                              : "rounded-tl-2xl rounded-tr-2xl rounded-br-2xl rounded-bl-none"
                                      }`}
                                  />
                                  {/* <Skeleton className={`h-4 w-20 mt-1 bg-gray-200`} /> */}
                                  <Skeleton
                                      className={`h-4 w-20 bg-gray-200 ${
                                          index % 2 === 0 ? "ml-auto" : ""
                                      }`}
                                  />
                              </motion.div>
                          ))
                        : messages?.map((message: any, index: number) => {
                              return (
                                  <motion.div
                                      key={index}
                                      layout
                                      initial={{ opacity: 0, scale: 1, y: 90, x: 0 }}
                                      animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
                                      exit={{ opacity: 0, scale: 1, y: 90, x: 0 }}
                                      transition={{
                                          opacity: { duration: 0.1 },
                                      }}
                                      style={{
                                          originX: 0.5,
                                          originY: 0.5,
                                      }}
                                      className={cn(
                                          "flex flex-col gap-2 p-4 whitespace-pre-wrap",
                                          message.senderId !== selectedUser._id
                                              ? "items-end"
                                              : "items-start",
                                      )}
                                  >
                                      <div
                                          className={`flex flex-col gap-1 ${
                                              message.senderId !== selectedUser._id
                                                  ? "items-end"
                                                  : "items-start"
                                          }`}
                                      >
                                          <span
                                              className={`p-3 max-w-xs ${
                                                  message.senderId !== selectedUser._id
                                                      ? "bg-white text-[#000] border border-gray-200 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl rounded-br-none"
                                                      : "bg-gray-200 text-black rounded-tl-2xl rounded-tr-2xl rounded-br-2xl rounded-bl-none"
                                              }`}
                                          >
                                              {message.content}
                                          </span>
                                          {message.createdAt && (
                                              <span className="text-xs text-gray-500">
                                                  {format(
                                                      new Date(
                                                          message.timestamp || message.createdAt,
                                                      ),
                                                      "p",
                                                  )}
                                              </span>
                                          )}
                                      </div>
                                  </motion.div>
                              );
                          })}
                </AnimatePresence>
            </div>
            <ChatBottombar
                setInputValue={setInputValue}
                inputValue={inputValue}
                sendMessage={sendMessage}
                isMobile={isMobile}
                totalMessages={messages}
                isConversationLoading={isConversationLoading}
            />
        </div>
    );
}
