import { TTableRows } from "@/components/common/table/table";

// export const TableHeadItems = [
//     {
//         key: 1,
//         value: "ID",
//     },
//     {
//         key: 1,
//         value: "Customer",
//     },
//     {
//         key: 1,
//         value: "Date",
//     },
//     {
//         key: 2,
//         value: "Duration",
//     },
// ];

// const TableHeadItems = [
//     { key: "id", value: "Id" },
//     { key: "customer", value: "Customer" },
//     { key: "phoneNumber", value: "Phone Number" },
//     { key: "date", value: "Date" },
//     { key: "category", value: "Category" },
//     { key: "messageType", value: "Call/Message Type" },
//     { key: "summary", value: "Summary" },
//     { key: "startDate", value: "Start Date" },
//     { key: "endDate", value: "End Date" },
// ];

export const sortItems = [
    { value: "id", key: "Id" },
    { value: "customer", key: "Customer" },
    { value: "phoneNumber", key: "Phone Number" },
    { value: "date", key: "Date" },
    { value: "category", key: "Category" },
    { value: "messageType", key: "Message Type" },
    { value: "summary", key: "Summary" },
    { value: "duration", key: "Duration" },
];

export const columnItems = [
    { value: "id", key: "Id" },
    { value: "customer", key: "Customer" },
    { value: "phoneNumber", key: "Phone Number" },
    { value: "date", key: "Date" },
    { value: "category", key: "Category" },
    { value: "messageType", key: "Message Type" },
    { value: "summary", key: "Summary" },
    { value: "duration", key: "Duration" },
];

export const TableHeadItems = [
    {
        accessorKey: "id",
        header: "Id",
        cell: ({ row }: any) => {
            const sequenceNumber = row.index + 1;
            return <div className="">{`#${sequenceNumber}`}</div>;
        },
    },
    {
        accessorKey: "customer",
        header: "Customer",
        cell: ({ row }: any) => {
            return <div className="capitalize">{row.getValue("customer")}</div>;
        },
    },
    {
        accessorKey: "phoneNumber",
        header: "Phone Number",
        cell: ({ row }: any) => {
            return <div className="lowercase">{row.getValue("phoneNumber")}</div>;
        },
    },
    {
        accessorKey: "date",
        header: "Date",
        cell: ({ row }: any) => {
            return <div className="">{row.getValue("date")}</div>;
        },
    },
    {
        accessorKey: "category",
        header: "Category",
        cell: ({ row }: any) => {
            return <div className="capitalize">{row.getValue("category")}</div>;
        },
    },
    {
        accessorKey: "messageType",
        header: "Message Type",
        cell: ({ row }: any) => {
            return <div className="capitalize">{row.getValue("messageType")}</div>;
        },
    },
    {
        accessorKey: "summary",
        header: "Summary",
        cell: ({ row }: any) => {
            return <div className="">{row.getValue("summary")}</div>;
        },
    },
    {
        accessorKey: "duration",
        header: "Duration",
        cell: ({ row }: any) => {
            return <div className="">{row.getValue("duration")}</div>;
        },
    },
];

export const TableRowItems: TTableRows[] = [
    ...new Array(10).fill([
        {
            key: "ID",
            value: "07700",
        },
        {
            key: "John Doe",
            value: (
                <div>
                    <p>John Doe</p>
                    <p>john.doe@example.com</p>
                </div>
            ),
        },
        {
            key: "Date",
            value: "Jul 25, 2024 12:50 AM",
        },
        {
            key: "Duration",
            value: "1 mins 27 secs",
        },
    ]),
];
