import React, { ReactNode, useCallback, useState } from "react";
import { AuthDetails, AuthInitialValues } from "../../../utils/interface/auth.interface";
import { IJob, JobDetailsValues } from "../../../utils/interface/dashboard.interface";

interface IInitialState {
    currentUser: AuthDetails;
    jobListings: IJob[];
    jobDetails: IJob;
}

type IContextValue = {
    currentState: IInitialState;
    setCurrentState: React.Dispatch<React.SetStateAction<IInitialState>>;
    handleSetState: (newState: Partial<IInitialState>) => void;
};

export const initialState: IInitialState = {
    currentUser: AuthInitialValues,
    jobListings: [],
    jobDetails: JobDetailsValues,
};

type StateProviderProps = {
    children: ReactNode;
};

export const StateContext = React.createContext<IContextValue>({
    currentState: initialState,
    setCurrentState: () => null,
    handleSetState: () => null,
});

const StateProvider = ({ children }: StateProviderProps) => {
    const [currentState, setCurrentState] = useState<IInitialState>(initialState);

    const handleSetState = useCallback((newState: Partial<IInitialState>) => {
        setCurrentState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    }, []);

    return (
        <StateContext.Provider value={{ currentState, setCurrentState, handleSetState }}>
            {children}
        </StateContext.Provider>
    );
};

export default StateProvider;
