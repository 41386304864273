import { TUser } from "@/types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useGetAddressQuery, useUpdateAddressMutation } from "@/redux/rtk-query/authApis";
import { toast } from "react-toastify";

const formSchema = z.object({
    country: z.string().min(1, "Country is required"),
    postalCode: z.string().min(1, "Postal code is required"),
    city: z.string().min(1, "City is required"),
    address1: z.string().min(1, "Address 1 is required"),
    address2: z.string(),
});

type TProp = {
    user: TUser | undefined;
};

type Country = {
    name: string;
    code: string;
};

const AddressForm = (props: TProp) => {
    const [countries, setCountries] = useState<Country[]>([]);
    const { data: addressData, refetch } = useGetAddressQuery(props.user?.companyInfo?.addressId, {
        skip: !props.user?.companyInfo?.addressId,
    });
    const [updateAddress, { isLoading }] = useUpdateAddressMutation();

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetch("https://restcountries.com/v3.1/all?fields=name,cca2");
                const data = await response.json();
                const formattedCountries = data.map((country: any) => ({
                    name: country.name.common,
                    code: country.cca2,
                }));
                setCountries(
                    formattedCountries.sort((a: Country, b: Country) =>
                        a.name.localeCompare(b.name),
                    ),
                );
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };

        fetchCountries();
    }, []);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            country: "",
            postalCode: "",
            city: "",
            address1: "",
            address2: "",
        },
        values: {
            country: addressData?.country || "",
            postalCode: addressData?.postalCode || "",
            city: addressData?.city || "",
            address1: addressData?.mainStreet || "",
            address2: addressData?.building || "",
        },
    });
    console.log(addressData);

    async function onSubmit(values: z.infer<typeof formSchema>) {
        try {
            const response = await updateAddress({
                _id: props?.user?.companyInfo?.addressId,
                addressType: "WORK",
                mainStreet: values.address1,
                building: values.address2,
                country: values?.country,
                city: values?.city,
                postalCode: values?.postalCode,
                province: "",
                timezone: "",
                latitude: 0,
                longitude: 0,
            });
            console.log(response);
            refetch();
            toast.success("Address updated successfully");
        } catch (error) {
            console.error("Error updating address:", error);
        }
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="max-w-2xl space-y-6">
                <div className="space-y-4">
                    <FormLabel>Street Address</FormLabel>
                    <FormField
                        control={form.control}
                        name="address1"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className="focus-visible:ring-1"
                                        placeholder="Address 1"
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="address2"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className="focus-visible:ring-1"
                                        placeholder="Address 2"
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <div className="flex gap-4">
                    <FormField
                        control={form.control}
                        name="postalCode"
                        render={({ field }) => (
                            <FormItem className="flex-1">
                                <FormLabel>Postal Code</FormLabel>
                                <FormControl>
                                    <Input
                                        className="focus-visible:ring-1"
                                        placeholder="Enter postal code"
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="city"
                        render={({ field }) => (
                            <FormItem className="flex-1">
                                <FormLabel>City</FormLabel>
                                <FormControl>
                                    <Input
                                        className="focus-visible:ring-1"
                                        placeholder="Enter city"
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <FormField
                    control={form.control}
                    name="country"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Country</FormLabel>
                            <FormControl>
                                <select
                                    onChange={field.onChange}
                                    defaultValue={field.value}
                                    className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>option]:py-3 [&>option]:px-3 [&>option]:bg-white [&>option:hover]:bg-gray-100 [&>option:checked]:bg-primary [&>option:checked]:text-primary-foreground"
                                >
                                    <option value="">Select a country</option>
                                    {countries.map((country) => (
                                        <option
                                            selected={country.code === addressData?.country}
                                            key={country.code}
                                            value={country.code}
                                        >
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <div className="flex gap-3">
                    <Button type="submit" disabled={!form.formState.isDirty || isLoading}>
                        {isLoading ? "Saving..." : "Save"}
                    </Button>
                    <Button type="button" variant="outline" onClick={() => form.reset()}>
                        Cancel
                    </Button>
                </div>
            </form>
        </Form>
    );
};

export default AddressForm;
