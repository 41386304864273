import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TPostJobSliceInitialState } from "../../types";
import { TDropdownItem } from "../../components/common/dropdown/Dropdown.types";

const InitialState: TPostJobSliceInitialState = {
    title: "",
    description: "",
    categories: [],
    address: "",
    addressMapsObject: undefined,
    budget: "",
    userPrompt: "",
    date: new Date().toString(),
    dueDate: new Date().toString(),
    images: [],
};

export type TPostJobStateKeys = Exclude<
    keyof typeof InitialState,
    "categories" | "userPrompt" | "images"
>;

type TAIRecommendationPayloadArg = {
    title: string;
    description: string;
    categories: string[];
};

type TSetPostJobFormStateArg = {
    name: TPostJobStateKeys;
    value: string | TDropdownItem;
};

export const languageSlice = createSlice({
    name: "postJob",
    initialState: InitialState,
    reducers: {
        setAIRecommendation: (state, action: PayloadAction<TAIRecommendationPayloadArg>) => {
            state.title = action.payload.title;
            state.description = action.payload.description;
            state.categories = action.payload.categories;
        },
        setPostJobUserPromptToAI: (state, action: PayloadAction<string>) => {
            state.userPrompt = action.payload;
        },
        setPostJobFormItemState: (state, action: PayloadAction<TSetPostJobFormStateArg>) => {
            if (typeof action.payload.value === "string") {
                // @ts-ignore
                state[action.payload.name] = action.payload.value;
            } else {
                state["property"] = action.payload.value;
            }
        },
        addPostJobCategory: (state, action: PayloadAction<string>) => {
            state.categories = [...state.categories, action.payload];
        },
        removePostJobCategory: (state, action: PayloadAction<string>) => {
            state.categories = state.categories.filter((category) => category !== action.payload);
        },
        addPostJobImages: (state, action: PayloadAction<string>) => {
            state.images.push(action.payload);
        },
        setAddressObject: (state, action: PayloadAction<google.maps.places.PlaceResult>) => {
            action.payload.photos?.forEach((p: any) => {
                if (p.getUrl) {
                    delete p.getUrl;
                }
            });
            state.addressMapsObject = action.payload;
        },
        setPostJobToInitialStateExceptPrompt: (state) => {
            state = {
                ...InitialState,
                userPrompt: state.userPrompt,
            };
        },
        setPostJobToInitialState: () => {
            return InitialState;
        },
        removeImageFromPostJob(state, action: PayloadAction<number>) {
            state.images.splice(action.payload, 1);
        },
    },
});

export const {
    setAIRecommendation,
    addPostJobCategory,
    removePostJobCategory,
    setPostJobFormItemState,
    setPostJobToInitialStateExceptPrompt,
    setPostJobUserPromptToAI,
    addPostJobImages,
    setAddressObject,
    setPostJobToInitialState,
    removeImageFromPostJob,
} = languageSlice.actions;

export default languageSlice.reducer;
