import { memo } from "react";
import styles from "./PropertyManagementCreateContract.module.css";

type TProps = {
    image: string;
    email: string;
    name: string;
};

export const TenantInformationCard = memo((props: TProps) => {
    const { image, email, name } = props;

    return (
        <div className={styles.tenantInformationCard}>
            <div className={styles.tenantInformationProfile}>
                {
                    <img
                        src={image || "https://via.placeholder.com/500x500"}
                        alt="profile"
                        className={styles.tenantProfileImage}
                    />
                }
            </div>
            <div className={styles.tenantInformationHeadings}>
                <>
                    <h4 className={styles.tenantInformationName}>{name}</h4>
                    {email && <p className={styles.tenantInformationSubHeading}>{email}</p>}
                </>
            </div>
        </div>
    );
});
