import { cn } from "@/lib/utils";
import * as React from "react";

import { Chip } from "@/components/common/chip";
import { NothingToSeePlaceholder } from "@/components/common/nothingToSeePlaceholder";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Skeleton } from "@/components/ui/skeleton";
import { format } from "date-fns";
import { CalendarIcon, ExternalLink, Pencil } from "lucide-react";
import RentalChart, { TRentalChartProps } from "./RentalChart";

import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Spinner } from "@/components/ui/spinner";
type RentalDetailsProps = {
    remainingDuration: string;
    rentAmount: string;
    rentDueDate: string;
    rentStatus: string;
    rentPeriod: string;
    propertyType: string;
    location: string;
    postalCode: string;
    propertyImages?: string[];
    addressObject?: any;
    tenure?: string;
    Open?: any;
    setOpen?: any;
    // data: string[];
    onClickSubmit: (data: any) => void;
    isLoading: boolean;
    tenantData: any;
} & { chartProps: Omit<TRentalChartProps, "remainingTimeLabel"> | undefined };

function fromKebabTitleCase(str: string) {
    return str
        .toLowerCase()
        .split(/[-\s]/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}
const FormSchema = z.object({
    rentAmount: z.string().min(3, { message: "Name must be at least 3 characters." }),
    rentDueDate: z.string().min(3, { message: "Please choose the correct format of date" }),
    securityDeposit: z.string().min(3, { message: "Name must be at least 3 characters." }),
    startPeriod: z.string().min(3, { message: "Name must be at least 3 characters." }),
    endPeriod: z.string().min(3, { message: "Name must be at least 3 characters." }),
});

const defaultValues: z.infer<typeof FormSchema> = {
    rentAmount: "",
    rentDueDate: "",
    securityDeposit: "",
    startPeriod: "",
    endPeriod: "",
};
const RentalDetails: React.FC<RentalDetailsProps> = ({
    remainingDuration,
    rentAmount,
    rentDueDate,
    rentStatus,
    rentPeriod,
    location,
    chartProps,
    propertyImages,
    addressObject,
    tenure,
    onClickSubmit,
    isLoading,
    tenantData,
    setOpen,
    Open,
}) => {
    function openInGoogleMaps() {
        if (
            typeof addressObject?.latitude === "number" &&
            typeof addressObject?.longitude === "number"
        ) {
            const url = `https://www.google.com/maps/search/?api=1&query=${addressObject?.latitude},${addressObject?.longitude}`;
            window.open(url, "_blank");
        }
    }
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            ...defaultValues,
            rentAmount,
            rentDueDate,
            // startPeriod,
            // endPeriod,
        },
    });
    // const [createBuilding, { isLoading: createBuildingLoading }] = useCreateBuildingEntryMutation();
    // const [updateBuilding, { isLoading: updateLoading }] = useUpdateBuildingEntryMutation();
    // const { reset, watch, formState } = form;
    async function onSubmit(data: z.infer<typeof FormSchema>) {
        onClickSubmit(data);
    }

    return (
        <div className="bg-white drop-shadow-md rounded-[12px] p-4 lg:w-80 border ">
            {isLoading || tenantData ? (
                <React.Fragment>
                    <div>
                        <div className="">
                            <div className="flex gap-10 items-center justify-between">
                                <h1 className="text-lg font-medium">Rental Details</h1>
                                <Button
                                    variant={"outline"}
                                    className=" w-10 min-w-[40px] max-w-[40px] h-10 min-h-[40px] max-h-10 !p-0 flex-col-reverse self-end"
                                    onClick={() => setOpen(true)}
                                >
                                    <Pencil size={16} />
                                </Button>
                                <Dialog open={Open} onOpenChange={setOpen}>
                                    <DialogContent className="max-h-[calc(100vh-50px)] overflow-y-auto">
                                        <DialogHeader>
                                            <DialogTitle className="font-semibold text-lg leading-[22px] text-[#09090B]">
                                                Edit Rental Details
                                            </DialogTitle>
                                        </DialogHeader>
                                        <div>
                                            <Form {...form}>
                                                <form
                                                    onSubmit={form.handleSubmit(onSubmit)}
                                                    className="w-full"
                                                >
                                                    <div className="max-w-[850px] border p-4  rounded-xl border-solid border-[#f2f2f2] ">
                                                        <FormField
                                                            control={form.control}
                                                            name="rentAmount"
                                                            render={({ field }) => (
                                                                <FormItem className="w-[350px] mt-2">
                                                                    <FormLabel className="text-sm text-black">
                                                                        Rent Amount
                                                                    </FormLabel>
                                                                    <FormControl>
                                                                        <Input
                                                                            placeholder="Enter Rent"
                                                                            type="number"
                                                                            style={{
                                                                                appearance:
                                                                                    "textfield",
                                                                            }} // For consistency across all browsers
                                                                            {...field}
                                                                            className="focus:ring-1 focus-visible:ring-gray-200 h-9 w-full"
                                                                        />
                                                                    </FormControl>
                                                                    <FormMessage className="text-xs" />
                                                                </FormItem>
                                                            )}
                                                        />
                                                        <FormField
                                                            control={form.control}
                                                            name="rentDueDate"
                                                            render={({ field }) => (
                                                                <FormItem className="w-[350px] mt-6">
                                                                    <FormLabel className="text-sm text-black">
                                                                        Rent Due Date
                                                                    </FormLabel>
                                                                    <FormControl>
                                                                        <Input
                                                                            placeholder="Pick a date"
                                                                            {...field}
                                                                            className="focus:ring-1 focus-visible:ring-gray-200 h-9 w-full"
                                                                        />
                                                                    </FormControl>
                                                                    <FormMessage className="text-xs" />
                                                                </FormItem>
                                                            )}
                                                        />
                                                        <FormField
                                                            control={form.control}
                                                            name="securityDeposit"
                                                            render={({ field }) => (
                                                                <FormItem className="w-[350px] mt-6">
                                                                    <FormLabel className="text-sm text-black">
                                                                        Security Deposit
                                                                    </FormLabel>
                                                                    <FormControl>
                                                                        <Input
                                                                            type="number"
                                                                            placeholder="Enter Security Deposit"
                                                                            {...field}
                                                                            className="focus:ring-1 focus-visible:ring-gray-200 h-9 w-full"
                                                                        />
                                                                    </FormControl>
                                                                    <FormMessage className="text-xs" />
                                                                </FormItem>
                                                            )}
                                                        />
                                                        <div className="flex gap-1 ">
                                                            <FormField
                                                                control={form.control}
                                                                name="startPeriod"
                                                                render={({ field }) => (
                                                                    <FormItem className="mt-6">
                                                                        <FormLabel className="text-sm text-black">
                                                                            Lease Period
                                                                        </FormLabel>
                                                                        <Popover>
                                                                            <PopoverTrigger asChild>
                                                                                <FormControl>
                                                                                    <Button
                                                                                        variant={
                                                                                            "outline"
                                                                                        }
                                                                                        className={cn(
                                                                                            "w-[175px] pl-3 text-left font-normal",
                                                                                            !field.value &&
                                                                                                "text-muted-foreground",
                                                                                        )}
                                                                                    >
                                                                                        {field.value ? (
                                                                                            format(
                                                                                                field.value,
                                                                                                "PPP",
                                                                                            )
                                                                                        ) : (
                                                                                            <span>
                                                                                                Start
                                                                                                Date
                                                                                            </span>
                                                                                        )}
                                                                                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                                                                    </Button>
                                                                                </FormControl>
                                                                            </PopoverTrigger>
                                                                            <PopoverContent
                                                                                className="w-auto p-0"
                                                                                align="start"
                                                                            >
                                                                                <Calendar
                                                                                    mode="single"
                                                                                    className={
                                                                                        "[&_td]:h-10 [&_tr]:h-8 [&_tr]:w-56 [&_tr]:pt-0"
                                                                                    }
                                                                                    selected={
                                                                                        new Date(
                                                                                            field.value,
                                                                                        )
                                                                                    }
                                                                                    onSelect={(
                                                                                        date,
                                                                                    ) => {
                                                                                        if (!date)
                                                                                            return;
                                                                                        form.setValue(
                                                                                            "startPeriod",
                                                                                            date?.toString(),
                                                                                        );
                                                                                    }}
                                                                                    disabled={() =>
                                                                                        new Date(
                                                                                            field.value,
                                                                                        ) >
                                                                                            new Date() ||
                                                                                        new Date(
                                                                                            field.value,
                                                                                        ) <
                                                                                            new Date(
                                                                                                "1900-01-01",
                                                                                            )
                                                                                    }
                                                                                    initialFocus
                                                                                />
                                                                            </PopoverContent>
                                                                        </Popover>
                                                                    </FormItem>
                                                                )}
                                                            />
                                                            <FormField
                                                                control={form.control}
                                                                name="endPeriod"
                                                                render={({ field }) => (
                                                                    <FormItem className="mt-6 self-end">
                                                                        <Popover>
                                                                            <PopoverTrigger asChild>
                                                                                <FormControl>
                                                                                    <Button
                                                                                        variant={
                                                                                            "outline"
                                                                                        }
                                                                                        className={cn(
                                                                                            "w-[175px] pl-3 text-left font-normal",
                                                                                            !field.value &&
                                                                                                "text-muted-foreground",
                                                                                        )}
                                                                                    >
                                                                                        {field.value ? (
                                                                                            format(
                                                                                                field.value,
                                                                                                "PPP",
                                                                                            )
                                                                                        ) : (
                                                                                            <span>
                                                                                                End
                                                                                                Date
                                                                                            </span>
                                                                                        )}
                                                                                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                                                                    </Button>
                                                                                </FormControl>
                                                                            </PopoverTrigger>
                                                                            <PopoverContent
                                                                                className="w-auto p-0"
                                                                                align="start"
                                                                            >
                                                                                <Calendar
                                                                                    mode="single"
                                                                                    className={
                                                                                        "[&_td]:h-10 [&_tr]:h-8 [&_tr]:w-56 [&_tr]:pt-0"
                                                                                    }
                                                                                    selected={
                                                                                        new Date(
                                                                                            field.value,
                                                                                        )
                                                                                    }
                                                                                    onSelect={(
                                                                                        date,
                                                                                    ) => {
                                                                                        if (!date)
                                                                                            return;
                                                                                        form.setValue(
                                                                                            "endPeriod",
                                                                                            date?.toString(),
                                                                                        );
                                                                                    }}
                                                                                    disabled={() =>
                                                                                        new Date(
                                                                                            field.value,
                                                                                        ) >
                                                                                            new Date() ||
                                                                                        new Date(
                                                                                            field.value,
                                                                                        ) <
                                                                                            new Date(
                                                                                                "1900-01-01",
                                                                                            )
                                                                                    }
                                                                                    initialFocus
                                                                                />
                                                                            </PopoverContent>
                                                                        </Popover>
                                                                    </FormItem>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex gap-3 justify-end items-end ">
                                                        <Button
                                                            type="submit"
                                                            className="mt-4 bg-[#000] w-[150px]"
                                                            disabled={isLoading}
                                                        >
                                                            {isLoading && (
                                                                <Spinner className="text-white size-5 mr-1" />
                                                            )}
                                                            Confirm Changes
                                                        </Button>
                                                    </div>
                                                </form>
                                            </Form>
                                        </div>
                                    </DialogContent>
                                </Dialog>
                            </div>
                            <RentalChart
                                remainingMonths={chartProps?.remainingMonths || 0}
                                totalMonths={chartProps?.totalMonths || 0}
                                remainingTimeLabel={remainingDuration}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <h1 className="text-gray-700 text-xs mb-[4px]">Rent amount</h1>
                            <h1 className="font-normal text-[15px]">{rentAmount}/month</h1>
                        </div>
                        <div>
                            <h1 className="text-gray-700 text-xs mb-[4px]">Rent Due Date</h1>
                            <h1 className="font-normal text-[15px]">{rentDueDate}</h1>
                        </div>
                        <div>
                            <h1 className="text-gray-700 text-xs mb-[4px]">Rent status</h1>
                            <div className="py-1 w-20 rounded-2xl text-center">
                                <Chip text={rentStatus} color="green" />
                                {/* <span className="text-green-500">{rentStatus}</span> */}
                            </div>
                            {/* <RentalChart
                                remainingMonths={chartProps?.remainingMonths || 0}
                                totalMonths={chartProps?.totalMonths || 0}
                                remainingTimeLabel={remainingDuration}
                                isLoading={isLoading}
                            /> */}
                        </div>
                        <div>
                            <h1 className="text-gray-700 text-xs mb-[4px]">Rent amount</h1>
                            {isLoading ? (
                                <Skeleton className="h-6 w-24 bg-gray-300" />
                            ) : (
                                <h1 className="text-[13.6px] font-bold">{rentAmount}/month</h1>
                            )}
                        </div>

                        <div>
                            <h1 className="text-gray-700 text-xs mb-[4px]">Tenure</h1>
                            {isLoading ? (
                                <Skeleton className="h-6 w-24 bg-gray-300" />
                            ) : (
                                <Chip text={fromKebabTitleCase(tenure || "")} color="gray" />
                            )}
                        </div>

                        <div className="mt-4">
                            <h1 className="text-gray-700 text-xs mb-[4px]">Lease Period</h1>
                            {isLoading ? (
                                <Skeleton className="h-6 w-full bg-gray-300" />
                            ) : (
                                <h4 className="font-normal text-[15px]">{rentPeriod || ""}</h4>
                            )}
                        </div>
                    </div>
                    <div className="h-[2px] my-3 bg-gray-300"></div>
                    <div>
                        <h1 className="font-semibold mb-3">Property</h1>
                        {isLoading ? (
                            <Skeleton className="h-48 w-full rounded-md bg-gray-300" />
                        ) : propertyImages && propertyImages.length > 0 ? (
                            <img
                                src={propertyImages[0]}
                                alt="houses"
                                className="rounded-md"
                                width={600}
                                height={400}
                            />
                        ) : (
                            <NothingToSeePlaceholder label="No Image Found!" height={170} />
                        )}
                        <div className="flex justify-between items-center mt-2">
                            <div className="w-full">
                                <div className="flex justify-between">
                                    {isLoading ? (
                                        <Skeleton className="h-6 w-32 bg-gray-300" />
                                    ) : (
                                        addressObject?.city && (
                                            <h1 className="font-semibold">
                                                Property in {addressObject?.city}
                                            </h1>
                                        )
                                    )}
                                </div>
                                {isLoading ? (
                                    <Skeleton className="h-6 w-48 mt-2 bg-gray-300" />
                                ) : (
                                    location && (
                                        <div className="flex gap-3 my-2 text-xs items-center text-gray-500">
                                            <p>{location}</p>
                                            <ExternalLink
                                                width={20}
                                                height={20}
                                                onClick={() => openInGoogleMaps()}
                                                className="cursor-pointer"
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                        {isLoading ? (
                            <Skeleton className="h-6 w-32 mt-2 bg-gray-300" />
                        ) : (
                            addressObject?.postalCode && (
                                <div className="flex gap-3 items-center">
                                    <span className="text-sm text-gray-900">Postal Code</span>
                                    <span className="text-sm">{addressObject?.postalCode}</span>
                                </div>
                            )
                        )}
                    </div>
                </React.Fragment>
            ) : (
                <div>
                    <h1 className="font-semibold mb-3">Property</h1>

                    <NothingToSeePlaceholder
                        label="No property Found!"
                        height={100}
                        border="none"
                        className="flex justify-center items-center"
                    />
                </div>
            )}
        </div>
    );
};

export default RentalDetails;
