import EventCalendar from "@/components/common/eventCalendar";
import { Header } from "@/components/common/headers/Header";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import style from "./dashboard.module.css";
import { cn } from "@/lib/utils";

export default function Calendar() {
    const { data: userDetails } = useGetMyDetailsQuery();

    return (
        <section className="h-screen flex flex-col">
            <Header
                image={userDetails?.imageUrl}
                heading={undefined}
                firstName={userDetails?.firstName}
                lastName={userDetails?.lastName}
                hasSearchBar={true}
            />
            <div className="flex-grow overflow-hidden p-5">
                <header className="flex justify-between items-center mb-6">
                    <h1 className="font-bold text-3xl leading-9 text-[#09090B]">Calender</h1>
                </header>
                <div
                    className={cn(
                        style.cardContainer,
                        "bg-white rounded-2xl pt-4 pb-12 px-5 h-full overflow-y-auto",
                    )}
                >
                    <EventCalendar />
                </div>
            </div>
        </section>
    );
}
