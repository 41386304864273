export const BankIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.24634 1.43258L14.2463 3.83256C14.4797 3.9259 14.6663 4.20589 14.6663 4.45256V6.6659C14.6663 7.03256 14.3663 7.33256 13.9997 7.33256H1.99967C1.63301 7.33256 1.33301 7.03256 1.33301 6.6659V4.45256C1.33301 4.20589 1.51968 3.9259 1.75301 3.83256L7.75301 1.43258C7.88634 1.37924 8.113 1.37924 8.24634 1.43258Z"
                stroke="#4D4D4D"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M14.6663 14.6667H1.33301V12.6667C1.33301 12.3 1.63301 12 1.99967 12H13.9997C14.3663 12 14.6663 12.3 14.6663 12.6667V14.6667Z"
                stroke="#4D4D4D"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M2.66699 12.0007V7.33398"
                stroke="#4D4D4D"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5.33301 12.0007V7.33398"
                stroke="#4D4D4D"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8 12.0007V7.33398"
                stroke="#4D4D4D"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10.667 12.0007V7.33398"
                stroke="#4D4D4D"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.333 12.0007V7.33398"
                stroke="#4D4D4D"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M0.666992 14.666H15.3337"
                stroke="#4D4D4D"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8 5.66602C8.55228 5.66602 9 5.2183 9 4.66602C9 4.11373 8.55228 3.66602 8 3.66602C7.44772 3.66602 7 4.11373 7 4.66602C7 5.2183 7.44772 5.66602 8 5.66602Z"
                stroke="#4D4D4D"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
