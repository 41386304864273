import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "remixicon/fonts/remixicon.css";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import posthog from "posthog-js";
// Initialize PostHog
posthog.init("phc_Psj1ehfXdtb35Ztz3MnoDBRbmwBlXLCwzndKF5tJwMU", {
    //Put into env later
    api_host: "https://posthog.lightwork.blue",
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
);

reportWebVitals();
