import Logo from "@/components/sideBar/Logo";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "./menu";

export default function Sidebar() {
    // collapsable sidebar is required in future
    const [sidebar] = useState<boolean>(true);

    return (
        <aside
            className={cn(
                "z-20 h-screen -translate-x-full translate-x-0 transition-[width] ease-in-out duration-300 bg-white",
                sidebar === false ? "w-[90px]" : "w-[240px]",
            )}
        >
            {/* <SidebarToggle isOpen={sidebar} setIsOpen={setsidebar} /> */}
            <div className="relative h-full flex flex-col px-3 py-4 overflow-y-auto shadow-md dark:shadow-zinc-800">
                <Button
                    className={cn(
                        "transition-transform ease-in-out duration-300 mb-[23px] !justify-start",
                        sidebar === false ? "translate-x-1" : "translate-x-0",
                    )}
                    variant="link"
                    asChild
                >
                    <Link to="/property-management/dashboard" className="flex !justify-start">
                        <Logo />
                    </Link>
                </Button>
                <Menu isOpen={sidebar} />
            </div>
        </aside>
    );
}
