import React, { useState } from "react";
import { PopOver } from "./PopOver";
import { Layers2 } from "lucide-react";
import { useGetBuildingQuery } from "@/redux/rtk-query/properties";

// Define the type for handleComboboxSelect prop
interface UnitPopOverProps {
    handleComboboxUnitSelect: (propertyId: string, value: string) => void;
    propertyId: string;
}

const UnitPopOver: React.FC<UnitPopOverProps> = ({ handleComboboxUnitSelect, propertyId }) => {
    const [popOverOpenUnit, setPopOverOpenUnit] = useState(false);

    const { data: unitsData, isLoading: unitsLoading } = useGetBuildingQuery(
        { id: propertyId ?? "", type: "BUILDING" },
        {
            skip: !propertyId,
        },
    );
    return (
        <PopOver
            open={popOverOpenUnit}
            setOpen={setPopOverOpenUnit}
            DropDownArray={
                unitsData?.flatMap(
                    (unit) =>
                        unit?.properties?.map((property) => {
                            return { value: property._id, label: property.name };
                        }) || [],
                ) || []
            }
            onChange={handleComboboxUnitSelect}
            className="min-w-[100px]"
            placeholder="Select Unit..."
            message="No Unit Found!"
            icon={Layers2}
        />
    );
};

export default UnitPopOver;
