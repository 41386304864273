import { TAddons, TResponse } from "@/types";
import { api } from "./api";

const addonsApis = api.injectEndpoints({
    endpoints: (builder) => ({
        getAddons: builder.query<TResponse<TAddons>[], string | void>({
            providesTags: ["ADDONS"],
            query: (query) =>
                query ? `properties/addOns?propertyId=${query}` : "properties/addOns?default=true",
        }),
        createAddons: builder.mutation<TResponse<TAddons>, TAddons>({
            query: (body) => {
                return {
                    url: `properties/addOns`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["ADDONS"],
        }),
        deleteAddons: builder.mutation<TResponse<TAddons>, string>({
            query: (id: string) => {
                return {
                    url: `properties/addOns/${id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ["ADDONS"],
        }),
    }),
});

export const { useGetAddonsQuery, useCreateAddonsMutation, useDeleteAddonsMutation } = addonsApis;
