import { memo } from "react";
import { HomeOwnerCard } from "./HomeOwnerCard";
import styles from "./homeOwnerDashboard.module.css";

type TQuickActions = {
    heading: string;
    subtext: string;
    buttonLabel: string;
    onClick: () => void;
};

type TProps = {
    quickActions: TQuickActions[] | undefined;
};

export const HomeOwnerCardSection = memo((props: TProps) => {
    const { quickActions } = props;

    return (
        <section className={styles.homeOwnerCardContainer}>
            {quickActions &&
                quickActions.length > 0 &&
                quickActions?.slice(0, 2).map((action) => {
                    return (
                        <HomeOwnerCard
                            onClick={action.onClick}
                            heading={action.heading}
                            subtext={action.subtext}
                            buttonLabel={action.buttonLabel}
                        />
                    );
                })}
        </section>
    );
});
