import styles from "./details.module.css";

type TProps = {
    heading: string;
    value: string;
    time?: string;
};

export const PriceAndDate = (props: TProps) => (
    <div className={styles.priceAndDateItemContainer}>
        <p className={styles.priceAndDateHeading}>{props.heading}</p>
        <p className={styles.priceAndDateValue}>
            {props.value} {props.time}
        </p>
    </div>
);
