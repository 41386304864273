import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TDropdownItem } from "../../../../components/common/dropdown/Dropdown.types";
import { NothingToSeePlaceholder } from "../../../../components/common/nothingToSeePlaceholder";
import "../../../../cssReset.css";
import { useAddContractorMutation, useGetJobQuery } from "../../../../redux/rtk-query/jobsApis";
import { capitalize } from "../../../../utils/capitalize";
import { getFormattedDate } from "../../../../utils/formatDate";
import { Header } from "./Header";
import { QuoteCard, QuoteCardSkeleton } from "./QuoteCard";
import styles from "./quotes.module.css";
import { useGetMyDetailsQuery } from "../../../../redux/rtk-query/authApis";

export const SORT_DROPDOWN_ITEMS: TDropdownItem[] = [
    {
        key: "Sorting options",
        value: "sorting-options",
    },
];

const JobDetailsQuotes = () => {
    const navigate = useNavigate();

    const { jobId } = useParams<{ jobId: string }>();

    const { data: myUserDetails } = useGetMyDetailsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const { data: jobData, isLoading } = useGetJobQuery(
        { jobId, companyId: myUserDetails?.companyId },
        {
            skip: !Boolean(jobId),
        },
    );

    const [addContractor, { isLoading: isAddingContractor }] = useAddContractorMutation();

    const onClickAccept = useCallback(
        (_: string, userId: string) => {
            if (jobId && userId) {
                addContractor({
                    _id: jobId,
                    assignedTo: userId,
                    navigate,
                });
            }
        },
        [jobId],
    );

    const onClickViewDetails = useCallback((quoteId: string) => {
        navigate(quoteId);
    }, []);

    return (
        <div className={styles.page}>
            <Header />
            {jobData && jobData.jobBid?.[0] ? (
                <div className={styles.quoteCardsContainer}>
                    {jobData.jobBid.map((quote, index) => {
                        const createdAt =
                            quote?.createdAt &&
                            getFormattedDate(new Date(quote?.createdAt)).toString();

                        const firstName = capitalize(quote?.userInfo?.firstName);
                        const lastName = capitalize(quote?.userInfo?.lastName);

                        return (
                            <QuoteCard
                                key={quote?._id ?? index}
                                price={quote?.price ?? 0}
                                createdAt={createdAt ?? ""}
                                _id={quote._id ?? ""}
                                userId={quote.userId}
                                onClickAccept={onClickAccept}
                                onClickViewDetails={onClickViewDetails}
                                isAddingContractor={isAddingContractor}
                                name={`${firstName} ${lastName}`}
                            />
                        );
                    })}
                </div>
            ) : isLoading ? (
                <div className={styles.quoteCardsContainer}>
                    {Array(3)
                        .fill(0)
                        .map((_, index) => (
                            <QuoteCardSkeleton key={index} />
                        ))}
                </div>
            ) : (
                <NothingToSeePlaceholder label="No job quotes" />
            )}
        </div>
    );
};

export default JobDetailsQuotes;
