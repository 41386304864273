import { useLocation, useParams } from "react-router-dom";
import { Logo } from "../../../assets/icons";
import { BackButton } from "../../../components/common/backButton";
import { Heading } from "../../../components/common/pageHeading";
import styles from "./QuoteDetail.module.css";
import { useGetNonUserJobQuery } from "../../../redux/rtk-query/jobsApis";
import isEmptyObject from "../../../utils/isEmptyObject";
import { useCallback, useMemo } from "react";
import { getFormattedDate } from "../../../utils/formatDate";
import Skeleton from "react-loading-skeleton";
import { PriceAndDate } from "../../myJobs/jobDetails/quotes/details/PriceAndDate";
import { NothingToSeePlaceholder } from "../../../components/common/nothingToSeePlaceholder";

export const QuoteDetail = () => {
    const location = useLocation();
    const { quoteId } = useParams();
    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get("jobId");
    const token = searchParams.get("token");
    const { data: jobData, isLoading } = useGetNonUserJobQuery(
        { jobId: jobId ?? undefined, token: token ?? undefined },
        {
            refetchOnMountOrArgChange: true,
            skip: !Boolean(jobId),
        },
    );

    const filteredQuotes = jobData?.jobBid?.filter((quote: any) => !isEmptyObject(quote)) ?? [];
    const quoteData = filteredQuotes.filter((quote: any) => quote._id === quoteId);
    const title = jobData?.jobDetail?.title ?? "";
    const city = jobData?.address?.city ?? "";
    const country = jobData?.address?.country ?? "";
    const date = useMemo(() => {
        return getFormattedDate(quoteData[0]?.startDate && new Date(quoteData[0].startDate));
    }, [quoteData]);

    useCallback(() => {
        console.log("accept");
    }, []);
    return (
        <>
            <div className={styles.navigation}>
                <Logo fill="#22BCFF" />
            </div>
            <div className={styles.page}>
                <div className={styles.header}>
                    <BackButton />
                    <Heading as="h1">Offer details</Heading>
                    <span></span>
                </div>
                {quoteData.length > 0 ? (
                    <>
                        {isLoading ? (
                            <Skeleton height={39} width={"100%"} style={{ marginBottom: 19 }} />
                        ) : (
                            <Heading as="h2" size="lg" bottom={19}>
                                {title}
                            </Heading>
                        )}
                        {isLoading ? (
                            <Skeleton height={19} width={"100%"} style={{ marginBottom: 38 }} />
                        ) : (
                            <p className={styles.country}>
                                {city}, {country}
                            </p>
                        )}
                        <div className={styles.priceAndDateContainer}>
                            <PriceAndDate
                                heading="Offered Price"
                                value={`£${quoteData[0]?.price}.00`}
                            />
                            <PriceAndDate heading="Scheduled date" value={date} />
                        </div>
                        <h3 className={styles.messageFromContractorHeading}>
                            Message from contractor
                        </h3>
                        {isLoading ? (
                            <Skeleton height={22} width={"100%"} style={{ marginBottom: 51 }} />
                        ) : (
                            <p className={styles.messageFromContractorText}>
                                {quoteData[0]?.message}
                            </p>
                        )}
                    </>
                ) : (
                    <NothingToSeePlaceholder label="No job quote found" />
                )}
            </div>
        </>
    );
};
