import { memo } from "react";
import styles from "./homeOwnerPostAJob.module.css";

type TProps = {
    userPromptValue: string;
};

export const UserAIPrompt = memo((props: TProps) => (
    <div className={styles.aiPromptByUserContainer}>
        <img
            className={styles.aiPromptUserProfilePicture}
            src="https://via.placeholder.com/500x500"
            alt="profile picture"
            width={32}
            height={32}
        />
        <div>
            <p className={styles.aiPromptByUserText}>{props.userPromptValue}</p>
            <p className={styles.aiPromptByUserTime}>Just now</p>
        </div>
    </div>
));
