import { LucideLogOut } from "lucide-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "../../redux/rtk-query/api";
import { clearLocalStorage } from "../../utils/service";
import Logo from "./Logo";
import Links from "./NavLinks";
import styles from "./Sidebar.module.css";
import { TSidebarItem } from "../layouts/PropertyManagementLayouts";

const Sidebar = ({ links }: { links: TSidebarItem[] }) => {
    // const navigate = useNavigate();
    // const dispatch = useAppDispatch();
    // const { data: user } = useGetMyDetailsQuery();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const searchTerm = "";
    return (
        // <div className={`${styles.sidebar}`}>
        // styles.sidebarContent
        <div className="px-5 py-6 max-w-[260px] w-full h-screen overflow-y-auto border-r bg-white">
            <Logo />
            <Links searchTerm={searchTerm} links={links}>
                <div className="">
                    {/* <SideFooter icon={<FiSettings />} title="Settings" /> */}
                    <div
                        className={styles.navLink}
                        style={{ display: "flex", cursor: "pointer" }}
                        onClick={() => {
                            clearLocalStorage();
                            dispatch(api.util.resetApiState());
                            navigate("/login");
                        }}
                    >
                        <span className={styles.linkIcon}>
                            <LucideLogOut />
                        </span>
                        <span className={styles.linkLabel}>Log Out</span>
                    </div>
                </div>
            </Links>
        </div>
        // </div>
    );
};

export default Sidebar;
