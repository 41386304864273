import { DatePickerInput } from "@mantine/dates";
import { DateTimePicker } from "@mantine/dates";
import Label from "../../components/common/label";
import styles from "./homeOwnerPostAJob.module.css";
import { memo } from "react";

type TVariant = "date-time" | "date";

type TProps = {
    variant?: TVariant;
    labelProps: Omit<Parameters<typeof Label>[0], "type">;
    datePickerProps: Parameters<typeof DateTimePicker>[0] & Parameters<typeof DatePickerInput>[0];
};

export const DatePickerWithLabel = memo(({ variant = "date", ...rest }: TProps) => {
    const PickerComponent = variant === "date" ? DatePickerInput : DateTimePicker;

    return (
        <div>
            <Label {...rest.labelProps} type="plain-black" />
            <PickerComponent
                {...rest.datePickerProps}
                classNames={{
                    input: styles.aiPromptDatePickerInput,
                    day: styles.aiPromptDatePickerDay,
                    calendarHeader: styles.aiPromptCalendarHeader,
                    calendarHeaderLevel: styles.aiPromptCalendarHeaderLevel,
                    section: styles.aiPromptDatePickerSection,
                }}
            />
        </div>
    );
});
