import styles from "../Settings.module.css";
import { memo } from "react";
import { StatCard } from "./StatCard";

export type TStatsCardContainerProps = {
    availableBalance: number;
    pendingBalance: number;
};

export const StatsCardContainer = memo((props: TStatsCardContainerProps) => {
    return (
        <div className={styles.payoutStatCardContainer}>
            <StatCard
                key={"Available Balance"}
                label={"Available Balance"}
                value={props.availableBalance}
            />
            <StatCard
                key={"Pending Payment"}
                label={"Pending Payment"}
                value={props.availableBalance}
            />
        </div>
    );
});
