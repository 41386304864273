import { useState } from "react";
import { BackButton } from "../../../../../components/common/backButton";
import InputField from "../../../../../components/common/input/inputField";
import SelectField from "../../../../../components/common/input/selectField";
import { Heading } from "../../../../../components/common/pageHeading";
import styles from "./SetPricing.module.css";
import { Button } from "@/components/ui/button";

export function SetPricing() {
    const [errors] = useState<Record<string, string>>({});

    const options = [
        { value: "10", label: "10 days" },
        { value: "20", label: "20 days" },
        { value: "30", label: "30 days" },
    ];
    return (
        <div className="w-full px-[60px]">
            <div className="mt-[50px] mb-[20px]">
                <BackButton />
            </div>
            <div className="w-full flex justify-between rounded-[12px]">
                <Heading>Set Pricing range</Heading>
                <Button className="bg-[#000]">Save Changes</Button>
            </div>
            <div className="w-1/2 mt-[50px]">
                <div className="mb-[15px]">
                    <label>Set Pricing</label>
                    <InputField type="number" placeholder="" />
                </div>
                <div className={styles.formInputs}>
                    <label>Services</label>
                    <SelectField
                        placeholder="Select option"
                        options={options}
                        error={errors}
                        name="services"
                        isActive={false}
                    />
                </div>
            </div>
        </div>
    );
}
