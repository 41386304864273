import styles from "./account.module.css";
import { Heading } from "../../../components/common/pageHeading";
import { InputWithLabel } from "../../postAJob/InputWithLabel";
import "../../../cssReset.css";
import { useResetPasswordMutation } from "../../../redux/rtk-query/settings";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@/components/ui/button";

export const EditPassword = () => {
    const [resetPassword] = useResetPasswordMutation();
    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (newPassword !== confirmNewPassword) {
            toast.error("Password mismatch!");
            return;
        }

        try {
            await resetPassword({
                old_password: oldPassword,
                new_password: newPassword,
            }).unwrap();
            setOldPassword("");
            setNewPassword("");
            setConfirmNewPassword("");
        } catch (error) {
            toast.error("Failed to update password");
        }
    };

    const onChangeOld = (e: any) => {
        setOldPassword(e.target.value);
    };

    const onChangeNew = (e: any) => {
        setNewPassword(e.target.value);
    };

    const onChangeConfirm = (e: any) => {
        setConfirmNewPassword(e.target.value);
    };

    return (
        <section>
            <Heading bottom={58}>Edit Password</Heading>
            <form className={styles.form}>
                <InputWithLabel
                    labelProps={{ text: "Old Password" }}
                    inputProps={{
                        name: "oldPassword",
                        placeholder: "Enter old password",
                        type: "password",
                        value: oldPassword,
                        onChange: onChangeOld,
                    }}
                />
                <InputWithLabel
                    labelProps={{ text: "New Password" }}
                    inputProps={{
                        name: "newPassword",
                        placeholder: "Enter new password",
                        type: "password",
                        value: newPassword,
                        onChange: onChangeNew,
                    }}
                />
                <InputWithLabel
                    labelProps={{ text: "Confirm New Password" }}
                    inputProps={{
                        name: "confirmNewPassword",
                        placeholder: "Enter new password again",
                        type: "password",
                        value: confirmNewPassword,
                        onChange: onChangeConfirm,
                    }}
                />
                <Button
                    type="submit"
                    style={{ height: 48, maxHeight: 48, padding: "12px 30px" }}
                    className="h-[48px] max-h-[48px] px-[12px] py-[30px] bg-[#000]"
                    onClick={handleSubmit}
                >
                    Save Changes
                </Button>
            </form>
        </section>
    );
};
