import { ReactNode } from "react";
import { BsStars } from "react-icons/bs";
import { CiCalendarDate } from "react-icons/ci";
import { FaSteamSymbol } from "react-icons/fa6";
import { FiHome, FiMessageSquare, FiSettings } from "react-icons/fi";
import { GoPeople } from "react-icons/go";
import { HiOutlineWrench } from "react-icons/hi2";
import { MdOutlineAccountCircle } from "react-icons/md";
import { RiBox3Line } from "react-icons/ri";
import { TbUserCheck } from "react-icons/tb";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/common/sidebar/index";
import styles from "./layoout.module.css";

export type TSidebarItem = {
    label: string;
    icon: ReactNode;
    path?: string;
    subMenu?: {
        label: string;
        icon: ReactNode;
        path: string;
    }[];
};

// const PropertySideBar: TSidebarItem[] = [
//     {
//         label: "Dashboard",
//         icon: <FiHome />,
//         path: "/property-management/dashboard",
//     },
//     {
//         label: "Work Order",
//         icon: <HiOutlineWrench />,
//         path: "/property-management/work-order",
//     },
//     {
//         label: "Properties",
//         icon: <RiBox3Line />,
//         path: "/property-management/properties",
//     },
//     {
//         label: "Tenants",
//         icon: <GoPeople />,
//         path: "/property-management/tenants",
//         // subMenu: [
//         //     {
//         //         label: "Contracts",
//         //         icon: <RiFilePaper2Line />,
//         //         path: "/property-management/contracts",
//         //     },
//         // ],
//     },

//     // {
//     //     label: "Compliance",
//     //     icon: <VscKey />,
//     //     path: "/property-management/compliance",
//     // },

//     // { label: "Financials", icon: <FiBarChart />, path: "financials" },
//     // { label: "Appliances", icon: <SlCup />, path: "appliances" },
//     // { label: "Analytics", icon: <VscKey />, path: "analytics" },
//     {
//         label: "Messages",
//         icon: <FiMessageSquare />,
//         path: "/property-management/chat",
//     },
//     {
//         label: "Felicity AI",
//         icon: <BsStars />,
//         path: "/property-management/ai-assistant",
//     },
//     {
//         label: "Knowledge",
//         icon: <TbUserCheck />,
//         path: "/property-management/knowledge",
//     },
//     {
//         label: "Calendar",
//         icon: <CiCalendarDate />,
//         path: "/property-management/calendar",
//     },
//     // {
//     //     label: "Maintenance",
//     //     icon: <FiSettings />,
//     //     path: "/property-management/maintenance",
//     // },

//     {
//         label: "Settings",
//         icon: <FiSettings />,
//         subMenu: [
//             {
//                 label: "Account",
//                 icon: <MdOutlineAccountCircle />,
//                 path: "/property-management/settings/account",
//             },
//             {
//                 label: "Teams",
//                 icon: <FaSteamSymbol />,
//                 path: "/property-management/teams",
//             },
//         ],
//     },

//     // {
//     //   label: "Notification",
//     //   icon: <IoMdNotificationsOutline />,
//     //   path: "notification",
//     // },
// ];

const PropertyManagementLayouts = () => {
    return (
        <div className={`${styles.propertyDashboardLayout} bg-[#F6F6F6] overflow-hidden`}>
            <div className="w-full max-w-[1440px] mx-auto flex">
                <Sidebar />
                {/* <Sidebarr links={PropertySideBar} /> */}
                <Outlet />
            </div>
        </div>
    );
};

export default PropertyManagementLayouts;
