import { IOption } from "../constantData";

export interface AuthDetails {
    firstName: string;
    lastName: string;
    username: string;
    userType: string;
    email: string;
    password: string;
    phone: string;
    checked?: boolean;
    providerId?: string | null;
    imageUrl?: string;
    companyId?: string;
    city?: string;
    mileRadiusPref?: number;
    skills?: IOption[];
    homeDescription?: string;
    homeFeature?: string[];
    companyName?: string;
    companyUrl?: string;
    companySize?: any;
    companyPhoneNumber?: any;
    reasonOfJoining?: string;
}

export const AuthInitialValues: AuthDetails = {
    firstName: "",
    lastName: "",
    username: "",
    userType: "",
    email: "",
    password: "",
    phone: "",
    checked: false,
    providerId: null,
    imageUrl: "",
    companyId: "",
    city: "",
    skills: [],
    homeDescription: "",
    homeFeature: [],
};

export enum UserType {
    SUPPLIERS = "SUPPLIERS",
    CONTRACTOR = "CONTRACTOR",
    TENANT = "TENANT",
    PROPERTY_MANAGER = "PROPERTY_MANAGER",
    HOMEOWNER = "HOMEOWNER",
}

export const USER_TYPES = {
    HOMEOWNER: 1,
    CONTRACTOR: 2,
    PROPERTY_MANAGER: 3,
    TENANT: 7,
    SUPPLIERS: 5,
    STAFF_TECHNICIAN: 6,
    SOLE_TRADER: 4,
    ADMIN: 8,
} as const;

export type TUSER_TYPE = (typeof USER_TYPES)[keyof typeof USER_TYPES];
