import { IConversation } from "@/types";
import { useEffect, useState } from "react";

export const useConversationSelection = (
    conversationList: IConversation[] | undefined,
    defaultConversationId?: string,
) => {
    const [selectedConversation, setSelectedConversation] = useState<string>();
    const [isAiChat, setIsAiChat] = useState(false);
    const [conversationObject, setConversationObject] = useState<IConversation>();

    useEffect(() => {
        const savedConversationId = localStorage.getItem("lastSelectedConversation") || "";
        const savedConversationObject = localStorage.getItem("lastConversationObject") || "";

        const initialConversationId = defaultConversationId || savedConversationId;

        if (initialConversationId && (conversationList || savedConversationObject)) {
            const conversationResult =
                conversationList?.find((item) => item._id === initialConversationId) ||
                JSON.parse(savedConversationObject);

            setSelectedConversation(initialConversationId);

            if (conversationResult?.conversationType === "AI_ASSISTANT_CHAT") {
                setIsAiChat(true);
            }

            setConversationObject(conversationResult);
        }
    }, [conversationList, defaultConversationId]);

    const handleConversationSelect = (conversationId: string) => {
        setSelectedConversation(conversationId);

        const conversationResult = conversationList?.find((item) => item._id === conversationId);

        if (conversationResult) {
            setIsAiChat(conversationResult?.conversationType === "AI_ASSISTANT_CHAT");
            setConversationObject(conversationResult);
            localStorage.setItem("lastSelectedConversation", conversationId);
            localStorage.setItem("lastConversationObject", JSON.stringify(conversationResult));
        }
    };

    return { selectedConversation, conversationObject, isAiChat, handleConversationSelect };
};
