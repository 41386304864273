import React, { ComponentProps, memo, ReactNode } from "react";
import { TKeyValue } from "../../../types";
import styles from "./table.module.css";
import { NothingToSeePlaceholder } from "../nothingToSeePlaceholder";

export type TTableHeaders = TKeyValue<number, string | number | ReactNode>[];
export type TTableRows = TKeyValue<string | number, string | number | ReactNode>[];

export type TTableProps = {
    headerItems: TTableHeaders;
    rowItems: TTableRows[];
    containerProps?: ComponentProps<"div">;
    style?: React.CSSProperties;
    onClickRow?: (rowItem: TTableRows, index: number) => void;
};

type TableHeadProps = {
    headerItems: TTableHeaders;
    style?: React.CSSProperties;
};

const TableHead = memo(({ headerItems, style }: TableHeadProps) => (
    <div className={styles.divTableHeading} style={style}>
        <div className={styles.divTableRow}>
            {headerItems.map(({ key, value }) => (
                <div key={key} className={styles.divTableHead}>
                    {value}
                </div>
            ))}
        </div>
    </div>
));
const TableRowDivider = () => <div className={styles.divTableRowDivider} />;

type TTableRowProps = {
    rowItem: TTableRows;
    onClickRow?: TTableProps["onClickRow"];
    index: number;
};
const TableRow = memo((props: TTableRowProps) => {
    const { rowItem, onClickRow, index } = props;
    return (
        <div
            className={styles.divTableRow}
            onClick={() => onClickRow && onClickRow(rowItem, index)}
            style={{ cursor: onClickRow ? "pointer" : "auto" }}
        >
            {rowItem &&
                rowItem.map((item) => {
                    return (
                        <div key={item.key} className={styles.divTableCell}>
                            {item.value}
                        </div>
                    );
                })}
        </div>
    );
});

export const Table = memo((props: TTableProps) => {
    const { headerItems, rowItems, containerProps, style } = props;
    return (
        <div {...containerProps}>
            <div className={`${styles.divTable} ${styles.myTable}`}>
                <TableHead headerItems={headerItems} style={style} />
                {rowItems.length > 0 ? (
                    rowItems.map((row, index) => (
                        <div className={styles.divTableBody}>
                            <React.Fragment key={index}>
                                <TableRow
                                    index={index}
                                    rowItem={row}
                                    onClickRow={props.onClickRow}
                                />
                                <TableRowDivider />
                            </React.Fragment>
                        </div>
                    ))
                ) : (
                    <tr>
                        <td colSpan={12}>
                            <NothingToSeePlaceholder label="No Data Found" />
                        </td>
                    </tr>
                )}
            </div>
        </div>
    );
});
