export function PhoneMissedIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <path
                d="M12.8334 5.99935L10.5 3.66602M10.5 5.99935L12.8334 3.66602M4.04547 3.66602H5.92859L7.30954 5.73744L6.21781 6.82918C6.04037 7.00662 5.97825 7.26847 6.08555 7.49532C6.27701 7.90011 6.65848 8.56904 7.30954 9.18983C7.94545 9.79616 8.59055 10.182 8.98809 10.3874C9.22249 10.5085 9.50128 10.4505 9.68784 10.2639L10.7619 9.18983L12.8334 10.5708V12.4539C12.8334 12.9392 12.4399 13.3327 11.9546 13.3327C7.10115 13.3327 3.16669 9.39822 3.16669 4.5448C3.16669 4.05946 3.56013 3.66602 4.04547 3.66602Z"
                stroke="#FFAC00"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
