import { useEffect, useRef, useState } from "react";

interface UseGoogleAutocompleteProps {
    name: string;
    handleUserDetails: (event: React.ChangeEvent<HTMLInputElement>) => void;
    showCompleteDetails?: boolean;
}

const useGoogleAutocomplete = ({
    name,
    handleUserDetails,
    showCompleteDetails = false,
}: UseGoogleAutocompleteProps) => {
    const apiKey = process.env.REACT_APP_PLACES_AUTO_COMPLETE;
    const autocompleteInputRef = useRef<HTMLInputElement>(null);
    const [place, setPlace] = useState<google.maps.places.PlaceResult>();

    useEffect(() => {
        const loadScript = () => {
            const script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initAutocomplete`;
            script.async = true;
            script.defer = true;
            script.onload = initializeAutocomplete;
            document.body.appendChild(script);
        };

        const initializeAutocomplete = () => {
            if (!autocompleteInputRef.current) return;
            const autocomplete = new google.maps.places.Autocomplete(
                autocompleteInputRef.current,
                showCompleteDetails ? {} : {},
            );

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (place) {
                    setPlace(place);
                    handleUserDetails({
                        target: {
                            name,
                            value: place.formatted_address || "",
                        },
                    } as React.ChangeEvent<HTMLInputElement>);
                }
            });
        };

        if (apiKey) {
            loadScript();
        }

        return () => {
            window.initAutocomplete = () => {};
            const existingScript = document.querySelector(
                `script[src="https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initAutocomplete"]`,
            );
            if (existingScript) {
                existingScript.remove();
            }
        };
    }, [apiKey, handleUserDetails, name, showCompleteDetails]);

    return { autocompleteInputRef, place };
};

export default useGoogleAutocomplete;
