import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import React, { useCallback, useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { useGetJobFileMetadataQuery } from "@/redux/rtk-query/uploadApis";
import AddWorkContainer from "@/pages/myJobs/components/AddWorkContainer";
import LBModal from "@/components/lBComponents/LBModal";
import { useGetMyDetailsQuery } from "@/pages/propertyManagementKnowledgeBase";
import { useGetJobQuery } from "@/redux/rtk-query/jobsApis";
import WorkOrder, { TGetAllJobs } from "@/types"; // Make sure to import these types
import Loader from "@/components/loading";

interface JobItemProps {
    date?: string;
    contractor?: string;
    urgency?: string;
    frequency?: string;
    status?: string;
    callSummary?: string;
    callDetails?: string;
    id: string; // Make this required
    name?: string;
    isLoading?: boolean;
}

const JobItem: React.FC<JobItemProps> = ({
    date,
    contractor,
    urgency,
    frequency,
    status,
    callSummary,
    id,
    name,
    isLoading,
}) => {
    const [workOrderDialog, setWorkOrderDialog] = React.useState(false);
    const [currentDocuments, setCurrentDocuments] = useState<any[] | undefined>(undefined);

    const { data: myUserDetails } = useGetMyDetailsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const { data: jobResult, isLoading: isJobLoading } = useGetJobQuery(
        { jobId: id, companyId: myUserDetails?.companyId },
        {
            skip: !workOrderDialog || !myUserDetails?.companyId,
        },
    );

    // Transform jobResult to WorkOrder
    const transformJobToWorkOrder = (job: TGetAllJobs): WorkOrder => {
        console.log(job);

        return {
            _id: job._id,
            status: job.status,
            slug: job.slug,
            jobType: job.jobType || undefined,
            contractType: job.contractType,
            jobPriority: job.jobPriority,
            propertyId: job.propertyId,
            // tenantId: job.tenantId,
            jobCategory: job.jobCategory,
            dueDate: job.dueDate || "",
            startDate: job.startDateTime || "",
            assignedTo: job.assignedTo || "",
            callerPhonenumber: job.callerPhonenumber,
            callerEmail: job.callerEmail,
            callId: null,
            callerName: null,
            bookedBy: job.bookedBy,
            tenantDetails: undefined,
            propertyDetails: null,
            jobDetail: job.jobDetail,
            createdAt: job.createdAt,
            updatedAt: job.updatedAt,
            addressId: job.addressId,
            jobDetailsId: job.jobDetailsId,
            address: job.address,
            attachments: [], // You might need to add this to TGetAllJobs if available
            postedBy: {
                _id: job.postedBy._id,
                userType: job.postedBy.userType,
                stripeConnectVerif: job.postedBy.stripeConnectVerif,
                email: job.postedBy.email,
                firstName: job.postedBy.firstName,
                lastName: job.postedBy.lastName,
                companyId: "",
                status: job.postedBy.status,
                createdAt: job.postedBy.createdAt,
                updatedAt: job.postedBy.updatedAt,
                __v: job.postedBy.__v,
                phone: job.postedBy.phone,
                username: job.postedBy.username,
                firebaseUid: job.postedBy.firebaseUid,
                imageUrl: job.postedBy.imageUrl,
                providerId: job.postedBy.providerId,
            },
            assignedToInfo: job.assignedToInfo!,
            dueDateTime: job.dueDateTime,
        };
    };

    const workOrderData = jobResult ? transformJobToWorkOrder(jobResult) : null;

    const { data: files, isFetching: isDocumentsLoading } = useGetJobFileMetadataQuery(id, {
        skip: !workOrderDialog,
    });

    React.useEffect(() => {
        if (files && jobResult) {
            setCurrentDocuments(files);
        }
    }, [files, jobResult]);

    const details = [
        { label: "Date", value: date },
        { label: "Contractor", value: contractor },
        { label: "Urgency", value: urgency },
        { label: "Frequency", value: frequency },
        { label: "Status", value: status },
    ];

    const onClickViewJob = useCallback(() => {
        setWorkOrderDialog(true);
    }, []);

    return (
        <div className="bg-white shadow-lg rounded-md p-4 w-80 lg:w-auto">
            {workOrderDialog && (
                <LBModal
                    headerContainerClassName=""
                    closeButtonClassName="text-black"
                    isOpen={workOrderDialog}
                    onClose={() => {
                        setWorkOrderDialog(false);
                        setCurrentDocuments(undefined);
                    }}
                    title={"Work Order Details"}
                    titleClassName="text-[16px] font-semibold"
                >
                    {isJobLoading ? (
                        <div className="">
                            <div>Loading....</div>
                        </div>
                    ) : (
                        <AddWorkContainer
                            mode={"Preview"}
                            currentPreviewOrderInfo={workOrderData}
                            currentWorkOrderDocuments={currentDocuments}
                            currentDocumentsLoading={isDocumentsLoading}
                            editDetailsButton={false}
                        />
                    )}
                </LBModal>
            )}
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-[35%_1fr]">
                <div className="bg-gray-50 p-2 rounded-md border border-gray-200">
                    <h3 className="font-medium">
                        {isLoading ? (
                            <Skeleton className="h-6 w-[100px] bg-gray-300" />
                        ) : (
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger
                                        className={cn(
                                            name && name.length > 25 ? "" : "cursor-default",
                                            "bg-transparent",
                                        )}
                                    >
                                        {name && name.length > 25 ? name.slice(0, 25) : name}
                                    </TooltipTrigger>
                                    {name && name.length > 25 && (
                                        <TooltipContent className="w-44 h-64 overflow-y-auto">
                                            {name}
                                        </TooltipContent>
                                    )}
                                </Tooltip>
                            </TooltipProvider>
                        )}
                    </h3>
                    <div className="mt-2 space-y-2 text-sm h-40">
                        {isLoading ? (
                            Array(5)
                                .fill("")
                                .map((_, index) => (
                                    <Skeleton key={index} className="h-4 w-full bg-gray-300" />
                                ))
                        ) : (
                            <div className="grid grid-cols-[auto_1fr] gap-x-4 gap-y-2">
                                {details.map((detail, index) => (
                                    <React.Fragment key={index}>
                                        <span className="text-gray-500">{detail.label}</span>
                                        <span>{detail.value}</span>
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="flex justify-center mt-2">
                        {isLoading ? (
                            <Skeleton className="h-10 w-60 bg-gray-300" />
                        ) : (
                            <Button className="w-60 bg-[#000]" onClick={onClickViewJob}>
                                View Work Order
                            </Button>
                        )}
                    </div>
                </div>
                <div className="bg-gray-50 rounded-md p-2 text-sm border border-gray-200">
                    <h3 className="font-medium">Call Summary</h3>
                    <div className="h-40">
                        {isLoading ? (
                            Array(9)
                                .fill("")
                                .map((_, index) => (
                                    <Skeleton key={index} className="h-4 w-full bg-gray-300 my-2" />
                                ))
                        ) : (
                            <p className="mt-2 text-gray-500">{callSummary}</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobItem;
