import { NavigateFunction } from "react-router-dom";
import { TUSER_TYPE, USER_TYPES } from "./interface/auth.interface";

const USER_TYPE_TO_AFTER_LOGIN_ROUTE_MAP: Record<TUSER_TYPE, string> = {
    [USER_TYPES.HOMEOWNER]: "/home-owner/dashboard",
    [USER_TYPES.CONTRACTOR]: "/contractor/dashboard",
    [USER_TYPES.PROPERTY_MANAGER]: "/property-management/dashboard",
    [USER_TYPES.TENANT]: "/tenant/dashboard",
    [USER_TYPES.SUPPLIERS]: "",
    [USER_TYPES.STAFF_TECHNICIAN]: "",
    [USER_TYPES.SOLE_TRADER]: "",
    [USER_TYPES.ADMIN]: "",
};

const ROUTED = true;

export const handleLoginRoute = (navigate: NavigateFunction, userType: TUSER_TYPE) => {
    const path = USER_TYPE_TO_AFTER_LOGIN_ROUTE_MAP[userType];

    if (!path) return !ROUTED;

    navigate(path, { replace: true });

    return ROUTED;
};
