import { useCallback, useState } from "react";
import "../../../cssReset.css";
import { useGetMyDetailsQuery } from "../../../redux/rtk-query/authApis";
import styles from "./account.module.css";
import { ProfileSettings } from "./AccountProfileSettings";
import { AccountSidebar } from "./AccountSidebar";
import { CompanySettings } from "./CompanySettings";
import { EditPassword } from "./EditPassword";
import { cn } from "@/lib/utils";

export const SidebarItems = [
    {
        key: "Profile",
        value: "profile",
    },
    {
        key: "Company",
        value: "company",
    },
    // {
    //   key: "Notifications",
    //   value: "notifications",
    // },
    {
        key: "Password",
        value: "password",
    },
] as const;

export type TSideBarItemValue = (typeof SidebarItems)[number]["value"];

export const Account = () => {
    const [selectedSidebarItem, setSelectedSidebar] = useState<TSideBarItemValue>(
        SidebarItems[0].value,
    );
    const { data: user } = useGetMyDetailsQuery();
    const onClickSidebarItem = useCallback((selectedItem: TSideBarItemValue) => {
        setSelectedSidebar(selectedItem);
    }, []);

    return (
        <section className={cn(styles.account)}>
            <AccountSidebar
                sidebarItems={SidebarItems}
                onClickSidebarItem={onClickSidebarItem}
                selectedSidebarItem={selectedSidebarItem}
            />

            <div className={styles.accountContent}>
                {(() => {
                    switch (selectedSidebarItem) {
                        case "profile":
                            return <ProfileSettings user={user} />;
                        case "company":
                            return <CompanySettings user={user} />;
                        case "password":
                            return <EditPassword />;
                    }
                })()}
            </div>

            {/* <div className={styles.accountAction}>
        <Button
          style={{ height: 48, maxHeight: 48, padding: "12px 30px" }}
          onClick={submitHandler}
        >
          Save Changes
        </Button>
      </div> */}
        </section>
    );
};
