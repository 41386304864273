import { memo, useMemo } from "react";
import Label from "../label";
import { ChevronLeftIcon } from "../../../assets/icons/ChevronLeftIcon";
import styles from "./Dropdown.module.css";
import { TDropdownValue } from "./Dropdown.types";

type TProps = {
    value: TDropdownValue;
    labelProps?: Omit<Parameters<typeof Label>[0], "type">;
};

export const DefaultTarget = memo((props: TProps) => {
    const { value, labelProps } = props;
    const _value = useMemo(() => {
        return Array.isArray(value) ? value.join(", ") : value;
    }, [value]);

    return (
        <div>
            {labelProps && <Label {...labelProps} type="plain-black" />}
            <div style={{ marginTop: labelProps ? 12 : 0 }} className={styles.defaultTarget}>
                <p>{_value}</p>
                <ChevronLeftIcon direction="down" />
            </div>
        </div>
    );
});
