import React from "react";
import ProfitChart from "../../assets/icons/ProfitChart";
import LossChart from "../../assets/icons/LossChart";
import SmallLineCharts from "@/components/chart/SmallLineCharts";

type TProps = {
    title: string;
    profit: boolean;
    profitNumber: number;
    data: any;
};

export const BoxContent: React.FC<TProps> = ({ title, profit, profitNumber, data }) => {
    return (
        <>
            <div className="flex flex-col justify-between px-[10px]">
                <p className="text-[13px] text-[#333] mb-[10px] text-xl font-semibold">{title}</p>
            </div>
            <div className="flex justify-between items-end gap-[10px] ml-[5px]">
                <div>
                    <p className="text-[35px] font-medium leading-[2] ">{data.data}</p>
                    <div className="flex">
                        <p
                            className={`flex items-center text-[15px] font-medium gap-[4px] ${profit ? "text-customGreen" : "text-customRed"}`}
                        >
                            {profit ? <ProfitChart /> : <LossChart />}
                            {`${profitNumber}%`}
                        </p>{" "}
                        {/* <span>vs last mth</span> */}
                    </div>
                </div>
                {/* {profit ? <ProfiltChartIcon /> : <LossChartIcon />} */}
                <SmallLineCharts isInProfit={profit} />
            </div>
        </>
    );
};
