import { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../dashboard/contractor/navbar";
import MobileNavigation from "../dashboard/contractor/navbar/mobileNavigation";
import styles from "./layoout.module.css";

const ChatContractorLayout = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    return (
        <>
            <MobileNavigation isOpen={isOpen} setIsOpen={setIsOpen} />
            <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
            <div className={styles.chatLayout}>
                <main>
                    <Outlet />
                </main>
            </div>
        </>
    );
};

export { ChatContractorLayout };
