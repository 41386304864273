// import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
// import {
//     Command,
//     CommandEmpty,
//     CommandGroup,
//     CommandInput,
//     CommandItem,
//     CommandList,
// } from "@/components/ui/command";
// import { Button } from "@/components/ui/button";
// import { Check, ChevronsUpDown } from "lucide-react";
// import { cn } from "@/lib/utils";
// import { useState } from "react";
// import { ScrollArea } from "@/components/ui/scroll-area";

// type DropDownItem = {
//     value: string;
//     label: string;
// };

// interface PopOverProps {
//     open: boolean;
//     setOpen: (open: boolean) => void;
//     DropDownArray: DropDownItem[];
//     type?: string;
//     onChange?: any;
//     className?: string;
//     placeholder: string;
//     message: string;
//     icon: React.ComponentType<{ className?: string }>;
// }

// export const PopOver: React.FC<PopOverProps> = ({
//     open,
//     setOpen,
//     DropDownArray,
//     onChange,
//     type = "responsePopUp",
//     className = "",
//     placeholder,
//     message,
//     icon: Icon,
// }) => {
//     const [value, setValue] = useState("");

//     const handleSelect = (currentValue: string, label: string) => {
//         const newValue = currentValue === value ? "" : currentValue;
//         setValue(newValue);
//         setOpen(false);
//         onChange(newValue, label);
//     };

//     return (
//         <Popover open={open} onOpenChange={setOpen}>
//             <PopoverTrigger asChild>
//                 <Button
//                     variant="outline"
//                     role="combobox"
//                     aria-expanded={open}
//                     className={`justify-start gap-2 pl-1 py-5 border ${type === "responsePopUp" ? "min-w-[350px] " : "border-dashed min-w-[180px]"} border-gray-300 hover:bg-white ${className}`}
//                 >
//                     {type === "responsePopUp" ? (
//                         <div className="flex justify-between items-center w-full">
//                             <div className="flex justify-start items-center gap-2">
//                                 <Icon className="ml-2 h-5 w-5 shrink-0" />
//                                 {value
//                                     ? DropDownArray.find((framework) => framework.value === value)
//                                           ?.label
//                                     : placeholder}
//                             </div>
//                             <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
//                         </div>
//                     ) : (
//                         <>
//                             <Icon className="ml-2 h-4 w-4 shrink-0" />
//                             Property{" "}
//                             <div className="flex items-center justify-center">
//                                 <div className="border-r border-gray-500 h-4 mr-2"></div>
//                                 <div className="bg-gray-200 rounded-sm p-1 min-w-[80px]">
//                                     {value
//                                         ? DropDownArray.find(
//                                               (framework) => framework.value === value,
//                                           )?.label
//                                         : placeholder}
//                                 </div>
//                             </div>
//                         </>
//                     )}
//                 </Button>
//             </PopoverTrigger>
//             <PopoverContent
//                 className={`${type === "responsePopUp" ? "min-w-[350px]" : "min-w-[200px]"} p-0 ${className}`}
//             >
//                 <Command>
//                     <CommandInput placeholder={placeholder} />
//                     <CommandList>
//                         <CommandEmpty>{message}</CommandEmpty>
//                         <ScrollArea className="min-h-2 max-h-48">
//                             <CommandGroup>
//                                 {DropDownArray.map((framework) => (
//                                     <CommandItem
//                                         key={framework.value}
//                                         value={framework.value}
//                                         onSelect={() =>
//                                             handleSelect(framework.value, framework.label)
//                                         }
//                                     >
//                                         <Check
//                                             className={cn(
//                                                 "mr-2 h-4 w-4",
//                                                 value === framework.value
//                                                     ? "opacity-100"
//                                                     : "opacity-0",
//                                             )}
//                                         />
//                                         {framework.label}
//                                     </CommandItem>
//                                 ))}
//                             </CommandGroup>
//                         </ScrollArea>
//                     </CommandList>
//                 </Command>
//             </PopoverContent>
//         </Popover>
//     );
// };

import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import { Button } from "@/components/ui/button";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";

type DropDownItem = {
    value: string;
    label: string;
};

interface PopOverProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    DropDownArray: DropDownItem[];
    type?: string;
    onChange?: (propertyId: string, propertyName: string) => void;
    className?: string;
    placeholder: string;
    message: string;
    icon: React.ComponentType<{ className?: string }>;
}

export const PopOver: React.FC<PopOverProps> = ({
    open,
    setOpen,
    DropDownArray,
    onChange,
    type = "responsePopUp",
    className = "",
    placeholder,
    message,
    icon: Icon,
}) => {
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const handleSelect = (currentValue: string, label: string) => {
        const isSelected = selectedValues.includes(currentValue);
        if (isSelected) {
            setSelectedValues(selectedValues.filter((value) => value !== currentValue));
        } else {
            setSelectedValues([...selectedValues, currentValue]);
        }
        setOpen(false);
        if (onChange) {
            onChange(currentValue, label); // Only pass the current value and label
        }
    };

    const selectedLabels = DropDownArray.filter((item) => selectedValues.includes(item.value))
        .map((item) => item.label)
        .join(", ");

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className={`justify-start gap-2 pl-1 py-5 border ${type === "responsePopUp" ? "min-w-[350px]" : "border-dashed min-w-[180px]"} border-gray-300 hover:bg-white ${className}`}
                >
                    {type === "responsePopUp" ? (
                        <div className="flex justify-between items-center w-full">
                            <div className="flex justify-start items-center gap-2">
                                <Icon className="ml-2 h-5 w-5 shrink-0" />
                                {selectedLabels || placeholder}
                            </div>
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </div>
                    ) : (
                        <>
                            <Icon className="ml-2 h-4 w-4 shrink-0" />
                            Property{" "}
                            <div className="flex items-center justify-center">
                                <div className="border-r border-gray-500 h-4 mr-2"></div>
                                <div className="bg-gray-200 rounded-sm p-1 min-w-[80px]">
                                    {selectedLabels || placeholder}
                                </div>
                            </div>
                        </>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent
                className={`${type === "responsePopUp" ? "min-w-[350px]" : "min-w-[200px]"} p-0 ${className}`}
            >
                <Command>
                    <CommandInput placeholder={placeholder} />
                    <CommandList>
                        <CommandEmpty>{message}</CommandEmpty>
                        <ScrollArea className="min-h-2 max-h-48">
                            <CommandGroup>
                                {DropDownArray.map((framework) => (
                                    <CommandItem
                                        key={framework.value}
                                        value={framework.value}
                                        onSelect={() =>
                                            handleSelect(framework.value, framework.label)
                                        }
                                    >
                                        <Check
                                            className={cn(
                                                "mr-2 h-4 w-4",
                                                selectedValues.includes(framework.value)
                                                    ? "opacity-100"
                                                    : "opacity-0",
                                            )}
                                        />
                                        {framework.label}
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        </ScrollArea>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
};
