import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Quotes.module.css";
import { BackButton } from "../../../components/common/backButton";
import { Heading } from "../../../components/common/pageHeading";
import { Logo } from "../../../assets/icons";
import {
    useGetNonUserJobQuery,
    useUpdateJobPhotosMutation,
} from "../../../redux/rtk-query/jobsApis";
import { QuoteCard, QuoteCardSkeleton } from "./QuoteCard";
import { NothingToSeePlaceholder } from "../../../components/common/nothingToSeePlaceholder";
import { getFormattedDate } from "../../../utils/formatDate";
import { capitalize } from "../../../utils/capitalize";
import { useCallback } from "react";
import isEmptyObject from "../../../utils/isEmptyObject";
import { toast } from "react-toastify";

export default function Quotes() {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get("jobId");
    const token = searchParams.get("token");
    const { data: jobData, isLoading } = useGetNonUserJobQuery(
        { jobId: jobId ?? undefined, token: token ?? undefined },
        {
            skip: !Boolean(jobId),
        },
    );
    const filteredQuotes = jobData?.jobBid?.filter((quote: any) => !isEmptyObject(quote)) ?? [];
    const acceptedQuotes = filteredQuotes.filter((quote: any) => quote.status === "ACCEPTED");
    const [updateJobPhotos] = useUpdateJobPhotosMutation();

    const onClickAccept = useCallback(
        async (_: any, userId: any) => {
            if (!jobId) {
                toast.error("Invalid job ID");
                return;
            }
            if (!token) {
                toast.error("Invalid token");
                return;
            }
            if (jobId && userId) {
                const payload = {
                    _id: jobId,
                    status: "ACTIVE",
                    // assignedTo: userId,
                };
                const res = await updateJobPhotos({
                    data: payload,
                    token: token,
                });
                if ("error" in res) {
                    toast.error("Failed to post job.");
                } else {
                    toast.success("Quote accepted successfully!");
                }
            }
        },
        [jobId, token, updateJobPhotos],
    );

    const onClickViewDetails = useCallback(
        (quoteId: string) => {
            navigate(`/ai-assistant/confirm-job/quotes/${quoteId}?jobId=${jobId}&token=${token}`);
        },
        [jobId, navigate, token],
    );

    return (
        <>
            <div className={styles.navigation}>
                <Logo fill="#22BCFF" />
            </div>
            <div className={styles.page}>
                <div className={styles.header}>
                    <BackButton />
                    <Heading as="h1">Job Quotes</Heading>
                    <span></span>
                </div>
                {filteredQuotes.length > 0 ? (
                    <div className={styles.quoteCardsContainer}>
                        {filteredQuotes.map((quote: any, index: number) => {
                            const createdAt =
                                quote?.createdAt &&
                                getFormattedDate(new Date(quote?.createdAt)).toString();
                            const firstName = capitalize(quote.userInfo.firstName);
                            const lastName = capitalize(quote.userInfo.lastName);

                            return (
                                <QuoteCard
                                    key={quote?._id ?? index}
                                    acceptedQuotes={acceptedQuotes}
                                    status={quote?.status}
                                    price={quote?.price ?? 0}
                                    createdAt={createdAt ?? ""}
                                    _id={quote._id ?? ""}
                                    userId={quote.userId}
                                    onClickAccept={onClickAccept}
                                    onClickViewDetails={onClickViewDetails}
                                    name={`${firstName} ${lastName}`}
                                />
                            );
                        })}
                    </div>
                ) : isLoading ? (
                    <div className={styles.quoteCardsContainer}>
                        {Array(3)
                            .fill(0)
                            .map((_, index) => (
                                <QuoteCardSkeleton key={index} />
                            ))}
                    </div>
                ) : (
                    <NothingToSeePlaceholder label="No job quotes" />
                )}
            </div>
        </>
    );
}
