import { createTheme, MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Router from "./routes";
import "react-phone-input-2/lib/style.css";

const theme = createTheme({});

function App() {
    return (
        <>
            <ToastContainer />
            <MantineProvider theme={theme}>
                <Router />
            </MantineProvider>
        </>
    );
}

export default App;
