export function PhoneIcomingIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <path
                d="M10.4 2.5V6.1H14"
                stroke="#22BCFF"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M14.6 1.90039L10.4 6.10039"
                stroke="#22BCFF"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.9999 11.4521V13.2521C14.0006 13.4192 13.9664 13.5846 13.8995 13.7377C13.8325 13.8908 13.7343 14.0282 13.6112 14.1412C13.4881 14.2541 13.3427 14.3401 13.1844 14.3937C13.0261 14.4472 12.8584 14.4671 12.6919 14.4521C10.8456 14.2514 9.07215 13.6205 7.51395 12.6101C6.06424 11.6889 4.83515 10.4598 3.91395 9.01006C2.89993 7.44478 2.26889 5.66266 2.07195 3.80806C2.05695 3.64214 2.07667 3.47491 2.12985 3.31703C2.18302 3.15915 2.26849 3.01407 2.38081 2.89103C2.49312 2.76799 2.62983 2.66968 2.78222 2.60237C2.93461 2.53506 3.09935 2.50022 3.26595 2.50006H5.06595C5.35713 2.49719 5.63942 2.60031 5.8602 2.79018C6.08099 2.98005 6.22519 3.24373 6.26595 3.53206C6.34192 4.1081 6.48282 4.67369 6.68595 5.21806C6.76667 5.43281 6.78415 5.66621 6.73629 5.89059C6.68844 6.11497 6.57727 6.32092 6.41595 6.48406L5.65395 7.24606C6.50808 8.74819 7.75182 9.99193 9.25395 10.8461L10.0159 10.0841C10.1791 9.92274 10.385 9.81157 10.6094 9.76371C10.8338 9.71586 11.0672 9.73333 11.2819 9.81406C11.8263 10.0172 12.3919 10.1581 12.9679 10.2341C13.2594 10.2752 13.5256 10.422 13.7159 10.6466C13.9061 10.8711 14.0072 11.1578 13.9999 11.4521Z"
                stroke="#22BCFF"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
