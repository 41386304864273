import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { useGetBuildingsByCompanyQuery } from "@/redux/rtk-query/properties";
import { useMemo, useState } from "react";

type PropertiesProps = {
    search: string;
};
const usePropertiesRow = ({ search }: PropertiesProps) => {
    const [queryParams, setQueryParams] = useState({
        limit: 10,
        skip: 0,
        sort: "",
    });
    const { data: user } = useGetMyDetailsQuery();
    const { data: propertiesData, isLoading } = useGetBuildingsByCompanyQuery(
        {
            id: user?.companyId || "",
            type: "COMPANY",
            ...queryParams,
            search,
        },
        {
            skip: !user?.companyId,
        },
    );

    const propertyRow = useMemo(() => {
        if (!Array.isArray(propertiesData?.data)) return [];
        return propertiesData?.data.map((property: any, _) => {
            return {
                id: property._id,
                name: property.name,
                category: property.category.slice(0, 1).toUpperCase() + property.category.slice(1),
            };
        });
    }, [propertiesData]);

    const handlePageChange = (newPage: number) => {
        setQueryParams((prev) => ({
            ...prev,
            skip: (newPage - 1) * prev.limit,
        }));
    };

    return {
        propertiesData: propertiesData?.data,
        propertyRow,
        isLoading,
        pagination: propertiesData?.pagination || {
            totalDocs: 0,
            limit: queryParams.limit,
            skip: queryParams.skip,
            totalPages: 0,
            currentPage: Math.floor(queryParams.skip / queryParams.limit) + 1,
        },
        handlePageChange,
    };
};

export default usePropertiesRow;
