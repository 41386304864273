import { Moveicon } from "@/assets/icons/Moveicon";
import { PieCharts } from "@/components/chart/PieChart";
import { Button } from "@/components/ui/button";
import React from "react";

interface ChartDataItem {
    propertyStatLabel: string;
    stat: number;
    fill: string;
}

interface DashBoardChartProps {
    title: string;
    chartData: ChartDataItem[];
    totalTasks?: number;
}

export const DashBoardChart: React.FC<DashBoardChartProps> = ({ title, chartData, totalTasks }) => {
    return (
        <div className="shadow-sm bg-white rounded-[10px] border border-gray-300">
            <div className="flex justify-between px-5 mt-[10px]">
                <div className="flex gap-2 items-center">
                    <div className="bg-transparent cursor-pointer">
                        <Moveicon />
                    </div>
                    <div className="text-[#101828] text-[15px] font-bold ">{title}</div>
                </div>
                <Button className="bg-white text-black hover:bg-[#f6f6f6]">View All</Button>
            </div>
            <div className="mb-[29px]">
                <PieCharts
                    chartConfig={{
                        chrome: {
                            label: "Valid",
                            color: "#0DADEA",
                        },
                        edge: {
                            label: "Expired",
                            color: "#02E09F",
                        },
                        safari: {
                            label: "Not Recorded",
                            color: "#00F5F5",
                        },
                    }}
                    label="%"
                    chartData={chartData}
                    totalTasks={totalTasks}
                />
            </div>
        </div>
    );
};
