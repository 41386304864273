/**
 * v0 by Vercel.
 * @see https://v0.dev/t/S7i4R9HnpaD
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { Card, CardContent } from "@/components/ui/card";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { LineChart, Line } from "recharts";

interface SmallLineChartsProps {
    isInProfit: boolean; // Change 'boolean' to 'number' if it should be a number
}

export default function SmallLineCharts({ isInProfit }: SmallLineChartsProps) {
    const chartConfig = {
        desktop: {
            label: "Desktop",
            color: `${isInProfit ? "#079455" : "#E04343"}`,
        },
        mobile: {
            label: "Mobile",
            color: `${isInProfit ? "#079455" : "#E04343"}`,
        },
    };
    const chartData = [
        { month: "January", desktop: 10, mobile: 80 },
        { month: "February", desktop: 20, mobile: 200 },
        { month: "March", desktop: 37, mobile: 120 },
        { month: "April", desktop: 40, mobile: 190 },
        { month: "May", desktop: 50, mobile: 130 },
        { month: "June", desktop: 64, mobile: 140 },
        { month: "july", desktop: 44, mobile: 140 },
        { month: "August", desktop: 44, mobile: 140 },
        { month: "september", desktop: 44, mobile: 140 },
        { month: "october", desktop: 64, mobile: 140 },
        { month: "november", desktop: 54, mobile: 140 },
        { month: "december", desktop: 74, mobile: 140 },
    ];
    return (
        <Card className="w-[100px] lg:[120px] 2xl:w-[150px] border-none bg-none" isBoxGraph={true}>
            <CardContent className="p-0">
                <ChartContainer config={chartConfig}>
                    <LineChart accessibilityLayer data={chartData} margin={{ left: 12, right: 12 }}>
                        <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                        <Line
                            dataKey="desktop"
                            type="natural"
                            stroke="var(--color-desktop)"
                            strokeWidth={2}
                            dot={{ fill: "var(--color-desktop)" }}
                            activeDot={{ r: 6 }}
                        />
                    </LineChart>
                </ChartContainer>
            </CardContent>
        </Card>
    );
}

// function TrendingUpIcon(props: any) {
//     return (
//         <svg
//             {...props}
//             xmlns="http://www.w3.org/2000/svg"
//             width="24"
//             height="24"
//             viewBox="0 0 24 24"
//             fill="none"
//             stroke="currentColor"
//             strokeWidth="2"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//         >
//             <polyline points="22 7 13.5 15.5 8.5 10.5 2 17" />
//             <polyline points="16 7 22 7 22 13" />
//         </svg>
//     );
// }

// function XIcon(props: any) {
//     return (
//         <svg
//             {...props}
//             xmlns="http://www.w3.org/2000/svg"
//             width="24"
//             height="24"
//             viewBox="0 0 24 24"
//             fill="none"
//             stroke="currentColor"
//             strokeWidth="2"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//         >
//             <path d="M18 6 6 18" />
//             <path d="m6 6 12 12" />
//         </svg>
//     );
// }
