import { cn } from "@/lib/utils";
import { Building, Layers2 } from "lucide-react";
import { TAddPropertyPage } from ".";

type TProps = {
    page: TAddPropertyPage;
};

export const PropertyCreationStep = ({ page }: TProps) => {
    return (
        <section>
            <div className="flex space-x-[10px]">
                <div className="flex flex-col items-center">
                    <span className="w-10 h-10 flex items-center justify-center rounded-lg border border-[#E4E4E7]">
                        <Building className={page === "property" ? "" : "text-[#9CA3AF]"} />
                    </span>
                    <div className="bg-[#E4E4E7] w-[1px] h-[67px] my-2"></div>
                </div>
                <div>
                    <p
                        className={cn(
                            "font-medium text-base leading-6 mb-1",
                            page === "property" ? "" : "text-[#9CA3AF]",
                        )}
                    >
                        Property's Details
                    </p>
                    <p
                        className={cn(
                            "text-xs leading-[15px]",
                            page === "property" ? "" : "text-[#9CA3AF]",
                        )}
                    >
                        Property name, image, location, owner
                    </p>
                </div>
            </div>
            <div className="flex space-x-[10px]">
                <div className="flex flex-col items-center">
                    <span className="w-10 h-10 flex items-center justify-center rounded-lg border border-[#E4E4E7]">
                        <Layers2 className={page !== "property" ? "" : "text-[#9CA3AF]"} />
                    </span>
                </div>
                <div>
                    <p
                        className={cn(
                            "font-medium text-base leading-6 mb-1",
                            page !== "property" ? "" : "text-[#9CA3AF]",
                        )}
                    >
                        Unit’s Details
                    </p>
                    <p
                        className={cn(
                            "text-xs leading-[15px]",
                            page !== "property" ? "" : "text-[#9CA3AF]",
                        )}
                    >
                        Unit number, image, location, owner
                    </p>
                </div>
            </div>
        </section>
    );
};
