import { Message } from "@/app/data";
import { InputWithSearchIcon } from "@/components/common/input/InputWithSearchIcon";
import { ChangeEvent } from "react";
import { IConversation, TUser } from "@/types";
import { UserCard } from "./UserCard";
import { UserCardSmall } from "./UserCardSmall";
import { Skeleton } from "@/components/ui/skeleton";

interface SidebarProps {
    onClickUser: Function;
    isCollapsed: boolean;
    onClick?: () => void;
    isMobile?: boolean;
    conversationList: IConversation[];
    currentUser?: TUser;
    onChangeSearch: (event: ChangeEvent<HTMLInputElement>) => void;
    search: string;
    isSelected: string | undefined;
    links?: {
        name: string;
        messages: Message[];
        avatar: string;
        variant: "grey" | "ghost";
    }[];
    dashboard?: boolean;
    isOnDashBoard?: boolean;
    isConversationLoading?: boolean;
}

export function Sidebar({
    onClickUser,
    isCollapsed,
    conversationList,
    currentUser,
    onChangeSearch,
    search,
    isSelected,
    isOnDashBoard = false,
    dashboard = false,
    isConversationLoading,
}: SidebarProps) {
    return (
        <div
            data-collapsed={isCollapsed}
            // style={{ maxHeight: "calc(100vh - 80px)", overflowY: "hidden" }}
            className="relative group flex flex-col h-full gap-4 p-2 data-[collapsed=true]:p-2 w-full"
        >
            {!isCollapsed && !isOnDashBoard && (
                <>
                    <div className="flex justify-between p-2 items-center">
                        <div className="flex gap-1 items-center justify-center">
                            <p className="font-medium text-xl">Chats</p>
                            <span className="text-zinc-400 text-sm mt-[2px]">
                                ({conversationList && conversationList.length})
                            </span>
                        </div>
                    </div>
                    {isConversationLoading ? (
                        <Skeleton className="h-[3rem] mx-2 bg-gray-200" />
                    ) : (
                        <InputWithSearchIcon
                            onChange={onChangeSearch}
                            value={search}
                            className="px-3"
                        />
                    )}
                </>
            )}

            <nav className="grid gap-2 px-2 overflow-auto group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
                {isConversationLoading
                    ? [...Array(15)].map((_, index) => {
                          return (
                              <Skeleton
                                  key={index}
                                  className={`h-[4rem] w-full ${
                                      isCollapsed
                                          ? "p-0 px-0 cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 text-accent-foreground shadow-sm h-11 w-11 md:h-16 md:w-16"
                                          : "rounded-lg"
                                  } bg-gray-200`}
                              />
                          );
                      })
                    : conversationList &&
                      conversationList.length > 0 &&
                      conversationList.map((link, index) => {
                          console.log(link);

                          const isSelectedCondition =
                              link._id ===
                              (isSelected || localStorage.getItem("lastSelectedConversation"));

                          if (isCollapsed) {
                              return (
                                  <UserCardSmall
                                      key={index}
                                      isSelected={isSelectedCondition}
                                      onClickUser={onClickUser}
                                      conversation={link}
                                      currentUser={currentUser}
                                  />
                              );
                          } else {
                              return (
                                  <UserCard
                                      key={index}
                                      isSelected={isSelectedCondition}
                                      onClickUser={onClickUser}
                                      conversation={link}
                                      currentUser={currentUser}
                                      dashboard={dashboard}
                                  />
                              );
                          }
                      })}
            </nav>
        </div>
    );
}
