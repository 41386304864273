// import { TaskTables } from "@/components/table/TaskTable";
// import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
// import { useCallback, useState } from "react";
// import { Button } from "@/components/ui/button";
// import FormContainer, { FormSkeleton } from "./FormContainer";
// import {
//     useDeleteScriptMutation,
//     useGetScriptByIdQuery,
//     useGetScriptsQuery,
// } from "@/redux/rtk-query/knowledgeApi";
// import { Trash2Icon } from "lucide-react";
// import { toast } from "react-toastify";
// import { Header } from "@/components/common/headers/Header";

// // const useCustomiseResponse = ({ search }: { search: string }) => {
// //     const { data: userDetails } = useGetMyDetailsQuery();
// //     const { data: tenants } = useGetTenantsQuery(userDetails?.companyId!, {
// //         skip: !userDetails?.companyId,
// //     });

// //     const searchFilteredTenants = useMemo(() => {
// //         if (!tenants) {
// //             return [];
// //         }
// //         if (!search) {
// //             return tenants;
// //         }

// //         const filteredTenants = tenants.filter(
// //             (tenant: any) =>
// //                 tenant.name.fullName.toLowerCase().includes(search.toLowerCase()) ||
// //                 tenant.email.toLowerCase().includes(search.toLowerCase()),
// //         );

// //         return filteredTenants;
// //     }, [search, tenants]);

// //     const tableRowItems = useMemo(() => {
// //         if (!searchFilteredTenants) return [];

// //         return [...searchFilteredTenants].map((tenant: any, index: number) => {
// //             return {
// //                 id: index + 1,
// //                 TenantName: tenant.name.fullName,
// //                 email: tenant.email,
// //                 phoneNumber: tenant.phone || "-",
// //             };
// //         });
// //     }, [searchFilteredTenants]);

// //     return tableRowItems;
// // };

// export const PropertyManagementKnowledgeBase = () => {
//     const [addScriptClicked, setAddScriptClicked] = useState(false);
//     const [editResponseClicked, setEditResponseClicked] = useState(false);
//     const { data: userDetails } = useGetMyDetailsQuery();
//     const { data: scripts, refetch: refetchScripts } = useGetScriptsQuery(
//         userDetails?.companyId || "",
//     );
//     const [scriptId, setScriptId] = useState("");
//     const { data: scriptData, isFetching } = useGetScriptByIdQuery(scriptId);
//     const [deleteScript] = useDeleteScriptMutation();
//     const { refetch } = useGetScriptByIdQuery(scriptId);
//     const editResponseHandler = (scriptId: string) => {
//         setScriptId(scriptId);
//         refetch();
//         setEditResponseClicked(true);
//     };

//     const onDeleteScript = useCallback(
//         async (scriptId: string) => {
//             // Implement your delete logic here
//             await deleteScript(scriptId);
//             toast.success("Script deleted successfully");
//             refetchScripts();
//         },
//         [deleteScript, refetchScripts],
//     );
//     // const CustomQuestionsData = {
//     //     data: [
//     //         {
//     //             header: CustomQuestionsHeaderItems,
//     //             row: useCustomiseResponse({ search }),
//     //             heading: "Customise Response",
//     //         },
//     //     ],
//     // };

//     const CustomResponsesData = {
//         data: [
//             {
//                 header: CustomResponsesHeaderItems,
//                 row: scripts || [],
//                 heading: "All Scripts",
//             },
//         ],
//     };

//     // const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
//     //     setSearch(event.target.value);
//     // };

//     // const onClickRowItem = useCallback(
//     //     (item: any) => {
//     //         console.log(item);

//     //         if (!userDetails || !item?.original?.id) return;

//     //         const basePath = getUserBasePath(userDetails.userType);

//     //         navigate(`${basePath}/user/${item?.original._id}`);
//     //     },
//     //     [navigate, userDetails],
//     // );

//     // const onClickInviteTenant = useCallback(() => {
//     //     navigate("/property-management/tenants/invite-tenant");
//     // }, [navigate]);

//     // const onClickCreateContract = useCallback(() => {
//     //     navigate("/property-management/contracts/create-contract");
//     // }, [navigate]);

//     return (
//         <div className="w-full max-h-screen overflow-y-auto">
//             <Header
//                 image={userDetails?.imageUrl}
//                 heading={undefined}
//                 firstName={userDetails?.firstName}
//                 lastName={userDetails?.lastName}
//                 hasSearchBar={true}
//             />
//             <div className="p-5">
//                 <div className="flex items-center gap-5 border-b-1 ">
//                     <header className="flex justify-between items-center mb-6">
//                         <h1 className="font-bold text-3xl leading-9 text-[#09090B]">Knowledge</h1>
//                     </header>
//                 </div>
//                 {/* <div className="bg-white py-6 px-5 border-b flex gap-4 items-center justify-start">
//                     <div className="p-3 rounded-lg bg-gray-100 border ">
//                         <HomeIcon />
//                     </div>
//                     <h1 className="text-lg font-semibold">Knowledge</h1>
//                 </div> */}
//                 <div className="mb-4">
//                     {/* <div className="border px-5 pt-4 pb-6 rounded-2xl my-4 bg-white">
//                     <div className="flex items-center justify-between">
//                         <div>
//                             <h2 className="text-lg font-extrabold block">Customise Questions</h2>

//                             <p className="text-sm leading-4 -mt-2 text-[#727272] font-normal">
//                                 Customise the questions that the AI asks tenants.
//                             </p>
//                         </div>
//                     </div>
//                     <div className="mt-6">
//                         <TaskTables
//                             data={CustomQuestionsData?.data || []}
//                             setEditResponseClicked={setEditResponseClicked}
//                             // onClickRow={onClickRowItem}
//                         />
//                     </div>
//                 </div> */}

//                     <div className="border rounded-2xl">
//                         <div className=" px-5 pt-4 pb-6 rounded-2xl bg-white">
//                             <div className="flex items-center justify-between">
//                                 <div>
//                                     <h2 className="text-lg font-extrabold block">
//                                         Customise Scripts
//                                     </h2>

//                                     <p className="text-sm leading-4 -mt-2 text-[#727272] font-normal">
//                                         Customise the questions that the AI asks tenants.
//                                     </p>
//                                 </div>
//                                 <Button onClick={() => setAddScriptClicked(true)}>
//                                     Add Script
//                                 </Button>
//                             </div>
//                             <div className="mt-6 ">
//                                 <TaskTables
//                                     data={CustomResponsesData?.data || []}
//                                     setEditResponseClicked={setEditResponseClicked}
//                                     editResponseHandler={editResponseHandler}
//                                     onDeleteScript={onDeleteScript}
//                                     className=""
//                                     card={false}
//                                     // onClickRow={onClickRowItem}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 {/* Script Form */}
//                 {addScriptClicked && (
//                     <div
//                         className="bg-black flex items-center justify-center absolute h-screen left-0 top-0 z-50 w-full"
//                         style={{ backgroundColor: "rgba(0, 0, 0, 0.75)" }}
//                     >
//                         <FormContainer
//                             type="Add"
//                             setEditResponseClicked={setEditResponseClicked}
//                             setAddScriptClicked={setAddScriptClicked}
//                             addScriptClicked={addScriptClicked}
//                             editResponseClicked={editResponseClicked}
//                             isFetching={isFetching}
//                         />
//                     </div>
//                 )}
//                 {editResponseClicked && (
//                     <div
//                         className="bg-black flex items-center justify-center absolute h-screen left-0 top-0 z-50 w-full"
//                         style={{ backgroundColor: "rgba(0, 0, 0, 0.75)" }}
//                     >
//                         {isFetching ? (
//                             <FormSkeleton
//                                 isFetching={isFetching}
//                                 setEditResponseClicked={setEditResponseClicked}
//                             />
//                         ) : (
//                             <FormContainer
//                                 type="Edit"
//                                 setEditResponseClicked={setEditResponseClicked}
//                                 setAddScriptClicked={setAddScriptClicked}
//                                 addScriptClicked={addScriptClicked}
//                                 editResponseClicked={editResponseClicked}
//                                 editData={scriptData}
//                             />
//                         )}
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// // *********** Column definition of questions **************

// // export const CustomQuestionsHeaderItems = [
// //     {
// //         accessorKey: "title",
// //         header: "Title",
// //         cell: ({ row }: any) => {
// //             return <div className="">{`#${row.getValue("title")}`}</div>;
// //         },
// //         size: 120,
// //     },
// //     {
// //         accessorKey: "question",
// //         header: "Question",
// //         cell: ({ row }: any) => <div className="capitalize">{row.getValue("question")}</div>,
// //         size: 500,
// //     },
// //     {
// //         accessorKey: "response",
// //         header: "Response",
// //         cell: ({ row }: any) => <button className="font-bold text-md py-2">Edit Response</button>,
// //     },
// // ];

// // *********** Column definition of responses **************

// export const CustomResponsesHeaderItems = [
//     // {
//     //     accessorKey: "title",
//     //     header: "Title",
//     //     cell: ({ row }: any) => {
//     //         return <div className="">{`#${row.getValue("title")}`}</div>;
//     //     },
//     //     size: 120,
//     // },
//     {
//         accessorKey: "questions",
//         header: "Question",
//         cell: ({ row }: any) => <div className="capitalize">{row.getValue("questions")}</div>,
//         size: 200,
//     },
//     {
//         accessorKey: "answer",
//         header: "Answer",
//         cell: ({ row }: any) => <div className="capitalize">{row.getValue("answer")}</div>,
//         size: 200,
//     },
//     {
//         accessorKey: "response",
//         header: "Response",
//         cell: () => <button className="font-bold text-md py-2">Edit Response</button>,
//     },
//     {
//         accessorKey: "delete",
//         header: "",
//         cell: () => (
//             <button className="text-red-500 hover:text-red-700">
//                 <Trash2Icon size={18} />
//             </button>
//         ),
//         size: 50,
//     },
// ];

// import { Header } from "@/components/common/headers/Header";
// import { Button } from "@/components/ui/button";
// import { Input } from "@/components/ui/input";
// import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
// import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
// import { Building, MoveUp, PhoneCall, Sparkles } from "lucide-react";
// import { Breadcrumbs } from "./BreadCrumbs";

// import { useState } from "react";
// import { PopOver } from "./PopOver";
// import { ResponseTab } from "./tabs/ResponseTab";
// import DocumentsTab from "./tabs/DocumentsTab";

// const frameworks = [
//     {
//         value: "next.js",
//         label: "Next.js",
//     },
//     {
//         value: "sveltekit",
//         label: "SvelteKit",
//     },
//     {
//         value: "nuxt.js",
//         label: "Nuxt.js",
//     },
//     {
//         value: "remix",
//         label: "Remix",
//     },
//     {
//         value: "astro",
//         label: "Astro",
//     },
// ];

// export const PropertyManagementKnowledgeBase = () => {
//     const { data: user, isLoading: isUserLoading } = useGetMyDetailsQuery();
//     const [open, setOpen] = useState(false);
//     const [activeTab, setActiveTab] = useState("responses");

//     return (
//         <div className="flex flex-col h-screen overflow-hidden bg-white">
//             <Header image={user?.imageUrl} heading="Customise AI" username="" hasSearchBar={true} />

//             <div className="flex px-4 items-center h-[60px] w-full border-b border-gray-200">
//                 <Breadcrumbs name="Customise AI" />
//             </div>

//             <div
//                 className={`flex flex-1 overflow-hidden ${activeTab === "documents" ? "w-full" : ""}`}
//             >
//                 <div className="flex-1 overflow-y-auto">
//                     <Tabs defaultValue="responses" className="flex-1" onValueChange={setActiveTab}>
//                         <div className="border-b border-gray-200 p-4">
//                             <PopOver open={open} setOpen={setOpen} DropDownArray={frameworks} />
//                         </div>
//                         <TabsList className="m-3">
//                             <TabsTrigger value="responses">Custom Responses</TabsTrigger>
//                             <TabsTrigger value="documents">Documents</TabsTrigger>
//                         </TabsList>
//                         <ResponseTab companyId={user?.companyId} />
//                         <DocumentsTab />
//                     </Tabs>
//                 </div>

//                 {activeTab === "responses" && (
//                     <div className="w-[40%] flex flex-col items-center justify-center bg-[#ECF9FF]">
//                         <div className="w-full p-5 flex justify-between items-center border-b border-gray-200 bg-white border-l">
//                             <div className="flex items-center space-x-2">
//                                 <div className="w-9 h-9 bg-[#22bcff] text-white rounded-sm flex items-center justify-center">
//                                     <PhoneCall className="h-5 w-5" />
//                                 </div>
//                                 <span className="font-semibold">Test Felicity</span>
//                             </div>
//                             <Button
//                                 variant="outline"
//                                 className="flex items-center space-x-1 text-gray-500 p-2"
//                             >
//                                 <Building className="ml-2 h-4 w-4 shrink-0" />
//                                 <span>All Properties</span>
//                             </Button>
//                         </div>

//                         <div className="flex flex-col items-center flex-grow justify-center">
//                             <div className="w-10 h-10 bg-black text-white rounded-sm flex items-center justify-center mb-4">
//                                 <Sparkles />
//                             </div>
//                             <p className="font-medium">Hi I’m Felicity</p>
//                             <p className="text-sm text-gray-500">Your AI Assistant</p>
//                         </div>

//                         <div className="w-full p-4 flex items-center border-gray-200 gap-2">
//                             <Input
//                                 className="flex-grow px-4 py-2 border border-gray-300 rounded-l-md"
//                                 placeholder="Type Here"
//                             />
//                             <Button className="px-4 py-2 text-white rounded-r-md h-fit">
//                                 <MoveUp />
//                             </Button>
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

import { Header } from "@/components/common/headers/Header";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { Building, MoveUp, PhoneCall, Sparkles } from "lucide-react";
import { Breadcrumbs } from "./BreadCrumbs";

import { useState } from "react";
import { PopOver } from "./PopOver";
import { ResponseTab } from "./tabs/ResponseTab";
import { DocumentsTab } from "./tabs/DocumentsTab";

const frameworks = [
    { value: "next.js", label: "Next.js" },
    { value: "sveltekit", label: "SvelteKit" },
    { value: "nuxt.js", label: "Nuxt.js" },
    { value: "remix", label: "Remix" },
    { value: "astro", label: "Astro" },
];

export const PropertyManagementKnowledgeBase = () => {
    const { data: user, isLoading } = useGetMyDetailsQuery();
    const [open, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("responses");

    return (
        <div className="flex flex-col h-screen overflow-hidden bg-white w-full">
            <Header image={user?.imageUrl} heading="Customise AI" username="" hasSearchBar={true} />

            <div className="flex px-4 items-center h-[60px] w-full border-b border-gray-200">
                <Breadcrumbs name="Customise AI" />
            </div>

            <div className="flex flex-1 overflow-hidden">
                <div className="flex-1 overflow-y-auto">
                    <Tabs defaultValue="responses" className="flex-1" onValueChange={setActiveTab}>
                        <div className="border-b border-gray-200 p-4">
                            <PopOver
                                open={open}
                                setOpen={setOpen}
                                DropDownArray={frameworks}
                                type="responsePage"
                                placeholder="Select Property..."
                                message="No Property Found!"
                                icon={Building}
                            />
                        </div>
                        <TabsList className="m-3">
                            <TabsTrigger value="responses">Custom Responses</TabsTrigger>
                            <TabsTrigger value="documents">Documents</TabsTrigger>
                        </TabsList>
                        <TabsContent value="responses">
                            <ResponseTab companyId={user?.companyId} isLoadingUser={isLoading} />
                        </TabsContent>
                        <TabsContent value="documents">
                            <DocumentsTab companyId={user?.companyId} />
                        </TabsContent>
                    </Tabs>
                </div>

                <div
                    className={`transition-all duration-500 ease-in-out ${
                        activeTab === "documents" ? "w-0 opacity-0" : "w-[40%] opacity-100"
                    } flex flex-col items-center justify-center bg-[#ECF9FF]`}
                >
                    <div className="w-full p-5 flex justify-between items-center border-b border-gray-200 bg-white border-l">
                        <div className="flex items-center space-x-2">
                            <div className="w-9 h-9 bg-[#22bcff] text-white rounded-sm flex items-center justify-center">
                                <PhoneCall className="h-5 w-5" />
                            </div>
                            <span className="font-semibold">Test Felicity</span>
                        </div>
                        <Button
                            variant="outline"
                            className="flex items-center space-x-1 text-gray-500 p-2"
                        >
                            <Building className="ml-2 h-4 w-4 shrink-0" />
                            <span>All Properties</span>
                        </Button>
                    </div>

                    <div className="flex flex-col items-center flex-grow justify-center">
                        <div className="w-10 h-10 bg-black text-white rounded-sm flex items-center justify-center mb-4">
                            <Sparkles />
                        </div>
                        <p className="font-medium">Hi I’m Felicity</p>
                        <p className="text-sm text-gray-500">Your AI Assistant</p>
                    </div>

                    <div className="w-full p-4 flex items-center border-gray-200 gap-2">
                        <Input
                            className="flex-grow px-4 py-2 border border-gray-300 rounded-l-md"
                            placeholder="Type Here"
                        />
                        <Button className="px-4 py-2 text-white rounded-r-md h-fit">
                            <MoveUp />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
