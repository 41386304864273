import { toast } from "react-toastify";
import { AuthDetails } from "./interface/auth.interface";

type ToastType = "success" | "error";

const displayAlert = (message: string | string[], type: ToastType = "success") => {
    const showToast = (text: string) => {
        if (type === "success") {
            toast.success(text);
        } else if (type === "error") {
            toast.error(text);
        }
    };

    if (Array.isArray(message)) {
        message.forEach(showToast);
    } else {
        showToast(message);
    }
};

const setLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, value);
};

const getLocalStorage = (key: string) => {
    return localStorage.getItem(key);
};

async function setUserDetailsToStorage(key: string, userDetails: AuthDetails): Promise<void> {
    try {
        const serializedData = JSON.stringify(userDetails);
        localStorage.setItem(key, serializedData);
    } catch (error) {
        console.error("Error saving to localStorage:", error);
    }
}

function getUserDetailsFromStorage(key: string, fallback: AuthDetails): AuthDetails {
    const item = localStorage.getItem(key);
    if (!item) return fallback;

    try {
        const parsedItem: AuthDetails = JSON.parse(item);
        return parsedItem;
    } catch (error) {
        console.error("Parsing error in getLocalStorage:", error);
        return fallback;
    }
}

const clearLocalStorage = () => {
    localStorage.clear();
};

function AuthRedirects(location: any, isAuthenticated: boolean) {
    const queryParams = new URLSearchParams(location.search);
    const redirectURL = queryParams.get("redirectURL");
    const token = localStorage.getItem("token");
    if (isAuthenticated && redirectURL) {
        const formattedURL = redirectURL.includes("://")
            ? `${redirectURL}?token=${token}`
            : `${redirectURL}`;
        window.location.href = formattedURL;
        clearLocalStorage();
    }
}

function summarizeText(sentence: string) {
    const words = sentence ? sentence.split(/\s+/) : [];
    const count = 20;

    if (words.length <= count) {
        return sentence;
    } else {
        const result = words.slice(0, count).join(" ") + "…";
        return result;
    }
}

function titleText(sentence: string) {
    const words = sentence ? sentence.split(/\s+/) : [];
    const count = 4;

    if (words.length <= count) {
        return sentence;
    } else {
        const result = words.slice(0, count).join(" ") + "…";
        return result;
    }
}

const formatDateToMonthYear = (dateString: string | undefined): string => {
    if (!dateString) {
        return "Unknown Date";
    }

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return "Invalid Date";
    }

    const options: Intl.DateTimeFormatOptions = {
        month: "long",
        year: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
};

const getTokenFromAccountStorage = (): any => {
    const account = localStorage.getItem("account");
    if (account) {
        const parsedAccount = JSON.parse(account);
        return parsedAccount.token;
    } else {
        return null;
    }
};

export {
    displayAlert,
    setLocalStorage,
    getLocalStorage,
    clearLocalStorage,
    setUserDetailsToStorage,
    getUserDetailsFromStorage,
    AuthRedirects,
    summarizeText,
    formatDateToMonthYear,
    getTokenFromAccountStorage,
    titleText,
};
