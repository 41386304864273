import { useState } from "react";
import { Outlet } from "react-router-dom";
import MobileNavigation from "../dashboard/contractor/navbar/mobileNavigation";
import Navbar from "../dashboard/homeOwner/navbar";
import styles from "./layoout.module.css";
const ChatHomeOwnerLayout = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    return (
        <>
            <MobileNavigation isOpen={isOpen} setIsOpen={setIsOpen} />
            <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
            <div className={styles.chatLayout}>
                <Outlet />
            </div>
        </>
    );
};

export { ChatHomeOwnerLayout };
