import { useState } from "react";
import { BackButton } from "../../../../../components/common/backButton";
import Button from "../../../../../components/common/button";
import { Heading } from "../../../../../components/common/pageHeading";
import styles from "./../SetPricing/SetPricing.module.css";
import { InputWithLabel } from "../../../../postAJob/InputWithLabel";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
    useGetMyDetailsQuery,
    useUpdateUserProfileMutation,
} from "../../../../../redux/rtk-query/authApis";

export function ManageProfile() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const { data: details } = useGetMyDetailsQuery();
    const [formData, setFormData] = useState({
        callOutFee: details?.userProfile.callOutFee
            ? details?.userProfile.callOutFee.match(/\d+/)[0]
            : "",
    });
    const [updateUserProfile] = useUpdateUserProfileMutation();
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!formData.callOutFee) {
            toast.error("Please provide call out fees");
        }
        try {
            setLoading(true);
            await updateUserProfile({
                callOutFee: `${formData.callOutFee}usd`,
            });
            setLoading(false);
            toast.success("Call out fee added successfully!");
            navigate("/contractor/ai-assistant");
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <>
            <div className={styles.TabBackBtn}>
                <BackButton />
            </div>
            <form onSubmit={handleSubmit}>
                <div className={styles.header}>
                    <Heading>Manage Profile</Heading>
                    <Button type="submit" text="Save Changes" loading={loading} />
                </div>
                <div className={styles.form}>
                    <InputWithLabel
                        labelProps={{ text: "Call Out Fee" }}
                        inputProps={{
                            type: "number",
                            name: "callOutFee",
                            placeholder: "Call Out Fee",
                            required: true,
                            value: formData.callOutFee,
                            onChange: handleChange,
                        }}
                    />
                </div>
            </form>
        </>
    );
}
