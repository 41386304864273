import { toast } from "react-toastify";
import { catchAll } from "../../utils/catch";
import { api } from "./api";

interface AiAssistantStats {
    totalAIBookedJobs: number;
    totalJobsScheduled: number;
    average_call_length: number;
    daily_calls: number;
}

const getAiAssistantApis = api.injectEndpoints({
    endpoints: (builder) => ({
        getAiAssistance: builder.query<AiAssistantStats, void>({
            query: () => ({
                url: `jobs/get-ai-jobs-stats`,
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    toast.error("Something went wrong! Please try again later.");
                }
            },
        }),
    }),
});

export const { useGetAiAssistanceQuery } = getAiAssistantApis;
