import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NothingToSeePlaceholder } from "../../../components/common/nothingToSeePlaceholder";
import { Heading } from "../../../components/common/pageHeading";
import { TabButtons } from "../../../components/common/tabButtons";
import "../../../cssReset.css";
import { useGetMyDetailsQuery } from "../../../redux/rtk-query/authApis";
import { useGetJobsQuery, useLazyGetJobsQuery } from "../../../redux/rtk-query/jobsApis";
import { TJobTabFilter } from "../../../types";
import { HomeOwnerTabs } from "../../../utils/constantData";
import { getFormattedDate } from "../../../utils/formatDate";
import { UserType } from "../../../utils/interface/auth.interface";
import { JobCard } from "../../myJobs/JobCard";
import { HomeOwnerCardSection } from "./HomeOwnerCardSection";
import styles from "./homeOwnerDashboard.module.css";
import { JobsAmountOverview } from "./JobsAmountOverview";
import { SpendingHeader } from "./SpendingHeader";
import { SpendingStats } from "./SpendingStats";
import { ViewDetails } from "./ViewDetails";

type TUser = UserType.HOMEOWNER | UserType.TENANT;

type TUserBasedLogic = {
    quickActionTexts: {
        heading: string;
        subtext: string;
        buttonLabel: string;
        onClick: () => void;
    }[];
    onClickViewDetails: string;
    hasCharts: boolean;
};

function HomeOwnerDashboard() {
    const navigate = useNavigate();

    const [selectedTab, setSelectedTab] = useState<TJobTabFilter>("ALL");
    const [showSpending, setShowSpending] = useState(true);

    const { data: myDetails } = useGetMyDetailsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const { data: allJobs } = useGetJobsQuery(
        { type: "ALL", myDetails },
        {
            refetchOnMountOrArgChange: true,
            skip: !myDetails,
        },
    );

    const [getOtherJobs, { data: otherJobs }] = useLazyGetJobsQuery();

    const UserBasedLogic = useMemo(() => {
        const UserBasedLogic: Record<TUser, TUserBasedLogic> = {
            HOMEOWNER: {
                quickActionTexts: [
                    {
                        heading: "Find a contractor",
                        subtext: "Find Reliable Contractors Instantly on Lightwork",
                        buttonLabel: "Search Contractors",
                        onClick: () => navigate("/home-owner/find-contractors"),
                    },
                    {
                        heading: "Post a job",
                        subtext: "Find the best contractors quickly with Lightwork.",
                        buttonLabel: "Book a job",
                        onClick: () => navigate("/home-owner/post-job"),
                    },
                ],
                onClickViewDetails: "/home-owner/jobs/",
                hasCharts: true,
            },
            TENANT: {
                quickActionTexts: [
                    {
                        heading: "Post a maintenance job",
                        subtext: "Find Reliable Jobs Instantly on Lightwork",
                        buttonLabel: "Book a job",
                        onClick: () => navigate("/tenant/post-job"),
                    },
                ],
                onClickViewDetails: "/tenant/jobs/",
                hasCharts: false,
            },
        };

        if (!myDetails?.userType) {
            return UserBasedLogic["HOMEOWNER"];
        }
        if (myDetails?.userType !== UserType.HOMEOWNER && myDetails?.userType !== UserType.TENANT) {
            return UserBasedLogic["HOMEOWNER"];
        }

        const userType = myDetails?.userType! as TUser;
        return UserBasedLogic[userType];
    }, [myDetails?.userType, navigate]);

    const onClickTab = useCallback(
        (id: TJobTabFilter) => {
            getOtherJobs({ type: id, myDetails });
            setSelectedTab(id);
        },
        [getOtherJobs, myDetails],
    );

    const onClickToggleSpendingButton = useCallback(() => {
        setShowSpending((prev) => !prev);
    }, []);

    const jobs = useMemo(() => {
        return selectedTab === "ALL" ? allJobs : otherJobs;
    }, [selectedTab, allJobs, otherJobs]);

    const onClickViewDetails = useCallback(
        (jobId: string) => {
            navigate(UserBasedLogic?.onClickViewDetails + jobId);
        },
        [UserBasedLogic?.onClickViewDetails, navigate],
    );

    const filteredHomeOwnerTabs = useMemo(() => {
        if (myDetails?.userType)
            return HomeOwnerTabs.filter((tab) => {
                const r = tab.userType.includes(myDetails?.userType);
                return r;
            });
        else {
            return HomeOwnerTabs;
        }
    }, [myDetails?.userType]);

    return (
        <div>
            <div className={styles.homeOwnerWelcomeMessageContainer}>
                <h3 className={styles.homeOwnerDate}>{getFormattedDate(new Date()).toString()}</h3>
                <Heading>Welcome {myDetails?.username}</Heading>
            </div>
            {myDetails ? (
                <HomeOwnerCardSection quickActions={UserBasedLogic?.quickActionTexts} />
            ) : (
                <div style={{ height: 239 }}></div>
            )}
            <section className={styles.myJobsSection}>
                <Heading>My Jobs</Heading>
                <TabButtons
                    selectedTab={selectedTab}
                    onClickTab={onClickTab}
                    tabs={filteredHomeOwnerTabs}
                />
                {jobs && jobs.length > 0 ? (
                    jobs.map((job: any) => (
                        <JobCard
                            key={job._id}
                            id={job._id}
                            image={job.jobDetail.photoUrl[0]}
                            price={job.jobDetail.rate}
                            status={job.status}
                            title={job.jobDetail.title}
                            onClickViewDetails={onClickViewDetails}
                            assignedTo={job.assignedToInfo?.username}
                            createdAt={job.createdAt}
                            selectedTab={selectedTab}
                            qoute={
                                selectedTab === "COMPLETED" || selectedTab === "ACTIVE"
                                    ? job.jobBid
                                    : job.matchedData
                            }
                        />
                    ))
                ) : (
                    <NothingToSeePlaceholder label="No job’s yet" />
                )}
                <div className={styles.myJobsFooter}>
                    <JobsAmountOverview amount={0} label="" />
                    <ViewDetails handleClick={() => navigate("/home-owner/jobs")}>
                        View All
                    </ViewDetails>
                </div>
            </section>
            {UserBasedLogic?.hasCharts && (
                <section className={styles.myJobsChartSection}>
                    <SpendingHeader
                        showSpending={showSpending}
                        onClick={onClickToggleSpendingButton}
                    />
                    <SpendingStats showSpending={showSpending} />
                    <div className={styles.earningsChartArea}>
                        {/* <AreaChart /> */}
                        <span className={styles.mySpendingStatHeading}>Spendings overtime</span>
                        <NothingToSeePlaceholder label="No data found" />
                    </div>
                    {/* <div className={styles.myJobsFooter}>
                        <JobsAmountOverview amount={0} label="active jobs" />
                        <ViewDetails>View Transaction History</ViewDetails>
                    </div> */}
                </section>
            )}
        </div>
    );
}

export default HomeOwnerDashboard;
