import * as React from "react";
import { Label, Legend, Pie, PieChart } from "recharts";

import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import { formatNumber, formatWithCommas } from "@/utils/formatNumber";

interface ChartDataItem {
    propertyStatLabel: string;
    stat: number;
    fill: string;
}

interface PieChartsProps {
    chartConfig: ChartConfig;
    label?: string;
    chartData: ChartDataItem[];
    type?: string;
    totalTasks: number | undefined;
}

const CustomLegend = ({ payload, type }: any) => {
    return (
        <ul
            className={`flex justify-center mt-[20px] ${type === "dashboard" ? "gap-2" : "flex-wrap space-x-2"}`}
        >
            {payload.map((entry: any, index: number) => (
                <li key={`item-${index}`} className="flex items-center space-x-2 mt-1">
                    <span
                        className="inline-block w-3 h-3 rounded-full"
                        style={{ backgroundColor: entry.color }}
                    />
                    <span className="text-black flex items-center space-x-1 text-[12px]">
                        <span>{entry.value}</span>
                    </span>
                </li>
            ))}
        </ul>
    );
};

export const PieCharts: React.FC<PieChartsProps> = ({
    chartConfig,
    chartData,
    type,
    totalTasks,
}) => {
    return (
        <ChartContainer config={chartConfig} className="mx-auto aspect-square max-h-[250px]">
            <PieChart>
                <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                <Pie
                    data={chartData}
                    dataKey="stat"
                    nameKey="propertyStatLabel"
                    innerRadius={70}
                    outerRadius={100}
                    height={400}
                    width={600}
                    // cornerRadius={10}
                    // paddingAngle={2}
                    strokeWidth={5}
                >
                    <Label
                        content={({ viewBox }) => {
                            if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                                return (
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <text
                                                    x={viewBox.cx}
                                                    y={viewBox.cy}
                                                    textAnchor="middle"
                                                    dominantBaseline="middle"
                                                >
                                                    <tspan
                                                        x={viewBox.cx}
                                                        y={viewBox.cy}
                                                        className="fill-foreground text-xl font-bold"
                                                    >
                                                        {formatNumber(totalTasks || 0)}
                                                    </tspan>
                                                </text>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <tspan>{formatWithCommas(totalTasks || 0)}</tspan>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                );
                            }
                            return null; // Add this line to return a value for all code paths
                        }}
                    />
                </Pie>

                <Legend
                    width={350}
                    content={CustomLegend}
                    verticalAlign="bottom"
                    height={36}
                    type={type}
                    wrapperStyle={{ left: -50 }}
                />
            </PieChart>
        </ChartContainer>
    );
};

// {/* {label && (
//     <tspan
//         x={viewBox.cx}
//         y={(viewBox.cy || 0) + 24}
//         className="fill-muted-foreground"
//     >
//         {label}
//     </tspan>
// )} */}
