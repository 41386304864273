import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetMyDetailsQuery } from "../../redux/rtk-query/authApis";
import {
    useCreateAccountMutation,
    useLazyGetAccountQuery,
} from "../../redux/rtk-query/paymentAccount";
import { Payout } from "./payout";
import "../../cssReset.css";
import TeamSettings from "./teams";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { ProfileSettings } from "./account/personal/ProfileSettings";
import { Organization } from "./account/organization/Organization";
import { Header } from "@/components/common/headers/Header";

export const SettingsTabs = {
    propertyManagement: [
        {
            label: "Personal",
            value: "Personal",
        },
        {
            label: "Organisation",
            value: "Organisation",
        },
        // {
        //     label: "Payouts",
        //     value: "Payment",
        // },
    ],
    contractor: [
        {
            label: "Personal",
            value: "Personal",
        },
        // {
        //     label: "Payouts",
        //     value: "Payment",
        // },
    ],

    homeOwner: [
        {
            label: "Account",
            value: "Account",
        },
        {
            label: "Branding",
            value: "branding",
        },
        {
            label: "Work Preferences",
            value: "work-preferences",
        },
        {
            label: "Teams",
            value: "Teams",
        },
        // {
        //     label: "Payouts",
        //     value: "Payment",
        // },
    ],
};

export const Settings = ({ type }: { type: keyof typeof SettingsTabs }) => {
    const [selectedTab, setSelectedTab] = useState<string>(SettingsTabs[type][0].value);

    const [createAccount, result] = useCreateAccountMutation();
    const { data: user } = useGetMyDetailsQuery();
    const [getAccount, { data: accountData }] = useLazyGetAccountQuery();

    useEffect(() => {
        if (user && user.stripeConnectId) {
            getAccount(user.stripeConnectId);
        }
    }, [getAccount, user]);

    useEffect(() => {
        if (result && !result.isLoading && result.data?.onboardingLink) {
            window.location.href = result.data.onboardingLink;
        }
    }, [result]);

    const accountClickHandler = () => {
        if (user?.stripeConnectId) {
            toast.error("Already have an account.");
        } else {
            createAccount({ email: user?.email, account_type: "express" });
        }
    };

    useCallback((tabValue: string) => {
        setSelectedTab(tabValue);
    }, []);

    return (
        <>
            <div className="bg-white w-full">
                <Header
                    image={user?.imageUrl}
                    heading={selectedTab}
                    firstName={user?.firstName}
                    lastName={user?.lastName}
                    hasSearchBar={true}
                />
                <div className="py-4 px-8">
                    {/* <Heading className={styles.settingHeading} as="h1" bottom={20}>
                        {selectedTab}
                    </Heading> */}

                    <Tabs defaultValue={SettingsTabs[type][0].value} onValueChange={setSelectedTab}>
                        <TabsList>
                            {SettingsTabs[type].map((tab) => (
                                <TabsTrigger key={tab.value} value={tab.value}>
                                    {tab.label}
                                </TabsTrigger>
                            ))}
                        </TabsList>

                        {SettingsTabs[type].map((tab) => (
                            <TabsContent key={tab.value} value={tab.value}>
                                {(() => {
                                    switch (tab.value) {
                                        case "Personal":
                                            return <ProfileSettings user={user} />;
                                        case "Organisation":
                                            return <Organization user={user} />;
                                        case "Payouts":
                                            return (
                                                <Payout
                                                    accountClickHandler={accountClickHandler}
                                                    data={accountData}
                                                    isLoading={result.isLoading}
                                                />
                                            );
                                        case "Teams":
                                            return <TeamSettings className="w-full" />;
                                        default:
                                            return <div>No such tab exists</div>;
                                    }
                                })()}
                            </TabsContent>
                        ))}
                    </Tabs>
                </div>
            </div>
        </>
    );
};
