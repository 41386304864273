import { Rating } from "../smallJobCard/Rating";
import styles from "./horizontalStat.module.css";
import { HorizontalStat } from "./HorizontalStat";
import { memo } from "react";

type TProps = {
    size?: "sm" | "lg";
    onClick?: () => void;
};

export const HorizontalStats = memo(({ size = "sm", onClick }: TProps) => (
    <div onClick={onClick} className={styles.stats}>
        <HorizontalStat heading="Rating" size={size}>
            <Rating size={size} />
        </HorizontalStat>
        <HorizontalStat heading="Jobs" size={size}>
            56
        </HorizontalStat>
        <HorizontalStat heading="Completed" size={size}>
            54
        </HorizontalStat>
    </div>
));
