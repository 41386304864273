import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetMyDetailsQuery } from "../../redux/rtk-query/authApis";
import { Button } from "../ui/button";
import { PropertyManagementContracts } from "@/pages/propertyManagementContracts";
import { PropertyManagementTenants } from "@/pages/propertyManagementTenants";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { Header } from "../common/headers/Header";

const TenantTabs = [
    {
        label: "Tenants",
        value: "Tenants",
    },
    {
        label: "Contracts",
        value: "Contracts",
    },
];

export const TenantAndContractor = ({ value }: any) => {
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState(value);
    const { data: userDetails } = useGetMyDetailsQuery();

    const onClickTab = useCallback(
        (newTab: string) => {
            setSelectedTab(newTab);
            if (newTab === "Tenants") {
                navigate("/property-management/tenants");
            } else if (newTab === "Contracts") {
                navigate("/property-management/contracts");
            }
        },
        [navigate],
    );

    const onClickInviteTenant = useCallback(() => {
        navigate("/property-management/tenants/invite-tenant");
    }, [navigate]);

    const onClickCreateContract = useCallback(() => {
        navigate("/property-management/contracts/create-contract");
    }, [navigate]);

    return (
        <div className="max-h-screen overflow-y-auto w-full">
            <Header
                image={userDetails?.imageUrl}
                heading={undefined}
                firstName={userDetails?.firstName}
                lastName={userDetails?.lastName}
                hasSearchBar={true}
            />
            <div className="p-5">
                <header className="mb-7">
                    <div className="flex justify-between items-center mb-5">
                        <h1 className="font-bold text-3xl leading-9 text-[#09090B]">
                            {selectedTab}
                        </h1>
                        <div className="flex space-x-2">
                            {selectedTab === "Tenants" && (
                                <Button onClick={onClickInviteTenant}>Add Tenant</Button>
                            )}
                            {selectedTab === "Contracts" && (
                                <Button onClick={onClickCreateContract}>Create Contract</Button>
                            )}
                        </div>
                    </div>
                </header>

                <Tabs defaultValue={value} onValueChange={onClickTab}>
                    {/* <TabsList className="bg-gray-200/50 gap-1">
                        {TenantTabs.map((tab) => (
                            <TabsTrigger
                                className="bg-transparent"
                                key={tab.value}
                                value={tab.value}
                                defaultChecked
                            >
                                {tab.label}
                            </TabsTrigger>
                        ))}
                    </TabsList> */}

                    {TenantTabs.map((tab) => (
                        <TabsContent className="mt-5" key={tab.value} value={tab.value}>
                            {tab.value === "Tenants" && <PropertyManagementTenants />}
                            {/* {tab.value === "Contracts" && <PropertyManagementContracts />} */}
                        </TabsContent>
                    ))}
                </Tabs>
            </div>
        </div>
    );
};
