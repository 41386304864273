export function StatusOpenIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.00008 2.00033C4.68637 2.00033 2.00008 4.68662 2.00008 8.00033C2.00008 11.314 4.68637 14.0003 8.00008 14.0003C11.3138 14.0003 14.0001 11.314 14.0001 8.00033C14.0001 4.68662 11.3138 2.00033 8.00008 2.00033ZM0.666748 8.00033C0.666748 3.95024 3.94999 0.666992 8.00008 0.666992C12.0502 0.666992 15.3334 3.95024 15.3334 8.00033C15.3334 12.0504 12.0502 15.3337 8.00008 15.3337C3.94999 15.3337 0.666748 12.0504 0.666748 8.00033Z"
                fill="#7A7493"
            />
        </svg>
    );
}
