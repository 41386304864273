import { toast } from "react-toastify";
import { TContract } from "../../types";
import { catchAll } from "../../utils/catch";
import { api } from "./api";

const getContractsApis = api.injectEndpoints({
    endpoints: (builder) => ({
        getContracts: builder.query<TContract[], void | string>({
            query: (id?: string) => (id ? `properties/tenancy?id=${id}` : `properties/tenancy`),
            providesTags: (_, __, id) => (id ? [{ type: "CONTRACTS", id }] : ["CONTRACTS"]),
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Something went wrong!");
                }
            },
            transformResponse: (response: TContract[] | TContract) => {
                return Array.isArray(response) ? response : [response];
            },
        }),
    }),
});

export type TCreateContractArgs = {
    companyId?: string;
    propertyId?: string;
    userId?: string;
    bookingDate?: string;
    startDate?: string;
    endDate?: string;
    tenure?: string;
    rent?: number;
    status?: string;
    securityDeposit?: number;
    otherCharges?: number;
    paymentMethod?: string;
    attachmentsUrls?: string[];
    _id?: string;
};

const createContractApis = api.injectEndpoints({
    endpoints: (builder) => ({
        createContract: builder.mutation<TContract, TCreateContractArgs>({
            query: (args) => {
                const body = {
                    ...args,
                    status: "active",
                    paymentMethod: "bank_transfer",
                    bookingDate: new Date(args?.bookingDate || "").toISOString(),
                    startDate: new Date(args?.startDate || "").toISOString(),
                    endDate: new Date(args?.endDate || "").toISOString(),
                };

                return {
                    url: "properties/tenancy",
                    method: "POST",
                    body,
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to create contract!");
                    return;
                }
            },
            invalidatesTags: ["CONTRACTS"],
        }),
        updateContract: builder.mutation<TContract, TCreateContractArgs>({
            query: (args) => {
                const body = {
                    ...args,
                };

                return {
                    url: "properties/tenancy",
                    method: "PUT",
                    body,
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    console.error(response.error);

                    toast.error("Failed to update contract!");
                    return;
                }
            },
            invalidatesTags: ["CONTRACTS"],
        }),
        getTenantDetail: builder.query<any, string>({
            query: (propertyId: string) => {
                return {
                    url: `properties/tenancy/active?propertyId=${propertyId}`,
                    method: "GET",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to get tenant details!");
                    return;
                }
            },
        }),
    }),
});
export const { useGetContractsQuery, useLazyGetContractsQuery } = getContractsApis;
export const { useCreateContractMutation, useGetTenantDetailQuery, useUpdateContractMutation } =
    createContractApis;
