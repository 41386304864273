import { Button } from "@/components/ui/button";
import {
    PaginationContent,
    PaginationItem,
    PaginationLink,
    Pagination,
} from "@/components/ui/pagination";
import { ChevronLeft, ChevronRight } from "lucide-react";
import React, { useState, useMemo } from "react";

interface Table {
    getPageCount: () => number;
    setPageIndex: (index: number) => void;
}

interface PaginationsProps {
    table: Table;
    totalPagesOfResponse?: number;
    itemsPerPage?: number;
    pageLimit?: number;
    currentPage?: number;
    onPageChange?: (page: number) => void;
}

const Paginations: React.FC<PaginationsProps> = ({
    table,
    totalPagesOfResponse,
    currentPage: page,
    pageLimit = 5,
    onPageChange,
}) => {
    const [currentPage, setCurrentPage] = useState(page || 1);
    const totalPages = totalPagesOfResponse ? totalPagesOfResponse : table.getPageCount();

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        table.setPageIndex(page - 1);
        onPageChange?.(page);
    };

    const renderPageNumbers = useMemo(() => {
        const pageNumbers = [];
        const leftSideCount = Math.floor(pageLimit / 2);
        const rightSideCount = pageLimit - leftSideCount - 1;

        let startPage = Math.max(1, currentPage - leftSideCount);
        let endPage = Math.min(totalPages, startPage + pageLimit - 1);

        if (endPage - startPage + 1 < pageLimit) {
            startPage = Math.max(1, endPage - pageLimit + 1);
        }

        // Always show first page
        if (startPage > 1) {
            pageNumbers.push(1);
            if (startPage > 2) {
                pageNumbers.push("...");
            }
        }

        // Add pages around current page
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        // Always show last page
        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pageNumbers.push("...");
            }
            pageNumbers.push(totalPages);
        }

        return pageNumbers.map((number, index) => (
            <PaginationItem key={index}>
                {number === "..." ? (
                    <PaginationLink className="cursor-pointer" isActive={false} onClick={() => {}}>
                        {number}
                    </PaginationLink>
                ) : (
                    <PaginationLink
                        className="cursor-pointer"
                        isActive={number === currentPage}
                        onClick={() => handlePageChange(number as number)}
                    >
                        {number}
                    </PaginationLink>
                )}
            </PaginationItem>
        ));
    }, [currentPage, totalPages, pageLimit]);

    return (
        <div className="flex items-center justify-end space-x-2 px-4 py-2">
            <Pagination>
                <PaginationContent>
                    <PaginationItem>
                        <Button
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                            className="cursor-pointer bg-bone shadow-none text-[#000] hover:bg-white"
                        >
                            <ChevronLeft className="h-4 w-4" />
                            Previous
                        </Button>
                    </PaginationItem>
                    {renderPageNumbers}
                    <PaginationItem>
                        <Button
                            disabled={currentPage === totalPages || !totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                            className="cursor-pointer bg-bone shadow-none text-[#000] hover:bg-white"
                        >
                            Next
                            <ChevronRight className="h-4 w-4" />
                        </Button>
                    </PaginationItem>
                </PaginationContent>
            </Pagination>
        </div>
    );
};

export default Paginations;
