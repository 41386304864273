import { Link } from "react-router-dom";
import styles from "./style.module.css";
import { useCallback, useEffect, useRef, useState } from "react";

const menu = [
    // {
    //   label: "Dashboard",
    //   path: "/",
    //   subMenu: [],
    // },
    {
        label: "Find Jobs",
        path: "/",
        subMenu: [
            { label: "Find Jobs", path: "/contractor/find-jobs" },
            { label: "Saved Jobs", path: "/contractor/saved-jobs" },
        ],
    },
    {
        label: "My Jobs",
        path: "/",
        subMenu: [
            { label: "My Jobs", path: "/contractor/my-jobs" },
            { label: "All Contracts", path: "/contractor/contracts" },
            { label: "Work Diary", path: "/contractor/workdiary" },
        ],
    },
    {
        label: "Report",
        path: "/contractor/reports",
        subMenu: [],
    },
    {
        label: "Messages",
        path: "/",
        subMenu: [],
    },
    {
        label: "AI Assistant",
        path: "/contractor/ai-assistant",
        subMenu: [],
    },
    {
        label: "Calendar",
        path: "/contractor/calendar",
        subMenu: [],
    },
];

interface MobileNavigationProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function MobileNavigation({ isOpen, setIsOpen }: MobileNavigationProps) {
    const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);
    const navRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (navRef.current && !navRef.current.contains(event.target as Node)) {
                setOpenSubMenu(null);
                setIsOpen(false);
            }
        },
        [setIsOpen],
    );

    useEffect(() => {
        // document.addEventListener("mousedown", handleClickOutside);
        // return () => {
        //   document.removeEventListener("mousedown", handleClickOutside);
        // };
    }, [handleClickOutside]);

    const toggleSubMenu = (menu: string) => {
        if (openSubMenu === menu) {
            setOpenSubMenu(null);
        } else {
            setOpenSubMenu(menu);
        }
    };
    return isOpen ? (
        <div ref={navRef} className={styles.mobileNavFrame}>
            <ul>
                {menu.length > 0 &&
                    menu.map((item, index) => {
                        return item.subMenu.length > 0 ? (
                            <button onClick={() => toggleSubMenu(item.label)} key={index}>
                                <li className={`${styles.liNav} ${styles.mobileDrop}`}>
                                    <div>
                                        <span>{item.label}</span>
                                        {openSubMenu === item.label ? (
                                            <i className="ri-arrow-up-s-line"></i>
                                        ) : (
                                            <i className="ri-arrow-down-s-line"></i>
                                        )}
                                    </div>
                                    {openSubMenu === item.label && (
                                        <ul className={styles.mobileSubList}>
                                            {item.subMenu.map((sub, index) => (
                                                <Link to={sub.path} key={index}>
                                                    <li>
                                                        <span>{sub.label}</span>
                                                    </li>
                                                </Link>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            </button>
                        ) : (
                            <Link to={item.path}>
                                <li className={styles.liNav} key={index}>
                                    <span>{item.label}</span>
                                </li>
                            </Link>
                        );
                    })}
            </ul>
        </div>
    ) : null;
}

export default MobileNavigation;
