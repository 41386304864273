import { useState } from "react";
import { TUser } from "@/types";
import OrganizationDetailsForm from "./OrganizationDetailsForm";
import ContactInformationForm from "./ContactInformationForm";
import AddressForm from "./AddressForm";

type TProp = {
    user: TUser | undefined;
};

export function Organization(props: TProp) {
    const [profileImage, setProfileImage] = useState(props.user?.companyInfo?.imageUrl || "");

    return (
        <section className="py-8">
            <div>
                <h1 className="text-xl font-semibold">Basic Details</h1>
                <p className="text-sm text-gray-500">Edit basic details of your organisation.</p>
            </div>

            <div className="mt-6">
                <OrganizationDetailsForm
                    user={props.user}
                    profileImage={profileImage}
                    setProfileImage={setProfileImage}
                />
            </div>

            <div className="mt-8">
                <h1 className="text-xl font-semibold">Contact Information</h1>
                <p className="text-sm text-gray-500">Edit your contact information.</p>
            </div>
            <div className="mt-6">
                <ContactInformationForm user={props.user} />
            </div>

            <div className="mt-14">
                <h1 className="text-xl font-semibold">Address</h1>
                <p className="text-sm text-gray-500">Edit your address.</p>
            </div>
            <div className="mt-6 ">
                <AddressForm user={props.user} />
            </div>
        </section>
    );
}
