import { TUser } from "@/types";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Skeleton } from "@/components/ui/skeleton";

interface ChatTopbarProps {
    selectedUser: TUser | undefined;
    isConversationLoading?: boolean;
}

export default function ChatTopbar({ selectedUser, isConversationLoading }: ChatTopbarProps) {
    // Remove the avatarColor variable as we'll use a fixed black background

    return (
        <div className="bg-gray-100 border-b text-gray-900 p-4 flex items-center">
            <div className="mr-3">
                {isConversationLoading ? (
                    <Skeleton className="h-[3rem] w-[3rem] rounded-full bg-white" />
                ) : (
                    <Avatar className="h-[2.2rem] w-[2.2rem]">
                        <AvatarImage src="" alt={selectedUser?.username || "User Avatar"} />
                        <AvatarFallback
                            className="text-sm text-white bg-neutral-700" // Added bg-black class
                        >
                            {selectedUser?.firstName.slice(0, 1).toUpperCase()}
                            {selectedUser?.lastName.slice(0, 1).toUpperCase()}
                        </AvatarFallback>
                    </Avatar>
                )}
            </div>
            <div>
                {isConversationLoading ? (
                    <>
                        <Skeleton className="h-5 w-24 bg-white mb-2" />
                        <Skeleton className="h-4 w-16 bg-white" />
                    </>
                ) : (
                    <>
                        <p className="text-lg font-semibold">
                            {selectedUser?.firstName.slice(0, 1).toUpperCase()}
                            {selectedUser?.firstName.slice(1)} {""}
                            {selectedUser?.lastName.slice(0, 1).toUpperCase()}
                            {selectedUser?.lastName.slice(1)}
                        </p>
                        {/* <p className="text-sm">Online</p> */}
                    </>
                )}
            </div>
        </div>
    );
}
