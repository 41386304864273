import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "../dashboard.module.css";

const JobDetailsSkeleton = () => {
    return (
        <section className={styles.homeFrame}>
            <div className={styles.jobHeader}>
                <Skeleton width={100} height={24} />
                <Skeleton circle={true} height={40} width={40} />
            </div>
            <Skeleton className={`${styles.head} ${styles.mar_y}`} height={30} width={`60%`} />
            <div className={styles.jobDetailsSect}>
                <div>
                    <Skeleton height={200} />
                    <Skeleton height={20} width={`70%`} style={{ marginTop: "20px" }} />
                    <Skeleton height={20} width={`60%`} style={{ marginTop: "10px" }} />
                    <div className={styles.jobDTable}>
                        <Skeleton count={5} height={20} />
                    </div>
                    <Skeleton count={3} height={20} style={{ marginTop: "20px" }} />
                </div>
                <div>
                    <Skeleton height={200} />
                </div>
            </div>
        </section>
    );
};

export default JobDetailsSkeleton;
