import styles from "./myJobs.module.css";
import { JobCardStatus } from "./JobCardStatus";
import { LeadIcon } from "../../assets/icons";
import { Dropdown } from "../../components/common/dropdown";
import { memo, useMemo } from "react";
import { JobCardDropdownOptions } from "../../utils/constantData";
import { TJobBid, TJobStatus } from "../../types";
import dayjs from "dayjs";
import { Button } from "@/components/ui/button";

const ContractorInfoItem = memo(
    ({ children, heading }: { children: React.ReactNode; heading?: string }) => (
        <div className={styles.jobCardContractorInfoItemContainer}>
            <div className={styles.jobCardContractorInfoItemHeading}>{heading}</div>
            <div className={styles.jobCardContractorInfoItemContent}>{children}</div>
        </div>
    ),
);

const ContractorInfo = memo(
    ({
        price,
        createdAt,
        assignedTo,
        postedBy,
        selectedTab,
    }: {
        assignedTo?: string;
        price: number;
        createdAt: string;
        postedBy?: string;
        selectedTab?: string;
    }) => {
        const userName = selectedTab === "QUOTES" ? postedBy : assignedTo;
        const contractorsInfo = useMemo(
            () => [
                {
                    heading: selectedTab === "QUOTES" ? "Customer" : "Assigned to",
                    content: (
                        <>
                            {userName ? (
                                <>
                                    {" "}
                                    <img
                                        src={"https://via.placeholder.com/500x500"}
                                        alt="contractor profile images"
                                        className={styles.jobCardContractorInfoProfileImage}
                                    />
                                    <p>{userName}</p>
                                </>
                            ) : (
                                <p>---- ----</p>
                            )}
                        </>
                    ),
                },
                {
                    heading:
                        selectedTab === "OFFERS"
                            ? ""
                            : selectedTab === "Quotes"
                              ? "Quoted Price"
                              : "Price",
                    content: <p>{selectedTab === "OFFERS" ? "" : `£${price}`}</p>,
                },
                {
                    heading: selectedTab === "QUOTES" ? "Requested Date" : "Start date",
                    content: (
                        <p className="text-sm">{dayjs(createdAt).format("h:mm A, D MMMM YYYY")}</p>
                    ),
                },
            ],
            [],
        );
        return (
            <div className={styles.jobCardContractorInfoContainer}>
                {contractorsInfo.map((item) => (
                    <ContractorInfoItem key={item.heading} heading={item.heading}>
                        {item.content}
                    </ContractorInfoItem>
                ))}
            </div>
        );
    },
);

export const JobCard = ({
    image,
    price,
    status,
    title,
    assignedTo,
    onClickViewDetails,
    id,
    createdAt,
    selectedTab,
    qoute,
    postedBy,
    jobId,
}: {
    status: Exclude<TJobStatus, "ALL">;
    title: string;
    price: number;
    image: string;
    assignedTo?: string;
    id: string;
    onClickViewDetails: (jobId: string) => void;
    createdAt: string;
    selectedTab?: string;
    qoute?: TJobBid[];
    postedBy?: string;
    jobId?: string;
}) => {
    const jobQoute = qoute && qoute[0];

    return (
        <div className={`${styles.jobCard} border border-gray-400 rounded-lg my-[10px]`}>
            <div className={`${styles.jobCardLeft} mx-[10px]`}>
                <img
                    className="w-full h-auto max-w-xs max-h-40 object-cover"
                    src={image || "https://via.placeholder.com/211x141"}
                    alt="job thumbnail"
                />
                <div className={styles.jobCardDetailsContainer}>
                    <JobCardStatus iconType={status} />
                    <p className={styles.jobCardHeading}>{title}</p>
                    <ContractorInfo
                        price={
                            selectedTab === "COMPLETED"
                                ? jobQoute?.price || 0
                                : selectedTab === "QUOTES" || selectedTab === "ACTIVE"
                                  ? jobQoute?.price || 0
                                  : price
                        }
                        createdAt={jobQoute?.createdAt ? jobQoute.createdAt : createdAt}
                        assignedTo={assignedTo}
                        postedBy={postedBy}
                        selectedTab={selectedTab}
                    />
                </div>
            </div>
            <div className={`${styles.jobCardActionContainer} text-white mx-[12px]`}>
                {/* {selectedTab !== "QUOTES" ? <> */}
                <Button
                    className="bg-[#000] text-white "
                    onClick={() => {
                        console.log(
                            selectedTab !== "QUOTES" ? id : jobId || "",
                            selectedTab,
                            id,
                            jobId,
                        );

                        return onClickViewDetails(selectedTab !== "QUOTES" ? id : jobId || "");
                    }}
                >
                    View Details
                </Button>
                <Dropdown items={JobCardDropdownOptions}>
                    <button className={styles.jobCardActionEllipses}>
                        <LeadIcon />
                    </button>
                </Dropdown>
            </div>
        </div>
    );
};
