import { useCallback, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { Heading } from "../../../../../components/common/pageHeading";
import { useAddContractorMutation, useGetJobQuery } from "../../../../../redux/rtk-query/jobsApis";
import { useGetQuoteByIdQuery } from "../../../../../redux/rtk-query/quoteApis";
import { getFormattedDate } from "../../../../../utils/formatDate";
import { Button } from "../../../../postAJob/button";
import styles from "./details.module.css";
import { Header } from "./Header";
import { PriceAndDate } from "./PriceAndDate";
import { useGetMyDetailsQuery } from "../../../../../redux/rtk-query/authApis";

export const JobDetailQuoteDetails = () => {
    const navigate = useNavigate();

    const { jobId = "", quoteId = "" } = useParams<{
        jobId: string;
        quoteId: string;
    }>();

    const { data: quoteData, isLoading: isLoadingQuote } = useGetQuoteByIdQuery(
        { jobId, quoteId },
        {
            skip: !Boolean(jobId) || !Boolean(quoteId),
        },
    );

    const { data: myUserDetails } = useGetMyDetailsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const { data, isLoading: isLoadingJob } = useGetJobQuery(
        { jobId, companyId: myUserDetails?.companyId },
        {
            skip: !Boolean(jobId),
        },
    );

    const [addContractor, { isLoading: isAddingContractor }] = useAddContractorMutation();
    const title = data?.jobDetail?.title ?? "";
    const city = data?.address?.city ?? "";
    const country = data?.address?.country ?? "";
    const rate = quoteData?.price ?? 0;

    const date = useMemo(() => {
        return quoteData?.createdAt
            ? getFormattedDate(new Date(quoteData.createdAt))
            : getFormattedDate(new Date());
    }, [quoteData?.createdAt]);

    const time = useMemo(() => {
        return quoteData?.createdAt
            ? new Date(quoteData.createdAt).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
              })
            : new Date().toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
              });
    }, [quoteData?.createdAt]);

    const onClickAccept = useCallback(
        (userId: string | undefined) => {
            if (jobId && userId) {
                addContractor({
                    _id: jobId,
                    assignedTo: userId,
                    navigate,
                });
            }
        },
        [jobId],
    );

    const offerSentNotAcceptedByContractorYet =
        Boolean(data?.assignedTo) && data?.status === "NOT_STARTED";

    return (
        <div className={styles.page}>
            <Header />
            {isLoadingJob ? (
                <Skeleton height={39} width={"100%"} style={{ marginBottom: 19 }} />
            ) : (
                <Heading as="h2" size="lg" bottom={19}>
                    {title}
                </Heading>
            )}
            {isLoadingJob ? (
                <Skeleton height={19} width={"100%"} style={{ marginBottom: 38 }} />
            ) : (
                <p className={styles.country}>
                    {city}, {country}
                </p>
            )}
            <div className={styles.priceAndDateContainer}>
                <PriceAndDate heading="Offered Price" value={`£${rate}.00`} />
                <PriceAndDate heading="Scheduled date" value={date} time={time} />
            </div>
            <h3 className={styles.messageFromContractorHeading}>Message from contractor</h3>
            {isLoadingQuote ? (
                <Skeleton height={22} width={"100%"} style={{ marginBottom: 51 }} />
            ) : (
                <p className={styles.messageFromContractorText}>{quoteData?.message}</p>
            )}

            <footer className={styles.footer}>
                <Button
                    onClick={() => onClickAccept(quoteData?.postedBy._id)}
                    isLoading={isAddingContractor}
                    disabled={offerSentNotAcceptedByContractorYet}
                >
                    {offerSentNotAcceptedByContractorYet ? "Offer Sent" : "Accept"}
                </Button>
            </footer>
        </div>
    );
};

export default JobDetailQuoteDetails;
