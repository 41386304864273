import { KeyboardEventHandler } from "react";
import { SearchIcon } from "../../../assets/icons";
import styles from "./input.module.css";

type TProp = {
    handleChange?: (event: any) => void;
    onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
    placeholder?: string;
};

function SearchField({ handleChange, onPressEnter, placeholder = "Search Jobs" }: TProp) {
    return (
        <div className={styles.searchFrame}>
            <div>
                <SearchIcon />
            </div>
            <input onKeyDown={onPressEnter} placeholder={placeholder} onChange={handleChange} />
        </div>
    );
}

export default SearchField;
