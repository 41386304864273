import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import {
    GetWorkOrderResult,
    TCreateJobBody,
    TGetAllJobs,
    TGetAllJobsSuccess,
    TJobTabFilter,
    TJobType,
    TPaginated,
    TPostJobSliceInitialState,
    TSuccessUpdateContractor,
    TUser,
    TUserType,
} from "../../types";
import { catchAll } from "../../utils/catch";
import { UserType } from "../../utils/interface/auth.interface";
import { setPostJobToInitialState } from "../features/postJobSlice";
import { api } from "./api";
export type { DefinitionType } from "@reduxjs/toolkit/query";

export type TCreateJobArgs = Omit<
    TPostJobSliceInitialState,
    "budget" | "userPrompt" | "addressMapsObject" | "address"
> & {
    budget: number;
    navigate?: NavigateFunction;
    _id?: string;
    address: TCreateJobBody["jobDetails"]["address"];
    jobType: TCreateJobBody["jobType"];
    navigateAfterCreating: string;
    status?: string;
    priority?: string;
    tenantId?: string;
};

type TAddContractorArgs = {
    _id: string;
    assignedTo: string;
    navigate: NavigateFunction;
};

type TAcceptJobByContractor = {
    _id: string;
    assignedTo?: string;
    status?: string;
};

type TJobTabFilterWithCompany = {
    type: TJobTabFilter;
    myDetails: TUser | undefined;
};

type TJob = {
    jobId: string | undefined;
    companyId: string | undefined;
    token?: string | undefined;
};

type TUpdateJobs = {
    _id: string;
    jobDetails: {
        title?: string;
        description?: string;
        photoUrl?: string[];
        supportingPhotos?: string[];
        rate?: number;
    };
    status?: string;
    jobPriority?: string;
    assignedTo?: string;
    tenantId?: string;
    dueDate?: string;
    jobCategory?: string;
};

const FETCH_JOBS_WITH_COMPANY_ID: TUserType[] = [UserType.TENANT, UserType.PROPERTY_MANAGER];

const createJobApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createJob: builder.mutation<any, TCreateJobArgs>({
            invalidatesTags: ["HOME_OWNER_JOBS", "WORK_ORDERS"],
            query: (data) => {
                // const [photoUrl] = data.image
                const stringSeparatedCategories = data.categories.join(", ");
                const body: TCreateJobBody = {
                    jobDetails: {
                        supportingPhotos: data.images.filter((_, i) => i !== 0),
                        jobId: "",
                        title: data.title,
                        description: data.description,
                        photoUrl: data.images,
                        rate: data.budget,
                        address: data.address,
                    },
                    jobPriority: data.priority || "STANDARD",
                    postedBy: data._id ?? "",
                    contractType: "FIXED",
                    status: data.status || "NOT_STARTED",
                    propertyId: data?.property?.value ?? "",
                    favoritedBy: [],
                    slug: data.categories,
                    jobCategory: stringSeparatedCategories,
                    jobType: data.jobType,
                    ...(data.dueDate ? { dueDateTime: data.dueDate } : ""),
                    ...(data?.companyId ? { companyId: data.companyId } : ""),
                    ...(data.date ? { startDateTime: data.date } : ""),
                    tenantId: data.tenantId || "",
                };

                return {
                    url: "jobs",
                    method: "POST",
                    body,
                };
            },
            onQueryStarted: async (args, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Failed to create work order");
                    return;
                } else {
                    toast.success("Work order created successfully");
                    api.dispatch(setPostJobToInitialState());
                    if (args?.navigate) {
                        args.navigate(args.navigateAfterCreating);
                    }
                }
            },
        }),
    }),
});

const jobsApis = api.injectEndpoints({
    endpoints: (builder) => ({
        getJobs: builder.query<TGetAllJobs[], TJobTabFilterWithCompany>({
            query: ({ type, myDetails }) => {
                const ifFetchWithCompanyId =
                    myDetails &&
                    FETCH_JOBS_WITH_COMPANY_ID.includes(myDetails?.userType) &&
                    myDetails?.companyId;

                const companyId = ifFetchWithCompanyId ? myDetails?.companyId : "";

                const baseEndpoint = "jobs";

                const params = new URLSearchParams();

                if (type !== "ALL") {
                    params.append("status", type);
                }

                if (companyId) {
                    params.append("companyId", companyId);
                }

                const paramsAsString = params.toString();

                const paramsToAdd = !!paramsAsString ? "?" + paramsAsString : "";

                return `${baseEndpoint}${paramsToAdd}`;
            },
            providesTags: (_, __, { type }) =>
                type !== "ALL"
                    ? [{ type: "HOME_OWNER_JOBS", id: type }]
                    : [{ type: "HOME_OWNER_JOBS" }],
            transformResponse: (response: any, _, { type }) => {
                if (type === "QUOTES") {
                    return response;
                }
                return response.data;
            },
        }),
        getWorkOrder: builder.query<
            GetWorkOrderResult,
            {
                page?: string;
                pageSize?: string;
                sortBy?: string;
                sortOrder?: string;
                status?: string | string[];
                search?: string;
                propertyId?: string;
                contractorId?: string;
                startDate?: string;
                endDate?: string;
                jobPriority?: string | string[];
            }
        >({
            query: (arg) => ({
                url: "jobs/work-orders",
                params: {
                    page: arg.page,
                    pageSize: arg.pageSize,
                    sortBy: arg.sortBy,
                    sortOrder: arg.sortOrder,
                    status: arg.status,
                    search: arg.search,
                    propertyId: arg.propertyId,
                    contractorId: arg.contractorId,
                    startDate: arg.startDate,
                    endDate: arg.endDate,
                    priority: arg.jobPriority,
                },
            }),
            providesTags: [{ type: "WORK_ORDERS" }],
            transformResponse: (response: GetWorkOrderResult) => {
                console.log(response);

                return response;
            },
        }),

        searchForJob: builder.mutation<TGetAllJobs[], string>({
            query: (query) => `jobs?search=${query}`,
            transformResponse: (response: TPaginated<TGetAllJobs[]>) => {
                return response.data;
            },
        }),

        getJob: builder.query<TGetAllJobsSuccess, TJob>({
            query: ({ jobId, companyId, token }) => {
                return {
                    url: `jobs?id=${jobId}&companyId=${companyId}`,
                    method: "GET",
                    headers: {
                        "X-API-KEY": token,
                    },
                };
            },
            providesTags: (_, __, arg) => [{ type: "HOME_OWNER_JOBS", id: arg.jobId }],
            transformResponse: (response: TPaginated<TGetAllJobsSuccess[]>) => {
                return response.data?.[0];
            },
        }),

        getJobStats: builder.query<any, any>({
            query: (companyId) => ({
                url: `jobs/job-stats?companyId=${companyId}`,
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    toast.error("Something went wrong! Please try again later.");
                }
            },
        }),

        addContractor: builder.mutation<TSuccessUpdateContractor, TAddContractorArgs>({
            query: (params) => {
                const { _id, assignedTo } = params;
                return {
                    url: "jobs",
                    method: "PUT",
                    body: {
                        _id,
                        assignedTo,
                    },
                };
            },
            invalidatesTags: (_, __, args) => {
                return [{ type: "HOME_OWNER_JOB", id: args._id }, "HOME_OWNER_JOBS"];
            },
            onQueryStarted: async (args, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Failed to assign contractor to the job");
                    return;
                }

                args.navigate("/home-owner/jobs/" + args._id);
            },
        }),

        changeJobStatus: builder.mutation<TSuccessUpdateContractor, TAcceptJobByContractor>({
            query: (params) => {
                return {
                    url: "jobs",
                    method: "PUT",
                    body: params,
                };
            },
            invalidatesTags: (_, __, args) => {
                return [{ type: "HOME_OWNER_JOB", id: args._id }, "HOME_OWNER_JOBS"];
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Failed to assign contractor to the job");
                    return;
                }
            },
        }),

        updateJob: builder.mutation<TSuccessUpdateContractor, TUpdateJobs>({
            query: (params) => {
                return {
                    url: "jobs",
                    method: "PUT",
                    body: params,
                };
            },
            invalidatesTags: (_, __, args) => {
                return [{ type: "HOME_OWNER_JOB", id: args._id }, "HOME_OWNER_JOBS", "WORK_ORDERS"];
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Failed to update the work order");
                    return;
                }
            },
        }),

        updateJobPhotos: builder.mutation<any, { data: any; token: string }>({
            query: ({ data, token }) => ({
                url: `jobs/update?token=${token}`,
                method: "PUT",
                body: data,
            }),
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    return;
                }
            },
        }),

        makeJobFavorite: builder.mutation<any, { id: string; myDetails: TUser | undefined }>({
            query: (args) => {
                return { url: `jobs/fav/${args.id}`, method: "GET" };
            },
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    jobsApis.util.updateQueryData(
                        "getJobs",
                        { type: "ALL", myDetails: args.myDetails },
                        (draft) => {
                            const indexOfJob = draft.findIndex((job) => job._id === args.id);
                            if (indexOfJob !== -1) {
                                draft[indexOfJob].isFavorited = !draft[indexOfJob].isFavorited;
                            }
                        },
                    ),
                );
                const response = await catchAll(queryFulfilled);

                if (response.error) {
                    patchResult.undo();
                }
            },
        }),
    }),
});

const approveMaintenanceJobApi = api.injectEndpoints({
    endpoints: (builder) => ({
        approveMaintenanceJob: builder.mutation<any, string>({
            query: (_id) => {
                const jobType: TJobType = "JOB";
                return {
                    url: "jobs",
                    method: "PUT",
                    body: {
                        _id,
                        jobType,
                    },
                };
            },
            invalidatesTags: (_, __, id) => {
                return [{ type: "HOME_OWNER_JOB", id }, "HOME_OWNER_JOBS"];
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Failed to approve the job");
                    return;
                }

                toast.success("Job approved");
            },
        }),
    }),
});

export const getJobApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getJob: builder.query<TGetAllJobs, TJob>({
            query: ({ jobId, companyId }) => `jobs?id=${jobId}&companyId=${companyId}`,
            providesTags: (_, __, arg) => [{ type: "HOME_OWNER_JOBS", id: arg.jobId }],
            transformResponse: (response: TPaginated<TGetAllJobsSuccess[]>) => {
                return response.data?.[0];
            },
        }),
    }),
});

export const getNonUserJobApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getNonUserJob: builder.query<any, any>({
            query: ({ jobId, token }) => `jobs/non-user/${jobId}?token=${token}`,
            transformResponse: (response: any) => {
                return response.data?.[0];
            },
        }),
        updateJobBid: builder.mutation<
            any,
            { data: any; token: string; jobId: string; contractorId: string }
        >({
            query: ({ data, token, jobId, contractorId }) => ({
                url: `jobs/non-user/bid/${jobId}/${contractorId}?token=${token}`,
                method: "PUT",
                body: data,
            }),
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    return;
                }
            },
        }),
    }),
});

export const getPaymentLinkApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getPaymentLink: builder.query<any, any>({
            query: ({ jobId }) => `payment/link/jobs/${jobId}`,
            transformResponse: (response: any) => {
                return response;
            },
        }),
    }),
});

export const {
    useGetJobsQuery,
    useGetWorkOrderQuery,
    useLazyGetJobsQuery,
    useAddContractorMutation,
    useMakeJobFavoriteMutation,
    useChangeJobStatusMutation,
    useSearchForJobMutation,
    useGetJobStatsQuery,
    useUpdateJobPhotosMutation,
    useUpdateJobMutation,
} = jobsApis;

export const { useGetJobQuery, useLazyGetJobQuery } = getJobApi;
export const { useCreateJobMutation } = createJobApi;
export const { useApproveMaintenanceJobMutation } = approveMaintenanceJobApi;
export const { useGetNonUserJobQuery, useUpdateJobBidMutation } = getNonUserJobApi;
export const { useGetPaymentLinkQuery, useLazyGetPaymentLinkQuery } = getPaymentLinkApi;
