import { memo } from "react";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import styles from "./homeOwnerPostAJob.module.css";

type TProps = {
    label: string;
    onClickClose: (category: string) => void;
};

export const AIPromptCategory = memo((props: TProps) => (
    <div className={styles.aiPromptCategory}>
        <span>{props.label}</span>
        <CloseIcon onClick={() => props.onClickClose(props.label)} />
    </div>
));
