import styles from "./PropertyManagementCreateContract.module.css";
import { TenantInformationCard } from "./TenantInformationCard";
import { memo } from "react";

type TProps = {
    name: string;
    email: string;
    image: string;
};

export const TenantInformation = memo((props: TProps) => {
    return (
        <>
            <h2 className={styles.subHeading}>Tenant Information</h2>
            <TenantInformationCard image={props.image} email={props.email} name={props.name} />
        </>
    );
});
