import { memo } from "react";
import { BackButton } from "../../components/common/backButton";
import styles from "./homeOwnerPostAJob.module.css";

export const HeadingWithBackButton = memo((props: { heading: string }) => (
    <header className={styles.header}>
        <BackButton />
        <h1 className={styles.mainHeading}>{props.heading}</h1>
        <div className={styles.headerDummy}></div>
    </header>
));
