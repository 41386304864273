import * as React from "react";
import {
    ColumnDef,
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { DataTable } from "./DataTable";
import { cn } from "@/lib/utils";
import style from "./table.module.css";

type tableData = {
    header: ColumnDef<any>[];
    row: any[];
    heading?: string;
};
export type TTableProps = {
    data: tableData[];
    mainHeading?: string;
    containerProps?: React.ComponentProps<"div">;
    style?: React.CSSProperties;
    onClickRow?: any;
    className?: string;
    isLoading?: boolean | undefined;
    sortAndFilter?: any;
    sortColumn?: string;
    sortDirection?: string;
    subHeading?: string;
    totalpages?: number;
    currentPage?: number;
    islengthAllow?: boolean;
    setEditResponseClicked?: React.Dispatch<React.SetStateAction<boolean>>;
    editResponseHandler?: (stringId: string) => void;
    onDeleteScript?: (scriptId: string) => void;
    onPageChange?: (page: number) => void;
    card?: boolean;
    handleFilter?: (filter: string) => void;
    tableCellStyle?: string;
    useTabs?: boolean; // New prop to determine if tabs should be used
    isLengthAllow?: boolean;
};

export function TaskTables(props: TTableProps) {
    const {
        data,
        mainHeading,
        onClickRow,
        isLoading,
        sortAndFilter,
        sortColumn,
        sortDirection,
        subHeading,
        totalpages,
        currentPage,
        setEditResponseClicked,
        islengthAllow = true,
        editResponseHandler,
        onPageChange,
        card = true,
        handleFilter,
        className,
        tableCellStyle,
        useTabs = true, // New prop to determine if tabs should be used
    } = props;
    const [selectedTab, setSelectedTab] = React.useState<string>(data[0]?.heading || "");
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
    const [rowSelection, setRowSelection] = React.useState({});
    const tableData = useTabs ? data.find((item) => item.heading === selectedTab) : data[0];
    const table = useReactTable({
        data: tableData?.row || [],
        columns: tableData?.header || [],
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    });

    return (
        <div
            className={
                card === false
                    ? " bg-white border-red-400 rounded-lg"
                    : cn(style.cardContainer, "p-4 bg-white border-red-400 rounded-lg")
            }
        >
            {mainHeading && data.length > 1 && (
                <div className="flex justify-between items-center">
                    <h2 className="text-xl">{mainHeading}</h2>
                </div>
            )}
            <div>
                {useTabs ? (
                    <Tabs value={selectedTab} onValueChange={setSelectedTab}>
                        <div
                            className={
                                sortAndFilter ? "flex justify-between mb-2 items-end" : "mb-2"
                            }
                        >
                            {data.length > 1 ? (
                                <TabsList className="">
                                    {data.map((item, index) => (
                                        <TabsTrigger
                                            key={index}
                                            value={item?.heading || ""}
                                            className="bg-[#f6f6f6] text-gray-900 text-opacity-100"
                                            onClick={() =>
                                                handleFilter && handleFilter(item.heading || "")
                                            }
                                        >
                                            {item.heading} {islengthAllow && `(${item.row.length})`}
                                        </TabsTrigger>
                                    ))}
                                </TabsList>
                            ) : (
                                <div className="text-xl font-semibold">{mainHeading}</div>
                            )}
                            {sortAndFilter}
                        </div>
                        {subHeading && (
                            <p className="mt-[10px] mb-[20px] text-sm leading-5 text-[#727272] font-normal">
                                {subHeading}
                            </p>
                        )}
                        {data.map((item, index) => (
                            <TabsContent key={index} value={item?.heading || ""}>
                                {item.heading === selectedTab && (
                                    <DataTable
                                        totalpages={totalpages}
                                        currentPage={currentPage}
                                        table={table}
                                        headerLength={item.header.length}
                                        onClickRow={onClickRow}
                                        className={className}
                                        tableCellStyle={tableCellStyle}
                                        isLoading={isLoading}
                                        sortColumn={sortColumn}
                                        sortDirection={sortDirection}
                                        setEditResponseClicked={setEditResponseClicked}
                                        editResponseHandler={editResponseHandler}
                                        onDeleteScript={props.onDeleteScript}
                                        onPageChange={onPageChange}
                                    />
                                )}
                            </TabsContent>
                        ))}
                    </Tabs>
                ) : (
                    <>
                        <div
                            className={
                                sortAndFilter ? "flex justify-between mb-2 items-end" : "mb-2"
                            }
                        >
                            {sortAndFilter}
                        </div>
                        {subHeading && (
                            <p className="mt-[10px] mb-[20px] text-sm leading-5 text-[#727272] font-normal">
                                {subHeading}
                            </p>
                        )}
                        <DataTable
                            totalpages={totalpages}
                            currentPage={currentPage}
                            table={table}
                            headerLength={tableData?.header.length || 0}
                            onClickRow={onClickRow}
                            className={className}
                            tableCellStyle={tableCellStyle}
                            isLoading={isLoading}
                            sortColumn={sortColumn}
                            sortDirection={sortDirection}
                            setEditResponseClicked={setEditResponseClicked}
                            editResponseHandler={editResponseHandler}
                            onDeleteScript={props.onDeleteScript}
                            onPageChange={onPageChange}
                        />
                    </>
                )}
            </div>
        </div>
    );
}
