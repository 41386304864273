export const formatDuration = (totalSeconds: any) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (hours > 0) {
        return `${hours} hrs ${minutes} mins`;
    } else if (minutes > 0) {
        return `${minutes} mins ${seconds} secs`;
    } else {
        return `${seconds} secs`;
    }
};
