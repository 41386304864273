import { Button } from "@/components/ui/button";
import { useGetBuildingQuery } from "@/redux/rtk-query/properties";
import { convertScreamToTitle } from "@/utils/convertScreamToTitle";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { TAddPropertyPage } from ".";

type TProps = {
    onChangePage: (page: TAddPropertyPage) => void;
    buildingId: string;
};

export const UnitAddedList = ({ onChangePage, buildingId }: TProps) => {
    const { data: propertiesData } = useGetBuildingQuery(
        { id: buildingId ?? "", type: "BUILDING" },
        {
            skip: !buildingId,
        },
    );

    const navigate = useNavigate();
    const onClickConfirmAndPublish = () => {
        navigate("/property-management/properties");
    };
    const onClickBackToProperty = () => {
        onChangePage("property");
    };
    const onClickAddMoreUnit = () => {
        onChangePage("units");
    };
    return (
        <section>
            <div
                className="bg-[#F6F8FA]  gap-1 px-2 py-1 rounded-3xl font-medium text-sm leading-[14px] text-[#666D80] flex items-center space-x-2 max-w-max mb-6 cursor-pointer"
                onClick={onClickBackToProperty}
            >
                <ArrowLeft size={20} /> Back to Property
            </div>
            <h2 className="font-medium text-lg leading-[22px] text-[#09090B] mb-6">
                Units Added ({propertiesData?.[0]?.properties?.length ?? 0})
            </h2>
            <div>
                {propertiesData?.[0]?.properties?.map((property) => {
                    return (
                        <div
                            key={property?._id}
                            className="border border-zinc-200 p-6 rounded-md mb-6"
                        >
                            <div className="flex items-center justify-between">
                                <h3 className="font-medium text-xl leading-[26px] text-black !m-0 !mb-2">
                                    #{property?.name}
                                </h3>
                            </div>
                            {property?.tenancies?.[0]?.tenant && (
                                <div className="flex space-x-4 items-center">
                                    <p className="font-normal text-xs leading-4 text-gray-500">
                                        Tenant
                                    </p>
                                    <p className="font-normal text-xs leading-4 text-gray-800">
                                        {(() => {
                                            const firstName =
                                                property?.tenancies?.[0]?.tenant?.firstName ?? "";
                                            const lastName =
                                                property?.tenancies?.[0]?.tenant?.lastName ?? "";
                                            return `${firstName} ${lastName}`;
                                        })()}
                                    </p>
                                </div>
                            )}
                            <div className="grid grid-cols-[auto_1fr] gap-x-4 gap-y-2 mt-3">
                                <p className="text-sm text-zinc-900 self-center">Area</p>
                                <p>{property?.area} sqrt ft.</p>
                                <p className="text-sm text-zinc-900 self-center">
                                    Number of Bedrooms
                                </p>
                                <p>{property?.noOfBedrooms}</p>
                                <p className="text-sm text-zinc-900 self-center">Status</p>
                                <p>{convertScreamToTitle(property?.status ?? "")}</p>
                                <p className="text-sm text-zinc-900 self-center">Type</p>
                                <p>{convertScreamToTitle(property?.type ?? "")}</p>
                            </div>
                            {/* <div className="flex flex-wrap gap-2 mt-4">
                                {Array(10)
                                    .fill(0)
                                    .map((_, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className="gap-2.5 border border-zinc-200 rounded px-2 py-1 border-solid bg-white text-xs leading-[15px] text-[#09090B] max-w-max"
                                            >
                                                Facility 1
                                            </div>
                                        );
                                    })}
                            </div> */}
                        </div>
                    );
                })}
            </div>
            <div>
                <Button onClick={onClickAddMoreUnit} variant={"outline"} className="mb-10">
                    Add More Unit
                </Button>
            </div>
            <Button onClick={onClickConfirmAndPublish} className="mb-16">
                Confirm & Publish
            </Button>
        </section>
    );
};
