export default function formatAddress(address: any) {
    const parts = [
        address.mainStreet && address.mainStreet !== "Not provided" && address.mainStreet !== "N/A"
            ? address.mainStreet
            : "",
        address.building && address.building !== "Not provided" && address.building !== "N/A"
            ? address.building
            : "",
        address.postalCode && address.postalCode !== "Not provided" && address.postalCode !== "N/A"
            ? address.postalCode
            : "",
        address.city && address.city !== "Not provided" && address.city !== "N/A"
            ? address.city
            : "",
        address.province && address.province !== "Not provided" && address.province !== "N/A"
            ? address.province
            : "",
        address.country && address.country !== "Not provided" && address.country !== "N/A"
            ? address.country
            : "",
    ];

    return parts.filter((part) => part).join(", ");
}
