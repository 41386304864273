import { memo } from "react";
import styles from "./smallJobCard.module.css";

export const ProfileImage = memo(() => (
    <img
        src="https://via.placeholder.com/500x500"
        alt="profile image"
        className={styles.suggestionCardProfileImage}
    />
));
