import avatar from "../../../../assets/images/Avatar.png";
import styles from "./AiAssistant.module.css";
import { CopyIcon } from "../../../../assets/icons/CopyIcon";
import { Dropdown } from "../../../../components/common/dropdown";
import { TDropdownItem } from "../../../../components/common/dropdown/Dropdown.types";
import { ManageAiAssistantDropdown } from "../../../../utils/constantData";
import { useCallback } from "react";
import { CallLogs } from "./CallLogs";
import { useNavigate } from "react-router-dom";
import { Tabs } from "@mantine/core";
import { LineChart } from "../../../../components/chart/lineChart";
import { useGetMyDetailsQuery } from "../../../../redux/rtk-query/authApis";
import { useGetCallLogsQuery } from "../../../../redux/rtk-query/aiApis";
import { useGetJobsQuery } from "@/redux/rtk-query/jobsApis";
import { CardContent, CardTitle } from "@/components/ui/card";
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@/components/ui/carousel";
import { Button } from "@/components/ui/button";
import { Header } from "@/components/common/headers/Header";

export const AiAssistant = () => {
    const labels = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const navigate = useNavigate();
    const onClickDropdown = useCallback(
        (clickedItem: TDropdownItem) => {
            if (clickedItem.value === "SET_AI_VOICE_ASSISTANT") {
                navigate("/contractor/ai-assistant/set-voice-assistant");
            } else if (clickedItem.value === "SET_PRICING") {
                navigate("/contractor/ai-assistant/set-pricing");
            } else if (clickedItem.value === "VIEW_INVOICES") {
                navigate("/contractor/ai-assistant/invoices");
            } else if (clickedItem.value === "MANAGE_PROFILE") {
                navigate("/contractor/ai-assistant/manage-profile");
            }
        },
        [navigate],
    );

    const { data: myDetails } = useGetMyDetailsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const { data: callLogs, isLoading } = useGetCallLogsQuery(
        { contractorId: myDetails?._id ?? "", limit: 10, page: 1 },
        { skip: !myDetails?._id, refetchOnMountOrArgChange: true },
    );
    const { data: allJobs } = useGetJobsQuery(
        { type: "ACTIVE", myDetails },
        {
            refetchOnMountOrArgChange: true,
            skip: !myDetails,
        },
    );

    return (
        <div className="overflow-y-scroll w-full">
            <Header
                image={myDetails?.imageUrl}
                heading={undefined}
                firstName={myDetails?.firstName}
                lastName={myDetails?.lastName}
                hasSearchBar={true}
            />
            <div className="mx-5 pt-2.5">
                <CardTitle className="mb-0">AI Assistant</CardTitle>
                <div className={styles.infoContainer}>
                    <div className={styles.topHeader}>
                        <div className={styles.aiAssistant}>
                            <img src={avatar} alt="avatar" />
                            <span>LightWork AI</span>
                        </div>
                        <div className={styles.btnDiv}>
                            <Button
                                onClick={() => navigate("/contractor/ai-assistant/invoices")}
                                className="text-white"
                            >
                                View Invoices
                            </Button>
                            <Dropdown onClick={onClickDropdown} items={ManageAiAssistantDropdown}>
                                <Button className="text-white">Manage AI Assistant</Button>
                            </Dropdown>
                            <Button className="text-white">Test Call</Button>
                        </div>
                    </div>
                    <div className={styles.phoneInfo}>
                        <span className="text-[#000] text-[18px] font-bold">
                            Lightwork Phone Number:
                        </span>
                        <span className={styles.numberText}>
                            {myDetails?.userProfile?.assistantPhoneNo}{" "}
                            {myDetails?.userProfile?.assistantPhoneNo && <CopyIcon />}
                        </span>
                    </div>
                    <div className={`${styles.analyticsBtn} `}>
                        <h6>Performance Overview</h6>
                        <Button
                            onClick={() => navigate("/contractor/ai-assistant/analytics")}
                            className="text-white"
                        >
                            View Analytics <i className="ri-arrow-right-line text-white"></i>
                        </Button>
                    </div>
                    <div className="my-[20px]">
                        <Carousel className="w-[90%] m-auto">
                            <CarouselContent className="-ml-1">
                                <CarouselItem className="pl-1 md:basis-1/2 lg:basis-1/3">
                                    <CardContent className="flex aspect-square items-center justify-center p-0">
                                        <div className={styles.analyticsCard}>
                                            <div className={styles.itemInfo}>
                                                <p>Total Invoices Sent</p>
                                                <h6>0</h6>
                                                <div className={styles.infoDiv}>
                                                    <span
                                                        className={`${styles.percent} ${styles.up}`}
                                                    >
                                                        0%
                                                    </span>
                                                    <span className={styles.day}>vs last week</span>
                                                </div>
                                            </div>
                                            <div className={styles.chart}>
                                                <LineChart
                                                    title=""
                                                    displayScales={false}
                                                    labels={labels}
                                                />
                                            </div>
                                        </div>
                                    </CardContent>
                                </CarouselItem>
                                <CarouselItem className="pl-1 md:basis-1/2 lg:basis-1/3">
                                    <CardContent className="flex aspect-square items-center justify-center p-0">
                                        <div className={styles.analyticsCard}>
                                            <div className={styles.itemInfo}>
                                                <p>Total Jobs Booked</p>
                                                <h6>0</h6>
                                                <div className={styles.infoDiv}>
                                                    <span
                                                        className={`${styles.percent} ${styles.up}`}
                                                    >
                                                        0%
                                                    </span>
                                                    <span className={styles.day}>vs last week</span>
                                                </div>
                                            </div>
                                            <div className={styles.chart}>
                                                <LineChart
                                                    title=""
                                                    displayScales={false}
                                                    labels={labels}
                                                />
                                            </div>
                                        </div>
                                    </CardContent>
                                </CarouselItem>
                                <CarouselItem className="pl-1 md:basis-1/2 lg:basis-1/3">
                                    <CardContent className="flex aspect-square items-center justify-center p-0">
                                        <div className={styles.analyticsCard}>
                                            <div className={styles.itemInfo}>
                                                <p>Jobs scheduled</p>
                                                <h6>{allJobs ? allJobs.length : 0}</h6>
                                                <div className={styles.infoDiv}>
                                                    <span
                                                        className={`${styles.percent} ${styles.up}`}
                                                    >
                                                        {allJobs ? allJobs.length : 0}%
                                                    </span>
                                                    <span className={styles.day}>vs last week</span>
                                                </div>
                                            </div>
                                            <div className={styles.chart}>
                                                <LineChart
                                                    title=""
                                                    displayScales={false}
                                                    labels={labels}
                                                />
                                            </div>
                                        </div>
                                    </CardContent>
                                </CarouselItem>
                                <CarouselItem className="pl-1 md:basis-1/2 lg:basis-1/3">
                                    <CardContent className="flex aspect-square items-center justify-center p-0">
                                        <div className={styles.analyticsCard}>
                                            <div className={styles.itemInfo}>
                                                <p>Total Calls</p>
                                                <h6>{callLogs ? callLogs.length : 0}</h6>
                                                <div className={styles.infoDiv}>
                                                    <span
                                                        className={`${styles.percent} ${styles.up}`}
                                                    >
                                                        {callLogs ? callLogs.length : 0}%
                                                    </span>
                                                    <span className={styles.day}>vs last week</span>
                                                </div>
                                            </div>
                                            <div className={styles.chart}>
                                                <LineChart
                                                    title=""
                                                    displayScales={false}
                                                    labels={labels}
                                                    dataPoints={callLogs ? [callLogs.length] : [0]}
                                                />
                                            </div>
                                        </div>
                                    </CardContent>
                                </CarouselItem>
                            </CarouselContent>
                            <CarouselPrevious className="" variant="grey" />
                            <CarouselNext className="" variant="grey" />
                        </Carousel>
                    </div>
                </div>
                <div className={styles.tabs}>
                    <Tabs defaultValue="Call Logs">
                        <Tabs.List>
                            <Tabs.Tab value="Call Logs">Call Logs</Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="Call Logs">
                            <CallLogs callLogs={callLogs} isLoading={isLoading} />
                        </Tabs.Panel>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};
