import { memo } from "react";
import styles from "./divider.module.css";

type TVariants = "light" | "dark";

type TProps = {
    top?: number;
    bottom?: number;
    variant?: TVariants;
};

const VARIANT: Record<TVariants, string> = {
    dark: styles.dark,
    light: styles.light,
};

export const Divider = memo((props: TProps) => {
    const { top = 0, bottom = 0, variant = "dark" } = props;

    const variantStyles = VARIANT[variant];

    return (
        <div
            style={{ marginTop: top, marginBottom: bottom }}
            className={`${styles.divider} ${variantStyles}`}
        ></div>
    );
});
