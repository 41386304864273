import { useEffect, useRef, useState } from "react";
import { FaCirclePause, FaCirclePlay } from "react-icons/fa6";

type WavAudioPlayerProps = {
    audioFileUrl: string | undefined;
    callDuration: string;
    title?: string; // Optional title prop
};

export default function WavAudioPlayer({ audioFileUrl, callDuration, title }: WavAudioPlayerProps) {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [playing, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);

    const handlePlayPause = () => {
        if (!audioRef.current) return;
        setPlaying((prev) => !prev);
        if (!playing) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    };

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    };

    useEffect(() => {
        if (!audioRef.current) return;

        const updateTime = () => {
            if (audioRef.current) {
                setCurrentTime(audioRef.current.currentTime);
                setDuration(audioRef.current.duration);
            }
        };

        // Sync with the audio's current time
        audioRef.current.addEventListener("timeupdate", updateTime);
        audioRef.current.addEventListener("loadedmetadata", updateTime); // Updates when metadata is loaded (e.g., duration)

        // Cleanup
        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener("timeupdate", updateTime);
                audioRef.current.removeEventListener("loadedmetadata", updateTime);
            }
        };
    }, [audioFileUrl]);

    const remainingTime = duration - currentTime; // Calculate remaining time

    return (
        <div className="rounded-lg bg-white py-4 px-4 mt-4 shadow-sm">
            {/* Title (Optional) */}
            {title && <h1 className="text-gray-800 text-md font-medium mb-4">{title}</h1>}

            <div className="flex items-center gap-4">
                {/* Play/Pause Button */}
                {playing ? (
                    <FaCirclePause
                        className="text-[2.5rem] text-black cursor-pointer hover:text-gray-700"
                        onClick={handlePlayPause}
                    />
                ) : (
                    <FaCirclePlay
                        className="text-[2.5rem] text-black cursor-pointer hover:text-gray-700"
                        onClick={handlePlayPause}
                    />
                )}

                {/* Audio Element */}
                <audio ref={audioRef} src={audioFileUrl} preload="metadata" />

                {/* Custom Progress Bar */}
                <div className="w-full">
                    <div className="relative h-2 bg-gray-200 rounded-full">
                        <div
                            className="absolute top-0 left-0 h-2 rounded-full"
                            style={{
                                width: `${(currentTime / duration) * 100}%`,
                                backgroundColor: "#22BCFF",
                            }}
                        />
                    </div>
                </div>

                {/* Remaining Time Display */}
                <p className="text-gray-500 text-sm w-12 text-right">
                    {formatTime(remainingTime)} {/* Display remaining time */}
                </p>
            </div>
        </div>
    );
}
