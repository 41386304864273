import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AIStarIcon } from "../../assets/icons/AIStarIcon";
import { CalendarIcon } from "../../assets/icons/CalendarIcon";
import { Dropdown, DropdownZIndex } from "../../components/common/dropdown";
import { TDropdownItem } from "../../components/common/dropdown/Dropdown.types";
import useGoogleAutocomplete from "../../hooks/useGoogleAutocomplete";
import {
    removePostJobCategory,
    setAddressObject,
    setPostJobFormItemState,
    TPostJobStateKeys,
} from "../../redux/features/postJobSlice";
import { useGetMyDetailsQuery } from "../../redux/rtk-query/authApis";
import { useGetPropertiesQuery } from "../../redux/rtk-query/properties";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { AIPromptCategories } from "./AIPromptCategories";
import { Button } from "./button";
import { DatePickerWithLabel } from "./DatePickerWithLabel";
import styles from "./homeOwnerPostAJob.module.css";
import { InputWithLabel } from "./InputWithLabel";
import { TextareaWithLabel } from "./TextareaWithLabel";
import { Map } from "../../components/common/map";

type TProps = {
    onClickContinue: () => void;
    onClickCancel: () => void;
    hasPropertyDropdown: boolean;
    hasDueDate: boolean;
    hasBudget: boolean;
};

export const AIPromptForm = (props: TProps) => {
    const { hasDueDate, hasPropertyDropdown } = props;
    const { data: myDetails } = useGetMyDetailsQuery();

    const dispatch = useAppDispatch();

    const [isMapOpen, setIsMapOpen] = useState(false);

    const { data: properties } = useGetPropertiesQuery(myDetails?.companyId!, {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        skip: !myDetails?.companyId,
    });

    const propertiesAsDropdownItems: TDropdownItem[] = useMemo(() => {
        if (!properties) return [];

        return properties.map((property: any) => {
            return {
                value: property._id,
                key: property.name,
            };
        });
    }, [properties]);

    const { categories, description, title, userPrompt, date, budget, address, ...rest } =
        useAppSelector((state) => state.postJob);

    // const [getAIResponseFromPrompt] = useGetAIJobRecommendationMutation();

    const onChangeFormItem = useCallback(
        (value: string | TDropdownItem, name: TPostJobStateKeys) => {
            dispatch(
                setPostJobFormItemState({
                    name,
                    value,
                }),
            );
        },
        [dispatch],
    );

    const { autocompleteInputRef, place } = useGoogleAutocomplete({
        name: "address",
        handleUserDetails: (e) => {
            onChangeFormItem(e.target.value, "address");
        },
        showCompleteDetails: true,
    });

    useEffect(() => {
        place && dispatch(setAddressObject(place));
    }, [dispatch, place]);

    const onClickRemoveCategory = useCallback(
        (category: string) => {
            dispatch(removePostJobCategory(category));
        },
        [dispatch],
    );

    const onAddressSelect = useCallback(
        (address: string, place?: google.maps.places.PlaceResult | undefined) => {
            onChangeFormItem(address, "address");
            place && dispatch(setAddressObject(place));
        },
        [dispatch, onChangeFormItem],
    );

    const onClickOpenMap = useCallback(() => {
        setIsMapOpen(true);
    }, []);

    const onMapClose = useCallback(() => {
        setIsMapOpen(false);
    }, []);

    return (
        <div className={styles.aiPromptResultContainer}>
            <h4 className={styles.aiPromptResultHeading}>
                <AIStarIcon /> <span>Results</span>
            </h4>
            <form className={styles.aiPromptResultForm}>
                <InputWithLabel
                    labelProps={{
                        text: "Title",
                    }}
                    inputProps={{
                        required: true,
                        error: {},
                        name: "title",
                        placeholder: "Enter a title",
                        type: "text",
                        value: title,
                        handleChange: (e) => onChangeFormItem(e.target.value, "title"),
                    }}
                />
                <AIPromptCategories
                    label="Categories"
                    categories={categories}
                    onClickRemoveCategory={onClickRemoveCategory}
                />

                {hasPropertyDropdown && (
                    <Dropdown
                        width={"target"}
                        items={propertiesAsDropdownItems}
                        onClick={(v) => onChangeFormItem(v, "property")}
                    >
                        <InputWithLabel
                            labelProps={{ text: "Property" }}
                            inputProps={{
                                required: true,
                                name: "property",
                                placeholder: "Select a property",
                                type: "text",
                                handleChange: () => {},
                                value: rest?.property?.key ?? "",
                                style: { zIndex: DropdownZIndex.DROPDOWN },
                            }}
                            inputContainerProps={{ style: { margin: 0 } }}
                        />
                    </Dropdown>
                )}

                <div className={styles.addressFieldWithMapButton}>
                    <InputWithLabel
                        labelProps={{
                            text: "Address",
                        }}
                        inputProps={{
                            required: true,
                            error: {},
                            name: "address",
                            placeholder: "Enter your location",
                            type: "text",
                            handleChange: (e) => onChangeFormItem(e.target.value, "address"),
                            value: address,
                        }}
                        ref={autocompleteInputRef}
                    />
                    <Button
                        onClick={onClickOpenMap}
                        variant="secondary-with-shadow"
                        style={{ height: 47 }}
                    >
                        Map
                    </Button>
                </div>

                <TextareaWithLabel
                    labelProps={{
                        text: "Description",
                    }}
                    textAreaProps={{
                        placeholder:
                            "I'm looking for a reliable and experienced contractor to clean my house in Lagos this Saturday, April 13th, at 3 PM.",
                        error: {},
                        required: true,
                        name: "description",
                        value: description,
                        handleChange: (e) => onChangeFormItem(e.target.value, "description"),
                    }}
                />
                <DatePickerWithLabel
                    variant={hasDueDate ? "date-time" : "date"}
                    labelProps={{ text: "Start Date Time" }}
                    datePickerProps={{
                        placeholder: "Enter start date & time",
                        rightSection: <CalendarIcon />,
                        onChange: (value) => onChangeFormItem(value?.toString() ?? "", "date"),
                        value: new Date(date),
                        minDate: new Date(),
                    }}
                />

                {hasDueDate && (
                    <DatePickerWithLabel
                        variant={"date-time"}
                        labelProps={{ text: "Due Date Time" }}
                        datePickerProps={{
                            placeholder: "Enter due date & time",
                            rightSection: <CalendarIcon />,
                            onChange: (value) =>
                                onChangeFormItem(value?.toString() ?? "", "dueDate"),
                            value: rest?.dueDate ? new Date(rest?.dueDate) : new Date(),
                            minDate: date ? dayjs(date).add(1, "minute").toDate() : new Date(),
                        }}
                    />
                )}
                {/* {hasBudget && (
                    <InputWithLabel
                        labelProps={{
                            text: "Budget",
                        }}
                        inputProps={{
                            required: true,
                            error: {},
                            name: "budget",
                            placeholder: "Enter your budget",
                            type: "number",
                            handleChange: (e) => onChangeFormItem(e.target.value, "budget"),
                            value: budget,
                        }}
                        icon={<CurrencyIcon />}
                    />
                )} */}

                <Map isOpen={isMapOpen} onAddressSelect={onAddressSelect} onClose={onMapClose} />
                {/* <div className={styles.aiPromptFormFooterContainer}>
                    {userPrompt ? (
                        <Button
                            onClick={onClickRegenerateResponse}
                            padding="zero"
                            variant="tertiary"
                        >
                            {isLoading ? "Generating..." : "Regenerate job post"}
                        </Button>
                    ) : (
                        <div></div>
                    )}
                    <div className={styles.buttonContainer}>
                        <Button type="button" variant="secondary" onClick={onClickCancel}>
                            Cancel
                        </Button>
                        <Button type="button" onClick={onClickContinue}>
                            Continue
                        </Button>
                    </div>
                </div> */}
            </form>
        </div>
    );
};
