import { Checkbox } from "@mantine/core";
import { Chip } from "../../components/common/chip";
import { Dropdown } from "../../components/common/dropdown";
import { TTableRows } from "../../components/common/table/table";
import styles from "./PropertyManagementTenants.module.css";
import { TStat } from "./StatsContainer";
import { Button } from "@/components/ui/button";
import { ArrowUpDown } from "lucide-react";
import dayjs from "dayjs";

export const BASE_PROPERTY_MANAGEMENT_PATH = "property-management";

export const STATS: TStat[] = [
    {
        heading: "Total Tenants",
        percentage: 12,
        percentageAs: "increase",
        stat: "19,456",
    },
    {
        heading: "Active Tenants",
        stat: "19,456",
    },
    {
        heading: "Booked Tenants",
        stat: "19,456",
    },
];

export const TableHeadItems = [
    {
        key: 1,
        value: "ID",
    },
    {
        key: 1,
        value: "Tenant Name",
    },
    {
        key: 1,
        value: "Email",
    },
    {
        key: 2,
        value: "Phone Number",
    },
    // {
    //     key: 3,
    //     value: "Status",
    // },
    // {
    //     key: 4,
    //     value: "Rent",
    // },
    // {
    //     key: 5,
    //     value: "Lease End Date",
    // },
    // {
    //     key: 6,
    //     value: "Tenure",
    // },
    // {
    //     key: 7,
    //     value: "Payment Status",
    // },
];

export const TenantsTableDropdown = [
    { key: "View Details", value: "View Details" },
    { key: "Request Rent", value: "Request Rent" },
    { key: "Check Compliance", value: "Check Compliance" },
    { key: "Message", value: "Message" },
    { key: "Report", value: "Report" },
];

export const TableRowItems: TTableRows[] = [
    ...new Array(10).fill([
        {
            key: "ID 001",
            value: (
                <div className={styles.tableRowWithCheckbox}>
                    <Checkbox size="xs" />
                    <p>ID 001</p>
                </div>
            ),
        },
        {
            key: "John Doe",
            value: (
                <div>
                    <p>John Doe</p>
                    <p className={styles.tableRowEmail}>john.doe@example.com</p>
                </div>
            ),
        },
        {
            key: "07700 900123",
            value: "07700 900123",
        },
        {
            key: "0283",
            value: "0283",
        },
        {
            key: "£1,200",
            value: "£1,200",
        },
        {
            key: "2024-01-01",
            value: "2024-01-01",
        },
        {
            key: "Annual",
            value: "Annual",
        },
        {
            key: "good",
            value: (
                <div className={styles.tableRowAction}>
                    <Chip color="green" text="Good" />
                    <Dropdown items={TenantsTableDropdown}>
                        <button>...</button>
                    </Dropdown>
                </div>
            ),
        },
    ]),
];

export const TableHeadItemsTenants = [
    {
        accessorKey: "id",
        header: "Tenant ID",
        cell: ({ row }: any) => {
            const sequenceNumber = row.index + 1;
            return <div className="ml-4">{`#${sequenceNumber}`}</div>;
        },
    },
    {
        accessorKey: "TenantName",
        header: "Tenant Name",
        cell: ({ row }: any) => <div className="capitalize">{row.getValue("TenantName")}</div>,
    },
    {
        accessorKey: "email",
        header: "Email",
        cell: ({ row }: any) => <div className="lowercase ">{row.getValue("email")}</div>,
    },
    {
        accessorKey: "phoneNumber",
        header: "Phone Number",
        cell: ({ row }: any) => {
            return (
                <div className={`font-medium ${row?.getValue?.("phoneNumber") ? "" : "ml-10"}`}>
                    {row?.getValue?.("phoneNumber") || "-"}
                </div>
            );
        },
    },
    // {
    //     id: "actions",
    //     enableHiding: false,
    //     cell: ({ row }: any) => {
    //         const payment = row.original;

    //         return;
    //         //  <DropdownMenuDemo field={["Message", "view Details"]} />;
    //     },
    // },
];

export const TableHeadItemsContracts = [
    {
        accessorKey: "id",
        header: ({ column }: any) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    className="hover:bg-transparent"
                >
                    Contract ID
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }: any) => {
            return <div className="ml-4">{`#${row.getValue("id")}`}</div>;
        },
    },
    {
        accessorKey: "rent",
        header: "Rent",
        cell: ({ row }: any) => <div className="capitalize">{row.getValue("rent")}</div>,
    },
    {
        accessorKey: "securityDeposit",
        header: ({ column }: any) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    className="hover:bg-transparent"
                >
                    Security Deposit
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }: any) => (
            <div className="lowercase ml-4">{row.getValue("securityDeposit")}</div>
        ),
    },
    {
        accessorKey: "otherCharges",
        header: () => <div>Other Charges</div>,
        cell: ({ row }: any) => {
            return <div className="font-medium">{row.getValue("otherCharges")}</div>;
        },
    },
    {
        accessorKey: "tenure",
        header: () => <div>Tenure</div>,
        cell: ({ row }: any) => {
            const tenure =
                row.getValue("tenure") === "long-term"
                    ? "Long Term"
                    : row.getValue("tenure") === "short-term"
                      ? "Short Term"
                      : "Monthly";
            return <div className="font-medium">{tenure}</div>;
        },
    },
    {
        accessorKey: "bookingDate",
        header: () => <div>Booking Date</div>,
        cell: ({ row }: any) => {
            return (
                <div className="font-medium">
                    {dayjs(row.getValue("bookingDate")).format("YYYY-MM-DD")}
                </div>
            );
        },
    },
    {
        accessorKey: "startDate",
        header: () => <div>Start Date</div>,
        cell: ({ row }: any) => {
            return (
                <div className="font-medium">
                    {dayjs(row.getValue("startDate")).format("YYYY-MM-DD")}
                </div>
            );
        },
    },
    {
        accessorKey: "endDate",
        header: () => <div>End Date</div>,
        cell: ({ row }: any) => {
            return (
                <div className="font-medium">
                    {dayjs(row.getValue("endDate")).format("YYYY-MM-DD")}
                </div>
            );
        },
    },
    {
        id: "actions",
        enableHiding: false,
        cell: () => {
            return;
            //  <DropdownMenuDemo field={["Message", "view Details"]} />;
        },
    },
];

export const TableHeadItemsInquires = [
    // {
    //     id: "select",
    //     header: ({ table }:any) => (
    //         <Checkbox
    //             checked={
    //                 table.getIsAllPageRowsSelected() ||
    //                 (table.getIsSomePageRowsSelected() && "indeterminate")
    //             }
    //             onCheckedChange={(value: any) => table.toggleAllPageRowsSelected(!!value)}
    //             aria-label="Select all"
    //         />
    //     ),
    //     cell: ({ row }:any) => (
    //         <Checkbox
    //             checked={row.getIsSelected()}
    //             onCheckedChange={(value: any) => row.toggleSelected(!!value)}
    //             aria-label="Select row"
    //         />
    //     ),
    //     enableSorting: false,
    //     enableHiding: false,
    // },
    {
        accessorKey: "id",
        header: ({ column }: any) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Id
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }: any) => {
            const sequenceNumber = row.index + 1;
            return <div className="ml-4">{`#${sequenceNumber}`}</div>;
        },
    },
    {
        accessorKey: "TenantName",
        header: "Tenant Name",
        cell: ({ row }: any) => <div className="capitalize">{row.getValue("TenantName")}</div>,
    },
    {
        accessorKey: "email",
        header: ({ column }: any) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Email
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }: any) => <div className="lowercase ml-4">{row.getValue("email")}</div>,
    },
    {
        accessorKey: "phoneNumber",
        header: () => <div>Phone Number</div>,
        cell: ({ row }: any) => {
            return <div className="font-medium">{row.getValue("phoneNumber")}</div>;
        },
    },
    {
        id: "actions",
        enableHiding: false,
        cell: () => {
            return;
            //  <DropdownMenuDemo field={["Message", "view Details"]} />;
        },
    },
];
