import { memo } from "react";
import Label from "../../components/common/label";
import { AIPromptCategory } from "./AIPromptCategory";
import styles from "./homeOwnerPostAJob.module.css";

type TProps = {
    categories: string[];
    label: string;
    onClickRemoveCategory: (category: string) => void;
};

export const AIPromptCategories = memo((props: TProps) => {
    return props.categories.length > 0 ? (
        <div>
            <Label text={props.label} type={"plain-black"} />
            <div className={styles.aiPromptCategoriesContainer}>
                {props.categories.map((category) => (
                    <AIPromptCategory
                        key={category}
                        label={category}
                        onClickClose={props.onClickRemoveCategory}
                    />
                ))}
            </div>
        </div>
    ) : (
        <></>
    );
});
