import { BackButton } from "../../../../../components/common/backButton";
import { Heading } from "../../../../../components/common/pageHeading";
import styles from "./details.module.css";

export const Header = () => (
    <header className={styles.header}>
        <BackButton />
        <Heading as="h1">Offer details</Heading>
        <div></div>
    </header>
);
