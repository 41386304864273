import { memo } from "react";
import styles from "./contractorDetails.module.css";

type TProps = {
    jobTitle: string;
};

export const JobTitleWithPrice = memo(({ jobTitle }: TProps) => (
    <div className={styles.titleAndPriceContainer}>
        <p className={styles.jobTitle}>{jobTitle}</p>
        <p className={styles.price}>£15.00/hr</p>
    </div>
));
