import {
    BriefcaseBusiness,
    Building,
    Calendar,
    House,
    LucideIcon,
    Phone,
    Settings,
    User,
} from "lucide-react";
type Submenu = {
    href: string;
    label: string;
    active: boolean;
};

type Menu = {
    href: string;
    label: string;
    active: boolean;
    icon: LucideIcon;
    submenus: Submenu[];
};

type Group = {
    groupLabel: string;
    menus: Menu[];
};

export function getMenuList(pathname: string): Group[] {
    return [
        {
            groupLabel: "",
            menus: [
                // {
                //     href: "/property-management/dashboard",
                //     label: "Dashboard",
                //     active: pathname.includes("/dashboard"),
                //     icon: House,
                //     submenus: [],
                // },
                {
                    href: "/property-management/ai-assistant",
                    label: "Felicity AI",
                    active: pathname.includes("/ai-assistant") || pathname.includes("/knowledge"),
                    icon: Phone,
                    submenus: [],
                },
                {
                    href: "/property-management/work-order",
                    label: "Work Order",
                    active: pathname.includes("/work-order"),
                    icon: BriefcaseBusiness,
                    submenus: [],
                },
                {
                    href: "/property-management/properties",
                    label: "Properties",
                    active: pathname.includes("/properties"),
                    icon: Building,
                    submenus: [],
                },
                {
                    href: "/property-management/tenants",
                    label: "Tenants",
                    active: pathname.includes("/tenants"),
                    icon: User,
                    submenus: [],
                },
                {
                    href: "/property-management/calendar",
                    label: "Calendar",
                    active: pathname.includes("/calendar"),
                    icon: Calendar,
                    submenus: [],
                },
            ],
        },

        {
            groupLabel: "",
            menus: [
                {
                    href: "",
                    label: "Settings",
                    active: pathname.includes("/settings"),
                    icon: Settings,
                    submenus: [
                        {
                            href: "/property-management/settings/account",
                            label: "Profile",
                            active: pathname === "/property-management/settings/account",
                        },
                        {
                            href: "/property-management/teams",
                            label: "Teams",
                            active: pathname === "/property-management/teams",
                        },
                    ],
                },
            ],
        },
    ];
}
