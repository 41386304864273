// DashboardLayout.tsx
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import MobileNavigation from "../dashboard/contractor/navbar/mobileNavigation";
import styles from "./layoout.module.css";
import Sidebar from "../sideBar";
import { FiHome, FiMessageSquare } from "react-icons/fi";
import { CiCalendarDate } from "react-icons/ci";

import { FaSteamSymbol } from "react-icons/fa6";
import { MdBusinessCenter, MdOutlineAccountCircle } from "react-icons/md";
import { Settings } from "lucide-react";
import { BsStars } from "react-icons/bs";

const DashBoardslayout: React.FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    // const { handleSetState } = useContext(StateContext);

    // const updateJobListing = useCallback(
    //     (data: IJob[]) => {
    //         handleSetState({ jobListings: data });
    //     },
    //     [handleSetState],
    // );

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const [userDetailsResponse, jobListingsResponse] = await Promise.all([
    //                 ApiCall.sendGet("auth/me"),
    //                 ApiCall.sendGet("jobs"),
    //             ]);
    //             if (jobListingsResponse.status === 200) {
    //                 const result = jobListingsResponse.data;
    //                 const data = result.data.map((item: any) => ({
    //                     id: item._id,
    //                     date: item.createdAt,
    //                     title: item.jobDetail?.title,
    //                     description: item.jobDetail?.description,
    //                     photoUrl: item.jobDetail?.photoUrl,
    //                     clock: "",
    //                     address: item.address.building,
    //                 }));
    //                 updateJobListing(data);
    //             }

    //             console.log("User Details Response:", userDetailsResponse);
    //             console.log("Job Listings Response:", jobListingsResponse);
    //         } catch (error) {
    //             console.error("An error occurred during fetch:", error);
    //         }
    //     };
    //     fetchData();
    // }, [updateJobListing]);

    const PropertySideBar = [
        {
            label: "Dashboard",
            icon: <FiHome />,
            path: "/contractor/dashboard",
        },
        // {
        //     label: "Find Jobs",
        //     icon: <MdWorkOutline />,
        //     path: "/contractor/find-jobs",
        // },
        // {
        //     label: "Saved Jobs",
        //     icon: <FaRegSave />,
        //     path: "/contractor/saved-jobs",
        // },
        {
            label: "My Jobs",
            icon: <MdBusinessCenter />,
            path: "/contractor/my-jobs",
        },
        {
            label: "Messages",
            icon: <FiMessageSquare />,
            path: "/contractor/chat",
        },
        {
            label: "Felicity AI",
            icon: <BsStars />,
            path: "/contractor/ai-assistant",
        },
        {
            label: "Calendar",
            icon: <CiCalendarDate />,
            path: "/contractor/calendar",
        },
        {
            label: "Settings",
            icon: <Settings />,
            subMenu: [
                {
                    label: "Account",
                    icon: <MdOutlineAccountCircle />,
                    path: "/contractor/settings",
                },
                {
                    label: "Teams",
                    icon: <FaSteamSymbol />,
                    path: "/contractor/teams",
                },
            ],
        },
        // {
        //   label: "Notification",
        //   icon: <IoMdNotificationsOutline />,
        //   path: "notification",
        // },
    ];

    return (
        <div className="bg-[#F6F6F6]">
            <MobileNavigation isOpen={isOpen} setIsOpen={setIsOpen} />
            <div className={`${styles.propertyDashboardLayout} max-w-[1440px] mx-auto `}>
                <Sidebar links={PropertySideBar} />
                <Outlet />
            </div>
        </div>
    );
};

export default DashBoardslayout;
