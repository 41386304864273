import { useEffect, useState } from "react";
import styles from "./UploadJobImages.module.css";
import { Dialog } from "../../components/common/dialog";
import UploadJobImagesForm from "./UploadJobImagesForm";
import { useLocation } from "react-router-dom";
import { Button } from "../postAJob/button";
import { toast } from "react-toastify";
import { useGetNonUserJobQuery, useUpdateJobPhotosMutation } from "../../redux/rtk-query/jobsApis";
import Success from "./Success";
import NotFoundPage from "../notFoundPage";
import { TUpdateJob } from "../../types";
import { Logo } from "../../assets/icons";
import isEmptyObject from "../../utils/isEmptyObject";
import { NothingToSeePlaceholder } from "../../components/common/nothingToSeePlaceholder";
import formatAddress from "../../utils/formatAddress";

export default function UploadJobImages() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const jobId = searchParams.get("jobId");
    const token = searchParams.get("token");
    const { data: jobResult, isError } = useGetNonUserJobQuery(
        { jobId: jobId ?? undefined, token: token ?? undefined },
        {
            skip: !Boolean(jobId),
        },
    );
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [images, setImages] = useState<string[]>([]);
    const [newImages, setNewImages] = useState<string[]>([]);
    const [updateJobPhotos] = useUpdateJobPhotosMutation();
    const [response, setResponse] = useState(false);
    const [details, setDetails] = useState<any>(null);
    const [formData, setFormData] = useState<TUpdateJob>({
        title: "",
        description: "",
        address: "",
        createdAt: undefined,
        addressObj: {},
    });
    const [, setQuotes] = useState<string[]>([]);

    useEffect(() => {
        if (jobResult?.jobDetail?.photoUrl) {
            setImages(jobResult.jobDetail.photoUrl);
        }
        if (jobResult) {
            setFormData({
                title: jobResult.jobDetail?.title || "",
                description: jobResult.jobDetail?.description || "",
                address: formatAddress(jobResult?.address),
                createdAt: jobResult.startDate ? new Date(jobResult.startDate) : undefined,
                addressObj: jobResult.address,
            });
            setDetails(jobResult);
            const filteredQuotes = jobResult.jobBid.filter((bid: any) => !isEmptyObject(bid));
            setQuotes(filteredQuotes);
        }
    }, [jobResult, setImages]);
    const disableFields = jobResult?.status === "ACTIVE" || jobResult?.status === "CANCELLED";

    const onClickPostJob = async () => {
        if (!jobId) {
            toast.error("Invalid job ID");
            return;
        }
        if (!token) {
            toast.error("Invalid token");
            return;
        }
        if (newImages.length === 0 && images.length === 0) {
            toast.error("Upload at least one photo");
            return;
        }
        try {
            const payload =
                jobResult?.status === "ACTIVE"
                    ? {
                          _id: jobId,
                          status: "CANCELLED",
                      }
                    : {
                          _id: jobId,
                          slug: details.slug,
                          jobDetails: {
                              _id: details.jobDetail?._id,
                              jobId: details._id,
                              description: formData.description,
                              title: formData.title,
                              photoUrl: newImages,
                              supportingPhotos: details.jobDetail?.supportingPhotos || [],
                              rate: details.jobDetail?.rate || 0,
                              address: {
                                  _id: details.address?._id,
                                  refId: details.address?.refId,
                                  addressType: details.address?.addressType,
                                  mainStreet: details.address?.mainStreet,
                                  building: details.address?.building,
                                  tag: details.address?.tag,
                                  country: details.address?.country,
                                  city: details.address?.city,
                                  postalCode: details.address?.postalCode,
                                  timezone: details.address?.timezone,
                                  latitude: details.address?.latitude,
                                  longitude: details.address?.longitude,
                                  province: details.address?.province,
                              },
                          },
                          jobBidsId: details.jobBidsId,
                          dueDateTime: details.dueDate,
                          startDateTime:
                              formData.createdAt && new Date(formData.createdAt).toISOString(),
                          companyName: details.companyDetails?.name,
                          contractId: details.contractId,
                          callerName: details.callerEmail,
                          companyId: details.companyDetails?._id,
                          jobPriority: details.jobPriority,
                          postedBy: details.postedBy?._id,
                          callerPhonenumber: details.callerPhonenumber,
                          assignedTo: details.assignedTo,
                          contractType: details.contractType,
                          status: details.status,
                          propertyId: details.propertyId,
                          favoritedBy: details.isFavorited
                              ? [
                                    {
                                        _id: details.postedBy?._id,
                                        userId: details.postedBy?._id,
                                        deleted_at: null,
                                    },
                                ]
                              : [],
                          jobCategory: details.jobCategory,
                          message: "",
                          attachmentId: "",
                          addressId: details.addressId,
                          deleted_at: null,
                          jobType: details.jobType || "JOB",
                          metadata: [],
                      };
            const res = await updateJobPhotos({
                data: payload,
                token: token,
            });
            if ("error" in res) {
                toast.error("Failed to post job.");
            } else {
                setResponse(true);
                toast.success("Job posted successfully!");
            }
        } catch (error) {
            toast.error("Failed to post job.");
        } finally {
            setIsDialogOpen(false);
        }
    };
    if (!jobId || !token) return <NotFoundPage />;
    if (response) return <Success />;
    const companyDetailsAvailable = jobResult?.companyDetails;
    const assignedToInfoAvailable = jobResult?.assignedToInfo;

    return (
        <div>
            <div className={styles.navigation}>
                <Logo fill="#22BCFF" />
                {companyDetailsAvailable?.imageUrl && (
                    <img src={companyDetailsAvailable.imageUrl} alt="Company Logo" />
                )}
            </div>
            {!jobResult && !isError ? (
                <div className={styles.Loader}>Loading...</div>
            ) : isError ? (
                <div className={styles.header}>
                    <NothingToSeePlaceholder label="Link has been expired" />
                </div>
            ) : (
                <>
                    <header className={styles.header}>
                        <h1 className={styles.mainHeading}>Update Job</h1>
                        {!companyDetailsAvailable && assignedToInfoAvailable?.imageUrl && (
                            <div>
                                <img src={assignedToInfoAvailable.imageUrl} alt="Contractor" />
                            </div>
                        )}
                        {(companyDetailsAvailable || assignedToInfoAvailable) && (
                            <p>
                                Book with{" "}
                                <h3>
                                    {companyDetailsAvailable
                                        ? companyDetailsAvailable.name
                                        : `${assignedToInfoAvailable?.firstName} ${assignedToInfoAvailable?.lastName}`}
                                </h3>
                            </p>
                        )}
                    </header>
                    <div className={styles.main}>
                        <UploadJobImagesForm
                            disableFields={disableFields}
                            images={images}
                            setImages={setImages}
                            newImages={newImages}
                            setNewImages={setNewImages}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </div>
                    <footer className={styles.footer}>
                        <div className={styles.footerActionContainer}>
                            <Button
                                disabled={jobResult?.status === "CANCELLED"}
                                onClick={() => setIsDialogOpen(true)}
                            >
                                {jobResult?.status === "ACTIVE"
                                    ? "Cancel Job"
                                    : jobResult?.status === "CANCELLED"
                                      ? "Cancelled"
                                      : "Update Job"}
                            </Button>
                        </div>
                    </footer>
                    <Dialog
                        heading={jobResult?.status === "ACTIVE" ? "Cancel Job" : "Update Job"}
                        primaryButtonLabel="Confirm"
                        isOpen={isDialogOpen}
                        setIsOpen={setIsDialogOpen}
                        onClickPrimary={() => onClickPostJob()}
                        content={
                            <p className={styles.dialogText}>
                                {jobResult?.status === "ACTIVE"
                                    ? "Are you sure you want to cancel your job with the contractor ?"
                                    : "By submitting this form, you confirm that you have reviewed and agreed upon the updated job details with the contractor."}
                            </p>
                        }
                    />
                </>
            )}
        </div>
    );
}

// (
// <div className={styles.header}>
//     <NothingToSeePlaceholder label="Link has been expired" />
// </div>
// )
