import { useState } from "react";
import { Asterisks } from "../../../components/dashboard/Asterisks";
import styles from "./homeOwnerDashboard.module.css";

export const MySpendingStat = (props: {
    heading: string;
    stat: string;
    isFirstStat?: boolean;
    showStat?: boolean;
}) => {
    const [hasHover, setHasHover] = useState(false);
    return (
        <div
            className={`${styles.mySpendingStatContainer} ${
                props.isFirstStat ? "" : styles.mySpendingStatBorderLeft
            }`}
            style={{
                borderBottom: hasHover ? "1px solid #22BCFF" : "1px solid #FFFFFF",
            }}
            onMouseEnter={() => setHasHover(true)}
            onMouseLeave={() => setHasHover(false)}
        >
            <span className={styles.mySpendingStatHeading}>{props.heading}</span>
            <span className={styles.mySpendingStat}>
                {props.showStat ? (
                    <div className={styles.SpendingsAmount}>{props.stat}</div>
                ) : (
                    <Asterisks />
                )}
            </span>
        </div>
    );
};
