import { BackButton } from "../../../../../components/common/backButton";
import Button from "../../../../../components/common/button";
import { Heading } from "../../../../../components/common/pageHeading";
import styles from "./SetVoiceAssistant.module.css";

export function SetVoiceAssistant() {
    return (
        <>
            <div className={styles.TabBackBtn}>
                <BackButton />
            </div>
            <div className={styles.header}>
                <Heading>Set AI assistant voice</Heading>
                <div className={styles.btnWrapper}>
                    <button className={styles.previewBtn}>Preview</button>
                    <Button text="Save Changes" />
                </div>
            </div>
            <div className={styles.form}>
                <h6>Gender</h6>
                <div className={styles.radioItem}>
                    <input type="radio" name="gender" id="male" className={styles.radioInput} />
                    <label htmlFor="male" className={styles.customCheckbox}></label>
                    <span>Male Voice</span>
                </div>
                <div className={styles.radioItem}>
                    <input type="radio" name="gender" id="female" className={styles.radioInput} />
                    <label htmlFor="female" className={styles.customCheckbox}></label>
                    <span>Female Voice</span>
                </div>
                <h6>Style</h6>
                <div className={styles.radioItem}>
                    <input
                        type="radio"
                        name="style"
                        id="professional"
                        className={styles.radioInput}
                    />
                    <label htmlFor="professional" className={styles.customCheckbox}></label>
                    <span>Professional</span>
                </div>
                <div className={styles.radioItem}>
                    <input type="radio" name="style" id="casual" className={styles.radioInput} />
                    <label htmlFor="casual" className={styles.customCheckbox}></label>
                    <span>Casual</span>
                </div>
                <h6>Tone</h6>
                <div className={styles.radioItem}>
                    <input
                        type="radio"
                        name="tone"
                        id="informative"
                        className={styles.radioInput}
                    />
                    <label htmlFor="informative" className={styles.customCheckbox}></label>
                    <span>Informative - Neutral and clear</span>
                </div>
                <div className={styles.radioItem}>
                    <input type="radio" name="tone" id="upbeat" className={styles.radioInput} />
                    <label htmlFor="upbeat" className={styles.customCheckbox}></label>
                    <span>Upbeat - Positive and energetic</span>
                </div>
            </div>
        </>
    );
}
