import { getTokenFromAccountStorage } from "../utils/service";
import { config } from "./config";
import axios from "axios";

// Set default axios headers
axios.defaults.headers["Accept"] = "application/json";
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.baseURL = config.baseUrl;

class ProcessRequest {
    // Method to get the JWT token from localStorage
    private getToken() {
        // Replace 'tokenKey' with your actual localStorage key where the token is stored
        return localStorage.getItem("token");
    }

    private getTokenFromAccountStorage() {
        const account = localStorage.getItem("account");
        if (account) {
            const parsedAccount = JSON.parse(account);
            return { "x-api-key": parsedAccount.token };
        } else {
            return {};
        }
    }

    // Method to set headers dynamically for each request
    private setHeaders() {
        let token = this.getToken();
        if (!token) {
            token = getTokenFromAccountStorage();
        }
        // If token exists, set the 'x-api-key' header, else return an empty object
        return token ? { "x-api-key": token } : {};
    }

    // Updated method to send POST requests
    public sendPost(url: string, data: any) {
        const headers = this.setHeaders();
        return axios.post(url, data, { headers });
    }

    // New method to send GET requests
    public sendGet(url: string) {
        const headers = this.setHeaders();
        return axios.get(url, { headers });
    }

    // New method to complete account for PUT requests
    public completeAccount(url: string, data: any) {
        const headers = this.getTokenFromAccountStorage();
        return axios.put(url, data, { headers });
    }
}

const ApiCall = new ProcessRequest();
export default ApiCall;
export { axios };
