import { DialogModal } from "@/components/common/dialogModal";
import "react-phone-input-2/lib/style.css";
import { Button } from "@/components/ui/button";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Spinner } from "@/components/ui/spinner";
import { useInviteUsersMutation } from "@/redux/rtk-query/authApis";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { z } from "zod";
import { Input } from "@/components/ui/input";

interface InviteContractorFormProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const formSchema = z.object({
    firstName: z.string().min(2, { message: "fFirst name needs 2+ chars." }),
    lastName: z.string().min(2, { message: "lastName needs 2+ chars." }),
    email: z.string().email({ message: "Please enter a valid email address." }),
    phoneNumber: z.string().min(10, { message: "Phone number must be at least 10 digits." }),
});

export const InviteContractorForm: React.FC<InviteContractorFormProps> = ({ open, setOpen }) => {
    const phoneInputContainerRef = useRef<HTMLDivElement | null>(null);

    const [inviteUsers, { isLoading: isInviteUserLoading }] = useInviteUsersMutation();

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
        },
    });
    const { handleSubmit, getValues, trigger } = form;

    const onSubmit = async (data: any) => {
        try {
            await inviteUsers({ ...data, userType: "CONTRACTOR", status: "INVITED" }).unwrap();
            setOpen(false);
            toast.success("Invite Sent Successfully.");
            form.reset({
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
            });
        } catch (error) {
            console.error("Failed to add contractor:", error);
            setOpen(false);
            toast.error("Invite Sent Successfully.");
        }
    };

    const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();

            const values = getValues();
            const emailValue = values.email.trim();
            const phoneNumberValue = values.phoneNumber.trim();

            const isValid = await trigger();

            if (emailValue === "" || phoneNumberValue === "") {
                if (phoneNumberValue === "" && emailValue !== "") {
                    if (phoneInputContainerRef.current) {
                        const input = phoneInputContainerRef.current.querySelector(
                            'input[type="tel"]',
                        ) as HTMLInputElement;
                        if (input) {
                            setTimeout(() => {
                                input.focus();
                            }, 0);
                        }
                    }
                }
                return;
            }

            if (isValid) {
                handleSubmit(onSubmit)();
            }
        }
    };

    return (
        <DialogModal
            open={open}
            dialogTitle="Add Contractor"
            setOpen={setOpen}
            className="sm:max-w-[470px]"
        >
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                    <div className="py-4">
                        <div className="flex w-full gap-3">
                            <FormField
                                control={form.control}
                                name="firstName"
                                render={({ field }) => (
                                    <FormItem className="my-3">
                                        <FormLabel className="text-xs font-bold text-black">
                                            First Name
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                id="name"
                                                placeholder="Enter First Name"
                                                className="focus:ring-1 focus-visible:ring-gray-200 w-[200px] border border-gray-300 rounded-md"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage className="text-xs" />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="lastName"
                                render={({ field }) => (
                                    <FormItem className="my-3">
                                        <FormLabel className="text-xs font-bold text-black">
                                            Last Name
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                id="name"
                                                placeholder="Enter Last Name"
                                                className="focus:ring-1 focus-visible:ring-gray-200 rounded-md w-[200px] border border-gray-300"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage className="text-xs" />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem className="my-3">
                                    <FormLabel className="text-xs font-bold text-black">
                                        Email Address
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            id="email"
                                            placeholder="debra.holt@example.com"
                                            className="focus:ring-1 focus-visible:ring-gray-200 rounded-md w-[412px] border border-gray-300"
                                            {...field}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </FormControl>
                                    <FormMessage className="text-xs" />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="phoneNumber"
                            render={({ field }) => (
                                <FormItem className="my-3">
                                    <FormLabel className="text-xs text-black font-bold">
                                        Phone Number
                                    </FormLabel>
                                    <FormControl>
                                        <div ref={phoneInputContainerRef}>
                                            <PhoneInput
                                                country={"gb"}
                                                placeholder="Enter number"
                                                countryCodeEditable={false}
                                                value={field.value}
                                                onChange={field.onChange}
                                                inputClass="!pl-[70px] !w-[412px] !h-11 !rounded-md !border !border-solid !border-input !bg-background !px-3 !py-3 !text-sm !ring-offset-background !file:border-0 !file:bg-transparent !file:text-sm !file:font-medium !placeholder:text-muted-foreground !focus-visible:outline-none !focus-visible:ring-2 !focus-visible:ring-ring !focus-visible:ring-offset-2 !disabled:cursor-not-allowed !disabled:opacity-50 h-9"
                                                dropdownStyle={{
                                                    marginTop: "45px",
                                                    width: "410px",
                                                }}
                                                onKeyDown={handleKeyDown}
                                            />
                                        </div>
                                    </FormControl>
                                    <FormMessage className="text-xs" />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className="flex justify-end items-end ">
                        <Button
                            type="submit"
                            className="mt-4 bg-[#000] w-[150px]"
                            disabled={isInviteUserLoading}
                        >
                            {isInviteUserLoading && <Spinner className="text-white size-5 mr-1" />}
                            Add Contractor
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </DialogModal>
    );
};
