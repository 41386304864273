import { useMemo, useState } from "react";

type TableColumn = {
    accessorKey: string;
    header: string;
    cell: ({ row }: any) => JSX.Element;
};

type TableSortAndFilterProps = {
    head: any;
    rows: any;
};

export default function useTableSortAndFilter({ head, rows }: TableSortAndFilterProps) {
    const [sortColumn, setSortColumn] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<string>("asc");
    const [visibleColumns, setVisibleColumns] = useState<TableColumn[]>(head);

    const handleSort = (column: string) => {
        const direction = sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
        setSortColumn(column);
        setSortDirection(direction);
    };

    const handleToggleColumn = (column: string, key: string) => {
        setVisibleColumns((prev: any) => {
            const columnExists = prev.some((col: any) => col.accessorKey === column);

            if (columnExists) {
                return prev.filter((col: any) => col.accessorKey !== column);
            } else {
                const newColumns = [...prev];

                const insertIndex = head.findIndex((data: any) => data.header === key);

                newColumns.splice(
                    insertIndex,
                    0,
                    head.find((__: any, index: any) => index === insertIndex),
                );

                return newColumns;
            }
        });
    };

    const sortedTableRowItems = useMemo(() => {
        if (!rows?.length) {
            console.log("No rows to sort");
            return [];
        }

        const sorted = rows.slice().sort((a: any, b: any) => {
            if (sortColumn) {
                const aValue = a[sortColumn] || "";
                const bValue = b[sortColumn] || "";

                if (sortDirection === "asc") {
                    return String(aValue).localeCompare(String(bValue));
                } else {
                    return String(bValue).localeCompare(String(aValue));
                }
            }
            return 0;
        });

        console.log("Sorted rows:", sorted);
        return sorted;
    }, [rows, sortColumn, sortDirection]);

    return {
        sortedTableRowItems,
        handleToggleColumn,
        handleSort,
        visibleColumns,
        sortDirection,
        sortColumn,
    };
}
