import { Footer } from "./Footer";
import { HorizontalStats } from "../horizontalStats";
import styles from "./smallJobCard.module.css";
import { memo } from "react";
import { ProfileWithPrice } from "./ProfileWithPrice";

type TProps = {
    hasMiddleStats?: boolean;
    onClickPrimaryButton: () => void;
    onClickCard?: () => void;
};

export const SmallJobCard = memo((props: TProps) => {
    const { hasMiddleStats = false, onClickPrimaryButton, onClickCard } = props;

    return (
        <div className={styles.suggestionCardContainer}>
            <ProfileWithPrice onClick={onClickCard} />
            {hasMiddleStats && <HorizontalStats onClick={onClickCard} />}
            <Footer onClickPrimaryButton={onClickPrimaryButton} onClickLabel={onClickCard} />
        </div>
    );
});
