import { toast } from "react-toastify";
import { catchAll } from "../../utils/catch";
import { api } from "./api";

const createCalendar = api.injectEndpoints({
    endpoints: (builder) => ({
        createCalendar: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `jobs/calendar-events`,
                    body: data,
                    method: "POST",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Something Went wrong! please try again later.");
                    return;
                }
            },
        }),
    }),
});

const getEvents = api.injectEndpoints({
    endpoints: (builder) => ({
        getEvents: builder.query<any, any>({
            query: () => {
                return {
                    url: `jobs/calendar-events`,
                    method: "GET",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    // toast.error("Failed to get Messages");
                    return;
                }
                // api.dispatch(addPostJobImages(response.data.data.url));
            },
        }),
    }),
});

export const { useCreateCalendarMutation } = createCalendar;
export const { useGetEventsQuery } = getEvents;
