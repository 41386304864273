import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import WorkOrder, { TGetAllJobs } from "@/types";
import dayjs from "dayjs";
import CallSummaryCard from "./CallSummryCard";
import JobItem from "./JobItem";
import { NothingToSeePlaceholder } from "@/components/common/nothingToSeePlaceholder";
import { Skeleton } from "@/components/ui/skeleton";

type Props = {
    jobs: WorkOrder[];
    isLoading: boolean;
};

function screamToTitleCase(str: string) {
    return str.toLowerCase().replace(/(^|_)(\w)/g, (_, separator, char) => {
        return (separator ? " " : "") + char.toUpperCase();
    });
}

export default function Jobs({ jobs = [], isLoading }: Props) {
    // console.log(jobs.length || isLoading, jobs, "OOOO");

    return (
        <Tabs defaultValue="maintain" className="w-full">
            <TabsList className="bg-gray-200">
                <TabsTrigger className="bg-gray-200" value="maintain">
                    Work Order ({jobs.length || 0})
                </TabsTrigger>
                {/* <TabsTrigger className="bg-gray-200" value="inquiry">
                    For Inquiry (2)
                </TabsTrigger> */}
            </TabsList>
            <TabsContent className="space-y-5" value="maintain">
                {isLoading ? (
                    <>
                        <JobItem isLoading={isLoading} id="" />
                        <JobItem isLoading={isLoading} id="" />
                    </>
                ) : jobs.length ? (
                    jobs.map((job) => {
                        console.log("Inside the map func", job);
                        return (
                            <JobItem
                                key={job._id}
                                id={job._id}
                                name={job?.jobDetail?.title || job.jobCategory || ""}
                                date={dayjs(job.dueDate).format("DD MMMM YYYY, HH:MM")}
                                contractor={job?.assignedTo ? "Assigned" : "Not Assigned"}
                                urgency={screamToTitleCase(job.jobPriority)}
                                frequency="One time"
                                status={screamToTitleCase(job.status)}
                                callSummary={
                                    job.jobDetail?.description || "No description available"
                                }
                                isLoading={isLoading}
                            />
                        );
                    })
                ) : (
                    <NothingToSeePlaceholder
                        className="drop-shadow-md !rounded-[12px]"
                        label="no jobs found!"
                        height={650}
                    />
                )}
            </TabsContent>
            <TabsContent className="grid grid-cols-1 gap-5" value="inquiry">
                {isLoading ? (
                    <>
                        <Skeleton className="h-16 w-full rounded-md" />
                        <Skeleton className="h-16 w-full rounded-md" />
                    </>
                ) : (
                    <>
                        <CallSummaryCard
                            date="20 Aug 2024, 01:46 PM"
                            callDescription="Holt Debra reported that their heater is turning on but not producing any heat. The AI Assistant asked about the urgency of the issue and confirmed it was a minor inconvenience but still needed timely attention. A maintenance visit has been scheduled for either after 4 PM on a weekday or at a convenient time during the weekend."
                        />
                        <CallSummaryCard
                            date="20 Aug 2024, 01:46 PM"
                            callDescription="Holt Debra reported that their heater is turning on but not producing any heat. The AI Assistant asked about the urgency of the issue and confirmed it was a minor inconvenience but still needed timely attention. A maintenance visit has been scheduled for either after 4 PM on a weekday or at a convenient time during the weekend."
                        />
                    </>
                )}
            </TabsContent>
        </Tabs>
    );
}
