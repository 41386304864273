export function StatusCancelledIcon() {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7 0.5C5.71442 0.5 4.45772 0.881218 3.3888 1.59545C2.31988 2.30968 1.48676 3.32484 0.994786 4.51256C0.502816 5.70028 0.374095 7.00721 0.624899 8.26809C0.875703 9.52896 1.49477 10.6872 2.40381 11.5962C3.31285 12.5052 4.47104 13.1243 5.73192 13.3751C6.99279 13.6259 8.29973 13.4972 9.48744 13.0052C10.6752 12.5132 11.6903 11.6801 12.4046 10.6112C13.1188 9.54229 13.5 8.28558 13.5 7C13.4967 5.27711 12.8108 3.62573 11.5925 2.40746C10.3743 1.18918 8.72289 0.503304 7 0.5ZM9.35625 8.64375C9.45014 8.73855 9.50282 8.86658 9.50282 9C9.50282 9.13342 9.45014 9.26145 9.35625 9.35625C9.26068 9.44866 9.13294 9.50032 9 9.50032C8.86706 9.50032 8.73932 9.44866 8.64375 9.35625L7 7.70625L5.35625 9.35625C5.26068 9.44866 5.13294 9.50032 5 9.50032C4.86706 9.50032 4.73932 9.44866 4.64375 9.35625C4.54986 9.26145 4.49719 9.13342 4.49719 9C4.49719 8.86658 4.54986 8.73855 4.64375 8.64375L6.29375 7L4.64375 5.35625C4.56402 5.2591 4.52328 5.13577 4.52944 5.01024C4.53561 4.88472 4.58824 4.76597 4.67711 4.67711C4.76598 4.58824 4.88472 4.5356 5.01025 4.52944C5.13577 4.52327 5.2591 4.56402 5.35625 4.64375L7 6.29375L8.64375 4.64375C8.7409 4.56402 8.86423 4.52327 8.98976 4.52944C9.11528 4.5356 9.23403 4.58824 9.3229 4.67711C9.41177 4.76597 9.4644 4.88472 9.47057 5.01024C9.47673 5.13577 9.43598 5.2591 9.35625 5.35625L7.70625 7L9.35625 8.64375Z"
                fill="#7A7493"
            />
        </svg>
    );
}
