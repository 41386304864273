import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { TGetAIJobRecommendationSuccess, TUser } from "../../types";
import { catchAll } from "../../utils/catch";
import {
    setAIRecommendation,
    setPostJobToInitialStateExceptPrompt,
} from "../features/postJobSlice";
import { api } from "./api";
import { getUserBasePath } from "../../utils/getBaseUserBasedPath";

const aiApis = api.injectEndpoints({
    endpoints: (builder) => ({
        getAIJobRecommendation: builder.mutation<
            TGetAIJobRecommendationSuccess,
            { prompt: string; myDetails: TUser | undefined; navigate?: NavigateFunction }
        >({
            query: ({ prompt }) => {
                return {
                    url: "jobs/ai",
                    method: "POST",
                    body: {
                        prompt,
                    },
                };
            },
            onQueryStarted: async (args, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to get AI response");
                    api.dispatch(setPostJobToInitialStateExceptPrompt());
                } else {
                    const data = response.data.data.Answer;
                    api.dispatch(setAIRecommendation({ ...data }));
                }

                if (!args.myDetails) {
                    toast.error("Failed to get user details. Try again");
                    return;
                }

                const basePath = getUserBasePath(args.myDetails.userType);

                args.navigate && args.navigate(basePath + "/post-job/edit");
            },
        }),
    }),
});

const aiAssistantApis = api.injectEndpoints({
    endpoints: (builder) => ({
        getCallLogs: builder.query<any, { contractorId: string; limit: number; page: number }>({
            query: ({ contractorId, limit, page }) => {
                return {
                    url: `ai-assistant/calls/${contractorId}`,
                    method: "GET",
                    params: {
                        limit,
                        page,
                    },
                };
            },
            transformResponse: (response: any) => {
                return {
                    data: response.data,
                    total: response.total,
                    page: response.page,
                    limit: response.limit,
                    average_call_length: response.average_call_length,
                    daily_calls: response.daily_calls,
                };
            },
        }),
        getConversations: builder.query<any, any>({
            query: ({ callId }) => {
                return {
                    url: `ai-assistant/calls/conversations/${callId}`,
                    method: "GET",
                };
            },
            transformResponse: (response: any) => {
                return response[0].messages;
            },
        }),
        getCallBooking: builder.query<any, any>({
            query: ({ callId }) => ({
                url: `jobs/call-booking/${callId}`,
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                await catchAll(queryFulfilled);
            },
        }),
    }),
});

export const { useGetAIJobRecommendationMutation } = aiApis;
export const { useGetCallLogsQuery, useGetConversationsQuery, useGetCallBookingQuery } =
    aiAssistantApis;

//     getCallBooking: builder.query<any, any>({
//         query: ({ callId }) => ({
//             url: `jobs/call-booking/CAc82bf1635126318780c4041ba09ed6c9`,
//         }),
//         onQueryStarted: async (_, { queryFulfilled }) => {
//             await catchAll(queryFulfilled);
//         },
//     }),
// }),
