import { memo } from "react";

export const WindowIcon = memo(() => {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 3.25h5.25a1.5 1.5 0 011.5 1.5v10.5a1.5 1.5 0 01-1.5 1.5H10m0-13.5v13.5m0-13.5H4.75a1.5 1.5 0 00-1.5 1.5v10.5a1.5 1.5 0 001.5 1.5H10"
                stroke="#1F1F1F"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
});
