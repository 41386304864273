export const Moveicon = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_71_27701)">
                <path
                    d="M9.3125 7.5C10.2445 7.5 11 6.74448 11 5.8125C11 4.88052 10.2445 4.125 9.3125 4.125C8.38052 4.125 7.625 4.88052 7.625 5.8125C7.625 6.74448 8.38052 7.5 9.3125 7.5Z"
                    fill="#667085"
                />
                <path
                    d="M16.0625 7.5C16.9945 7.5 17.75 6.74448 17.75 5.8125C17.75 4.88052 16.9945 4.125 16.0625 4.125C15.1305 4.125 14.375 4.88052 14.375 5.8125C14.375 6.74448 15.1305 7.5 16.0625 7.5Z"
                    fill="#667085"
                />
                <path
                    d="M9.3125 13.875C10.2445 13.875 11 13.1195 11 12.1875C11 11.2555 10.2445 10.5 9.3125 10.5C8.38052 10.5 7.625 11.2555 7.625 12.1875C7.625 13.1195 8.38052 13.875 9.3125 13.875Z"
                    fill="#667085"
                />
                <path
                    d="M16.0625 13.875C16.9945 13.875 17.75 13.1195 17.75 12.1875C17.75 11.2555 16.9945 10.5 16.0625 10.5C15.1305 10.5 14.375 11.2555 14.375 12.1875C14.375 13.1195 15.1305 13.875 16.0625 13.875Z"
                    fill="#667085"
                />
                <path
                    d="M9.3125 20.25C10.2445 20.25 11 19.4945 11 18.5625C11 17.6305 10.2445 16.875 9.3125 16.875C8.38052 16.875 7.625 17.6305 7.625 18.5625C7.625 19.4945 8.38052 20.25 9.3125 20.25Z"
                    fill="#667085"
                />
                <path
                    d="M16.0625 20.25C16.9945 20.25 17.75 19.4945 17.75 18.5625C17.75 17.6305 16.9945 16.875 16.0625 16.875C15.1305 16.875 14.375 17.6305 14.375 18.5625C14.375 19.4945 15.1305 20.25 16.0625 20.25Z"
                    fill="#667085"
                />
            </g>
            <defs>
                <clipPath id="clip0_71_27701">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
