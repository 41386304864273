import styles from "./quotes.module.css";
import { Rating } from "../../../../components/common/smallJobCard/Rating";
import { Button } from "../../../postAJob/button";
import { memo } from "react";
import Skeleton from "react-loading-skeleton";

type TFooterProps = Pick<
    TQuoteCardProps,
    "onClickAccept" | "onClickViewDetails" | "_id" | "userId" | "isAddingContractor"
>;

type TQuoteCardProps = {
    price: number;
    onClickAccept: (quoteId: string, userId: string) => void;
    onClickViewDetails: (quoteId: string) => void;
    _id: string;
    userId: string;
    isAddingContractor: boolean;
    createdAt: string;
    name: string;
};

type TQuoteCardRightProps = Pick<TQuoteCardProps, "price" | "createdAt">;

const QuoteCardLeft = memo((props: Pick<TQuoteCardProps, "name">) => {
    const { name } = props;
    return (
        <div className={styles.quoteCardLeft}>
            <img
                className={styles.quoteCardImage}
                src="https://via.placeholder.com/500x500"
                alt="quote avatar"
            />
            <div>
                <p className={styles.quoteCardName}>{name}</p>
                <p className={styles.quoteCardCountry}>United Kingdom</p>
                <Rating />
            </div>
        </div>
    );
});

const QuoteCardRight = memo((props: TQuoteCardRightProps) => {
    const { createdAt, price } = props;
    return (
        <div>
            <p className={styles.quoteCardOfferedPriceLabel}>Offered Price</p>
            <p className={styles.quoteCardOfferedPrice}>£{price}</p>
            <p className={styles.quoteCardDate}>{createdAt}</p>
        </div>
    );
});

const Footer = memo((props: TFooterProps) => {
    const { onClickAccept, onClickViewDetails, _id, userId, isAddingContractor } = props;

    return (
        <footer className={styles.quoteCardFooter}>
            <Button variant="secondary-with-shadow" onClick={() => onClickViewDetails(_id)}>
                View Details
            </Button>
            <Button
                onClick={() => onClickAccept(_id, userId)}
                variant="primary"
                isLoading={isAddingContractor}
            >
                Accept
            </Button>
        </footer>
    );
});

export const QuoteCard = memo((props: TQuoteCardProps) => {
    const {
        _id,
        onClickAccept,
        onClickViewDetails,
        price,
        userId,
        isAddingContractor,
        createdAt,
        name,
    } = props;

    return (
        <div className={styles.quoteCardContainer}>
            <div className={styles.quoteCardContent}>
                <QuoteCardLeft name={name} />
                <QuoteCardRight price={price} createdAt={createdAt} />
            </div>
            <Footer
                _id={_id}
                userId={userId}
                onClickAccept={onClickAccept}
                onClickViewDetails={onClickViewDetails}
                isAddingContractor={isAddingContractor}
            />
        </div>
    );
});

//	=======================================================================
//								        SKELETON
//	=======================================================================

const FooterSkeleton = () => {
    return (
        <footer className={styles.quoteCardFooter}>
            <Skeleton width={135} height={34} />
            <Skeleton width={99} height={34} />
        </footer>
    );
};

const QuoteCardLeftSkeleton = () => {
    return (
        <div className={styles.quoteCardLeft} style={{ width: "70%" }}>
            <Skeleton className={styles.quoteCardImage} />

            <div>
                <Skeleton className={styles.quoteCardName} width={"100%"} />
                <Skeleton className={styles.quoteCardCountry} width={"100%"} />
                <Skeleton className={styles.quoteCardCountry} width={"10%"} />
            </div>
        </div>
    );
};

const QuoteCardRightSkeleton = () => {
    return (
        <div style={{ width: "20%" }}>
            <Skeleton className={styles.quoteCardOfferedPriceLabel} height={21} width={"100%"} />
            <Skeleton className={styles.quoteCardOfferedPrice} height={28} width={"100%"} />
            <Skeleton className={styles.quoteCardDate} height={21} width={"100%"} />
        </div>
    );
};

export const QuoteCardSkeleton = () => {
    return (
        <div className={styles.quoteCardContainer}>
            <div className={styles.quoteCardContent}>
                <QuoteCardLeftSkeleton />
                <QuoteCardRightSkeleton />
            </div>
            <FooterSkeleton />
        </div>
    );
};
