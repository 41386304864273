import React from "react";

import DashBoard from "@/components/common/dashboard/DashBoard";
import {
    TableHeadItems2,
    TableHeadItemsCompliance2,
} from "@/pages/propertyManagementDashboard/propertyManagementDashboard.constant";
import MainTainanceRow from "@/pages/propertyManagementDashboard/ui/MainTainanceRow";
import ComplianceRow from "@/pages/propertyManagementDashboard/ui/ComplianceRow";
import { chartConfig } from "./ContractorDashboard.constant";
import { BoxContent } from "@/components/common/Box/BoxContent";
import DashBoardMap from "@/pages/propertyManagementDashboard/DashBoardMap";
import BoxSegment from "@/components/common/Box/BoxSegment";

interface AnalysisData {
    title: string;
    profit: boolean;
    profitNumber: number;
    data: number;
}

interface BoxDataItem {
    children: React.ReactNode;
    ismap: boolean;
}

const ContractorDashboard: React.FC = () => {
    // const [, setSearchTerm] = useState("");

    // const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setSearchTerm(event.target.value);
    // };

    // const noClickMaintenanceRow = useCallback(
    //     (rowItem: TTableRows) => {
    //         if (typeof rowItem?.[0]?.value === "string") {
    //             navigate(`/property-management/jobs/${rowItem[0].value}`);
    //         }
    //     },
    //     [navigate],
    // );

    // Define the analysis data
    const AnalysisData: AnalysisData[] = [
        {
            title: "Total Calls",
            profit: true,
            profitNumber: 19.5,
            data: 0,
        },
        {
            title: "Total Bookings",
            profit: false,
            profitNumber: 20.2,
            data: 0,
        },
        {
            title: "Time Saved",
            profit: true,
            profitNumber: 45.5,
            data: 0,
        },
    ];

    // Prepare data for BoxSegment
    const BoxData: BoxDataItem[] = [
        ...AnalysisData.map((item) => ({
            children: <BoxContent {...item} />,
            ismap: false,
        })),
        {
            ismap: true,
            children: <DashBoardMap />,
        },
    ];

    const TableData = {
        data: [
            { header: TableHeadItems2, row: MainTainanceRow(), heading: "All Calls" },
            { header: TableHeadItemsCompliance2, row: ComplianceRow(), heading: "Bookings" },
            { header: TableHeadItemsCompliance2, row: ComplianceRow(), heading: "Enquiries" },
        ],
        mainHeading: "Recent calls",
    };

    const graphHeading = {
        heading1: "Money Saved",
        heading2: "Call Type",
    };

    const PiechartData = [
        {
            propertyStatLabel: "Enquiry",
            stat: 275,
            fill: "var(--color-chrome)",
        },
        { propertyStatLabel: "Booking", stat: 200, fill: "var(--color-safari)" },
        { propertyStatLabel: "Other", stat: 173, fill: "var(--color-edge)" },
    ];

    const BarchartData = [
        { month: "Sunday", Profit: 186, Loss: 80 },
        { month: "Monday", Profit: 120, Loss: 150 },
        { month: "Tuesday", Profit: 130, Loss: 120 },
        { month: "Wednesday", Profit: 73, Loss: 190 },
        { month: "Thursday", Profit: 209, Loss: 130 },
        { month: "Friday", Profit: 214, Loss: 140 },
    ];
    return (
        <DashBoard
            TableData={TableData}
            graphHeading={graphHeading}
            PiechartData={PiechartData}
            totalNumber={123}
            BarChartData={{ BarchartData, chartConfig }}
            accountType="contractor"
        >
            <BoxSegment BoxData={BoxData} />
        </DashBoard>
    );
};

export default ContractorDashboard;
