import { IConversation, TUser } from "@/types";
import { getRecipient } from "@/utils/getRecipent";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from "@/components/ui/tooltip";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { AvatarImage } from "@radix-ui/react-avatar";

type TProps = {
    conversation: IConversation;
    currentUser?: TUser;
    onClickUser: Function;
    isSelected: boolean;
};

export const UserCardSmall = ({
    conversation,
    isSelected = false,
    currentUser,
    onClickUser,
}: TProps) => {
    const recipientUser = getRecipient(conversation.participantDetails, currentUser);
    return (
        <TooltipProvider>
            <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                    <div
                        onClick={() => {
                            onClickUser(conversation._id);
                        }}
                        className={`p-0 px-0 cursor-pointer ${cn(
                            buttonVariants({
                                variant: "ghost",
                                size: "icon",
                            }),
                            "h-11 w-11 md:h-16 md:w-16 p-0 hover:bg-gray-100 dark:hover:bg-gray-700",
                            isSelected && "shadow-md bg-gray-100 border-none dark:bg-gray-600",
                        )}`}
                    >
                        <Avatar className="ring-1 ring-gray-200 dark:ring-gray-700">
                            <AvatarImage src=""></AvatarImage>
                            <AvatarFallback className="text-sm text-white bg-neutral-700">
                                {recipientUser?.firstName.slice(0, 1).toUpperCase()}
                                {recipientUser?.lastName.slice(0, 1).toUpperCase()}
                            </AvatarFallback>
                        </Avatar>
                        <span className="sr-only">
                            {recipientUser?.firstName} {recipientUser?.lastName}
                        </span>
                    </div>
                </TooltipTrigger>
                <TooltipContent side="right" className="flex items-center gap-4">
                    {recipientUser?.firstName} {recipientUser?.lastName}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};
