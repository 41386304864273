import { memo, useCallback, useMemo, useState } from "react";
import { CalendarIcon } from "../../../assets/icons/CalendarIcon";
import { Divider } from "../../../components/common/divider";
import { Dropdown, DropdownZIndex } from "../../../components/common/dropdown";
import { TDropdownItem } from "../../../components/common/dropdown/Dropdown.types";
import {
    TPostJobStateKeys,
    removeImageFromPostJob,
    removePostJobCategory,
    setPostJobFormItemState,
} from "../../../redux/features/postJobSlice";
import { useGetPropertiesQuery } from "../../../redux/rtk-query/properties";
import { useUploadToAWSMutation } from "../../../redux/rtk-query/uploadApis";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { AIPromptCategories } from "../AIPromptCategories";
import { CurrencyIcon } from "../CurrencyIcon";
import { DatePickerWithLabel } from "../DatePickerWithLabel";
import { InputWithLabel } from "../InputWithLabel";
import { TextareaWithLabel } from "../TextareaWithLabel";
import styles from "./confirmJobDetails.module.css";
import { UploadButton } from "./UploadButton";
import dayjs from "dayjs";
import { Button } from "../button";
import { Map } from "../../../components/common/map";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";

type TProps = {
    hasPropertyDropdown: boolean;
    hasDueDate: boolean;
    hasBudget: boolean;
};

export const ConfirmJobDetailsForm = memo((props: TProps) => {
    const { hasDueDate, hasPropertyDropdown, hasBudget } = props;
    const { data: userDetails } = useGetMyDetailsQuery();

    const dispatch = useAppDispatch();

    const { categories, description, title, date, budget, address, images, ...rest } =
        useAppSelector((state) => state.postJob);

    const [isMapOpen, setIsMapOpen] = useState(false);

    const { data: properties } = useGetPropertiesQuery(userDetails?.companyId!, {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        skip: !userDetails?.companyId,
    });

    const propertiesAsDropdownItems: TDropdownItem[] = useMemo(() => {
        if (!properties) return [];

        return properties.map((property: any) => {
            return {
                value: property._id,
                key: property.name,
            };
        });
    }, [properties]);

    const [uploadImageToAWS, { isLoading }] = useUploadToAWSMutation();

    const onChangeFormItem = useCallback(
        (value: string | TDropdownItem, name: TPostJobStateKeys) => {
            dispatch(
                setPostJobFormItemState({
                    name,
                    value,
                }),
            );
        },
        [dispatch],
    );

    const handleRemoveImage = useCallback(
        (index: number) => {
            dispatch(removeImageFromPostJob(index));
        },
        [dispatch],
    );
    const onClickRemoveCategory = useCallback(
        (category: string) => {
            dispatch(removePostJobCategory(category));
        },
        [dispatch],
    );

    const handleOnClickUploadImage: React.ChangeEventHandler<HTMLInputElement> = useCallback(
        async (e) => {
            const formData = new FormData();
            if (e.target.files) {
                const files = Array.from(e.target.files as any) as File[];
                formData.append("file", files[0]);
                formData.append("folder", "jobs");
                uploadImageToAWS(formData);
            }
        },
        [uploadImageToAWS],
    );

    const onAddressSelect = useCallback(
        (address: string) => {
            onChangeFormItem(address, "address");
        },
        [onChangeFormItem],
    );

    const onMapClose = useCallback(() => {
        setIsMapOpen(false);
    }, []);

    const onClickMapButton = useCallback(() => {
        setIsMapOpen(true);
    }, []);

    return (
        <form className={styles.form}>
            <InputWithLabel
                labelProps={{
                    text: "Title",
                }}
                inputProps={{
                    error: {},
                    name: "title",
                    placeholder:
                        "House Cleaning Needed This Saturday (April 13th) at 3 PM in East london",
                    required: false,
                    type: "text",
                    value: title,
                    handleChange: (e) => onChangeFormItem(e.target.value, "title"),
                }}
            />

            {hasPropertyDropdown && (
                <Dropdown
                    width={"target"}
                    items={propertiesAsDropdownItems}
                    onClick={(v) => onChangeFormItem(v, "property")}
                >
                    <InputWithLabel
                        labelProps={{ text: "Property" }}
                        inputProps={{
                            required: true,
                            name: "property",
                            placeholder: "Select a property",
                            type: "text",
                            handleChange: () => {},
                            value: rest?.property?.value ?? "",
                            style: { zIndex: DropdownZIndex.DROPDOWN },
                        }}
                        inputContainerProps={{ style: { margin: 0 } }}
                    />
                </Dropdown>
            )}

            <TextareaWithLabel
                labelProps={{
                    text: "Description",
                }}
                textAreaProps={{
                    error: {},
                    handleChange: (e) => onChangeFormItem(e.target.value, "description"),
                    name: "description",
                    required: false,
                    placeholder:
                        "I'm looking for a reliable and experienced contractor to clean my house in Lagos this Saturday, April 13th, at 3 PM.",
                    value: description,
                }}
            />

            <AIPromptCategories
                label="Skills"
                categories={categories}
                onClickRemoveCategory={onClickRemoveCategory}
            />

            <Divider />

            <div className={styles.addressAndMapButton}>
                <InputWithLabel
                    labelProps={{
                        text: "Location",
                    }}
                    inputProps={{
                        required: false,
                        error: {},
                        name: "address",
                        placeholder: "Enter your location",
                        type: "text",
                        value: address,
                        handleChange: (e) => onChangeFormItem(e.target.value, "address"),
                    }}
                />

                <Button
                    style={{ height: 47 }}
                    variant="secondary-with-shadow"
                    onClick={onClickMapButton}
                >
                    Map
                </Button>
            </div>

            <DatePickerWithLabel
                variant={hasDueDate ? "date-time" : "date"}
                labelProps={{ text: "Start Date Time" }}
                datePickerProps={{
                    placeholder: "Enter start date & time",
                    rightSection: <CalendarIcon />,
                    onChange: (value) => onChangeFormItem(value?.toString() ?? "", "date"),
                    value: new Date(date),
                    minDate: new Date(),
                }}
            />

            {hasDueDate && (
                <DatePickerWithLabel
                    variant={"date-time"}
                    labelProps={{ text: "Due Date Time" }}
                    datePickerProps={{
                        placeholder: "Enter due date & time",
                        rightSection: <CalendarIcon />,
                        onChange: (value) => onChangeFormItem(value?.toString() ?? "", "dueDate"),
                        value: rest?.dueDate ? new Date(rest?.dueDate) : new Date(),
                        minDate: date ? dayjs(date).add(1, "minute").toDate() : new Date(),
                    }}
                />
            )}

            {hasBudget && (
                <InputWithLabel
                    labelProps={{
                        text: "Budget",
                    }}
                    inputProps={{
                        required: false,
                        error: {},
                        name: "budget",
                        placeholder: "Enter your budget",
                        type: "number",
                        value: budget,
                        handleChange: (e) => onChangeFormItem(e.target.value, "budget"),
                    }}
                    icon={<CurrencyIcon />}
                />
            )}

            <Map isOpen={isMapOpen} onAddressSelect={onAddressSelect} onClose={onMapClose} />

            <Divider />
            <UploadButton
                handleImageChange={handleOnClickUploadImage}
                images={images}
                isLoading={isLoading}
                disabled={false}
                handleRemoveImage={handleRemoveImage}
            />
        </form>
    );
});
