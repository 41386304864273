import { Progress } from "@/components/ui/progress";

type TProps = {
    mainStatLabel: string;
    mainStatValue: number;
    progressStat1Label: string;
    progressStat1Value: number;
    progressStat2Value: number;
    progressStat2Label: string;
};

export const StatCard = ({
    mainStatLabel,
    mainStatValue,
    progressStat1Label,
    progressStat1Value,
    progressStat2Label,
    progressStat2Value,
}: TProps) => {
    const value = Math.round((progressStat1Value / mainStatValue) * 100);
    return (
        <div className="isolate border border-zinc-200 shadow-[0px_1px_3px_rgba(0,0,0,0.1),0px_1px_2px_rgba(0,0,0,0.06)] rounded-xl border-solid bg-white p-6 flex space-x-[22%] mb-6">
            <div>
                <p className="font-medium text-sm leading-[17px] text-[#09090B] mb-2">
                    {mainStatLabel}
                </p>
                <p className="font-bold text-2xl leading-[29px] text-[#09090B]">{mainStatValue}</p>
            </div>
            <div className="w-[31.85%]">
                <Progress value={value} className="mb-2 w-full" />
                <div className="flex items-center justify-center space-x-8">
                    <span className="font-normal text-xs text-zinc-500">
                        {progressStat1Label}
                        <span className="text-sm leading-5 text-[#101010] ml-2">
                            {progressStat1Value}
                        </span>
                    </span>
                    <span className="font-normal text-xs text-zinc-500">
                        {progressStat2Label}
                        <span className="text-sm leading-5 text-[#101010] ml-2">
                            {progressStat2Value}
                        </span>
                    </span>
                </div>
            </div>
        </div>
    );
};
