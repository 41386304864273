import { memo } from "react";
import styles from "./Chip.module.css";

type TChipColors = "green" | "gray" | "blue" | "white" | "red" | "yellow";

type TProps = {
    color: TChipColors;
    text: string;
    onClick?: (text: string) => void;
};

const COLOR_STYLES: Record<TChipColors, string> = {
    green: styles.chipGreen,
    gray: styles.chipGray,
    blue: styles.chipBlue,
    white: styles.chipWhite,
    red: styles.chipRed,
    yellow: styles.chipYellow,
};

export const Chip = memo(({ text, color, onClick }: TProps) => {
    const colorStyles = COLOR_STYLES[color];

    return (
        <p
            className={`${styles.chip} ${colorStyles}`}
            style={{ cursor: onClick ? "pointer" : "auto" }}
            onClick={() => onClick && onClick(text)}
        >
            {text}
        </p>
    );
});
