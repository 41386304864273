import { Button } from "@/components/ui/button";
import { Heading } from "../../../components/common/pageHeading";
import styles from "../Settings.module.css";

export const PayoutHeader = () => (
    <header className={styles.payoutHeader}>
        <Heading>Payout</Heading>
        <Button className="bg-[#000]">Withdraw</Button>
    </header>
);
