import { Fragment } from "react/jsx-runtime";
import { AIPromptInput } from "./AIPromptInput";
import { AIPromptSamples } from "./AIPromptSamples";
import { ChangeEventHandler } from "react";

type TProps = {
    value: string;
    onChangeValue: ChangeEventHandler<HTMLInputElement>;
    onClickArrowButton: () => void;
    isLoading: boolean;
};

export const AIPromptSamplesWithInput = (props: TProps) => (
    <Fragment>
        <AIPromptSamples />
        <AIPromptInput
            onClickArrowButton={props.onClickArrowButton}
            value={props.value}
            onChangeValue={props.onChangeValue}
            isLoading={props.isLoading}
        />
    </Fragment>
);
