import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import StatsCard from "./StatsCard";
import { useMemo, useState } from "react";
import { useGetWorkOrderQuery } from "@/redux/rtk-query/jobsApis";

const StatsInfo = () => {
    const [activeTab, setActiveTab] = useState("daily");

    // Calculate date range based on active tab
    const dateRange = useMemo(() => {
        const now = new Date();
        const endDate = now.toISOString();
        let startDate;
        switch (activeTab) {
            case "daily":
                startDate = new Date(now.setHours(now.getHours() - 24)).toISOString();
                break;
            case "weekly":
                startDate = new Date(now.setDate(now.getDate() - 7)).toISOString();
                break;
            case "monthly":
                startDate = new Date(now.setDate(now.getDate() - 30)).toISOString();
                break;
            default:
                startDate = endDate;
        }
        return { startDate, endDate };
    }, [activeTab]);

    const { data, isLoading, isFetching, error } = useGetWorkOrderQuery(dateRange);

    const stats = useMemo(
        () => [
            { label: "Total Requests", value: data?.totalDocuments ?? 0 },
            { label: "Assigned Requests", value: data?.assignedRequests ?? 0 },
            { label: "Unassigned Requests", value: data?.unassignedRequests ?? 0 },
            { label: "Completed Requests", value: data?.completedRequests ?? 0 },
        ],
        [data],
    );

    if (error) {
        console.error("Error fetching work order data:", error);
        return <div>Error loading stats. Please try again later.</div>;
    }

    return (
        <div>
            <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full mb-6">
                <TabsList className="grid w-[300px] grid-cols-3">
                    <TabsTrigger value="daily">Daily</TabsTrigger>
                    <TabsTrigger value="weekly">Weekly</TabsTrigger>
                    <TabsTrigger value="monthly">Monthly</TabsTrigger>
                </TabsList>
                <div className="mt-4">
                    <StatsCard stats={stats} isLoading={isLoading || isFetching} />
                </div>
            </Tabs>
        </div>
    );
};

export default StatsInfo;
