import { Button } from "@/components/ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import AddWorkOrder from "./AddWorkForm";
import PropertyUnitForm from "./PropertyUnitForm";
import WorkOrderDetails from "./WorkOrderDetails";
import { defaultWorkOrderValues, WorkOrderFormValues, workOrderSchema } from "./workOrderSchema";
import { toast, useGetMyDetailsQuery } from "@/pages/propertyManagementKnowledgeBase";
import {
    TCreateJobArgs,
    useAddContractorMutation,
    useCreateJobMutation,
    useGetJobStatsQuery,
    useUpdateJobMutation,
} from "@/redux/rtk-query/jobsApis";
import { transformStatus, transformStatusForAPI } from "../utils/utils";
import WorkOrder, { TFileMetadata, TResponse } from "@/types";
import { format } from "date-fns";
import { Pencil1Icon } from "@radix-ui/react-icons";
import { useGetTenantsQuery } from "@/redux/rtk-query/tenantsApis";
import {
    useCreateJobFileMetadataMutation,
    useGetJobFileMetadataQuery,
} from "@/redux/rtk-query/uploadApis";
import { useDeleteFileMutation } from "@/redux/rtk-query/uploadApis";

const STEP = {
    AddProperty: 1,
    AddWorkOrder: 2,
};

export type FormStatusProps = {
    mode: "Edit" | "Add" | "Preview";
    currentPreviewOrderInfo?: WorkOrder | null;
    setCurrentMode?: React.Dispatch<React.SetStateAction<"Edit" | "Add" | "Preview">>;
    currentWorkOrderDocuments?: TResponse<TFileMetadata>[];
    currentDocumentsLoading?: boolean;
    isUploading?: boolean;
    isUploadingDoc?: boolean;
    editDetailsButton?: boolean;
    setWorkOrderDialog?: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddWorkContainer = ({
    mode,
    currentPreviewOrderInfo,
    setCurrentMode,
    currentWorkOrderDocuments,
    currentDocumentsLoading,
    editDetailsButton,
    setWorkOrderDialog,
}: FormStatusProps) => {
    // console.log("currentPreviewOrderInfo", currentPreviewOrderInfo);
    const methods = useForm<WorkOrderFormValues>({
        resolver: zodResolver(workOrderSchema),
        defaultValues: defaultWorkOrderValues,
    });
    const [currentStep, setCurrentStep] = useState(
        mode === "Add" ? STEP.AddProperty : STEP.AddWorkOrder,
    );

    const { data: myDetails } = useGetMyDetailsQuery();
    const [createJob] = useCreateJobMutation();
    const [assignContractor] = useAddContractorMutation();

    const { refetch: refetchStats } = useGetJobStatsQuery(myDetails?.companyId, {
        skip: !myDetails?.companyId,
    });

    const { data: tenants } = useGetTenantsQuery({
        companyId: myDetails?.companyId || "",
        limit: 500,
    });
    const [updateJob] = useUpdateJobMutation();
    const [createJobFileMetaData] = useCreateJobFileMetadataMutation();
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadingDoc, setIsUploadingDoc] = useState(false);
    const [markedAsDeletedId, setMarkedAsDeleted] = useState<string[]>([]);
    const {
        data: files,
        isFetching: isDocumentsLoading,
        refetch: refetchFiles,
    } = useGetJobFileMetadataQuery(currentPreviewOrderInfo?._id || "", {
        skip: !currentPreviewOrderInfo?._id,
    });
    const [deleteFile] = useDeleteFileMutation();

    useEffect(() => {
        if (currentPreviewOrderInfo) {
            methods.reset({
                title: currentPreviewOrderInfo.jobDetail?.title || "",
                category: currentPreviewOrderInfo.jobCategory.split(", ") || [],
                description: currentPreviewOrderInfo.jobDetail.description || "",
                photoUrl: currentPreviewOrderInfo.jobDetail.photoUrl || [],
                documents:
                    currentWorkOrderDocuments?.map((document) => ({
                        _id: document._id,
                        fileName: document.fileName,
                        filePath: document.filePath,
                    })) || [],
                requestedBy:
                    tenants?.tenants.find(
                        (tenant) => tenant.id === currentPreviewOrderInfo?.tenantId,
                    ) || {},
                contractor: currentPreviewOrderInfo.assignedToInfo || {},
                dueDate: new Date(currentPreviewOrderInfo.dueDate) || new Date(),
                priority: transformStatus(currentPreviewOrderInfo.jobPriority || "") || "",
                status: transformStatus(currentPreviewOrderInfo.status || "") || "",
                notes: "",
                address: currentPreviewOrderInfo.address || "",
                property: currentPreviewOrderInfo.propertyDetails || {},
                unit: currentPreviewOrderInfo.propertyDetails || {},
            });
        }
    }, [currentPreviewOrderInfo, methods, tenants?.tenants, currentWorkOrderDocuments]);

    async function onSubmit(values: WorkOrderFormValues) {
        console.log(values);

        const result = workOrderSchema.safeParse(values);
        if (!result.success) {
            console.log("Validation errors:", result.error.flatten());
            return;
        }
        if (mode === "Add") {
            const { address } = values;
            if ("createdAt" in address) delete (address as any).createdAt;
            if ("updatedAt" in address) delete (address as any).updatedAt;
            if ("__v" in address) delete (address as any).__v;
            if ("_id" in address) delete (address as any)._id;

            const payload: TCreateJobArgs = {
                title: values.title,
                description: values.description,
                categories: values.category,
                images: values.photoUrl || [],
                address: { ...values.address, addressType: "WORK" },
                _id: myDetails?._id,
                dueDate: values.dueDate.toISOString(),
                status: transformStatusForAPI(values.status),
                budget: 0,
                property: {
                    key: "",
                    value: values.unit._id,
                },
                companyId: myDetails?.companyId,
                date: "",
                navigateAfterCreating: "",
                jobType: "MAINTENANCE",
                priority: values.priority.toUpperCase(),
                tenantId: values.requestedBy?.id || "",
            };

            try {
                const res = await createJob(payload);
                if (res) {
                    await assignContractor({
                        _id: res.data.jobDetails.jobId,
                        assignedTo: values?.contractor?._id || "",
                        navigate: () => {},
                    });
                    for (const document of values.documents) {
                        const response = await createJobFileMetaData({
                            createdBy: myDetails?._id || "",
                            status: "NEW",
                            type: "JOB",
                            fileName: document.fileName,
                            refId: res.data.newJob._id,
                            filePath: document.filePath,
                            tag: "workorder",
                            group: "general",
                        });
                    }
                }
                setWorkOrderDialog && setWorkOrderDialog(false);
                refetchStats();
            } catch (error: any) {
                console.log(error.message);
                toast.error("Failed to create work order");
            }
        } else if (mode === "Edit") {
            const payload: any = {
                _id: currentPreviewOrderInfo?.jobDetail.jobId || "",
                jobDetails: {
                    title: values.title,
                    description: values.description,
                    rate: 0,
                    supportingPhotos: [],
                    photoUrl: values.photoUrl,
                },
                jobPriority: values.priority.toUpperCase(),
                status: transformStatusForAPI(values.status),
                assignedTo: values?.contractor?._id,
                tenantId: values?.requestedBy?.id,
                jobCategory: values?.category.join(", "),
                // dueDateTime: values?.dueDate.toISOString(),
                // add notes property
            };
            try {
                const res = await updateJob(payload).unwrap();
                if (res.updatedJobData._id) {
                    // Delete marked documents
                    for (const documentId of markedAsDeletedId) {
                        deleteFile({ id: documentId });
                    }

                    toast.success("Work order updated successfully");
                    const existingFilePaths: string[] = [];
                    if (currentWorkOrderDocuments) {
                        currentWorkOrderDocuments.forEach((document) =>
                            existingFilePaths.push(document.filePath),
                        );
                    }
                    for (const document of values.documents) {
                        if (!existingFilePaths.includes(document.filePath)) {
                            await createJobFileMetaData({
                                createdBy: myDetails?._id || "",
                                status: "NEW",
                                type: "JOB",
                                fileName: document.fileName,
                                refId: res.updatedJobData._id,
                                filePath: document.filePath,
                                tag: "workorder",
                                group: "general",
                            });
                        }
                    }
                }
                setWorkOrderDialog && setWorkOrderDialog(false);
                // No need to manually refetch, the invalidation will trigger a refetch
                refetchStats();
                refetchFiles();
            } catch (error: any) {
                console.log(error);
                toast.error("Failed to update work order");
            }
        }
    }

    return (
        <div className="bg-white w-full">
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(onSubmit, (errors) => {
                        console.log("Validation errors:", errors);
                    })}
                    noValidate
                >
                    {currentStep === STEP.AddProperty && (
                        <PropertyUnitForm setCurrentStep={setCurrentStep} />
                    )}
                    {currentStep === STEP.AddWorkOrder && (
                        <AddWorkMain
                            mode={mode}
                            currentPreviewOrderInfo={currentPreviewOrderInfo}
                            setCurrentMode={setCurrentMode}
                            currentWorkOrderDocuments={currentWorkOrderDocuments}
                            currentDocumentsLoading={currentDocumentsLoading}
                            isUploading={isUploading}
                            isUploadingDoc={isUploadingDoc}
                            setIsUploading={setIsUploading}
                            setIsUploadingDoc={setIsUploadingDoc}
                            editDetailsButton={editDetailsButton}
                            setMarkedAsDeleted={setMarkedAsDeleted}
                        />
                    )}
                </form>
            </FormProvider>
        </div>
    );
};

export default AddWorkContainer;

export const AddWorkMain = ({
    mode,
    currentPreviewOrderInfo,
    setCurrentMode,
    currentWorkOrderDocuments,
    currentDocumentsLoading,
    isUploading,
    isUploadingDoc,
    setIsUploading,
    setIsUploadingDoc,
    editDetailsButton = true,
    setMarkedAsDeleted,
}: FormStatusProps & {
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
    setIsUploadingDoc: React.Dispatch<React.SetStateAction<boolean>>;
    setMarkedAsDeleted: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
    return (
        <div className="max-w-full w-[800px] ">
            <div className="flex gap-6">
                <AddWorkOrder
                    mode={mode}
                    currentPreviewOrderInfo={currentPreviewOrderInfo}
                    setCurrentMode={setCurrentMode}
                    currentWorkOrderDocuments={currentWorkOrderDocuments}
                    currentDocumentsLoading={currentDocumentsLoading}
                    isUploading={isUploading}
                    isUploadingDoc={isUploadingDoc}
                    setIsUploading={setIsUploading}
                    setIsUploadingDoc={setIsUploadingDoc}
                    setMarkedAsDeleted={setMarkedAsDeleted}
                />
                <div className="flex-1">
                    {mode === "Preview" &&
                        editDetailsButton &&
                        currentPreviewOrderInfo?.status !== "COMPLETED" && (
                            <div className="mb-2">
                                <div className="flex items-center justify-end">
                                    <Button
                                        type="button"
                                        onClick={() => setCurrentMode && setCurrentMode("Edit")}
                                        variant={"grey"}
                                        className="ring-1 ring-gray-200"
                                    >
                                        <Pencil1Icon className="mr-2" />
                                        Edit Details
                                    </Button>
                                </div>
                                <p className="text-sm mt-4">
                                    Last Updated :{" "}
                                    {format(
                                        new Date(currentPreviewOrderInfo?.updatedAt!),
                                        "dd MMM yyyy, hh:mm a",
                                    )}
                                </p>
                            </div>
                        )}
                    <WorkOrderDetails
                        mode={mode}
                        currentPreviewOrderInfo={currentPreviewOrderInfo}
                        setCurrentMode={setCurrentMode}
                        currentWorkOrderDocuments={currentWorkOrderDocuments}
                        isUploading={isUploading}
                        isUploadingDoc={isUploadingDoc}
                    />
                </div>
            </div>
            {mode !== "Preview" && (
                <div className="mt-4 flex justify-end">
                    <Button
                        type="submit"
                        className="bg-primary text-white"
                        disabled={isUploading || isUploadingDoc}
                    >
                        {mode === "Add" ? "Add Work Order" : "Update Work Order"}
                    </Button>
                </div>
            )}
        </div>
    );
};
