import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useUpdateUserDetailsMutation } from "@/redux/rtk-query/authApis";
import { useResetPasswordMutation } from "@/redux/rtk-query/settings";
import { toast } from "react-toastify";
import { useState } from "react";
import { Heading } from "@/components/common/pageHeading";
import { UploadImage } from "./UploadImage";
import PhoneInput from "react-phone-input-2";
import { TUser } from "@/types";

const profileSchema = z.object({
    firstName: z.string().min(2, "First name must be at least 2 characters."),
    lastName: z.string().min(2, "Last name must be at least 2 characters."),
    email: z.string().email("Invalid email address."),
    phone: z.string().optional(),
});

const passwordSchema = z
    .object({
        currentPassword: z.string().min(6, "Password must be at least 6 characters."),
        newPassword: z.string().min(6, "Password must be at least 6 characters."),
        confirmPassword: z.string().min(6, "Password must be at least 6 characters."),
    })
    .refine((data) => data.newPassword === data.confirmPassword, {
        message: "Passwords don't match",
        path: ["confirmPassword"],
    });

type TProp = {
    user: TUser | undefined;
};

export function ProfileSettings(props: TProp) {
    const [profileImage, setProfileImage] = useState(props.user?.imageUrl || "");
    const [updateUserDetails] = useUpdateUserDetailsMutation();
    const [resetPassword] = useResetPasswordMutation();

    const profileForm = useForm({
        resolver: zodResolver(profileSchema),
        defaultValues: {
            firstName: props.user?.firstName || "",
            lastName: props.user?.lastName || "",
            email: props.user?.email || "",
            phone: props.user?.phone || "",
        },
    });

    const passwordForm = useForm({
        resolver: zodResolver(passwordSchema),
        defaultValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
    });

    async function onProfileSubmit(values: z.infer<typeof profileSchema>) {
        try {
            await updateUserDetails({
                ...values,
                imageUrl: profileImage,
            }).unwrap();
            toast.success("Profile updated successfully");
        } catch (error) {
            toast.error("Failed to update profile");
        }
    }

    async function onPasswordSubmit(values: z.infer<typeof passwordSchema>) {
        try {
            await resetPassword({
                old_password: values.currentPassword,
                new_password: values.newPassword,
            }).unwrap();
            toast.success("Password updated successfully");
            passwordForm.reset();
        } catch (error) {
            toast.error("Failed to update password");
        }
    }

    return (
        <section className="space-y-8">
            <Heading bottom={58}>Profile Settings</Heading>

            <div className="bg-white p-6 rounded-lg shadow">
                <div className="mb-6">
                    <UploadImage
                        profileImage={profileImage}
                        setProfileImage={setProfileImage}
                        title="Change Profile picture"
                    />
                </div>

                <Form {...profileForm}>
                    <form
                        onSubmit={profileForm.handleSubmit(onProfileSubmit)}
                        className="space-y-4"
                    >
                        <div className="grid grid-cols-2 gap-4">
                            <FormField
                                control={profileForm.control}
                                name="firstName"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>First Name</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Enter first name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={profileForm.control}
                                name="lastName"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Last Name</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Enter last name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>

                        <FormField
                            control={profileForm.control}
                            name="phone"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Phone Number</FormLabel>
                                    <FormControl>
                                        <PhoneInput
                                            country={"gb"}
                                            countryCodeEditable={false}
                                            value={field.value}
                                            onChange={(phone) => field.onChange(phone)}
                                            inputProps={{
                                                name: "phone",
                                                required: true,
                                            }}
                                            inputClass="!w-full !h-10 !rounded-md !border !border-input"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={profileForm.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Enter email" {...field} disabled />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <div className="flex justify-end space-x-4">
                            <Button
                                type="button"
                                variant="outline"
                                onClick={() => profileForm.reset()}
                            >
                                Cancel
                            </Button>
                            <Button type="submit">Save Changes</Button>
                        </div>
                    </form>
                </Form>
            </div>

            <div className="bg-white p-6 rounded-lg shadow">
                <h2 className="text-xl font-semibold mb-4">Change Password</h2>
                <Form {...passwordForm}>
                    <form
                        onSubmit={passwordForm.handleSubmit(onPasswordSubmit)}
                        className="space-y-4"
                    >
                        <FormField
                            control={passwordForm.control}
                            name="currentPassword"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Current Password</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="password"
                                            placeholder="Enter current password"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={passwordForm.control}
                            name="newPassword"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>New Password</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="password"
                                            placeholder="Enter new password"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={passwordForm.control}
                            name="confirmPassword"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Confirm New Password</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="password"
                                            placeholder="Confirm new password"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <div className="flex justify-end space-x-4">
                            <Button
                                type="button"
                                variant="outline"
                                onClick={() => passwordForm.reset()}
                            >
                                Cancel
                            </Button>
                            <Button type="submit">Change Password</Button>
                        </div>
                    </form>
                </Form>
            </div>
        </section>
    );
}
