import ChatTopbar from "./chat-topbar";
import { ChatList } from "./chat-list";
import { useMemo, useState } from "react";
import { IConversation, IMessage, TUser, IAIMessage } from "@/types";
import { useGetMessagesQuery, useSendMessageMutation } from "@/redux/rtk-query/chatApis";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { catchAll } from "@/utils/catch";
import { NothingToSeePlaceholder } from "@/components/common/nothingToSeePlaceholder";
import { toast } from "react-toastify";
import axios from "axios";

interface ChatProps {
    isMobile: boolean;
    isSelected?: string;
    conversation?: IConversation;
    isAiChat?: boolean;
    isTopBar?: boolean;
    isConversationLoading?: boolean;
}

export function Chats({
    isMobile,
    isSelected,
    conversation,
    isAiChat = false,
    isTopBar = true,
    isConversationLoading,
}: ChatProps) {
    const [inputValue, setInputValue] = useState<string>("");
    const [messageArray, setMessageArray] = useState<IMessage[]>([]);
    const [sendMessage] = useSendMessageMutation();
    const { data: user } = useGetMyDetailsQuery();
    const { data: messages } = useGetMessagesQuery(
        localStorage?.getItem("lastSelectedConversation") || isSelected,
    );

    const receiverObject: TUser | undefined = useMemo(() => {
        if (!conversation?.participantDetails) return undefined;

        for (const participant of conversation?.participantDetails) {
            if (participant._id !== user?._id) {
                return participant;
            }
        }
        return undefined;
    }, [conversation?.participantDetails, user?._id]);

    const sendAiMessage = async (payload: IAIMessage) => {
        try {
            const response = await axios.post(
                "https://ai-assistant.lightwork.blue/chat/property-manager",
                payload,
            );
            return response;
        } catch (error) {
            toast.error("Failed to send AI message");
            return;
        }
    };

    async function handleClick(isemojiClicked: boolean = false) {
        if (!user?._id || !isSelected || !receiverObject?._id) return;

        if (!inputValue && !isemojiClicked) return;

        const payload: IMessage = {
            senderId: user?._id,
            conversationId: isSelected,
            receipientId: receiverObject?._id,
            content: isemojiClicked ? "👍" : inputValue,
            status: "SENT",
        };

        setMessageArray((prev) => [...prev, { ...payload, timestamp: new Date().toISOString() }]);
        let response;
        if (isAiChat) {
            const aiPayload: IAIMessage = {
                content: isemojiClicked ? "👍" : inputValue,
                receipient_id: receiverObject?._id,
                status: "SENT",
                user_id: user?._id,
                conversation_id: isSelected,
            };
            response = await catchAll(sendAiMessage(aiPayload));
            if (response) {
                const responsePayload: IMessage = {
                    senderId: receiverObject?._id,
                    conversationId: isSelected,
                    receipientId: user?._id,
                    content: response.data?.data.reply,
                    status: "SENT",
                };
                setMessageArray((prev) => [
                    ...prev,
                    { ...responsePayload, timestamp: new Date().toISOString() },
                ]);
            }
        } else {
            response = await catchAll(sendMessage(payload));
        }

        setInputValue("");
    }

    const allMessages = useMemo(() => {
        if (!messages) return [];
        return [...messages, ...messageArray];
    }, [messageArray, messages]);

    return (
        <div className="flex flex-col justify-between w-full h-full">
            {receiverObject ? (
                <>
                    {isTopBar && (
                        <ChatTopbar
                            selectedUser={receiverObject}
                            isConversationLoading={isConversationLoading}
                        />
                    )}

                    <ChatList
                        messages={allMessages}
                        selectedUser={receiverObject}
                        sendMessage={(isemojiClicked: boolean) => handleClick(isemojiClicked)}
                        isMobile={isMobile}
                        setInputValue={setInputValue}
                        inputValue={inputValue}
                        isConversationLoading={isConversationLoading}
                    />
                </>
            ) : (
                <NothingToSeePlaceholder
                    height={1080}
                    label="No chat selected"
                    className="!border-none !mt-0"
                />
            )}
        </div>
    );
}
