type TProps = {
    onClick?: () => void;
};

/**
 * @description Cross Icon (X)
 */
export function CloseIcon(props: TProps) {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={props.onClick}
        >
            <path
                d="M10.5 1.5L1.5 10.5M1.5 1.5L10.5 10.5"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
