import { CardProperties } from "../pages/propertyManagementDashboard/propertyManagementDashboard.constant";

export const updateCardPropertiesDetailsSections = (
    bookedProperty: any[] = [],
    availableProperty: any[] = [],
    notAvailableProperty: any[] = [],
) => {
    const totalProperties =
        bookedProperty.length + availableProperty.length + notAvailableProperty.length;

    const bookedWidth =
        totalProperties > 0 ? `${(bookedProperty.length / totalProperties) * 100}%` : "0%";
    const availableWidth =
        totalProperties > 0 ? `${(availableProperty.length / totalProperties) * 100}%` : "0%";
    const notAvailableWidth =
        totalProperties > 0 ? `${(notAvailableProperty.length / totalProperties) * 100}%` : "0%";

    CardProperties.progressBarSegments = [
        { color: "green", width: bookedWidth },
        { color: "blue", width: availableWidth },
        { color: "red", width: notAvailableWidth },
    ];

    CardProperties.data = String(
        bookedProperty.length + availableProperty.length + notAvailableProperty.length,
    );
    CardProperties.detailsSections = CardProperties.detailsSections.map((section, index) => {
        let propertyLength = 0;
        switch (index) {
            case 0:
                propertyLength = bookedProperty.length;
                break;
            case 1:
                propertyLength = availableProperty.length;
                break;
            case 2:
                propertyLength = notAvailableProperty.length;
                break;
            default:
                break;
        }
        return {
            ...section,
            property: String(propertyLength),
        };
    });
};
