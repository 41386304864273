import styles from "./contractorDetails.module.css";
import { Button } from "../../postAJob/button";
import { ShareIcon } from "../../../assets/icons/ShareIcon";
import { memo } from "react";

type TActionContainerProps = {
    onClickBook?: () => void;
    onClickMessage?: () => void;
    onClickShare?: () => void;
};

const ActionContainer = memo((props: TActionContainerProps) => (
    <div className={styles.contractorInitialDetailsContainerRight}>
        {props.onClickBook && (
            <Button onClick={props.onClickBook} borderRadius="lg" style={{ height: 35 }}>
                Book
            </Button>
        )}

        {props.onClickMessage && (
            <Button
                onClick={props.onClickMessage}
                borderRadius="lg"
                variant="secondary-with-shadow"
                style={{ height: 39 }}
            >
                Message
            </Button>
        )}

        {props.onClickShare && (
            <Button
                variant="secondary-with-shadow"
                borderRadius="lg"
                icon={<ShareIcon />}
                iconPosition="left"
                style={{ height: 39 }}
            >
                Share
            </Button>
        )}
    </div>
));

const ProfileImage = memo(() => (
    <img
        className={styles.contractorProfileImage}
        src="https://via.placeholder.com/500x500"
        alt="profile"
    />
));

const StatusChip = memo(() => <span className={styles.contractorAvailabilityChip}>Available</span>);

const NameAndCountry = memo(() => (
    <div className={styles.contractorNameAndCountry}>
        <p className={styles.contractorName}>John Doe</p>
        <p className={styles.contractorCountry}>United Kingdom</p>
    </div>
));

const CardLeft = memo(() => (
    <div className={styles.contractorInitialDetailsContainerLeft}>
        <span className={styles.contractorProfileImageContainer}>
            <ProfileImage />
            <StatusChip />
        </span>
        <NameAndCountry />
    </div>
));

export const ContractorProfileHeader = (props: {
    onClickBook?: () => void;
    onClickMessage?: () => void;
    onClickShare?: () => void;
}) => (
    <header className={styles.profileHeader}>
        <div className={styles.contractorInitialDetailsContainer}>
            <CardLeft />
            <ActionContainer
                onClickBook={props.onClickBook}
                onClickMessage={props.onClickMessage}
                onClickShare={props.onClickShare}
            />
        </div>
    </header>
);
