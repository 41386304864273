import styles from "./notFoundPage.module.css";

const NotFoundPage = () => {
    const goHome = () => {
        window.location.href = "/";
    };

    return (
        <div className={styles.error_frame_page}>
            <div>
                <span>
                    <i className="fa fa-chain-broken fa-5"></i>
                </span>
                <h2>
                    <span> 4</span>
                    <span>0</span>
                    <span>4</span>
                </h2>
                <div className={styles.error_footer}>
                    <div>PAGE NOT FOUND</div>
                    <button type="button" onClick={goHome}>
                        Go home
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
