import { useEffect, useCallback } from "react";
import styles from "./UploadJobImages.module.css";
import { InputWithLabel } from "../postAJob/InputWithLabel";
import { DatePickerWithLabel } from "../postAJob/DatePickerWithLabel";
import { CalendarIcon } from "../../assets/icons/CalendarIcon";
import { Divider } from "../../components/common/divider";
import { TextareaWithLabel } from "../postAJob/TextareaWithLabel";
import { UploadButton } from "../postAJob/ConfirmJobDetails/UploadButton";
import { useUploadToAWSMutation } from "../../redux/rtk-query/uploadApis";
import { useAppDispatch } from "../../redux/store";
import { removeImageFromPostJob } from "../../redux/features/postJobSlice";
import { TUpdateJob } from "../../types";
import useGoogleAutocomplete from "../../hooks/useGoogleAutocomplete";
import { getAddressFromGoogleObject } from "../../utils/getAddressFromGoogleObject";

type FormProps = {
    images: string[];
    setImages: (images: string[] | ((prevImages: string[]) => string[])) => void;
    newImages: string[];
    setNewImages: (images: string[] | ((prevNewImages: string[]) => string[])) => void;
    formData: TUpdateJob;
    setFormData: (formData: TUpdateJob | ((prevState: TUpdateJob) => TUpdateJob)) => void;
    disableFields?: boolean;
};

export default function UploadJobImagesForm({
    images,
    setImages,
    setNewImages,
    formData,
    setFormData,
    disableFields = false,
}: FormProps) {
    const dispatch = useAppDispatch();

    const [uploadImageToAWS, { isLoading }] = useUploadToAWSMutation();
    const handleAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setFormData((prevState) => ({ ...prevState, address: value }));
    };
    const { autocompleteInputRef, place } = useGoogleAutocomplete({
        name: "address",
        handleUserDetails: handleAddress,
    });
    useEffect(() => {
        if (place) {
            const address = getAddressFromGoogleObject(place);
            setFormData((prevState) => ({
                ...prevState,
                addressObj: address,
                address: `${address.mainStreet}, ${address.city}, ${address.province}, ${address.postalCode}, ${address.country}`,
            }));
        }
    }, [place, setFormData]);

    const handleChange = useCallback(
        (value: any, name: string) => {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        },
        [setFormData],
    );

    const handleAddressChange = useCallback(
        (value: any, name: string) => {
            setFormData((prevState) => ({
                ...prevState,
                addressObj: {
                    ...prevState.addressObj,
                    [name]: value,
                },
            }));
        },
        [setFormData],
    );

    const getTodayDate = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today;
    };

    const handleRemoveImage = useCallback(
        (index: number) => {
            dispatch(removeImageFromPostJob(index));
            setImages((prevImages: string[]) => prevImages.filter((_, i) => i !== index));
            setNewImages((prevNewImages: string[]) => prevNewImages.filter((_, i) => i !== index));
        },
        [dispatch, setImages, setNewImages],
    );

    const handleOnClickUploadImage: React.ChangeEventHandler<HTMLInputElement> = useCallback(
        async (e) => {
            const formData = new FormData();
            if (e.target.files) {
                const files = Array.from(e.target.files) as File[];
                formData.append("file", files[0]);
                formData.append("folder", "jobs");
                const response = await uploadImageToAWS(formData).unwrap();

                if (response.data) {
                    setImages((prevImages: string[]) => [...prevImages, response.url]);
                    setNewImages((prevNewImages: string[]) => [...prevNewImages, response.url]);
                }
            }
        },
        [setImages, setNewImages, uploadImageToAWS],
    );

    return (
        <div className={styles.form}>
            <InputWithLabel
                labelProps={{
                    text: "Title",
                }}
                inputProps={{
                    error: {},
                    name: "title",
                    placeholder:
                        "House Cleaning Needed This Saturday (April 13th) at 3 PM in East London",
                    required: true,
                    type: "text",
                    disabled: disableFields,
                    value: formData.title,
                    handleChange: (e) => handleChange(e.target.value, "title"),
                }}
            />
            <TextareaWithLabel
                labelProps={{
                    text: "Description",
                }}
                textAreaProps={{
                    error: {},
                    handleChange: (e) => handleChange(e.target.value, "description"),
                    name: "description",
                    placeholder: "Provide a detailed description of the job...",
                    required: true,
                    disabled: disableFields,
                    value: formData.description,
                }}
            />
            <Divider />
            <InputWithLabel
                ref={autocompleteInputRef}
                labelProps={{
                    text: "Address",
                }}
                inputProps={{
                    name: "address",
                    placeholder: "Search for address...",
                    value: formData.address,
                    disabled: disableFields,
                    handleChange: handleAddress,
                }}
            />
            {formData.addressObj && (
                <>
                    <InputWithLabel
                        labelProps={{
                            text: "Street",
                        }}
                        inputProps={{
                            name: "mainStreet",
                            placeholder: "Main Street",
                            value: formData.addressObj.mainStreet,
                            disabled: disableFields,
                            handleChange: (e) => handleAddressChange(e.target.value, "mainStreet"),
                        }}
                    />
                    <div className={styles.formCol}>
                        <InputWithLabel
                            labelProps={{
                                text: "City",
                            }}
                            inputProps={{
                                name: "city",
                                placeholder: "City",
                                value: formData.addressObj.city,
                                disabled: disableFields,
                                handleChange: (e) => handleAddressChange(e.target.value, "city"),
                            }}
                        />
                        <InputWithLabel
                            labelProps={{
                                text: "Province",
                            }}
                            inputProps={{
                                name: "province",
                                placeholder: "Province",
                                value: formData.addressObj.province,
                                disabled: disableFields,
                                handleChange: (e) =>
                                    handleAddressChange(e.target.value, "province"),
                            }}
                        />
                    </div>
                    <div className={styles.formCol}>
                        <InputWithLabel
                            labelProps={{
                                text: "Postal Code",
                            }}
                            inputProps={{
                                name: "postalCode",
                                placeholder: "Postal Code",
                                value: formData.addressObj.postalCode,
                                disabled: disableFields,
                                handleChange: (e) =>
                                    handleAddressChange(e.target.value, "postalCode"),
                            }}
                        />
                        <InputWithLabel
                            labelProps={{
                                text: "Country",
                            }}
                            inputProps={{
                                name: "country",
                                placeholder: "Country",
                                value: formData.addressObj.country,
                                disabled: disableFields,
                                handleChange: (e) => handleAddressChange(e.target.value, "country"),
                            }}
                        />
                    </div>
                </>
            )}
            <DatePickerWithLabel
                variant="date-time"
                labelProps={{ text: "Booking Date" }}
                datePickerProps={{
                    placeholder: "Enter Booking Date",
                    rightSection: <CalendarIcon />,
                    disabled: disableFields,
                    minDate: getTodayDate(),
                    onChange: (value) => handleChange(value?.toString() ?? "", "createdAt"),
                    value: formData.createdAt ? new Date(formData.createdAt) : undefined,
                }}
            />
            <Divider />
            <UploadButton
                handleImageChange={handleOnClickUploadImage}
                images={images}
                isLoading={isLoading}
                disabled={disableFields}
                handleRemoveImage={handleRemoveImage}
            />
        </div>
    );
}
