import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { useGetAccountTransactionsQuery } from "@/redux/rtk-query/paymentAccount";
import { useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import Loader from "../loading";
import { Key } from "react";
import { NothingToSeePlaceholder } from "../common/nothingToSeePlaceholder";

export default function RecentTransactions() {
    const { data: user } = useGetMyDetailsQuery();

    const { data: transactionData, isLoading: transactionsLoading } =
        useGetAccountTransactionsQuery(user?.stripeConnectId || "", {
            skip: !Boolean(user?.stripeConnectId),
        });

    const headItems = ["Date", "Account", "Status", "Amount"];

    const TableBodyItems =
        !transactionsLoading && transactionData?.data
            ? transactionData.data.map((item: any) => {
                  return [
                      {
                          key: 0,
                          value: new Date(item.date).toDateString(),
                      },
                      {
                          key: 1,
                          value: item.type,
                      },
                      {
                          key: 2,
                          value: item.status,
                      },
                      {
                          key: 3,
                          value: item.amount,
                      },
                  ];
              })
            : [];

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl">Recent Transactions</h2>
                <div className="flex gap-2 items-center">
                    {/* <Button
                        variant="outline"
                        size="sm"
                        className="rounded-full flex items-center gap-2"
                    >
                        <SlCalender /> Last 7 days
                    </Button>
                    <Button
                        variant="outline"
                        size="sm"
                        className="rounded-full flex items-center gap-2"
                    >
                        <SlCalender /> View All
                    </Button> */}
                    {/* <Button variant="outline" size="icon" className="rounded-full">
                        <GoDownload className="h-4 w-4" />
                    </Button> */}
                </div>
            </div>
            <Tabs defaultValue="viewall">
                <TabsList className="bg-[#f6f6f6] mb-2">
                    <TabsTrigger value="viewall" className="bg-[#f6f6f6]">
                        View All
                    </TabsTrigger>
                    <TabsTrigger value="sent" className="bg-[#f6f6f6]">
                        Sent
                    </TabsTrigger>
                    <TabsTrigger value="received" className="bg-[#f6f6f6]">
                        Received
                    </TabsTrigger>
                </TabsList>
                {transactionsLoading ? (
                    <Loader />
                ) : (
                    <>
                        <TabsContent value="viewall">
                            <div className="relative">
                                <div className="overflow-x-auto">
                                    <div className="inline-block min-w-full align-middle">
                                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            {transactionData?.data?.length > 0 ? (
                                                <div className="max-h-[350px] overflow-auto">
                                                    <table className="min-w-full divide-y divide-gray-300">
                                                        <thead className="bg-gray-50 sticky top-0">
                                                            <tr>
                                                                {headItems.map((item, index) => (
                                                                    <th
                                                                        key={index}
                                                                        scope="col"
                                                                        className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 ${index === 0 ? "sm:pl-6" : ""}`}
                                                                    >
                                                                        {item}
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                            {transactionData?.data?.length > 0 &&
                                                                TableBodyItems.map(
                                                                    (
                                                                        row: any[],
                                                                        rowIndex:
                                                                            | Key
                                                                            | null
                                                                            | undefined,
                                                                    ) => (
                                                                        <tr key={rowIndex}>
                                                                            {row.map((cell) => (
                                                                                <td
                                                                                    className={`${rowIndex === 0 ? "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" : "whitespace-nowrap px-3 py-4 text-sm text-gray-500"}`}
                                                                                    key={cell.key}
                                                                                >
                                                                                    {cell.value}
                                                                                </td>
                                                                            ))}
                                                                        </tr>
                                                                    ),
                                                                )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : (
                                                <NothingToSeePlaceholder
                                                    height={274}
                                                    width="100%"
                                                    border="0.5px solid gray"
                                                    label="No Data Found!"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabsContent>
                        <TabsContent value="sent">
                            <div className="relative">
                                <div className="overflow-x-auto">
                                    <div className="inline-block min-w-full align-middle">
                                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            {transactionData?.data?.length > 0 ? (
                                                <div className="max-h-[350px] overflow-auto">
                                                    <table className="min-w-full divide-y divide-gray-300">
                                                        <thead className="bg-gray-50 sticky top-0">
                                                            <tr>
                                                                {headItems.map((item, index) => (
                                                                    <th
                                                                        key={index}
                                                                        scope="col"
                                                                        className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 ${index === 0 ? "sm:pl-6" : ""}`}
                                                                    >
                                                                        {item}
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                            {transactionData?.data?.length > 0 &&
                                                                TableBodyItems.map(
                                                                    (
                                                                        row: any[],
                                                                        rowIndex:
                                                                            | Key
                                                                            | null
                                                                            | undefined,
                                                                    ) => (
                                                                        <tr key={rowIndex}>
                                                                            {row.map((cell) => (
                                                                                <td
                                                                                    className={`${rowIndex === 0 ? "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" : "whitespace-nowrap px-3 py-4 text-sm text-gray-500"}`}
                                                                                    key={cell.key}
                                                                                >
                                                                                    {cell.value}
                                                                                </td>
                                                                            ))}
                                                                        </tr>
                                                                    ),
                                                                )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : (
                                                <NothingToSeePlaceholder
                                                    height={274}
                                                    width="100%"
                                                    border="0.5px solid gray"
                                                    label="No Data Found!"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabsContent>
                        <TabsContent value="received">
                            <div className="relative">
                                <div className="overflow-x-auto">
                                    <div className="inline-block min-w-full align-middle">
                                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                            {transactionData?.data?.length > 0 ? (
                                                <div className="max-h-[350px] overflow-auto">
                                                    <table className="min-w-full divide-y divide-gray-300">
                                                        <thead className="bg-gray-50 sticky top-0">
                                                            <tr>
                                                                {headItems.map((item, index) => (
                                                                    <th
                                                                        key={index}
                                                                        scope="col"
                                                                        className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 ${index === 0 ? "sm:pl-6" : ""}`}
                                                                    >
                                                                        {item}
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                            {transactionData?.data?.length > 0 &&
                                                                TableBodyItems.map(
                                                                    (
                                                                        row: any[],
                                                                        rowIndex:
                                                                            | Key
                                                                            | null
                                                                            | undefined,
                                                                    ) => (
                                                                        <tr key={rowIndex}>
                                                                            {row.map((cell) => (
                                                                                <td
                                                                                    className={`${rowIndex === 0 ? "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" : "whitespace-nowrap px-3 py-4 text-sm text-gray-500"}`}
                                                                                    key={cell.key}
                                                                                >
                                                                                    {cell.value}
                                                                                </td>
                                                                            ))}
                                                                        </tr>
                                                                    ),
                                                                )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : (
                                                <NothingToSeePlaceholder
                                                    height={274}
                                                    width="100%"
                                                    border="0.5px solid gray"
                                                    label="No Data Found!"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabsContent>
                    </>
                )}
            </Tabs>
        </div>
    );
}
