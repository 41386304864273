import Lottie from "lottie-react";
import mapAnimation from "../../jsonData/mapAnimation.json";

const MapLoaderGif = () => {
    return (
        <div className="flex justify-center items-center h-full">
            <Lottie animationData={mapAnimation} loop={true} className="h-[8rem]" />
        </div>
    );
};

export default MapLoaderGif;
