import { memo } from "react";
import { BackButton } from "../../../components/common/backButton";
import { Heading } from "../../../components/common/pageHeading";

export const Header = memo(({ heading }: { heading: string }) => {
    return (
        <header>
            <BackButton bottom={24} />
            {/* <Breadcrumb /> */}
            <Heading top={24} bottom={40}>
                {heading}
            </Heading>
        </header>
    );
});
