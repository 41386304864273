import { TGetAllTenants, TGetAllTenantsTransformedResponseWithPagination } from "../../types";
import { capitalize } from "../../utils/capitalize";
import { api } from "./api";

const tenantsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTenants: builder.query<
            TGetAllTenantsTransformedResponseWithPagination,
            {
                companyId: string;
                limit?: number;
                skip?: number;
                sortBy?: string;
                search?: string;
            }
        >({
            providesTags: ["TENANTS"],
            query: ({ companyId, ...restParams }) => {
                return {
                    url: `auth/users/tenants/${companyId}`,
                    params: {
                        limit: restParams.limit,
                        skip: restParams.skip,
                        sortBy: restParams.sortBy,
                        search: restParams.search,
                    },
                };
            },
            transformResponse: (response: TGetAllTenants) => {
                return {
                    tenants: response.data.map((d) => {
                        return {
                            id: d._id,
                            email: d.email,
                            name: {
                                fullName: capitalize(d.firstName) + " " + capitalize(d.lastName),
                                firstName: d.firstName,
                                lastName: d.lastName,
                            },
                            phone: d.phone,
                            propertyId: d.providerId,
                            image: d.imageUrl,
                            status: d.status,
                        };
                    }),
                    pagination: response.pagination,
                } as TGetAllTenantsTransformedResponseWithPagination;
            },
        }),
        updateTenants: builder.mutation({
            invalidatesTags: ["TENANTS"],
            query: (body) => {
                return {
                    url: `properties/tenancy`,
                    method: "PUT",
                    body,
                };
            },
        }),
    }),
});

export const { useGetTenantsQuery, useUpdateTenantsMutation } = tenantsApi;
