import { memo } from "react";
import { InputWithSearchIcon } from "../../../components/common/input/InputWithSearchIcon";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
    SelectLabel,
} from "@/components/ui/select";

type TableActionsProps = {
    totalMembers: number;
    onClickSort: (direction: string) => void;
    searchTerm: string;
    setSearchTerm: (term: string) => void;
};

export const TableActions = memo((props: TableActionsProps) => {
    const { onClickSort, searchTerm, setSearchTerm, totalMembers } = props;

    const handleSortChange = (value: string) => {
        onClickSort(value);
    };

    return (
        <div className="flex items-center justify-between mb-4">
            <div className="font-medium text-lg leading-[26px] text-black">
                Members ({totalMembers})
            </div>
            <div className="flex items-center gap-4">
                <InputWithSearchIcon
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="flex gap-2">
                    <Select onValueChange={handleSortChange}>
                        <SelectTrigger className="flex items-center gap-2">
                            <SelectValue
                                placeholder="Sort By"
                                className="font-medium text-sm leading-[17px] text-[#09090B]"
                            />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectLabel>Sort By Joined Date</SelectLabel>
                                <SelectItem value="asc">Ascending</SelectItem>
                                <SelectItem value="desc">Descending</SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            </div>
        </div>
    );
});
