import { memo } from "react";
import { PlusIcon } from "../../../assets/icons/PlusIcon";
import { LeadIcon } from "../../../assets/icons";
import { IoClose } from "react-icons/io5";

import styles from "./confirmJobDetails.module.css";

type TProps = {
    handleImageChange: React.ChangeEventHandler<HTMLInputElement>;
    images?: string[];
    isLoading?: boolean;
    disabled: boolean;
    handleRemoveImage: (index: number) => void;
};

export const UploadButton = memo((props: TProps) => {
    return (
        <div>
            <h3 className={styles.uploadPhotosHeading}>Upload Photos</h3>
            <p className={styles.uploadPhotosDescription}>
                Add photos to provide visual context for contractors to understand the project
                better
            </p>
            <div className={styles.uploadButtonWithPhotosContainer}>
                <div className={styles.uploadButtonContainer}>
                    <input
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        onChange={props.handleImageChange}
                        className={styles.uploadInput}
                        multiple
                        disabled={props.disabled}
                    />
                    <div className={styles.uploadButton}>
                        {props.isLoading ? <LeadIcon /> : <PlusIcon />}
                    </div>
                </div>
                {props.images &&
                    props.images.length > 0 &&
                    props.images.map((image, index) => (
                        <div key={image} className={styles.uploadedImageContainer}>
                            <img
                                src={image}
                                alt="job-pic"
                                width={120}
                                height={100}
                                className={styles.uploadedImage}
                            />
                            <button
                                type="button"
                                className={styles.removeImageButton}
                                onClick={() => props.handleRemoveImage(index)}
                            >
                                <IoClose />
                            </button>
                        </div>
                    ))}
            </div>
            <p className={styles.uploadPhotosDescription}>You can add up to 10 photos</p>
        </div>
    );
});
