import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { CalendarIcon, Check, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { useFormContext, Controller } from "react-hook-form";
import { WorkOrderFormValues } from "./workOrderSchema";
import { Command, CommandGroup, CommandItem, CommandList } from "@/components/ui/command";
import { useGetTenantsQuery } from "@/redux/rtk-query/tenantsApis";
import { toast, useGetMyDetailsQuery } from "@/pages/propertyManagementKnowledgeBase";
import { IoIosClose } from "react-icons/io";
import { useGetUserbyTypeQuery } from "@/redux/rtk-query/authApis";
import { ChevronDown, ChevronUp } from "lucide-react";
import { PRIORITY, STATUS } from "../Workorder.constant";
import { TGetAllTenantsTransformedResponse, TUser } from "@/types";
import LBPopoverDropdown from "@/components/lBComponents/LBPopoverDropdown";
import React, { useEffect, useState } from "react";
import { FormStatusProps } from "./AddWorkContainer";
import { useChangeJobStatusMutation } from "@/redux/rtk-query/jobsApis";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { useWorkOrderRow } from "../ui/useWorkOrderRow";
import { transformPriority } from "../utils/utils";

const WorkOrderDetails = ({ mode, currentPreviewOrderInfo }: FormStatusProps) => {
    const {
        control,
        formState: { errors },
        getValues,
        clearErrors,
        trigger,
    } = useFormContext<WorkOrderFormValues>();
    const { data: myDetails } = useGetMyDetailsQuery();
    const { data: tenants } = useGetTenantsQuery({
        companyId: myDetails?.companyId || "",
        limit: 500,
    });
    const { data: contractors } = useGetUserbyTypeQuery({
        userType: "CONTRACTOR",
    });

    const [calendarOpen, setCalendarOpen] = React.useState(false);
    const [statusOpen, setStatusOpen] = React.useState(false);
    const [priorityOpen, setPriorityOpen] = React.useState(false);
    const [updateJob, { isLoading: isUpdatingJob }] = useChangeJobStatusMutation();
    const [jobCompleted, setJobCompleted] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const { refetchTableData } = useWorkOrderRow({ head: [] });

    const handleCompleteJob = async () => {
        if (!currentPreviewOrderInfo?.jobDetail.jobId) return;
        setIsConfirmDialogOpen(true);
    };

    const confirmCompleteJob = async () => {
        if (!currentPreviewOrderInfo?.jobDetail.jobId) return;
        try {
            await updateJob({
                _id: currentPreviewOrderInfo?.jobDetail.jobId,
                status: "COMPLETED",
            }).unwrap();
            setJobCompleted(true);
            toast.success("Job Completed successfully!");
            refetchTableData();
        } catch (error) {
            setJobCompleted(false);
            toast.error("Please try again.");
        } finally {
            setIsConfirmDialogOpen(false);
        }
    };
    useEffect(() => {
        if (currentPreviewOrderInfo) {
            currentPreviewOrderInfo.status === "COMPLETED" && setJobCompleted(true);
        }
    }, [currentPreviewOrderInfo]);

    const isItemDisabled = (item: TGetAllTenantsTransformedResponse | TUser) => {
        return "name" in item && item.id === "you";
    };

    const requestedUserData = [
        {
            id: "you",
            name: {
                fullName: `${myDetails?.firstName} ${myDetails?.lastName}`,
            },
            imageUrl: myDetails?.imageUrl || "",
        } as unknown as TUser,
        ...(tenants?.tenants || []),
    ];
    return (
        <div className="">
            <div className="border px-4 py-4 rounded-lg">
                <h3 className="font-semibold text-lg m-0">Work Order Details</h3>
                {mode === "Preview" && (
                    <div className="mt-4">
                        <Button
                            disabled={jobCompleted || isUpdatingJob}
                            type="button"
                            onClick={handleCompleteJob}
                            variant={null}
                            className={`${jobCompleted ? "ring-1 ring-green-500 text-green-500 disabled:opacity-70" : "ring-1 ring-gray-200"} text-sm`}
                        >
                            <Check
                                size={18}
                                className={`${jobCompleted ? "text-green-500" : "text-black"} mr-1`}
                            />
                            {jobCompleted
                                ? "Completed"
                                : isUpdatingJob
                                  ? "Completing..."
                                  : "Mark as completed"}
                        </Button>
                    </div>
                )}
                <div className="mt-2 space-y-4">
                    <FormField
                        control={control}
                        name="requestedBy"
                        render={({ field }) => (
                            <FormItem className="flex flex-col">
                                <div className="flex items-center">
                                    <FormLabel className="max-w-28 mt-3 text-gray-800 text-sm font-normal w-full">
                                        Requested By
                                    </FormLabel>
                                    <div className="flex-1">
                                        <LBPopoverDropdown<
                                            TGetAllTenantsTransformedResponse | TUser
                                        >
                                            disabled={mode === "Preview"}
                                            value={field.value}
                                            onChange={(value) => {
                                                clearErrors("requestedBy");
                                                field.onChange(value);
                                            }}
                                            items={requestedUserData}
                                            getItemValue={(item) =>
                                                "name" in item
                                                    ? item.name.fullName
                                                    : `${item.firstName} ${item.lastName}`
                                            }
                                            renderTrigger={({ open }) => (
                                                <div className="flex text-gray-500 items-center justify-between w-[200px]">
                                                    {field.value.id
                                                        ? field?.value?.name?.fullName
                                                        : "Select user"}
                                                    {open ? (
                                                        <ChevronUp className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                                    ) : (
                                                        <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                                    )}
                                                </div>
                                            )}
                                            renderCommandItem={(item) => (
                                                <div
                                                    className={`flex items-center ${
                                                        "name" in item && item.id === "you"
                                                            ? "opacity-50 cursor-not-allowed"
                                                            : ""
                                                    }`}
                                                >
                                                    {"name" in item && item.id === "you" ? (
                                                        <>
                                                            <div className="w-8 h-8 rounded-full mr-2">
                                                                <img
                                                                    alt="Profile"
                                                                    src={myDetails?.imageUrl}
                                                                    className="object-cover w-full h-full rounded-full"
                                                                />
                                                            </div>
                                                            <p>{item.name.fullName}</p>
                                                            <p className="text-gray-500 text-sm ml-2">
                                                                (You)
                                                            </p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="w-8 h-8 rounded-full mr-2 flex items-center justify-center bg-gray-200">
                                                                <p className="text-sm">
                                                                    {"name" in item
                                                                        ? `${item.name.firstName?.charAt(0).toUpperCase()}${item.name.lastName?.charAt(0).toUpperCase()}`
                                                                        : `${item.firstName?.charAt(0).toUpperCase()}${item.lastName?.charAt(0).toUpperCase()}`}
                                                                </p>
                                                            </div>
                                                            <p>
                                                                {"name" in item
                                                                    ? item.name.fullName
                                                                    : `${item.firstName} ${item.lastName}`}
                                                            </p>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                            triggerButtonClassName="w-[200px] justify-between"
                                            popoverContentClassName="w-[300px]"
                                            commandInputPlaceholder="Search user..."
                                            emptyText="No user found."
                                            isItemDisabled={isItemDisabled}
                                            storeObject={true}
                                        />
                                        <FormMessage className="mt-1 text-xs" />
                                    </div>
                                </div>
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={control}
                        name="contractor"
                        render={({ field }) => (
                            <FormItem className="flex flex-col">
                                <div className="flex items-center">
                                    <FormLabel className="max-w-28 mt-3 text-gray-800 text-sm font-normal w-full">
                                        Contractor
                                    </FormLabel>
                                    <div className="flex flex-col">
                                        <div className="flex items-center flex-1 w-full">
                                            <LBPopoverDropdown<TUser>
                                                disabled={mode === "Preview"}
                                                value={field.value}
                                                onChange={(value) => {
                                                    clearErrors("contractor");
                                                    field.onChange(value);
                                                }}
                                                items={contractors?.data || []}
                                                getItemValue={(item) =>
                                                    `${item.firstName} ${item.lastName}`
                                                }
                                                renderTrigger={() =>
                                                    mode !== "Preview" && (
                                                        <div className="flex text-gray-500 items-center">
                                                            <Plus size={16} className="mr-1" />
                                                            {field.value ? "Change" : "Assign"}
                                                        </div>
                                                    )
                                                }
                                                renderTriggerButton={
                                                    mode === "Preview" ? false : true
                                                }
                                                renderSelectedItem={(value, onChange) =>
                                                    field.value ? (
                                                        <Button
                                                            type="button"
                                                            variant="outline"
                                                            disabled={mode === "Preview"}
                                                            className="px-2 py-1 w-32 text-xs ml-2 flex items-center justify-between gap-1 transition-colors duration-150 hover:bg-[#aaddf4] rounded-md bg-[#CCEFFF]"
                                                        >
                                                            <p className="text-ellipsis overflow-hidden w-full">
                                                                {`${value.firstName} ${value.lastName}`}
                                                            </p>
                                                            {mode !== "Preview" && (
                                                                <IoIosClose
                                                                    onClick={() => {
                                                                        onChange(null);
                                                                        field.onChange(null);
                                                                    }}
                                                                    className="cursor-pointer text-black"
                                                                    size={24}
                                                                />
                                                            )}
                                                        </Button>
                                                    ) : null
                                                }
                                                renderCommandItem={(person) => (
                                                    <div className="flex items-center">
                                                        <div className="w-8 h-8 rounded-full mr-2 flex items-center justify-center bg-gray-200">
                                                            <p className="text-sm">
                                                                {person.firstName
                                                                    .charAt(0)
                                                                    .toUpperCase()}
                                                                {person.lastName
                                                                    .charAt(0)
                                                                    .toUpperCase()}
                                                            </p>
                                                        </div>
                                                        <p>
                                                            {person.firstName +
                                                                " " +
                                                                person.lastName}
                                                        </p>
                                                    </div>
                                                )}
                                                emptyText="No contractors found."
                                                storeObject={true}
                                            />
                                        </div>
                                        <div>
                                            <FormMessage className="text-xs mt-1" />
                                        </div>
                                    </div>
                                </div>
                            </FormItem>
                        )}
                    />
                    {mode === "Preview" && (
                        <div className="flex items-center">
                            <FormLabel className="max-w-28 mt-3 text-gray-800 text-sm font-normal w-full">
                                Posted On
                            </FormLabel>
                            <p className="text-sm text-gray-500 mt-1">
                                {format(
                                    new Date(currentPreviewOrderInfo?.createdAt!),
                                    "dd MMM yyyy, hh:mm a",
                                )}
                            </p>
                        </div>
                    )}
                    <Controller
                        control={control}
                        name="dueDate"
                        render={({ field }) => (
                            <FormItem className="flex flex-col">
                                <div className="flex items-center">
                                    <FormLabel className="max-w-28 m-0 text-gray-800 text-sm font-normal w-full">
                                        Due Date
                                    </FormLabel>
                                    {mode === "Preview" ? (
                                        <p className="text-sm text-gray-500">
                                            {format(
                                                new Date(currentPreviewOrderInfo?.dueDate!),
                                                "dd MMM yyyy, hh:mm a",
                                            )}
                                        </p>
                                    ) : (
                                        <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
                                            <PopoverTrigger asChild>
                                                <FormControl>
                                                    <Button
                                                        variant={"outline"}
                                                        className={cn(
                                                            " pl-3 text-left font-normal",
                                                            !field.value && "text-muted-foreground",
                                                        )}
                                                    >
                                                        {field.value ? (
                                                            format(field.value, "PPP")
                                                        ) : (
                                                            <span>Select Date</span>
                                                        )}
                                                        <CalendarIcon className="ml-2 h-4 w-4 opacity-50" />
                                                    </Button>
                                                </FormControl>
                                            </PopoverTrigger>
                                            <PopoverContent
                                                className="w-auto p-0"
                                                side="bottom"
                                                align="center"
                                            >
                                                <Calendar
                                                    mode="single"
                                                    selected={field.value}
                                                    onSelect={(value) => {
                                                        field.onChange(value);
                                                        setCalendarOpen(false);
                                                        trigger("dueDate");
                                                    }}
                                                    className="max-h-full"
                                                    disabled={(date) =>
                                                        date < new Date() ||
                                                        date < new Date("1900-01-01")
                                                    }
                                                    initialFocus
                                                />
                                            </PopoverContent>
                                        </Popover>
                                    )}
                                </div>
                                {errors.dueDate && (
                                    <p className="text-sm text-red-500 mt-1">
                                        {errors.dueDate.message}
                                    </p>
                                )}
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name="priority"
                        render={({ field }) => (
                            <FormItem className="flex items-center">
                                <FormLabel className="w-28 m-0 text-gray-800 text-sm font-normal">
                                    Priority
                                </FormLabel>
                                <Popover open={priorityOpen} onOpenChange={setPriorityOpen}>
                                    <PopoverTrigger className="" asChild>
                                        <Button
                                            disabled={mode === "Preview"}
                                            variant="outline"
                                            size="sm"
                                            className={`w-[140px] justify-between text-gray-500 font-normal ${
                                                PRIORITY.find((p) => p.label === field.value)?.color
                                            }`}
                                        >
                                            <div className="flex items-center">
                                                <div
                                                    className={`w-2 h-2 rounded-full mr-2 ${
                                                        PRIORITY.find(
                                                            (p) => p.label === field.value,
                                                        )?.textColor
                                                    }`}
                                                />
                                                {transformPriority(field.value) ||
                                                    "Select priority"}
                                            </div>
                                            {priorityOpen ? (
                                                <ChevronUp className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                            ) : (
                                                <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                            )}
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-[200px] p-0" align="start">
                                        <Command>
                                            <CommandList>
                                                <CommandGroup>
                                                    {PRIORITY.map((priority) => (
                                                        <CommandItem
                                                            key={priority.value}
                                                            onSelect={() => {
                                                                field.onChange(priority.label);
                                                                setStatusOpen(false);
                                                            }}
                                                            className="flex items-center px-2 py-2 cursor-pointer hover:bg-gray-100"
                                                        >
                                                            <div
                                                                className={`w-2 h-2 rounded-full mr-2 ${priority.textColor}`}
                                                            />
                                                            <span>{priority.label}</span>
                                                            {field.value === priority.value && (
                                                                <Check className="ml-auto h-4 w-4" />
                                                            )}
                                                        </CommandItem>
                                                    ))}
                                                </CommandGroup>
                                            </CommandList>
                                        </Command>
                                    </PopoverContent>
                                </Popover>
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name="status"
                        render={({ field }) => (
                            <FormItem className="flex items-center mt-4">
                                <FormLabel className="w-28  m-0 text-gray-800 text-sm font-normal">
                                    Status
                                </FormLabel>
                                <Popover open={statusOpen} onOpenChange={setStatusOpen}>
                                    <PopoverTrigger asChild>
                                        <Button
                                            disabled={mode === "Preview"}
                                            variant="outline"
                                            size="sm"
                                            className={`w-[140px] justify-between text-gray-500 font-normal ${
                                                STATUS.find((s) => s.label === field.value)?.color
                                            }`}
                                        >
                                            <div className="flex items-center">
                                                <div
                                                    className={`w-2 h-2 rounded-full mr-2 ${
                                                        STATUS.find((s) => s.label === field.value)
                                                            ?.textColor
                                                    }`}
                                                />
                                                {field.value || "Select status"}
                                            </div>
                                            {statusOpen ? (
                                                <ChevronUp className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                            ) : (
                                                <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                            )}
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-[200px] p-0" align="start">
                                        <Command>
                                            <CommandList>
                                                <CommandGroup>
                                                    {STATUS.map((status) => (
                                                        <CommandItem
                                                            key={status.value}
                                                            onSelect={() => {
                                                                field.onChange(status.label);
                                                                setStatusOpen(false);
                                                            }}
                                                            className="flex items-center px-2 py-2 cursor-pointer hover:bg-gray-100"
                                                        >
                                                            <div
                                                                className={`w-2 h-2 rounded-full mr-2 ${status.textColor}`}
                                                            />
                                                            <span>{status.label}</span>
                                                            {field.value === status.value && (
                                                                <Check className="ml-auto h-4 w-4" />
                                                            )}
                                                        </CommandItem>
                                                    ))}
                                                </CommandGroup>
                                            </CommandList>
                                        </Command>
                                    </PopoverContent>
                                </Popover>
                            </FormItem>
                        )}
                    />
                </div>
            </div>
            <FormField
                control={control}
                name="notes"
                render={({ field }) => (
                    <FormItem className="mt-6">
                        <FormLabel className="font-medium text-gray-800">Notes</FormLabel>
                        {mode === "Preview" && getValues("notes").length < 1 ? (
                            <p className="mt-2 text-sm text-gray-400">No Notes</p>
                        ) : (
                            <FormControl>
                                <Textarea
                                    onInput={(e) => {
                                        const target = e.target as HTMLTextAreaElement;
                                        target.style.height = "auto";
                                        target.style.height = `${target.scrollHeight}px`;
                                    }}
                                    disabled={mode === "Preview"}
                                    rows={1}
                                    className="focus:ring-0 focus:ring-offset-0 placeholder:text-gray-500 focus-visible:ring-1 focus-visible:ring-offset-1 focus:outline-none focus-visible:ring-gray-400 ring-0 ring-offset-0 resize-none"
                                    placeholder="Write note"
                                    {...field}
                                />
                            </FormControl>
                        )}
                    </FormItem>
                )}
            />

            <Dialog open={isConfirmDialogOpen} onOpenChange={setIsConfirmDialogOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Confirm Completion</DialogTitle>
                        <DialogDescription>
                            Are you sure you want to mark this work order as completed? This action
                            cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setIsConfirmDialogOpen(false)}>
                            Cancel
                        </Button>
                        <Button onClick={confirmCompleteJob} disabled={isUpdatingJob}>
                            {isUpdatingJob ? "Completing..." : "Confirm"}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default WorkOrderDetails;
