import { catchAll } from "@/utils/catch";
import { getUserBasePath } from "@/utils/getBaseUserBasedPath";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetMyDetailsQuery } from "../../../redux/rtk-query/authApis";
import {
    useCreateContractMutation,
    useGetContractsQuery,
} from "../../../redux/rtk-query/contractsApis";
import { Content } from "./Content";
import { Header } from "./Header";
import styles from "./PropertyManagementCreateContract.module.css";

// function camelCaseToSentenceCase(camelCaseString: string) {
//     const spacedString = camelCaseString.replace(/([a-z])([A-Z])/g, "$1 $2");
//     const sentenceCaseString = spacedString.charAt(0).toUpperCase() + spacedString.slice(1);
//     return sentenceCaseString;
// }

// const validateDate = (
//     object: any,
//     optionalValues?: string[],
// ): { hasError: boolean; missingKeys: string[]; errorMessages: string[] } => {
//     if (typeof object !== "object") return { hasError: true, missingKeys: [], errorMessages: [] };

//     const missingKeys: string[] = [];
//     const errorMessages: string[] = [];

//     for (const key in object) {
//         if (optionalValues?.includes(key)) continue;

//         if (!object?.[key]) {
//             missingKeys.push(key);
//             errorMessages.push(`${camelCaseToSentenceCase(key).replace("Id", "")} is required!`);
//         }
//     }

//     return { hasError: missingKeys.length > 0, missingKeys, errorMessages };
// };

export const PropertyManagementCreateContract = () => {
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState<any>({
        propertyId: "",
        companyId: "",
        userId: "",
        attachmentsUrls: [],
    });

    const { id } = useParams<{ id: string }>();

    const { data: contractDetails } = useGetContractsQuery(id!, { skip: !id });
    const { data: myDetails } = useGetMyDetailsQuery();
    const [createContract, { isLoading: isCreatingContract }] = useCreateContractMutation();

    const onClickCreateContract = useCallback(
        async (data: any) => {
            if (!myDetails?.userType) return;

            const bookingDate = dayjs(data.bookingDate);
            const startDate = dayjs(data.startDate);
            const endDate = dayjs(data.endDate);

            if (bookingDate.isAfter(startDate) || bookingDate.isAfter(endDate)) {
                toast.error("Booking date cannot be after start and end dates");
                return;
            }

            if (startDate.isAfter(endDate)) {
                toast.error("Start date cannot be after end date");
                return;
            }

            if (data.rent < 0 || data.securityDeposit < 0 || data.otherCharges < 0) {
                toast.error("Rent, security deposit & other charges cannot be negative");
                return;
            }

            const { tenant, ...restData } = data;
            const createContractResponse = await catchAll(
                createContract({ ...formValues, ...restData }).unwrap(),
            );
            if (createContractResponse.error) {
                return;
            }
            const basePath = getUserBasePath(myDetails?.userType);
            navigate(`${basePath}/contracts`);
        },
        [createContract, formValues, myDetails?.userType, navigate],
    );
    // console.log(contractDetails, "llllllllllllllslslslssmsdndn");

    return (
        <div className={`${styles.main} mx-5 mt-2.5`}>
            <div className={styles.innerContainer}>
                <Header heading="Create Contract" />
                <Content
                    formValues={formValues}
                    setFormValues={setFormValues}
                    contractDetails={contractDetails}
                    onSubmit={onClickCreateContract}
                    isCreatingContract={isCreatingContract}
                />
                {/* {!contractDetails && (
                    <Footer
                        onClickCreateContract={onClickCreateContract}
                        isCreatingContract={isCreatingContract}
                    />
                )} */}
            </div>
        </div>
    );
};
