import { Button } from "@/components/ui/button";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useGetAddressQuery, useGetMyDetailsQuery } from "@/redux/rtk-query/authApis";
import { useGetJobsQuery } from "@/redux/rtk-query/jobsApis";
import { useGetBuildingQuery, useGetBuildingsByCompanyQuery } from "@/redux/rtk-query/properties";
import { capitalize } from "@/utils/capitalize";
import { convertScreamToTitle } from "@/utils/convertScreamToTitle";
import { useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "./Breadcrumbs";
import { NewHeader } from "../../../../components/common/NewHeader/NewHeader";
import { PropertyCard } from "./PropertyCard";
import { StatCard } from "./StatCard";
import { AddUnit } from "../addUnit";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { ActionsCell } from "./actionCell";
import React from "react";
import LBModal from "@/components/lBComponents/LBModal";
import AddWorkContainer from "@/pages/myJobs/components/AddWorkContainer";
import { useGetJobFileMetadataQuery } from "@/redux/rtk-query/uploadApis";

const UnitTableColumns = [
    { key: "name", value: "Unit" },
    { key: "noOfBedrooms", value: "Bedrooms" },
    { key: "status", value: "status" },
    { key: "tenant", value: "Tenant" },
    { key: "", value: "" },
];

const JobTableColumns = [
    // { key: "name", value: "Unit" },
    { key: "jobTitle", value: "Job Title" },
    { key: "contractor", value: "Contractor" },
    { key: "status", value: "Status" },
];

export const ViewUnitAndMaintenance = () => {
    const [unitTableVisibleColumns] = useState<string[]>([
        "name",
        "noOfBedrooms",
        "status",
        "tenant",
    ]);
    const [jobTableVisibleColumns] = useState<string[]>([
        "name",
        "jobTitle",
        "contractor",
        "status",
    ]);

    const { propertyId } = useParams<{ propertyId: string }>();

    const { data: myDetails } = useGetMyDetailsQuery();

    const { data: allJobsData, isLoading: allJobsLoading } = useGetJobsQuery(
        { type: "ALL", myDetails },
        {
            skip: !myDetails,
        },
    );

    const { data: propertiesData } = useGetBuildingsByCompanyQuery(
        { id: myDetails?.companyId || "", type: "COMPANY" },
        {
            skip: !myDetails?.companyId,
        },
    );

    const { data: unitsData, isLoading: unitsLoading } = useGetBuildingQuery(
        { id: propertyId ?? "", type: "BUILDING" },
        {
            skip: !propertyId,
        },
    );

    const thisProperty: any = propertiesData?.data?.find(
        (property) => property?._id === propertyId,
    );

    const { data: thisPropertyAddress } = useGetAddressQuery(thisProperty?.addressId, {
        skip: !thisProperty?.addressId,
    });

    const username =
        myDetails?.firstName && myDetails?.lastName
            ? myDetails?.firstName + " " + myDetails?.lastName
            : myDetails?.firstName
              ? myDetails?.firstName
              : myDetails?.lastName;

    const UnitTableHeadItems = useMemo(
        () => UnitTableColumns.filter((item) => unitTableVisibleColumns.includes(item.key)),
        [unitTableVisibleColumns],
    );

    const JobTableHeadItems = useMemo(
        () => JobTableColumns.filter((item) => jobTableVisibleColumns.includes(item.key)),
        [jobTableVisibleColumns],
    );

    const [isAddUnitModalOpen, setIsAddUnitModalOpen] = useState(false);
    const [workOrderDialog, setWorkOrderDialog] = React.useState(false);
    const [selectedJob, setSelectedJob] = React.useState<any>(null);
    const [currentDocuments, setCurrentDocuments] = useState<any[] | undefined>(undefined);

    const { data: files, isFetching: isDocumentsLoading } = useGetJobFileMetadataQuery(
        selectedJob?._id || "",
        {
            skip: !selectedJob?._id,
        },
    );

    React.useEffect(() => {
        if (files && selectedJob) {
            setCurrentDocuments(files);
        }
    }, [files, selectedJob]);

    const handleEditMaintenance = (job: any) => {
        setSelectedJob(job);
        setWorkOrderDialog(true);
    };

    // const onClickAddUnit = useCallback(() => {}, []);

    // console.log(thisProperty?.files , "llll");
    console.log(selectedJob);

    return (
        <div className="w-full bg-white">
            {workOrderDialog && (
                <LBModal
                    headerContainerClassName=""
                    closeButtonClassName="text-black"
                    isOpen={workOrderDialog}
                    onClose={() => {
                        setWorkOrderDialog(false);
                        setCurrentDocuments(undefined);
                        setSelectedJob(null);
                    }}
                    title={"Work Order Details"}
                    titleClassName="text-[16px] font-semibold"
                >
                    <AddWorkContainer
                        mode={"Preview"}
                        currentPreviewOrderInfo={selectedJob}
                        currentWorkOrderDocuments={currentDocuments}
                        currentDocumentsLoading={isDocumentsLoading}
                        editDetailsButton={false}
                    />
                </LBModal>
            )}
            <NewHeader
                heading={thisProperty?.name}
                username={username}
                image={myDetails?.imageUrl}
            />
            <div className="flex h-full">
                <section className="p-4 max-w-[31.16%]">
                    <Breadcrumbs name={thisProperty?.name} className="mb-4" />
                    <PropertyCard
                        images={thisProperty?.images}
                        propertyName={thisProperty?.name}
                        province={thisPropertyAddress?.province}
                        country={thisPropertyAddress?.country}
                        category={thisProperty?.category}
                        ownerName={thisProperty?.ownerName}
                        files={thisProperty?.files}
                    />
                </section>
                <section className="flex-1 py-2 border-l border-l-zinc-200 h-full">
                    <Tabs defaultValue="units" className="w-full">
                        <div className="border-b border-b-zinc-200 pb-4 px-4">
                            <TabsList>
                                <TabsTrigger value="units">All Units</TabsTrigger>
                                <TabsTrigger value="maintenance">Maintenance Requests</TabsTrigger>
                            </TabsList>
                        </div>

                        <TabsContent value="units" className="!mt-4 mx-4">
                            <StatCard
                                mainStatLabel={"Total Units"}
                                mainStatValue={unitsData?.[0]?.properties?.length ?? 0}
                                progressStat1Label={"Occupied"}
                                progressStat1Value={
                                    unitsData?.[0]?.properties?.filter(
                                        (prop) => prop.status === "BOOKED",
                                    )?.length ?? 0
                                }
                                progressStat2Label={"Vacant"}
                                progressStat2Value={
                                    unitsData?.[0]?.properties?.filter(
                                        (prop) => prop.status === "AVAILABLE",
                                    )?.length ?? 0
                                }
                            />
                            <div className="flex items-center justify-between mb-3">
                                <h2 className="font-medium text-lg leading-[26px] text-black">
                                    All Units ({unitsData?.[0]?.properties?.length ?? 0})
                                </h2>
                                <div className="">
                                    <Dialog
                                        open={isAddUnitModalOpen}
                                        onOpenChange={setIsAddUnitModalOpen}
                                    >
                                        <DialogTrigger asChild>
                                            <Button>Add Unit</Button>
                                        </DialogTrigger>
                                        <DialogContent className="">
                                            <AddUnit />
                                        </DialogContent>
                                    </Dialog>
                                </div>
                            </div>
                            <Table className="bg-white">
                                <TableHeader className="sticky top-0 z-10">
                                    <TableRow>
                                        {UnitTableHeadItems.map((item: any) => (
                                            <TableHead
                                                key={item.key}
                                                className="cursor-pointer bg-transparent"
                                            >
                                                {item.value}
                                            </TableHead>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {unitsLoading ? (
                                        [...Array(5)].map((_, index) => (
                                            <TableRow key={index}>
                                                {UnitTableHeadItems.map((item) => (
                                                    <TableCell key={item.key}>
                                                        <Skeleton height={20} />
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    ) : unitsData?.[0]?.properties &&
                                      unitsData[0].properties.length > 0 ? (
                                        unitsData[0].properties.map((property) => (
                                            <TableRow key={property._id}>
                                                {unitTableVisibleColumns.includes("name") && (
                                                    <TableCell>
                                                        <div className="flex items-center gap-6">
                                                            <p>{property?.name ?? ""}</p>
                                                        </div>
                                                    </TableCell>
                                                )}
                                                {unitTableVisibleColumns.includes(
                                                    "noOfBedrooms",
                                                ) && (
                                                    <TableCell>{property?.noOfBedrooms}</TableCell>
                                                )}
                                                {unitTableVisibleColumns.includes("status") && (
                                                    <TableCell>
                                                        {convertScreamToTitle(property?.status)}
                                                    </TableCell>
                                                )}
                                                {unitTableVisibleColumns.includes("tenant") && (
                                                    <TableCell>
                                                        {(() => {
                                                            const firstName = (property as any)
                                                                ?.tenancies?.[0]?.tenant?.firstName;

                                                            const lastName = (property as any)
                                                                ?.tenancies?.[0]?.tenant?.lastName;

                                                            const fullName =
                                                                capitalize(firstName) +
                                                                capitalize(lastName);

                                                            console.log(property);

                                                            const finalName =
                                                                firstName?.length ||
                                                                lastName?.length
                                                                    ? fullName
                                                                    : "-";

                                                            return finalName;
                                                        })()}
                                                    </TableCell>
                                                )}
                                                <TableCell>
                                                    <ActionsCell
                                                        id={property?._id}
                                                        text="Edit Unit"
                                                        onClick={() =>
                                                            handleEditMaintenance(property)
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={UnitTableHeadItems.length + 1}>
                                                <div className="text-center font-semibold">
                                                    No data exists!
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TabsContent>
                        <TabsContent value="maintenance" className="!mt-4 mx-4">
                            <StatCard
                                mainStatLabel={"Total Requests"}
                                mainStatValue={allJobsData?.length ?? 0}
                                progressStat1Label={"Completed"}
                                progressStat1Value={
                                    allJobsData?.filter((job) => job.status === "COMPLETED")
                                        ?.length ?? 0
                                }
                                progressStat2Value={
                                    allJobsData?.filter((job) => job.status !== "COMPLETED")
                                        ?.length ?? 0
                                }
                                progressStat2Label={"In Progress"}
                            />

                            <div className="flex items-center justify-between mb-3">
                                <h2 className="font-medium text-lg leading-[26px] text-black">
                                    All Requests ({allJobsData?.length ?? 0})
                                </h2>
                                <div className="flex items-center space-x-2">
                                    {/* <Select  onValueChange={handleToggleColumn}>
                                        <SelectTrigger className="flex items-center gap-2">
                                            <WindowIcon />
                                            <SelectValue placeholder="Columns" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectLabel>Filter</SelectLabel>
                                                {columnItems.map((item) => (
                                                    <SelectItem key={item.value} value={item.key}>
                                                        {item.value}
                                                    </SelectItem>
                                                ))}
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select> */}
                                </div>
                            </div>
                            <Table className="bg-white">
                                <TableHeader className="sticky top-0 z-10">
                                    <TableRow>
                                        {JobTableHeadItems.map((item: any) => (
                                            <TableHead
                                                key={item.key}
                                                className="cursor-pointer bg-transparent"
                                            >
                                                {item.value}
                                            </TableHead>
                                        ))}
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {allJobsLoading ? (
                                        [...Array(5)].map((_, index) => (
                                            <TableRow key={index}>
                                                {JobTableHeadItems.map((item) => (
                                                    <TableCell key={item.key}>
                                                        <Skeleton height={20} />
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    ) : allJobsData && allJobsData.length > 0 ? (
                                        allJobsData?.map((job) => (
                                            <TableRow key={job._id}>
                                                {/* {jobTableVisibleColumns.includes("name") && (
                                                    <TableCell>
                                                        <div className="flex items-center gap-6">
                                                            <p>{capitalize(job.jobCategory)}</p>
                                                        </div>
                                                    </TableCell>
                                                )} */}
                                                {jobTableVisibleColumns.includes("jobTitle") && (
                                                    <TableCell>
                                                        {job?.jobDetail?.title ?? ""}
                                                    </TableCell>
                                                )}
                                                {jobTableVisibleColumns.includes("contractor") && (
                                                    <TableCell>
                                                        {(() => {
                                                            const firstName =
                                                                job?.assignedToInfo?.firstName ??
                                                                "";
                                                            const lastName =
                                                                job?.assignedToInfo?.lastName ?? "";

                                                            if (firstName && lastName) {
                                                                return (
                                                                    capitalize(firstName) +
                                                                    " " +
                                                                    capitalize(lastName)
                                                                );
                                                            } else if (firstName) {
                                                                return capitalize(firstName);
                                                            } else if (lastName) {
                                                                return capitalize(lastName);
                                                            } else {
                                                                return "Not Assigned";
                                                            }
                                                        })()}
                                                    </TableCell>
                                                )}
                                                {jobTableVisibleColumns.includes("status") && (
                                                    <TableCell>
                                                        {convertScreamToTitle(job?.status)}
                                                    </TableCell>
                                                )}
                                                <TableCell>
                                                    <ActionsCell
                                                        id={job?._id}
                                                        text="Edit Maintenance"
                                                        onClick={() => handleEditMaintenance(job)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={JobTableHeadItems.length + 1}>
                                                <div className="text-center font-semibold">
                                                    No data exists!
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TabsContent>
                    </Tabs>
                </section>
            </div>
        </div>
    );
};
