interface AddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}

export const getCity = (addressComponents: AddressComponent[]): string | null => {
    const cityComponent = addressComponents.find((component) =>
        component.types.includes("locality"),
    );
    return cityComponent ? cityComponent.long_name : "";
};
