import { toast } from "react-toastify";
import { TCreateJobBody, TUpdateUser, TUser } from "../../types";
import { catchAll } from "../../utils/catch";
import { api } from "./api";

export const getMyDetails = api.injectEndpoints({
    endpoints: (builder) => ({
        getMyDetails: builder.query<TUser, void>({
            query: () => "auth/me",
            providesTags: ["USER_DETAILS"],
        }),

        getUserbyType: builder.query<{ data: TUser[] }, { userType?: string; status?: string }>({
            providesTags: ["USERS"],
            query: ({ userType, status }) => ({
                url:
                    userType && status
                        ? `auth/users?userType=${userType}&status=${status}`
                        : userType
                          ? `auth/users?userType=${userType}`
                          : `auth/users?&status=${status}`,
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                } catch (error) {
                    toast.error("Something went wrong! Please try again later.");
                }
            },
        }),
    }),
});

// const getCompaniesDetails = api.injectEndpoints({
//   endpoints: (builder) => {
//     return {
//       getMyCompanyDetails: builder.query<CompanyDetails, void>({
//         query: (id) => `auth/companies?id=${id}`,
//       }),
//     };
//   },
// });
// const updateUserDetails = api.injectEndpoints({
//   endpoints: (builder) => ({
//     updateUserDetails: builder.query<TUser, void>({
//       query: () => "auth/edit",
//       providesTags: ["USER_DETAILS"],
//     }),
//   }),
// });

type TCreateAddressArgs = TCreateJobBody["jobDetails"]["address"];
type TCreateAddressResponse = {
    _id: string;
    refId: string;
    tag: string;
    addressType: string;
    mainStreet: string;
    building: string;
    country: string;
    city: string;
    postalCode: string;
    timezone: string;
    latitude: number;
    longitude: number;
    province: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
};

type TUpdateAddressArgs = {
    _id: string | undefined;
    addressType: string;
    building: string;
    mainStreet: string;
    country: string;
    city: string;
    postalCode: string;
    province: string;
    timezone: string;
    latitude: number;
    longitude: number;
};

type TUpdateUserProfileArgs = Partial<{
    userId: string;
    idVerification: boolean;
    isOnline: boolean;
    bio: string;
    hourlyRate: number;
    visualVerification: boolean;
    qualificationVerification: boolean;
    qualificationName: string;
    visibility: string;
    contractPreference: string;
    businessDays: Array<string>;
    businessTime: Array<string>;
    skills: Array<any>;
    schedules: Array<string>;
    avgRating: number;
    experienceNum: number;
    totalJobs: number;
    language: string;
    companySize: string;
    yearsInBusiness: number;
    addressId?: string;
    mileRadiusPref?: number;
    callOutFee?: string;
    reasonOfJoining?: string;
}>;

type TUserProfile = {
    userId: string;
    visibility: string;
    idVerification: boolean;
    skills: Array<string>;
    isOnline: boolean;
    hourlyRate: number;
    bio: string;
    visualVerification: boolean;
    qualificationVerification: boolean;
    qualificationName: string;
    contractPreference: string;
    businessDays: Array<string>;
    avgRating: number;
    schedules: Array<string>;
    experienceNum: number;
    totalJobs: number;
    yearsInBusiness: number;
    language: string;
    _id: string;
    categories: Array<any>;
    createdAt: string;
    updatedAt: string;
    __v: number;
};

const updateUserDetails = api.injectEndpoints({
    endpoints: (builder) => ({
        updateUserDetails: builder.mutation<any, TUpdateUser>({
            query: (data) => {
                return {
                    url: `auth/edit`,
                    body: data,
                    method: "PUT",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Something Went wrong! Please try again later.");
                    return;
                }
            },
        }),

        createAddress: builder.mutation<TCreateAddressResponse, TCreateAddressArgs>({
            query: (body) => {
                return {
                    url: "auth/address",
                    method: "POST",
                    body,
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to save address");
                    return;
                }
            },
            transformResponse: (response: TCreateAddressResponse[]) => {
                return response[0];
            },
        }),

        getAddress: builder.query({
            query: (id) => ({
                url: `auth/address?id=${id}`,
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                const response = await catchAll(queryFulfilled);
                if (response.error) {
                    console.log(response.error);

                    toast.error("Something went wrong! Please try again later.");
                }
            },
        }),

        updateAddress: builder.mutation<TCreateAddressResponse, TUpdateAddressArgs>({
            query: (body) => {
                return {
                    url: "auth/address",
                    method: "PUT",
                    body,
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to update address");
                    return;
                }
            },
            transformResponse: (response: TCreateAddressResponse[]) => {
                return response[0];
            },
        }),
    }),
});

const inviteUsers = api.injectEndpoints({
    endpoints: (builder) => ({
        inviteUsers: builder.mutation<any, any>({
            invalidatesTags: ["TENANTS", "USERS"],
            query: (data) => ({
                url: `auth/invite`,
                body: data,
                method: "POST",
            }),
            onQueryStarted: async (_, api) => {
                try {
                    await api.queryFulfilled;
                } catch (error: any) {
                    const errorMessage =
                        error.error?.data?.message || "Invitation failed. Please try again.";
                    toast.error(errorMessage);
                }
            },
        }),
    }),
});

const setPassword = api.injectEndpoints({
    endpoints: (builder) => ({
        setPassword: builder.mutation<any, any>({
            query: ({ body, token }) => {
                return {
                    url: `auth/set-password`,
                    body: body,
                    method: "PUT",
                    headers: {
                        "X-API-Key": token,
                    },
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Something Went wrong! please try again later.");
                    return;
                }
            },
        }),
    }),
});

const userProfileApis = api.injectEndpoints({
    endpoints: (builder) => ({
        getProfileById: builder.query<TUserProfile, string>({
            query: (id) => `auth/profile?id=${id}`,
            providesTags: (_, __, id) => [{ type: "USER_PROFILE", id: id }],
        }),

        createUserProfile: builder.mutation<TUserProfile, TUpdateUserProfileArgs>({
            query: (body) => {
                return {
                    url: "auth/profile",
                    method: "POST",
                    body,
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to save user details");
                    return;
                }
            },
            invalidatesTags: (response) => [{ type: "USER_PROFILE", id: response?._id }],
        }),

        updateUserProfile: builder.mutation<TUserProfile, TUpdateUserProfileArgs>({
            query: (body) => {
                return {
                    url: "auth/profile",
                    method: "PUT",
                    body,
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);
                if (response.error) {
                    toast.error("Failed to save user details");
                    return;
                }
            },
            invalidatesTags: (response) => [{ type: "USER_PROFILE", id: response?._id }],
        }),
    }),
});

export const { useInviteUsersMutation } = inviteUsers;
export const { useSetPasswordMutation } = setPassword;
export const { useGetMyDetailsQuery, useLazyGetMyDetailsQuery, useGetUserbyTypeQuery } =
    getMyDetails;
// export const { useGetMyCompanyDetailsQuery } = getCompaniesDetails;
export const {
    useUpdateUserDetailsMutation,
    useCreateAddressMutation,
    useGetAddressQuery,
    useLazyGetAddressQuery,
    useUpdateAddressMutation,
} = updateUserDetails;

export const {
    useCreateUserProfileMutation,
    useUpdateUserProfileMutation,
    useGetProfileByIdQuery,
    useLazyGetProfileByIdQuery,
} = userProfileApis;
