import { toast } from "react-toastify";
import { catchAll } from "../../utils/catch";
import { api } from "./api";

const scriptApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createScript: builder.mutation<any, any>({
            query: (data) => ({
                url: "ai-assistant/scripts",
                method: "POST",
                body: data,
            }),
            onQueryStarted: async (_, { queryFulfilled }) => {
                try {
                    const response = await catchAll(queryFulfilled);
                    if (response.error) {
                        toast.error("Failed to create script");
                        return;
                    }
                } catch (error) {
                    toast.error("An unexpected error occurred");
                }
            },
        }),

        createTool: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `ai-assistant/tools`,
                    method: "POST",
                    body: data,
                };
            },

            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to create tool");
                    return;
                }
            },
        }),

        createToolParameter: builder.mutation<any, any>({
            query: (data) => {
                return {
                    url: `ai-assistant/toolParameters`,
                    method: "POST",
                    body: data,
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to create parameter");
                    return;
                }
            },
        }),
        getScripts: builder.query<any, string>({
            query: (companyId) => {
                // if (companyId) {
                return {
                    url: `ai-assistant/scripts/${companyId}`,
                    method: "GET",
                };
                // }
                // return null
            },
        }),
        getScriptsWeb: builder.query<any, { companyId: string; pageSize: number }>({
            query: (arg) => {
                return {
                    url: `ai-assistant/scripts-web/${arg.companyId}`,
                    method: "GET",
                    params: {
                        pageSize: arg.pageSize,
                    },
                };
            },
        }),

        getScriptById: builder.query<any, string>({
            query: (scriptId) => {
                // const params = new URLSearchParams({
                //     scriptId: scriptId,
                // }).toString();
                return {
                    url: `ai-assistant/scripts/details/${scriptId}`,
                    method: "GET",
                };
            },
        }),
        updateScript: builder.mutation({
            query: (body) => {
                return {
                    url: `ai-assistant/scripts`,
                    method: "PUT",
                    body,
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to update script");
                    return;
                }
            },
        }),
        updateTools: builder.mutation({
            query: (body) => {
                return {
                    url: `ai-assistant/tools`,
                    method: "PUT",
                    body,
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to update tools");
                    return;
                }
            },
        }),
        updateParameters: builder.mutation({
            query: (body) => {
                return {
                    url: `ai-assistant/toolParameters`,
                    method: "PUT",
                    body,
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to update parameter");
                    return;
                }
            },
        }),
        deleteParameter: builder.mutation({
            query: (_id) => {
                return {
                    url: `ai-assistant/toolParameters/${_id}`,
                    method: "DELETE",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to delete parameter");
                    return;
                }
            },
        }),
        deleteScript: builder.mutation({
            query: (_id) => {
                return {
                    url: `ai-assistant/scripts/${_id}`,
                    method: "DELETE",
                };
            },
            onQueryStarted: async (_, api) => {
                const response = await catchAll(api.queryFulfilled);

                if (response.error) {
                    toast.error("Failed to delete script");
                    return;
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useCreateScriptMutation,
    useCreateToolMutation,
    useCreateToolParameterMutation,
    useGetScriptsQuery,
    useGetScriptsWebQuery,
    useLazyGetScriptsQuery,
    useLazyGetScriptByIdQuery,
    useGetScriptByIdQuery,
    useUpdateScriptMutation,
    useUpdateToolsMutation,
    useUpdateParametersMutation,
    useDeleteParameterMutation,
    useDeleteScriptMutation,
} = scriptApi;
