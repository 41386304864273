import { useFormContext } from "react-hook-form";
import { WorkOrderFormValues } from "./workOrderSchema";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { ChevronDown, ChevronUp } from "lucide-react"; // Add this import
import { cn } from "@/lib/utils";
import { useGetBuildingQuery, useGetBuildingsByCompanyQuery } from "@/redux/rtk-query/properties";
import { useGetMyDetailsQuery } from "@/pages/propertyManagementKnowledgeBase";
import { useEffect, useState, useRef, useCallback } from "react";
import { Loader2 } from "lucide-react"; // Import the Loader2 icon from lucide-react
import { useGetAddressQuery } from "@/redux/rtk-query/authApis";
import { TProperty, TPropertyBuildingFormResponse } from "@/types";

const PropertyUnitForm = ({ setCurrentStep }: { setCurrentStep: (step: number) => void }) => {
    const {
        control,
        formState: { errors },
        trigger,
        getValues,
        setValue,
        clearErrors,
    } = useFormContext<WorkOrderFormValues>();
    const [skip, setSkip] = useState(0);
    const [allProperties, setAllProperties] = useState<any[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef<IntersectionObserver | null>(null);
    const { data: myDetails } = useGetMyDetailsQuery();
    const {
        data: address,
        isLoading: addressLoading,
        isFetching: addressFetching,
    } = useGetAddressQuery(getValues("property")?.addressId || "", {
        skip: !getValues("property")?.addressId,
    });
    const [currentProperty, setCurrentProperty] = useState<TPropertyBuildingFormResponse>();
    const { data: building } = useGetBuildingQuery(
        { id: currentProperty?._id || "", type: "BUILDING" },
        {
            skip: !currentProperty?._id,
        },
    );

    const { data: properties, isFetching } = useGetBuildingsByCompanyQuery({
        id: myDetails?.companyId || "",
        type: "COMPANY",
        skip,
        limit: 10,
    });
    useEffect(() => {
        if (properties?.data) {
            setAllProperties((prev: any) => [...prev, ...properties.data]);
            setHasMore(properties.data.length === 10);
        }
    }, [properties]);

    const lastElementRef = useCallback(
        (node: HTMLDivElement | null) => {
            if (isFetching) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setSkip((prev: any) => prev + 10);
                }
            });
            if (node) observer.current.observe(node);
        },
        [hasMore, isFetching],
    );

    useEffect(() => {
        if (address && !addressLoading && !addressFetching) {
            setValue("address", address);
        }
    }, [address, addressLoading, addressFetching, setValue]);

    useEffect(() => {
        if (currentProperty) {
            // Reset the unit selection when the property changes
            setValue("unit", {} as TProperty);
            clearErrors("unit");
        }
    }, [currentProperty, setValue, clearErrors]);

    const [open, setOpen] = useState({ property: false, unit: false });

    const handleContinue = async () => {
        // Trigger validation for both property and unit fields
        const result = await trigger(["property", "unit"]);

        if (result) {
            // Both fields are valid, proceed to the next step
            setCurrentStep(2);
        } else {
            // Fields are invalid, errors will be displayed
            console.log("Please select both property and unit");
        }
    };

    return (
        <div className="max-w-full w-[500px] ">
            <div>
                <h1 className="text-2xl font-bold">Property & Unit</h1>
                <p className="text-sm text-gray-500">Select property and unit of the work order</p>
            </div>
            <div className="mt-6">
                <div className="flex flex-col space-y-4">
                    <div className="flex space-x-4">
                        <div className="flex-1">
                            <FormField
                                control={control}
                                name="property"
                                render={({ field }) => (
                                    <FormItem className="flex flex-col">
                                        <FormLabel>Property</FormLabel>
                                        <Popover
                                            open={open.property}
                                            onOpenChange={(isOpen) =>
                                                setOpen((prev) => ({ ...prev, property: isOpen }))
                                            }
                                        >
                                            <PopoverTrigger asChild>
                                                <FormControl>
                                                    <Button
                                                        variant="outline"
                                                        role="combobox"
                                                        className={cn(
                                                            "w-full justify-between text-[#555555]",
                                                        )}
                                                    >
                                                        {field.value.name
                                                            ? field.value.name
                                                            : "Select property"}
                                                        {open.property ? (
                                                            <ChevronUp className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                                        ) : (
                                                            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                                        )}
                                                    </Button>
                                                </FormControl>
                                            </PopoverTrigger>
                                            <PopoverContent
                                                side="bottom"
                                                align="start"
                                                className="w-[200px] h-60 p-0"
                                            >
                                                <Command>
                                                    <CommandInput placeholder="Search property..." />
                                                    <CommandEmpty>No property found.</CommandEmpty>
                                                    <CommandList>
                                                        <CommandGroup>
                                                            {allProperties.map((property: any) => (
                                                                <CommandItem
                                                                    key={property._id}
                                                                    className="py-2 text-[#555555] hover:bg-gray-100 transition-all duration-150"
                                                                    onSelect={() => {
                                                                        setValue(
                                                                            "property",
                                                                            property,
                                                                        );
                                                                        setCurrentProperty(
                                                                            property,
                                                                        );
                                                                        field.onChange(property);
                                                                        setOpen((prev) => ({
                                                                            ...prev,
                                                                            property: false,
                                                                        }));
                                                                        clearErrors("property");
                                                                        // Reset the unit when property changes
                                                                        setValue(
                                                                            "unit",
                                                                            {} as TProperty,
                                                                        );
                                                                        clearErrors("unit");
                                                                    }}
                                                                    value={property.name}
                                                                >
                                                                    {property.name}
                                                                </CommandItem>
                                                            ))}
                                                            {hasMore && (
                                                                <div
                                                                    ref={lastElementRef}
                                                                    className="h-1"
                                                                />
                                                            )}
                                                            {isFetching && hasMore && (
                                                                <div className="flex items-center justify-center py-2 text-sm text-gray-500">
                                                                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                                                                    Loading more...
                                                                </div>
                                                            )}
                                                        </CommandGroup>
                                                    </CommandList>
                                                </Command>
                                            </PopoverContent>
                                        </Popover>
                                        <FormMessage>
                                            {errors?.property && "Please select a property"}
                                        </FormMessage>
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="flex-1">
                            <FormField
                                control={control}
                                name="unit"
                                render={({ field }) => (
                                    <FormItem className="flex flex-col">
                                        <FormLabel>Unit</FormLabel>
                                        <Popover
                                            open={open.unit}
                                            onOpenChange={(isOpen) =>
                                                setOpen((prev) => ({ ...prev, unit: isOpen }))
                                            }
                                        >
                                            <PopoverTrigger
                                                disabled={getValues("property").name ? false : true}
                                                asChild
                                            >
                                                <FormControl>
                                                    <Button
                                                        variant="outline"
                                                        role="combobox"
                                                        className={cn(
                                                            "w-full justify-between text-[#555555]",
                                                        )}
                                                    >
                                                        {field?.value?._id
                                                            ? field?.value?.name
                                                                  ?.charAt(0)
                                                                  .toUpperCase() +
                                                              field?.value?.name?.slice(1)
                                                            : "Select unit"}
                                                        {open.unit ? (
                                                            <ChevronUp className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                                        ) : (
                                                            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                                        )}
                                                    </Button>
                                                </FormControl>
                                            </PopoverTrigger>
                                            <PopoverContent
                                                side="bottom"
                                                align="start"
                                                className="w-[200px] p-0"
                                            >
                                                <Command>
                                                    <CommandInput placeholder="Search unit..." />
                                                    <CommandEmpty>No unit found.</CommandEmpty>
                                                    <CommandList>
                                                        <CommandGroup>
                                                            {building &&
                                                                building &&
                                                                building[0].properties &&
                                                                building[0].properties.map(
                                                                    (unit) => (
                                                                        <CommandItem
                                                                            key={unit._id}
                                                                            onSelect={() => {
                                                                                field.onChange(
                                                                                    unit,
                                                                                );
                                                                                setOpen((prev) => ({
                                                                                    ...prev,
                                                                                    unit: false,
                                                                                }));
                                                                                clearErrors("unit");
                                                                            }}
                                                                            value={unit.name}
                                                                            className="cursor-pointer text-[#555555]"
                                                                        >
                                                                            {unit.name}
                                                                        </CommandItem>
                                                                    ),
                                                                )}
                                                        </CommandGroup>
                                                    </CommandList>
                                                </Command>
                                            </PopoverContent>
                                        </Popover>
                                        <FormMessage>
                                            {errors.unit && "Please select a unit"}
                                        </FormMessage>
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex items-center mt-6 justify-end">
                    <Button
                        type="button"
                        disabled={addressLoading || addressFetching}
                        onClick={handleContinue}
                    >
                        {addressLoading || addressFetching ? "Address Loading..." : "Continue"}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PropertyUnitForm;
