import styles from "../Common.module.css";
import pAvatart from "../../../assets/images/jobImg.png";

interface ProfileCardProps {
    firstName: string;
    lastName: string;
    handleDetailPage?: () => void;
}

function ProfileCard({ firstName, lastName, handleDetailPage }: ProfileCardProps) {
    return (
        <div className={styles.pCardFrame}>
            <div>
                <img src={pAvatart} alt="profile_avatar" />
            </div>
            <div className={styles.pCardHead} onClick={handleDetailPage}>
                {firstName || "john"} {lastName || "Doe"}
            </div>
            {/* <div className={styles.ratFrame}>
                    <div>
                        <span>
                            <RatingIcon />
                        </span>
                        <span>
                            <RatingIcon />
                        </span>
                        <span>
                            <RatingIcon />
                        </span>
                        <span>
                            <RatingIcon />
                        </span>
                        <span>
                            <RatingIcon />
                        </span>
                    </div>
                    <span className={styles.pCardRat}>4.5</span>
                </div> */}
        </div>
    );
}

export default ProfileCard;
